import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserRoleService } from '@sharedservices/BackServices/ComTrak/role/userrole.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-createuserrole',
  templateUrl: './createuserrole.component.html',
  styleUrls: ['./createuserrole.component.scss', '../../style.component.scss']
})
export class CreateuserroleComponent implements OnInit {
  UserRoleForm: FormGroup;
  submitted: boolean = false;
  public _data: any;
  isedit: boolean = false;
  actionType: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Name',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };
  disbaledFields: boolean = false;
  constructor(public dialogRef: MatDialogRef<CreateuserroleComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, public userRoleService: UserRoleService, public spinner: NgxSpinnerService, public _Global: GlobalfunctionService) { }

  ngOnInit() {
    this.initForm();

    this.userRoleService.GetRoles();
    if (this.data.callList != undefined) {
      this.userRoleService._staffUsers = this.userRoleService._staffUsers
      var filteredUser = this.userRoleService._userRoles.filter(o => o.Email == this.data.callList.Email)[0]
      if (filteredUser) {
        this.userRoleService._staffUsers.push(filteredUser)
      }
      this.UserRoleForm.controls.Email.setValue(this.data.callList.Email);
      this.UserRoleForm.controls.RoleName.setValue(this.data.callList.UserRoleName);
      this.isedit = true;
    }
    else {
      this.isedit = false;
    }
  }

  initForm() {
    this.UserRoleForm = this.fb.group({
      RoleName: [[], Validators.required],
      Email: ['', Validators.required],
      Manager: ['', Validators.required],
      Id: [''],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', {
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.UserRoleForm.controls;
  }

  onSubmit() {
    let selectedManager = this.userRoleService._staffUsers.find((e) => e.Email === this.UserRoleForm.controls.Manager.value)
    this.submitted = true;
    if (this.UserRoleForm.invalid) {
      return;
    }
    else {
      let roleIDarray: string[] = [];
      if (this.UserRoleForm.controls.RoleName.value && this.UserRoleForm.controls.RoleName.value.length > 0) {
        this.UserRoleForm.controls.RoleName.value.forEach(element => {
          roleIDarray.push(element.Name ? element.Name : element);
        });
      }
      let Id = this.data.callList ? this.data.callList.Id : 0;
      this.UserRoleForm.controls.Id.setValue(Id);
      let userRoleobject: any = {
        RoleName: roleIDarray,
        Email: this.UserRoleForm.controls.Email.value,
        ManagerId: selectedManager.Id
      };
      this.userRoleService.SaveAndEditUserRole(userRoleobject, this.isedit);
      this.userRoleService.isCallApi.subscribe(res => {
        if (res) {
          this.onNoClick();
          this.userRoleService.isCallApiSave.next(true);
        }
      });
    }
  }
}
