import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CampaignReferralSource, CampaignReferralSourceAdvertisement } from '@sharedmodels/BackModels/ComTrak/Setups/CampaignReferralSource';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { StaffService } from '@sharedservices/BackServices/ComTrak/Staff/staff.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';

@Component({
  selector: 'app-assignleadstorecruiter',
  templateUrl: './assignleadstorecruiter.component.html',
  styleUrls: ['./assignleadstorecruiter.component.css', '../../style.component.scss']
})
export class AssignleadstorecruiterComponent implements OnInit {

  leadToRecruiterForm: FormGroup
  submitted: boolean;
  // campaignReferralArray: CampaignReferralSource[] = [];
  // campaignReferralAdDetailArray: CampaignReferralSourceAdvertisement[] = [];
  // assignCount: number;
  // unAssignCount: number;
  constructor(public dialogRef: MatDialogRef<AssignleadstorecruiterComponent>, public fb: FormBuilder, public campaignService: CampaignService,
    public campaignRefSourceService: CampaignReferralSourceService, public staffService: StaffService, public leadService: LeadService,
    public _Global: GlobalfunctionService, public _LocationService: LocationService) { }

  ngOnInit() {
    this.campaignService._patientsCampaignRefSource = []
    this.campaignService._patientsCampaignAdDetails = []
    this.campaignService.GetLeadsPatientsCampaigns("sp_AssignLeads_getLOVs", 0)
    // this.leadService.GetAllLeadsList("")
    this.leadService.AssignCount = 0
    this.leadService.UnAssignCount = 0
    this.staffService.GetAllActiveStaff()
    this.initForm()
  }

  initForm() {
    this.leadToRecruiterForm = this.fb.group({
      CampaignId: [null, Validators.required],
      CampaignRefSourceId: [null],
      CampaignRefSourceAdDetailId: [null],
      StateID: [null],
      RecruiterId: ['', Validators.required],
      NoOfAssign: ['', Validators.required],
      Assignable: [''],
      UnAssignable: [''],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  ChangeCampaign() {
    this.leadToRecruiterForm.controls.CampaignRefSourceId.setValue(null)
    this.leadToRecruiterForm.controls.CampaignRefSourceAdDetailId.setValue(null)
    this.leadToRecruiterForm.controls.StateID.setValue(null)
    this.campaignService._patientsCampaignRefSource = []
    this.campaignService.GetPatientsCampaignsRefSource("sp_AssignLeads_getLOVs", this.leadToRecruiterForm.controls.RecruiterId.value, this.leadToRecruiterForm.controls.CampaignId.value);
  }

  ChangeCampaignRefSource() {
    this.leadToRecruiterForm.controls.CampaignRefSourceAdDetailId.setValue(null)
    this.campaignService._patientsCampaignAdDetails = []
    this.campaignService.GetPatientsCampaignsAdDetails("sp_AssignLeads_getLOVs", this.leadToRecruiterForm.controls.RecruiterId.value, this.leadToRecruiterForm.controls.CampaignId.value, this.leadToRecruiterForm.controls.CampaignRefSourceId.value);
  }

  get f() {
    return this.leadToRecruiterForm.controls;
  }

  GetAssignUnAssignCount() {
    this.leadService.GetAllLeadsCount(this.leadToRecruiterForm.controls.CampaignId.value, this.leadToRecruiterForm.controls.CampaignRefSourceId.value, this.leadToRecruiterForm.controls.CampaignRefSourceAdDetailId.value, this.leadToRecruiterForm.controls.StateID.value)
  }

  ChangeNoOfAssiign() {
    if (this.leadToRecruiterForm.controls.NoOfAssign.value > this.leadService.AssignCount) {
      this._Global.showErrorMsg("No.Of Assign cannot be greater than Assignable");
      return true
    }
    if (this.leadToRecruiterForm.controls.NoOfAssign.value == 0) {
      this._Global.showErrorMsg("No.Of Assign greater than Zero (0)");
      return true
    }
    else {
      return false
    }
  }
  Increment() {
    let num: number
    num = parseInt(this.leadToRecruiterForm.controls.NoOfAssign.value != '' ? this.leadToRecruiterForm.controls.NoOfAssign.value : '0') + 1
    this.leadToRecruiterForm.controls.NoOfAssign.setValue(num.toString());

  }
  Decrement() {
    let num: number
    num = parseInt(this.leadToRecruiterForm.controls.NoOfAssign.value) - 1;
    if (num >= 1 && num <= 100) {
      this.leadToRecruiterForm.controls.NoOfAssign.setValue(num.toString());
    }

  }

  onSubmit() {
    this.submitted = true;
    if (this.leadToRecruiterForm.invalid) {
      return;
    }
    else {
      if (this.ChangeNoOfAssiign() == false) {
        if (this.leadToRecruiterForm.controls.NoOfAssign.value > 100) {
          this._Global.showErrorMsg("No Of Leads to Assign should not be greater than 100")
          return
        }
        this.leadService.AssignLeadsToRecruiter(this.leadToRecruiterForm.controls.RecruiterId.value,
          this.leadToRecruiterForm.controls.CampaignId.value, this.leadToRecruiterForm.controls.CampaignRefSourceId.value,
          this.leadToRecruiterForm.controls.CampaignRefSourceAdDetailId.value, this.leadToRecruiterForm.controls.StateID.value, this.leadToRecruiterForm.controls.NoOfAssign.value);
        this.leadService.isCallApiAssign.subscribe(res => {
          if (res) {
            this.onNoClick()
          }
        })
      }
    }
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[(0-9)]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

}
