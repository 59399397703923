export class ListMaster {
    Id: number
    Name: string
    TherapeuticArea: string
    CreatedOn: string
    IsActive: boolean
}

export class ListFilter {
    Id: number
    ListMasterId: number
    IsInclusion: boolean
    FilterTypeId: number
    FilterTypeValue: number
}
export class ReAssignableRecruiterList {
    Count?: any
    RecruiterId: number
    TotalPatientsCount?: any
    ListMasterId: number
    NoOfAssignPatient: any
    SelectedRecruiterId: any
}