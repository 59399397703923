import { SiteCalendar } from './../../../../Models/BackModels/ComTrak/Setups/SiteCalendar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SiteCalendarService {
    public isCallApi = new Subject<boolean>();
    public _SiteCalendar: SiteCalendar[]
    public _allSiteCalendar: SiteCalendar[]
    public _allSiteCalendarArray: SiteCalendar[]
    arraylength: number;
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService) { }

    LoadSiteCalendarBySiteId(siteId: number) {
        try {
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'SiteCalendar?siteId=' + siteId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._Global.IsReady = true;
                    return this._SiteCalendar = <SiteCalendar[]>data;
                }, error => {
                    this._Global.handleError(error);
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
        }
    }

    LoadAllSiteCalendars(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'SiteCalendar?getType=GetAll' + "&searchString=" + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._allSiteCalendar = <SiteCalendar[]>data;
                    this._allSiteCalendarArray = <SiteCalendar[]>data;
                    this.arraylength = this._allSiteCalendar.length
                    this.isCallApi.next(true)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    GetSiteCalendars(searchString: string): SiteCalendar[] {
        if (this._allSiteCalendar !== undefined && this._allSiteCalendar.length !== null) {
            return this._allSiteCalendar;
        } else {
            this.LoadAllSiteCalendars(searchString);
        }
    }

    SaveSiteCalendar(body: SiteCalendar) {
        try {
          this.spinner.show()
          this._Global.IsReady = false;
          this._http.post(myGlobals._apiPath + 'SiteCalendar/Save', body, { headers: this._Global.httpOptions })
            .subscribe(data => {
              this.isCallApi.next(true)
              this._Global.showSuccessMsg("Site Calendar Saved Successfully")
              this.spinner.hide()
              this._Global.IsReady = true;
              this.LoadAllSiteCalendars('')
            },
              error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
                this.isCallApi.next(false)
              });
        } catch (x) {
          this._Global.showErrorMsg(x);
          this._Global.IsReady = true;
          this.spinner.hide()
          this.isCallApi.next(false)
        }
      }
    
      SiteCalendarEdit(siteCalendarId: number, body: SiteCalendar) {
        try {
          this.spinner.show()
          this._Global.IsReady = false;
          this._http.put(myGlobals._apiPath + 'SiteCalendar/Edit/' + siteCalendarId, body, { headers: this._Global.httpOptions })
            .subscribe(data => {
              this.isCallApi.next(true)
              this._Global.showSuccessMsg("Site Calendar Updated Successfully")
              this.spinner.hide()
              this._Global.IsReady = true;
              this.LoadAllSiteCalendars('')
            },
              error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
                this.isCallApi.next(false)
              });
        } catch (x) {
          this._Global.showErrorMsg(x);
          this._Global.IsReady = true;
          this.spinner.hide()
          this.isCallApi.next(false)
        }
      }
    
      SaveAndEditSiteCalendar(siteId: number, body: SiteCalendar, isEdit: boolean) {
        if (isEdit) {
          this.SiteCalendarEdit(siteId, body)
        }
        else {
          this.SaveSiteCalendar(body)
        }
      }
}
