import { SiteRooms } from './../../../../Models/BackModels/ComTrak/Setups/Site';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';

import { Site, SitesStudy, StudySite } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import { Observable } from 'rxjs';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  public searchString: string = ''
  public isSaveSiteRooms = new Subject<boolean>();
  public isSaveSitePIs = new Subject<boolean>();
  public isEditSitePIs = new Subject<boolean>();
  public isCallApi = new Subject<boolean>();
  public sitesStudy = new Subject<Study[]>();
  public _sites: Site[];
  public siteId: number;
  public _sitesArray: Site[];
  public arraylength: number = 0
  public _sitesbyStudy: StudySite[];
  public _studyWiseSites: SitesStudy[];
  public _sitesStudy: Study[];
  public _allSites: Site[];
  public _SitesByStudy: Site[];
  public _activeSites: Site[];
  public _roomsbySite: SiteRooms[];
  public _siteIds: Site[];
  public _alertConfigSiteStudies: Study[] = []
  _sitesActiveStudy: Study[];
  _sitesStudyActive: Study[];
  public _principleInvestigators: any[] = []
  public _principalInvestigatorbySite: any[] = [];
  public _allPrincipalInvestigatorWithSite: any[] = [];
  public _activePrincipleInvestigators: any[] = [];
  public isSave: boolean = false
  public _StudiesBySite: any[] = [];
  public _StudiesBySiteWise: any[] = [];
  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

  LoadSites(searchString: string) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this.isCallApi.next(false)
      this._http.get(myGlobals._apiPath + 'Site?searchString=' + searchString, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._sites = <Site[]>data;
          this._allSites = this._sites.filter(x => x.IsActive);
          this._sitesArray = <Site[]>data;
          this.arraylength = this._sites.length
          this.isCallApi.next(true)
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  GetAllPrincipalInvestigators() {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.get(myGlobals._apiPath + 'Site?type=GetAllPrincipalInvestigators', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._principleInvestigators = <any[]>data;
          this._activePrincipleInvestigators = this._principleInvestigators.filter(o => o.IsActive == true)
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  GetSiteIdsFromSiteRooms(siteId: number): Observable<any> {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'Site?type=GetSiteIdsFromSiteRooms&siteId=' + siteId, { headers: this._Global.httpOptions })

    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  GetSites(searchString: string): Site[] {
    if (this._sites !== undefined && this._sites.length !== null) {
      return this._sites;
    } else {
      this.LoadSites(searchString);
    }
  }

  LoadActiveSites() {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'Site?type=ActiveSites', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._activeSites = <Site[]>data;

          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }
  GetActiveSites(): Site[] {
    if (this._activeSites !== undefined && this._activeSites.length !== null) {
      return this._activeSites;
    } else {
      this.LoadActiveSites();
    }
  }

  GetSiteById(siteId: number) {
    this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + "&type=GetByID", { headers: this._Global.httpOptions })
  }

  GetSitesByStudy(studyId: number) {
    try {
      this._Global.IsReady = false
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'Site?studyId=' + studyId + '&type=GetSitesByStudy', { headers: this._Global.httpOptions }).subscribe(res => {
        this._sitesbyStudy = <StudySite[]>res;
        this.spinner.hide()
        this._Global.IsReady = true
      }, error => {
        this.spinner.hide()
        this._Global.IsReady = true
        this._Global.handleError(error)
      })
    } catch (x) {
    }
  }

  async GetStudyWiseSites(studyId: number) {
    try {
      this._Global.IsReady = false;
      this.spinner.show();
  
      const res = await this._http.get<SitesStudy[]>(myGlobals._apiPath + 'Site?type=GetStudyWiseSites&studyId=' + studyId, { headers: this._Global.httpOptions }).toPromise();
  
      this._studyWiseSites = res;
  
      this.spinner.hide();
      this._Global.IsReady = true;
    } catch (error) {
      this.spinner.hide();
      this._Global.IsReady = true;

      this._Global.handleError(error);
    }
  }
  

  GetRoomsBySiteId(siteId: number) {
    try {
      this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + '&type=GetRoomsBySiteId',
        { headers: this._Global.httpOptions }).subscribe((res: any) => {
          if (res && res.length && res.length > 0) {
            this._roomsbySite = <SiteRooms[]>res;
          }
          else {
            this._roomsbySite = []
          }
        }, error => {
          this._Global.handleError(error)
        })
    } catch (x) {
    }
  }

  GetSiteRoomsBySiteId(siteId: number) {
    return this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + '&type=GetRoomsBySiteId', { headers: this._Global.httpOptions })
  }

  GetPrincipalInvestigatorBySiteId(siteId: number) {
    try {
      this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + '&type=GetSitePrincipalInvestigator',
        { headers: this._Global.httpOptions }).subscribe((res: any) => {
          this._principalInvestigatorbySite = <any[]>res;
        }, error => {
          this._Global.handleError(error)
        })
    } catch (x) {
    }
  }

  GetSitePrincipalInvestigator(siteId: number) {
    return this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + '&type=GetSitePrincipalInvestigator', { headers: this._Global.httpOptions })
  }

  GetAllPrincipalInvestigatorWithSiteId() {
    try {
      this._http.get(myGlobals._apiPath + 'Site?type=GetAllPrincipalInvestigatorsWithSiteId', { headers: this._Global.httpOptions }).subscribe((res: any) => {
        this._allPrincipalInvestigatorWithSite = <any[]>res;
      }, error => {
        this._Global.handleError(error)
      })
    } catch (x) {
    }
  }


  GetStudiesBySite(siteId: number, screenName: string = '') {
    try {
      this._Global.IsReady = false
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + '&type=GetStudiesBySite&screenName=' + screenName, { headers: this._Global.httpOptions }).subscribe(res => {
        this._sitesStudy = <Study[]>res;
        this.sitesStudy.next(this._sitesStudy);
        let dataArray: Study[] = []
        dataArray = this._sitesStudy.filter(o => o.IsActive == true)
        dataArray.forEach(element => {
          this._alertConfigSiteStudies.push({ Id: element.Id, Name: element.Name, SiteId: siteId })
        })
        this._sitesActiveStudy = this._sitesStudy.filter(o => o.IsActive == true)
        this.spinner.hide()
        this._Global.IsReady = true
      }, error => {
        this.spinner.hide()
        this._Global.IsReady = true
        this._Global.handleError(error)
      })
    } catch (x) {
    }
  }

  GetStudiesBySiteId(siteId: number) {
    try {
      this._http.get(myGlobals._apiPath + 'Site?siteId=' + siteId + '&type=GetStudiesBySite', { headers: this._Global.httpOptions }).subscribe(res => {
        this._sitesStudy = <Study[]>res;
        this.sitesStudy.next(this._sitesStudy);
        this._sitesStudyActive = this._sitesStudy.filter(o => o.IsActive == true)
      }, error => {
        this.spinner.hide()
        this._Global.IsReady = true
        this._Global.handleError(error)
      })
    } catch (x) {
    }
  }

  GetStudiesBySites() {
    try {
      this._http.get(myGlobals._apiPath + 'Site?type=GetStudiesBySites', { headers: this._Global.httpOptions }).subscribe(res => {
        this._StudiesBySite = <any[]>res;
      }, error => {
        this.spinner.hide()
        this._Global.IsReady = true
        this._Global.handleError(error)
      })
    } catch (x) {
    }
  }

  GetSitesByPatientLocation(patientId: number): Observable<Study> {
    try {
      return this._http.get(myGlobals._apiPath + 'site?type=SitesByPatientLocation&patientId=' + patientId, { headers: this._Global.httpOptions })
    } catch (x) {
    }
  }

  SaveSites(body: Site, isSaveRoom?: boolean, siteRoom?: SiteRooms[]) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Site/SaveSite', body, { headers: this._Global.httpOptions })
        .subscribe((data: Site) => {
          // if (isSaveRoom) {
          //   siteRoom.forEach(element => {
          //     element.SiteId = data.Id
          //   })
          //   this.SaveSiteRooms(siteRoom)
          // }
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Site Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadSites('')
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  SaveAndEditSites(siteId: number, body: Site, isEdit: boolean, isSaveRoom?: boolean, siteRoom?: SiteRooms[]) {  
    if (isEdit) {
      this.EditSites(siteId, body, isEdit, siteRoom)
    }
    else {
      this.SaveSites(body, isSaveRoom, siteRoom)
    }
  }

  EditSites(siteID: number, body: Site, isEdit?: boolean, siteRoom?: SiteRooms[]) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;

      this._http.put(myGlobals._apiPath + 'Site/UpdateSite?siteId=' + siteID, body, { headers: this._Global.httpOptions })
        .subscribe((data: Site) => {
          if (isEdit) {
            // siteRoom.forEach(element => {
            //   element.SiteId = data.Id
            // })
            // let postBody = {
            //   siteId: siteID,
            //   SiteRooms: siteRoom
            // }
            // siteRoom.SiteId = data.Id
            // this.EditSiteRooms(postBody)
          }
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Site Updated Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadSites('')
          this.LoadActiveSites()
          // this.GetRoomsBySiteId(data.Id)
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  InActiveSiteVisitorFlow(siteID: number, body: Site, message: string) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Site/UpdateSite?siteId=' + siteID, body, { headers: this._Global.httpOptions })
        .subscribe((data: Site) => {
          this.LoadSites('')
          this._Global.showSuccessMsg(message)
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  InActiveSiteRooms(body: SiteRooms, message: string) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Site?Type=InActiveSiteRooms', body, { headers: this._Global.httpOptions })
        .subscribe((data: SiteRooms) => {
          this.GetRoomsBySiteId(data.SiteId)
          this._Global.showSuccessMsg(message)
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  SaveSiteRooms(body: SiteRooms) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Site/SaveSiteRooms', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.GetRoomsBySiteId(this.siteId)
          this._Global.showSuccessMsg("Saved Successfully.")
          this.isSaveSiteRooms.next(true)
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  EditSiteRooms(body: SiteRooms) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Site/EditSiteRoom', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.GetRoomsBySiteId(this.siteId)
          this._Global.showSuccessMsg("Updated Successfully.")
          this.isSaveSiteRooms.next(true)
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  SaveAndEditSiteRooms(body: SiteRooms, isEdit: boolean) {
    if (isEdit) {
      this.EditSiteRooms(body)
    }
    else {
      this.SaveSiteRooms(body)
    }
  }


  CreateSitePrincipalInvestigator(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Site/CreateSitePrincipalInvestigator', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.GetPrincipalInvestigatorBySiteId(this.siteId)
          this._Global.showSuccessMsg("Saved Successfully.")
          this.isSaveSitePIs.next(true)
          this.isSave = true
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  UpdateSitePrincipalInvestigator(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Site/UpdateSitePrincipalInvestigator', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isSave = true
          this.isSaveSitePIs.next(true)
          this.GetPrincipalInvestigatorBySiteId(this.siteId)
          this._Global.showSuccessMsg("Updated Successfully.")
          this.spinner.hide()
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  SaveAndEditPrincipalInvestigator(body: any, isEdit: boolean) {
    if (isEdit) {
      this.UpdateSitePrincipalInvestigator(body)
    }
    else {
      this.CreateSitePrincipalInvestigator(body)
    }
  }

  GetSiteByIdWithAdditionalSiteStates(SiteId?:any){
  //   try {
  //     this.spinner.show()
  //     this._Global.IsReady = false;
  //     this._http.get(myGlobals._apiPath + 'Site?type=GetByID&siteId='+SiteId, { headers: this._Global.httpOptions })
  //       .subscribe(data => {
  //         this._principleInvestigators = <any[]>data;
  //         this._activePrincipleInvestigators = this._principleInvestigators.filter(o => o.IsActive == true)
  //         this.spinner.hide()
  //         this._Global.IsReady = true;
  //       },
  //         error => {
  //           this._Global.handleError(error);
  //           this.spinner.hide()
  //           this._Global.IsReady = true;
  //         });
  //   } catch (x) {
  //     this._Global.showErrorMsg(x);
  //     this._Global.IsReady = true;
  //     this.spinner.hide()
  //   }
  // }
  try {
    return this._http.get(myGlobals._apiPath + 'Site?type=GetByID&siteId='+SiteId, { headers: this._Global.httpOptions })
  } catch (x) {
  }
}
}
