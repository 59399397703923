import { StaticDataService } from "@sharedservices/BackServices/ComTrak/Setups/StaticData.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CampaignService } from "@sharedservices/BackServices/ComTrak/Setups/campaign.service";
import { RecruiterService } from "@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AssignpatienttorecruiterComponent } from "./../../calllists/assignpatienttorecruiter/assignpatienttorecruiter.component";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { CampaignReferralSourceService } from "@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service";
import { StaffService } from "@sharedservices/BackServices/ComTrak/Staff/staff.service";
import { SiteService } from "@sharedservices/BackServices/ComTrak/Setups/site.service";
import { StudyService } from "@sharedservices/BackServices/ComTrak/Setups/study.service";
import { StatusService } from "@sharedservices/BackServices/ComTrak/Setups/status.service";
import { LocationService } from "@sharedservices/BackServices/ComTrak/Setups/location.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subscription, combineLatest } from "rxjs";
import { AssignPatientFuncEnum } from "./assignpatient.enum";

@Component({
  selector: "app-assignpatient",
  templateUrl: "./assignpatient.component.html",
  styleUrls: [
    "./assignpatient.component.scss",
    "../../style.component.scss",
    "../../../assets/comtrak-ui-system-v-0.1/sass/screens/_recuriterpopupDesign.scss",
  ],
})
export class AssignpatientComponent implements OnInit, OnDestroy {
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  fromDate: string = "";
  toDate: string = "";
  submitted: boolean = false;
  assignPatientForm: FormGroup;
  //Race: string[];
  showReAssign: boolean;
  showActionButtons: boolean;
  isFormValid = false;
  maxLength: number;
  storeProcName: string;
  getStoreProcName: string;
  UnAssignCountStoreProcName: string;
  CampaignWiseRefSource: any[] = [];
  CampaignWiseRefSourceAdds: any[] = [];
  SiteWiseStudies: any[] = [];
  isFromMergeProfileList: boolean = false;

  subscriptions = new Subscription();

  screenFunc = AssignPatientFuncEnum;

  constructor(
    public dialogRef: MatDialogRef<AssignpatienttorecruiterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public recruiterService: RecruiterService,
    public campaignService: CampaignService,
    private _Global: GlobalfunctionService,
    private spinner: NgxSpinnerService,
    public StaticDataService: StaticDataService,
    public campaignRefService: CampaignReferralSourceService,
    public staffService: StaffService,
    public siteService: SiteService,
    public studyService: StudyService,
    public statusService: StatusService,
    public locationService: LocationService
  ) {}

  ngOnInit() {
    this.isFromMergeProfileList = this.data.isFromMergeProfileList;
    this.campaignService._patientsCampaignWiseRefSource = [];
    this.campaignService._patientsCampaignRefAdds = [];
    this.staffService.GetStateWiseStaff();
    this.StaticDataService.Gender = this.StaticDataService.Gender.sort((a, b) =>
        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
    if (this.data.screenType == "Assign") {
      // this.storeProcName = "sp_AssignPatients_getLOVs"
      this.storeProcName = "sp_AssignPatients_getLOVs_All";
      // this.getStoreProcName = "sp_GetPatientToAssignedCount"
      this.getStoreProcName = "sp_GetPatientToAssignedCount_All";
      this.UnAssignCountStoreProcName = "sp_GetPatientToRelease";
    } else {
      // this.storeProcName = "sp_ReleasePatients_getLOVs"
      this.storeProcName = "sp_ReleasePatients_getLOVs_All";
      // this.getStoreProcName = "sp_GetPatientCountToRelease"
      this.getStoreProcName = "sp_GetPatientCountToRelease_All";
      this.UnAssignCountStoreProcName = "sp_GetPatientToRelease";
    }
    this.recruiterService.assignPatientCount = 0;
    this.initForm();
    if (!this.isFromMergeProfileList) {
      this.assignPatientForm.controls.fromrecruiterId.setValue(
        this.data.rowData.Id
      );
      this.campaignService.GetPatientsCampaigns(
        this.storeProcName,
        this.assignPatientForm.controls.fromrecruiterId.value
      );
    }
    if (this.data.screenType != "Release") {
      this.maxLength = 3;
    } else {
      this.maxLength = 10000000000000;
    }
    //this.GetAssignPatientCount();
    // this.ChangeSite()
  }

  ngOnInitNew(setValue: boolean) {
    this.initForm();
    this.campaignService._patientsCampaignRefSource = [];
    this.campaignService._patientsCampaignAdDetails = [];
    this.staffService.GetStateWiseStaff();
    this.recruiterService.assignPatientCount = 0;

    if (!this.isFromMergeProfileList) {
      this.assignPatientForm.controls.fromrecruiterId.setValue(
        this.data.rowData.Id
      );
      this.campaignService.GetPatientsCampaigns(
        this.storeProcName,
        this.assignPatientForm.controls.fromrecruiterId.value
      );
      this.assignPatientForm.controls.fromrecruiterId.setValue(
        this.assignPatientForm.controls.fromrecruiterId.value
      );
    } else {
      this.campaignService._patientsCampaignRefSource = [];
      this.campaignService._patientsCampaignAdDetails = [];
      this.campaignService._patientsCampaign = [];
    }

    if (this.data.screenType != "Release") {
      this.maxLength = 3;
    } else {
      this.maxLength = 10000000000000;
    }
    this.assignPatientForm.controls.isReAssign.setValue(setValue);
  }

  initForm() {
    this.assignPatientForm = this.fb.group({
      recruiterId: [""],
      campaignId: [""],
      campaignrefId: [""],
      fromage: [""],
      toage: [""],
      gender: [""],
      numberOfPatients: ["", Validators.required],
      remainingPatient: [0],
      remainingUnAssignPatient: [0],
      isReAssign: [false],
      fromrecruiterId: [""],
      siteId: [""],
      studyId: [""],
      statusId: [""],
      stateId: [""],
    });

    if (this.isFromMergeProfileList) {
      this.assignPatientForm.controls["fromrecruiterId"].setValidators([
        Validators.required,
      ]);
    }

    this.assignPatientForm.valueChanges.subscribe((form) => {
      if (form) {
        let fields = [
          "campaignId",
          "campaignrefId",
          "fromage",
          "toage",
          "gender",
          "siteId",
          "studyId",
          "statusId",
          "stateId",
        ];

        if (this.isFromMergeProfileList) {
          fields.push("fromrecruiterId");
        }
        this.atLeastOneHasValue(fields);
      }
    });
  }

  private atLeastOneHasValue(fields: Array<string>) {
    for (const fieldName of fields) {
      if (
        Array.isArray(this.assignPatientForm.get(fieldName).value) &&
        this.assignPatientForm.get(fieldName).value.length > 1
      ) {
        this.isFormValid = true;
        return;
      } else if (this.assignPatientForm.get(fieldName).value) {
        this.isFormValid = true;
        return;
      }

      this.isFormValid = false;
    }
  }

  isDateValid() {
    var toDate = new Date(this.toDate);
    toDate.setHours(0, 0, 0, 0);
    var fromDate = new Date(this.fromDate);
    fromDate.setHours(0, 0, 0, 0);
    if (this.fromDate == "" && this.toDate == "") {
      return true;
    } else if (this.fromDate == "" && this.toDate != "") {
      this._Global.showErrorMsg("Please Select FromDate !");
      return false;
    } else if (this.fromDate != "" && this.toDate == "") {
      this._Global.showErrorMsg("Please Select ToDate !");
      return false;
    } else if (toDate < fromDate && this.fromDate != "") {
      this._Global.showErrorMsg("ToDate Cannot Be Less Then FromDate !");
      return false;
    } else if (fromDate > toDate && this.toDate != "") {
      this._Global.showErrorMsg("FromDate Cannot Be Greater Then ToDate !");
      return false;
    } else {
      return true;
    }
  }

  ChangeFromRecruiter() {
    this.locationService.getRecruiterWiseStates(
      this.assignPatientForm.controls.fromrecruiterId.value
    );
    this.campaignService.GetPatientsCampaigns(
      this.storeProcName,
      this.assignPatientForm.controls.fromrecruiterId.value
    );
    this.campaignService._patientsCampaignRefSource = [];
    this.campaignService._patientsCampaignAdDetails = [];
    this.campaignService._patientsCampaign = [];
  }

  ChangeReAssign() {
    this.campaignService._patientsCampaignWiseRefSource = [];
    this.campaignService._patientsCampaignRefAdds = [];
    this.showActionButtons = false;
    if (this.assignPatientForm.controls.isReAssign.value == true) {
      // this.storeProcName = "sp_ReleasePatients_getLOVs"
      this.storeProcName = "sp_ReleasePatients_getLOVs_All";
      // this.getStoreProcName = "sp_GetPatientCountToRelease"
      this.getStoreProcName = "sp_GetPatientCountToRelease_All";
      console.log(this.getStoreProcName);
      this.assignPatientForm.controls["recruiterId"].setValidators([
        Validators.required,
      ]);
      this.showReAssign = true;
      this.UnAssignCountStoreProcName = "sp_GetPatientToRelease";
    } else {
      // this.storeProcName = "sp_AssignPatients_getLOVs"
      this.storeProcName = "sp_AssignPatients_getLOVs_All";
      // this.getStoreProcName = "sp_GetPatientToAssignedCount"
      this.getStoreProcName = "sp_GetPatientToAssignedCount_All";
      this.showReAssign = false;
      this.assignPatientForm.controls["recruiterId"].setValidators(null);
      this.UnAssignCountStoreProcName = "sp_GetPatientToRelease";
    }
    this.assignPatientForm.controls["remainingUnAssignPatient"].setValue(0);
    this.ngOnInitNew(this.assignPatientForm.controls.isReAssign.value);
    if (this.assignPatientForm.controls.isReAssign.value == true) {
      this.assignPatientForm.controls["recruiterId"].setValidators([
        Validators.required,
      ]);
      this.maxLength = 10000000000000;
    } else {
      this.assignPatientForm.controls["recruiterId"].setValidators(null);
      this.maxLength = 3;
    }
    this.assignPatientForm.controls["recruiterId"].updateValueAndValidity();
  }

  // ChangeCampaign() {
  // this.assignPatientForm.controls.campaignrefId.setValue("")
  // this.assignPatientForm.controls.campaignrefAdDetailId.setValue("")
  // this.campaignService._patientsCampaignRefSource = []
  // this.campaignService.GetPatientsCampaignsRefSource(this.storeProcName, this.data.rowData.Id, this.assignPatientForm.controls.campaignId.value);
  // }

  // ChangeCampaignReferralSource() {
  // this.assignPatientForm.controls.campaignrefAdDetailId.setValue("")
  // this.campaignService._patientsCampaignAdDetails = []
  // this.campaignService.GetPatientsCampaignsAdDetails(this.storeProcName, this.data.rowData.Id, this.assignPatientForm.controls.campaignId.value, this.assignPatientForm.controls.campaignrefId.value);
  // }

  GetPatientCount(): void {
    if (
      !this.isFormValid ||
      (this.isFromMergeProfileList &&
        this.assignPatientForm.controls["fromrecruiterId"].invalid)
    )
      return;

    this.spinner.show();
    this._Global.IsReady = false;
    this.showActionButtons = false;

    if (
      this.isFromMergeProfileList &&
      !this.assignPatientForm.controls.isReAssign.value
    ) {
      this.GetAssignPatientCount();

      const subscription = this.recruiterService.getPatientCount.subscribe(
        (data) => {
          if (data) {
            this.assignPatientForm.controls.remainingPatient.setValue(
              this.recruiterService.assignPatientCount
            );

            this._Global.IsReady = true;
            this.spinner.hide();
            this.showActionButtons = true;
          } else {
            this.assignPatientForm.controls.remainingPatient.setValue(0);
          }
        }
      );

      this.subscriptions.add(subscription);
    } else if (
      !this.isFromMergeProfileList &&
      !this.assignPatientForm.controls.isReAssign.value
    ) {
      this.GetAssignPatientCount();
      this.GetPatientsToReleaseCount();

      const getPatientCount$ = this.recruiterService.getPatientCount;
      const getPatientUnAssignCount$ =
        this.recruiterService.getPatientUnAssignCount;

      const dataToGet = [getPatientCount$, getPatientUnAssignCount$];
      this.subscriptions.add(getPatientCount$);
      this.subscriptions.add(getPatientUnAssignCount$);

      combineLatest(dataToGet).subscribe((data: [any, any]): void =>
        this.onDataLoad(data)
      );
    } else {
      this.GetPatientsToReleaseCount();

      const subscription =
        this.recruiterService.getPatientUnAssignCount.subscribe((data) => {
          if (data) {
            this.assignPatientForm.controls.remainingPatient.setValue(
              this.recruiterService.UnassignPatientCount
            );

            this._Global.IsReady = true;
            this.spinner.hide();
            this.showActionButtons = true;
          } else {
            this.assignPatientForm.controls.remainingPatient.setValue(0);
          }
        });

      this.subscriptions.add(subscription);
    }
  }

  GetAssignPatientCount() {
    //debugger
    // if (this.isFromMergeProfileList == true && this.isDateValid() == false) {
    //   return;
    // }
    this.assignPatientForm.controls.numberOfPatients.setValue("");
    this.recruiterService.GetPatientCount(
      this.assignPatientForm.controls.fromrecruiterId.value,
      this.assignPatientForm.controls.campaignId.value,
      this.assignPatientForm.controls.campaignrefId.value,
      this.assignPatientForm.controls.stateId.value,
      this.assignPatientForm.controls.siteId.value,
      this.assignPatientForm.controls.studyId.value,
      this.assignPatientForm.controls.statusId.value,
      this.assignPatientForm.controls.gender.value,
      this.assignPatientForm.controls.fromage.value,
      this.assignPatientForm.controls.toage.value,
      this.data && this.data.isFromMergeProfileList ? true : false,
      this.getStoreProcName
    );
  }

  GetPatientsToReleaseCount() {
    if (
      this.isFromMergeProfileList &&
      !this.assignPatientForm.controls.isReAssign.value
    ) {
      this.recruiterService.getPatientUnAssignCount.next(true);
      return;
    }
    this.assignPatientForm.controls.numberOfPatients.setValue("");
    this.recruiterService.GetPatientsToReleaseCount(
      this.assignPatientForm.controls.campaignId.value,
      this.assignPatientForm.controls.campaignrefId.value,
      this.assignPatientForm.controls.fromage.value,
      this.assignPatientForm.controls.toage.value,
      this.assignPatientForm.controls.gender.value,
      this.assignPatientForm.controls.fromrecruiterId.value,
      this.UnAssignCountStoreProcName,
      this.assignPatientForm.controls.siteId.value,
      this.assignPatientForm.controls.studyId.value,
      this.assignPatientForm.controls.statusId.value,
      this.assignPatientForm.controls.stateId.value,
      this.data && this.data.isFromMergeProfileList ? true : false
    );
  }

  onDataLoad([assignPatientCountFlag, remainingPatientCountFlag]): void {
    if (assignPatientCountFlag) {
      this.assignPatientForm.controls.remainingPatient.setValue(
        this.recruiterService.assignPatientCount
      );
    } else {
      this.assignPatientForm.controls.remainingPatient.setValue(0);
    }

    if (remainingPatientCountFlag) {
      this.assignPatientForm.controls.remainingUnAssignPatient.setValue(
        this.recruiterService.UnassignPatientCount
      );
    } else {
      this.assignPatientForm.controls.remainingUnAssignPatient.setValue(0);
    }

    if (assignPatientCountFlag && remainingPatientCountFlag) {
      this._Global.IsReady = true;
      this.spinner.hide();
      this.showActionButtons = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.assignPatientForm.controls;
  }

  MinusNumberOfPatients() {
    this.assignPatientForm.controls.remainingPatient.setValue(
      this.recruiterService.assignPatientCount
    );
    if (
      this.assignPatientForm.controls.numberOfPatients.value >
      this.assignPatientForm.controls.remainingPatient.value
    ) {
      this._Global.showErrorMsg(
        "# of Patients to Assign cannot be greater than # of Remaining Patients"
      );
      return;
    } else {
      if (
        this.assignPatientForm.controls.numberOfPatients.value == "" ||
        this.assignPatientForm.controls.numberOfPatients.value == 0
      ) {
        this.assignPatientForm.controls.remainingPatient.setValue(
          this.recruiterService.assignPatientCount
        );
      } else {
        let value =
          this.assignPatientForm.controls.remainingPatient.value -
          this.assignPatientForm.controls.numberOfPatients.value;
        this.assignPatientForm.controls.remainingPatient.setValue(value);
      }
    }
  }

  onSubmit(storeProcName: string, screenFunc: string) {
    this.submitted = true;
    if (this.assignPatientForm.invalid) {
      return;
    } else if (
      this.isFromMergeProfileList == true &&
      this.isDateValid() == false
    ) {
      return;
    } else {
      let formValue: any = this.assignPatientForm.value;
      if (
        formValue.numberOfPatients > 100 &&
        this.data.screenType != "Release"
      ) {
        this._Global.showErrorMsg(
          "# of patients should not be greater than 100"
        );
        return;
      }
      if (
        formValue.campaignId == "" &&
        formValue.campaignrefId == "" &&
        formValue.fromage == "" &&
        formValue.toage == "" &&
        formValue.gender == "" &&
        formValue.siteId == "" &&
        formValue.studyId == "" &&
        formValue.statusId == "" &&
        formValue.stateId == ""
      ) {
        this._Global.showErrorMsg("Select atleast one filter");
        return;
      }
      if (this.assignPatientForm.controls.numberOfPatients.value == "0") {
        this._Global.showErrorMsg(
          "# of Patients to Assign is not equal to Zero (0)"
        );
        return;
      }

      if (
        screenFunc === AssignPatientFuncEnum.Assign &&
        this.assignPatientForm.controls.numberOfPatients.value >
          this.assignPatientForm.controls.remainingPatient.value
      ) {
        this._Global.showErrorMsg(
          "# of Patients to assign cannot be greater than # of Remaining Patients"
        );
        return;
      } else if (
        screenFunc === AssignPatientFuncEnum.Release &&
        this.assignPatientForm.controls.numberOfPatients.value >
          this.assignPatientForm.controls.remainingUnAssignPatient.value
      ) {
        this._Global.showErrorMsg(
          "# of Patients to release cannot be greater than # of available patients"
        );
        return;
      } else if (
        screenFunc === AssignPatientFuncEnum.ReAssign &&
        this.assignPatientForm.controls.numberOfPatients.value >
          this.assignPatientForm.controls.remainingPatient.value
      ) {
        this._Global.showErrorMsg(
          "# of Patients to re-assign cannot be greater than # of available patients"
        );
        return;
      }

      // if (this.data.screenType == "Release") {
      //   this.recruiterService.ReleaseRecruiterPatients(
      //     this.assignPatientForm.controls.numberOfPatients.value,
      //     this.assignPatientForm.controls.fromrecruiterId.value,
      //     this.assignPatientForm.controls.campaignId.value,
      //     this.assignPatientForm.controls.campaignrefId.value,
      //     this.assignPatientForm.controls.campaignrefAdDetailId.value,
      //     this.assignPatientForm.controls.fromage.value,
      //     this.assignPatientForm.controls.toage.value,
      //     this.assignPatientForm.controls.gender.value,
      //     this.assignPatientForm.controls.race.value,
      //     this.assignPatientForm.controls.ethnicity.value,
      //     this.assignPatientForm.controls.siteId.value,
      //     this.assignPatientForm.controls.studyId.value,
      //     this.assignPatientForm.controls.statusId.value,
      //     this.assignPatientForm.controls.stateId.value
      //   );
      //   this.recruiterService.isCallApi.subscribe((res) => {
      //     if (res) {
      //       this.onNoClick();
      //     }
      //   });
      // }
      //else {
      this._Global.IsReady = false;
      this.spinner.show();
      this.recruiterService
        .AssignOrReAssignPatients(
          this.assignPatientForm.controls.numberOfPatients.value,
          this.assignPatientForm.controls.recruiterId.value,
          this.assignPatientForm.controls.campaignId.value,
          this.assignPatientForm.controls.campaignrefId.value,
          this.assignPatientForm.controls.fromage.value,
          this.assignPatientForm.controls.toage.value,
          this.assignPatientForm.controls.gender.value,
          storeProcName,
          this.assignPatientForm.controls.fromrecruiterId.value,
          this.assignPatientForm.controls.siteId.value,
          this.assignPatientForm.controls.studyId.value,
          this.assignPatientForm.controls.statusId.value,
          this.assignPatientForm.controls.stateId.value,
          this.data && this.data.isFromMergeProfileList ? true : false
        )
        .subscribe(
          (res) => {
            this._Global.IsReady = true;
            this.spinner.hide();
            this.recruiterService.isCallApi.next(true);
            if (
              storeProcName == "sp_GetPatientToAssigned_All" ||
              storeProcName === "sp_ReleasePatients"
            ) {
              const msg = "Successfuly " + screenFunc + "ed patients";
              this._Global.showSuccessMsg(msg);
            } else {
              this._Global.showSuccessMsg(
                "Successfuly Re-assigned patients to recruiter"
              );
            }
            this.onNoClick();
          },
          (error) => {
            this._Global.IsReady = true;
            this.spinner.hide();
            this.recruiterService.isCallApi.next(false);
            this._Global.showErrorMsg(
              error.error && error.error.Value && error.error.Value.status
                ? error.error.Value.status
                : error
            );
          }
        );
    }
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[(0-9)]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  // ChangeSite() {
  //   this.assignPatientForm.controls.studyId.setValue('');
  //   if (this.assignPatientForm.controls.siteId.value == null || this.assignPatientForm.controls.siteId.value == "") {
  //     this.siteService._sitesStudyActive = this.studyService._activeStudies;
  //   } else {
  //     this.siteService.GetStudiesBySiteId(this.assignPatientForm.controls.siteId.value)
  //   }
  // }

  onItemSelectCampaign(event: any) {
    this.campaignService._patientsCampaignWiseRefSource = [];
    if (event && event.length && event.length > 0) {
      event.forEach((item) => {
        this.campaignService._patientsCampaignRefSource
          .filter((o) => o.CampaignId == item.Id)
          .forEach((element) => {
            this.CampaignWiseRefSource.push({
              Id: element.Id,
              Name: element.Name,
              CampaignId: item.Id,
            });
          });
      });
    } else {
      this.campaignService._patientsCampaignRefSource
        .filter((o) => o.CampaignId == event.Id)
        .forEach((element) => {
          this.CampaignWiseRefSource.push({
            Id: element.Id,
            Name: element.Name,
            CampaignId: event.Id,
          });
        });
    }
    setTimeout(() => {
      this.campaignService._patientsCampaignWiseRefSource =
        this.CampaignWiseRefSource;
    }, 300);
  }

  onItemDeSelectCampaign(event: any) {
    if (event.length == 0) {
      this.assignPatientForm.controls.campaignrefId.setValue("");
      this.CampaignWiseRefSource = [];
      this.campaignService._patientsCampaignWiseRefSource = [];
      this.CampaignWiseRefSourceAdds = [];
      this.campaignService._patientsCampaignRefAdds = [];
      this.assignPatientForm.controls.campaignrefAdDetailId.setValue("");
    } else {
      setTimeout(() => {
        this.campaignService._patientsCampaignWiseRefSource =
          this.campaignService._patientsCampaignWiseRefSource.filter(
            (o) => o.CampaignId != event.Id
          );
      }, 300);
    }
  }

  onItemSelectCampaignRef(event: any) {
    this.campaignService._patientsCampaignRefAdds = [];
    if (event && event.length && event.length > 0) {
      event.forEach((item) => {
        this.campaignService._patientsCampaignAdDetails
          .filter((o) => o.CampaignRefID == item.Id)
          .forEach((element) => {
            this.CampaignWiseRefSourceAdds.push({
              Id: element.Id,
              Name: element.Name,
              CampaignRefID: item.Id,
            });
          });
      });
    } else {
      this.campaignService._patientsCampaignAdDetails
        .filter((o) => o.CampaignRefID == event.Id)
        .forEach((element) => {
          this.CampaignWiseRefSourceAdds.push({
            Id: element.Id,
            Name: element.Name,
            CampaignRefID: event.Id,
          });
        });
    }
    setTimeout(() => {
      this.campaignService._patientsCampaignRefAdds =
        this.CampaignWiseRefSourceAdds;
    }, 300);
  }

  onItemDeSelectCampaignRef(event: any) {
    if (event.length == 0) {
      this.assignPatientForm.controls.campaignrefAdDetailId.setValue("");
      this.CampaignWiseRefSourceAdds = [];
      this.campaignService._patientsCampaignRefAdds = [];
    } else {
      setTimeout(() => {
        this.campaignService._patientsCampaignRefAdds =
          this.campaignService._patientsCampaignRefAdds.filter(
            (o) => o.CampaignRefID != event.Id
          );
      }, 300);
    }
  }

  onItemSelectSite(event: any) {
    ////debugger
    this.siteService._StudiesBySiteWise = [];
    if (event && event.length && event.length > 0) {
      event.forEach((item) => {
        this.siteService._StudiesBySite
          .filter((o) => o.SiteId == item.Id)
          .forEach((element) => {
            this.SiteWiseStudies.push({
              Id: element.Id,
              Name: element.Name,
              SiteId: item.Id,
            });
          });
      });
    } else {
      this.siteService._StudiesBySite
        .filter((o) => o.SiteId == event.Id)
        .forEach((element) => {
          this.SiteWiseStudies.push({
            Id: element.Id,
            Name: element.Name,
            SiteId: event.Id,
          });
        });
    }
    setTimeout(() => {
      this.siteService._StudiesBySiteWise = this.SiteWiseStudies;
    }, 300);
  }

  onItemDeSelectSite(event: any) {
    ////debugger
    if (event.length == 0) {
      this.assignPatientForm.controls.studyId.setValue("");
      this.SiteWiseStudies = [];
      this.siteService._StudiesBySiteWise = [];
    } else {
      setTimeout(() => {
        this.siteService._StudiesBySiteWise =
          this.siteService._StudiesBySiteWise.filter(
            (o) => o.SiteId != event.Id
          );
      }, 300);
    }
  }

  Increment(control: AbstractControl) {
    let num: number;
    num = +control.value + 1;
    control.setValue(num);
    control.markAsDirty();
  }

  Decrement(control: AbstractControl) {
    let num: number;
    num = +control.value - 1;
    if (num >= 1 && num <= 100) {
      control.setValue(num);
      control.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    //if (this.subscriptions) this.subscriptions.unsubscribe();
  }
}
