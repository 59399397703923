import { Country, State, City } from './../../../../Models/BackModels/ComTrak/Setups/Country';
import { SiteRooms } from './../../../../Models/BackModels/ComTrak/Setups/Site';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';

import { Site, SitesStudy, StudySite } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import { Observable } from 'rxjs';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';


@Injectable({
    providedIn: 'root'
})
export class LocationService {
    public _Country: Country[];
    public _State: State[];
    public _City: City[];
    public _MetroArea:any[];
    public isCallApi = new Subject<boolean>();
    public _StateArray: State[];
    public _CityArray: City[];
    public _ActiveStateArray: State[];
    public _leadsStateArray: any[] = [];
    _recruiterWiseState: any[] = [];
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

    getAllCountry() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Location?getType=getAllCountry', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._Country = <Country[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getAllState() {

        if (this._StateArray !== undefined && this._StateArray.length > 0) {
            return this._StateArray;
        }
        else {
            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this._http.get(myGlobals._apiPath + 'Location?getType=getAllState', { headers: this._Global.httpOptions })
                    .subscribe(data => {
                        this._StateArray = <State[]>data;
                        this._ActiveStateArray = this._StateArray.filter(o => o.Active == true)
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    },
                        error => {
                            this._Global.handleError(error);
                            this.spinner.hide()
                            this._Global.IsReady = true;
                        });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
            }
        }
    }

    GetAllLeadsState() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Location?getType=GetAllLeadsState', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._leadsStateArray = <any[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getAllCity() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Location?getType=getAllCity', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._CityArray = <City[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getRecruiterWiseStates(recruiterId: number) {
        try {
            // this.spinner.show()
            // this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Location?getType=getRecruiterWiseStates&recruiterId=' + recruiterId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._recruiterWiseState = <any[]>data;
                    // this.spinner.hide()
                    // this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getStatebyCountry(countryId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'Location?getType=getStatebyCountry&countryId=' + countryId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._State = <State[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(true)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getCitybyState(stateId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Location?getType=getCitybyState&stateId=' + stateId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._City = <City[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    async getMetroAreabyStateAsync(stateId: number): Promise<any[]> {
        const url = `${myGlobals._apiPath}Location?getType=getMetroByState&stateId=${stateId}`;
        this.spinner.show();
        this._Global.IsReady = false;
    
        try {
          const data = await this._http.get<any[]>(url, { headers: this._Global.httpOptions }).toPromise();
          this._MetroArea = data;
          return data;
        } catch (error) {
          this._Global.handleError(error);
          throw error;
        } finally {
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      }
}
