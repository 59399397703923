import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-close-recruiter-assignment-prompt',
  templateUrl: './close-recruiter-assignment-prompt.component.html',
  styleUrls: ['./close-recruiter-assignment-prompt.component.css']
})
export class CloseRecruiterAssignmentPromptComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CloseRecruiterAssignmentPromptComponent>) { }

  ngOnInit() {
  }
  onClickYes() {

    this.dialogRef.close('yes');
  }

  onClickNo() {
    this.dialogRef.close('no');
  }

}
