import { Component, Inject, OnInit } from '@angular/core';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { VaccineService } from '@sharedservices/BackServices/ComTrak/Setups/vaccine.service';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { CommandClickEventArgs, CommandModel } from '@syncfusion/ej2-angular-grids';
import { ActionType } from '@sharedmodels/DMCREnums/DMCREnums';
import { ColDef } from 'ag-grid-community';
import { CreateManufacturerComponent } from '../createmanufacturer/createmanufacturer.component';
import { CreateIndicationComponent } from '../createindication/createindication.component';
import { DatePipe } from '@angular/common';
import { DATE_UTC_NFORMAT, DATE_UTC_PFORMAT, OnlyTextNumberPattern, OnlyTextSpecialattern, OnlyNumberPattern, FormValidationMessages } from '@shared/Constants';
@Component({
  selector: 'app-createvaccine',
  templateUrl: './createvaccine.component.html',
  styleUrls: ['./createvaccine.component.scss', '../../style.component.scss'],
  // providers: [DatePipe],
})
export class CreatevaccineComponent implements OnInit {
  format: string = 'MM/dd/yyyy';
  VaccineForm: FormGroup
  submitted: boolean = false;
  public _data: Study;
  nestedDetailTargetError: boolean = false;
  isedit: boolean = false;
  isIndicationEnable: boolean = false;
  dropdownList = [];
  selectedItems = [];
  columnDefs: ColDef[]
  public gridApi: any;
  public gridColumnApi: any;
  public defaultColDef;
  columnDefinition: CustomGridColumns[]
  public commands: CommandModel[];
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Value',
    textField: 'Text',
    allowSearchFilter: true,
    enableCheckAll: false,


  };
  dropdownSettingsDoses: IDropdownSettings = {
    singleSelection: true,
    idField: 'item',
    textField: 'item',
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  _VaccineTypes: any[];
  _Manufacturers: any[];
  _Indicators: any[];
  _Doses: any[];
  _vaccines: any[];
  showRecord: boolean = false;
  PatientName: string;
  Address: string;
  _days: string;
  public _isCovid: boolean;

  IsApproximateDate = false;

  constructor(
    public dialogRef: MatDialogRef<CreatevaccineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    private _Global: GlobalfunctionService,
    public vaccineService: VaccineService,
    public dialog: MatDialog,
    public datePipe: DatePipe) {
    this.getDose();
    this.commands = [
      { title: 'Edit Vaccine', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-edit e-icons' } },
      //{ title: 'Add Study Visit', type: 'Delete', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-circle-add' } },
    ];
  }
  commandClick(args: CommandClickEventArgs): void {
    if (args.commandColumn.buttonOption.iconCss == ActionType.Edit) {
      //
    }
  }
  ngOnInit() {
    this._Global.gotoTop();
    this.initForm()

    this.getManufacturers()
    this.getIndicators()

    if (this.data.callList != undefined) {
      this.GetData();
    }
  }
  GetData() {
    this.VaccineForm.controls.PatientId.setValue(this.data.callList.Id)
    this.VaccineForm.controls.PatientName.setValue(this.data.callList.FullName)
    let patientDetails = this.data.callList.Id + " - " + this.data.callList.FullName;
    patientDetails += this.data.callList.Gender != '' && this.data.callList.Gender != null ? " | " + this.data.callList.Gender : "";
    patientDetails += this.data.callList.DateOfBirth != '' && this.data.callList.DateOfBirth != null ? " | " + this.data.callList.DateOfBirth : "";
    patientDetails += this.data.callList.Age != 0 && this.data.callList.Age != null ? " | " + this.data.callList.Age : "";
    this.PatientName = patientDetails;
    this.Address = this.data.callList.Address
    this.LoadAllVaccines(this.data.callList.Id);
  }
  get f() {
    return this.VaccineForm.controls
  }
  initForm() {
    this.VaccineForm = this.fb.group({
      Id: [0],
      VaccinationName: ['', [Validators.required, Validators.pattern(/^.*\S.*$/)]],
      VaccinationDate: ['', Validators.required],
      PatientId: [0, Validators.required],
      SelectedIndication: [[], Validators.required],
      SelectedManufacturer: [[]],
      SelectedDose: [[1]],
      IndicationId: [0],
      ManufacturerId: [0],
      Comments: [''],
      DoseNo: [1],
      PatientName: ['', Validators.required],
      IsApproximateDate: [0],
      Days: ['']

    })
  }

  onNoooClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    if (this.CheckVaccineDateEmpty() == false) {
      return;
    }
    if (this.VaccineDateValidation() == false) {
      this.checkVaccineDateValidation(true);
      return;
    }
    else if (this._days == "") {
      this.checkVaccineDateValidation(false);
      return;
    }
    if (this.VaccineForm.invalid) {
      this.checkFormValidation()
      return;
    }
    else {
      if (this.VaccineForm.controls.SelectedManufacturer.value != null && this.VaccineForm.controls.SelectedManufacturer.value != undefined && this.VaccineForm.controls.SelectedManufacturer.value.length > 0) {
        this.VaccineForm.controls.ManufacturerId.setValue(this.VaccineForm.controls.SelectedManufacturer.value[0].Value)
      }
      else {
        this.VaccineForm.controls.ManufacturerId.setValue(null)
      }
      this.VaccineForm.controls.IndicationId.setValue(this.VaccineForm.controls.SelectedIndication.value[0].Value)
      this.vaccineService.SaveAndEditVaccine(this.VaccineForm.value, this.isedit)
      this.vaccineService.isCallApi.subscribe(res => {
        if (res) {
          this.isedit = false
          if (this.data.callList != undefined) {
            this.LoadAllVaccines(this.data.callList.Id);
            this.clearFields();
            this._isCovid = false;
          }
        }
      })
    }
  }

  VaccineDateValidation() {
    let vaccineDate = this.VaccineForm.controls.VaccinationDate.value;
    let currentDate = new Date();
    if (vaccineDate.includes("/")) {
      let dateArray = vaccineDate.split("/");
      if (dateArray.length == 2) {
        let month = dateArray[0]
        let year = dateArray[1]
        if (month <= 12 && month.length == 2 && year > 1970 && year.length == 4) {
          this._days = year + "-" + month + DATE_UTC_NFORMAT
          if (this.CalculateDiff() >= 0) {
            this._days = year + "-" + month + DATE_UTC_NFORMAT;
            this.VaccineForm.controls.IsApproximateDate.setValue(true);
            return true;
          }
          else {
            this._days = ""
            this.VaccineForm.controls.Days.setValue("");
            this.VaccineForm.controls.IsApproximateDate.setValue(true);
            return true;
          }
        }
        else {

          return false;
        }
      }
      else if (dateArray.length == 3) {
        let month = dateArray[0]
        let date = dateArray[1]
        let year = dateArray[2]
        if (date <= 31 && date.length == 2 && month <= 12 && month.length == 2 && year > 1970 && year.length == 4) {
          this._days = year + "-" + month + "-" + date + DATE_UTC_PFORMAT
          if (this.CalculateDiff() >= 0) {
            this._days = year + "-" + month + "-" + date + DATE_UTC_PFORMAT
            this.VaccineForm.controls.IsApproximateDate.setValue(false);
            return true;
          }
          else {
            this._days = ""
            this.VaccineForm.controls.Days.setValue("");
            this.VaccineForm.controls.IsApproximateDate.setValue(false);
            return true;
          }
        }
        else {

          return false;
        }
      }
      else {

        return false;
      }
    }
    else { return false; }
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[0-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytext(event): boolean {
    var result = OnlyNumberPattern.test(event.key);
    return result;
  }

  Onlytextnumber(event): boolean {
    var result = OnlyTextNumberPattern.test(event.key);
    return result;
  }
  Onlytextspecail(event): boolean {
    var result = OnlyTextSpecialattern.test(event.key);
    return result;
  }
  checkFormValidation() {
    this._Global.showSuccessMsg(FormValidationMessages.allFiledsRequired)
    this._Global.IsReady = true;
  }
  checkVaccineDateValidation(isInvalid: boolean) {
    let errorMessage = ""
    if (isInvalid) {
      errorMessage = FormValidationMessages.invalidVaccineDateFormat
    }
    else {
      errorMessage = FormValidationMessages.invalidVaccineDate;
    }
    this._Global.showSuccessMsg(errorMessage)
    this._Global.IsReady = true;
  }
  getManufacturers() {
    this.vaccineService.LoadManufacturers();
  }

  getIndicators() {
    this.vaccineService.LoadIndications()
  }

  getDose() {
    this._Doses = Array(20).fill(0).map((x, i) => i + 1);
  }
  gridConfig() {
    this.columnDefinition = [
      {
        header: 'Vaccine',
        key: "VaccinationName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Type',
        key: "ManufacturerName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Indication',
        key: "IndicationName",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Date',
        key: "VaccineDate",
        width: 180,
        visible: true,
        type: "string",
      },
      {
        header: 'Dose',
        key: "DoseNo",
        width: 90,
        visible: true,
        type: "string",
      },
      {
        header: 'Days',
        key: "Days",
        width: 90,
        visible: true,
        type: "string",
      }
    ]
  }
  late() {
    setTimeout(() => {
      this.showRecord = true
    }, 2000);
  }


  LoadAllVaccines(patientId: number) {
    this.vaccineService.LoadAllVaccines('', patientId).subscribe(res => {
      this._vaccines = <any[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }
  SDose: any[];
  SIndication: any[];
  SManufacturer: any[];

  EditVaccine(row: any) {
    this.vaccineService.GetVaccineByID(row.Id).subscribe(res => {
      this.VaccineForm.patchValue(res);
      if (res.IsApproximateDate) {
        this.VaccineForm.controls.VaccinationDate.setValue(res.VaccinationDate.slice(5, 7) + "/" + res.VaccinationDate.slice(0, 4))
      }
      else {
        this.VaccineForm.controls.VaccinationDate.setValue(res.VaccinationDate.slice(5, 7) + "/" + res.VaccinationDate.slice(8, 10) + "/" + res.VaccinationDate.slice(0, 4))
      }
      this.isedit = true;
    }, error => {
      this._Global.handleError(error);
    })

  }
  getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }
  clearFields() {
    let PatientName = this.VaccineForm.controls.PatientName.value
    let PatientId = this.VaccineForm.controls.PatientId.value
    this.VaccineForm.reset();
    this.initForm();
    this.isedit = false;
    this.VaccineForm.controls.PatientName.setValue(PatientName);
    this.VaccineForm.controls.PatientId.setValue(PatientId);
  }
  Increment() {
    let num: number
    num = this.VaccineForm.controls.DoseNo.value + 1
    if(num<100){
    this.VaccineForm.controls.DoseNo.setValue(num);
    }
  }
  Decrement() {
    let num: number
    num = this.VaccineForm.controls.DoseNo.value - 1
    if (num >= 1 && num <= 99) {
      this.VaccineForm.controls.DoseNo.setValue(num)
    }

  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode == 47 || charCode == 191)) {
      return true;
    }
    else if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  CreateManufacturerDialog(row) {
    const dialogRef = this.dialog.open(CreateManufacturerComponent, {
      panelClass: 'Createstudyvisitdailogwidth',
      disableClose: true,
      data: {
        callList: row,
        actionType: ''
      }
    })
  }
  CreateIndicationDialog(row) {
    const dialogRef = this.dialog.open(CreateIndicationComponent, {
      panelClass: 'Createstudyvisitdailogwidth',
      disableClose: true,
      data: {
        callList: row,
        actionType: ''
      }
    })
  }
  OpenManufacturerDialog() {
    this.CreateManufacturerDialog("Manufacturer")

  }
  OpenIndicationDialog() {
    this.CreateIndicationDialog('Indication')

  }
  IsApproximateDateChange() {
    this.VaccineForm.controls.IsApproximateDate.setValue(true);
  }
  GetDaysFromVaccineDate() {
    if (this.VaccineDateValidation()) {
      if (this._days == "") {
        this.checkVaccineDateValidation(false);
        return;
      }
      else {
        let noOfdays = this.CalculateDiff();
        if (this.VaccineForm.controls.IsApproximateDate != undefined && this.VaccineForm.controls.IsApproximateDate != null && this.VaccineForm.controls.IsApproximateDate.value) {
          this.VaccineForm.controls.Days.setValue(noOfdays + "*")
        }
        else {
          this.VaccineForm.controls.Days.setValue(noOfdays)
        }

      }
    }
  }
  CalculateDiff() {
    let vaccineDate = new Date(this._days);
    let currentDate = new Date(this.getCurrentDate());
    let currentUTC = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    let vaccineUTC = Date.UTC(vaccineDate.getFullYear(), vaccineDate.getMonth(), vaccineDate.getDate());
    let dataDiff = currentUTC - vaccineUTC;
    return Math.floor(dataDiff / (1000 * 60 * 60 * 24));
  }
  IndicationChange() {
    if (this.VaccineForm.controls.SelectedIndication.value != null && this.VaccineForm.controls.SelectedIndication.value != undefined) {
      if (this.VaccineForm.controls.SelectedIndication.value[0].Text == "COVID") {
        this._isCovid = true;
        this.VaccineForm.get('SelectedManufacturer').setValidators(Validators.required);
      } else {
        this._isCovid = false;
        this.VaccineForm.get('SelectedManufacturer').clearValidators();
      }
      this.VaccineForm.get('SelectedManufacturer').updateValueAndValidity();
    }
  }
  CheckVaccineDateEmpty() {
    let vaccineDate = this.VaccineForm.controls.VaccinationDate.value;
    if (vaccineDate == null || vaccineDate == "") {
      this.checkFormValidation();
      return false;
    }
    else {
      return true;
    }
  }
  getCurrentDate() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1; //months are 0 indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  doseOnKeyInput($event){
    var inputValue = $event.target.value;

    if(($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105))
    {
      inputValue=inputValue+$event.key;
    }
  
    if((($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) && (inputValue <= 0 || inputValue > 99) || ($event.key=='-'|| $event.key=='+')){
      event.preventDefault(); 
    }
  }
}


