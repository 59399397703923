import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';

@Component({
  selector: 'app-previewnotification',
  templateUrl: './previewnotification.component.html',
  styleUrls: ['./previewnotification.component.scss'],
})  
export class PreviewNotificationComponent implements OnInit {
  public myData: any = [];
  htmlContent: any;
  patientFc = new FormControl(''); 
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public dialogRef: MatDialogRef<PreviewNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _global: GlobalfunctionService,
    private sanitizer: DomSanitizer,
  ) {}  

  ngOnInit() {
    this.myData = this.data;
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.myData) as string;
  }
  
  onSubmit(): void {
    this.onNoClick(true);
  }
  
  onNoClick(flag: boolean): void {
    this.dialogRef.close(flag); 
  } 
}
