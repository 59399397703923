import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HelperService implements OnDestroy {
  public technicalSkills: any;
  private cache = new Map<string, any>();

  constructor(
    private datePipe: DatePipe
  ) {
  }

  toDateString(date: string): string {
    return new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000).toISOString()
  }

  ngOnDestroy(): void {
  }
  
  sortByKey(array: Array<any>, key: string) {
    return array.sort(function (a, b) {
      let x = a[key].toLowerCase(); let y = b[key].toLowerCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getDaysBetweenTwoDates(startDate:string,endDate:string):number{
    const date1: Date = new Date(startDate);
    const date2: Date = new Date(endDate);
    const timeDifference: number = date2.getTime() - date1.getTime();
    const numberOfDays: number = timeDifference / (1000 * 60 * 60 * 24);
    const numberOfDaysRounded: number = Math.floor(numberOfDays);
    return numberOfDaysRounded
  }

  static replaceAll(input: string, search: string, replacement: string): string {
    const regex = new RegExp(search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
    return input.replace(regex, replacement);
  }

  replaceVariablesWithPatientData(content: string, rowData: any): string {
    
    let matches:any = content.match(/{{(.*?)}}/g);
    if (matches) {
      for (const match of matches) {
        const textInside = match.substring(2, match.length - 2).trim();
        let info: any;
        switch (textInside) {

          case 'first_name' :
            info = rowData.FirstName || ' - ';
            break;
          
          case 'last_name' :
            info = rowData.LastName || ' - ';
            break;

          case 'ComtrakID' :
            info = rowData.Id || ' - ';
            break;

          case 'CRIOID' :
            info = rowData.CrioId || ' - ';
            break;

          case 'age' :
            info = rowData.Age || ' - ';
            break;

          case 'date_of_birth' :
            info = !!rowData.DOB ? this.datePipe.transform(rowData.DOB, 'MM/dd/yyyy') : ' - ';
            break;

          case 'phone' :
            info = rowData.Phone || ' - ';
            break;
          
          case 'address': 
            info = rowData.Address || ' - ';
            break;
        
          case 'gender':
            info = rowData.Gender || ' - ';
            break;

          case 'campaign' :
            info = rowData['patientLastInteraction.Campaign'] || ' - ';
            break;

          case 'StudyName' :
            info = rowData['patientLastInteraction.Study'] || ' - ';
            break;

          case 'SiteName' :
            info = rowData['patientLastInteraction.SiteName'] || ' - ';
            break;

          case 'SiteAddress':
            info = rowData['patientLastInteraction.site.Address'] || ' - ';
            break;
          
          case 'SitePhoneNumber':
            info = rowData['patientLastInteraction.site.Phone'] || ' - ';
            break;

          case 'RecruiterFirstName':
            info = rowData['patientLastInteraction.RFirstName'] || ' - ';
            break;
          
          case 'RecruiterLastName':
            info = rowData['patientLastInteraction.RLastName'] || ' - ';
            break;
          
          case 'RecruiterPhone':
            info = rowData['patientLastInteraction.recruiter.Phone'] || ' - ';
            break;
          
          case 'RecruiterEmail':
            info = rowData['patientLastInteraction.REmail'] || ' - ';
            break;

          case 'TodayDateTime':
            info = this.datePipe.transform(new Date(), 'MM/dd/yyyy | hh:mm a');
            break;
          
          case 'Salutation':
            info = !!rowData.Gender ? rowData.Gender == 'Male' ? 'Mr.' : 'Mrs.' : ' - ';
            break;
          
          case 'TimeSalutation':
            info = this.getTimeSalutation(); // Assuming you have a method to determine time salutation
            break;
          
          case 'ScheduledVisitAt':
            info = rowData['patientLastInteraction.ScheduledDateTime'] || ' - ';
            break;
          
          case 'ConfirmationStatus':
            info = rowData['patientLastInteraction.Confirmation'] || ' - ';
            break;
          
          case 'PatientStatus':
            info = rowData['patientLastInteraction.PatientStatus'] || ' - ';
            break;
          
          case 'CallBackAt':
            info = !!rowData['patientLastInteraction.NextDate'] ? this.datePipe.transform(rowData['patientLastInteraction.NextDate'], 'MM/dd/yyyy') : ' - ';
            break;
          
          case 'CommunicationBy':
            info = rowData['patientLastInteraction.REmail'] || ' - ';
            break;
          
          case 'ethnicity':
            info = rowData['patientLastInteraction.Ethnicity'] || ' - ';
            break;
          
          case 'race':
            info = rowData['patientLastInteraction.Race'] || ' - ';
            break;
          
        }
        content = content.replace(match, info + ' ');
      }
    }
    return content;
  }

  getTimeSalutation(): string {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }

  /* Cache Methods */
  getCache(key: string): Observable<any> | undefined {
    const cacheEntry = this.cache.get(key);

    if (cacheEntry) {
      const currentTime = new Date().getTime();
      
      if (currentTime < cacheEntry.expiry) {
        return of(cacheEntry.data);
      } else {
        this.cache.delete(key);  // Remove expired cache entry
      }
    }

    return undefined;
  }

  setCache(key: string, data: any): Observable<any> {
    const expiryTime = new Date().getTime() + 900000;  // 15 min in milliseconds

    this.cache.set(key, { data: data, expiry: expiryTime });

    // Automatically clear the cache after 15 min
    setTimeout(() => {
      this.cache.delete(key);
    }, 900000);  // 15 min in milliseconds

    return of(data);
  }
}