import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

(function overrideConsole() {
  const originalConsole = {
      log: console.log,
      warn: console.warn,
      error: console.error,
      info: console.info
  };

  let showLogs = !environment.production; // Enable logs if not in production by default

  if (!showLogs) {
    console.clear();
    // Optional: Add a greeting or info message
    console.info("%cHey, Welcome to the Console!", "color: green; font-size:16px;");
    console.info("%cThis application is running in " + (environment.production ? "production" : "development") + " mode.", "color: orange; font-size:14px;");
  }

  console.log = function(...args) {
      if (showLogs) {
          originalConsole.log.apply(console, args);
      }
  };

  console.warn = function(...args) {
      if (showLogs) {
          originalConsole.warn.apply(console, args);
      }
  };

  console.error = function(...args) {
      if (showLogs) {
          originalConsole.error.apply(console, args);
      }
  };

  console.info = function(...args) {
      if (showLogs) {
          originalConsole.info.apply(console, args);
      }
  };

  window['enableLogging'] = (enable: boolean) => { showLogs = enable; }; // Expose method to enable/disable logging
})();

if (environment.production) {
  enableProdMode();
}
 declare global {
   interface Window {
     dataLayer: any[];
     gtag: (...args: any[]) => void;
   }
 }
platformBrowserDynamic().bootstrapModule(AppModule).then(()=>{
   window.dataLayer = window.dataLayer || []
   window.gtag = function() {
     window.dataLayer.push(arguments);
   };
   window.gtag('js', new Date());
   window.gtag('config', environment.tracking_code);
}).catch(err => console.error(err));
