import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Renderer2, ChangeDetectorRef, HostListener, AfterViewInit } from '@angular/core';
import { MatDialog, MatSidenav } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { CallListType } from '@sharedmodels/DMCREnums/DMCREnums';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeclinependingreviewComponent } from '@app/pages/declinependingreview/declinependingreview.component';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { HttpParams } from '@angular/common/http';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { DatePipe } from '@angular/common';
import { CommunicationService } from '@sharedservices/BackServices/ComTrak/Communication/communication.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { TwilioService, callStatusType, participant, participantType } from '@sharedservices/FrontServices/twilio.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { HelperService } from '@sharedservices/helper.service';
import { ChangeEventArgs, ToolbarSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import { HtmlEditorService, ImageService, LinkService, PasteCleanupService, RichTextEditorComponent, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, PasteCleanupService]
})
export class SideNavBarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild("sidenav", { read: ElementRef }) sidenavcontainer: ElementRef;
  @ViewChild("markinteraction", { read: ElementRef }) markinteraction: ElementRef;
  @ViewChild('smsTextarea') smsTextarea: ElementRef;
  @ViewChild('textInput') textInput!: ElementRef;
  @ViewChild('btnContainer') btnContainerRef!: ElementRef;
  @ViewChild('richEditor') public rteObj: RichTextEditorComponent;
  sidenavHeight = 0;
  markinteractionHeight = 0;
  @HostListener('window:resize', ['$event'])
  getScreenHeight() {
    this.getResizedScreenHeight();
  }

public backgroundColor = { columns: 2, colorCode: { 'Custom': ['#ffff00', '#008000', '#800080', '#800000', '#808000', '#c0c0c0', '#000000', ''] } }
public tools = {
  items: ['BackgroundColor']
};
public insertImageSettings = { allowedTypes: ['.jpeg', '.jpg', '.png'], display: 'inline', width: 'auto', height: 'auto', saveFormat: 'Blob', saveUrl: null, path: null }
public maxLength: number = 500;
public tableSettings = { width: '100%', styles: [{ text: 'Dashed Borders', command: 'Table', subCommand: 'Dashed' }, { text: 'Alternate Rows', command: 'Table', subCommand: 'Alternate' }], resize: true, minWidth: 0, maxWidth: null }
public toolbarSettings: ToolbarSettingsModel = {
  items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'Blockquote', 'OrderedList',
    'UnorderedList', '|', 'CreateLink', 'Image', '|', 'SourceCode', '|', 'Undo', 'Redo'
  ]
}

  shouldScrollToBottom: boolean = false;
  slideIn = true;
  WIDTH = 33.33;
  screenHeight = 0;
  isNotifyMeSms= false
  isNotifyMeEmail= false
  cursorPosition: number = 0;
  smsInputElement: string = '';
  expand = false;
  CommunicationHistory: any[];
  senderEmailAddressList: any;
  bccEmailValidFlag = true;
  rowData: any;
  PatientId:string;
  isOngoingCall = false;
  ccEmailValidFlag = true;
  callParticipantFlag=true;
  callHistoryFlag=true;
  callStartTime: Date;
  callEndTime: Date;
  loginUser: any = {};
  setRowData:any;
  callDuration: string = '00:00';
  filteredEmails: any[] = [];
  minimizeOnGoingCallWindow = false;
  communicationType = '';
  currentCall = null;
  currentCallData = null;
  isCallMuted = false;
  isCallonHold = false;
  toastId:number|null= null;
  subs = new Subscription();
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  // call section
  PhoneActivePageNo: number;
  isMorePhone:boolean = true;
  isMorePhoneLoader:boolean = true;
  senderPhone:any = [];
  userdata = JSON.parse(localStorage.getItem('data'));
  AllPhoneList :any = [];
  numberVariable:string;
  callStatus = null;
  isTransferCall = false;
  isAddCall = false;
  isNumpad= false;
  numValue = '';
  histoyLoaderFlag= false;
  isScript = false;
  isOpenInteraction = false;
  saveFlag = true;
  markInteractionData: any;
  phoneScriptSearch = new FormControl(null);
  phoneScriptDetails = [];
  callSenderPhone: FormControl;

  // sms section

  smsChatMessageList:any = [];
  isEmojiPickerOpenForSMS = false;
  isEmojiButtonClickForSMS = false;
  smsInputValue: FormControl;
  smsSenderPhone: FormControl;
  searchValueFc = new FormControl('');
  selectedSMSImages = []; 
  smsImageUrls: string[] = [];
  searchSMSChat = false;
  smsSearchCount = 0;
  smsSearchIndexes = [];
  smsCurrentSearchIndex = -1;
  sendingMessage = false;

  /** email section */
  @ViewChild('ccmultiselectelement') ccmultiselectelement: MultiSelectComponent;
  @ViewChild('bccmultiselectelement') bccmultiselectelement: MultiSelectComponent;
  emailSearchValueFc = new FormControl('');
  EmailActivePageNo: number;
  isMoreEmail:boolean = true;
  isMoreEmailLoader:boolean = true;
  sendMailFlag = false;
  isReply = false;
  isGoBack = false;
  ccFlag = false;
  bccFlag = false;
  emailList = ['example@dmclinical.com'];
  editorContent = `<p>&nbsp;</p>
  <p><span> ${this.loginUser.name}</span><br />DM Clinical Research<br />Phone:  ${this.numberVariable}  ${this.userdata.recruiterid ? '( ext: ' + this.userdata.recruiterid + ' )' : '' }<br />Email: <span style="color: #3598db;"> ${this.loginUser.email}</span><br />Website: <span style="color: #3598db;"><a href="http://www.dmclinicalresearch.com">www.dmclinicalresearch.com</a></span></p>
  <p><img src=\"https://dmclinicstaging.blob.core.windows.net/commodule/Footer.png\" alt=\"Custom Image\"   width="100%" height="100%"/></p>`;
  initialContent = `<p>&nbsp;</p><br /><br />
    <p><span> ${this.loginUser.name}</span><br />DM Clinical Research<br />Phone:  ${this.numberVariable}  ${this.userdata.recruiterid ? '( ext: ' + this.userdata.recruiterid + ' )' : '' }<br />Email: <span style="color: #3598db;"> ${this.loginUser.email}</span><br />Website: <span style="color: #3598db;"><a href="http://www.dmclinicalresearch.com">www.dmclinicalresearch.com</a></span></p>
    <p><img src=\"https://dmclinicstaging.blob.core.windows.net/commodule/Footer.png\" alt=\"Custom Image\"  width="100%" height="100%"/></p>`;
  scriptList = [];
  expandMailMenu = true;
  expandMailMenuId = 0;
  expandMailContentIdx = undefined;
  expandMailContentData: any = {};
  emailCharCount = 0;
  showEmailReplies=false;
  isInnerReply = false;
  sendMailForm: FormGroup;
  com_history_id = '';
  EmailLoaderFlag= false;
  EmailDetailLoaderFlag= false;
  communicationConversation = [];
  apiCallFlag = false;
  isEmojiPickerOpenForEmail = false;
  isEmojiButtonClickForEmail = false;
  checkPhoneNumber = false;
  backgroundRefresh: boolean = false;
  activeSubscriptions: any = [];

  columns = [
    {
      header: 'Communication ID',
      key: "token",
      visible: true,
      type: "string",
      width: "30px",
    },
    {
      header: 'Title',
      key: "title",
      visible: true,
      type: "string",
      width: "20px"
    },
    {
      header: 'Category',
      key: "category.name",
      visible: true,
      type: "string",
      width: "20px"
    },
    {
      header: 'Type',
      key: "type",
      visible: true,
      type: "string",
      width: "20px"
    },
    {
      header: 'Hashtags',
      key: "script_tag",
      visible: true,
      type: "string",
      width: 60
    },
  ];
  init = {
    menubar: false,
    height: 445,
    statusbar: true,
    plugins: ['advlist autolink lists link image charmap print preview anchor', 'fullscreen','','searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
    toolbar: 'emoji customimage fullscreen | undo redo | formatselect | quickscript | backcolor forecolor bold italic underline strikethrough |code bullist numlist alignleft aligncenter alignright alignjustify |outdent indent lists link media paste wordcount mention',
    setup: (editor) => {
      editor.ui.registry.addButton('quickscript', {
        text: 'Add Quick Script',
        onAction: () => {
          document.getElementById('open-script').click();
        }
      });
      editor.ui.registry.addButton('customimage', {
        icon: 'image',
        onAction: () => {
          document.getElementById('image-upload').click();
        }
      });
      editor.ui.registry.addButton('emoji', {
        icon: 'emoji',
        onAction: () => {
          let btnClickEvent:MouseEvent;
          function handleClick(event) {
            btnClickEvent = event
          }
          var clickEvent = new Event('click', {
            bubbles: true,
            cancelable: true,
          });
          document.getElementById('emoji-mart-trigger').addEventListener('click', handleClick);
          document.getElementById('emoji-mart-trigger').dispatchEvent(clickEvent);
        }
      });
      editor.on('keyup change paste input', () => {
        this.updateCharCount(editor.getContent({ format: 'text' }));
      });
    },
    importcss_append: true,
    link_context_toolbar: true,
    image_caption: true,
    toolbar_mode: 'sliding',
    paste_webkit_styles: 'color font-size background-color',
    paste_data_images: true,
    contextmenu: 'link image imagetools table configurepermanentpen',
    elementpath: false,
    branding: false,
    resize: false,
    entity_encoding: 'raw',
  };

  commands = [
    { title: 'Add', type: 'Edit', buttonOption: { content: '', cssClass: 'custom-bold', iconCss: 'e-icons e-square-add' } },
    { title: 'Preview', type: 'Eye', buttonOption: { content: '', cssClass: 'custom-bold', iconCss: 'e-icons e-eye' } },
  ];

  page = {
    size: 1,
    totalElements: 1,
    pageCount: 3,
  };
  toastrActive = false;
  transferUserList: object[] = [];
  transferToUser: any;
  selectedUserforTransfer: object = {};
  addToCallUserList: object[] = [];
  addToCallUser: any;
  selectedUserforAddToCall: object = {};
  transferNotes: string = '';
  onlineUsers = [];
  distroOnlineUsers = [];
  userObject = null;
  patientDetails: any;

  constructor(private _RecruiterService: RecruiterService,
    private dialog: MatDialog,
    public _Global: GlobalfunctionService,
    private scriptService: ScriptService,
    private prescreenService: PrescreenService,
    public spinner: NgxSpinnerService,
    private toastService: ToastrService,
    public _PatientInteractionService: PatientInteractionService,
    private communicationService: CommunicationService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private cd: ChangeDetectorRef,
    public PatientService: PatientService,
    private fb: FormBuilder,
    public _global: GlobalfunctionService,
    private helperService: HelperService,
    private twilioService: TwilioService,
    private socketService: SocketHandlerService,
    private eRef: ElementRef
  ) {
      this.smsInputValue = new FormControl();
      this.smsSenderPhone = new FormControl('');
      this.callSenderPhone = new FormControl('');
      this.userObject = JSON.parse(localStorage.getItem('data'));
    }

    private joinRoomForPatient(event, data) {
      
      this.socketService.socket.emit(event, data, 
      err => {
          if (err){
              console.log("🚀  ~ joinRoomForPatient ~ err:", err)
              return;
          }
      });
    }

  ngOnInit() {
    this.loginUser = this._global.getLoginUserDetails()
    // this.editorContent = `<p>&nbsp;</p> 
    // <p><span> ${this.loginUser.name}</span><br />DM Clinical Research<br />Phone: ${this.numberVariable}  ${this.userdata.recruiterid ? '( ext: ' + this.userdata.recruiterid + ' )' : '' }<br />Email: <span style="color: #3598db;"> ${this.loginUser.email}</span><br />Website: <span style="color: #3598db;"><a href="http://www.dmclinicalresearch.com">www.dmclinicalresearch.com</a></span></p>
    // <p><img src=\"https://dmclinicstaging.blob.core.windows.net/commodule/Footer.png\" alt=\"Custom Image\" width="100%" height="100%" /></p>`;
    // this.initialContent = `<p>&nbsp;</p>
    //   <p><span> ${this.loginUser.name}</span><br />DM Clinical Research<br />Phone:${this.numberVariable}  ${this.userdata.recruiterid ? '( ext: ' + this.userdata.recruiterid + ' )' : '' }<br />Email: <span style="color: #3598db;"> ${this.loginUser.email}</span><br />Website: <span style="color: #3598db;"><a href="http://www.dmclinicalresearch.com">www.dmclinicalresearch.com</a></span></p>
    //   <p><img src=\"https://dmclinicstaging.blob.core.windows.net/commodule/Footer.png\" alt=\"Custom Image\" width="100%" height="100%" /></p>`;
    this.onlineUsers = this.socketService.onlineUsers.users || [];
    console.log('Oninit: onlineUsers => ', this.onlineUsers);
    this.distroOnlineUsers = this.socketService.onlineUsers.distros || [];
    console.log('Oninit: Distros => ', this.distroOnlineUsers);

    /* Remove current user from transferlist */
    this.onlineUsers = this.filterOnlineUsers(this.onlineUsers);

    // this.transferUserList = [...this.distroOnlineUsers.map(data => data.group_name), ...this.onlineUsers];
    this.transferUserList = [
      ...this.distroOnlineUsers.map(data => ({ type: 'distro', value: data.group_email, name: data.group_name })),
      ...this.onlineUsers.map(email => ({ type: 'email', value: email, name: email }))
    ];

    this.addToCallUserList = [
      ...this.onlineUsers.map(email => ({ type: 'email', value: email, name: email }))
    ];
    
    this.socketService.receivedOnlineUserEvent().subscribe(res =>{
      this.onlineUsers = res;
      /* Remove current user from transferlist */
      this.onlineUsers =  this.filterOnlineUsers(this.onlineUsers);
      this.transferUserList = [
        ...this.distroOnlineUsers.map(data => ({ type: 'distro', value: data.group_email, name: data.group_name })),
        ...this.onlineUsers.map(email => ({ type: 'email', value: email, name: email }))
      ];
      this.addToCallUserList = [
        ...this.onlineUsers.map(email => ({ type: 'email', value: email, name: email }))
      ];
      this.updateSelectUser();
    });

    this.socketService.receivedOnlineDistroUserEvent().subscribe(res =>{
      this.distroOnlineUsers = res;
      this.transferUserList = [
        ...this.distroOnlineUsers.map(data => ({ type: 'distro', value: data.group_email, name: data.group_name })),
        ...this.onlineUsers.map(email => ({ type: 'email', value: email, name: email }))
      ];
      this.updateSelectUser();
    });

    const exitInteractionSub = this.scriptService.exitFromInteraction.subscribe(res => {
      if (!!res) {
        this.isOpenInteraction = false;
      }
    });
    this.subs.add(exitInteractionSub);

    const isOpenInteractionSub = this.prescreenService.isOpenInteraction.subscribe(res => {
      this.saveFlag = res;
      res ? null : (this.isOpenInteraction = false); 
    });
    this.subs.add(isOpenInteractionSub);

    const insertScriptDataSub = this.scriptService.insertScriptData.subscribe(res => {
      if (res.content) {
        this.communicationType = res.script_type === 'text' ? 'sms' : 'email';
        if (this.communicationType === 'email') {
          this.editorContent = this.replaceVariablesFromScript(res.content) + this.initialContent;
        } else {
          let removeHtmlTags = res.content.replace(/<br\s*\/?>/g, '\n').replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, "").replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
          this.smsInputValue.setValue(this.replaceVariablesFromScript(removeHtmlTags));
          this.smsImageUrls = res.data && res.data.media ? res.data.media : null
          if (this.smsImageUrls) {
            this.selectedSMSImages = this.smsImageUrls.map(url => ({ name: url }));
          }
        }
        this.scriptService.insertScriptData.next({content: "", script_type: ""});
      }
    });
    this.subs.add(insertScriptDataSub);

    const contactCenterTypeSub = this._RecruiterService.contactCenterType.subscribe(res => {
      this.communicationType = res;
    });
    this.subs.add(contactCenterTypeSub);

    const contactCenterRowDataSub = this._RecruiterService.contactCenterRowData.subscribe((res:any) => {
      if (!!res) {
        this.rowData = res;
        this.PatientId = (res && res.Id) || (res && res.patient_obj && res.patient_obj.Id) || 0;
        this.getPatientDetails();
        this.getNotifyMe()
        this.createPatientSendForm();
      } else {
        this.rowData = localStorage.getItem(this.PatientService.setRowData.Id) ? JSON.parse(localStorage.getItem(this.PatientService.setRowData.Id)) : this.PatientService.setRowData;
        this.PatientId = this.rowData.Id;
        this.createPatientSendForm();
      }
    });
    this.subs.add(contactCenterRowDataSub);
    this.joinRoomForPatient('joinRoom', `patient_profiles#${this.PatientId}`);


    this.socketService.updatePatientProfile().subscribe(res => {
      console.log("res update patient profile ===>>>", res);
      this.getPatientDetails();
      this.getNotifyMe()
    })
    /* Let's watch the current call - On new incoming call start need to display it */
    const currentCallSub = this._RecruiterService.currentCall.subscribe(res => {
      console.log("🚀 ~ SideNavBarComponent ~ currentCallSub ~ res:", res, this.rowData);
      this.currentCall = res;
      if (res === null) {
        this.callPatient(false, '');
      } else {
        this.callPatient(true, 'Incoming');
      }
    });
    this.subs.add(currentCallSub);

    /* Let's watch the current call - On new incoming call start need to display it */
    const currentCallDataSub = this._RecruiterService.currentCallData.subscribe(res => {
      console.log("🚀 ~ SideNavBarComponent ~ currentCallDataSub ~ res:", res, this.rowData);
        this.currentCallData = res;
      if (res && res.participants) {

        const agentsInCall = res.participants.filter((p) => {
          return p.type === participantType.agent
        });

        if (!agentsInCall || agentsInCall.length == 0) return;

        this.sortParticipants(res.participants);
        this.onlineUsers = this.filterOnlineUsers(this.onlineUsers);
        console.log(`${new Date().toISOString()} ~ file: side-nav-bar.component.ts:396 ~ SideNavBarComponent ~ currentCallDataSub ~ res.participants:`, res.participants);
        const myCallData:participant = res.participants.find((p) => {
          return p.address === this._Global.loginUserEmail
        });
        
        
        console.log(`${new Date().toISOString()} ~ file: side-nav-bar.component.ts:389 ~ SideNavBarComponent ~ constmyCallData:participant=res.participants.find ~ myCallData:`, myCallData);
        if (myCallData) {
          this.isCallMuted = myCallData.mute || false;
        }
      }
    });
    this.subs.add(currentCallDataSub);

    const contactCenterFlagSub = this._RecruiterService.contactCenterFlag.subscribe(res => {
      this.openCloseSidebar(res);
    });
    this.subs.add(contactCenterFlagSub);

    this.communicationType === 'call' ?
      this.setCommunicationType('call',0) : this.communicationType == 'sms' ?
      this.setCommunicationType('sms',1) : this.setCommunicationType('email',2)

    this.searchValueFc.valueChanges.subscribe(v => {
      if (!(!!v)) {
        this.smsSearchCount = 0;
        this.smsSearchIndexes = [];
        this.smsCurrentSearchIndex = -1;
      }
    })
     
    this.emailSearchValueFc.valueChanges.subscribe((vl) => {
      if(!vl) {this.emailSearchFilter();}
    });

    // When user switch sender number, we need to load SMS History
    this.smsSenderPhone.valueChanges.subscribe(v => {
      console.log('LoadCommunicationHistory called from ===> SMS Sender Phone Value change', v);
        this.LoadCommunicationHistory(1,true);
    });

    this.phoneScriptSearch.valueChanges.subscribe(v => {
      if (!v) {
        this.openQuickScript('phone');
      }
    })

    this.activeSubscriptions.subCallUpdate = this.socketService.receiveCommunicationUpdate().subscribe(commObj => {
      this.updateCommunicationHistory(commObj);
    });

    this.isNotifyMeSms= false
    this.isNotifyMeEmail= false
  }

  isRequiredToggle(type:string){
    if(type ==='email'){
      this.isNotifyMeEmail = true
      this.handleNotifyMeStatus('email')
    }else if(type === 'sms'){
      this.isNotifyMeSms = true
      this.handleNotifyMeStatus('sms')
    }
  }

  getNotifyMe(): void {

    let getNotifyMeList = this.communicationService.getNotifyMe(this.PatientId).subscribe({
      next: (res) => {
        if (res.status) {
          this.isNotifyMeSms = res.data.sms_notify || false
          this.isNotifyMeEmail = res.data.email_notify || false
          // this.isNotifyMeSms = true
          // this.isNotifyMeEmail =  true
        } else {
          this.showErrorToast('error', '', res.message);
        }
      },
      error: (err: any) => {
        this.showErrorToast('error', '', err.message);
      }
    });

    this.subs.add(getNotifyMeList);
  }


  handleNotifyMeStatus(type): void {
    const payload = {
      type: type,
      patient_id: this.PatientId
    }

    let getList = this.communicationService.postNotifyMeStatus(payload).subscribe({
      next: (res) => {
        if (res.status) {
        console.log('res.data', res)
        } else {
          this.showErrorToast('error', '', res.message);
        }
      },
      error: (err: any) => {
        this.showErrorToast('error', '', err.message);
      }
    });

    this.subs.add(getList);
  }

  getPatientDetails(): void {
    console.log(`SideNavBarComponent ~ getPatientDetails Called ~ payload.this.PatientId:`, this.PatientId);

    const payload = {
      patientId: this.PatientId,
      fromCache: true,
    }

    let getList = this.PatientService.getPatinetDataById(payload).subscribe({
      next: (res) => {
        if (res.status) {
          this.patientDetails = res.data;
          this.getSenderPhone();
        } else {
          this.showErrorToast('error', '', res.message);
        }
      },
      error: (err: any) => {
        this.showErrorToast('error', '', err.message);
      }
    });

    this.subs.add(getList);
  }

  handleOpen(value: string) {
    if (value === 'call participants') {
      if (this.expand) {
        this.callHistoryFlag = false; 
      }
      this.callParticipantFlag = !this.callParticipantFlag;
    } else if (value === 'call history') {
      if (this.expand && this.currentCallData && this.currentCallData.participants && this.currentCallData.participants.length > 0) {
        this.callParticipantFlag = false;
      }
      this.callHistoryFlag = !this.callHistoryFlag;
    }
  }

  filterOnlineUsers(users) {
    let agentEmails = [];
    if (this.currentCallData && this.currentCallData.participants) {
      agentEmails = this.currentCallData.participants.map(participant => {
        // Check if participant type is 'participantType.agent' before accessing 'address'
        if (participant.type === participantType.agent && participant.status === callStatusType.inProgess) {
          return participant.address;
        } else {
          // Handle non-agent participants (optional)
          return null; // Or any other default value
        }
      });
    }
    
    return users.filter(
      (email) => (email !== this._Global.loginUserEmail && !agentEmails.includes(email))
    );
  }

  updateSelectUser() {
    console.log('On Online event received ===>', this.isTransferCall, this.selectedUserforTransfer, this.transferUserList, (this.selectedUserforTransfer && this.selectedUserforTransfer['email']));
    if (this.isTransferCall) {
      if (this.selectedUserforTransfer && this.selectedUserforTransfer['email']) {
        const isUserStillOnline = this.transferUserList.filter(user => user['value'] === this.selectedUserforTransfer['email'])
        console.log("🚀 ~ SideNavBarComponent ~ this.socketService.receivedOnlineUserEvent ~ isUserStillOnline:", isUserStillOnline)
        if (isUserStillOnline.length === 0) {
          this.showErrorToast('info', '', 'Selected Agent for transfer went offline.');
          this.transferToUser = null;
          this.selectedUserforTransfer = null;
        }
      }
    }

    if (this.isAddCall) {
      if (this.selectedUserforAddToCall && this.selectedUserforAddToCall['email']) {
        const isUserStillOnline = this.transferUserList.filter(user => user['value'] === this.selectedUserforAddToCall['email'])
        console.log("🚀 ~ SideNavBarComponent ~ this.socketService.receivedOnlineUserEvent ~ isUserStillOnline:", isUserStillOnline)
        if (isUserStillOnline.length === 0) {
          this.showErrorToast('info', '', 'Selected Agent for adding to the call went offline.');
          this.addToCallUser = null;
          this.selectedUserforAddToCall = null;
        }
      }
    }
  }

  sortParticipants(participants) {
    if (participants && participants.length > 0) {
      this.currentCallData.participants = participants.slice() // Clone to avoid modifying original array
        .sort((a, b) => {
          // Sort by type (patient first)
          if (a.type === 'patient' && b.type === 'agent') {
            return -1; // Move patient up
          } else if (a.type === 'agent' && b.type === 'patient') {
            return 1; // Move agent down
          }

          // Sort by status (accepted first)
          if (a.status === 'accepted' && b.status !== 'accepted') {
            return -1; // Move accepted up
          } else if (a.status !== 'accepted' && b.status === 'accepted') {
            return 1; // Move non-accepted down
          }

          // Sort by startTime (latest first)
          return new Date(b.startTime).getTime() - new Date(a.startTime).getTime(); // Descending order
        });
    }
  }

  formatTime(timeString: string): string {
    const date = moment(timeString); // Use Moment.js to parse the time
    return date.format('HH:mm:ss'); // Format using Moment's format function
  }

  toEmailEmpty(){
    if( this.sendMailForm.get('to').invalid){
      this.showErrorToast('error', '', 'Register Email Id first in Patient Profile');
    }
  }

  resetAllValues(): void {
    this.selectedUserforTransfer = {};
    this.selectedUserforAddToCall = {};
    this.transferUserList = [];
    this.addToCallUserList = [];
    this.smsInputValue.setValue(null);
    this._RecruiterService.contactCenterFlag.next(false);
    this._RecruiterService.contactCenterType.next(null);
    this._RecruiterService.contactCenterRowData.next(null);
    this.rowData = null;
    this.subs.unsubscribe();
  }

  updateCursorPosition(event: any): void {
    this.cursorPosition = event.target.selectionStart;
  }
 
  emailSearchFilter() {
    const searchTerm = this.emailSearchValueFc.value.trim().toLowerCase();
  
    if (searchTerm) {
      this.filteredEmails = this.CommunicationHistory.filter(item =>
        item.subject.toLowerCase().includes(searchTerm) && item.username.toLowerCase().includes(searchTerm)
      );
  
      if (this.filteredEmails.length === 0) {
        this.filteredEmails = this.CommunicationHistory.filter(item =>
          item.username.toLowerCase().includes(searchTerm)
        );
  
        if (this.filteredEmails.length === 0) {
          this.filteredEmails = this.CommunicationHistory.filter(item =>
            item.subject.toLowerCase().includes(searchTerm)
          );
        }
      }
    } else {
      this.filteredEmails = this.CommunicationHistory;
    }
  }
  
  ngAfterViewInit(): void {
    this.sidenavHeight = this.sidenavcontainer.nativeElement.offsetHeight;
    this.markinteractionHeight = this.markinteraction.nativeElement.offsetHeight;
    this._RecruiterService.sidenavHeight.next(this.sidenavHeight);
  }

  createPatientSendForm(): void {
    this.sendMailForm = this.fb.group({
      from: ['', Validators.required],
      to: [this.rowData.Email || '', Validators.required],
      cc: [[]],
      bcc: [[]],
      subject: ['', Validators.required]
    })
  }

  emailValidator(event, emailType = '') {
    const dmclinicalEmailPattern = /^[a-z0-9._%+-]+@dmclinical\.com$/;
    
    this[emailType === 'cc' ? 'ccmultiselectelement' : 'bccmultiselectelement']['popupObj'].element.querySelector('.e-content').style.display = 'none';
    emailType === 'cc' ? (this.ccEmailValidFlag = event.text.match(dmclinicalEmailPattern) ? true : false) : (this.bccEmailValidFlag = event.text.match(dmclinicalEmailPattern) ? true : false);
    this.cd.detectChanges();
  }
  getResizedScreenHeight(): void {
    this.sidenavHeight = this.sidenavcontainer.nativeElement.offsetHeight;
    this.markinteractionHeight = this.markinteraction.nativeElement.offsetHeight;
    this._RecruiterService.sidenavHeight.next(this.sidenavHeight);
  }

  replaceVariablesFromScript(content: string): string {
    return this.helperService.replaceVariablesWithPatientData(content, this.patientDetails);
  }
  
  setCommunicationType(type: string, n:number): void {
    this.communicationType = type;
    this.communicationType === 'email' ? this.getSenderEmail() : this.getSenderPhone();
    this.isEmojiPickerOpenForSMS = false;
    this.isEmojiButtonClickForSMS = false;
    this.isEmojiPickerOpenForEmail = false;
    this.isEmojiButtonClickForEmail = false;
    const bgOffset = this.WIDTH * n;
    setTimeout(() => {
      const btnref = this.btnContainerRef ? this.btnContainerRef.nativeElement : null;
      btnref.style.setProperty("--bg-offset", `${bgOffset}%`);
    }, 100);
  }

  expandWindow(): void {
    setTimeout(() => {
      this.getResizedScreenHeight();
    }, 200);
    if (!this.expand && this.currentCallData && this.isOngoingCall &&
       this.currentCallData.participants && 
       this.currentCallData.participants.length > 0 ) {
      this.callParticipantFlag = false;
      this.callHistoryFlag = false;
    }
  }

  minimizeWindow(): void {
    this.expand = true;
    this.minimizeOnGoingCallWindow = true;
    setTimeout(() => {
      this.getResizedScreenHeight();
    }, 800);
  }

  openCloseSidebar(flag: boolean): void {

    if (!flag) {
      this.sidenav.close();
      setTimeout(() => {
        this.slideIn = true;
        this.resetAllValues();
      }, 600);
    } else {
      this.sidenav.open();
      setTimeout(() => {
      this.slideIn = false;
      }, 1);
    }
  }

  onTransferUserChange(event: ChangeEventArgs): void {
    console.log("🚀 ~ SideNavBarComponent ~ onTransferUserChange ~ onTransferUserChange:", event)
    const selectedType = event.itemData['type'];
    const selectedValue = event.itemData['value'];

    console.log('On Transfer user change: Selected Type:', selectedType, 'Selected Value:', selectedValue);

    this.selectedUserforTransfer = {
      type: selectedType,
      email: selectedValue
    };
  }

  onAddToCallUserChange(event: ChangeEventArgs): void {
    console.log("🚀 ~ SideNavBarComponent ~ onAddToCallUserChange ~ onAddToCallUserChange:", event)
    const selectedType = event.itemData['type'];
    const selectedValue = event.itemData['value'];

    console.log('On Transfer user change: Selected Type:', selectedType, 'Selected Value:', selectedValue);

    this.selectedUserforAddToCall = {
      type: selectedType,
      email: selectedValue
    };
  }

  onTransferNotesChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.transferNotes = selectElement.value;
  }

  updateCall(action, participant) {
    console.log('Currnet Call Action Called => ', action);
    console.log(this.currentCall);
    if (action === 'mute') {
      if (!participant) this.isCallMuted = !this.isCallMuted;
      const mute = participant ? !participant.mute : this.isCallMuted;
      this.twilioService.muteCall(mute, participant);
      // this.currentCall.mute()
    }
    if (action === 'end-call' && participant) {
      this.twilioService.endCall(participant);
      // this.currentCall.mute()
    }
    if (action === 'hold') {
      this.isCallonHold = !this.isCallonHold;
      this.twilioService.holdCall(this.isCallonHold);
    }
    if (action === 'transfer' || action === 'add-to-call') {
      if (action === 'transfer' && (!this.selectedUserforTransfer || !this.selectedUserforTransfer['type'])) {
        this.showErrorToast('info', '', 'Plesae choose an agent you want to transfer to.');  
        return; 
      }
      if (action === 'add-to-call' && (!this.selectedUserforAddToCall || !this.selectedUserforAddToCall['type'])) {
        this.showErrorToast('info', '', 'Plesae choose an agent you want to add.');  
        return; 
      }
      const userToAdd = action === 'transfer' ? this.selectedUserforTransfer : this.selectedUserforAddToCall;
      this.twilioService.transferCall(userToAdd, action === 'transfer' ? this.transferNotes : 'ADD_TO_CALL');
        this.currentCallData.participants.push({
          address: userToAdd['email'],
          type: participantType.agent,
          callSid: action === 'transfer' ? 'Transferring..' : 'Adding..',
          startTime: new Date().toISOString(),
          status: callStatusType.inProgess,
          isTemp: true
        })
        this.isTransferCall = false;
        this.isAddCall = false;
        this.isNumpad = false;
        return;     
    }
  }

  // call section 
  callPatient(flag: boolean, type: string): void {
    console.log("🚀 ~ SideNavBarComponent ~ callPatient ~ flag:", flag)
    if (flag) {
      // If Incoming call, No need to check call Sender Phone Value
      if (!this.callSenderPhone.value && !this.currentCall) {
        this.showErrorToast('info', '', 'Please select sender phone number');
        return;
      }

      if (!this.rowData && !this.rowData.Phone && !this.patientDetails && !this.patientDetails.Phone) {
        this.showErrorToast('info', '', 'Loading Patient Data, Please try in a moment..');
        return;
      }

      const patientPhone = this.rowData && this.rowData.Phone ? this.rowData.Phone : this.patientDetails.Phone;
      
      console.log("🚀 ~ SideNavBarComponent ~ callPatient ~ this.rowData:", this.rowData)
      if (type === 'outgoing') {
        this.twilioService.startCall(patientPhone, this.callSenderPhone.value);
      }
      this.isOngoingCall = true;
      // To start receiving Online user events
      this.socketService.emitChatJoinRoomEvent('online-user-event', '');
      if (!this.isCallonHold) {
        this.callStartTime = new Date();
      }
      this.callStatus = this.currentCall ? this.currentCall.status() : null;
      // console.log("🚀 ~ SideNavBarComponent ~ callPatient ~ this.callStatus:", this.callStatus, this.currentCall)
      this.updateCallDuration();
      localStorage.setItem('isOngoingCall', JSON.stringify(this.isOngoingCall));
    } else {
      this.twilioService.endCall(null);
      this.markInteractionData = null;
      this.isOngoingCall = false;
      this.socketService.leaveRoom('online-user-event');
      this.isOpenInteraction = false;
      this.callEndTime = new Date();
      this.callStatus = null;
      this.callDuration = "Connecting...";
      this.isTransferCall = flag;
      this.isAddCall = flag;
      this.isNumpad = flag;
      this.isScript = flag;
      localStorage.setItem('isOngoingCall', JSON.stringify(this.isOngoingCall));
    }
  }

  updateCallDuration(): void {
    let dots = ""; // Initialize empty string for dots
    if (!this.isOngoingCall || !this.currentCall) return;
    setInterval(() => {
      if (this.isOngoingCall) {
        const callStatusNow = this.currentCall.status();
        if (this.callStatus != callStatusNow) {
          this.callStatus = this.currentCall.status();
        }

        // console.log("🚀 ~ SideNavBarComponent ~ callPatient ~ this.callStatus:", callStatusNow, this.callStatus, this.currentCall)

         // Display "Connecting" if call is not yet in progress
        if (callStatusNow !== "open") {
          dots += "."; // Add a dot to the string
          if (dots.length > 3) {
            dots = ""; // Reset dots after reaching 4
          }
          this.callDuration = `Connecting${dots}`;
        } else {
          const currentTime = new Date();
          const durationInSeconds = Math.floor((currentTime.getTime() - this.callStartTime.getTime()) / 1000);
          const hours = Math.floor(durationInSeconds / 3600);
          const minutes = Math.floor((durationInSeconds % 3600) / 60);
          const seconds = durationInSeconds % 60;

          this.callDuration = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
      }
    }, 1000); 
  }

  callType(type: string): void {
    if (this.expand && this.currentCallData && this.currentCallData.participants && this.currentCallData.participants.length > 0 && (type === 'transfer' || type === 'add')) {
      this.callParticipantFlag = false;
      this.callHistoryFlag = false;
    }
    switch (type) {

      case 'transfer': {
        this.isTransferCall = true;
        this.isAddCall = false;
        this.isScript = false;
        this.isNumpad = false;
        break;
      }

      case 'add': {
        this.isTransferCall = false;
        this.isAddCall = true;
        this.isScript = false;
        this.isNumpad = false;
        break;
      }

      case 'numpad': {
        this.isTransferCall = false;
        this.isAddCall = false;
        this.isScript = false;
        this.isNumpad = true;
        break;
      }

      case 'script': {
        this.isTransferCall = false;
        this.isAddCall = false;
        this.isScript = true;
        this.isNumpad = false;
        this.openQuickScript('phone')
        break;
      }

      default: {
        this.isTransferCall = false;
        this.isAddCall = false;
        this.isScript = false;
        this.isNumpad = false;
      }
    }
  }

  handleNumberClick(number: string | number): void {
    if (number === 'x') {
      this.numValue = this.numValue.slice(0, -1);
    } else {
      this.numValue += number;
      this.twilioService.sendDigit(number.toString());
    }
  }

  showPreview(itemId: any, isShow: boolean): void {

    if (isShow) {
      this.phoneScriptDetails.forEach(v => v.isPreview = v._id === itemId ? isShow : !isShow)
    } else {
      this.phoneScriptDetails.forEach(v => {
        if (v._id === itemId) {
          v.isPreview = isShow;
        }
      })
    }
  }

  openInteractionDialog(): void {
    if (this.isOpenInteraction) {
      const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
        data: {
          title: 'Alert!',
          message: "Are you sure you want to close mark interaction, your data will be lost ?",
          AcceptBtnText: "Yes",
          CancelBtnText: " No"
        },
      });
      const dialogRefSub = dialogRef.afterClosed().subscribe((action) => {
        action === 'yes' ? this.toggleMarkInteraction() : null;
      });
      this.subs.add(dialogRefSub);
      setTimeout(() => {
        this.getResizedScreenHeight();
      }, 1000);
    } else {
      this.toggleMarkInteraction();
      setTimeout(() => {
        this.getResizedScreenHeight();
      }, 1000);
    }
  }

  toggleMarkInteraction(): void {
    this.isOpenInteraction = !this.isOpenInteraction;
    this.saveFlag = this.isOpenInteraction;

    if (this.isOpenInteraction) {
      this.spinner.show();
      this._Global.IsReady = false;
      this._PatientInteractionService.GetInteractions(CallListType.Recruiter, this.rowData.Id, this.rowData.StatusId, this.rowData.LastInteractionId).subscribe(res => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.markInteractionData = {
          callList: <PatientInteraction>res,
          screenType: CallListType.Recruiter,
          IsDNQ: false,
          isEdit: false,
          fromSidenav: true,
          lastInterationStatus: this.rowData.Status ? this.rowData.Status : "",
          lastInterationStatusId: this.rowData.StatusId ? this.rowData.StatusId : ""
        }
      })
      setTimeout(() => {
        this.getResizedScreenHeight();
      }, 1000);
    } else {
      this.markInteractionData = null;
      setTimeout(() => {
        this.getResizedScreenHeight();
      }, 1000);
    }
  }

  onPhoneScriptSearch(): void {
    if (!!this.phoneScriptSearch.value) {
      this.phoneScriptSearch.setValue(this.phoneScriptSearch.value.trim());
      this.openQuickScript('phone');
    }
  }

  openQuickScript(scriptType: string) {
    if (scriptType === 'phone') {
      this.spinner.show();
      this._Global.IsReady = false;
      let params = new HttpParams().set('ScriptStatus', 'published').set('ScriptType', 'phone').set('is_active','true');
      if (!!this.phoneScriptSearch.value) {
        params = params.append('SearchString', this.phoneScriptSearch.value);
      }
      const getData = this.communicationService.getScriptEditorData(params).subscribe({
        next: (res) => {
          if (res.status) {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.phoneScriptDetails = res.data;
            this.phoneScriptDetails.forEach(v => {
              v['isPreview'] = false;
            });
          } else {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.toastService.error('', res.message, this.toastOptions);
          }
        },
        error: (err) => {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.toastService.error('', err.message, this.toastOptions);
        }
      });
      this.subs.add(getData);
    } else {
      this.scriptService.openScriptDialog.next({ openFlag: true, type: scriptType });
    }
  }

  uploadImageToServer(base64: string, type: string, name: string): void {
    const requestBody = {
      image: base64,
      type: type,
      filename: name || ''
    };
  
    this.spinner.show();
    this._Global.IsReady = false;
    const uploadImageSub = this.PatientService.uploadImage(requestBody).subscribe({
      next: (res) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        if (res.status) {
          if (this.communicationType !== 'email') {
            if (!this.smsImageUrls) this.smsImageUrls = [];
            this.smsImageUrls.push(res.data); // Store in the array if not email
          }
        } else {
          this.showErrorToast('error', '', res.message);
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.showErrorToast('error', '', err);
      }
    });
    this.subs.add(uploadImageSub);
  }

  onImageUpload(event: any) {
    const files = event.target.files; // Get the selected files
    const validFormats = ['png', 'jpg', 'jpeg', 'gif'];
    
    if (files.length > 0) {
      for (let file of files) {
        if (validFormats.includes(file.type.split('/')[1])) {
          if (file.size > 1024 * 1024 * 2) {
            this.showErrorToast('info', '', 'File size exceeds the limit of 2 MB');
            return;
          } else {
            this.selectedSMSImages.push(file); // Store multiple files
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const base64String = e.target.result.split('base64,')[1];
              this.uploadImageToServer(base64String, file.type.split('/')[1], file.name.split('.').slice(0, -1).join('.'));
            };
            reader.readAsDataURL(file);
          }
        } else {
          this.showErrorToast('info', '', 'Please select a valid format (png, jpg, jpeg, gif)');
        }
      }
    }
  }

  onSMSSearchHistory(): void {
    this.smsSearchIndexes = [];
    const searchValue = this.searchValueFc.value.trim();
    if (searchValue) {
      this.searchSMSChat = true;
      this.scrollToLastSearchedWord(searchValue);
    }
  }

  scrollToLastSearchedWord(searchWord: any): void {
    for (let i = this.smsChatMessageList.length - 1; i >= 0; i--) {
      const messageText = this.smsChatMessageList[i].text.toLowerCase();
      if (messageText.includes(searchWord.toLowerCase())) {
        this.smsSearchIndexes.push(i);
      }
    }
    !!this.smsSearchIndexes.length ? 
      this.smsSearchCount = this.smsSearchIndexes.length :
      this.showErrorToast('info', '', 'No Search Found');
    if (this.smsSearchCount > 0) {
      this.smsCurrentSearchIndex = 0;
      this.scrollToIndex(this.smsSearchIndexes[this.smsCurrentSearchIndex]);
    }
  }

  scrollToIndex(index: number): void {
    const element = document.getElementById('message-' + index);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToNext(): void {
    if (this.smsSearchCount > 0 && this.smsCurrentSearchIndex < this.smsSearchCount - 1) {
      this.smsCurrentSearchIndex++;
      this.scrollToIndex(this.smsSearchIndexes[this.smsCurrentSearchIndex]);
    }
  }

  scrollToPrevious(): void {
    if (this.smsSearchCount > 0 && this.smsCurrentSearchIndex > 0) {
      this.smsCurrentSearchIndex--;
      this.scrollToIndex(this.smsSearchIndexes[this.smsCurrentSearchIndex]);
    }
  }

  highlightText(content: string, searchText: string): string {
    if (!searchText || searchText === '') {
      this.searchSMSChat = false;
      return content;
    }
    const pattern = new RegExp(searchText, 'gi');
    return content.replace(pattern, match => `<span class="highlight">${match}</span>`);
  }

  openMoreOptions(item: any, innerReply: boolean): void {
    this.showEmailReplies=false;
    this.isInnerReply = innerReply;
    this.sendMailFlag = true;
    this.isReply = true;
    this.com_history_id = item._id;
    this.sendMailForm.get('from').setValue(item.from || '');
    this.sendMailForm.get('subject').setValue(item.subject);
    if (item.direction.toLowerCase() === 'outbound') {
      this.sendMailForm.get('from').disable();
    } else {
      this.sendMailForm.get('from').setValue('');
    }
    this.sendMailForm.get('subject').disable();
    this.sendMailForm.get('cc').setValue(item.sender_config.cc);
    this.sendMailForm.get('bcc').setValue(item.sender_config.bcc);
    this.ccFlag = item.sender_config.cc.length ? true : false; 
    this.bccFlag = item.sender_config.bcc.length ? true : false; 
  }

  replyEmail(): void {
    if (this.sendMailForm.invalid || !this.editorContent.trim()) {
      this.sendMailForm.get('from').markAsTouched();
      this.sendMailForm.get('to').markAsTouched();
      this.sendMailForm.get('subject').markAsTouched();
      this.editorContent.trim() ? null : this.showErrorToast('error', '', 'content is required');
    } else {
      const requestBody = {
        type: "reply",
        com_history_id: this.com_history_id,
        content: this.editorContent,
        from: this.sendMailForm.get('from').value,
        to: this.sendMailForm.get('to').value
      };
      if (this.isNotifyMeEmail) {
        requestBody['is_notify'] = true;
      }
      this.sendMailForm.value.cc.length ? (requestBody['cc'] = this.sendMailForm.value.cc) : null;
      this.sendMailForm.value.bcc.length ? (requestBody['bcc'] = this.sendMailForm.value.bcc) : null;
    
      this.apiCallFlag = true;
      this.PatientService.replyPatientEmail(requestBody).subscribe({
        next: (res) => {
          if (res.status) {
            this.spinner.show();
            this._Global.IsReady = false;
            this.filteredEmails = [];
            // TODO: This should be event driven, Backend should send emit upon SMS/Email sent
            setTimeout(() => {
              console.log('LoadCommunicationHistory called from ===> Reply Email Timeout');
              this.LoadCommunicationHistory(1,true);
            }, 5000);
            this.sendMailFlag = false;
            this.showEmailReplies = false;
            this.isReply = false;
            this.editorContent = this.initialContent;
            this.createPatientSendForm();
            this.apiCallFlag = false;
          } 
          else {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.showErrorToast('error', '', res.message);
            this.apiCallFlag = false;
          }
        },
        error: (err) => {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.showErrorToast('error', '', err);
          this.apiCallFlag = false;
        }
      });
    }
  }
  

  sendEmail(): void {
    if (this.sendMailForm.invalid || !this.editorContent.trim()) {
      this.sendMailForm.get('from').markAsTouched();
      // this.sendMailForm.get('to').markAsTouched();
      this.sendMailForm.get('subject').markAsTouched();
      this.replaceVariablesFromScript(this.editorContent).trim() ? null : this.showErrorToast('error', '', 'content is required');
      if( this.sendMailForm.get('to').invalid){
        this.showErrorToast('error', '', 'Register Email Id first in Patient Profile');
      }
    } else {
      const requestBody = {
        type: "email",
        patient_id: this.rowData.Id,
        from: this.sendMailForm.value.from,
        to: this.sendMailForm.value.to,
        content: (this.editorContent || '').replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
        subject: this.sendMailForm.value.subject
      }
      if (this.isNotifyMeEmail) {
        requestBody['is_notify'] = true;
      }
      this.sendMailForm.value.cc.length ? requestBody['cc'] = this.sendMailForm.value.cc : null;
      this.sendMailForm.value.bcc.length ? requestBody['bcc'] = this.sendMailForm.value.bcc : null;
      
      this.apiCallFlag = true;
      this.PatientService.sendPatientEmail(requestBody).subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.show();
          this._Global.IsReady = false;
          this.filteredEmails = [];
          // TODO: This should be event driven, Backend should send emit upon SMS/Email sent
          setTimeout(() => {
            console.log('LoadCommunicationHistory called from ===> Send Email Timeout');
            this.LoadCommunicationHistory(1,true);
          }, 5000);
          this.sendMailFlag = false;
          this.showEmailReplies = false;
          this.isReply = false;
          this.editorContent = this.initialContent;
          this.createPatientSendForm();
          this.apiCallFlag = false;
        } else {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.showErrorToast('error', '', res.message);
          this.apiCallFlag = false;
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.showErrorToast('error', '', err);
        this.apiCallFlag = false;
      }
    });
  }
}

  onGoBack() {
    this.sendMailFlag = false;
    this.ccFlag = false;
    this.bccFlag = false;
    this.isGoBack = false;
    this.showEmailReplies = false;
    this.isInnerReply = false
    this.expandMailContentIdx = undefined;
    this.isReply = false;
    this.apiCallFlag = false;
    this.editorContent = this.initialContent;
    this.createPatientSendForm();
  }

  toggleCcFlag(): void {
    this.ccFlag = !this.ccFlag;
  }

  toggleBccFlag(): void {
    this.bccFlag = !this.bccFlag;
  }

  updateEditorContent(content: string): void {

    this.editorContent = content;
    const matches = this.editorContent.match(/{{(.*?)}}/g);
    if (matches) {
      for (const match of matches) {
        const textInside = match.substring(2, match.length - 2).trim();
        if (textInside) {
        } else {
          this.positionOptionsContainer({ top: 0, left: 0 }, false);
        }
      }
    } else {
      this.positionOptionsContainer({ top: 0, left: 0 }, false);
    }
  }

  getCommunicationConversation() {
    this.EmailDetailLoaderFlag= true
    let params = new HttpParams();
    params = params.set('type', this.communicationType === 'call' ? 'phone' : this.communicationType).set('isSidePanel', '1').set('parent_id', this.expandMailContentData._id);

    this.PatientService.GeCommunicationHistoryById(this.PatientId, params).subscribe(res => {

      this.communicationConversation = res.data.map(data => {
        data.sent_at = this.datePipe.transform(data.sent_at, 'MM/dd/yyyy | hh:mm a');
        data.received_at = this.datePipe.transform(data.received_at, 'MM/dd/yyyy | hh:mm a');
        return data;
      });
      this.spinner.hide();
      this._Global.IsReady = true;
      this.EmailDetailLoaderFlag= false
      this.communicationConversation.unshift(this.expandMailContentData);
      this.expandMailContentIdx = this.communicationConversation.length ? this.communicationConversation.length - 1 : undefined;
    })
  }

  onClickEmailItem(item: any) {
    this.expandMailContentData = item;
    this.communicationConversation = [];
    this.getCommunicationConversation();
    this.showEmailReplies = !this.showEmailReplies
  }

  onClickReplyItem(idx: number) {
    this.expandMailContentIdx = this.expandMailContentIdx === idx ? undefined : idx;
  }

  openArrowMenu(event: Event) {
    event.stopPropagation();
  }


  positionOptionsContainer(textPosition: { top: number; left: number }, display: boolean): void {
    const optionsContainer = document.getElementById('suggestions-container');
    if (optionsContainer) {
      optionsContainer.style.top = display ? `${textPosition.top + 60}px` : '0px'; // Adjust position as needed
      optionsContainer.style.left = display ? `${textPosition.left}px` : '0px';
      optionsContainer.style.display = display ? 'block' : 'none';
    }
  }

  calculateDisplayedLength(): number {
    if (!this.editorContent) {
      return 0;
    }
    const tempElement = document.createElement('div');
    tempElement.innerHTML = this.editorContent;
    const textContent = tempElement.textContent || tempElement.innerText || '';
    const adjustedLength = Math.max(0, textContent.length);
    return adjustedLength;
  }
 
  emojiPickerStyle = {};

  // sms and email emoji
  toggleEmojiPicker() {

    if (this.communicationType === 'email') {
      this.isEmojiButtonClickForEmail = !this.isEmojiButtonClickForEmail;
      this.isEmojiPickerOpenForEmail = this.isEmojiButtonClickForEmail; // Add this line
    
      if (this.isEmojiPickerOpenForEmail) {
        // Set the emoji picker style to appear above the editor
        this.emojiPickerStyle = {
          position: 'absolute',
          bottom: `${60}px`,
          left: `${40}px`
        };
      }
    }
    

    if (this.communicationType === 'sms') {
      this.isEmojiButtonClickForSMS = !this.isEmojiButtonClickForSMS;
      this.isEmojiPickerOpenForSMS =  this.isEmojiButtonClickForSMS;
    }
  }
 
  emojiSelect(e: any): void {

    if (this.communicationType === 'sms') {
      const emoji = e.emoji.native;
      const currentValue = !!this.smsInputValue.value ? this.smsInputValue.value : null;
      const newValue = !!currentValue ? currentValue.slice(0, this.cursorPosition) + emoji + currentValue.slice(this.cursorPosition) : emoji;
      this.smsInputValue.setValue(newValue);
      this.cursorPosition += emoji.length;
  
      setTimeout(() => {
        this.textInput.nativeElement.setSelectionRange(this.cursorPosition, this.cursorPosition);
      }, 0);
    }
  
    if (this.communicationType === 'email') {
      const emoji = e.emoji.native;  // Use 'e' instead of 'event'
      if (emoji) {
        // Insert the emoji at the current cursor position
        this.rteObj.executeCommand('insertText', emoji);
        this.isEmojiPickerOpenForEmail = false;
      }
    }
  }

  updateCharCount(content: string) {
    this.emailCharCount = content.length;
  }

  scrollToBottomForChatList(): void {
    this.cdr.detectChanges();
    const smsChatElement = document.querySelector('.sms-chat');
    if (smsChatElement) {
      smsChatElement.scroll({
        top: smsChatElement.scrollHeight,
        behavior: 'smooth'
      });
    }
    this.cdr.detectChanges();
  }

  getSMSHistoryData(data:any): void {
    this.smsChatMessageList = [];
    data.forEach(v => {
      this.smsChatMessageList.push(
        {
          text: !!v.content ? (v.content).replace(/\n/g, '<br>') : v.content,
          date: v.direction === 'Outbound' ? v.sent_at : v.received_at,
          is_sent: v.direction === 'Outbound' ? true : false,
          media: !!v.media_url ? v.media_url : [], 
          sending: false
        }
      );
    });
    this.shouldScrollToBottom = true;
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    if (this.shouldScrollToBottom) {
      this.scrollToBottomForChatList();
      this.shouldScrollToBottom = false; 
    }
  }

  updateDivValue(event: any) {
    this.smsInputValue.setValue(event.target.innerText);
  }

  sendMessage(isFailedMess?: boolean, item?: any): void {
    this.isEmojiPickerOpenForSMS = false;
    this.isEmojiButtonClickForSMS = false;
  
    if (!this.smsSenderPhone.value) {
      this.showErrorToast('info', '', 'Please select sender phone number');
    } else {
      let trimedSMSMessage = null;
      if (!!this.smsInputValue.value) {
        trimedSMSMessage = this.replaceVariablesFromScript(this.smsInputValue.value).trim();
      }
  
      if (!!trimedSMSMessage || (this.smsImageUrls && this.smsImageUrls.length > 0) || isFailedMess) {
  
        let smsText = !!item && isFailedMess ? item.text : trimedSMSMessage || null;
        let smsMedia = !!item && isFailedMess ? item.media : (this.smsImageUrls && this.smsImageUrls.length > 0) ? this.smsImageUrls : null;
        const patientPhone = this.rowData && this.rowData.Phone ? this.rowData.Phone : this.patientDetails.Phone;
        let payload: any = {
          type: "sms",
          patient_id: this.PatientId,
          to: patientPhone,
          from: this.smsSenderPhone.value,
          content: !!smsText ? smsText.replace(/<br\s*\/?>/g, '\n').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>') : null
        }
  
        if (this.smsImageUrls && this.smsImageUrls.length > 0 || isFailedMess) {
          payload['media'] = smsMedia;  // Now using the array smsImageUrls
        }
  
        if (isFailedMess && !!item) {
          this.smsChatMessageList = this.smsChatMessageList.filter(v => v.text !== item.text);
        }
  
        this.smsChatMessageList.push({
          text: !!smsText ? smsText.replace(/\n/g, '<br>') : null,
          date: null,
          is_sent: true,
          media: smsMedia,
          sending: true
        });
  
        this.sendingMessage = true;
        this.smsInputValue.reset();
        this.selectedSMSImages = [];  // Clear the array after sending
        this.smsImageUrls = [];  // Clear the array of image URLs
        this.scrollToBottomForChatList();
  
        let sendData = this.communicationService.sendSMS(payload).subscribe({
          next: (res: any) => {
            if (res.status) {
              // TODO: This should be event driven, Backend should send emit upon SMS/Email sent
              setTimeout(() => {
                this.LoadCommunicationHistory(1, true);
              }, 6000);
            } else {
              this.lastFailedEle();
            }
          },
          error: (err: any) => {
            this.showErrorToast('error', '', err.error.message);
            this.lastFailedEle();
          }
        });
        this.subs.add(sendData);
      } else {
        this.showErrorToast('info', '', 'Add Your Message');
      }
    }
  }
  
  

  lastFailedEle(): void {
    this.sendingMessage = false;
    this.smsChatMessageList.forEach((v, i) => {
      if (i === this.smsChatMessageList.length - 1) {
        v['failed'] = true;
        v['sending'] = false;
      }
    });
  }
 
  ngOnDestroy(): void {
    // To Stop receiving Online user events
    this.socketService.leaveRoom('online-user-event');
    if (this.PatientId) {
      this.socketService.leaveRoom(`patient_profiles#${this.PatientId}`);
    }
    this.resetAllValues();
  }

  openFileInput() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  clearSelectedFile(index: number): void {
    this.selectedSMSImages.splice(index, 1);
    this.smsImageUrls = []; 
  }
  

  getSenderPhone(): void {
    // if (this.AllPhoneList.length > 0) return;
    this.histoyLoaderFlag= true;
    let getList = this.communicationService.getSenderPhoneList().subscribe({
      next: (res) => {
        if (res.status && this.patientDetails) {
            this.AllPhoneList = res.data
            this.senderPhone = res.data.filter(res => (res.state === this.patientDetails['patientLastInteraction.StateName']));
            const data = this.senderPhone.find(res => (res.state === this.patientDetails['patientLastInteraction.StateName']));
            data && this.senderPhone.length == 1 ? this.smsSenderPhone.setValue(this.senderPhone[0].phone) : null;
            data && this.senderPhone.length == 1 ? this.callSenderPhone.setValue(this.senderPhone[0].phone) : null;
            this.checkPhoneNumber = this.senderPhone.length === 0;
              // Set phone number based on availability
              if (this.senderPhone.length > 0) {
                this.numberVariable = this.senderPhone[0].phone;
              } else if (this.AllPhoneList.length > 0) {
                this.numberVariable = this.AllPhoneList[0].phone;
              } else {
                this.numberVariable = '+1 346 550 9559';
               }
               this.editorContent = `<p>&nbsp;</p> 
               <p><span> ${this.loginUser.name}</span><br />DM Clinical Research<br />Phone: ${this.numberVariable}  ${this.userdata.recruiterid ? '( ext: ' + this.userdata.recruiterid + ' )' : '' }<br />Email: <span style="color: #3598db;"> ${this.loginUser.email}</span><br />Website: <span style="color: #3598db;"><a href="http://www.dmclinicalresearch.com">www.dmclinicalresearch.com</a></span></p>
               <p><img src=\"https://dmclinicstaging.blob.core.windows.net/commodule/Footer.png\" alt=\"Custom Image\" width="100%" height="100%" /></p>`;
               this.initialContent = `<p>&nbsp;</p><br /><br />
                 <p><span> ${this.loginUser.name}</span><br />DM Clinical Research<br />Phone:${this.numberVariable}  ${this.userdata.recruiterid ? '( ext: ' + this.userdata.recruiterid + ' )' : '' }<br />Email: <span style="color: #3598db;"> ${this.loginUser.email}</span><br />Website: <span style="color: #3598db;"><a href="http://www.dmclinicalresearch.com">www.dmclinicalresearch.com</a></span></p>
                 <p><img src=\"https://dmclinicstaging.blob.core.windows.net/commodule/Footer.png\" alt=\"Custom Image\" width="100%" height="100%" /></p>`;
               this.editorContent = this.initialContent;

          // Below code fetch communication history upon tab switch to Call / SMS
          if (this.communicationType === 'call' || (this.communicationType === 'sms' && !this.smsSenderPhone.value)) {
            console.log('LoadCommunicationHistory called from ===> Get Sender Phone Value get');
            this.LoadCommunicationHistory(1,true);
          }
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      },
      error: (err: any) => {
        console.log("🚀 ~ SideNavBarComponent ~ getList ~ err:", err)
        this.spinner.hide();
        this._Global.IsReady = true;
        this.showErrorToast('error', '', err.message);
      }
    });

    this.subs.add(getList);
  }




  onScroll(event: any, status:string): void {  // to handle api call , when user scroll upto bottom
    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if(status === 'phone'){
        this.LoadCommunicationHistory(this.PhoneActivePageNo + 1 )
      }else if(status === 'email'){
        this.LoadCommunicationHistory(this.EmailActivePageNo + 1 )
      }
    }
  }

  LoadCommunicationHistory(pageNo = 1, isDefault = false) {
    if (!this.sendingMessage && isDefault) {
      this.histoyLoaderFlag= true
    }

   if((this.isMorePhone && this.communicationType === 'call') || (this.isMoreEmail && this.communicationType === 'email') || isDefault){   // if communicationType is match && is more is true 

      if(this.isMorePhone && this.communicationType === 'call'){    // to active the loader
        this.isMorePhoneLoader =  true
      }else if(this.isMoreEmail && this.communicationType === 'email'){
        this.isMoreEmailLoader = true
      }

    let params = new HttpParams()
    if(this.isMorePhone && this.communicationType === 'call' || this.isMoreEmail && this.communicationType === 'email' ){
      params = params.append('PageNo', String(pageNo))
    }
    if(this.isMorePhone && this.communicationType === 'sms'){
      params = params.append('is_pagination', '0');
    }

    params = params.set('type', this.communicationType === 'call' ? 'phone' : this.communicationType).set('isSidePanel', '1');
    
    if (!!this.smsSenderPhone.value && this.communicationType === 'sms') {
      params = params.append('to_number', this.smsSenderPhone.value);
    }

    const getData = this.PatientService.GeCommunicationHistoryById(this.PatientId, params).subscribe({
      next: (res:any) => {
        if (res.status) {
          this.histoyLoaderFlag= false    // to false the loader on sucess
          this.isMorePhoneLoader =  false
          this.isMoreEmailLoader = false
          if (!!res.data.length) {
            const newData = res.data.map(data => {
              data.sent_at = this.datePipe.transform(data.sent_at, 'MM/dd/yyyy | hh:mm a');
              data.received_at = this.datePipe.transform(data.received_at, 'MM/dd/yyyy | hh:mm a');
              data.call_start_time = data.call_obj && this.datePipe.transform(data.call_obj.start_time, 'MM/dd/yyyy | hh:mm a');
              data.direction = data.direction.charAt(0).toUpperCase() + data.direction.slice(1);
              data.type = typeof data.type === 'string' ?
                (data.type.toLowerCase() === 'sms' ? 'SMS' : data.type.replace(/^\w/, c => c.toUpperCase())) :
                data.type;
              return data;
            });
            if (isDefault) {
              this.CommunicationHistory = newData;
            } else {
              this.CommunicationHistory = [...this.CommunicationHistory, ...newData];
            }
            if(this.communicationType === 'call'){  // to set isMore data available for pagination or not 
              this.isMorePhone =  res.pagination.isMore;
              this.PhoneActivePageNo = Number(res.pagination.currentPage)
           
            }else if(this.communicationType === 'email'){
              this.isMoreEmail =  res.pagination.isMore;
              this.EmailLoaderFlag= false;
              this.EmailActivePageNo = Number(res.pagination.currentPage)
            }       
            if(this.communicationType === 'email'){
              this.filteredEmails = this.CommunicationHistory;
              this.EmailLoaderFlag= false;
            }
            if (this.communicationType === 'sms') {
              this.sendingMessage = false;
              this.getSMSHistoryData(this.CommunicationHistory);
            }
            if (this.communicationType === 'email' && this.sendMailFlag) {
              this.showEmailReplies = false;
              this.isReply = false;
              this.sendMailFlag = false;
            }
          } else {
            if(this.communicationType === 'email'){
              this.EmailLoaderFlag= false;
            }
            this.sendingMessage = false;
            this.getSMSHistoryData([]);
          }
        } else {
          this.isMorePhoneLoader =  false
          this.isMoreEmailLoader = false
          this.histoyLoaderFlag= false
          this.showErrorToast('error', '', res.message);
        }
        this.spinner.hide();
        this._Global.IsReady = true;
      },
      error: (err:any) => {
        this.isMorePhoneLoader =  false
        this.isMoreEmailLoader = false
        this.histoyLoaderFlag= false
        this.showErrorToast('error', '', err.message);
      }
    });
    this.subs.add(getData);
   }
  }

  // get sender email 
  getSenderEmail(): void {
    this.EmailLoaderFlag= true;
    let params = new HttpParams()
    .set('isSidePanel', 'true')

    const getData = this.communicationService.getSenderEmail(params).subscribe({
      next: (res) => {
        if (res.status) {
          this.senderEmailAddressList = res.data.map(data => data.email);
          this.emailList = res.data.map(data => data.email);
          var isConneted = res.data.filter(data => data.email === this.userObject.user.email);
          if (isConneted) {
            this.sendMailForm.get('from').setValue(this.userObject.user.email || '');
          }
          console.log('LoadCommunicationHistory called from ===> Get Sender Email');
          this.LoadCommunicationHistory(1,true);
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.EmailLoaderFlag= false;
        this.showErrorToast('error', '', err.message);
      }
    });
    this.subs.add(getData);
  }

  showErrorToast(type: string, title: string, message: string): void {
    if (!this.toastrActive) {
      this.toastrActive = true;
      const toast = this.toastService[type](title, message, this.toastOptions);
      toast.onHidden.subscribe(() => this.toastrActive = false);
    }
  }

  getInitials(username: string): string {
    if (username) {
      const parts = username.split(' ');
      let initials = '';
      if (parts.length === 1) {
        initials = parts[0].charAt(0) + parts[0].charAt(1); // Take the first two characters of the single word
      } else {
        for (const part of parts) {
          initials += part.charAt(0);
        }
      }
      return initials.toUpperCase();
    }
    return '';
  }
  
getAvatarBackgroundColor(): string {
   return '#004B76';
}

updateCommunicationHistory = (commObj) => {
  console.log(`${new Date().toISOString()} file: patientprofile.component.ts:739 ~ PatientProfileComponent ~ commObj:`, this.PatientId, commObj);
  
  if (!commObj.patient_obj || !commObj.patient_obj.Id || (this.PatientId != commObj.patient_obj.Id)) {
      return;
  }

  const isCallExists = this.CommunicationHistory.find((ch) => {
      if (ch.call_obj && commObj.call_obj) return ch.call_obj.call_sid === commObj.call_obj.call_sid;
  });
  console.log(`${new Date().toISOString()} ~ file: patientprofile.component.ts:744 ~ PatientProfileComponent ~ isCallExists ~ isCallExists:`, isCallExists);

  // For any communication update need to reload the list
  this.backgroundRefresh = true;
  this.LoadCommunicationHistory(1, true);
  
  if (isCallExists && isCallExists.call_obj.call_type.toLowerCase() !== commObj.call_obj.call_type.toLowerCase()) {
    const toastRef = this.toastService.info( "Call updated for this patient. Refreshing Communication history.", '', this.toastOptions);
    this.toastId = toastRef.toastId;
    if (this.toastId !== null) {
      this.toastService.remove(this.toastId);
    }
    return;
}
}
}
