import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LovService {
    public isCallApi = new Subject<boolean>();
    public _LovData: Lov[];
    public _SMSConfigList: Lov[];
    public _SMSmasterList: Lov[];

    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService,
        public router: Router,) { }

    LoadLov(refId: string) {
        try {
            this._http.get(myGlobals._apiPath + 'Lov?refId=' + refId + "&getType=GetLovById", { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._LovData = <Lov[]>data;
                },
                    error => {
                        this._Global.handleError(error);
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetLov(refId: string) {
        this.LoadLov(refId);
    }

    getLovDataById(refId: string): Observable<Lov> {
        try {
            return this._http.get(myGlobals._apiPath + 'Lov?refId=' + refId + "&getType=GetLovById", { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    getCongifSMSResponse(searcString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lov?getType=GetSMSResponseList' + "&searchString=" + searcString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._SMSConfigList = <Lov[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetSMSMaster() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lov?getType=GetSMSMaster', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._SMSmasterList = <Lov[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    Save(body: any) {
        try {
            this.isCallApi.next(false)
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Lov', body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.getCongifSMSResponse("")
                    this._Global.showSuccessMsg("Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(true)
                },
                    error => {
                        this.isCallApi.next(false)
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    Edit(body: any) {
        try {
            this.isCallApi.next(false)
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'Lov', body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.getCongifSMSResponse("")
                    this._Global.showSuccessMsg("Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(true)
                },
                    error => {
                        this.isCallApi.next(false)
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    SaveAndEdit(body: any, isEdit: boolean) {
        if (isEdit) {
            this.Edit(body)
        }
        else {
            this.Save(body)
        }
    }
}

