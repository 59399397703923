export enum CallListType {
    Recruiter = "recruiter",
    Screener = "screener",
    DNQScreener = "DNQScreener",
    PreScreener = "prescreener",
    PatientNotes = "patientnotes",
    VisitCompletedCallList = "VisitCompletedCallList",
}

export enum OperationReportsType {
    PatientStudyVisitReport = "patientstudyvisit",
    MissedVisitReport = "missedvisit",
    CompletedOutOfWindowVisitReport = "compvisit",
    FutureOutOfWindowReport = "futurevisit",
    VisitNotScheduledReport = "visitnotscheduled",
}

export enum RecruitmentPSReportsType {
    PatientPreScreenReport = "patientprescreenreport",
    MostRecentInteractionsReport = "mostrecentinteractionsreport",
    CampaignReport = "campaignreport",
    RecruiterReport = "RecruiterReport",
}

export enum Params {
    PageSize = 20
}

export enum Recruiter {
    GetPatientCount = "GetPatientCount",
    AssignPatients = "AssignPatients",
    ReleaseRecruiterPatients = "ReleaseRecruiterPatients",
    ReleasePatients = "ReleasePatients"
}

export enum Status {
    VisitComplete = 55,
    NoShow = 42,
}


export enum LovStatusMasterID {
    EmployeeCategory = "EmployeeCategory",
    StudyVisitType = "StudyVisitType",
    RecruitmentCalllistStatus = "Recruitment Call list Status",
    Language = "Language",
    Transportation = "Transportation",
    CampaignType = "Campaign Type",
    TemplateType = "Template Type",
    EmailConfigurationSendTo = "Email Configuration Send To",
    SMSIntervalHrs = "SMS Interval Hrs",
    Sponsor = "Sponsor",
    Phase = "Phase",
    PatientPopulation = "PatientPopulation",
    StudyType = "StudyType",
    IRB = "IRB",
    CRO = "CRO",
    BusinessHrs = "BusinessHrs",
    SiteTimeZones = "SiteTimeZones",

}

export enum ActionType {
    MarkInteraction = "e-icons e-plus",
    TransferPatient = "e-icons e-align-top-2",
    Edit = "e-edit e-icons",
    EditLine = "e-edit-line e-icons",
    EditMarkInt = "e-icons e-hyperlink-edit",
    Delete = "e-icons e-trash",
    Notes = "e-icons e-file",
    ReAssignList = "e-icons e-upload-1",
    Chat = 'e-icons e-message',
    ValidateLead = "e-icons e-group-icon",
    Indication = "e-icons e-justify",
    AssignPatient = "e-icons e-paste-text-only",
    ReleasePatient = "e-icons e-paste-match-destination",
    ReleaseAllPatient = "e-icons e-align-top-2",
    VisitorFLowPhoneCall = "e-icons e-open-link",
    VisitorFLowInClinic = "e-icons e-table-insert-row",
    CreateStudyVisit = "e-icons e-circle-add",
    CreateReferralSource = "e-icons e-circle-add",
    CreateSiteRooms = "e-freeze-column e-icons",
    CreateSitePI = "e-people e-icons",
    CreateVaccine = "e-icons e-calculated-member",
    ShowCampaigns = "e-icons e-vertical-align-top",
    Description = "e-icons e-description",
    Eye = "e-icons e-eye",
    Accept = "e-icons e-accept",
    Pin= "e-icons e-pin",
    Unpin="e-icons e-unpin",
    delete="e-icons e-delete",
    edit="e-icons e-edit-line",
    Decline="e-icons e-decline",
    Restore="e-icons e-restore",
    Send="e-icons e-send-1",
    SendGrey= "e-icons e-send-2",
}

export enum SiteColumnIndexType {
    PreScreenStatus = 1,
    CurrentProcedure = 2,
    Study = 3,
    Visit = 4,
    PatientName = 5,
    DateOfBirth = 6,
    Sex = 7,
    RoomNumber = 8,
    TempCheck = 9,
    IPAdministration = 10,
    PostDoseTime = 11,
    IsDischarged = 12,
    DischargeTime = 13,
    AdministeredTime = 14,
    Reschedule = 15,
    NoShow = 16,
}

export enum LeadsStatusEnumns {
    NewLead = 0,
    AssignableLead = 1,
    UnAssignableLead = 2,
    AssignedLead = 3,
    DiscardLead = 4,
    MergedLead = 5,
    IncorrectLead = 6,
}

export enum StatesTimeZone {
    SamoaStandardTime = 1,
    HawaiiAleutianStandardTime = 2,
    AlaskaStandardTime = 3,
    PacificStandardTime = 4,
    MountainStandardTime = 5,
    CentralStandardTime = 6,
    EasternStandardTime = 7,
    AtlanticStandardTime = 8,
    ChamorroStandardTime = 9,
}

export enum PoolStatus {
    Active = 1,
    Inactive = 2,
    Draft = 3,
    Publish = 4,
    Deleted = 5,
    Released = 6,
    Stealed = 7,
    Original = 8,
}