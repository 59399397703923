import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalfunctionService } from '../../../Shared/Services/FrontServices/globalfunction.service';
import { SiteService } from '../../../Shared/Services/BackServices/ComTrak/Setups/site.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { StudyVisit } from '../../../Shared/Models/BackModels/ComTrak/Setups/StudyVisit';
import { StudyvisitService } from '../../../Shared/Services/BackServices/ComTrak/Setups/studyvisit.service';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { ExcelService } from '@sharedservices/FrontServices/Export/excel.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ValueTransformer } from '@angular/compiler/src/util';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { ViewportScroller } from '@angular/common';
import { CRIOService } from '@sharedservices/BackServices/ComTrak/CRIO/crio.service';

@Component({
  selector: 'app-createstudyvisit',
  templateUrl: './createstudyvisit.component.html',
  styleUrls: ['./createstudyvisit.component.scss', '../../style.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),

    ]),
  ]
})
export class CreatestudyvisitComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  StudyVisitForm: FormGroup
  AnchorForm: FormGroup
  submitted: boolean = false;
  public _data: StudyVisit;
  public studyVisitArray: StudyVisit[] = [];
  public studyVisitGrid : any[];
  isedit: boolean = false;
  Id: number;
  actionType: string;
  disbaledFields: boolean;
  isCallVisitsApi: boolean;
  dropdownSettingsCRIO: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    allowSearchFilter: true,
    enableCheckAll: false
  };
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'siteId',
    textField: 'siteName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    maxHeight: 197,
    allowSearchFilter: true
  };
  isDelete: boolean = false;
  CRIOStudyVisits: any[] = [];
  CRIOStudies: any[];
  constructor(public dialogRef: MatDialogRef<CreatestudyvisitComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public excelService: ExcelService,
    public fb: FormBuilder, public studyvisitService: StudyvisitService, public studyService: StudyService, public lovService: LovService,
    public siteService: SiteService, public _Global: GlobalfunctionService, private crioService: CRIOService,public spinner: NgxSpinnerService) { }

  ngOnInit() {
    this._Global.gotoTop();
    this.initForm()
    //console.log('This is an data call list',this.data.callList)
    this.lovService.GetLov(LovStatusMasterID.StudyVisitType)
    this.studyService.LoadStudies('')
    this.studyvisitService.GetStudyVisitsParentAnchorByStudyId(this.data.StudyId)
    this.siteService.GetSitesByStudy(this.data.StudyId)
    //this.LoadStudyVisitsForSequence()
    this.StudyVisitForm.controls.StudyId.setValue(this.data.StudyId)

    //debugger;
    this.getCRIOStudyBYComtrekStudy();
    this.StudyVisitForm.controls.studyVisitName.setValue(this.data.StudyName)
    this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
    //this.studyVisitGrid = this.studyVisitArray;
    //this.studyVisitGrid = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
    //this.crioService.GetCRIOStudiesByComtrekSite('');
   // this.StudyVisitForm.controls.SiteId.setValue(this.data.SiteId)
    ////debugger;
    //this.crioService.GetCRIOStudiesByComtrekSite(this.data.SiteId);
    //console.log(a);
    this.getStudyWiseCRIOStudyVisits()
    this.studyvisitService.isCallDeleteApi.subscribe(res => {
      if (res) {
        this.studyvisitService.GetStudyVisitsForSequenceByStudyId(this.data.StudyId).subscribe(res => {
          this.data.callList = res
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
        });
       
      }
      // this.crioService.GetCRIOStudiesByComtrekSite(this.data.SiteId).subscribe(res => {
      //   this.data.callList = res
      // });
    })
    this.StudyVisitForm.controls.CRIOStudyVisitId.setValue('')
  }

  initForm() {
    this.StudyVisitForm = this.fb.group({
      StudyId: [, Validators.required],
      Name: ['', Validators.required],
      ActualDayDuration: ['', Validators.required],
      WindowStart: ['', Validators.required],
      WindowEnd: ['', Validators.required],
      VisitTypeId: ['', Validators.required],
      StartDayMarker: [false],
      childAnchorNumber: [this.data.callList[0] ? this.data.callList[0].childAnchorNumber : 0],
      parentAnchorNumber: [0],
      studyVisitSites: [''],
      studyVisitSitesDetail: [[]],
      isUnscheduledVisit: [false],
      Sequence: [, Validators.required],
      studyVisitName: [],
      Id: [''],
      CRIOStudyVisitId: ['', Validators.required],
      CRIOStudyId: ['',Validators.required],
      SiteId: ['',Validators.required],
    })

    this.AnchorForm = this.fb.group({
      studyVisitArray: [[], Validators.required],
      studyVisitObject: ['', Validators.required],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
    if (this.isCallVisitsApi || this.isDelete) {
      this.studyvisitService.LoadStudyVisitsForSequence()
    }

    if (this.isCallVisitsApi) {
      this.studyvisitService.isCallRefSource.next(true)
    }
  }

  get f() {
    return this.StudyVisitForm.controls;
  }

  onSubmit() {
    //debugger;
    this.submitted = true;
    if (this.StudyVisitForm.invalid) {
      return;
    }
    else {
      //let siteIDarray = []
      // if (this.StudyVisitForm.controls.studyVisitSites.value && this.StudyVisitForm.controls.studyVisitSites.value.length > 0) {
      //   // this.StudyVisitForm.controls.studyVisitSites.value.forEach(element => {
      //   //   siteIDarray.push({ SiteID: element.siteId, StudyID: this.data.StudyId })
      //   // });
      //   // this.StudyVisitForm.controls.studyVisitSitesDetail.setValue(siteIDarray)
      // }
      
      let studySites = this.StudyVisitForm.controls.SiteId.value;
      let crioStudy = this.StudyVisitForm.controls.CRIOStudyId.value;
      let crioStudyVisitId = this.StudyVisitForm.controls.CRIOStudyVisitId.value;
      console.log(studySites)
      //console.log(crioStudy)
      //this.StudyVisitForm.controls.VisitTypeId.setValue()
      // let CRIOStudyVisits = this.StudyVisitForm.controls.CRIOStudyVisitId.value
      // this.StudyVisitForm.controls.CRIOStudyVisitId.setValue(CRIOStudyVisits[0].Id)
      this.StudyVisitForm.controls.Id.setValue(this.Id)
      console.log(this.StudyVisitForm.value)
      this.studyvisitService.SaveAndEditStudyVisit(this.Id, this.StudyVisitForm.value, this.isedit)
      
      this.studyvisitService.isCallApiSave.subscribe(res => {
        if (res) {
          //debugger;
          // this.initForm()
          this.StudyVisitForm.reset()
          this.StudyVisitForm.controls.parentAnchorNumber.setValue(0)
          this.StudyVisitForm.controls.StartDayMarker.setValue(false)
          this.StudyVisitForm.controls.isUnscheduledVisit.setValue(false)
          this.StudyVisitForm.controls.childAnchorNumber.setValue(this.data.callList[0] ? this.data.callList[0].childAnchorNumber : 0)
          this.submitted = false
          //this.studyvisitService.LoadStudyVisitsForSequenceByStudyId(this.data.StudyId)
          this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == studySites)
          this.isCallVisitsApi = true
          this.isedit = false
          
          //this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
          this.StudyVisitForm.controls.StudyId.setValue(this.data.StudyId)
          this.StudyVisitForm.controls.studyVisitName.setValue(this.data.StudyName)
          this.StudyVisitForm.controls.SiteId.setValue(studySites)
          this.StudyVisitForm.controls.CRIOStudyId.setValue(crioStudy)
          this.StudyVisitForm.controls.VisitTypeId.setValue("")
        }
        else {
          this.isCallVisitsApi = false
        }
      })
      //this.studyvisitService.LoadStudyVisitsForSequenceByStudyId(this.data.StudyId)
      //this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
      this.StudyVisitForm.controls.SiteId.setValue(studySites)
      this.StudyVisitForm.controls.CRIOStudyId.setValue(crioStudy)
      this.getStudyWiseCRIOStudyVisits();
      this.StudyVisitForm.controls.CRIOStudyVisitId.setValue(crioStudyVisitId)
      
      
    }
  }

  PrintElem(elem) {
    this.excelService.PrintElem('Study Visit', elem)
  }

  ExportToExcel() {
    this.excelService.exportAsExcelFile(this.studyvisitService._studyvisitssequence, 'Study Visit')
  }

  DownloadAsPDF() {
    this.excelService.DownloadAsPDF('Study Visit', this.pdfTable)
  }


  EditSingleAnchor(anchorNumber: number, row: StudyVisit) {
    if (confirm("Are you sure You want to update its anchor against this visit?") == true) {
      //debugger
      console.log(row)
      let anchorNumberValue: number = anchorNumber
      console.log(anchorNumberValue)
      let rowData: StudyVisit = row
      console.log(rowData)
      //rowData.studyVisitSites = rowData.SiteId[0]
      this.studyvisitService.EditSingleAnchor(anchorNumberValue, rowData)
      this.studyvisitService.isCallApiSave.subscribe(res => {
        if (res) {
          this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
        }
        else {
          this.data.callList = this.data.callList
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
        }
      })
    } else {
      this.getAnchors(+row.childAnchorNumber)
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex != event.previousIndex){
    if (confirm("Are you sure You want to change the index of this study visits?") == true) {
      // console.log("Previous = ", this.data.callList[event.currentIndex - 1])
      // console.log("Updated = ", this.data.callList[event.previousIndex])
      // console.log("Next = ", this.data.callList[event.currentIndex])
      //this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
      //debugger;
      if (event.currentIndex > event.previousIndex){
        var PreviousVisitId = this.studyVisitArray[event.currentIndex].Id//this.data.callList[event.currentIndex - 1].Id
      var updatedVisitId = this.studyVisitArray[event.previousIndex].Id//this.data.callList[event.previousIndex].Id
      var NextVisitId = event.currentIndex == (this.studyVisitArray.length - 1) ?  this.studyVisitArray[event.currentIndex].Id : this.studyVisitArray[event.currentIndex+1].Id
      }
      else {
      var PreviousVisitId = event.currentIndex == 0 ? this.studyVisitArray[event.currentIndex].Id : this.studyVisitArray[event.currentIndex - 1].Id//this.data.callList[event.currentIndex - 1].Id
      var updatedVisitId = this.studyVisitArray[event.previousIndex].Id//this.data.callList[event.previousIndex].Id
      var NextVisitId = event.currentIndex == 0 ? this.studyVisitArray[event.currentIndex].Id : this.studyVisitArray[event.currentIndex].Id//this.data.callList[event.currentIndex].Id
      }
      delete this.studyVisitArray[event.previousIndex].Study//this.data.callList[event.previousIndex].Study
      this.studyvisitService.EditStudyVisit(updatedVisitId, PreviousVisitId, NextVisitId, this.studyVisitArray[event.previousIndex]);//this.data.callList[event.previousIndex]);
      this.studyvisitService.isCallApiSave.subscribe(res => {
        if (res) {
          this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
        }
        else {
          this.data.callList = this.data.callList
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == this.StudyVisitForm.controls.SiteId.value)
        }
      })
    }
  }
  }

  SetEditCampaignRefSourceData(row: StudyVisit) {
this.Id = row.Id
    this.isedit = true
    this.studyvisitService.GetStudyVisitById(this.Id, row.StudyVisitSitesDetailId).subscribe(res => {
      this.StudyVisitForm.patchValue(res[0])
      this.StudyVisitForm.controls.StudyId.setValue(this.data.StudyId)
      this.StudyVisitForm.controls.studyVisitName.setValue(this.data.StudyName)
      this.StudyVisitForm.controls.studyVisitSites.setValue(res[0].SiteId)
      this.ChangeCRIOSites();
      this.getStudyWiseCRIOStudyVisits();   

       //var CRIOStudyVisits = this.crioService._CRIOStudyVisits.filter(o => o.Id == res[0].CRIOStudyVisitId)
       this.StudyVisitForm.controls.CRIOStudyVisitId.setValue(res[0].CRIOStudyVisitId)
       

    }, err => {
      this._Global.showErrorMsg(err)
    })

  }


  EditStudyVisitAnchor(row: StudyVisit) {
    try {
      if (confirm("Are you sure You want to start anchor against this visit?") == true) {
        //debugger;
        if (row.parentAnchorNumber == 0) {
          let siteId = row.SiteId
          let studyVisit: StudyVisit = row
          var ind = this.data.callList.findIndex(o => o.Id == row.Id)
          var parentAnchorArray: StudyVisit[] = []
          var array: StudyVisit[] = []
          var finalArray: StudyVisit[] = []
          let dataArray: StudyVisit[] = []
         
          parentAnchorArray = this.data.callList.filter(o => o.parentAnchorNumber != 0 && o.SiteId == siteId)
            .sort((a, b) => a.parentAnchorNumber - b.parentAnchorNumber)
          if (parentAnchorArray.length != 0) {
            let index = parentAnchorArray.length - 1
            var obj = parentAnchorArray[index]
          }
          if (obj) {
            studyVisit.parentAnchorNumber = obj.parentAnchorNumber + 1
          }
          else {
            studyVisit.parentAnchorNumber = 1
          }
          array = this.data.callList.filter((element, index) => index >= ind && element.childAnchorNumber == row.childAnchorNumber && element.SiteId == siteId)
          array.forEach(element => {
            finalArray = this.data.callList.filter(o => o.parentAnchorNumber != 0 && o.parentAnchorNumber == element.parentAnchorNumber && element.SiteId == siteId)
          })
          // console.log("-------------------------((((((((((( = ", finalArray)
          if (finalArray.length > 1) {
            let index = array.findIndex(o => o.Id == finalArray[0].Id)
            array.splice(index, finalArray[0].Id)
          }
          array.forEach(element => {
            if (element.Id == studyVisit.Id) {
              element.childAnchorNumber = studyVisit.parentAnchorNumber - 1
            }
            else {
              element.childAnchorNumber = studyVisit.parentAnchorNumber
            }
          });
          array.forEach(element => {
            dataArray.push({
              ActualDayDuration: element.ActualDayDuration,
              Id: element.Id,
              Indexing: element.Indexing,
              Name: element.Name,
              Sequence: element.Sequence,
              StartDayMarker: element.StartDayMarker,
              StudyId: element.StudyId,
              VisitTypeId: element.VisitTypeId,
              WindowEnd: element.WindowEnd,
              WindowStart: element.WindowStart,
              childAnchorNumber: element.childAnchorNumber,
              parentAnchorNumber: element.parentAnchorNumber,
              studyVisitSites: element.studyVisitSites,
              studyVisitSitesDetail: element.studyVisitSitesDetail,
            })
          })
          // console.log("------------------------", array)
          // return
          this.AnchorForm.controls.studyVisitObject.setValue(studyVisit)
          this.AnchorForm.controls.studyVisitArray.setValue(array)
          this.studyvisitService.EditStudyVisitAnchor(dataArray)
          this.studyvisitService.isCallApiSave.subscribe(res => {
            if (res) {
              this.isCallVisitsApi = true
              this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
              this.studyVisitArray = this.data.callList.filter(x => x.SiteId == siteId)
            }
            else {
              this.isCallVisitsApi = false
              this.data.callList = this.data.callList
              this.studyVisitArray = this.data.callList.filter(x => x.SiteId == siteId)
            }
          })
        }
        else {
          return
        }
      }
      else {
        return
      }
    }
    catch (ex) {
      this._Global.showErrorMsg(ex)
      row.parentAnchorNumber = row.parentAnchorNumber
    }
  }

  RemoveAnchor(row: StudyVisit) {
    if (confirm("Are you sure You want to remove anchor against this visit?") == true) {
//debugger;
      // var childAnchorNumber: number = row.childAnchorNumber
      var parentAnchorNumber: number = row.parentAnchorNumber
      var anchorArray: StudyVisit[] = []
      var parentArray: StudyVisit[] = []
      var removeAnchorArray: StudyVisit[] = []
      var DataArray: StudyVisit[] = this.studyVisitArray.filter(x=>x.SiteId == row.SiteId )
      parentArray = DataArray.filter(o => o.parentAnchorNumber != 0)
      parentArray.map((element) => {
        element.childAnchorNumber = element.parentAnchorNumber
      })
      parentArray.forEach(element => {
        DataArray = DataArray.filter(o => o.Id != element.Id)
      })
      parentArray.forEach(element => {
        DataArray.push(element)
      })
      DataArray = DataArray.filter(o => o.isUnscheduledVisit != true)
      anchorArray = DataArray.sort((a, b) => a.childAnchorNumber - b.childAnchorNumber)
      removeAnchorArray = anchorArray.filter(o => o.childAnchorNumber >= parentAnchorNumber)

      removeAnchorArray.forEach((element) => {
        delete element.Study
        if (element.childAnchorNumber == parentAnchorNumber) {
          element.childAnchorNumber = 0;
          element.parentAnchorNumber = 0
        }
        else {
          element.childAnchorNumber = element.childAnchorNumber - 1;
          if (element.parentAnchorNumber != 0) {
            element.parentAnchorNumber = element.parentAnchorNumber - 1;
          }
          else {
            return;
          }
        }
      })
      removeAnchorArray.forEach(element => {
        if (element.childAnchorNumber != 0 && element.parentAnchorNumber != 0) {
          if (element.parentAnchorNumber == element.childAnchorNumber) {
            element.childAnchorNumber = element.childAnchorNumber - 1
          }
          else {
            return
          }
        }
        else {
          return
        }
      })
      // if (removeAnchorArray != null && removeAnchorArray != undefined){
      //   removeAnchorArray.forEach(element=>{
      //     element.studyVisitSites = null
      //   })
      //  }
       console.log("removeAnchorArray", removeAnchorArray)
      this.studyvisitService.RemoveStudyVisitAnchor(removeAnchorArray)
      this.studyvisitService.isCallApiSave.subscribe(res => {
        if (res) {
          this.isCallVisitsApi = true
          this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == row.SiteId)
        }
        else {
          this.isCallVisitsApi = false
          this.data.callList = this.data.callList
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == row.SiteId)
        }
      })
    }
    else {
      return
    }
  }

  getAnchors(childAnchorNumber: number) {
    if (childAnchorNumber && childAnchorNumber > 0) {
      if (this.studyvisitService.studyVisitsParentAnchorByStudy) {
        return this.studyvisitService.studyVisitsParentAnchorByStudy.filter(o => o.parentAnchorNumber <= childAnchorNumber)
      }
      // else {
      //   return
      // }
    }
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[(0-9)]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytext(event): boolean {
    var Regexpattern = /[a-zA-Z0-9 ]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  DeleteStudyVisit(Id: number, SiteId: number) {
    this.isDelete = true
    //debugger;
    console.log(SiteId)
    this.studyvisitService.Delete(Id, this.data.StudyId, SiteId)
    
  }

  clearForm(){
  
    let studyId = this.StudyVisitForm.controls.StudyId.value
    let studyName = this.StudyVisitForm.controls.studyVisitName.value
    let siteId =  this.StudyVisitForm.controls.SiteId.value
    this.StudyVisitForm.reset()
    this.StudyVisitForm.controls.StudyId.setValue(studyId)
          this.StudyVisitForm.controls.studyVisitName.setValue(studyName)
          this.StudyVisitForm.controls.SiteId.setValue("")
          this.StudyVisitForm.controls.CRIOStudyId.setValue("")
          this.StudyVisitForm.controls.VisitTypeId.setValue("")
          this.StudyVisitForm.controls.CRIOStudyVisitId.setValue("")

          this.StudyVisitForm.controls.parentAnchorNumber.setValue(0)
          this.StudyVisitForm.controls.StartDayMarker.setValue(false)
          this.StudyVisitForm.controls.isUnscheduledVisit.setValue(false)
          this.StudyVisitForm.controls.childAnchorNumber.setValue(this.data.callList[0] ? this.data.callList[0].childAnchorNumber : 0)
          this.submitted = false
          this.data.callList = this.studyvisitService._studyvisitssequenceBYStudyId
          this.studyVisitArray = this.data.callList.filter(x => x.SiteId == siteId)
          this.isCallVisitsApi = true
          this.isedit = false
  }
  getStudyWiseCRIOStudyVisits() {
    //debugger;
    this.CRIOStudyVisits = this.crioService._CRIOStudyVisits.filter(o => o.CRIOStudyId == this.StudyVisitForm.controls.CRIOStudyId.value)

  }
  ChangeCRIOSites() {
    
  
    let crioSiteId = this.StudyVisitForm.controls.SiteId.value;
    this.CRIOStudies = this.crioService._CRIOStudiesByComtrekSite.filter(x=> x.SiteID == crioSiteId && x.StudyId ==  this.StudyVisitForm.controls.StudyId.value);
    this.studyVisitArray = this.data.callList.filter(x => x.SiteId == crioSiteId)
    
  }
  GetCRIOStudyVisitDetails(){
    //debugger;
    console.log(this.StudyVisitForm.controls.CRIOStudyVisitId.value)
    let studyVisit = this.CRIOStudyVisits.filter(x=> x.Id == this.StudyVisitForm.controls.CRIOStudyVisitId.value);
    console.log(studyVisit);
     this.StudyVisitForm.controls.Name.setValue(studyVisit[0].Name);
     //this.StudyVisitForm.controls.VisitTypeId.setValue(studyVisit[0].VisitType);
     this.StudyVisitForm.controls.WindowStart.setValue(studyVisit[0].VisitOffSetBefore);
     this.StudyVisitForm.controls.WindowEnd.setValue(studyVisit[0].VisitOffSetAfter);
     this.StudyVisitForm.controls.ActualDayDuration.setValue(studyVisit[0].VisitOffSet);
     console.log(studyVisit[0].IsAnchor)
     this.StudyVisitForm.controls.StartDayMarker.setValue(studyVisit[0].IsAnchor);
     
     this.StudyVisitForm.controls.Sequence.setValue(studyVisit[0].VisitOrderNumber);
     
  }

  getCRIOStudyBYComtrekStudy() {
      let comtStudyId = this.StudyVisitForm.controls.StudyId.value;
      
      ////debugger;
      console.log(comtStudyId);
      this.CRIOStudies = this.crioService._CRIOStudiesByComtrekSite.filter(x=> x.StudyID == comtStudyId );
      console.log(this.CRIOStudies);
    }
  // gotoTop() {
  //   var scrollElem = document.querySelector('#moveTop');
  //   scrollElem.scrollIntoView();

  // }


}


