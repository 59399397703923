

//------------- Global Error Messages--------------//
export const globalToastErrorHeader = 'Error!';
export const globalToastErrorMessage = 'Something unexpected happened!';
export const globalToastSuccessHeader = 'Success!';
export const globalToastWarningHeader = 'Warning!';

export enum ErrorResponse {
  DataNotFound = 'Data Not Found',
  ApplyLeavesError = 'Error!',
  UnexpectedMessage = 'Something unexpected happened!'
}
export const SuccessResponse = {
  success: "Success!",
  deletedSuccessfully: 'Deleted Successfully!',
  updatedSuccessfully: 'updated Successfully!',
  createdSuccessfully: 'Created Successfully!'
}

//------------- Date Formats --------------//
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_UPDATED = 'M/dd/yyyy';
export const DATE_FORMAT_DD_MM_YY = 'dd MMM yyyy';
export const DATE_UTC_NFORMAT = '-01T06:00:00.000Z'
export const DATE_UTC_PFORMAT = 'T06:00:00.000Z'

//------------- Regix Pattrens --------------//
export const OnlyCharactersRegex = /^[a-zA-Z]+$/;
export const NumberRegex = /^[0-9]*$/;
export const OnlyNumberPattern = /[a-zA-Z0-9]/;
export const OnlyTextNumberPattern = /[a-zA-Z-0-9 ]/;
export const OnlyTextSpecialattern = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
//-------------Const Messages --------------//

export const FormValidationMessages = {
  allFiledsRequired : 'Please fill all the required fields',
  invalidForm: 'Please fill valid inputs!',
  selectOptionError: '* Please select an option',
  namePattern: 'Only Alphabets are allowed!',
  invalidVaccineDateFormat: 'Invalid Vaccine Date format',
  invalidVaccineDate: 'Vaccine Date Cannot Be Graater Than Current Date'
}

export const ConfirmationMessages = {
  confirmHeader: 'Confirmation',
  confirmHeaderUpcomingJoinersStatusChange: 'Confirmation Required!',
  statusChange: 'Are you sure you want to change?'
}

export const AppMessages = {
  invalidForm: 'Please fill valid inputs!',
  selectOptionError: '* Please select an option',
  namePattern: 'Only Alphabets are allowed!'
}

export const patientVariables: any = [
  {
    slug: "first_name",
    value: "First Name"
  },
  {
    slug: "last_name",
    value: "Last Name"
  },
  {
    slug: "ComtrakID",
    value: "Comtrak Id"
  },
  {
    slug: "CRIOID",
    value: "Crio Id"
  },
  {
    slug: "age",
    value: "Age"
  },
  {
    slug: "date_of_birth",
    value: "Date of Birth"
  },
  {
    slug: "phone",
    value: "Phone"
  },
  {
    slug: "gender",
    value: "Gender"
  },
  {
    slug: "address",
    value: "Address"
  },
  {
    slug: "campaign",
    value: "Campaign"
  },
  {
    slug: "StudyName",
    value: "Study"
  },
  {
    slug: "SiteName",
    value: "Site"
  },
  {
    slug: "SiteAddress",
    value: "Site Address"
  },
  {
    slug: "SitePhoneNumber",
    value: "Site Phone Number"
  },
  {
    slug: "RecruiterFirstName",
    value: "Recruiter First Name"
  },
  {
    slug: "RecruiterLastName",
    value: "Recruiter Last Name"
  },
  {
    slug: "RecruiterEmail",
    value: "Recruiter Email"
  },
  {
    slug: "RecruiterPhone",
    value: "Recruiter Phone"
  },
  {
    slug: "ethnicity",
    value: "Ethnicity"
  },
  {
    slug: "race",
    value: "Race"
  },
  {
    slug: "TodayDateTime",
    value: "Today Date and Time"
  },
  {
    slug: "Salutation",
    value: "Salutation"
  },
  {
    slug: "TimeSalutation",
    value: "Time Salutation"
  },
  {
    slug: "ScheduledVisitAt",
    value: "Scheduled Visit At"
  },
  {
    slug: "ConfirmationStatus",
    value: "Confirmation Status"
  },
  {
    slug: "PatientStatus",
    value: "Patient Status"
  },
  {
    slug: "CallBackAt",
    value: "Call Back At"
  },
  {
    slug: "CommunicationBy",
    value: "Communication By"
  }
]