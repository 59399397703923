import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from './../../../Shared/Services/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { CampaignReferralSource, CampaignReferralSourceAdvertisement } from './../../../Shared/Models/BackModels/ComTrak/Setups/CampaignReferralSource';
import { Component, Inject, OnInit, ViewChild, ElementRef, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ExcelService } from '@sharedservices/FrontServices/Export/excel.service';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { ColDef } from 'ag-grid-community';
import { Campaign } from '@sharedmodels/BackModels/ComTrak/Setups/Campaign';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';


@Component({
  selector: 'app-createcampaignrefsource',
  templateUrl: './createcampaignrefsource.component.html',
  styleUrls: ['./createcampaignrefsource.component.scss', '../../style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CreatecampaignrefsourceComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('checkBox') checkBox: TemplateRef<any>;
  @ViewChild('edit') edit: TemplateRef<any>;
  CamapignRefSourceForm: FormGroup
  submitted: boolean = false;
  public _data: CampaignReferralSource;
  isedit: boolean = false;
  isActiveCampaign: boolean;
  disbaledFields: boolean;
  isCallrefSource: boolean;
  startDate: any = null;
  endDate: any = null;
  Id: number;
  columnDefs: ColDef[]
  AdvertisementDetails: CampaignReferralSourceAdvertisement[] = []
  format: string = 'M/d/yyyy';
  public defaultColDef;
  public gridApi: any;
  public gridColumnApi: any;
  showAdvertsiementDetails: boolean;
  displayRemoveIcon: boolean;
  AdvertisementDetailsExport: any[] = []
  existingcampaignStudies=[]
  StudyListdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    allowSearchFilter: true,
    enableCheckAll: false,
    maxHeight: 120,
    itemsShowLimit: 1,
  };


  MetroAreadropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "MetropolitanAreaId",
    textField: "Name",
    allowSearchFilter: true,
    enableCheckAll: false,
    maxHeight: 120,
  
  };
  constructor(public dialogRef: MatDialogRef<CreatecampaignrefsourceComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private excelService: ExcelService,
    public fb: FormBuilder, public campaignReferralSourceService: CampaignReferralSourceService, public campaignService: CampaignService,
    public _Global: GlobalfunctionService,public studyService: StudyService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.campaignReferralSourceService.GetMetraAreas()
    this.studyService.LoadStudies('')

    this._Global.gotoTop();
    this.gridConfig()
    this.isActiveCampaign = this.data.isActiveCampaign
    this.initForm()
    this.campaignService.LoadAllCampaign('')
    this.AddAdvertisementArray(null)
  
let checkMetroAeaExist= setInterval(() => {
  if (this.campaignReferralSourceService.MetroAreas.length>0) {
    this.data.callList.forEach(x=>{
      let Area= this.campaignReferralSourceService.MetroAreas.filter(y=>y.MetropolitanAreaId==x.MetropolitanAreaId)[0]
      if(Area){
        x.MetroArea=Area.Name
      }
      else{
        x.MetroArea=""
      }
     })
     this.gridApi.setRowData(this.data.callList);
    clearInterval(checkMetroAeaExist)
  }
}, 1000);
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef
    this.columnDefs = [
      { headerName: 'Name', field: 'Name', sortable: true, filter: true },
      {
        headerName: 'Is Active', field: 'IsActive', sortable: true, filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.checkBox
        }
      },
      { headerName: 'Metro Area', field: 'MetroArea', sortable: true, filter: true },
      ,
      {
        headerName: 'Edit',
        colId: 'edit',
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.edit
        }
      },
    ];
  }

  initForm() {
    this.CamapignRefSourceForm = this.fb.group({
      Name: ['', Validators.required],
      CampaignId: [this.data.campaignId, Validators.required],
      IsActive: [false],
      Studies: [],
      MetropolitanAreaId: new FormControl([],Validators.required),
      //CampaignReferralSourcesStudies:this.fb.array([]),
      CampaignReferralSourcesStudies:new FormControl([],Validators.required),
      // IsAdvertismentDetails: [false],
      campaignReferralSourcesAdDetails: [],
      Id: [''],
    })
  }
  createStudyGroup(studyId: number): FormGroup {
    return this.fb.group({
      StudyId: [studyId, Validators.required]
    });
  }
  get MetropolitanAreaId(): FormArray {
    return this.CamapignRefSourceForm.get('CampaignReferralSourcesStudies') as FormArray;
  }
  get campaignReferralSourcesStudiesArray(): FormArray {
    return this.CamapignRefSourceForm.get('CampaignReferralSourcesStudies') as FormArray;
  }
  addStudy(studyId: number) {
   this.campaignReferralSourcesStudiesArray.push(this.createStudyGroup(studyId));
  }
  removeStudyById(studyId: number) {
    const studiesArray = this.campaignReferralSourcesStudiesArray;
    const index = studiesArray.controls.findIndex((control) => control.value.StudyId === studyId);
  
    if (index !== -1) {
      studiesArray.removeAt(index);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
    if (this.isCallrefSource) {
      this.campaignReferralSourceService.isCallRefSource.next(true)
    }
  }

  get f() {
    return this.CamapignRefSourceForm.controls;
  }

  checkTableValidation() {
    this.AdvertisementDetails = this.AdvertisementDetails
    let lastObject = this.AdvertisementDetails[this.AdvertisementDetails.length - 1]
    //var checkValidate = lastObject.Name == "" || lastObject.StartDate == "" || lastObject.EndDate == "" || lastObject.Budget.toString() == "" || lastObject.Spend.toString() == "" ? true : false;
    var checkValidate = lastObject.Name == "" || lastObject.StartDate == ""  ? true : false;
    if (checkValidate) {
      let index = this.AdvertisementDetails.findIndex(o => o.Name == lastObject.Name)
      let errorMessage = `Advertisement Detail ${index + 1} Is Required`
      this._Global.showErrorMsg(errorMessage)
      return true
    }
    else {
      return false
    }
  }

  Onlyphonenumber(event): boolean {
    const Regexpattern = /[-.(0-9) ]/;
    const result = Regexpattern.test(event.key);
    return result;
  }

  onSubmit() {
    this.submitted = true;
    if (this.CamapignRefSourceForm.invalid) {
      this.checkTableValidation()
      return;
    }
    else {
      // if (this.CamapignRefSourceForm.controls.IsAdvertismentDetails.value == true) {
      const check = this.checkTableValidation()
      if (check == true) {
        return;
      }
      // }
      // else {
      //   this.AdvertisementDetails = []
      // }
      this.CamapignRefSourceForm.controls.campaignReferralSourcesAdDetails.setValue(this.AdvertisementDetails)
      this.CamapignRefSourceForm.controls.Id.setValue(this.Id)
      let data=JSON.parse( JSON.stringify( this.CamapignRefSourceForm.value))

      // data.CampaignReferralSourcesStudies=this.CamapignRefSourceForm.value.CampaignReferralSourcesStudies.map(x=>({StudyId:x.Id}))
      // data.MetropolitanAreaId=this.CamapignRefSourceForm.value.MetropolitanAreaId[0].MetropolitanAreaId


      data.CampaignReferralSourcesStudies=data.CampaignReferralSourcesStudies.map(x=>({StudyId:x.Id}))
      data.MetropolitanAreaId=data.MetropolitanAreaId[0].MetropolitanAreaId


      this.campaignReferralSourceService.SaveAndEditCampaignRefSource(data, this.isedit,this.existingcampaignStudies)
      this.campaignReferralSourceService.isCallApiById.subscribe(res => {
        if (res) {
          this.CamapignRefSourceForm.controls.Name.setValue("")
          this.CamapignRefSourceForm.controls.MetropolitanAreaId.setValue([])
          this.CamapignRefSourceForm.controls.CampaignReferralSourcesStudies.setValue([])
          //this.initForm()
          this.submitted = false
          this.AdvertisementDetails = []
          this.AddAdvertisementArray(null)
          this.data.callList = this.campaignReferralSourceService.CampaignReferralSourceByCampaignId
          this.data.callList.forEach(x=>{
            let Area= this.campaignReferralSourceService.MetroAreas.filter(y=>y.MetropolitanAreaId==x.MetropolitanAreaId)[0]
            if(Area){
              x.MetroArea=Area.Name
            }
            else{
              x.MetroArea=""
            }
           })
           this.triggerFalseClick()
          this.isCallrefSource = true
          this.isedit = false
          this.showAdvertsiementDetails = false
        }
        else {
          this.isCallrefSource = false
        }
      })
    }
  }

  SetEditCampaignRefSourceData(row: CampaignReferralSource) {
    this.Id = row.Id
    this.isedit = true
    let campaignObject: Campaign = this.campaignService._AllCampaign.filter(o => o.Id == this.CamapignRefSourceForm.controls.CampaignId.value)[0]
    if (row.campaignReferralSourcesAdDetails && row.campaignReferralSourcesAdDetails.length > 0) {
      // this.CamapignRefSourceForm.controls.IsAdvertismentDetails.setValue(true)
      this.showAdvertsiementDetails = true
      this.AdvertisementDetails = []
      for (let index = 0; index < row.campaignReferralSourcesAdDetails.length; index++) {
        this.AdvertisementDetails.push({
          Id: row.campaignReferralSourcesAdDetails[index].Id,
          Name: row.campaignReferralSourcesAdDetails[index].Name,
          StartDate: row.campaignReferralSourcesAdDetails[index].StartDate,
          EndDate: row.campaignReferralSourcesAdDetails[index].EndDate,
          Budget: row.campaignReferralSourcesAdDetails[index].Budget,
          Spend: row.campaignReferralSourcesAdDetails[index].Spend,
          // showValidator: false
        })
        this.showRemoveIcon()
      }
      this.AdvertisementDetailsExport = []
      this.AdvertisementDetails.forEach(element => {
        this.AdvertisementDetailsExport.push({
          Campaign: campaignObject.Name,
          CampaignReferralSource: row.Name,
          Id: element.Id,
          Name: element.Name,
          StartDate: element.StartDate,
          EndDate: element.EndDate,
          Budget: element.Budget,
          Spend: element.Spend,
        })
      })
    }
    else {
      this.AdvertisementDetailsExport = []
      this.AdvertisementDetails = []
      // this.AdvertisementDetails.push({
      //   Id: null,
      //   Name: '',
      //   StartDate: '',
      //   EndDate: '',
      //   Budget: 0,
      //   Spend: 0,
      // })
      // this.CamapignRefSourceForm.controls.IsAdvertismentDetails.setValue(false)
      this.showAdvertsiementDetails = false
      this.AddAdvertisementArray(null)
    }
    let rowData =JSON.parse(  JSON.stringify( row))
    //rowData.MetropolitanAreaId=this.campaignReferralSourceService.MetroAreas.filter(o => o.MetropolitanAreaId == row.MetropolitanAreaId)


    let studyArray=[]
    this.existingcampaignStudies=[]
    row.CampaignReferralSourcesStudies.forEach(element => {
      let study=this.studyService._studiesArray.filter(x=>x.Id==element.StudyId)[0]
      this.existingcampaignStudies.push({studyId:element.StudyId,Id:element.Id})
      studyArray.push({Id:study.Id,Name:study. Name     } )
  })
  rowData.CampaignReferralSourcesStudies=studyArray


    this.CamapignRefSourceForm.patchValue(rowData)
  
    //populate metro area
    this.campaignReferralSourceService.MetroAreas.filter(o => o.MetropolitanAreaId == row.MetropolitanAreaId).map(o => {
      this.CamapignRefSourceForm.controls.MetropolitanAreaId.setValue([o])

    })
    //populate studies
    this.cd.detectChanges();
  

   // this.CamapignRefSourceForm.controls.MetropolitanAreaId.setValue([{ "MetropolitanAreaId": "BOS", "Name": "Boston" } ])
}

  PrintElem(elem) {
    this.excelService.PrintElem('Campaign Referral Source', elem)
  }

  ExportToExcel() {
    this.excelService.exportAsExcelFile(this.AdvertisementDetailsExport, 'Campaign Referral Source Ad Details')
  }

  DownloadAsPDF() {
    this.excelService.DownloadAsPDF('Campaign Referral Source', this.pdfTable)
  }

  Cancel() {
    this.isedit = false
    this.CamapignRefSourceForm.controls.IsActive.setValue(false)
    this.CamapignRefSourceForm.controls.Name.setValue("")
    this.CamapignRefSourceForm.controls.MetropolitanAreaId.setValue([])
    this.CamapignRefSourceForm.controls.CampaignReferralSourcesStudies.setValue([])
    this.CamapignRefSourceForm.controls.campaignReferralSourcesAdDetails.setValue([])
    this.AdvertisementDetails=[]
    this.AddAdvertisementArray(null)

  }

  CheckActiveCampiagn() {
    if (this.isActiveCampaign == false) {
      this._Global.showErrorMsg("Campaign Referral Source can't be active . Active Campaign first.")
      this.CamapignRefSourceForm.controls.IsActive.setValue(false)
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.data.callList);
  }

  onPageSizeChanged(event: any) {
    this.gridApi.paginationSetPageSize(Number(event.target.value));
  }

  onBtExport() {
    this.gridApi.exportDataAsCsv();
  }

  destroyFilter() {
    this.columnDefs.forEach(element => {
      this.gridApi.destroyFilter(element.field);
    })
  }

  // CheckActiveAdvertisementDetails() {
  //   if (this.CamapignRefSourceForm.controls.IsAdvertismentDetails.value == true) {
  //     this.showAdvertsiementDetails = true
  //   }
  //   else {
  //     this.showAdvertsiementDetails = false
  //   }
  // }

  AddAdvertisementArray(index: number) {
    if (index != null) {
      let checkValidate = this.checkTableValidation()
      if (checkValidate == true) {
        return
      }
      else {
        this.AdvertisementDetails.push({
          Name: '',
          StartDate: '',
          EndDate: '2030-12-31',
          Budget: '0',
          Spend: '0',
        })
      }
    }
    else {
      this.AdvertisementDetails.push({
        Name: '',
        StartDate: '',
        EndDate: '2030-12-31',
        Budget: '0',
        Spend: '0',
      })
    }

  }

  // ChangeStartDate($event, index: number) {
  //   this.startDate = $event
  //   if (this.startDate > this.endDate) {
  //     this._Global.showErrorMsg("Start Date cannot be greater than End Date ")
  //     this.AdvertisementDetails[index].StartDate = new Date().toString()
  //   }
  // }

  // ChangeEndDate($event, index: number) {
  //   this.endDate = $event
  //   if (this.endDate < this.startDate) {
  //     this._Global.showErrorMsg("End Date cannot be less than Start Date ")
  //     this.AdvertisementDetails[index].EndDate = new Date().toString()
  //   }
  // }

  ChangeStartDate($event, index: number) {
    if (this.AdvertisementDetails[index].StartDate != "" && this.AdvertisementDetails[index].EndDate != "") {
      if (this.AdvertisementDetails[index].StartDate > this.AdvertisementDetails[index].EndDate) {
        this.endDate = ""
        this.startDate = ""
        this._Global.showErrorMsg("Start Date cannot be greater than End Date ")
        this.AdvertisementDetails[index].StartDate = new Date().toString()
        this.AdvertisementDetails[index].EndDate = new Date().toString()
      }
    }
  }

  ChangeEndDate($event, index: number) {
    if (this.AdvertisementDetails[index].StartDate != "" && this.AdvertisementDetails[index].EndDate != "") {
      if (this.AdvertisementDetails[index].EndDate < this.AdvertisementDetails[index].StartDate) {
        this.endDate = ""
        this.startDate = ""
        this._Global.showErrorMsg("End Date cannot be less than Start Date ")
        this.AdvertisementDetails[index].StartDate = new Date().toString()
        this.AdvertisementDetails[index].EndDate = new Date().toString()
      }
    }
  }

  showRemoveIcon() {
    if (this.AdvertisementDetails.length === 1) {
      this.displayRemoveIcon = false;
    } else {
      this.displayRemoveIcon = true;
    }
  }

  SpliceAdvertisementArray(index: number) {
    if (this.AdvertisementDetails.length == 1) {
      return
    }
    else {
      this.AdvertisementDetails.splice(index, 1)
    }
  }

  onMetroAreaSelect(event) {

this.CamapignRefSourceForm.controls.MetropolitanAreaId.setValue(event.MetropolitanAreaId);

  }
  onMetroAreaDeSelect(event) {
    this.CamapignRefSourceForm.controls.MetropolitanAreaId.setValue(null);

  }
  onStudySelect(event) {
    this.addStudy(event.Id)
      }
      onStudyDeSelect(event) {
      this.removeStudyById(event.Id)
          }
  @ViewChild('falseClick') falseClick: ElementRef<HTMLElement>;
  triggerFalseClick() {
    let el: HTMLElement = this.falseClick.nativeElement;
    el.click();
  }       
}
