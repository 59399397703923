import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assign-communication',
  templateUrl: './assign-communication.component.html',
  styleUrls: ['./assign-communication.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssigncommunicationComponent implements OnInit {
  public myData: any = [];
  htmlContent: any;
  usersList: any;
  patient: any
  memoFc = new FormControl('');
  assignForm: FormGroup;
  assignToMe: boolean = false;
  subs = new Subscription()
  public fields: Object = { text: 'UserName', value: 'Id' };
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public dialogRef: MatDialogRef<AssigncommunicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private NotitificationsService: NotitificationsService,
    private spinner: NgxSpinnerService,
    private _global: GlobalfunctionService,
    private toastService: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.assignForm = this.fb.group({
      assignToMe: [false] 
    });
    this.usersList = this.data.usersList;
    this.patient = this.data.Patient;
    if(this.patient.is_read){
      this.toastService.error('','Already mark as read, cannot assign', this.toastOptions);
    }
  }

  onSubmit(value: string): void {
    this.onSubmitAction.emit(value);
    this.onNoClick();
  }

  onSearch(): void {
    let selectedUser = this.usersList.find((e) => e.Id === this.memoFc.value)
    let param;
    if (this.assignToMe === true) {
      param = {
        "patientId": this.patient.patient_obj.Id,
      };
    } else {
      param = {
        "patientId": this.patient.patient_obj.Id,
        "recruiterId": selectedUser.Id,
        "recruiterEmail": selectedUser.Email,
      };
    }
    this.spinner.show();
    this._global.IsReady = false;
    const getData = this.NotitificationsService.AssignPatient(param).subscribe({
      next: (res) => {
        if (res.status) {
          this.onSubmit('')
          this.spinner.hide();
          this._global.IsReady = true;
          this.toastService.success('', res.message, this.toastOptions);
        } else {
          this.toastService.error('', res.message, this.toastOptions);
          this.spinner.hide();
          this._global.IsReady = true;
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._global.IsReady = true;
        this.toastService.error('', err, this.toastOptions);
      }
    });
    this.subs.add(getData);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
