import { Gender } from './../../../../Models/BackModels/ComTrak/Setups/Gender';
import { Race } from './../../../../Models/BackModels/ComTrak/Setups/race';
import { Ethinicity } from './../../../../Models/BackModels/ComTrak/Setups/ethinicity';
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import {  StaffType, StudyStatus } from '@sharedmodels/BackModels/ComTrak/Setups/StudyStatus';

@Injectable({
    providedIn: 'root'
})
export class StaticDataService {
    public Ethinicity: Ethinicity[] = [
        {
            Name: 'Hispanic or Latino(a) or of Spanish Origin'
        },
        {
            Name: 'Not Hispanic or Latino(a) or of Spanish Origin'
        },
        {
            Name: 'Not Reported'
        },
    ]
    public Race: Race[] = [

        {
            Name: 'White'
        },
        {
            Name: 'Black or African American'
        },
        {
            Name: 'Asian'
        },
        {
            Name: 'American Indian or Alaska Native'
        },
        {
            Name: 'Native Hawaiian or other Pacific Islander'
        },
        {
            Name: 'Not reported'
        }
    ]
    public Gender: Gender[] = [
        {
            Name: 'Male'
        },
        {
            Name: 'Female'
        },
    ]
    public StudyStatus: StudyStatus[] = [
        {
            Id: 1,
            Name: 'Configuring'
        },
        {
            Id: 2,
            Name: 'Startup'
        },
        {
            Id: 3,
            Name: 'Enrolling'
        },
        {
            Id: 4,
            Name: 'Maintenance'
        },
        {
            Id: 5,
            Name: 'Pre-Closed'
        },
        {
            Id: 6,
            Name: 'Closed'
        },
        {
            Id: 7,
            Name: 'Suspended'
        },
        {
            Id: 8,
            Name: 'Withdrawn'
        },


    ]
    public StaffType: StaffType[] = [
        {
            Id: -1,
            Name: 'UserAdmins'
        },
        {
            Id: 1,
            Name: 'Recruiters'
        },
        {
            Id: 2,
            Name: 'Pre-Screeners'
        },
        {
            Id: 3,
            Name: 'Screeners'
        },
        {
            Id: 4,
            Name: 'Manager'
        },
        {
            Id: 5,
            Name: 'Confirmations'
        },
    ]

    public StatesTimeZone: any[] = [
        {
            Id: 1,
            Name: 'Samoa Standard Time (SST)'
        },
        {
            Id: 2,
            Name: 'Hawaii-Aleutian Standard Time (HST)'
        },
        {
            Id: 3,
            Name: 'Alaska Standard Time (AKST)'
        },
        {
            Id: 4,
            Name: 'Pacific Standard Time (PST)'
        },
        {
            Id: 5,
            Name: 'Mountain Standard Time (MST)'
        },
        {
            Id: 6,
            Name: 'Central Standard Time (CST)'
        },
        {
            Id: 7,
            Name: 'Eastern Standard Time (EST)'
        },
        {
            Id: 8,
            Name: 'Atlantic Standard Time (AST)'
        },
        {
            Id: 9,
            Name: 'Chamorro Standard Time (CST)'
        },
    ]
    // public EmployeeType: EmployeeType[] = [
    //     {
    //         Id: 1,
    //         Name: 'DM'
    //     },
    //     {
    //         Id: 2,
    //         Name: 'IBEX'
    //     },
    //     {
    //         Id: 2,
    //         Name: 'Precision'
    //     },
    //     {
    //         Id: 2,
    //         Name: 'Medix'
    //     },
    //     {
    //         Id: 2,
    //         Name: 'James Barrington'
    //     },

    // ]
    constructor() { }
}
