import { PatientParams } from "./../../../../Models/BackModels/ComTrak/Patient/patientParams";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as myGlobals from "@shared/global_var";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { Patient } from "@sharedmodels/BackModels/ComTrak/Setups/patient";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { HelperService } from "@sharedservices/helper.service";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  public _PatientDetails: Patient;
  public isCallApi = new Subject<boolean>();
  public isCallApiPreScreen = new Subject<boolean>();
  public isCallApiRecruiter = new Subject<boolean>();
  public isCallApiScreener = new Subject<boolean>();
  public acceptCallFlag = new BehaviorSubject<string>('');

  public studyid: string;
  public siteid: string;
  public statusid: string;
  public startDate: string;
  public studyvisit: string;
  public endDate: string;
  public searchText: string;
  public responseId: string;
  public patientId: string;
  public setRowData: any;
  saveStudyAmountEvent: BehaviorSubject<any> = new BehaviorSubject('');
  sendSmsEmail: BehaviorSubject<any> = new BehaviorSubject('');

  constructor(
    public _http: HttpClient,
    private _Global: GlobalfunctionService,
    public spinner: NgxSpinnerService,
    public router: Router,
    private helper: HelperService

  ) {}
  SetPatientId(patientId: string) {
    this.patientId = patientId;
  }
  SetRowData(rowData:any){
    this.setRowData=rowData;
  }
  GetPatientDetailById(patientId: number): Observable<Patient> {
    try {
      this._Global.IsReady = false;
      this.spinner.show();
      return this._http.get(
        myGlobals._apiPath +
          "Patient?getType=GetPateintById&patientId=" +
          patientId,
        { headers: this._Global.httpOptions }
      );
    } catch (ex) {
      this._Global.showErrorMsg(ex);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetPatientWithDetailById(patientId: number): Observable<Patient> {
    try {
      this.spinner.show();
      this._Global.IsReady = false;

      return this._http.get(
        myGlobals._apiPath +
          "Patient?getType=GetPateintWithDetailsById&patientId=" +
          patientId,
        { headers: this._Global.httpOptions }
      );
    } catch (ex) {
      this._Global.showErrorMsg(ex);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetPatientInteractionsDetails(patientId: number): Observable<Patient> {
    try {
      this._Global.IsReady = false;
      this.spinner.show();
      return this._http.get(
        myGlobals._apiPath +
          "Patient?getType=GetPatientInteractionsDetails&patientId=" +
          patientId,
        { headers: this._Global.httpOptions }
      );
    } catch (ex) {
      this._Global.showErrorMsg(ex);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  EditPatient(petientId: number, patient: Patient): Observable<Patient> {
    try {
      return this._http.put(
        myGlobals._apiPath + "Patient/" + petientId,
        patient,
        { headers: this._Global.httpOptions }
      );
    } catch (ex) {
      this._Global.showErrorMsg(ex);
      this._Global.IsReady = false;
    }
  }

  GetSearchPatients(
    body: PatientParams,
    statusId: number,
    studyId: number,
    studyVisitId: number,
    AgeFrom: string,
    AgeTo: string,
    siteId: number,
    pagesize: number,
    pageno: number
  ): Observable<Patient> {
    try {
      // this.spinner.show()
      // this._Global.IsReady = false;
      return this._http.post(
        myGlobals._apiPath +
          "Patient/GetSearchPatient?getType=GetSearchPatient&statusId=" +
          statusId +
          "&studyId=" +
          studyId +
          "&studyVisitId=" +
          studyVisitId +
          "&AgeFrom=" +
          AgeFrom +
          "&AgeTo=" +
          AgeTo +
          "&siteId=" +
          siteId +
          "&pagesize=" +
          pagesize +
          "&pageno=" +
          pageno,
        body,
        { headers: this._Global.httpOptions }
      );
    } catch (x) {
      this._Global.showErrorMsg(x);
      // this._Global.IsReady = false;
      // this.spinner.hide()
    }
  }

  GetReferralSources(campaignid: number): Observable<any> {
    return this._http.get(
      myGlobals._apiPath +
        "Patient?getType=GetReferralSources&campaignid=" +
        campaignid,
      { headers: this._Global.httpOptions }
    );
  }

  CreatePatient(body: Patient): Observable<Patient> {
    return this._http.post(myGlobals._apiPath + "Patient/CreatePatient", body, {
      headers: this._Global.httpOptions,
    });
  }

  TransferPatient(patientId: number, statusId: number): any {
    return this._http.get(
      myGlobals._apiPath +
        "Patient?getType=PatientTransfer&patientId=" +
        patientId +
        "&statusId=" +
        statusId,
      { headers: this._Global.httpOptions }
    );
  }

  TransferPatientForced(
    patientId: number,
    statusId: number,
    isForced: boolean
  ): any {
    return this._http.get(
      myGlobals._apiPath +
        "Patient?getType=PatientTransferForced&patientId=" +
        patientId +
        "&statusId=" +
        statusId,
      { headers: this._Global.httpOptions }
    );
  }

  CheckCRIOID(CRIOID: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPath + "Patient?getType=CheckCRIOId&CRIOID=" + CRIOID,
      { headers: this._Global.httpOptions }
    );
  }

  CheckPhoneNumber(phoneNumber: string, patientId: number): Observable<any> {
    //debugger;
    return this._http.get(
      myGlobals._apiPath +
        "Patient?getType=CheckPhoneNumber&phoneNumber=" +
        phoneNumber +
        "&patientId=" +
        patientId,
      { headers: this._Global.httpOptions }
    );
  }

  SaveAndEditPatient(
    patientId: number,
    body: Patient,
    isEdit: boolean
  ): Observable<Patient> {
    console.log("body=" + JSON.stringify(body));
    if (isEdit) {
      return this._http.put(myGlobals._apiPath + "Patient/" + patientId, body, {
        headers: this._Global.httpOptions,
      });
    } else {
      let patients: Patient[];
      patients = [];
      patients.push(body);
      return this._http.post(
        myGlobals._apiPath + "Patient?GetType=add",
        patients,
        { headers: this._Global.httpOptions }
      );
    }
  }

  UploadPatient(patients: Patient[]): Observable<any> {
    return this._http.post(
      myGlobals._apiPath + "Patient?GetType=upload",
      patients,
      { headers: this._Global.httpOptions }
    );
  }

  CheckSiteStudyInInteraction(patientId: number): Observable<Patient> {
    try {
      return this._http.get(
        myGlobals._apiPath +
          "Patient?getType=CheckSiteStudyInInteraction&patientId=" +
          patientId,
        { headers: this._Global.httpOptions }
      );
    } catch (ex) {
      this._Global.showErrorMsg(ex);
    }
  }

  //Duplicate Patient
  FetchDuplicatePatientInteractions(
    patientId: number,
    duplicatePatientId: number
  ): Observable<any> {
    return this._http.get(
      myGlobals._apiPath +
        "Patient/InteractionsForDuplicatePatient?comtrakId=" +
        patientId +
        "&duplicateComtrakId=" +
        duplicatePatientId,
      { headers: this._Global.httpOptions }
    );
  }

  ValidateDuplicatePatient(
    patientId: number,
    duplicatePatientId: number
  ): Observable<Object> {
    return this._http.get(
      myGlobals._apiPath +
        "Patient/ValidateInteractionForDuplicatePatients?comtrakId=" +
        patientId +
        "&duplicateComtrakId=" +
        duplicatePatientId,
      { headers: this._Global.httpOptions }
    );
  }

  MergeDuplicatePatient(
    patientId: number,
    duplicatePatientId: number,
    retainStatus: boolean
  ): Observable<Object> {
    return this._http.post(
      myGlobals._apiPath +
        "Patient/MergeDeleteDuplicatePatient?comtrakId=" +
        patientId +
        "&duplicateComtrakId=" +
        duplicatePatientId +
        "&retainStatus=" +
        retainStatus,
      "",
      { headers: this._Global.httpOptions }
    );
  }

  GeCommunicationHistoryById(
    patientId: string,
    params: HttpParams
  ): Observable<any> {
    try {
      return this._http.get(
        myGlobals._apiPathv3 +
          "patient-manage/patient-communication-history/" +
          patientId,
        { params: params, headers: this._Global.httpOptions }
      );
    } catch (ex) {
      this._Global.showErrorMsg(ex);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  getPatinetDataById(data: any): Observable<any> {
    return this._http.post(`${myGlobals._apiPathv3}patient-manage/get-patient-details`, data , { headers: this._Global.httpOptions }) as Observable<any>;
  }

  getTrasferPatient(id: string): Observable<any> {
    return this._http.get(`${myGlobals._apiPathv3}patient-manage/trasfer-patient/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  sendPatientEmail(body): Observable<any> {
    return this._http.post(`${myGlobals._apiPathv3}patient-manage/send-email`, body, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  replyPatientEmail(body): Observable<any> {
    return this._http.post(`${myGlobals._apiPathv3}patient-manage/reply-email`, body, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  uploadImage(body): Observable<any> {
    return this._http.post(`${myGlobals._apiPathv3}mass-com/upload-image`, body, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  // ---------- patient payment module apis ---------- 

  // patient data based on comtrakid search
  getPatientSearchData(option: HttpParams): Observable<any> {
    return this._http.get(`${myGlobals._apiPatientPayment}patient/search`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
  }

  // patient payment sent
  patientPaymentSent(body: any): Observable<any> {
    return this._http.post(`${myGlobals._apiPatientPayment}patient/disbursement`, body, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  // patient payment list
  getPatientPaymentData(option: HttpParams): Observable<any> {
    return this._http.get(`${myGlobals._apiPatientPayment}patient/disbursement-list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
  }

  sendRequest(data: any): Observable<any> {
    return this._http.post(`${myGlobals._apiPatientPayment}patient/disbursement-resend`, data, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  // study search and get amount
  getStudyAmount(data: any): Observable<any> {
    return this._http.get(`${myGlobals._apiPatientPayment}study/search/${data}`, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  // study list data
  getPaymentStudyListData(option: HttpParams): Observable<any> {
    return this._http.get(`${myGlobals._apiPatientPayment}study/list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
  }

  // save study amount
  saveStudyAmount(data: any): Observable<any> {
    return this._http.post(`${myGlobals._apiPatientPayment}study/save-amount`, data, { headers: this._Global.httpOptions }) as Observable<any>;
  }

  refreshStudyData(): Observable<any> {
    return this._http.get(`${myGlobals._apiPatientPayment}study/sync`, { headers: this._Global.httpOptions }) as Observable<any>;
  }
}
