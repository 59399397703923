import { Component, Input, OnInit } from '@angular/core';
import { inputs } from '@syncfusion/ej2-angular-buttons/src/button/button.component';

@Component({
    selector: 'totalrecordshow',
    templateUrl: 'totalrecordshow.component.html',
    styleUrls: ['totalrecordshow.component.scss']
})
export class TotalrecordshowComponent implements OnInit {
    @Input() totalCount: number;
    @Input() showPlus: boolean = false;
    countUpProps: any = {
        duration: 4,
    }
    constructor() { }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.

    }
}
