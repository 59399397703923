import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

// This Module's Components
import { DatepickerComponent } from './datepicker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbPopoverModule,
        NgbDatepickerModule
    ],
    declarations: [
        DatepickerComponent,
    ],
    exports: [
        DatepickerComponent,
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DatepickerModule {

}
