import { Data } from './../../../Shared/Models/FrontModels/ordertaking/MenuProducts';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ivr-option',
  templateUrl: './ivr-option.component.html',
  styleUrls: ['./ivr-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IvrOptionComponent implements OnInit {
  fields: any[] = [{ keypad_no: null}];
  DistroList = []
  toastId: number | null = null;
  date = ''
  subs = new Subscription()
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  IverOptionList= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  users=[] ;
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public dialogRef: MatDialogRef<IvrOptionComponent>,
    private spinner: NgxSpinnerService,
    private _global: GlobalfunctionService,
    private toastService: ToastrService,
    private datePipe: DatePipe,
    private NotitificationsService: NotitificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {}

  ngOnInit() {
    this.handleDistro()
  }

  resetFields() {
    if (this.data.ivr_option && this.data.ivr_option.length > 0) {    // Check if `ivr_option` exists and has items
      this.fields = this.data.ivr_option.map(item => ({
        keypad_no: item.keypad_no.toString(),
        distro: item.distro.email
      }));
    } else {
      this.fields = [{ keypad_no: null, distro: null }];              // Initialize with an empty object if no ivr_option exists
    }
  }

  addField() {
    // Check if any field is empty
    const hasEmptySelection = this.fields.some(field => !field.keypad_no || !field.distro);

    if (hasEmptySelection) {
      if (this.toastId !== null) {
        this.toastService.remove(this.toastId); 
      }
      const toastRef = this.toastService.error('Please select an option from IVR Option and Distro before adding another.', '', this.toastOptions);
      this.toastId = toastRef.toastId; 
    } else {
      // Add new field
      this.fields.push({ keypad_no: null, distro: null });
      this.toastId = null; 
    }
  }

  removeField(index: number) {
    if (this.fields.length > 1) {
      this.fields.splice(index, 1);
    } else {
      this.fields[index].keypad_no = null;
      this.fields[index].distro = null;
    }
  }

  getFilteredIVROptions(index: number) {
    // Get a list of selected IVR options, excluding the current one
    const selectedOptions = this.fields
      .filter((_, i) => i !== index)
      .map(field => field.keypad_no)
      .filter(option => option !== null);

    // Return options that are not already selected
    return this.IverOptionList.filter(option => !selectedOptions.includes(option));
  }

  onSubmit(value:any): void {
    this.onSubmitAction.emit(value);
    this.onNoClick();
  }

  handleDistro() {
    this.spinner.show();
    this._global.IsReady = false;
    this.NotitificationsService.getDistoList().subscribe(
      (response: any) => {
        this.spinner.hide();
        this._global.IsReady = true;
        this.DistroList = response.data
        this.resetFields()
      },
      (error: any) => {
        this.toastService.error('', error, this.toastOptions);
        this.spinner.hide();
        this._global.IsReady = true;
      }
    );
  }

  
  onUpdate(): void {
    const hasEmptySelection = this.fields.some(field => !field.keypad_no || !field.distro);
    const distroLookup = this.DistroList.reduce((Data, item) => {
      Data[item.email] = item;
      return Data;
    }, {});
  
    // Map fields to replace distro with the full object
    const updatedFields = this.fields.map(field => ({
        keypad_no: Number(field.keypad_no), 
        distro: distroLookup[field.distro] || field.distro
    }));

    const new_data = { ...this.data, ivr_option: updatedFields }

    if (!hasEmptySelection) {
      this.onSubmit(new_data)
      this.toastId = null; 
    } else {
      if (this.toastId !== null) {
        this.toastService.remove(this.toastId); 
      }
      const toastRef = this.toastService.error('Please select an option from IVR Option and Distro before adding another.', '', this.toastOptions);
      this.toastId = toastRef.toastId; 
    }
  }

  formatDate1(dateTime: string): string {
    if (dateTime != null) {
      const localDateTime = new Date(dateTime);
      const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy | h:mm a');
      return formattedDate;
    } else {
      return null
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
