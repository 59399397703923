import { Component, Inject, OnInit, ViewChild,EventEmitter,Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GridComponent,
} from "@syncfusion/ej2-angular-grids";
import { StudyService } from './../../../Shared/Services/BackServices/ComTrak/Setups/study.service';
import { SweetAlertComponent } from "@app/components/sweetalert/sweetalert.component";
import { SweetAlertResult } from "sweetalert2";


@Component({
  selector: 'app-update-armid',
  templateUrl: './update-armid.component.html',
  styleUrls: ['./update-armid.component.scss', '../../style.component.scss'],
  
})
export class UpdateArmIdModalComponent implements OnInit {

  studyVisitData:any[];
  NewArmIDForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public item,  public studyService: StudyService,public fb: FormBuilder
  ,private sweetAlert: SweetAlertComponent,   public dialogRef: MatDialogRef<UpdateArmIdModalComponent>,) {
    console.log(item)
  }
  ngOnInit() {

    this.NewArmIDForm = this.fb.group({
      NewArmID: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    });

    this.LoadStudyVisit();
    
  }

   LoadStudyVisit() {
    this.studyService.FetchStudyVisitData(this.item.CRIOStudyId,this.item.SiteID,this.item.StudyID).subscribe(
      (res) => {
        debugger
        this.studyVisitData = res;

        // this._Global.IsReady = false;
        // console.log(this.comTrakData, 'comTrakData');
        // console.log(this.duplicateComtrakData, 'duplicate');

        // this.spinner.hide();
      },
      (error) => {
        // this._Global.showErrorMsg(error.error.Value.status);
        // this._Global.IsReady = false;
        // this.spinner.hide();
      }
    );
  }
  SaveStudyArmId(){
     let armid=this.NewArmIDForm.controls.NewArmID.value;
     this.updateArmConfirmation(armid);
  }


  async updateArmConfirmation(armId): Promise<void> {
    let modalResult: SweetAlertResult;

    let message = `<div class="text-left my-4 mx-2">
                      <p class="font-weight-bold mb-3"><b>Update visits and study with Arm ID: </b>${armId}</p>
                    </div>`;

      modalResult = await this.sweetAlert.showSwal(
        "normal-confirmation",
        message,
        message,
        "Yes",
        "No"
      );
    
    if(modalResult.value){
       this.studyService.UpdateStudyArm(this.item.CRIOStudyId,this.item.SiteID,this.item.StudyID,armId);
       this.studyService.isArmUpdated.subscribe(res => {
        if (res) {
          this.item.CrioStudyArmId=armId;
          this.dialogRef.close(this.item);
        }
      })
    }
  }
}



  