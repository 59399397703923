import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommunicationService } from '@sharedservices/BackServices/ComTrak/Communication/communication.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { HelperService } from '@sharedservices/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sendtest',
  templateUrl: './sendtest.component.html',
  styleUrls: ['./sendtest.component.scss'],
})
export class SendTestComponent implements OnInit,OnDestroy {
  htmlContent: SafeHtml;
  userForm : FormGroup;
  subs = new Subscription();
  emailValidFlag: boolean = true;
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  @ViewChild('departmentSelect') departmentSelect: any;
  constructor(
    public dialogRef: MatDialogRef<SendTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Global: GlobalfunctionService,
    private sanitizer: DomSanitizer,
    private helperService: HelperService,
    public PatientService: PatientService,
    private spinner: NgxSpinnerService,
    private toastService: ToastrService,
    private communicationservice: CommunicationService
  ) {
    this.userForm = new FormGroup({
      email: new FormControl(''),
      phone: new FormControl(''),
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.data.content) as string;

    if (this.data.type === 'EMAIL') {
      this.userForm.get('email').setValidators([
        Validators.required,
      ]);
      this.userForm.get('email').valueChanges.subscribe((value) => {
        this.emailValidator({ text: value });
      });
    } else {
      this.userForm.get('phone').setValidators([
        Validators.required,
        Validators.pattern(/^\d{10,14}$/),
      ]);
    }

    this.userForm.updateValueAndValidity();
  }

  emailValidator(event, emailType = '') {
    const dmclinicalEmailPattern = /^[a-z0-9._%+-]+@dmclinical\.com$/;
    this.emailValidFlag = event.text.match(dmclinicalEmailPattern) ? true : false;
  }

  onSubmit(): void {

    const datatype = this.data.type === 'SMS' ? 'sms': 'email'

    this.spinner.show();
    this._Global.IsReady = false;
    if (this.userForm.valid && this.emailValidFlag) {
      let userData = this.userForm.getRawValue();

        const payload = {
          type: datatype,
          content: datatype === 'sms' ? this.data.content.replace(/<br\s*\/?>/g, '\n').replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, "").replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>') : this.data.content.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
          media: this.data.media || []
        };
  
        if (userData.email) {
          payload['email'] = userData.email
        }
        if (datatype === 'email') {
          payload['subject'] = this.data.subject;
        }
        if (userData.phone) {
          payload['phone_no'] = userData.phone
        }
        payload['from'] = datatype === 'email' ? this.data.formdata.value.sender_email :  this.data.formdata.value.sender_phone_number
    
        this.communicationservice.postSendTest(payload).subscribe({
          next: (res) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            if (res.status) {
              this.toastService.success('', res.message, this.toastOptions);
            } else {
              this.toastService.error('', res.message, this.toastOptions);
            }
          },
          error: (err) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            console.error('Error sending test:', err);
          }
        });
    
        this.dialogRef.close();
      }
  }

  onPhoneInput(event: any): void {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
