import { Injectable, OnInit } from '@angular/core';
import { Message } from '../../Models/FrontModels/Message';
import { HttpHeaders } from '@angular/common/http';
import { SweetAlertComponent } from '@app/components/sweetalert/sweetalert.component';
import { Router } from '@angular/router';
import * as myGlobals from '@shared/global_var';
import { ToastrService } from 'ngx-toastr';
import { Howl, Howler } from 'howler';
import { SocialUser } from '@sharedmodels/BackModels/ComTrak/Security/SocialUser';
import { Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class GlobalfunctionService implements OnInit {
  public isRecruiters = new Subject<boolean>();
  public defaultColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    resizable: true,
    floatingFilter: false,
    enableCellChangeFlash: true,
    onGridReady: params => {
      params.api.sizeColumnsToFit();
    }
  };
  public averageRecord: number = 500
  public isFreeTier: boolean = false;
  private _viewWidth: number;
  private _viewHeight: number;

  isPaymentMethodSelected: boolean = false
  private _socialuser: SocialUser;
  userRoles: any;
  loginUser: any;
  public loginUserEmail: string;
  public get socialUser(): SocialUser {
    return this._socialuser;
  }
  public set socialUser(v: SocialUser) {
    this._socialuser = v;
  }

  private _eventCategory: string;
  public get eventCategory(): string {
    return this._eventCategory;
  }
  public set eventCategory(v: string) {
    this._eventCategory = v;
  }
  private _powerbiaccesstoken: string;
  public get powerbiaccesstoken(): string {
    return this._powerbiaccesstoken;
  }
  public set powerbiaccesstoken(v: string) {
    this._powerbiaccesstoken = v;
  }
  private _tokenexpireslocal: Date;
  public get tokenexpireslocal(): Date {
    return this._tokenexpireslocal;
  }
  public set tokenexpireslocal(v: Date) {
    this._tokenexpireslocal = v;
  }
  private _tokenexpires: Date;
  public get tokenexpires(): Date {
    return this._tokenexpires;
  }
  public set tokenexpires(v: Date) {
    this._tokenexpires = v;
  }

  public get viewWidth(): number {
    return this._viewWidth;
  }

  public get viewHeight(): number {
    return this._viewHeight;
  }
  private _IsReady: boolean;

  public get IsReady(): boolean {
    return this._IsReady;
  }
  public set IsReady(v: boolean) {
    this._IsReady = v;
  }

  private _ShownError: string;
  public get ShownError(): string {
    return this._ShownError;
  }
  public set ShownError(v: string) {
    this._ShownError = v;
  }
  private _IsHandleError: boolean;
  public get IsHandleError(): boolean {
    return this._IsHandleError;
  }
  public set IsHandleError(v: boolean) {
    this._IsHandleError = v;
  }
  private _pincode: string;
  public get pincode(): string {
    return this._pincode;
  }
  public set pincode(v: string) {
    this._pincode = v;
  }
  private _loginName: string;
  public get loginName(): string {
    return this._loginName;
  }
  public set loginName(v: string) {
    this._loginName = v;
  }
  private _email: string;
  public get email(): string {
    return this._email;
  }
  public set email(v: string) {
    this._email = v;
  }
  private _loginkey: string;
  public get loginkey(): string {
    return this._loginkey;
  }
  public set loginkey(v: string) {
    this._loginkey = v;
  }

  private _loginuserid: number;
  public get loginuserid(): number {
    return this._loginuserid;
  }
  public set loginuserid(v: number) {
    this._loginuserid = v;
  }
  private _loginrecruiterid: number;
  public get loginrecruiterid(): number {
    return this._loginrecruiterid;
  }
  public set loginrecruiterid(v: number) {
    this._loginrecruiterid = v;
  }
  private _loginuserrole: any[];
  public get loginuserrole(): any[] {
    return this._loginuserrole;
  }
  public set loginuserrole(v: any[]) {
    this._loginuserrole = v;
  }


  private _httpOptions: HttpHeaders;
  public get httpOptions(): HttpHeaders {
    return this._httpOptions;
  }
  public set httpOptions(v: HttpHeaders) {
    this._httpOptions = v;
  }
  private _httpOptionsExternalLogin: HttpHeaders;
  public get httpOptionsExternalLogin(): HttpHeaders {
    return this._httpOptionsExternalLogin;
  }
  public set httpOptionsExternalLogin(v: HttpHeaders) {
    this._httpOptionsExternalLogin = v;
  }
  private _auth_token: string;
  public get auth_token(): string {
    return this._auth_token;
  }
  public set auth_token(v: string) {
    this._auth_token = v;
  }
  private _CompanyID: number;

  public get CompanyID(): number {
    return this._CompanyID;
  }
  public set CompanyID(v: number) {
    this._CompanyID = v;
  }

  // to do kaleem start
  private _CompanyoutletID: number;

  public get CompanyOutletID(): number {
    return this._CompanyoutletID;
  }
  public set CompanyOutletID(v: number) {
    this._CompanyoutletID = v;
  }

  private _userID: number;
  public get userID(): number {
    return this._userID;
  }
  public set userID(v: number) {
    this._userID = v;
  }

  // to do kaleem end
  private _Screen: string;
  public get Screen(): string {
    return this._Screen;
  }
  public set Screen(v: string) {
    this._Screen = v;
  }

  private _dashBoardURL: string;
  public get dashBoardURL(): string {
    return this._dashBoardURL;
  }
  public set dashBoardURL(v: string) {
    this._dashBoardURL = v;
  }

  private _isCompanyAdmin: boolean;
  public get isCompanyAdmin(): boolean {
    return this._isCompanyAdmin;
  }
  public set isCompanyAdmin(v: boolean) {
    this._isCompanyAdmin = v;
  }
  private _isCompanyTaxInclusive: boolean;
  public get isCompanyTaxInclusive(): boolean {
    return this._isCompanyTaxInclusive;
  }
  public set isCompanyTaxInclusive(v: boolean) {
    this._isCompanyTaxInclusive = v;
  }

  private _CompanyCurrency: string;
  public get CompanyCurrency(): string {
    return this._CompanyCurrency;
  }
  public set CompanyCurrency(v: string) {
    this._CompanyCurrency = v;
  }
  private _S3foldername: string;
  public get S3foldername(): string {
    return this._S3foldername;
  }
  public set S3foldername(v: string) {
    this._S3foldername = v;
  }

  private _Messages: Message[] = [
    { MessageKey: 'LoadSucc', MessageDesc: 'Loading Successfuly', ActionDesc: '', Type: 2, DispalyStyle: 0 },
    { MessageKey: 'LoadErr', MessageDesc: 'Loading Failed', ActionDesc: '', Type: 0, DispalyStyle: 0 },
    { MessageKey: 'DataErr', MessageDesc: 'Data not found', ActionDesc: '', Type: 0, DispalyStyle: 0 },
    { MessageKey: 'SaveSucc', MessageDesc: 'Save Successfuly', ActionDesc: '', Type: 2, DispalyStyle: 0 },
    { MessageKey: 'SaveErr', MessageDesc: 'Error while saving', ActionDesc: '', Type: 0, DispalyStyle: 0 },
    { MessageKey: 'ValidErr', MessageDesc: '', ActionDesc: '', Type: 0, DispalyStyle: 0 },
    { MessageKey: '', MessageDesc: '', ActionDesc: '', Type: 0, DispalyStyle: 0 },
    { MessageKey: '403', MessageDesc: 'You are not authorized to perform this operation', ActionDesc: '', Type: 0, DispalyStyle: 0 },
    { MessageKey: '401', MessageDesc: 'You are not authorized ', ActionDesc: '', Type: 0, DispalyStyle: 0 },

  ];



  private _Displayproduct: boolean;
  public get Displayproduct(): boolean {
    return this._Displayproduct;
  }
  public set Displayproduct(v: boolean) {
    this._Displayproduct = v;
  }

  private _Displaydeal: boolean;
  public get Displaydeal(): boolean {
    return this._Displaydeal;
  }
  public set Displaydeal(v: boolean) {
    this._Displaydeal = v;
  }
  private _DayEndStarted: boolean;
  public get DayEndStarted(): boolean {
    return this._DayEndStarted;
  }
  public set DayEndStarted(v: boolean) {
    this._DayEndStarted = v;
  }

  public ngOnInit() {
    this._pincode = '';
    this._Displayproduct = false;
    this._Displaydeal = false;
    this._loginName = '';
    this._loginkey = '';
    this._auth_token = '';
    this._CompanyCurrency = 'PKR';
    this._CompanyID = 0;
    this._CompanyoutletID = 0;
    this._dashBoardURL = '';
    this._isCompanyAdmin = false;

    this.isCompanyTaxInclusive = false;
    this._S3foldername = '';
    this._Screen = '';
    this._IsReady = true;
    this._IsHandleError = true;
    this._ShownError = '';
    this._httpOptions = null;
    this._eventCategory = 'Pegasus Dashboard';
    this._tokenexpires = null;
    this._tokenexpireslocal = null;
    this._DayEndStarted = false;

  }

  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
  showconfirmMsg(msg: string): Promise<boolean> {
    this.IsReady = true;
    return this._SweetAlertComponent.showSwal('confirmation', msg, this.convertFirstLetterToUpperCase(msg));
    // return JSON.stringify(errMsg);
  }
  showSuccessMsg(msg: string) {
    this.IsReady = true;
    this.openSnackbar(this.convertFirstLetterToUpperCase(msg), 'success')
    // this._Global.openSnackbar(this.isedit ? 'Updated successfully' : 'Saved successfully', "success")
    // this._SweetAlertComponent.showSwal('success-message', 'Done', msg);
    // this._SweetAlertComponent.showSwal('success-message', 'Done', this.convertFirstLetterToUpperCase(msg));
    // return JSON.stringify(errMsg);
  }

  showSuccess(msg: string) {
    this._SweetAlertComponent.showSwal('success-message', 'Done', this.convertFirstLetterToUpperCase(msg));
    // return JSON.stringify(errMsg);
  }

  showAuthenticationMsg(error: any) {
    if (error && error.status && error.status == 403 || error.status == 401) {
      this._SweetAlertComponent.showSwal('title-and-text', 'Error', "You Have No Rights To Perform This Action.");
    }
    else {
      this._SweetAlertComponent.showSwal('title-and-text', 'Error', error);
    }
  }


  showErrorMsg(msg: string) {
    this.IsReady = true;
    this.openSnackbar(this.convertFirstLetterToUpperCase(msg), 'danger')
    // this._SweetAlertComponent.showSwal('title-and-text', 'Error', msg);
    
    // this._SweetAlertComponent.showSwal('title-and-text', 'Error', this.convertFirstLetterToUpperCase(msg));
    // return JSON.stringify(errMsg);
  }

  showInfoMsg(msg: string) {
    this._SweetAlertComponent.showSwal('title-and-text', 'Info', this.convertFirstLetterToUpperCase(msg));
    // return JSON.stringify(errMsg);
  }

  sortDescending(_sorted: any[]): any[] {
    try {
      _sorted = _sorted.slice(0);
      _sorted.sort((ls, rs): number => {
        if (ls.lastUpdatedAt > rs.lastUpdatedAt) { return 1; }
        if (ls.lastUpdatedAt < rs.lastUpdatedAt) { return -1; }
      });
      return _sorted;
    } catch (Error) {

      return _sorted;
    }
  }
  sort(_sorted: any[]): any[] {
    try {
      _sorted = _sorted.slice(0);
      _sorted.sort((ls, rs): number => {
        if (ls.lastUpdatedAt < rs.lastUpdatedAt) { return 1; }
        if (ls.lastUpdatedAt > rs.lastUpdatedAt) { return -1; }
      });
      return _sorted;
    } catch (Error) {

      return _sorted;
    }
  }

  setData(obj: any) {
    try {
      const data = JSON.parse(obj);
      this.loginUser = obj.user
      this.httpOptions = new HttpHeaders({ 'Authorization': 'Bearer ' + data.auth_token });
      this._loginuserrole = data.role;

      this._auth_token = data.auth_token;
      this._loginrecruiterid = data.recruiterid;
      //localStorage.clear();
      localStorage.setItem('data', obj);
      this.userRoles = JSON.parse(localStorage.getItem("data"))
      this.isRecruiters.next(true)
      // if (this.userRoles && this.userRoles.role && this.userRoles.role.length) {
      //   this.isRecruiters.next(true)
      // }
      // else {
      //   this.isRecruiters.next(false)
      // }

    } catch (Error) {


    }
  }
  notifyUser(head: string, det: string, isbeep: boolean = true) {
    if (isbeep === undefined || isbeep === null) isbeep = true;
    this.showToaster(head, det);
    if (isbeep) { this.playsound(); }

  }
  showToaster(head: string, det: string) {
    try {
      this.toast.success(head, det);
    } catch (x) {

    }
  }
  playsound() {
    try {
      const sound = new Howl({
        src: ['assets/sounds/censor beep 5s.mp3']
      });
      sound.play();
    } catch (e) { }
  }
  handleError(error: any) {

    this.IsReady = true;
    //  console.log('on glbal func=' + JSON.stringify(error));
    if (this.IsHandleError !== undefined && !this._IsHandleError) { return; }

    let errMsg, err;
    try {

      if (error.status === 403) {
        return this.showErrorMsg("You Have No Rights To Perform This Action.")
      }
      if (error.status === 404) {
        return this.showErrorMsg("API Not Found")
      }

      if (error.status === 200) { return; }
      if (error.status === 401) { this.router.navigate(['/login']); return; }

      // errMsg = error.status === 0 ? 'Unknown Host' :
      //   error.status && error.status !== 500 ? error.statusText :
      //     error.error !== null && error.error.value !== undefined &&
      //       error.error.value.status !== undefined ? error.error.value.status :
      //       error.error.value && error.error.value !== null && error.error.value !== undefined ? error.error.value :
      //         error.message ? error.message :
      //           error.value ? error.value : 'Server error';

      errMsg = error.status === 0 ? 'Unknown Host' :
        error.status && error.status === 500 && error.error.Value.status !== undefined ? error.error.Value.status :
          error.status && error.status === 400 ? error.statusText :
            error.status && error.status !== 500 ? error.statusText :
              error.error !== null && error.error.value !== undefined &&
                error.error.value.status !== undefined ? error.error.value.status :
                error.error.value && error.error.value !== null && error.error.value !== undefined ? error.error.value :
                  error.message ? error.message :
                    error.value ? error.value : 'Server error';


      try {

        try {
          //     console.log('in global error=' + JSON.stringify(error));
          let FilterMessage: Message[];
          FilterMessage = this._Messages.filter(rec => rec.MessageKey.toString() === error.status.toString());
          if (FilterMessage.length > 0) {
            errMsg = FilterMessage[0].MessageDesc;
          }
        } catch (Error) { }
        if (errMsg === undefined || errMsg === null || errMsg === '') {
          errMsg = JSON.stringify(error.error);
        }
        err = errMsg.replace('"', '');
        if (error.status === '401' || error.status === '0') {
          // show all other error messages again n agian except unauth and unknown host
          this.ShownError = err;
        }
        if (err === '' || err.trim().toLowerCase() === 'ok') { return; }
        if (this.ShownError === '' || this._ShownError !== err) {
          this.openSnackbar(this.convertFirstLetterToUpperCase(err), 'danger')
          // this._SweetAlertComponent.showSwal('title-and-text', 'Error', err);
          // this._SweetAlertComponent.showSwal('title-and-text', 'Error', this.convertFirstLetterToUpperCase(err));
        }


      } catch (Error) { }
    } catch (Error) {
      this.IsReady = true;
      errMsg = 'Error occured- ' + JSON.stringify(error);
    }

  }
  constructor(
    private _SweetAlertComponent: SweetAlertComponent,
    private toast: ToastrService,
    public snackBar: MatSnackBar,
    // public _daystartendService: DayStartEndService,
    public router: Router) {
    // this._viewWidth = GlobalfunctionHelperService.getScreenWidth();
    // this._viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

  getLoginUserDetails() {
    let userObject: any
    userObject = JSON.parse(localStorage.getItem('data'))
    return userObject && userObject.user ? userObject.user : null;
  }

  getLoginUserEmail() {
    let userObject: any
    userObject = JSON.parse(localStorage.getItem('data'))
    this.loginUserEmail = userObject && userObject.user ? userObject.user.email : null;
    // return userObject && userObject.user ? userObject.user.email : null;
  }


  ShowMessage(MessageKey: string, AdditionalInfo?: string) {
    if (AdditionalInfo === undefined) {
      AdditionalInfo = '';
    } else {
      AdditionalInfo = ' ' + AdditionalInfo + '';
    }

    let FilterMessage: Message[];
    FilterMessage = this._Messages.filter(rec => rec.MessageKey.toLowerCase() === MessageKey.toLowerCase());


    if (FilterMessage.length > 0) {
      switch (FilterMessage[0].DispalyStyle) {
        case 0:
          alert(FilterMessage[0].MessageDesc + AdditionalInfo); break;
        case 1:
          alert(FilterMessage[0].MessageDesc + AdditionalInfo); break;

      }

    }
  }
  CanActivate(_Link: string): boolean {
    if (this._pincode === undefined || this._pincode === null || this._pincode === '') {
      return false;
    }
    // CheckRights and then activated
    return true;
  }
  IsLogin(): boolean {
    if (this._pincode === undefined || this._pincode === null || this._pincode === '') {
      return true;
    }
    return false;
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[0-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytext(event): boolean {
    var Regexpattern = /[a-zA-Z ]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytextnumber(event): boolean {
    var Regexpattern = /[a-zA-Z0-9 ]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  // for pop up edit click scroll to tip
  gotoTop() {
    try {
      var scrollElem = document.querySelector('#moveTop');
      scrollElem.scrollIntoView({ behavior: 'smooth' });
    }
    catch (x) {
      console.log(x)
    }
  }


  // added by afnan
  action: boolean = true;
  setAutoHide: boolean = true;
  // autoHide: number = 1000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  addExtraClass: boolean = true;

  openSnackbar(message: string, action?: string) {
    var snackBarType = action == "success" ? "success" : "danger"
    this.snackBar.open(message, undefined, {
      duration: 3000,
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      panelClass: snackBarType,
    });
  }
  convertFirstLetterToUpperCase(str: string) {
    try {
      if (str && typeof str === 'string' && str != "") {
        var splitStr = str.toLowerCase().split(' ');
        
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
      }
      else {
        return str
      }
    } catch (err) {
      console.log("🚀 ~ GlobalfunctionService ~ convertFirstLetterToUpperCase ~ err:", err)
      return str
    }
  }
}
