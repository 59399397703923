import {
  TherapeuticArea,
  TherapeuticAreaIndication,
} from "./../../../../Models/BackModels/ComTrak/Setups/TherapeuticArea";
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as myGlobals from "@shared/global_var";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { GoogleAnalyticsService } from "@sharedservices/FrontServices/google-analytics.service";
import { Subject, Observable } from "rxjs";
import { Body } from "@angular/http/src/body";

@Injectable({
  providedIn: "root",
})
export class TherapeuticAreaService {
  public isCallApi = new Subject<boolean>();
  public isCallApiSave = new Subject<boolean>();
  public _therapeuticArea: any[] = [];
  public _therapeuticAreaIndications: Array<any> = [];
  public _IndicationsArray: Array<any> = [];
  public _therapeuticAreaActive : any[] = [];
  public _therapeuticAreaArray: any[] = [];
  public arraylength: number;

  constructor(
    public _http: HttpClient,
    private _Global: GlobalfunctionService,
    private _GA: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) { }

  LoadTherapeuticArea(searchString: string) {
    try {
      // if (searchString === undefined || searchString === null) {
      //   searchString = "test";
      // }
      this.spinner.show();
      this._Global.IsReady = false;

      this.isCallApi.next(false);
      this._http
        .get(
          myGlobals._apiPath +
          "TherapeuticArea?type=GetAllTherapeuticArea&searchString=" +
          searchString,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (data) => {
            this._therapeuticArea = <any[]>data;
            // console.log("Theraoeutic Area :",data)
            this._therapeuticAreaArray = <any[]>data;
            this._therapeuticAreaActive = this._therapeuticArea.filter(o => o.IsActive == true)
            this.arraylength = this._therapeuticArea.length;
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(true);
            // console.log("Therapeutic Area Array");
            // console.log(data);
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  SaveTherapeuticArea(body: TherapeuticArea) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._Global.clean(body);
      this._http
        .post(
          myGlobals._apiPath + "TherapeuticArea/Save/TherapeuticArea",
          body,
          {
            headers: this._Global.httpOptions,
          }
        )
        .subscribe(
          (data) => {
            this.isCallApi.next(true);
            this._Global.showSuccessMsg("Therapeutic Area Saved Successfully");
            this.spinner.hide();
            this._Global.IsReady = true;
            this.LoadTherapeuticArea('');
          },
          (error) => {
            this._Global.showErrorMsg(
              error.error && error.error.Value && error.error.Value.status
                ? error.error.Value.status
                : error
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  UpdateTherapeuticArea(body: TherapeuticArea) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._Global.clean(body);
      this._http
        .put(
          myGlobals._apiPath + "TherapeuticArea/Edit/TherapeuticArea",
          body,
          {
            headers: this._Global.httpOptions,
          }
        )
        .subscribe(
          (data) => {
            this.isCallApi.next(true);
            this._Global.showSuccessMsg(
              "Therapeutic Area Updated Successfully"
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.LoadTherapeuticArea('');
          },
          (error) => {
            this._Global.showErrorMsg(
              error.error && error.error.Value && error.error.Value.status
                ? error.error.Value.status
                : error
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  checkIfEditorAddTherapeuticArea(body: TherapeuticArea, isEdit: boolean) {
    if (isEdit == false) {
      this.SaveTherapeuticArea(body);
    } else {
      this.UpdateTherapeuticArea(body);
    }
  }

  SaveTherapeuticIndication(body: TherapeuticAreaIndication) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._Global.clean(body);
      this._http
        .post(
          myGlobals._apiPath +
          "TherapeuticArea/Save/TherapeuticAreaIndications",
          body,
          {
            headers: this._Global.httpOptions,
          }
        )
        .subscribe(
          (data) => {
            this.isCallApi.next(true);
            this._Global.showSuccessMsg("Therapeutic Area Indication Saved Successfully");
            this.spinner.hide();
            this._Global.IsReady = true;
            this.LoadTherapeuticArea('');
          },
          (error) => {
            this._Global.showErrorMsg(
              error.error && error.error.Value && error.error.Value.status
                ? error.error.Value.status
                : error
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  UpdateTherapeuticIndication(body: TherapeuticAreaIndication) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._Global.clean(body);
      this._http
        .put(
          myGlobals._apiPath +
          "TherapeuticArea/Edit/TherapeuticAreaIndications",
          body,
          {
            headers: this._Global.httpOptions,
          }
        )
        .subscribe(
          (data) => {
            this.isCallApi.next(true);
            this._Global.showSuccessMsg(
              "Therapeutic Area Indication Updated Successfully"
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.LoadTherapeuticArea('');
          },
          (error) => {
            this._Global.showErrorMsg(
              error.error && error.error.Value && error.error.Value.status
                ? error.error.Value.status
                : error
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  checkIfEditOrAddTherapeuticIndication(
    body: TherapeuticAreaIndication,
    isEdit: boolean
  ) {
    if (isEdit == false) {
      this.SaveTherapeuticIndication(body);
    } else {
      this.UpdateTherapeuticIndication(body);
    }
  }
}

export interface Test {
  Id: number
  Name: string
}