import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { MultiSelectComponent, ToolbarSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import { DeclinependingreviewComponent } from '../declinependingreview/declinependingreview.component';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { Subject } from 'rxjs';
import { HtmlEditorService, ImageService, LinkService, PasteCleanupService, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-creatememo',
  templateUrl: './creatememo.component.html',
  styleUrls: ['./creatememo.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, PasteCleanupService]
})
export class CreatememoComponent implements OnInit, OnDestroy {
  subs = new Subscription();
  previousContent: string = '';
  emailCharCount = 0;
  SubmitClicked:boolean= false;
  public myData: any = [];
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  reasonFc = new FormControl('');
  showError: boolean = false;
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000,
    positionClass: 'toast-top-right'
  };
  public fields: Object = { text: 'UserName', value: 'Email' };
  usersList:any
  selectedItems = [];
  @ViewChild('multiselectelement') multiselectelement: MultiSelectComponent;
  CreateMemoForm: FormGroup;
  // public backgroundColor = { columns: 2, colorCode: { 'Custom': ['#ffff00', '#008000', '#800080', '#800000', '#808000', '#c0c0c0', '#000000', ''] } }
  // public tools = {
  //   items: ['BackgroundColor']
  // };
  public insertImageSettings = { allowedTypes: ['.jpeg', '.jpg', '.png'], display: 'inline', width: 'auto', height: 'auto', saveFormat: 'Blob', saveUrl: null, path: null }
  public maxLength: number = 500;
  // public tableSettings = { width: '100%', styles: [{ text: 'Dashed Borders', command: 'Table', subCommand: 'Dashed' }, { text: 'Alternate Rows', command: 'Table', subCommand: 'Alternate' }], resize: true, minWidth: 0, maxWidth: null }
  public toolbarSettings: ToolbarSettingsModel = {
    items: ['Undo', 'Redo', '|', 'Bold', 'Italic', 'Underline']
  };
  
  constructor(
    public dialogRef: MatDialogRef<CreatememoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ScriptList: TemplateService,
    private _Global: GlobalfunctionService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastService: ToastrService,
    private NotitificationsService: NotitificationsService,
    private socketHandlerService: SocketHandlerService,
    private ngZone: NgZone
  ) {
    this.CreateMemoForm = this.fb.group({
      users: [[], Validators.required],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      editorContent: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.usersList = this.data;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onRichTextChange(event: any) {
    const content = event.value; // Capturing the value from the event
    this.CreateMemoForm.get('editorContent').setValue(content); // Update FormControl value
    this.updateCharCount(content); // Update character count
  }

  CreateMemo(): void {
    this.spinner.show();
    this._Global.IsReady = false;
    let formData = this.CreateMemoForm.getRawValue();
    let selectedUsers = this.usersList.filter(user => formData.users.includes(user.Email));
    let transformedUsers = selectedUsers.map(user => ({
      ...(user.Id && { id: user.Id.toString() }),
      email: user.Email,
      name:user.UserName,
      ...(user.is_distro === false && { name: user.UserName }),
      ...(user.is_distro === true && { is_distro: true })
    }));
    let passData = {
      "title": formData.title,
      "content": formData.editorContent,
      "users": transformedUsers
    };
    const getData = this.NotitificationsService.MemoCreate(passData).subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.hide();
          this._Global.IsReady = true;
         this.toastService.success('', res.message, this.toastOptions);
         this.dialogRef.close(); 
        }else{
          this.toastService.error('', res.message, this.toastOptions);
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      },
      error: (err) => {
        this.toastService.error('', err, this.toastOptions);
        this.spinner.hide();
        this._Global.IsReady = true;
      }
    });
    this.subs.add(getData);
  }
  

  ondiscardmemo() {
    const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
      panelClass: ['small-dialog-width'],
      data: { title: 'Are you sure?', message: "Are you sure you want to discard this?", AcceptBtnText: "Yes, Discard", CancelBtnText: "Cancel" },
    });

    dialogRef.componentInstance.onSubmitAction.subscribe((action: string) => {
        this.CreateMemoForm.reset();
    });
  }

  updateCharCount(content: string) {
    this.emailCharCount = content.length;
  }

  onSubmit() {
    if (this.CreateMemoForm.valid && this.emailCharCount <= 2000 && this.emailCharCount > 0) {
      this.SubmitClicked=false
      this.CreateMemo();
    } else {
      this.SubmitClicked=true
    }
  }
  closecreatememo(){
    this.dialogRef.close();
  }
}
