import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { Leads, LeadStatusDetail } from '@sharedmodels/BackModels/ComTrak/Leads/lead';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { Router } from '@angular/router';
@Injectable({ 
    providedIn: 'root'
})  
export class LeadService {
    public isCallApi = new Subject<boolean>();
    public isDiscardLeadApi = new Subject<boolean>();
    public isCallApiREvert = new Subject<boolean>();
    public _leadList: Leads[];
    public _leadValidationList: Leads[];
    public _leadsMachedPatients: Patient[];
    public _adminleadList: Leads[];
    public _allLeadList: Leads[];
    public isCallApiAssign = new Subject<boolean>();
    public leadsReport: any;
    public leadsReportArray: any;
    public _discardleadList: Leads[];
    public leadsValidationCount: number = 0;
    public assingableLeadsCount: number = 0;
    public UnassingableLeadsCount: number = 0;
    public incorrectLeadsCount: number = 0;
    public mergedLeadsCount: number = 0;
    public discardedLeadsCount: number = 0;
    public totalLeadsCount: number = 0;
    public leadsCount: number;
    public mergedProfileLeadList: any;
    public AssignCount: any;
    public UnAssignCount: any;
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private spinner: NgxSpinnerService, public router: Router) { }

    GetLeadsList(searcString: string, campaignId?: number, campaignRefId?: number): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?searchString=' + searcString + '&currentStatusId=' + LeadsStatusEnumns.AssignableLead
                + '&callApiType=GetAll' + '&campaignId=' + campaignId + '&campaignRefId=' + campaignRefId, { headers: this._Global.httpOptions }).subscribe((res: Leads[]) => {
                    this._leadList = <Leads[]>res
                    // this.leadsCount = <Leads[]>res
                    // this.assingableLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.AssignableLead).length
                    // this.UnassingableLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.UnAssignableLead).length
                    // this.incorrectLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.IncorrectLead).length;
                    // this.mergedLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.MergedLead).length;
                    // this.discardedLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.DiscardLead).length;
                    // this.totalLeadsCount = this.leadsCount.length;
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getDataProduct(){
        this.spinner.show();
        try {
            this._http.get("https://fakestoreapi.com/products", { headers: this._Global.httpOptions }).subscribe((res: any) => {
                console.log(res);
            });
        } catch (error) {
            console.error(error);
        }
    }

    // getScriptedDraftData(){
    //     this.spinner.show();
    //     try{
    //     this._http.get(myGlobals._apiPathv3 + 'script?status=draft',{headers:this._Global.httpOptions}).subscribe((res:any)=>{
    //         console.log(res)
    //     })
    //     }catch(error){
    //     console.warn(error)
    //     }
    // }
    
    GetLeadsValidationList(searcString: string, campaignId?: number, campaignRefId?: number): any {
        try { 
            this.spinner.show(); 
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?searchString=' + searcString + '&currentStatusId=' + LeadsStatusEnumns.UnAssignableLead + '&callApiType=GetAll'
                + '&campaignId=' + campaignId + '&campaignRefId=' + campaignRefId, { headers: this._Global.httpOptions }).subscribe((res: Leads[]) => {
                    this._leadValidationList = <Leads[]>res
                    this.leadsValidationCount = this._leadValidationList.length
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
 
    GetDiscardedLeadsList(searcString: string, campaignId?: number, campaignRefId?: number): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?searchString=' + searcString + '&currentStatusId=' + LeadsStatusEnumns.DiscardLead + '&callApiType=GetAll'
                + '&campaignId=' + campaignId + '&campaignRefId=' + campaignRefId, { headers: this._Global.httpOptions }).subscribe((res: Leads[]) => {
                    this._discardleadList = <Leads[]>res
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAdminLeadList(recruiterId?: string, campaignId?: string, campaignRefId?: string, campaignRefAdDetailId?: string, startDate?: string, endDate?: string): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?callApiType=GetAdminLeadList&recruiterId=' + recruiterId + '&campaignId=' + campaignId +
                '&campaignRefId=' + campaignRefId + "&campaignRefAdDetailId=" + campaignRefAdDetailId + "&startDate=" + startDate + "&endDate=" + endDate, { headers: this._Global.httpOptions }).subscribe((res: Leads[]) => {
                    this._adminleadList = <Leads[]>res
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }


    GetLeadsCounts(searcString: string, campaignId: number, campaignRefId: number): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?searchString=' + searcString + '&callApiType=GetLeadsCounts'
                + '&campaignId=' + campaignId + '&campaignRefId=' + campaignRefId, { headers: this._Global.httpOptions }).subscribe((res: Leads) => {
                    if (res && res != null && res != undefined) {
                        this.leadsCount = res.AllLeadsCount
                        this.assingableLeadsCount = res.AssignCount
                        this.UnassingableLeadsCount = res.UnAssignCount
                        this.incorrectLeadsCount = res.IncorrectCount
                        this.mergedLeadsCount = res.MergedCount
                        this.discardedLeadsCount = res.DiscardCount
                        this.totalLeadsCount = res.AllLeadsCount
                    }
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllLeadsList(searcString: string): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?searchString=' + searcString + '&currentStatusId=' + null + '&callApiType=GetAll'
                , { headers: this._Global.httpOptions }).subscribe((res: Leads[]) => {
                    this._allLeadList = <Leads[]>res
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllLeadsCount(campaignId: number, campaignRefId: number, campaignRefAdDetailId: number, StateID: number): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?callApiType=GetAllLeadsCount' + '&campaignId=' + campaignId + '&campaignRefId=' + campaignRefId +
                '&campaignRefAdDetailId=' + campaignRefAdDetailId + '&stateID=' + StateID
                , { headers: this._Global.httpOptions }).subscribe((res: any) => {
                    if (res && res.AssignCount && res.AssignCount > 0) {
                        this.AssignCount = res.AssignCount
                    }
                    else {
                        this.AssignCount = 0
                    }
                    if (res && res.UnAssignCount && res.UnAssignCount > 0) {
                        this.UnAssignCount = res.UnAssignCount
                    }
                    else {
                        this.UnAssignCount = 0
                    }
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetLeadsMachedPatients(phone: string, email: string, firstName: string, lastName: string): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'Lead?callApiType=GetLeadsMachedPatients' + '&phone=' + phone
                + '&email=' + email + '&firstName=' + firstName + '&lastName=' + lastName, { headers: this._Global.httpOptions });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    EditLead(body: Leads) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'Lead?apiType=EditLead', body, { headers: this._Global.httpOptions }).subscribe((data: Leads) => {
                this.isCallApi.next(true)
                this._Global.showSuccessMsg("Lead Updated Successfully")
                this.spinner.hide()
                this._Global.IsReady = true;
            },
                error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(false)
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    AssignedLead(body: Leads) {
        if (confirm("Are you sure you want to create this lead?") == true) {
            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this._http.put(myGlobals._apiPath + 'Lead?apiType=AssignedLead', body, { headers: this._Global.httpOptions }).subscribe((data: Leads) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Patient Created Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
                this.isCallApi.next(false)
            }
        }
    }

    MergeLead(body: Patient, leadId: number) {
        // if (confirm("Are you sure you want to merge this lead?") == true) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'Lead/MergeLead?leadId=' + leadId, body, { headers: this._Global.httpOptions }).subscribe((data: Leads) => {
                this.isCallApi.next(true)
                this.spinner.hide()
                this._Global.IsReady = true;
                if (data && data != null && data != undefined && data.Message != "" && data.Message != null){
                    this._Global.showSuccessMsg(data.Message)  
                }
                else{
                    this._Global.showSuccessMsg("Lead Merged Successfully")
                    // this.router.navigateByUrl("/recruitercalllist/recruiter")
                }
            },
                error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(false)
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
        // }
    }
 
    DiscardLead(body: LeadStatusDetail) {
        if (confirm("Are you sure you want to discard this lead?") == true) {
            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this._http.post(myGlobals._apiPath + 'Lead?apiType=DiscardLead', body, { headers: this._Global.httpOptions }).subscribe((data: LeadStatusDetail) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Lead Discarded Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
                this.isCallApi.next(false)
            }
        }
    }

    AssignLeadsToRecruiter(recruiterId: number, campaignId: number, campaignRefId: number, campaignRefAdId: number,stateID: number, noOfLeadToAssign: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?callApiType=AssignLeadsToRecruiter&recruiterId=' + recruiterId + "&campaignId=" + campaignId +
                "&campaignRefId=" + campaignRefId + "&campaignRefAdDetailId=" + campaignRefAdId +  "&stateID=" + stateID + "&noOfLeadToAssign=" + noOfLeadToAssign, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.isCallApiAssign.next(true)
                    this.isCallApi.next(true)
                    // this._Global.showSuccessMsg("Leads Assigned to Recruiter Successfully")
                    this._Global.showSuccessMsg(data.Result ? data.Result : data)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        if (error.status == 200) {
                            this._Global.showSuccessMsg(error.error.text)
                            this.isCallApiAssign.next(true)
                            this.isCallApi.next(true)
                        }
                        else {
                            this._Global.handleError(error);
                        }
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApiAssign.next(false)
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApiAssign.next(false)
        }
    }

    RevertLead(body: any) {
        if (confirm("Are you sure . you want to revert this lead ?") == true) {
            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this._http.post(myGlobals._apiPath + 'Lead/RevertLead', body, { headers: this._Global.httpOptions })
                    .subscribe((data: any) => {
                        this.isCallApiREvert.next(true)
                        this.GetDiscardedLeadsList("")
                        this._Global.showSuccessMsg("Lead Revert Successfully")
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    },
                        error => {
                            this._Global.handleError(error);
                            this.spinner.hide()
                            this._Global.IsReady = true;
                            this.isCallApiREvert.next(false)
                        });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
                this.isCallApiREvert.next(false)
            }
        }
    }

    GetLeadReports(recruiterId?: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?callApiType=GetLeadReports&recruiterId=' + recruiterId, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.leadsReport = data;
                    this.leadsReportArray = data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetMergedProfileLeadList(searchString: string, campaignId: number, campaignRefId: number, campaignRefAdDetailId: number, siteId: number, studyId: number, statusId: number, stateId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?callApiType=GetMergedProfileLeadList&searchString=' + searchString + '&campaignId=' + campaignId
                + '&campaignRefId=' + campaignRefId + '&campaignRefAdDetailId=' + campaignRefAdDetailId + '&siteId=' + siteId + '&studyId=' + studyId
                + '&statusId=' + statusId+ '&stateId=' + stateId, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.mergedProfileLeadList = data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
    MergePatient(body: Patient, patientId: number) {
        // if (confirm("Are you sure you want to merge this lead?") == true) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Patient/MergePatient?existedpatientId=' + patientId, body, { headers: this._Global.httpOptions }).subscribe((data: any) => {
                this.isCallApi.next(true)
                //debugger
                this.spinner.hide()
                this._Global.IsReady = true;
                if (data && data != null && data != undefined && data.Message != "" && data.Message != null){
                    this._Global.showSuccessMsg(data.Message)  
                }
                else{
                    this._Global.showSuccessMsg("Lead Merged Successfully")
                    this.router.navigateByUrl("/recruitercalllist/recruiter")
                }
               
            },
                error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(false)
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
        // }
    }
    LoadLeadHistory(patientId: number) {
        try {
        return this._http.get(myGlobals._apiPath + 'Lead/LeadCampaigns?patientId=' + patientId, { headers: this._Global.httpOptions })
        } catch (x) {
        } 
      }
      ShowLeadCampaigns(patientId: number) {
        try {
        return this._http.get(myGlobals._apiPath + 'Lead/LeadCampaigns?patientId=' + patientId, { headers: this._Global.httpOptions })
        } catch (x) {
        } 
      }
}
