import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LovService } from './../../../Shared/Services/BackServices/ComTrak/Setups/lov.service';
import { EmployeeTypeService } from './../../../Shared/Services/BackServices/ComTrak/Setups/EmployeeType.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExcelService } from '@sharedservices/FrontServices/Export/excel.service';
import { EmployeeType } from '@sharedmodels/BackModels/ComTrak/Setups/EmployeeType';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { ColDef } from 'ag-grid-community';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { TemplateRef } from '@angular/core';

@Component({
  selector: 'app-createemployeetype',
  templateUrl: './createemployeetype.component.html',
  styleUrls: ['./createemployeetype.component.scss', '../../style.component.scss']
})
export class CreateemployeetypeComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('greetCell') greetCell: TemplateRef<any>;
  pageSize: number = 20;
  EmployeeTypeForm: FormGroup
  submitted: boolean = false;
  isedit: boolean = false;
  columnDefs: ColDef[]
  public defaultColDef;
  public gridApi: any;
  public gridColumnApi: any;
  constructor(public dialogRef: MatDialogRef<CreateemployeetypeComponent>, private excelService: ExcelService, public _Global: GlobalfunctionService,
    public fb: FormBuilder, public employeeTypeService: EmployeeTypeService, public lovService: LovService) { }

  ngOnInit() {
    this._Global.gotoTop();
    this.gridConfig()
    this.initForm()
    this.lovService.GetLov(LovStatusMasterID.EmployeeCategory)
    this.employeeTypeService.GetEmployeeType()
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef
    this.columnDefs = [
      { headerName: "Name", field: 'Name', sortable: true, filter: true },
      { headerName: "Type", field: 'lovName', sortable: true, filter: true },
      { headerName: "Description", field: 'Description', sortable: true, filter: true },
      {
        field: 'Edit', sortable: true, filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.greetCell
        }
      },
    ];
  }

  initForm() {
    this.EmployeeTypeForm = this.fb.group({
      Name: ['', Validators.required],
      Description: [''],
      LovDetailId: ['', Validators.required],
      Id: [0],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });

  }

  get f() {
    return this.EmployeeTypeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.EmployeeTypeForm.invalid) {
      return;
    }
    else {
      this.EmployeeTypeForm.controls.LovDetailId.setValue(+this.EmployeeTypeForm.controls.LovDetailId.value)
      this.employeeTypeService.SaveAndEditEmployeeType(this.EmployeeTypeForm.value, this.isedit)
      this.initForm()
      this.submitted = false
    }
  }

  SetEditEmployeeTypeData(row: EmployeeType) {
    this.isedit = true
    this.EmployeeTypeForm.patchValue(row)
  }

  PrintElem(elem) {
    this.excelService.PrintElem('Employee Type', elem)
  }

  ExportToExcel() {
    this.excelService.exportAsExcelFile(this.employeeTypeService._employeeType, 'Employee Type')
  }

  DownloadAsPDF() {
    this.excelService.DownloadAsPDF('Employee Type', this.pdfTable)
  }

  Cancel() {
    this.isedit = false
    this.initForm()
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.employeeTypeService._employeeType);
  }

  onPageSizeChanged(event: any) {
    this.gridApi.paginationSetPageSize(Number(event.target.value));
  }

  onBtExport() {
    this.gridApi.exportDataAsCsv();
  }

  destroyFilter() {
    this.columnDefs.forEach(element => {
      this.gridApi.destroyFilter(element.field);
    })
  }
}
