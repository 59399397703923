import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DBLMService } from '@sharedservices/BackServices/ComTrak/DBLM/dblm.service';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as XLSX from 'xlsx';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-importdblm',
  templateUrl: './importdblm.component.html',
  styleUrls: ['./importdblm.component.scss']
})
export class ImportdblmComponent implements OnInit {
  importDBLMSheet: SheetModel[] = [];
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  workbook: ExcelProper.Workbook = new Excel.Workbook();
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  DBListForm: FormGroup
  submitted: boolean = false
  IsEdit: boolean = false;
  constructor(private fb: FormBuilder, public dblmService: DBLMService, public dialogRef: MatDialogRef<ImportdblmComponent>,
    private _Global: GlobalfunctionService, private spinner: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: any,) {
    let worksheet = this.workbook.addWorksheet('DBLM Sheet');
    worksheet.columns = [
      { header: 'PatientId', key: 'PatientId', width: 25 },
    ];
  }

  ngOnInit() {
    this.dblmService.listMasterId = undefined
    this._Global.getLoginUserEmail();
    this.dblmService.disablebtn = true
    this.initForm()
    if (this.data.rowData != undefined) {
      this.dblmService.listMasterId = this.data.rowData.Id
      this.dblmService.disablebtn = false
      this.IsEdit = true;
      this.DBListForm.patchValue(this.data.rowData)
    } else {
      this.dblmService.disablebtn = true
      this.IsEdit = false;
    }
  }

  initForm() {
    this.DBListForm = this.fb.group({
      Id: [0],
      Name: ['', Validators.required],
      TherapeuticArea: [''],
      Description: [''],
      IsActive: [true],
      IsImport: [true]
    })
  }

  get f() {
    return this.DBListForm.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    if (this.DBListForm.invalid) {
      return;
    }
    else {
      this.dblmService.SaveAndEditListMaster(this.IsEdit, this.DBListForm.value)
    }
  }

  downloadTemplate(): void {
    this.workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      console.log(data);
      console.log(blob);
      FileSaver.saveAs(blob, 'DBLM Template');
    });
  }

  importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    var data = <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
    return data;
  }

  UploadFile(evt: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      const target: DataTransfer = <DataTransfer>(evt.target);
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const data = <any[]>this.importFromFile(bstr);
        const header: string[] = Object.getOwnPropertyNames(new SheetModel());
        const importedData = data.filter(o => o != "PatientId")
        this.importDBLMSheet = importedData.map(arr => {
          const obj = {};
          for (let i = 0; i < header.length; i++) {
            const k = header[i];
            obj[k] = arr[i];
          }
          return <SheetModel>obj;
        })
      };
      reader.readAsBinaryString(target.files[0]);
      setTimeout(() => {
        if (this.importDBLMSheet && this.importDBLMSheet.length && this.importDBLMSheet.length > 0) {
          this.importDBLMSheet.map(element => {
            element.ListMasterId = this.dblmService.listMasterId;
            element.ListCreatedBy = this._Global.loginUserEmail;
          })
          this.dblmService.SaveBulkInsert(this.importDBLMSheet, target.files[0].name)
          this.dblmService.IsSaveBulkInsert.subscribe(res => {
            if (res) {
              this.onNoClick()
              this.myInputVariable.nativeElement.value = "";
            }
            else {
              this.myInputVariable.nativeElement.value = "";
              this.spinner.hide()
              this._Global.IsReady = true;
            }
          })
        }
        else {
          this._Global.showErrorMsg("No data found in sheet")
          this.spinner.hide()
          this._Global.IsReady = true;
        }
      }, 2000);
    }
    catch (e) {
      this.spinner.hide()
      this._Global.IsReady = true;
    }
  }

  onFileChange(evt: any) {
    if (this.dblmService.listMasterId == undefined || this.dblmService.listMasterId == 0) {
      this._Global.showErrorMsg("Please save list name first");
      this.myInputVariable.nativeElement.value = "";
    }
    else {
      if (this.data && this.data.rowData && this.data.rowData.Id) {
        this.spinner.show()
        this._Global.IsReady = false;
        this.dblmService.GetlistPatientsCount(this.data.rowData.Id).subscribe(res => {
          if (res > 0) {
            this.myInputVariable.nativeElement.value = "";
            this._Global.showErrorMsg("Can not import file again. This list already contains patients")
            this.spinner.hide()
            this._Global.IsReady = true;
          }
          else {
            this.UploadFile(evt)
          }
        })
      }
      else {
        this.UploadFile(evt)
      }
    }
  }
}

export class SheetModel {
  PatientId: any = "";
  ListMasterId?: any = ''
  ListCreatedBy?: any = ''
}