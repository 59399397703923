import { Component, Inject, OnInit } from '@angular/core';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { VaccineService } from '@sharedservices/BackServices/ComTrak/Setups/vaccine.service';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { CommandClickEventArgs, CommandModel } from '@syncfusion/ej2-angular-grids';
import { ActionType } from '@sharedmodels/DMCREnums/DMCREnums';
import { ColDef } from 'ag-grid-community';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateManufacturerComponent } from '../createmanufacturer/createmanufacturer.component';
import { CreateIndicationComponent } from '../createindication/createindication.component';
import { DatePipe } from '@angular/common';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';
@Component({
  selector: 'app-showcampaigns',
  templateUrl: './showcampaigns.component.html',
  styleUrls: ['./showcampaigns.component.scss', '../../style.component.scss'],
 // providers: [DatePipe],
})
export class ShowCampaignsComponent implements OnInit {
  format: string = 'MM/dd/yyyy';
  VaccineForm: FormGroup
  submitted: boolean = false;
  public _data: Study;
  nestedDetailTargetError: boolean = false;
  isedit: boolean = false;
  isIndicationEnable: boolean = false;
  dropdownList = [];
  selectedItems = [];
  columnDefs: ColDef[]
  public gridApi: any;
  public gridColumnApi: any;
  public defaultColDef;
  columnDefinition: CustomGridColumns[]
  public commands: CommandModel[];

  Campaigns: any[];
  showRecord: boolean = false;
  PatientName: string;
  Address: string;
  constructor(public dialogRef: MatDialogRef<ShowCampaignsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, private _Global: GlobalfunctionService, public leadService: LeadService,
    private modalService: NgbModal, public dialog: MatDialog, public datePipe: DatePipe) {
    this.commands = [
      { title: 'Edit Vaccine', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-edit e-icons' } },
    ];
  }
  commandClick(args: CommandClickEventArgs): void {
    // if (args.commandColumn.buttonOption.iconCss == ActionType.Edit) {
    // }
  }
  ngOnInit() {
    this._Global.gotoTop();
    if (this.data.callList != undefined) {
      this.GetData();
    }
  }
  GetData() {
    
    //this.VaccineForm.controls.PatientId.setValue(this.data.callList.Id)
    //this.VaccineForm.controls.PatientName.setValue(this.data.callList.FullName)
    let patientDetails = this.data.callList.Id + " - " + this.data.callList.FullName;
    patientDetails += this.data.callList.Gender != '' && this.data.callList.Gender != null ? " | " + this.data.callList.Gender : "";
    patientDetails += this.data.callList.DateOfBirth != '' && this.data.callList.DateOfBirth != null ? " | " + this.data.callList.DateOfBirth : "";
    patientDetails += this.data.callList.Age != 0  && this.data.callList.Age != null ? " | " + this.data.callList.Age : "";
    this.PatientName =    patientDetails;
    this.Address = this.data.callList.Address
    this.LoadCampaigns(this.data.callList.Id);
  }
  get f() {
    return this.VaccineForm.controls
  }
  initForm() {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  late() {
    setTimeout(() => {
      this.showRecord = true
    }, 2000);
  }

  LoadCampaigns(patientId: number){

    this.leadService.LoadLeadHistory(patientId).subscribe(res => {
    this.Campaigns= <any[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }
  
}


