import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router, ActivationStart, ActivatedRoute } from "@angular/router";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormGroup,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FormBuilder } from "@angular/forms";
import { Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/platform-browser";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { _selfUrl, _env } from "@shared/global_var";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { GoogleLoginProvider, SocialService } from "ngx-social-button";
import { SocialUser } from "@sharedmodels/BackModels/ComTrak/Security/SocialUser";
import { ExternalAuthService } from "@sharedservices/BackServices/ComTrak/Security/externalauth.service";
import { RoutesService } from "@sharedservices/BackServices/ComTrak/Security/routes.service";
import { SocketHandlerService } from "@sharedservices/FrontServices/socket-handler.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: "app-login-cmp",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  animations: [
    trigger("fadeInOut", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(1500)),
    ]),
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public _url: string;
  _showspinner: boolean;
  private toggleButton: any;
  private sidebarVisible: boolean;
  @ViewChild(Router) outlet: Router;
  mobile: boolean;
  userRoles: any;
  // shareObj = {
  //     href: "FACEBOOK-SHARE-LINK",
  //     hashtag:"#FACEBOOK-SHARE-HASGTAG"
  // };
  constructor(
    private socialAuthService: SocialService,
    public authService: ExternalAuthService,
    public spinner: NgxSpinnerService,
    public routesService: RoutesService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private element: ElementRef,
    private route: ActivatedRoute,
    private _globalService: GlobalfunctionService,
    private _Router: Router,
    private fb: FormBuilder,
    private socketService: SocketHandlerService
  ) {
    this.sidebarVisible = false;
    this._globalService.IsReady = true;
    this._showspinner = false;
  }
  signOut() {
    if (this.socialAuthService.isSocialLoggedIn()) {
      this.socialAuthService.signOut().catch((err) => {});
    }
  }

  getSocialUser(socialUser) {
    // console.log(socialUser);
  }
  public socialSignIn(socialPlatform: string) {
    this.routesService._routes = [];
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      //  socialPlatformProvider = FacebookLoginProvider.PROVIDER_TYPE;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_TYPE;
    }
    this._globalService.IsReady = false;
    this.spinner.show();
    caches
      .keys()
      .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    this.socialAuthService
      .signIn(socialPlatformProvider, [
        "https://www.googleapis.com/auth/calendar",
      ])
      .then((socialUser) => {
        this._globalService.socialUser = <SocialUser>socialUser;
        this.authService
          .ExternalLogin(this._globalService.socialUser)
          .subscribe(
            (data: any) => {
              if (data === undefined || data === null) {
                this._globalService.handleError("error at recieving response");
                return;
              }
              const d = JSON.stringify(data);
              const dataObj = typeof data == 'string' ? JSON.parse(data) : data;
              console.log('After Login Data ==> ', typeof data, dataObj, _env, _selfUrl);
              // Only if selfUrl is defined in ENV Variable
              if (dataObj && dataObj.recruiterid && _selfUrl) {
                this.authService
                .CheckBetaAccess(dataObj.recruiterid)
                .subscribe(
                  (respObj: any) => {
                    console.log('CheckBetaAccess => ', respObj);
                    if (respObj && respObj.url != _selfUrl) {
                      alert(`Your are being redirected to ${respObj.url}, Please continue and login there...`);
                      return window.location.href = respObj.url;
                    }
                    this.continueLogin(d);
                  },
                  (error) => {
                    this.continueLogin(d);
                    this._globalService.handleError(error);
                    this.spinner.hide();
                    this._globalService.IsReady = true;
                  }
                );
              } else {
                this.continueLogin(d);
              }
            },
            (error) => {
              console.log('error at sociallogin= error', error);
              this._globalService.handleError(error);
              this.spinner.hide();
              this._globalService.IsReady = true;
            }
          );
      })
      .catch((ex) => {
         console.log('error at sociallogin=', ex);
        this.spinner.hide();
        this._globalService.IsReady = true;
      });
  }

  // This method continues the login flow
  continueLogin(d) {
    console.log('Continue Login ==> ', d);
    this._globalService.setData(d);
    this.routesService.LoadRoutes();
    // this.authService.RefreshToken().subscribe((res: any) => {
    //     var userData = JSON.parse(localStorage.getItem("data"));
    //     userData.user.accessToken = ''
    //     userData.user.accessToken = res.access_token ? res.access_token : this._globalService.socialUser.accessToken
    //     localStorage.setItem("data", JSON.stringify(userData));
    //     //this._globalService.auth_token =  res.id_token ? res.id_token : this._globalService.auth_token;
    //     this._Router.navigate(['homepage']);
    // }, error => {
    //     this._globalService.showErrorMsg(error)
    // })
    this._Router.navigate(["homepage"]);
    localStorage.setItem('shouldUpdateStatus', 'true');
    this.spinner.hide();
    this._globalService.IsReady = true;
  }

  ngOnInit() {
    this.socketService.disconnectSocket();
    const s = this.renderer2.createElement("script");
    s.type = "text/javascript";
    //  s.src = 'https://path/to/your/script';
    s.text = `window.fcWidget.hide();`;
    this.renderer2.appendChild(this._document.body, s);

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
    body.classList.add("off-canvas-sidebar");
    const card = document.getElementsByClassName("card")[0];
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove("card-hidden");
    }, 700);
    // This one works actually:
    this._globalService.IsReady = true;

    this._Router.events.subscribe(
      (e) => {
        if (e instanceof ActivationStart && e.snapshot.outlet === "login") {
          this.outlet.dispose();
        }
      },
      () => {
        this._globalService.IsReady = true;
      }
    );

    if (window.screen.width < 786) {
      // 768px portrait
      this.mobile = true;
    }
  }

  Register() {
    this._Router.navigate(["register"]);
  }

  sidebarToggle() {
    // tslint:disable-next-line:prefer-const
    let toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    if (this.sidebarVisible === false) {
      setTimeout(function () {
        toggleButton.classList.add("toggled");
      }, 500);
      body.classList.add("nav-open");
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove("toggled");
      this.sidebarVisible = false;
      body.classList.remove("nav-open");
    }
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
    body.classList.remove("off-canvas-sidebar");
  }
}
