import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
@Component({
  selector: 'app-alert-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  SMSForm: FormGroup
  isEdit: boolean = false
  submitted: boolean = false
  dataObject: any;
  constructor(public fb: FormBuilder, public _lovService: LovService, public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.initForm()
    this._lovService.GetSMSMaster()
    this.dataObject = this.data ? this.data.dataObject : undefined
    this.isEdit = this.data ? this.data.isEdit : false
    if (this.dataObject) {
      this.SMSForm.patchValue(this.data.dataObject)
    }
  }

  initForm() {
    this.SMSForm = this.fb.group({
      LovMasterId: ['', Validators.required],
      LovDetailId: [0],
      Name: ['', Validators.required],
      Description: ['']
    })
  }

  Onlytext(event): boolean {
    var Regexpattern = /[a-zA-Z0-9 ]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.SMSForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.SMSForm.invalid) {
      return;
    }
    else {
      this._lovService.SaveAndEdit(this.SMSForm.value, this.isEdit)
      this._lovService.isCallApi.subscribe(res => {
        if (res) {
          this.onNoClick()
        }
      })
    }
  }
}
