import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as myGlobals from '@shared/global_var';

@Injectable({
    providedIn: 'root'
})
export class PrescreenService {
    prescreen_apiUrl = `${myGlobals._apiPathv3}prescreen-forms`;
    TotalCountPrescreenPendingingReview:BehaviorSubject<number> = new BehaviorSubject(0);
    hideSideBar: BehaviorSubject<string> = new BehaviorSubject('');
    isOpenInteraction: BehaviorSubject<boolean> = new BehaviorSubject(true);
    openChatBox: BehaviorSubject<boolean> = new BehaviorSubject(false);
    selectedNotificationType: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        public http: HttpClient,
        private _Global: GlobalfunctionService
    ) {}

    // prescreen forms List
    getPrescreenList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.prescreen_apiUrl}/list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    PrescreenAction(id: string, action: string): Observable<any> {
        return this.http.post(`${this.prescreen_apiUrl}/${action}/${id}`, {}, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    PrescreenChangeStatus(requestBody: any): Observable<any> {
        return this.http.post(`${this.prescreen_apiUrl}/change-status`,requestBody , {headers: this._Global.httpOptions }) as Observable<any>;
    }

    createPreScreenForm(data: any): Observable<any> {
        return this.http.post(`${this.prescreen_apiUrl}/create`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    getCategoryList(): Observable<any> {
        return this.http.get(`${this.prescreen_apiUrl}/category`, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    //get Prescreen detail
    getPrescreenDetail(id: string ): Observable<any> {
        return this.http.get(`${this.prescreen_apiUrl}/details/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    savePatientPreScreenConfig(data: any): Observable<any> {
        return this.http.post(`${this.prescreen_apiUrl}/save-patient-pre-screen-config`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    getFormListBasedOnStudy(option: HttpParams): Observable<any> {
        return this.http.get(`${this.prescreen_apiUrl}/check-patient-pre-screen-config`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    // phone screen list in patient profile modal 

    getPatientPhoneScreenList(option: HttpParams,id:number): Observable<any> {
        return this.http.get(`${this.prescreen_apiUrl}/patient-phonescreen-list/${id}`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

}