import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-analyticsmodal',
  templateUrl: './analyticsmodal.component.html',
  styleUrls: ['./analyticsmodal.component.scss']
})
export class AnalyticsmodalComponent implements OnInit {
  public myData: any = [];
  newData:any;
  randomColors: string[] = [
    'green','darkgoldenrod','blue','lightblue','red','orange','purple','pink','cyan','magenta','lime','brown'
  ];
  constructor(
    public dialogRef: MatDialogRef<AnalyticsmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  
  ) {}

  ngOnInit() {
  this.newData= this.data.newdata  ;

  this.newData.forEach((item, index) => {
    item.color = this.randomColors[index % this.randomColors.length]; // Cycle through colors
  });

  }

  transformSlug(slug: string): string {
    const parts = slug.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1));
    return parts.join(' ');
  }



  onNoClick(): void {
    this.dialogRef.close();
  }
}
