import { PrincipalInvestigator } from "../../../../Models/BackModels/ComTrak/Setups/PrincipalInvestigator";
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as myGlobals from "@shared/global_var";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PrincipalInvestigatorService {
  public isCallApi = new Subject<boolean>();
  public _PrincipalInvestigator: PrincipalInvestigator[];
  public _allPrincipalInvestigator: PrincipalInvestigator[];
  arraylength: number;
  constructor(
    public _http: HttpClient,
    private _Global: GlobalfunctionService,
    public spinner: NgxSpinnerService
  ) {}

  LoadPrincipalInvestigators(searchString: string) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this.isCallApi.next(false);
      this._http
        .get(
          myGlobals._apiPath +
            "PrincipalInvestigator?getType=GetAll&" +
            "searchString=" +
            searchString,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (data) => {
            this._PrincipalInvestigator = <PrincipalInvestigator[]>data;
            this._allPrincipalInvestigator = <PrincipalInvestigator[]>data;
            this.arraylength = this._allPrincipalInvestigator.length;
            // console.log(data);
            this.isCallApi.next(true);
            this.spinner.hide();
            this._Global.IsReady = true;
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  SavePrincipalInvestigator(body: PrincipalInvestigator) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .post(myGlobals._apiPath + "PrincipalInvestigator", body, {
          headers: this._Global.httpOptions,
        })
        .subscribe(
          (data) => {
            this.isCallApi.next(true);
            this._Global.showSuccessMsg(
              "Principal Investigator Saved Successfully"
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.LoadPrincipalInvestigators("");
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  PrincipalInvestigatorEdit(body: PrincipalInvestigator) {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .put(myGlobals._apiPath + "PrincipalInvestigator", body, {
          headers: this._Global.httpOptions,
        })
        .subscribe(
          (data) => {
            this.isCallApi.next(true);
            this._Global.showSuccessMsg(
              "Principal Investigator Updated Successfully"
            );
            this.spinner.hide();
            this._Global.IsReady = true;
            this.LoadPrincipalInvestigators("");
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
            this.isCallApi.next(false);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(false);
    }
  }

  AddOrEditPrincipalInvestigator(body: PrincipalInvestigator, isEdit: boolean) {
    if (isEdit) {
      // console.log("Edit True");
      this.PrincipalInvestigatorEdit(body);
    } else {
      // console.log("Edit False");
      this.SavePrincipalInvestigator(body);
    }
  }
}
