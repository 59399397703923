import { Patient } from '../../../Shared/Models/BackModels/ComTrak/Setups/patient';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { StaticDataService } from '@sharedservices/BackServices/ComTrak/Setups/StaticData.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CampaignReferralSource, CampaignReferralSourceAdvertisement } from '@sharedmodels/BackModels/ComTrak/Setups/CampaignReferralSource';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { CallListType, LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { fadeInOut, bottomtotop } from '@app/animation';
import { PrescreenercalllistComponent } from '@app/calllists/prescreenercalllist/prescreenercalllist.component';
import { RecruitercalllistComponent } from '@app/calllists/recruitercalllist/recruitercalllist.component';
import { ScreenercalllistComponent } from '@app/calllists/screenercalllist/screenercalllist.component';
import { DnqlistComponent } from '@app/calllists/dnqlist/dnqlist.component';
import { MergePatientComponent } from '@app/pages/mergepatient/mergepatient.component';
import { analyzeFileForInjectables } from '@angular/compiler';
import { Router } from '@angular/router';
import { CreatevaccineComponent } from '../createvaccine/createvaccine.component';
import { PatientDetailDialogService } from '@app/calllists/patientdetail/patientdetaildialogservice';
import { Observable, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    providers: [PrescreenercalllistComponent, ScreenercalllistComponent, RecruitercalllistComponent, DnqlistComponent],
    selector: 'createpatient',
    templateUrl: 'createpatient.component.html',
    styleUrls: ['createpatient.component.scss', '../../style.component.scss'],
    animations: [
        fadeInOut, bottomtotop
    ],
})
export class CreatepatientComponent implements OnInit {
    PatientForm: FormGroup
    public ReferralSourceArray: CampaignReferralSource[];
    submitted: boolean = false;
    public _data: Patient;
    format: string = 'M/d/yyyy';
    public isedit: boolean = false;
    CrioIdExist: boolean = false;
    CrioIdExistObj: any;
    show: boolean = false;
    disabledFields: boolean = false;
    LanguageData: Lov[];
    TransportationData: Lov[];
    PhoneExist: boolean;
    Phone2Exist: boolean;
    ReferralSourceAdDetailsArray: CampaignReferralSourceAdvertisement[];
    @ViewChild('appDialog') appDialog: any;
    PhoneExistingPatient: any;
    Phone2ExistingPatient: any;
    existingPatient: any;
    count: number = 0;

    maxDate: { year: number; month: number; day: number; };
    age: number;
    screenType: any;   
    
    state: string=null;
    country: string=null;
    metroarea:string=null;

    constructor(public dialogRef: MatDialogRef<CreatepatientComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
        public fb: FormBuilder, public staticDataService: StaticDataService, public _PatientInteractionService: PatientInteractionService,
        public locationService: LocationService, public campaignService: CampaignService, public _lovService: LovService,
        public patientService: PatientService, public _CampaignReferralSourceService: CampaignReferralSourceService,
        public spinner: NgxSpinnerService, public _Global: GlobalfunctionService, private modalService: NgbModal,
        public _PrescreenercalllistComponent: PrescreenercalllistComponent, public _ScreenercalllistComponent: ScreenercalllistComponent,
        public _RecruitercalllistComponent: RecruitercalllistComponent, public _DNQList: DnqlistComponent, public dialog: MatDialog, public router: Router,
        private _PatientDetailDialogService: PatientDetailDialogService) {
        var current = new Date();
        current.setDate(current.getDate());
        this.maxDate = {
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate() - 1,
        };
    }

    async ngOnInit() {
        this.screenType = this.data.screenType;
        this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise = []
        if (this.data && this.data.callList && this.data.callList.Id) {
            this.CheckSiteStudyInInteraction(this.data);
        }
        this.sortFields();
        this.initForm();
        this.loadData();
        if (this.data.callList != undefined) {
            this.isedit = true;
            const patientcampaignid = this.data.callList.CampaignId;
            this.PatientForm.patchValue(this.data.callList)
            if (patientcampaignid > 0 && this.campaignService._Campaign && this.campaignService._Campaign.findIndex(x => x.Id === patientcampaignid) <= 0) {
                this.campaignService._Campaign.push(this.campaignService._AllCampaign.filter(x => x.Id === patientcampaignid)[0]);
            }
            this.PatientForm.controls.campaignId.setValue(this.data.callList.CampaignId == 0 || this.data.callList.CampaignId == null ? '' : this.data.callList.CampaignId)
            this.ChangeCampaign();
            this.PatientForm.controls.CountryID.setValue(this.data.callList.CountryID == 0 || this.data.callList.CountryID == null ? 1 : this.data.callList.CountryID)
            this.PatientForm.controls.MetropolitanAreaId.setValue(this.data.callList.MetropolitanAreaId == 0 || this.data.callList.MetropolitanAreaId == null ? '' : this.data.callList.MetropolitanAreaId)
            this.PatientForm.controls.CampaignReferralSourceId.setValue(this.data.callList.CampaignReferralSourceId == 0 || this.data.callList.CampaignReferralSourceId == null ? '' : this.data.callList.CampaignReferralSourceId)
            this.PatientForm.controls.StateID.setValue(this.data.callList.StateID == 0 || this.data.callList.StateID == null ? '' : this.data.callList.StateID)
            this.PatientForm.controls.CityID.setValue(this.data.callList.CityID == 0 || this.data.callList.CityID == null ? null : this.data.callList.CityID)
            this.PatientForm.controls.Phone2.setValue(this.data.callList.Phone2 == null ? '' : this.data.callList.Phone2)
            this.PatientForm.controls.Phone.setValue(this.data.callList.Phone == null ? '' : this.data.callList.Phone)
            this.PatientForm.controls.CrioId.setValue(this.data.callList.CrioId)
            
    
            if (this.data.callList.Phone && this.data.callList.Phone != "") {
                this.CheckPhoneNumber(this.data.callList.Phone)
            }
            if (this.data.callList.Phone && this.data.callList.Phone2 != "") {
                this.CheckPhoneNumber2(this.data.callList.Phone2)
            }
            await this.ChangeState();
            this.ChangeReferralSource()

            if(this.data.callList.StateID!=null && this.data.callList.StateID!=undefined){
            this.state = this.locationService._State.find(s=> s.StateID === this.data.callList.StateID).Name;
            }
            
            if(this.data.callList.CountryID!=null && this.data.callList.CountryID!=undefined){
              this.country = this.locationService._Country.find(s=> s.CountryID === this.data.callList.CountryID).Name;
            }

            if(this.data.callList.MetropolitanAreaId!=null && this.data.callList.MetropolitanAreaId!=undefined){
              this.metroarea = this.locationService._MetroArea.find(s=> s.MetropolitanAreaId === this.data.callList.MetropolitanAreaId).Name;
            }
        
        }
        else {
            this.isedit = false
            this.locationService._MetroArea=null; //for fresh create it should be empty if state not selected.
        }

        this.disableSelectControl();

    }

    loadData(): void {
        this.spinner.show();

        var getData$: CreatePatientDataToGet = [];
        getData$.push(this.locationService.isCallApi.asObservable());
        getData$.push(this.campaignService.isCallApi.asObservable());
        getData$.push(this._lovService.getLovDataById(LovStatusMasterID.Language));
        getData$.push(this._lovService.getLovDataById(LovStatusMasterID.Transportation));

        this.ChangeCountry();
        this.campaignService.LoadActiveCampaign();

        combineLatest(getData$)
            .subscribe((data: any) => this.onDataLoad(data));
    }

    onDataLoad([countryLoadFlag, CampaignLoadFlag, LanguageData, TransportData]: CreatePatientData): void {
        if(countryLoadFlag == true && CampaignLoadFlag == true && LanguageData != null && TransportData !=null) {
            this.LanguageData = <Lov[]>LanguageData;
            this.TransportationData = <Lov[]>TransportData;

            this.spinner.hide();
        }
    }

    disableSelectControl() {
        const campaignIdControl = this.PatientForm.get('campaignId');
        if (campaignIdControl.value > 0) {
            campaignIdControl.disable();
        }

        const CampaignReferralSourceControl = this.PatientForm.get('CampaignReferralSourceId');
        if (CampaignReferralSourceControl.value > 0) {
            CampaignReferralSourceControl.disable();
        }

        const CampaignReferralSourcesAdDetailControl = this.PatientForm.get('CampaignReferralSourcesAdDetailId');
        if (CampaignReferralSourcesAdDetailControl.value > 0) {
            CampaignReferralSourcesAdDetailControl.disable();
        }
    }

    // getLanguageData() {
    //     this._lovService.getLovDataById(LovStatusMasterID.Language).subscribe(res => {
    //         this.LanguageData = <Lov[]>res;
    //     }, error => {
    //         this._Global.handleError(error)
    //     })
    // }

    // getTransportationData() {
    //     this._lovService.getLovDataById(LovStatusMasterID.Transportation).subscribe(res => {
    //         this.TransportationData = <Lov[]>res;
    //     }, error => {
    //         this._Global.handleError(error)
    //     })
    // }

    initForm() {
        this.PatientForm = this.fb.group({
            Address: [''],
            Bmi: [''],
            CampaignReferralSourceId: ['', Validators.required],
            CityID: [''],
            CountryID: [1, Validators.required],
            CrioId: [''],
            DateOfBirth: [''],
            Email: ['', Validators.email],
            FirstName: ['', Validators.required],
            Gender: ['', Validators.required],
            LastName: ['', Validators.required],
            MiddleName: [''],
            // Phone: ['', [Validators.required, Validators.min(900000000), Validators.max(10000000000), Validators.pattern(/^(0|1)\d{9}$/)]],
            // Phone2: ['', [Validators.min(900000000), Validators.max(10000000000), Validators.pattern(/^(0|1)\d{9}$/)]],
            Phone: ['', [Validators.required, Validators.pattern(/^(2|3|4|5|6|7|8|9)\d{9}$/)]],
            Phone2: ['', [Validators.pattern(/^(2|3|4|5|6|7|8|9)\d{9}$/)]],
            Race: [''],
            // ReferralSource: [''],
            StateID: ['', Validators.required],
            MetropolitanAreaId:['',Validators.required],
            ZipCode: [''],
            campaignId: ['', Validators.required],
            CampaignReferralSourcesAdDetailId: ['',],
            Ethnicity: [''],
            LanguageId: [''],
            TransportationId: [''],
            Id: [''],
            AmbassadorLink: ['']
            // IsCRIOPatient: [0]
        })
    }

    onNoClick(): void {
        this.dialogRef.close();

        // (<any>window).ga('send', 'event', { // google event tracker//
        //     eventCategory: 'click',
        //     eventLabel: 'buttonClick',
        //     eventAction: 'onNoClick',
        // });

        // alert("Asdsad");
        // document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInLeft")
        // document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
        // setTimeout(() => { this.dialog.closeAll(); }, 1000);
    }

    get f() {
        return this.PatientForm.controls;
    }

    ChangeCountry() {
        this.locationService.getStatebyCountry(this.PatientForm.controls.CountryID.value)
    }

    async ChangeState() {
        this.locationService.getCitybyState(this.PatientForm.controls.StateID.value)
        await this.locationService.getMetroAreabyStateAsync(this.PatientForm.controls.StateID.value)
    }

    ChangeCampaign() {
        try {
            let id = this.PatientForm.controls.campaignId.value;
            this.ReferralSourceArray = [];
            this.PatientForm.controls.CampaignReferralSourceId.setValue('');
            this.ReferralSourceArray = this._CampaignReferralSourceService._CampaignReferralSource.filter(x =>
                x.CampaignId.toString() === id.toString() && x.IsActive == true);
            let patientcampaignrefid: number = 0;
            if (this.data.callList != undefined && this.data.callList != null && this.data.callList.CampaignId == id) {
                patientcampaignrefid = this.data.callList.CampaignReferralSourceId;
                this.PatientForm.patchValue(this.data.callList);
                if (patientcampaignrefid > 0 && this.ReferralSourceArray.findIndex(x => x.Id === patientcampaignrefid) <= 0) {
                    let data = this._CampaignReferralSourceService._AllCampaignReferralSource.filter(x => x.Id === patientcampaignrefid)[0]
                    if (data) {
                        this.ReferralSourceArray.push(data);
                    }
                }
            }
        } catch (x) {
            this.ReferralSourceArray = [];
        }
    }

    onSubmit() {
        //debugger;
        try {

            if (this.PatientForm.invalid) {
                this.submitted = true;
                return;
            }
            if (this.age > 90) {
                if (confirm("The age of this patient is greater than 90 years , would you like to proceed further?") == true) {
                    this.SavePatient()
                }
                return;
            }
            if (this.CrioIdExist == true) {
                this._Global.showErrorMsg("CRIO Id is already Exist")
                return
            }
            if ((this.PhoneExistingPatient || this.Phone2ExistingPatient) && this.isedit == false) {
                this.existingPatient = this.PhoneExistingPatient ? this.PhoneExistingPatient : this.Phone2ExistingPatient
                //this.openModal()
                if (this.isedit == false) {
                    this.OpenMerge();
                }
                else {
                    this._Global.showSuccessMsg('Phone No exists with different patient.');
                }
                return
            }
            this.submitted = true;
            if (this.PatientForm.invalid) {
                return;
            }
            else {
                this.SavePatient()
            }
        }
        catch (error) {
            this._Global.showErrorMsg(error);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.patientService.isCallApi.next(false)
        }
    }

    SavePatient() {
        //debugger
        this.PhoneExistingPatient = undefined
        this.Phone2ExistingPatient = undefined
        this.submitted = true;
        if (this.PatientForm.invalid) {
            return;
        } else {
            let Id = this.data.callList ? this.data.callList.Id : 0
            if (this.disabledFields) {
                this.PatientForm.controls['CountryID'].enable();
                this.PatientForm.controls['StateID'].enable();

                if(this.data.callList.CountryID!=null) //otherwise it will set user selected value as null.
                {
                  this.PatientForm.controls.CountryID.setValue(this.data.callList.CountryID == 0 || this.data.callList.CountryID == null ? 1 : this.data.callList.CountryID)
                }

                if(this.data.callList.StateID!=null) //otherwise it will set user selected value as null.
                {
                    this.PatientForm.controls.StateID.setValue(this.data.callList.StateID == 0 || this.data.callList.StateID == null ? '' : this.data.callList.StateID)
                }
            }
            this.PatientForm.controls.Id.setValue(Id)
            this._Global.IsReady = false;
            this.spinner.show()
            this.PatientForm.controls.Address.setValue(this.PatientForm.controls.Address.value && this.PatientForm.controls.Address.value != '' ? this.PatientForm.controls.Address.value.trim() : "")
            // this.PatientForm.controls.Bmi.setValue(this.PatientForm.controls.Bmi.value && this.PatientForm.controls.Bmi.value != '' ? this.PatientForm.controls.Bmi.value.trim() : "")
            this.PatientForm.controls.Email.setValue(this.PatientForm.controls.Email.value && this.PatientForm.controls.Email.value != '' ? this.PatientForm.controls.Email.value.trim() : "")
            this.PatientForm.controls.FirstName.setValue(this.PatientForm.controls.FirstName.value && this.PatientForm.controls.FirstName.value != '' ? this.PatientForm.controls.FirstName.value.trim() : "")
            this.PatientForm.controls.LastName.setValue(this.PatientForm.controls.LastName.value && this.PatientForm.controls.LastName.value != '' ? this.PatientForm.controls.LastName.value.trim() : "")
            this.PatientForm.controls.MiddleName.setValue(this.PatientForm.controls.MiddleName.value && this.PatientForm.controls.MiddleName.value != '' ? this.PatientForm.controls.MiddleName.value.trim() : "")
            this.PatientForm.controls.Phone.setValue(this.PatientForm.controls.Phone.value && this.PatientForm.controls.Phone.value != '' ? this.PatientForm.controls.Phone.value.trim() : "")
            this.PatientForm.controls.Phone2.setValue(this.PatientForm.controls.Phone2.value && this.PatientForm.controls.Phone2.value != '' ? this.PatientForm.controls.Phone2.value.trim() : "")
            // this.PatientForm.controls.ZipCode.setValue(this.PatientForm.controls.ZipCode.value && this.PatientForm.controls.ZipCode.value != '' ? this.PatientForm.controls.ZipCode.value.trim() : "")
            // this.PatientForm.controls.CrioId.setValue(this.PatientForm.controls.CrioId.value && this.PatientForm.controls.CrioId.value != '' ? this.PatientForm.controls.CrioId.value.trim() : "")
            //debugger
            this.patientService.SaveAndEditPatient(Id, this.PatientForm.value, this.isedit).subscribe(res => {
                //debugger
                let Isredirect: boolean = false;
                if (this.isedit) {
                    if (this.screenType == CallListType.PreScreener) {
                        this._PrescreenercalllistComponent.GetPreScreenerCallList();
                    }
                    else if (this.screenType == CallListType.Recruiter) {
                        this._RecruitercalllistComponent.GetRecruiterCallList()
                    }
                    else if (this.screenType == CallListType.DNQScreener) {
                        this._DNQList.GetDNQList()
                    }
                    else if (this.screenType == CallListType.Screener) {
                        this._ScreenercalllistComponent.GetScreenerCallList()
                    }
                }
                //debugger;
                this.onNoClick()
                this.patientService.isCallApi.next(true)
                this._Global.IsReady = true;
                this.spinner.hide()
                if (res.Message != undefined && res.Message != null && res.Message != '') {
                    this._Global.showSuccessMsg(res.Message);
                }
                else {
                    if (this.isedit) {
                        this._Global.showSuccessMsg('Updated successfully');
                        this._PatientDetailDialogService.closePatientDetailDialog();
                    }
                    else {
                        this._Global.showSuccessMsg('Saved successfully');
                        this.router.navigateByUrl("/recruitercalllist/recruiter")
                    }
                }
            }, error => {
                if (this.isedit) {
                    this.patientService.isCallApiPreScreen.next(false)
                    this.patientService.isCallApiRecruiter.next(false)
                    this.patientService.isCallApiScreener.next(false)
                }
                this._Global.IsReady = true;
                this.spinner.hide()
                this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                this.patientService.isCallApi.next(false)
            })
        }
    }

    CheckCRIOID() {
        if (this.PatientForm.controls.CrioId.value !== '') {
            this.patientService.CheckCRIOID(this.PatientForm.controls.CrioId.value).subscribe((res: any) => {
                if (res && res.length && res.length > 0) {
                    this.CrioIdExist = true
                    this.CrioIdExistObj = res[0]
                }
                else {
                    this.CrioIdExist = false
                }
            }, err => {
                this._Global.handleError(err)
            })
        }
    }

    CheckPhoneNumber(phoneNumber: string) {
        //debugger
        if (phoneNumber !== '') {
            let Id = this.data.callList ? this.data.callList.Id : 0
            this.patientService.CheckPhoneNumber(phoneNumber, Id).subscribe((res: any) => {
                if (res && res.length && res.length > 0) {
                    this.PhoneExistingPatient = res
                }
                else {
                    this.PhoneExistingPatient = undefined
                }

            }, err => {
                this._Global.handleError(err)
            })
        }
        else {
            this.PhoneExistingPatient = null
        }
    }

    CheckPhoneNumber2(phoneNumber: string) {
        if (phoneNumber !== '') {
            let Id = this.data.callList ? this.data.callList.Id : 0
            this.patientService.CheckPhoneNumber(phoneNumber, Id).subscribe((res: any) => {
                if (res && res.length && res.length > 0) {
                    this.Phone2ExistingPatient = res
                }
                else {
                    this.Phone2ExistingPatient = undefined
                }
            }, err => {
                this._Global.handleError(err)
            })
        }
        else {
            this.Phone2ExistingPatient = null
        }
    }

    Onlytext(event): boolean {
        const Regexpattern = /[\s(a-zA-Z)]/;
        const result = Regexpattern.test(event.key);
        return result;
    }

    Onlynumberbmi(event): boolean {
        const Regexpattern = /[-.(0-9) ]/;
        const result = Regexpattern.test(event.key);
        return result;
    }

    Onlynumber(event): boolean {
        const Regexpattern = /^[0-9]*$/;
        const result = Regexpattern.test(event.key);
        return result;
    }

    CheckSiteStudyInInteraction(data) {
        this.patientService.CheckSiteStudyInInteraction(data.callList.Id).subscribe((res: any) => {
            if (res == true) {
                this.disabledFields = true

                if (data.callList.CountryID !=null && data.callList.CountryID!=undefined) {
                    this.PatientForm.controls['CountryID'].disable();
                }

                if (data.callList.StateID !=null && data.callList.StateID!=undefined) {
                    this.PatientForm.controls['StateID'].disable();
                }
            }
            else {
                this.disabledFields = false
                this.PatientForm.controls['CountryID'].enable();
                this.PatientForm.controls['StateID'].enable();
            }
        }, err => {
            this._Global.handleError(err)
        })
    }

    openModal() {
        this.modalService.open(this.appDialog, { ariaLabelledBy: 'modal-basic-title' })
    }
    ChangeReferralSource() {
        try {
            let id = this.PatientForm.controls.CampaignReferralSourceId.value;
            this.ReferralSourceAdDetailsArray = [];
            this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise = []
            this.PatientForm.controls.CampaignReferralSourcesAdDetailId.setValue('');
            this.PatientForm.controls.CampaignReferralSourcesAdDetailId.enable();
            this.ReferralSourceAdDetailsArray = this._CampaignReferralSourceService._CampaignReferralSourceAdDetails.filter(x =>
                x.CampaignReferralSourcesId.toString() === id.toString());
            var dateTime = moment(new Date()).format('YYYY-MM-DD')
            this.ReferralSourceAdDetailsArray.forEach(element => {
                if (element.startDate >= dateTime || dateTime <= element.endDate) {
                    this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise.push(element)
                }
            })
            let patientcampaignrefadDetailid: number = 0;
            if (this.data.callList != undefined && this.data.callList != null && this.data.callList.CampaignReferralSourceId == id) {
                patientcampaignrefadDetailid = this.data.callList.CampaignReferralSourcesAdDetailId;
                this.PatientForm.patchValue(this.data.callList);
                if (patientcampaignrefadDetailid > 0 && this.ReferralSourceArray.findIndex(x => x.Id === patientcampaignrefadDetailid) <= 0) {
                    this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise.push(this._CampaignReferralSourceService._CampaignReferralSourceAdDetails.filter(x => x.Id === patientcampaignrefadDetailid)[0]);
                    var exisitingData = this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise.filter((element, index, array) => array.indexOf(element) !== index)
                    if (exisitingData && exisitingData.length > 0) {
                        var index = this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise.findIndex(o => o.Id == exisitingData[0].Id)
                        this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise.splice(index, 1)
                    }
                }
            }
        } catch (x) {
            this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise = [];
        }
    }

    CalculateAge() {
        var dateOfBirth: any = new Date(this.PatientForm.controls.DateOfBirth.value)
        if (this.PatientForm.controls.DateOfBirth.value != "") {
            var timeDiff = Math.abs(Date.now() - dateOfBirth);
            this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        }
    }

    OpenMerge() {
        //debugger
        let Name = this.PatientForm.controls.FirstName.value
        Name += this.PatientForm.controls.MiddleName.value != "" ? this.PatientForm.controls.MiddleName.value + " " : " "
        Name += this.PatientForm.controls.LastName.value
        let patientData: any;
        patientData = {
            FirstName: this.PatientForm.controls.FirstName.value,
            MiddleName: this.PatientForm.controls.MiddleName.value,
            LastName: this.PatientForm.controls.LastName.value,
            FullName: Name,
            Phone: this.PatientForm.controls.Phone.value,
            Phone2: this.PatientForm.controls.Phone2.value,
            Email: this.PatientForm.controls.Email.value,
            Gender: this.PatientForm.controls.Gender.value,
            DateOfBirth: this.PatientForm.controls.DateOfBirth.value != '' ? this.PatientForm.controls.DateOfBirth.value : '',
            Campaign: this.PatientForm.controls.campaignId != undefined && this.PatientForm.controls.campaignId.value != '' ? this.campaignService._Campaign.filter(x => x.Id == this.PatientForm.controls.campaignId.value)[0].Name : '',
            CampaignId: this.PatientForm.controls.campaignId.value,
            CampaignRefSource: this.PatientForm.controls.CampaignReferralSourceId != undefined && this.PatientForm.controls.CampaignReferralSourceId.value != '' ? this.ReferralSourceArray.filter(x => x.Id == this.PatientForm.controls.CampaignReferralSourceId.value)[0].Name : '',
            CampaignReferralSourceId: this.PatientForm.controls.CampaignReferralSourceId.value,
            CampaignRefSourceAd: this.PatientForm.controls.CampaignReferralSourcesAdDetailId != undefined && this.PatientForm.controls.CampaignReferralSourcesAdDetailId.value != '' ? this._CampaignReferralSourceService._CampaignReferralSourceAdDetailsDateWise.filter(x => x.Id == this.PatientForm.controls.CampaignReferralSourcesAdDetailId.value)[0].Name : '',
            CampaignReferralSourcesAdDetailId: this.PatientForm.controls.CampaignReferralSourcesAdDetailId.value,
            Address: this.PatientForm.controls.Address.value,
            Bmi: this.PatientForm.controls.Bmi.value,
            CityID: this.PatientForm.controls.CityID.value,
            CountryID: this.PatientForm.controls.CountryID.value,
            CrioId: this.PatientForm.controls.CrioId.value,
            Race: this.PatientForm.controls.Race.value,
            StateID: this.PatientForm.controls.StateID.value,
            ZipCode: this.PatientForm.controls.ZipCode.value,
            Ethnicity: this.PatientForm.controls.Ethnicity.value,
            LanguageId: this.PatientForm.controls.LanguageId.value,
            TransportationId: this.PatientForm.controls.TransportationId.value,
            MetropolitanAreaId:this.PatientForm.controls.MetropolitanAreaId.value,
            Id: 0,
        };
        this.OpenMergeLeadDialog(patientData);
    }
    OpenMergeLeadDialog(data: any) {
        const dialogRef = this.dialog.open(MergePatientComponent, {
            panelClass: 'dailogwidth',
            disableClose: true,
            data: {
                existingData: this.existingPatient,
                leadData: data
            }
        }).afterClosed().subscribe(res => {
        })
    }

    // RestrictPhone(event: any) {
    // if (this.PatientForm.controls.Phone.value.length === 0 && event.key == "1") {
    //     setTimeout(() => {
    //         this.PatientForm.controls.Phone.setValue("")
    //         this.PatientForm.controls.Phone.setValue(1)
    //     }, 1000);
    // }
    // else if (this.PatientForm.controls.Phone.value.length === 0 && event.key == "0") {
    //     setTimeout(() => {
    //         this.PatientForm.controls.Phone.setValue("")
    //         this.PatientForm.controls.Phone.setValue(0)
    //     }, 1000);
    // }
    // else if (this.PatientForm.controls.Phone.value.length === 0 && event.key !== "0") {
    //     this.PatientForm.controls.Phone.setValue("")
    //     event.preventDefault()
    // }
    // else if (this.PatientForm.controls.Phone.value.length === 0 && event.key !== "1") {
    //     this.PatientForm.controls.Phone.setValue("")
    //     event.preventDefault()
    // }

    // }

    // RestrictPhone2(event: any) {
    // if (this.PatientForm.controls.Phone2.value.length === 0 && event.key == "1") {
    //     setTimeout(() => {
    //         this.PatientForm.controls.Phone2.setValue("")
    //         this.PatientForm.controls.Phone2.setValue(1)
    //     }, 1000);
    // }
    // else if (this.PatientForm.controls.Phone2.value.length === 0 && event.key == "0") {
    //     setTimeout(() => {
    //         this.PatientForm.controls.Phone2.setValue("")
    //         this.PatientForm.controls.Phone2.setValue(0)
    //     }, 1000);
    // }
    // else if (this.PatientForm.controls.Phone2.value.length === 0 && event.key !== "0") {
    //     this.PatientForm.controls.Phone2.setValue("")
    //     event.preventDefault()
    // }
    // else if (this.PatientForm.controls.Phone2.value.length === 0 && event.key !== "1") {
    //     this.PatientForm.controls.Phone2.setValue("")
    //     event.preventDefault()
    // }

    // }

    // openAddVaccineDetailModal(){
    //     this.modalService.open(this.appDialog, { ariaLabelledBy: 'modal-basic-title' })
    // }
    openAddVaccineDetailModal() {
        // //debugger;
        this.onNoClick();
        const dialogRef = this.dialog.open(CreatevaccineComponent, {
            panelClass: ['Createvaccinedailogwidth', 'comtrak-modal'],
            disableClose: true,
            data: {
                callList: this.data.callList,
                actionType: 'EditSave'
            }
        })
    }

    sortFields(){
        this.staticDataService.Gender = this.staticDataService.Gender.sort((a, b) =>
        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
        );

        this.staticDataService.Race = this.staticDataService.Race.sort((a, b) =>
        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
        );

        this.staticDataService.Ethinicity = this.staticDataService.Ethinicity.sort((a, b) =>
        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
        );
    }

}

export type CreatePatientDataToGet = [
    Observable<boolean>?,
    Observable<boolean>?,
    Observable<Lov>?,
    Observable<Lov>?
  ];

  export type CreatePatientData = [
    boolean,
    boolean,
    Lov?,
    Lov?
  ]