import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { VisitorFlowColumns } from '@sharedmodels/BackModels/ComTrak/VisitorFlow/VisitorFlowColumns';

@Injectable({
    providedIn: 'root'
})
export class VisitorFlowColumnService {
    public isCallApi = new Subject<boolean>();
    public _VisitorFlowColumns: VisitorFlowColumns[];
    public _activeVisitorFlowColumns: VisitorFlowColumns[];
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

    LoadVisitorFlowColumns() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'VisitorFlowColumn?type=GetAllVisitorFlowColumns', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._VisitorFlowColumns = <VisitorFlowColumns[]>data;
                    this.isCallApi.next(true)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    GetVisitorFlowColumns(): VisitorFlowColumns[] {
        if (this._VisitorFlowColumns !== undefined && this._VisitorFlowColumns.length !== null) {
            return this._VisitorFlowColumns;
        } else {
            this.LoadVisitorFlowColumns();
        }
    }

    LoadActiveVisitorFlowColumns() {
        try {
            this._Global.IsReady = false;
            this.spinner.show()
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'VisitorFlowColumn?type=GetAllActiveVisitorFlowColumns', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._activeVisitorFlowColumns = <VisitorFlowColumns[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(true)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetActiveVisitorFlowColumns(): VisitorFlowColumns[] {
        if (this._activeVisitorFlowColumns !== undefined && this._activeVisitorFlowColumns.length !== null) {
            return this._activeVisitorFlowColumns;
        } else {
            this.LoadActiveVisitorFlowColumns();
        }
    }

    SaveVisitorFlowColumns(body: VisitorFlowColumns) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'VisitorFlowColumn/SaveVisitorFlowColumns', body, { headers: this._Global.httpOptions })
                .subscribe((data: VisitorFlowColumns) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Visitor Flow Columns Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadVisitorFlowColumns()
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    SaveAndEdit(body: VisitorFlowColumns, isEdit: boolean) {
        if (isEdit) {
            this.Edit(body)
        }
        else {
            this.SaveVisitorFlowColumns(body)
        }
    }

    Edit(body: VisitorFlowColumns) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'VisitorFlowColumn?apiType=Edit', body, { headers: this._Global.httpOptions })
                .subscribe((data: VisitorFlowColumns) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Visitor Flow Columns Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadVisitorFlowColumns()
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }


    InActiveVisitorFlowColumns(body: VisitorFlowColumns, message: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'VisitorFlowColumn?apiType=InActiveVisitorFlowColumns', body, { headers: this._Global.httpOptions })
                .subscribe((data: VisitorFlowColumns) => {
                    this.LoadActiveVisitorFlowColumns()
                    this._Global.showSuccessMsg(message)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
}
