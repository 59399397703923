import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { IncomingCallComponent } from '@app/pages/incomingcall/incomingcall.component';
import { Call, Device } from '@twilio/voice-sdk';
import { HttpClient } from '@angular/common/http';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { GlobalfunctionService } from './globalfunction.service';
import { Howl } from 'howler';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { SocketHandlerService } from './socket-handler.service';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { CommunicationService } from '@sharedservices/BackServices/ComTrak/Communication/communication.service';
import * as myGlobals from '@shared/global_var';

/* TODO: Move Types to definition file */
export interface callDataObject {
  parentCallSid: string,     // The unique identifier of the user or object.
  callType: callType,  // The email associated with the user or object.
  patientData: patientData | null,
  department: string,
  direction: callDirection,
  callSid: string | null,
  from: string,
  to: string,
  startTime: Date,
  endTime: Date | null,
  duration: number,
  recruiterEmail: string,
  transferNote: string,
  confSid: string | null,
  participants: Array<participant>,
  dialedAgents: Array<dialedAgent>,
  status: callStatusType,
  recordingSaved: recordingSaveType
}

export enum recordingSaveType {
  yes = 'YES',
  no = 'NO'
}

export interface dialedAgent {
  agent: string,
  status: callReplyOptions,
  callType?: agentCallType,
  startTime: Date, 
  endTime?: Date,
  notes?: string,
  socketId?: string,
}

export interface participant {
  type: participantType,
  address: string,
  callSid: string | null,
  status: callReplyOptions,
  notes?: string,
  callType?: agentCallType,
  mute?: boolean,
  isTemp?: boolean
}

export enum callStatusType {
  completed = 'completed',
  inProgess = 'in-progress',
  missed = 'missed',
}

export enum participantType {
  agent = 'agent',
  patient = 'patient'
}

export enum callType {
  incoming = 'incoming',
  outgoing = 'outgoing',
  inProgess = 'in-progress'
}

export enum callDirection {
  inbound = 'inbound',
  outbound = 'outbound'
}

export enum callReplyOptions {
  accepted = 'accepted',
  declined = 'declined',
  ringing = 'ringing',
  hangup = 'hang-up'
}

export enum agentCallType {
  new = 'new',
  transfer = 'transfer',
  conference = 'conference',
}

export interface patientData {
  id: number,
  firstName: string,
  lastName: callType,
  email: string,
  phone: string,
  status: string,
  StatusId: number,
};

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  private device: Device;
  private currentCall: Call;
  private currentCallData: callDataObject;
  private conferenceName: String = null;
  private callSid: any = null;
  private callConnectToken: string = null;
  private patientCallSid: string = null;
  private isResumingCall: boolean = false;
  private callType: string = null;
  private dialogRef: any = null;
  private callInterval: any = null;
  private putCallonHold: boolean = false;
  private userStatus: string;
  private tempCallData: any = [];
  private incomingSound = new Howl({
    src: ['assets/sounds/call-ringtone.mp3'],
    autoplay: false,
    loop: true,
  });
  private callingEP: string = `${myGlobals._apiPathv3}notifications/calling/`;
  isBargeIn = false;
  toastrActive = false;
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  activeSubscriptions: any = [];

  constructor(
    private dialog: MatDialog, 
    private http: HttpClient, 
    private _RecruiterService: RecruiterService,  
    private _PatientService: PatientService,
    private _globalService: GlobalfunctionService,
    private toastService: ToastrService,
    private socketService: SocketHandlerService,
    private notificationService: NotitificationsService,
    private communicationService: CommunicationService,
  ) {}

  initialize() {
    this.getTwilioToken();
  }

  ngOnDestroy() {
    // On Logout let's destroy the device
    if (this.device) {
      this.device.destroy();
      this.device = null;
    } 
    if (this.activeSubscriptions) {
      console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:118 ~ TwilioService ~ ngOnDestroy ~ this.activeSubscriptions:`, this.activeSubscriptions);

      this.activeSubscriptions.forEach((sub) => {
        sub.unsubscribe();
      })
    }
  }

  getTwilioToken() {
    console.log('getTwilioToken: Init');
    /* On App Load Let's load Twilio Access Token */
    
    this._globalService.getLoginUserEmail();
    const agentEmail = this._globalService.loginUserEmail;
    if (String(agentEmail).length > 0) {
      this.communicationService.generateToken(agentEmail).subscribe({
        next: (tokenResponse) => {
          console.log('getTwilioToken: Response', tokenResponse);
          if (tokenResponse.token) {
              this.initializeDevice(tokenResponse.token);
          }
        },
        error: (err:any) => {
            this.showErrorToast('info', '', 'Error, Connecting you to the calling Backend. Please contact the develoepr.');
            console.error(err);
            return;
          }
        });
    }
}

  initializeDevice(token: string) {
    console.log('getTwilioToken: initializeTwilio Service', token);
    this.device = new Device(token, { logLevel: 3 });

    this.device.on("ready", (device) => {
      console.log("Twilio.Device Ready!", device);
      
    });

    const handleSuccessfulRegistration = () => {
      console.log('The device is ready to receive incoming calls.')
      
      /* TODO: Test on going offline and coming online - May need to remove subs */
      if (this.activeSubscriptions && this.activeSubscriptions.subIncoming) this.activeSubscriptions.subIncoming.unsubscribe();
      this.activeSubscriptions.subIncoming = this.socketService.receiveIncomingCall().subscribe((callObj) => {
        console.log('==============> Incoming Call event called => ', callObj);
        this.setIncomingCall(callObj);
      });

      if (this.activeSubscriptions && this.activeSubscriptions.subCallUpdate) this.activeSubscriptions.subCallUpdate.unsubscribe();
      this.activeSubscriptions.subCallUpdate = this.socketService.receiveCallObjEvent().subscribe(callObj => {
        console.log('==============> Call update event called => ', callObj);
        this.updateCallData(callObj);
      });
    }
    
    this.device.on('registered', handleSuccessfulRegistration);
    
    /* Let's register if device is unregistered */
    // if (this.device.state === 'unregistered')
    console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:200 ~ TwilioService ~ initializeDevice ~ this.device.state:`, this.device.state);

    /* TODO: Deep - Ask for Mic and Speak Permission if not given */
    /* TODO: Deep - Add Mic and Speak Options so that user can change the default deivce - https://www.twilio.com/docs/voice/sdks/javascript/best-practices#working-with-microphones-and-getusermedia */
    this.device.register();

    this.device.on('error', (error) => {
      console.error('Twilio Device error:', error.message, error.code);

      if (error.code === 20104) {
        this.showErrorToast('info', '', 'Twilio Token Expired, please refresh your browser and try again.');
        // May be get token again
        this.getTwilioToken();
        this.endCallCleanup();
      }
    });

    this.device.on(Device.EventName.TokenWillExpire, async () => {
      this.getTwilioToken();
    });

    /* Let's watch the current call - On new incoming call start need to display it */
    this.activeSubscriptions.myStatusSub = this._RecruiterService.myStatus.subscribe(newStatus => {
      console.log("🚀 ~ TwilioService ~ myStatusSub ~ newStatus:", newStatus)

      if (this.userStatus === newStatus) return;

      this.userStatus = newStatus;
      if (newStatus === 'online') {
        /* Let's register if device is unregistered */
        if (this.device.state === 'unregistered')
        this.device.register();
      } else {
        if (this.device.state === 'registered')
        this.device.unregister();
      }
    });
  }

  acceptCall(callSid: string) {
    if (this.currentCall && this.currentCall.parameters.CallSid === callSid) {
      this.currentCall.accept();
    }
  }

  rejectCall(callSid: string) {
    if (this.currentCall && this.currentCall.parameters.CallSid === callSid) {
      this.currentCall.reject();
      this.endCallCleanup();
    }
  }

  startCall = async (toNumber, fromNumber) => {
    if (!this.device) {
      this.showErrorToast('info', '', 'Error establishing connection to the Call Backend, please refresh your browser and try again.');
      return;
    }

    if (this.currentCall && this.currentCall.parameters.Call) {
      this.showErrorToast('info', '', 'Can not make a call, as you\'ve another active call on-going. If this is error, please refresh your browser and try again.');
      return;
    }

    const formattedNumber = this.formatPhoneNumber(toNumber);
    const params = { params: { 
      recruiterEmail: this._globalService.loginUserEmail,
      To: formattedNumber, 
      callerId: fromNumber,
    }};
    this.patientCallSid = null;
    this.changeUserStatus('dnd');
    this.currentCall = await this.device.connect(params);
    this._RecruiterService.currentCall.next(this.currentCall);
    if (this.currentCall && this.currentCall.parameters && this.currentCall.parameters.CallSid) {
      console.log('On Start Call: Found Callsid: ', this.currentCall.parameters.CallSid);
      this.conferenceName = `call_${this.currentCall.parameters.CallSid}`;
      this.patientCallSid = this.currentCall.parameters.CallSid;
      /* Let's join the Room to know call update status */
      this.sendSocketEvent('joinRoom', this.patientCallSid);
    } else {
      console.log('On Start Call: Callsid not found');
    }
    this.setCallEvents(this.currentCall);
    this.callType = 'outgoing';
  }

  endCall(participant) {
    console.log("🚀 ~ TwilioService ~ endCall ~ this.currentCall:", this.currentCall, this.putCallonHold, participant)

    if (participant) {
      const url = `${this.callingEP}end-call`; // Replace with your Twilio Function URL
      this.http.post(url, { callSid: participant.callSid, callType: this.callType }, {headers: this._globalService.httpOptions}).subscribe(response => {
        console.log("🚀 ~ TwilioService ~ this.http.post ~ end-call response:", response)
      });

      console.log('End Call ===> ', participant.callSid, this.currentCall.parameters.CallSid);
      if (participant.callSid !== this.currentCall.parameters.CallSid)
      return;
    }

    if (this.currentCall) {
      this.currentCall.disconnect();
      this.endCallCleanup();
    }
  }

  // Mute/Unmute the call
  muteCall(mute, participant) {
    if (this.currentCall) {
      if (!participant) this.currentCall.mute(mute);
      this.updateParticipants({ action: 'mute', data: { mute, participant }})
      console.log('Call muted');
    }
  }

  // Hold the call
  holdCall = async (hold, agentTo = {}, notes = '') => {
    console.log('Holding: ', hold, this.currentCall.parameters.CallSid, this.patientCallSid, agentTo, notes);
    return new Promise((resolve, reject) => {
      if (hold) {
        if (this.callInterval) clearInterval(this.callInterval);
        const url = `${this.callingEP}hold-call`; // Replace with your Twilio Function URL
        this.http.post(url, { callSid: this.patientCallSid ? this.patientCallSid : this.currentCall.parameters.CallSid, callType: this.callType },{headers: this._globalService.httpOptions}).subscribe(response => {
          console.log("🚀 ~ TwilioService ~ this.http.post ~ response:", response)
          return resolve(true);
        });
        console.log('Call on hold');
      } else {
        console.log('Call on removing from hold');
        console.log('Hold ==> ', hold);
        const url = `${this.callingEP}resume-call`; // Replace with your Twilio Function URL
        if (!agentTo || !agentTo['type']) this.isResumingCall = true;
        this.http.post(url, { callSid: this.patientCallSid ? this.patientCallSid : this.currentCall.parameters.CallSid, callType: this.callType, clientId: agentTo && agentTo['type'] ? agentTo : this._globalService.loginUserEmail, patientData: this._RecruiterService.contactCenterRowData.getValue(), notes },
        {headers: this._globalService.httpOptions}).subscribe(response => {
          console.log("🚀 ~ TwilioService ~ this.http.post ~ Call Resume Response:", response)
          return resolve(true);
        });
        console.log('Hold Call Resume Done ==> ', this.currentCall);
      }
    });
  }

  sendDigit(digit: string) {
    console.log('Send Digit: ', digit);
    if (this.currentCall && digit && digit !== '') {
      this.currentCall.sendDigits(digit);
    }
  }

  transferCall = async (agentTo: object, notes: string) => {
    console.log('Transferring the call to...', agentTo);
    // await this.holdCall(true, toObject);
    console.log('Asking backend to call the next agent...');

    if (this.callInterval) clearInterval(this.callInterval);
    // if (!agentTo || !agentTo['type']) this.putCallonHold = true;
    const url = `${this.callingEP}transfer-call`; // Replace with your Twilio Function URL

    const postData = {
      callSid: this.patientCallSid ? this.patientCallSid : this.currentCall.parameters.CallSid, 
      callType: this.callType,
      clientId: agentTo,
      isTransfer: notes !== 'ADD_TO_CALL',
      notes,
    };
    this.http.post(url, postData,{headers: this._globalService.httpOptions}).subscribe(response => {
      console.log("🚀 ~ TwilioService ~ this.http.post ~ response:", response)
    });

    // await this.holdCall(false, toObject, notes);
    console.log('Done, the next agent to take the call now.');
    // this.endCallCleanup();
  };

  addToCall = async (toObject: object, callObj: object = {}) => {
    console.log("🚀 ~ TwilioService ~ addToCall= ~ toObject:", toObject)
    const agentToAdd = toObject['email'];
    // this.putCallonHold = true;
    console.log("🚀 ~ TwilioService ~ addToCall= ~ this.putCallonHold:", this.putCallonHold)

    /* Changes for Barge in */
    if (agentToAdd === 'self' && callObj['call_sid']) {
      if (callObj['call_sid']) {
        this.conferenceName = `call_${callObj['call_sid']}`;
        this.patientCallSid = callObj['call_sid'];
        this.callType === 'inbound' ? 'incoming' : 'outgoing';
      }
    }

    this.addAgentToConference(agentToAdd);
  }

  bargeInCall = async(dataObject, type = 'barge') => {
    console.log(`file: twilio.service.ts:421 ~ TwilioService ~ bargeInCall=async ~ dataObject:`, dataObject);
    /* TODO: What if agent tries to barge-in after call is completed */
    const { call_obj, patient_obj } = dataObject;

    this.currentCallData = call_obj;
    const parentCallSid = call_obj.call_sid;
    this.currentCallData.callSid = call_obj.call_sid;
    this.patientCallSid = parentCallSid;
    /* Let's join the Room to know call update status */
    this.sendSocketEvent('joinRoom', parentCallSid);

    /* Notify other agents that call is picked up */
    this.sendSocketEvent('call-sid-update', {
      agent: this._globalService.loginUserEmail,
      parentCallSid,
      type: 'barge-in',
      status: callReplyOptions.accepted,
    })
    this.isBargeIn = true;
    // this.currentCallData = ;

    const params = { params: { 
      recruiterEmail: this._globalService.loginUserEmail,
      agentType: 'self', 
      parentCallSid: parentCallSid,
      callType: callType.inProgess,
      isCallBarge: type
    } };
    this.currentCall = await this.device.connect(params);
    this.setCallEvents(this.currentCall);
    this.callType = 'incoming';
    this._RecruiterService.currentCall.next(this.currentCall);
    // this._RecruiterService.currentCallData.next(this.currentCallData);
    this._RecruiterService.contactCenterFlag.next(true);
    this._RecruiterService.contactCenterType.next('call');
    this._RecruiterService.contactCenterRowData.next(patient_obj);
    this._PatientService.SetPatientId(patient_obj.Id)
    this._PatientService.SetRowData(patient_obj)
  }

  // joinConference = async () => {
  //   this.addAgentToConference(agentToAdd);
  // }

  addAgentToConference = async (newAgentId: string) => {
    console.log("🚀 ~ TwilioService ~ addAgentToConference= ~ newAgentId:", newAgentId)
    const currentCallSid = this.currentCall && this.currentCall.parameters && this.currentCall.parameters.CallSid ? this.currentCall.parameters.CallSid : '';
    const callSID = this.patientCallSid ? this.patientCallSid : currentCallSid;
    if (callSID === null) {
      this.showErrorToast('info', '', 'Can not add agent as no call is on-going.');
      return;
    }

    if (this.conferenceName === null) {
      this.conferenceName = `call_${this.patientCallSid}`
      this.showErrorToast('info', '', 'Conference Call Created, Adding the other agent to the call..');
    }

    const agentEmail = newAgentId === 'self' ? this._globalService.loginUserEmail : newAgentId;
    const agentType = newAgentId === 'self' ? 'self' : '';

    console.log("🚀 ~ TwilioService ~ this.http.post ~ this.conferenceName:", this.conferenceName);
    this.isResumingCall = true;
    this.http.post(`${this.callingEP}add-agent`, { 
      newAgentId: agentEmail,
      agentType, 
      conferenceName: this.conferenceName, 
      callSID,
      callType: this.callType,
      patientData: this._RecruiterService.contactCenterRowData.getValue() 
    },
    {headers: this._globalService.httpOptions}).subscribe(response => {
      console.log("🚀 ~ TwilioService ~ this.http.post ~ response:", response)
      this.showErrorToast('info', '', 'Agent added to the call.');
    });
  }

  showErrorToast(type: string, title: string, message: string): void {
    if (!this.toastrActive) {
      this.toastrActive = true;
      const toast = this.toastService[type](title, message, this.toastOptions);
      toast.onHidden.subscribe(() => this.toastrActive = false);
    }
  }

  private formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber.startsWith('+1')) {
      return `+1${phoneNumber}`;
    }
    return phoneNumber;
  }

  private endCallCleanup(): void {
    console.log('endCallCleanup called ==> ')
    if (this.callInterval) clearInterval(this.callInterval);
    if (this.patientCallSid) this.socketService.leaveRoom(this.patientCallSid);
    this.callType = null;
    this.currentCallData = null;
    this.currentCall = null;
    this.isResumingCall = false;
    this.putCallonHold = false;
    this.patientCallSid = null;
    this._RecruiterService.currentCall.next(null);
    this._RecruiterService.currentCallData.next(null);
    this.incomingSound.stop();
    // On call end let's clean the audio/mic usage from twilio voice sdk
    this.device.disconnectAll();

    // Let's make user online again to receive other calls
    this.changeUserStatus('online');
  }

  private setCallEvents(currentCall): void {
    console.log('Setting up call events');
    // Handle the incoming call here
      // Listen for call state changes
      
      currentCall.on('accept',(call) => {
        console.log('EVENT:Call accepted:', call);
        // console.log('CallToken: ', call.connectToken);
        this.callConnectToken = call.connectToken;
        
        if(this.callType === 'outgoing') {
          if (currentCall && currentCall.parameters && currentCall.parameters.CallSid) {
            this.conferenceName = `call_${call.parameters.CallSid}`;

            if (this.tempCallData.length > 0) {
              console.log(`${new Date().toISOString()} TwilioService ~ currentCall.on ~ this.tempCallData:`, this.tempCallData, call.parameters.CallSid);
              
              const tempDatabySid = this.tempCallData.slice().reverse().find(item => item.callSid === call.parameters.CallSid);

              if (tempDatabySid) {
                this.currentCallData = tempDatabySid;
                this.tempCallData = [];
              }
            }   
          }
        }

        console.log('this.currentCall.parameters.CallSid ==> ', call, call.parameters, call.parameters.CallSid);

        // User should not be available for other calls during an on-goingcall
        this.changeUserStatus('dnd');
        /* Let's send updated CallSid */
          this.sendCallSid();
        // Let's get Child Call SID
        // this.http.post(`${this.callingEP}get-child-calls`, { parentCallSid: call.parameters.CallSid, type: 'ongoing' }, {headers: this._globalService.httpOptions}).subscribe(response => {
        //   console.log("🚀 ~ TwilioService ~ this.http.post ~ Get Child Calls => response:", response);
        //   if (response['calls'] && response['calls'][0]) {
        //     this.patientCallSid = response['calls'][0].sid;

        //     let currentPatientData = this._RecruiterService.contactCenterRowData.getValue();
        //     currentPatientData['patientCallSid'] = response['calls'][0].sid;
        //   }
        // });
      });

      currentCall.on('disconnect', () => (call) => {
        console.log('EVENT:Call disconnected:', call);
        this.endCallCleanup();
      });

      currentCall.on('cancel', () => {
        console.log('EVENT:Call canceled');
        // Let's close the Incoming call dialog
        if (this.dialogRef) this.dialogRef.close();

        // Let's set the current call to null
        this.endCallCleanup();
      });

      currentCall.on('reject', () => {
        console.log('EVENT:Call rejected');
        this.endCallCleanup();
      });
      
      currentCall.on('transportClose', () => {
        console.log('EVENT:Call transportClose');
        this.endCallCleanup();
      });

      currentCall.on('error', (error) => {
        console.error('EVENT:Call error:', error);
        this.endCallCleanup();
      });

      /* TEMP FIX: Disconnect event not working so setting a workaround to disconnect call */
      this.setCallInterval(currentCall);

      console.log('Call Eventnames:');
      console.log(currentCall.eventNames())
  }

  changeUserStatus(status: string): void {
    console.log(`${new Date().toISOString()} TwilioService ~ changeUserStatus ~ status:`, status);
    
    this._RecruiterService.myStatus.next(status);
  }

  private setCallInterval(currentCall): void {
     /* TEMP FIX: Disconnect event not working so setting a workaround to disconnect call */
     this.callInterval = setInterval(() => {

      // console.log('Current Call State: ', currentCall.status(), this.putCallonHold);
      const callStatus = currentCall.status();
      const callLastStatus = this.currentCall ? this.currentCall.status() : null;
      // console.log('Call Status: ', callStatus, callLastStatus);

      if (currentCall.status() === 'closed') {
        this.endCallCleanup();
      }
    }, 1000);
  }

  private async getPatientData(phoneNumber) {
    return new Promise((resolve, reject) => { 
      this.notificationService.PatientData(this.removeCountryCode(phoneNumber)).subscribe(
        (response: any) => {
          console.log("🚀 ~ TwilioService ~ getPatientData response:", response)
          resolve(response.data);
        // this.spinner.hide();
        // this._Global.IsReady = true;
        },
        (error: any) => {
          resolve({});
        }
    );
    });
  }

  private removeCountryCode(phoneNumber) {
    if (phoneNumber.startsWith('+1')) {
      return phoneNumber.slice(2);
    }
    return phoneNumber;
  }

  private sendSocketEvent(event, data) {
    console.log("🚀 ~ TwilioService ~ sendSocketEvent ~ data:", event, data)
    
    this.socketService.socket.emit(event, data, 
    err => {
        if (err){
            console.log("🚀 ~ TwilioService ~ sendSocketEvent ~ err:", err)
            return;
        }
    });
  }

  private updateCallData(callData) {
    console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:563 ~ TwilioService ~ updateCallData ~ callData:`, callData, callData.status, !this.currentCall, this.dialogRef, this.currentCallData);

    // When another agent already picked up the same call
    if (callData && callData.status && callData.status === 'picked-up-by-other') {
      if (callData.agent !== this._globalService.loginUserEmail) {
        this.showErrorToast('info', '', `${callData.agent} ${callData.type ? 'has picked up the call.' : 'has already picked up this call.'}`);
        if (this.dialogRef) this.dialogRef.close('force-close');
        this.endCallCleanup();
      }
      return;
    }

    if (!this.currentCall) {

      if (callData.type === 'agent-declined' && callData.agents) {
        // If I my self rejects the call then It should end in other tabs
        const rejectedByMe = callData.agents[0] === this._globalService.loginUserEmail;
        console.log(`${new Date().toISOString()} ~ ~ file: twilio.service.ts:747 ~ TwilioService ~ updateCallData ~ rejectedByMe:`, rejectedByMe);
        if (rejectedByMe) {
          if (this.dialogRef) this.dialogRef.close('force-close');
          this.endCallCleanup();
        }
        return;
      }

      if(this.dialogRef) {

        if (callData.status === callStatusType.completed || callData.status === callStatusType.missed) {
          console.log('Call Disconnected => Closing the dialogs');
          this.dialogRef.close('force-close');
          this.endCallCleanup();
          return;
        }

        const dialedAgents = callData.dialedAgents;


        let acceptedBy = [];

        if (dialedAgents) {
          acceptedBy = dialedAgents.filter((agent) => (agent.status === callReplyOptions.accepted));
          console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:646 ~ TwilioService ~ updateCallData ~ acceptedBy:`, acceptedBy);
        }

        if (acceptedBy.length === 0) {
          return;
        }

        const acceptedByMe = acceptedBy[0].agent === this._globalService.loginUserEmail && acceptedBy[0].socketId === this.socketService.socket.id;

        console.log(acceptedBy[0].agent, 
          this._globalService.loginUserEmail, 
          acceptedBy[0].socketId, 
          this.socketService.socket.id
        );

        if (acceptedBy.length > 0 && !acceptedByMe) {
          this.dialogRef.close('force-close');
          this.endCallCleanup();
          return;
        }
      }
    } else {
      console.log('Ongoing call ==>');

      if (!this.currentCallData) {
        console.log('Received Socket data for other call ID: Ignoring as we have no local call data', this.currentCallData, callData.callSid);
        this.tempCallData.push(callData);
        console.log(`${new Date().toISOString()} TwilioService ~ currentCall.on ~ this.tempCallData storing more:`, this.tempCallData);
        return; 
      }
      if (this.currentCallData && this.currentCallData.callSid !== callData.callSid) {
        console.log('Received Socket data for other call ID: Ignoring', this.currentCallData.callSid, callData.callSid);
        return;
      }
      
      if (callData.type === 'agent-declined') {
        console.log('Got Agent Call Declined Event: ', callData.agents);
        if (!callData.agents || !callData.agents.length) {
          return;
        }
        // Let's look calldata.agents and if we find any this.currentCallData.isTemp == true having same address then we need to show a toast showing, callData.agents[i] didn't pick the call.
        callData.agents.forEach(agentEmail => {
          const isAddedByMe = this.currentCallData.participants.find((p) => {
            return p.address === agentEmail && p.isTemp;
          });
          console.log(`${new Date().toISOString()} ~ ~ file: twilio.service.ts:767 ~ TwilioService ~ isAddedByMe ~ isAddedByMe:`, isAddedByMe);

          if (isAddedByMe) {
            this.showErrorToast('info', '', `${agentEmail} didn't pick the call.`);
          }

        });
        return;
      }


      // const myCallDatainAgent:dialedAgent[] = this.sortByLatest(callData.dialedAgents).filter((d) => {
      //   return d.agent === this._globalService.loginUserEmail
      // });
      const myCallData:participant = this.sortByLatest(callData.participants).find((p) => {
        return p.address === this._globalService.loginUserEmail
      });
      console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:717 ~ TwilioService ~ constmyCallData:participant=this.sortByLatest ~ myCallData:`, myCallData);

      if (callData.status === callStatusType.completed){
        console.log(`${new Date().toISOString()}  ~ file: twilio.service.ts:663 ~ TwilioService ~ updateCallData ~ callData.callStatus === callStatus.completed:`, (callData.status === callStatusType.completed));
        if (this.dialogRef) this.dialogRef.close('force-close');
        this.endCall(null);
      }

      /* TODO: Bug: When Transfer is done so old agent should be disconnected
        myCallData: What if Agent was added before and again being added?
      */
      if (myCallData && myCallData.status === callReplyOptions.hangup) {
        console.log("🚀 ~ TwilioService ~ updateCallData ~ myCallData:", myCallData)
        
        if (this.dialogRef) this.dialogRef.close('force-close');
        this.endCall(null);
        return; 
      }

      console.log('this.currentCallData ==> ', this.currentCallData);
      
      /* Let's update new participants to our participants */
      if (!this.currentCallData) this.currentCallData = callData;
      console.log('this.currentCallData after ==> ', this.currentCallData);

      this.currentCallData.parentCallSid = callData.parentCallSid;
      if (!this.patientCallSid || this.patientCallSid !== callData.parentCallSid) {
        this.patientCallSid = callData.parentCallSid;
        /* Let's join the Room to know call update status */
      this.sendSocketEvent('joinRoom', this.patientCallSid);
      }

      this.currentCallData.participants = callData.participants;
      this._RecruiterService.currentCallData.next(this.currentCallData);
    }

  }

  /* TODO: What happens when multiple calls come at same time to same agent? */
  private setIncomingCall(callObj) {
    const { parentCallSid, from, to, dialedAgents } = callObj;
    console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:517 ~ TwilioService ~ setIncomingCall ~ callObj:`, callObj,  this.userStatus);

    if ( this.userStatus !== 'online') {
      console.log('User is not online to receive the call.');
       /* Notify other agents that call is picked up */
      this.sendSocketEvent('call-sid-update', {
        agent: this._globalService.loginUserEmail,
        parentCallSid,
        status: callReplyOptions.declined,
      })
      return;
    }
    
    if (this.currentCallData && this.currentCallData.dialedAgents) {
      console.log('Already have an incoming call to answer.');
      this.sendSocketEvent('call-sid-update', {
        agent: this._globalService.loginUserEmail,
        parentCallSid,
        status: callReplyOptions.declined,
      })
      return;
    }


    /* Let's join the Room to know call update status */
    this.sendSocketEvent('joinRoom', parentCallSid);

    this.currentCallData = callObj;

    let { patientData } = callObj;
    // Let's set call data
    if (parentCallSid) {
      this.patientCallSid = parentCallSid;
    }

    let callParams = {
      CallSid: parentCallSid,
      From: from,
      To: to,
    }

    const myCallData = this.sortByLatest(dialedAgents)
                      .find((d) => {
                        return d.agent === this._globalService.loginUserEmail && d.status !== callReplyOptions.declined
                      });


    console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:768 ~ TwilioService ~ setIncomingCall ~ myCallData:`, myCallData);


    if (myCallData && myCallData.callType === agentCallType.transfer) {
      callParams['notes'] = myCallData.notes;
    }

    /* TODO: Deep Play the Ringtone While Incoming Call */
    this.dialogRef = this.dialog.open(IncomingCallComponent, {
      panelClass: ['small-dailog-width'],
      disableClose: true,
      data: { callParams , patientData},
    });

    // Let's play a sound as ringtone when an incoming call comes
    this.incomingSound.play();

    this.socketService.notifyMe({
      title: `New Incoming Call from ${patientData.FirstName ? `${patientData.FirstName} ${patientData.LastName}` : `${callParams.From}`}`,
      content: `Click to Open Tab and Respond`
    }, 'call');


    this.dialogRef.afterClosed().subscribe( async (result) => {
      console.log('On Call Respond => ', result);
      this.incomingSound.stop();
      
      if (result === 'force-close') {
        // Let's close the Incoming call dialog
        if (this.dialogRef) this.dialogRef.close();
        this.dialogRef = undefined;
        return;
      }
      this.dialogRef = undefined;

      /* Notify other agents that call is picked up */
      this.sendSocketEvent('call-sid-update', {
        agent: this._globalService.loginUserEmail,
        parentCallSid,
        status: result ? callReplyOptions.accepted : callReplyOptions.declined,
      })

      if (!this.patientCallSid) return;

      /* On Call Answered */
      if (result) {
        console.log('Passing Patient Data => ', patientData);

        if (!patientData || !patientData.Id || !patientData.Phone) {
          patientData = { ...patientData };
          patientData.Id = 0;
          patientData.Phone = callParams.From;
          patientData.FirstName = 'Unknown';
          patientData.LastName = 'Caller';
        }

        const params = { params: { 
          recruiterEmail: this._globalService.loginUserEmail,
          agentType: 'self', 
          conferenceName: `call_${parentCallSid}` ,
          parentCallSid,
          callType: myCallData.callType,
        } };

        /* For Transferred to me call -> We need to remove Parent ID */
        if (myCallData && myCallData.callType === agentCallType.transfer) {
          this.patientCallSid = null;
        } else if (!this.patientCallSid) return;
        
        this.currentCall = await this.device.connect(params);
        this.setCallEvents(this.currentCall);
        this.callType = 'incoming';
        this._RecruiterService.currentCall.next(this.currentCall);
        this._RecruiterService.currentCallData.next(this.currentCallData);
        this._RecruiterService.contactCenterFlag.next(true);
        this._RecruiterService.contactCenterType.next('call');
        this._RecruiterService.contactCenterRowData.next(patientData);
        this._PatientService.SetPatientId(patientData.Id)
        this._PatientService.SetRowData(patientData);
      } else {
        this.endCallCleanup();
      }
    });
  }

  sendCallSid = () => {
    console.log('Accepted Call SID: => ', ((this.currentCall && this.currentCall.parameters) ? this.currentCall.parameters.CallSid : false), this.patientCallSid);
    if (this.currentCall && this.currentCall.parameters && this.currentCall.parameters.CallSid) {

      if (!this.patientCallSid) {
        this.patientCallSid = this.currentCall.parameters.CallSid;
        this.conferenceName = `call_${this.currentCall.parameters.CallSid}`;
        /* Let's join the Room to know call update status */
        this.sendSocketEvent('joinRoom', this.patientCallSid);
      }

      /* Update Call SID to backend */
      this.sendSocketEvent('call-sid-update', {
        agent: this._globalService.loginUserEmail,
        parentCallSid: this.patientCallSid,
        callSid: this.currentCall.parameters.CallSid,
        status: callReplyOptions.accepted,
        type: this.isBargeIn ? 'barge-in' : null
      })
    } else {
      setTimeout(() => {
        this.sendCallSid();
      }, 500)
    }
  }

  updateParticipants = (dataParam) => {
    console.log(`${new Date().toISOString()}  ~ file: twilio.service.ts:842 ~ TwilioService ~ dataParam:`, dataParam);

    const { action, data } = dataParam;
    
    let participants = this.currentCallData.participants;
    if (action === 'mute') {
      let agentToUpdate = data.participant && data.participant.address ? data.participant.address : this._globalService.loginUserEmail;

      participants = participants.map((p) => {
        
        if (p.address === agentToUpdate) {
          p.mute = data.mute;
        }
        
        return p;
      });
      console.log(`${new Date().toISOString()} ~ file: twilio.service.ts:852 ~ TwilioService ~ participants=participants.map ~ participants:`, participants);
    }

    this.currentCallData.participants = participants;

    this.sendSocketEvent('call-sid-update', {
      agent: this._globalService.loginUserEmail,
      parentCallSid: this.patientCallSid,
      participants
    })

  }

  sortByLatest(arr) {
    return arr.sort((a, b) => {
      // Sort by startTime (latest first)
      return new Date(b.startTime).getTime() - new Date(a.startTime).getTime(); // Descending order
    })
  }
}
