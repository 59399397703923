import { UserRoleService } from './../../../Shared/Services/BackServices/ComTrak/role/userrole.service';
import { Staff } from './../../../Shared/Models/BackModels/ComTrak/Setups/Staff';
import { StaticDataService } from '@sharedservices/BackServices/ComTrak/Setups/StaticData.service';
import { StaffService } from './../../../Shared/Services/BackServices/ComTrak/Staff/staff.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Site } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { EmployeeTypeService } from '@sharedservices/BackServices/ComTrak/Setups/EmployeeType.service';
import { EmployeeType } from '@sharedmodels/BackModels/ComTrak/Setups/EmployeeType';

@Component({
  selector: 'app-createstaff',
  templateUrl: './createstaff.component.html',
  styleUrls: ['./createstaff.component.scss', '../../style.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),

    ]),
  ]
})
export class CreatestaffComponent implements OnInit {
  StaffForm: FormGroup;
  submitted: boolean = false;
  public _data: Staff;
  isedit: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };
  disbaledFields: boolean = false;
  constructor(public dialogRef: MatDialogRef<CreatestaffComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public siteService: SiteService,
    public fb: FormBuilder, public staffService: StaffService, public staticDataService: StaticDataService, public userRoleService: UserRoleService,
    public spinner: NgxSpinnerService, public _Global: GlobalfunctionService, public employeeTypeService: EmployeeTypeService) { }

  ngOnInit() {
    this.initForm();
    this.siteService.GetSites("");
    this.employeeTypeService.GetEmployeeType()
    if (this.data.callList != undefined) {
      this.isedit = true;
      this.StaffForm.patchValue(this.data.callList);
      this.StaffForm.controls.recruiterSitesDetail.setValue(this.data.callList.recruiterSites);
      this.StaffForm.controls.EmployeeTypeId.setValue(this.data.callList.EmployeeTypeId == 0 ? '' : this.data.callList.EmployeeTypeId);
      this.ChangeEmployeeType()
    }
    else {
      this.isedit = false;
    }
  }

  initForm() {
    this.StaffForm = this.fb.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.email, Validators.required]],
      IsActive: [false],
      StaffType: ['', Validators.required],
      Phone: ['', [Validators.min(90000000), Validators.max(10000000000)]],
      // EmployeeType: ['', Validators.required],
      recruiterSitesDetail: [[], Validators.required],
      EmployeeTypeId: ['', Validators.required],
      EmployeeCategoryId: [null],
      Id: [0],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', {
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.StaffForm.controls;
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  ClickIsActive() {
    if (this.data && this.data.callList && this.StaffForm.controls.IsActive.value == true) {
      this.spinner.show()
      this._Global.IsReady = false
      this.staffService.GetAssignPatientCount(this.data.callList.Id).subscribe((res: any) => {
        if (res && res.length && res.length > 0) {
          if (res[0].AssignedPatientCount && res[0].AssignedPatientCount > 0) {
            this._Global.showErrorMsg("This recruiter cannot be deactivated. Please unassigned all patients against this recruiter.")
            this.disbaledFields = true
          }
          else {
            this.disbaledFields = false
          }
        }
        this._Global.IsReady = true
        this.spinner.hide()
      }, (err => {
        this._Global.showErrorMsg(err)
        this.spinner.hide()
        this._Global.IsReady = true
      }))
    }
    else {
      // return false;
    }
  }

  Onlyphonenumber(event): boolean {
    const Regexpattern = /[-.(0-9) ]/;
    const result = Regexpattern.test(event.key);
    return result;
  }

  ChangeEmployeeType() {
    let lovDetailId: EmployeeType = this.employeeTypeService._employeeType.filter(o => o.Id == this.StaffForm.controls.EmployeeTypeId.value)[0]
    if (lovDetailId) {
      this.StaffForm.controls.EmployeeCategoryId.setValue(lovDetailId.LovDetailId)
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.StaffForm.invalid) {
      return;
    }
    else {
      try {
        let siteIDarray = [];
        if (this.StaffForm.controls.recruiterSitesDetail.value && this.StaffForm.controls.recruiterSitesDetail.value.length > 0) {
          this.StaffForm.controls.recruiterSitesDetail.value.forEach(element => {
            siteIDarray.push({ SiteID: element.Id });
          });
        }

        let Staffobject: Staff = {
          FirstName: this.StaffForm.controls.FirstName.value,
          LastName: this.StaffForm.controls.LastName.value,
          Email: this.StaffForm.controls.Email.value,
          IsActive: this.StaffForm.controls.IsActive.value,
          StaffType: this.StaffForm.controls.StaffType.value,
          Phone: this.StaffForm.controls.Phone.value,
          EmployeeCategoryId: this.StaffForm.controls.EmployeeCategoryId.value,
          EmployeeTypeId: this.StaffForm.controls.EmployeeTypeId.value,
          recruiterSitesDetail: siteIDarray,
          Id: this.StaffForm.controls.Id.value,
        }
        let Id = this.data.callList ? this.data.callList.Id : 0;
        this.StaffForm.controls.Id.setValue(Id);
        this.staffService.SaveAndEditStaff(Id, Staffobject, this.isedit);
        this.staffService.isCallApi.subscribe(res => {
          if (res) {
            this.onNoClick();
            this.staffService.isCallApi.next(true);
          }
        });
      }
      catch (ex) {
        this._Global.showErrorMsg(ex)
      }
    }
  }
}
