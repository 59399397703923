import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-toaster',
  templateUrl: './notification-toaster.component.html',
  styleUrls: ['./notification-toaster.component.scss']
})
export class NotificationToasterComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav!: MatSidenav;
  subs = new Subscription();
  isOpen = false;
  data: any;
  notificationType = '';
  notificationAudioFlag = true;
  entityObj = [
    { key: 'site_visit_list', url: 'screenercalllist' },
    { key: 'prescreen_list', url: 'prescreenercalllist' },
    { key: 'recruiter_list', url: 'recruitercalllist/recruiter' },
    { key: 'patient_profiles', url: '' },
    { key: 'communication_draft', url: 'communication-draft-screen' },
    { key: 'communication_pending', url: 'communication-pendingreview-screen' },
    { key: 'script_draft', url: 'script-draft-screen' },
    { key: 'script_pending', url: 'script-pending-review-screen' },
    { key: 'prescreen_draft', url: 'prescreen-draft-screen' },
    { key: 'prescreen_pending', url: 'prescreen-pending-review-screen' },
    { key: 'phone_distro', url: 'phonescreen', type: 'distro' },
    { key: 'text_distro', url: 'textscreen', type: 'distro' },
    { key: 'email_distro', url: 'emailscreen', type: 'distro' },
    { key: 'phone_individual', url: 'phonescreen', type: 'individual' },
    { key: 'text_individual', url: 'textscreen', type: 'individual' },
    { key: 'email_individual', url: 'emailscreen', type: 'individual' },
    { key: 'chat_list', url: 'chatscreen' },
];

  constructor(private _RecruiterService: RecruiterService, private router: Router,
    private NotificationsService: NotitificationsService, public PrescreenList: PrescreenService,
    private socketService: SocketHandlerService) { }

  ngOnInit() {
    const notificationAudioFlagSub = this.NotificationsService.notificationAudioFlag.subscribe(res => this.notificationAudioFlag = res);
    this.subs.add(notificationAudioFlagSub);

    const notificationDataSub = this._RecruiterService.notificationRowData.subscribe(res => {
      this.data = res;
    });
    this.subs.add(notificationDataSub);

    const notificationTypeSub = this._RecruiterService.notificationType.subscribe(res => {
      this.notificationType = res;
    });
    this.subs.add(notificationTypeSub);

    const contactCenterFlagSub = this._RecruiterService.notificationToasterFlag.subscribe(res => {
      this.openCloseSidebar(res);
    });
    this.subs.add(contactCenterFlagSub);
  }

  openCloseSidebar(flag: boolean): void {
    if (flag) {
      this.isOpen = true;
      
      if (this.notificationAudioFlag) {
        const audio = new Audio('../../../assets/sounds/notification.wav');
        audio.load();
        audio.play();
      }
      this.sidenav.open();

      setTimeout(() => {
        this.isOpen = false;
        setTimeout(() => {
          this._RecruiterService.notificationToasterFlag.next(false);
          this._RecruiterService.notificationRowData.next('');
          this.sidenav.close();
        }, 300);
      }, 5000);
    }
  }

  navigateToRespectiveScreen(): void {
    if (this.notificationType === 'memo') {
      this.NotificationsService.memoIdData.next(this.data);
      this.router.navigate(['./all-memos-screen'], { queryParams: { Id: this.data._id } });
    } else if (this.notificationType === 'automated-notification') {
      this.router.navigate(['./automated-notifications-screen'], { queryParams: { Id: this.data._id } });
    } else if (this.data.type === 'email') {
      this.router.navigate(['./emailscreen'], { queryParams: { Id: this.data._id } });
    } else if (this.data.type === 'sms') {
      this.router.navigate(['./textscreen'], { queryParams: { Id: this.data._id } });
    } else if (this.notificationType === 'chat') {
      const filteredObj = this.entityObj.find(data => data.key === this.data.entity_name);
      if (filteredObj) {
        this.PrescreenList.selectedNotificationType.next(this.data.entity_name.includes(filteredObj.type) ? filteredObj.type : '');
        this.socketService.emitChatJoinRoomEvent(this.data.entity_name, this.data.entity_id);
        localStorage.setItem('entity_name', this.data.entity_name);
        localStorage.setItem('entity_title', this.data.entity_title);
        localStorage.setItem('entity_id', this.data.entity_id);
        this.PrescreenList.openChatBox.next(true);
        this.router.navigate(['./' + filteredObj.url], { queryParams: { Id: this.data._id } });
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
