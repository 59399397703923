import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';  // Import FormControl
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';

@Component({
  selector: 'app-addscript',
  templateUrl: './addscript.component.html',
  styleUrls: ['./addscript.component.scss']
})
export class AddscriptComponent implements OnInit {
  public myData: any = [];
  @Output() onSubmitAction: EventEmitter<{ value: string, id: string }> = new EventEmitter<{ value: string, id: string }>();
  nameFc = new FormControl('');
  ActiveId: string;
  showError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddscriptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ScriptList: TemplateService,
    private _Global: GlobalfunctionService
  ) { }

  ngOnInit() {
    this.myData = this.data.rowData;
    this.nameFc.setValue(this.data.obj.name);
    this.ActiveId = this.data.obj._id;
  }

  onNoClick(flag?: any): void {
    this.dialogRef.close(flag);
  }

  handleInput(): void {
    this.showError = false;
  }

  onSubmit(): void {
    if (this.nameFc.value) {
      this.onSubmitAction.emit({ value: this.nameFc.value, id: this.ActiveId });
      this.onNoClick('yes');
    } else {
      this.showError = true;
    }
  }
}
