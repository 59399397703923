import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListMaster } from '@sharedmodels/BackModels/ComTrak/DBLM/DBLM';
import { DBLMService } from '@sharedservices/BackServices/ComTrak/DBLM/dblm.service';

@Component({
  selector: 'app-createlist',
  templateUrl: './createlist.component.html',
  styleUrls: ['./createlist.component.scss', '../../style.component.scss']
})
export class CreatelistComponent implements OnInit {
  @Input() listMasterObject: ListMaster
  @Input() isDisabledAllFields: boolean = false
  DBListForm: FormGroup
  submitted: boolean = false
  disablebtn: boolean;
  constructor(private fb: FormBuilder, public dblmService: DBLMService) { }

  ngOnInit() {
    console.log("this.listMasterObject", this.listMasterObject)
    this.initForm()
    if (this.listMasterObject) {
      this.DBListForm.patchValue(this.listMasterObject)
    }
    if (this.isDisabledAllFields) {
      this.disablebtn = true
      Object.keys(this.DBListForm.controls).forEach(element => {
        this.DBListForm.controls[element].disable()
      })
    }
    else {
      this.disablebtn = false
      Object.keys(this.DBListForm.controls).forEach(element => {
        this.DBListForm.controls[element].enable()
      })
    }
  }

  initForm() {
    this.DBListForm = this.fb.group({
      Id: [0],
      Name: ['', Validators.required],
      TherapeuticArea: [''],
      Description: [''],
      IsActive: [false],
      IsImport: [false]
    })
  }

  get f() {
    return this.DBListForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.DBListForm.invalid) {
      return;
    }
    else {
      var isEdit = this.listMasterObject ? true : false
      this.dblmService.SaveAndEditListMaster(isEdit, this.DBListForm.value)
      this.dblmService.IsSaveListMaster.subscribe(res => {
        if (res) {
          this.disablebtn = true
        }
      })
    }
  }

}
