import { Injectable } from '@angular/core';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { HttpClient } from '@angular/common/http';
import { Routes } from '@sharedmodels/BackModels/ComTrak/Security/Routes';
import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { LeadService } from '../Leads/lead.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  public _routes: Routes[];
  public isRecruiters: boolean = false
  public userRoles: string[] = []
  public userObject: any;

  public routes:any =    [    
          {
            "routesID": 6,
            "path": "/Utilities",
            "title": "Utilities",
            "type": "sub",
            "icontype": "settings",
            "collapse": "Utilities",
            "Precedence": 9,
            "pid": null,
            "isExternalLink": false,
            "url": null
        },
        {
          "routesID": 142,
          "path": "/therapeuticarea",
          "title": "Manage Therapeutic Area",
          "type": "F",
          "icontype": "domain",
          "collapse": "Therapeutic Area",
          "Precedence": 2,
          "pid": 6,
          "isExternalLink": false,
          "url": null
      },
      {
        "routesID": 45,
        "path": "/sitecalendarlist",
        "title": "Manage Site Calendar",
        "type": "F",
        "icontype": "domain",
        "collapse": "Manage Site Calendar",
        "Precedence": 3,
        "pid": 6,
        "isExternalLink": false,
        "url": null
    },
    {
      "routesID": 46,
      "path": "/studieslist",
      "title": "Manage Studies",
      "type": "F",
      "icontype": "menu_book",
      "collapse": "Manage Studies",
      "Precedence": 4,
      "pid": 6,
      "isExternalLink": false,
      "url": null
    },
    {
      "routesID": 48,
      "path": "/scriptcategory",
      "title": "Manage Script Category",
      "type": "F",
      "icontype": "menu_book",
      "collapse": "Manage Studies",
      "Precedence": 4,
      "pid": 6,
      "isExternalLink": false,
      "url": null
    },
    {
      "routesID": 55,
      "path": "/manage-ivr-phone",
      "title": "Manage Phone and IVR",
      "type": "F",
      "icontype": "menu_book",
      "collapse": "Manage Studies",
      "Precedence": 4,
      "pid": 6,
      "isExternalLink": false,
      "url": null
    },
    {
      "routesID": 59,
      "path": "/manage-call-metrics",
      "title": "Manage Call Metrics",
      "type": "F",
      "icontype": "menu_book",
      "collapse": "Manage Studies",
      "Precedence": 4,
      "pid": 6,
      "isExternalLink": false,
      "url": null
    },
    {
      "routesID": 49,
      "path": "/department",
      "title": "Manage Department",
      "type": "F",
      "icontype": "menu_book",
      "collapse": "Manage Studies",
      "Precedence": 4,
      "pid": 6,
      "isExternalLink": false,
      "url": null
    },
    {
      "routesID": 50,
      "path": "/prescreencategory",
      "title": "Manage Prescreen Category",
      "type": "F",
      "icontype": "menu_book",
      "collapse": "Manage Studies",
      "Precedence": 4,
      "pid": 6,
      "isExternalLink": false,
      "url": null
    },
    
    {
    "routesID": 126,
    "path": "/Communication",
    "title": "Communication",
    "type": "sub",
    "icontype": "person_add",
    "collapse": "Communication",
    "Precedence": 8,
    "pid": null,
    "isExternalLink": false,
    "url": null
}, 
{
  "routesID": 111,
  "path": "/Communication",
  "title": "Mass Communication",
  "type": "grandchild",
  "icontype": "person_add",
  "collapse": "Mass Communication",
  "Precedence": 8,
  "pid": 126,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 112,
  "path": "/email-accounts",
  "title": "Email Accounts",
  "type": "F",
  "icontype": "person_add",
  "collapse": "",
  "Precedence": 8,
  "pid": 126,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 177,
  "path": "/Communication/scriptlibrary",
  "title": "Script Library",
  "type": "grandchild",
  "icontype": "person_add",
  "collapse": "",
  "Precedence": 8,
  "pid": 126,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 188,
  "path": "/Communication/prescreenbuilder",
  "title": "Prescreen Form",
  "type": "grandchild",
  "icontype": "person_add",
  "collapse": "",
  "Precedence": 8,
  "pid": 126,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 137,
  "path": "/prescreen-create-new",
  "title": "create New",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":188,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 139,
  "path": "/prescreen-draft-screen",
  "title": "Drafts",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":188,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 140,
  "path": "/prescreen-pending-review-screen",
  "title": "Pending Review",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":188,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 141,
  "path": "/prescreen-published-screen",
  "title": "Published",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":188,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 138,
  "path": "/prescreen-discarded-screen",
  "title": "Discarded",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":188,
  "isExternalLink": false,
  "url": null
},
// { 
//     "routesID": 128,
//     "path": "/incomingmessageslist",
//     "title": "Incoming Messages List",
//     "type": "F",
//     "icontype": "person_add",
//     "collapse": "Incoming Messages List",
//     "Precedence": 8,
//     "pid": 111,
//     "isExternalLink": false,
//     "url": null
// }, 
// {
//     "routesID": 129, 
//     "path": "/smsresponselist", 
//     "title": "SMS Response List", 
//     "type": "F",  
//     "icontype": "person_add", 
//     "collapse": "SMS Response List", 
//     "Precedence": 8, 
//     "pid": 111, 
//     "isExternalLink": false,
//     "url": null  
// },  
// {
//     "routesID": 139,
//     "path": "/outgoingmessagelist",
//     "title": "Outgoing Messages List",
//     "type": "F",
//     "icontype": "person_add",
//     "collapse": "Outgoing Messages List",
//     "Precedence": 8,
//     "pid": 111,
//     "isExternalLink": false,
//     "url": null
// },
{
  "routesID": 131,
  "path": "/communication-create-new",
  "title": "create New",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":111,
  "isExternalLink": false,
  "url": null
},

{
  "routesID": 133,
  "path": "/communication-draft-screen",
  "title": "Drafts",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":111,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 134,
  "path": "/communication-pendingreview-screen",
  "title": "Pending Review",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":111,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 135,
  "path": "/communication-published-screen",
  "title": "Published",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":111,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 136,
  "path": "/communication-incoming-message-responses",
  "title": "Incoming Responses",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":111,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 132,
  "path": "/communication-discarded-screen",
  "title": "Discarded",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid":111,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 130,
  "path": "/create-new-script",
  "title": "create New",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid": 177,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 152,
  "path": "/script-draft-screen",
  "title": "Drafts",
  "type": "F",
  "icontype": "person_add",
  "collapse": "draft",
  "Precedence": 8,
  "pid": 177,
  "isExternalLink": false,
  "url": null
},

{
  "routesID": 153,
  "path": "/script-pending-review-screen",
  "title": "Pending Review",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid": 177,
  "isExternalLink": false,
  "url": null
}, 
{
  "routesID": 154,
  "path": "/script-published-screen",
  "title": "Published",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid": 177,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 155,
  "path": "/script-discarded-screen",
  "title": "Discarded",
  "type": "F",
  "icontype": "person_add",
  "collapse": "createnew",
  "Precedence": 8,
  "pid": 177,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 7,
  "path": "/Notifications",
  "title": "Notifications",
  "type": "sub",
  "icontype": "notification",
  "collapse": "Notifications",
  "Precedence": 10,
  "pid": null,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 13,
  "path": "/automated-notifications-screen",
  "title": "Automated User Notifications",
  "type": "link",
  "icontype": "notification",
  "collapse": "AutomatedUserNotifications",
  "Precedence": 11,
  "pid": null,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 1333,
  "path": "/editor1-screen",
  "title": "Quill editor",
  "type": "link",
  "icontype": "notification",
  "collapse": "AutomatedUserNotifications",
  "Precedence": 11,
  "pid": null,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 1334,
  "path": "/editor2-screen",
  "title": "CK editor",
  "type": "link",
  "icontype": "notification",
  "collapse": "AutomatedUserNotifications",
  "Precedence": 11,
  "pid": null,
  "isExternalLink": false,
  "url": null
},
  {
    "routesID": 14,
    "path": "/emailscreen",
    "title": "Email",
    "type": "F",
    "icontype": "domain",
    "collapse": "Email",
    "Precedence": 2,
    "pid": 7,
    "isExternalLink": false,
    "url": null
  },
  {
    "routesID": 15,
    "path": "/textscreen",
    "title": "Text",
    "type": "F",
    "icontype": "domain",
    "collapse": "Text",
    "Precedence": 3,
    "pid": 7,
    "isExternalLink": false,
    "url": null
},
{
  "routesID": 16,
  "path": "/phonescreen",
  "title": "Phone",
  "type": "F",
  "icontype": "menu_book",
  "collapse": "Phone",
  "Precedence": 4,
  "pid": 7,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 18,
  "path": "/chatscreen",
  "title": "Chat",
  "type": "F",
  "icontype": "menu_book",
  "collapse": "Chat",
  "Precedence": 4,
  "pid": 7,
  "isExternalLink": false,
  "url": null
},
{
  "routesID": 19,
  "path": "/all-memos-screen",
  "title": "All Memos",
  "type": "F",
  "icontype": "menu_book",
  "collapse": "Chat",
  "Precedence": 4,
  "pid": 7,
  "isExternalLink": false,
  "url": null
},
]

  //Not a proper way to check updated data but to make minimum changes this is done
  public routeSubject: Subject<boolean> = new Subject();

  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private spinner: NgxSpinnerService, public leadService: LeadService) {
    // this.leadService.GetLeadsValidationList("") 
    this._Global.isRecruiters.subscribe(res => {
      // if (res) {
      //   this.isRecruiters = true
      //   this.LoadStaticRoutes()
      // }
      // else {
      //   this.isRecruiters = false
      //   this.LoadStaticRoutes()
      // }
    })
  }

  LoadStaticRoutes() {
    try {
      this.userObject = JSON.parse(localStorage.getItem("data"))
      this.userRoles = this.userObject.role
      //this.userRoles= ["SiteAdmins", "Managers"];

      if (this.userRoles.some(o => o == "Recruiters")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
        ];

      }
      if (this.userRoles.some(o => o == "Screeners")) {
        this._routes = [{
          routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
          pid: null,
        },
        {
          routesID: 31,
          title: 'Site Visit List',
          path: '/screenercalllist',
          icontype: 'call',
          type: 'C',
          collapse: null,
          isExternalLink: false,
          pid: 3,
        },
        {
          routesID: 32,
          title: 'Completed Visit List',
          path: '/Visitcompletedcalllist',
          icontype: 'call',
          type: 'C',
          collapse: null,
          isExternalLink: false,
          pid: 3,
        }];

      }
      if (this.userRoles.some(o => o == "PhoneScreeners")) {

        this._routes = [
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          }];

      }
      if (this.userRoles.some(o => o == "Managers")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 22,
            title: ' Recruiter Admin List',
            path: '/recruitercalllist/admincalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 23,
            title: 'Assign Patient-Recruiter ',
            path: '/assignpatientstorecruiter',
            icontype: 'person_search',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 72,
            title: 'Recruiter Report',
            path: '/recruitmentpsreports/RecruiterReport/Recruiter Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 25,
            title: 'Campaign Report',
            path: '/recruitmentpsreports/campaignreport/Campaign Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 4, title: 'Patient Visit Tracker', path: '/Patient Visit Tracker', icontype: 'settings', type: 'sub', collapse: 'Patient Visit Tracker', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 41,
            title: 'InClinic',
            path: '/visitorflowlist/3/Visitor Flow (In Clinic)',
            icontype: 'moving',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          {
            routesID: 42,
            title: 'Phone Call Visits',
            path: '/visitorflowlist/5/Visitor Flow (Phone Call)',
            icontype: 'moving',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          {
            routesID: 5, title: 'Reports-Patient Enrollment', path: '/Reports-Patient Enrollment', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 51,
            title: 'Recruiter Report',
            path: '/recruitmentpsreports/RecruiterReport/Recruiter Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/3/Randomized by Campaign and Referral',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral Pie',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Randomized by Campaign and Referral Pie',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },

          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 53,
            title: 'Recruiter Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/2/Recruiter Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 54,
            title: 'Recruiter Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/4/Recruiter Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 56,
            title: 'Pre screen Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/3/Pre-Screen Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 57,
            title: 'Pre screen Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/5/Pre-Screen Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 6, title: 'Reports-Site Operations', path: '/Reports-Site Operations', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 61,
            title: 'Visit Tracking Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Visit Tracking Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 62,
            title: 'Patient Journey Report',
            path: '/powerbi/fc27e562-e93b-42c4-9ab6-387579cb177a/false/0/Patient Journey Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 63,
            title: 'Missed Visits Report',
            path: '/operationreports/missedvisit/Missed Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 64,
            title: 'Comp OOW Visits Report',
            path: '/operationreports/compvisit/Completed Out Of Window Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 65,
            title: 'Future OOW Visits Report',
            path: '/operationreports/futurevisit/Future OOW Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 66,
            title: 'Visit Not Scheduled Report',
            path: '/operationreports/visitnotscheduled/Visit Not Scheduled Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 7, title: 'Reports-Marketing', path: '/Reports-Marketing', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Marketing', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 71,
            title: 'Referral Source Detail Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/0/Referral Source Detail Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 72,
            title: 'Campaign & Referral Source Detail',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/2/Campaign & Referral Source Detail',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 8, title: 'Utilities', path: '/Utilities', icontype: 'settings', type: 'sub', collapse: 'Utilities', isExternalLink: false,
            pid: null,
          }, {
            routesID: 81,
            title: 'Import Patients',
            path: '/importpatients',
            icontype: 'import_export',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 82,
            title: 'Manage Sites',
            path: '/siteslist',
            icontype: 'domain',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 83,
            title: 'Manage Site Calendar',
            path: '/sitecalendarlist',
            icontype: 'domain',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 84,
            title: 'Manage Studies',
            path: '/studieslist',
            icontype: 'menu_book',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 85,
            title: 'Manage Staff',
            path: '/stafflist',
            icontype: 'settings_accessibility',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 86,
            title: 'Manage User Role',
            path: '/userrolelist',
            icontype: 'person_add',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 87,
            title: 'Manage Campaign',
            path: '/campaignlist',
            icontype: 'campaign',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
        ];
      }
      if (this.userRoles.includes(("Recruiters")) && this.userRoles.includes(("PhoneScreeners"))) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
        ];

      }
      if (this.userRoles.includes(("Screeners")) && this.userRoles.includes(("PhoneScreeners"))) {
        this._routes = [{
          routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
          pid: null,
        },
        {
          routesID: 24,
          title: 'Prescreener List',
          path: '/prescreenercalllist',
          icontype: 'call',
          type: 'C',
          collapse: null,
          isExternalLink: false,
          pid: 2,
        },
        {
          routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
          pid: null,
        },
        {
          routesID: 31,
          title: 'Site Visit List',
          path: '/screenercalllist',
          icontype: 'call',
          type: 'C',
          collapse: null,
          isExternalLink: false,
          pid: 3,
        },
        {
          routesID: 32,
          title: 'Completed Visit List',
          path: '/Visitcompletedcalllist',
          icontype: 'call',
          type: 'C',
          collapse: null,
          isExternalLink: false,
          pid: 3,
        },
        ];

      }
      if (this.userRoles.includes("Screeners") && this.userRoles.includes("Recruiters")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          }];

      }
      if (this.userRoles.includes(("Recruiters")) && this.userRoles.includes(("Screeners")) && this.userRoles.includes(("PhoneScreeners"))) {

        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
        ];
      }
      if (this.userRoles.some(o => o == "RecruiterManagers")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
        ];
      }
      if (this.userRoles.some(o => o == "RecruiterManagers") && this.userRoles.some(o => o == "Managers")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 72,
            title: 'Recruiter Report',
            path: '/recruitmentpsreports/RecruiterReport/Recruiter Report',
            icontype: 'summarize',
            type: 'F',
            pid: 2,
          },
          {
            routesID: 23,
            title: 'Assign Patient-Recruiter ',
            path: '/assignpatientstorecruiter',
            icontype: 'person_search',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          }, {
            routesID: 22,
            title: ' Recruiter Admin List',
            path: '/recruitercalllist/admincalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          }, {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          }, ,
          {
            routesID: 25,
            title: 'Campaign Report',
            path: '/recruitmentpsreports/campaignreport/Campaign Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          }, {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 6, title: 'Reports-Site Operations', path: '/Reports-Site Operations', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 61,
            title: 'Visit Tracking Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Visit Tracking Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          ,
          {
            routesID: 62,
            title: 'Patient Journey Report',
            path: '/powerbi/fc27e562-e93b-42c4-9ab6-387579cb177a/false/0/Patient Journey Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 7, title: 'Reports-Marketing', path: '/Reports-Marketing', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Marketing', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 71,
            title: 'Referral Source Detail Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/0/Referral Source Detail Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 72,
            title: 'Campaign & Referral Source Detail',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/2/Campaign & Referral Source Detail',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/3/Randomized by Campaign and Referral',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral Pie',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Randomized by Campaign and Referral Pie',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 53,
            title: 'Recruiter Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/2/Recruiter Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 54,
            title: 'Recruiter Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/4/Recruiter Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 56,
            title: 'Pre screen Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/3/Pre-Screen Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 57,
            title: 'Pre screen Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/5/Pre-Screen Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },


          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 6, title: 'Reports-Site Operations', path: '/Reports-Site Operations', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Site Operations', isExternalLink: false,
            pid: null,
          }, {
            routesID: 63,
            title: 'Missed Visits Report',
            path: '/operationreports/missedvisit/Missed Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 60,
            title: 'Patient Study Visits Report',
            path: '/operationreports/patientstudyvisit/Patient Study Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          ,
          {
            routesID: 64,
            title: 'Comp OOW Visits Report',
            path: '/operationreports/compvisit/Completed Out Of Window Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 65,
            title: 'Future OOW Visits Report',
            path: '/operationreports/futurevisit/Future OOW Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 66,
            title: 'Visit Not Scheduled Report',
            path: '/operationreports/visitnotscheduled/Visit Not Scheduled Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },

          {
            routesID: 67,
            title: 'Patient Pre-Screen Report',
            path: '/recruitmentpsreports/patientprescreenreport/Patient Pre-Screen Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },

        ];

      }
      if (this.userRoles.some(o => o == "SiteAdmins")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 22,
            title: ' Recruiter Admin List',
            path: '/recruitercalllist/admincalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 23,
            title: 'Assign Patient-Recruiter ',
            path: '/assignpatientstorecruiter',
            icontype: 'person_search',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 25,
            title: 'Campaign Report',
            path: '/recruitmentpsreports/campaignreport/Campaign Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          ,
          {
            routesID: 6, title: 'Reports-Site Operations', path: '/Reports-Site Operations', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 61,
            title: 'Visit Tracking Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Visit Tracking Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 62,
            title: 'Patient Journey Report',
            path: '/powerbi/fc27e562-e93b-42c4-9ab6-387579cb177a/false/0/Patient Journey Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 7, title: 'Reports-Marketing', path: '/Reports-Marketing', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Marketing', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 71,
            title: 'Referral Source Detail Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/0/Referral Source Detail Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 72,
            title: 'Campaign & Referral Source Detail',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/2/Campaign & Referral Source Detail',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          ,
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/3/Randomized by Campaign and Referral',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral Pie',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Randomized by Campaign and Referral Pie',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 53,
            title: 'Recruiter Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/2/Recruiter Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 54,
            title: 'Recruiter Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/4/Recruiter Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          ,
          {
            routesID: 56,
            title: 'Pre screen Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/3/Pre-Screen Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },

          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 57,
            title: 'Pre screen Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/5/Pre-Screen Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          }, {
            routesID: 60,
            title: 'Patient Study Visits Report',
            path: '/operationreports/patientstudyvisit/Patient Study Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          }, ,
          {
            routesID: 63,
            title: 'Missed Visits Report',
            path: '/operationreports/missedvisit/Missed Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 64,
            title: 'Comp OOW Visits Report',
            path: '/operationreports/compvisit/Completed Out Of Window Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 65,
            title: 'Future OOW Visits Report',
            path: '/operationreports/futurevisit/Future OOW Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 66,
            title: 'Visit Not Scheduled Report',
            path: '/operationreports/visitnotscheduled/Visit Not Scheduled Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 67,
            title: 'Patient Pre-Screen Report',
            path: '/recruitmentpsreports/patientprescreenreport/Patient Pre-Screen Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
        ];

      }
      if (this.userRoles.some(o => o == "SiteAdmins") || this.userRoles.some(o => o == "Managers")) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 22,
            title: ' Recruiter Admin List',
            path: '/recruitercalllist/admincalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 23,
            title: 'Assign Patient-Recruiter ',
            path: '/assignpatientstorecruiter',
            icontype: 'person_search',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          }
          ,
          {
            routesID: 25,
            title: 'Campaign Report',
            path: '/recruitmentpsreports/campaignreport/Campaign Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'auto_stories', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          }, {
            routesID: 5, title: 'Reports-Patient Enrollment', path: '/Reports-Patient Enrollment', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 51,
            title: 'Recruiter Report',
            path: '/recruitmentpsreports/RecruiterReport/Recruiter Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },

          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/3/Randomized by Campaign and Referral',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral Pie',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Randomized by Campaign and Referral Pie',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 53,
            title: 'Recruiter Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/2/Recruiter Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 54,
            title: 'Recruiter Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/4/Recruiter Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 56,
            title: 'Pre screen Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/3/Pre-Screen Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 57,
            title: 'Pre screen Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/5/Pre-Screen Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 6, title: 'Reports-Site Operations', path: '/Reports-Site Operations', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 61,
            title: 'Visit Tracking Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Visit Tracking Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 62,
            title: 'Patient Journey Report',
            path: '/powerbi/fc27e562-e93b-42c4-9ab6-387579cb177a/false/0/Patient Journey Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 63,
            title: 'Missed Visits Report',
            path: '/operationreports/missedvisit/Missed Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 64,
            title: 'Comp OOW Visits Report',
            path: '/operationreports/compvisit/Completed Out Of Window Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 65,
            title: 'Future OOW Visits Report',
            path: '/operationreports/futurevisit/Future OOW Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 66,
            title: 'Visit Not Scheduled Report',
            path: '/operationreports/visitnotscheduled/Visit Not Scheduled Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 7, title: 'Reports-Marketing', path: '/Reports-Marketing', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Marketing', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 71,
            title: 'Referral Source Detail Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/0/Referral Source Detail Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 72,
            title: 'Campaign & Referral Source Detail',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/2/Campaign & Referral Source Detail',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          }
          ,
          {
            routesID: 4, title: 'Patient Visit Tracker', path: '/Patient Visit Tracker', icontype: 'settings', type: 'sub', collapse: 'Patient Visit Tracker', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 41,
            title: 'InClinic',
            path: '/visitorflowlist/3/Visitor Flow (In Clinic)',
            icontype: 'moving',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          {
            routesID: 42,
            title: 'Phone Call Visits',
            path: '/visitorflowlist/5/Visitor Flow (Phone Call)',
            icontype: 'moving',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          ,
          {
            routesID: 8, title: 'Utilities', path: '/Utilities', icontype: 'settings', type: 'sub', collapse: 'Utilities', isExternalLink: false,
            pid: null,
          }, {
            routesID: 81,
            title: 'Import Patients',
            path: '/importpatients',
            icontype: 'import_export',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 82,
            title: 'Manage Sites',
            path: '/siteslist',
            icontype: 'domain',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 83,
            title: 'Manage Site Calendar',
            path: '/sitecalendarlist',
            icontype: 'domain',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 84,
            title: 'Manage Studies',
            path: '/studieslist',
            icontype: 'menu_book',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 85,
            title: 'Manage Staff',
            path: '/stafflist',
            icontype: 'settings_accessibility',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 86,
            title: 'Manage User Role',
            path: '/userrolelist',
            icontype: 'person_add',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 87,
            title: 'Manage Campaign',
            path: '/campaignlist',
            icontype: 'campaign',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
        ];

      }
      if (this.userRoles.includes(("SuperAdmins")) || this.userRoles.includes(("UserAdmins"))) {
        this._routes = [
          {
            routesID: 1, title: 'Patient List', path: '/Patient List', icontype: 'person_search', type: 'sub', collapse: 'Patient List', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 11,
            title: 'Create Patient',
            path: '/patientslist',
            icontype: 'person_add',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 1,
          },
          {
            routesID: 2, title: 'Patient Enrollment', path: '/Patient Enrollment', icontype: 'person_search', type: 'sub', collapse: 'Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 21,
            title: ' Recruiter List',
            path: '/recruitercalllist/recruiter',
            icontype: 'contact_phone',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 22,
            title: ' Recruiter Admin List',
            path: '/recruitercalllist/admincalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 23,
            title: 'Assign Patient-Recruiter ',
            path: '/assignpatientstorecruiter',
            icontype: 'person_search',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 24,
            title: 'Prescreener List',
            path: '/prescreenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 25,
            title: 'Campaign Report',
            path: '/recruitmentpsreports/campaignreport/Campaign Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 2,
          },
          {
            routesID: 3, title: 'Site Operations', path: '/Site Operations', icontype: 'manage_accounts', type: 'sub', collapse: 'Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 31,
            title: 'Site Visit List',
            path: '/screenercalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 32,
            title: 'Completed Visit List',
            path: '/Visitcompletedcalllist',
            icontype: 'call',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 3,
          },
          {
            routesID: 4, title: 'Patient Visit Tracker', path: '/Patient Visit Tracker', icontype: 'settings', type: 'sub', collapse: 'Patient Visit Tracker', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 41,
            title: 'InClinic',
            path: '/visitorflowlist/3/Visitor Flow (In Clinic)',
            icontype: 'moving',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          {
            routesID: 42,
            title: 'Phone Call Visits',
            path: '/visitorflowlist/5/Visitor Flow (Phone Call)',
            icontype: 'moving',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 4,
          },
          {
            routesID: 5, title: 'Reports-Patient Enrollment', path: '/Reports-Patient Enrollment', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Patient Enrollment', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 52,
            title: 'Most Recent Int. Report',
            path: '/recruitmentpsreports/mostrecentinteractionsreport/Most Recent Int. Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 58,
            title: 'Booking by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/0/Booking by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 581,
            title: 'All Interactions by Recruiters',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/1/All Interactions by Recruiters',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 582,
            title: 'Recruiter scheduling by schedule date',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/2/Recruiter scheduling by schedule date',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 583,
            title: 'Outcome of scheduled visit',
            path: '/powerbi/01b6b651-81ba-4ba3-b051-c6f2e372cffe/false/3/Outcome of scheduled visit',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 51,
            title: 'Recruiter Report',
            path: '/recruitmentpsreports/RecruiterReport/Recruiter Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },

          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/3/Randomized by Campaign and Referral',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 46,
            title: 'Randomized by Campaign and Referral Pie',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Randomized by Campaign and Referral Pie',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 53,
            title: 'Recruiter Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/2/Recruiter Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 54,
            title: 'Recruiter Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/4/Recruiter Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 56,
            title: 'Pre screen Dashboard',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/3/Pre-Screen Dashboard',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 57,
            title: 'Pre screen Productivity',
            path: '/powerbi/c08c0519-8ead-42ad-9253-96c560b902b4/false/5/Pre-Screen Productivity',
            icontype: 'dashboard_customize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 5,
          },
          {
            routesID: 6, title: 'Reports-Site Operations', path: '/Reports-Site Operations', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Site Operations', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 61,
            title: 'Visit Tracking Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/4/Visit Tracking Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 62,
            title: 'Patient Journey Report',
            path: '/powerbi/fc27e562-e93b-42c4-9ab6-387579cb177a/false/0/Patient Journey Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 63,
            title: 'Missed Visits Report',
            path: '/operationreports/missedvisit/Missed Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 64,
            title: 'Comp OOW Visits Report',
            path: '/operationreports/compvisit/Completed Out Of Window Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 65,
            title: 'Future OOW Visits Report',
            path: '/operationreports/futurevisit/Future OOW Visits Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 66,
            title: 'Visit Not Scheduled Report',
            path: '/operationreports/visitnotscheduled/Visit Not Scheduled Report',
            icontype: 'summarize',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 6,
          },
          {
            routesID: 7, title: 'Reports-Marketing', path: '/Reports-Marketing', icontype: 'dashboard', type: 'sub', collapse: 'Reports-Marketing', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 71,
            title: 'Referral Source Detail Report',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/0/Referral Source Detail Report',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 72,
            title: 'Campaign & Referral Source Detail',
            path: '/powerbi/4bd2595b-aaf3-47a3-aba4-8a5d5c283dd3/false/2/Campaign & Referral Source Detail',
            icontype: 'summarize',
            type: 'C',
            collapse: null,
            isExternalLink: false,
            pid: 7,
          },
          {
            routesID: 9,
            title: 'Leads',
            path: '/Leads',
            icontype: 'groups',
            type: 'sub',
            collapse: 'Leads',
            isExternalLink: false,
            pid: null,
          },
          {
            routesID: 8, title: 'Utilities', path: '/Utilities', icontype: 'settings', type: 'sub', collapse: 'Utilities', isExternalLink: false,
            pid: null,
          },
          {
            routesID: 81,
            title: 'Import Patients',
            path: '/importpatients',
            icontype: 'import_export',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 82,
            title: 'Manage Sites',
            path: '/siteslist',
            icontype: 'domain',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 83,
            title: 'Manage Site Calendar',
            path: '/sitecalendarlist',
            icontype: 'domain',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 84,
            title: 'Manage Studies',
            path: '/studieslist',
            icontype: 'menu_book',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 85,
            title: 'Manage Staff',
            path: '/stafflist',
            icontype: 'settings_accessibility',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 86,
            title: 'Manage User Role',
            path: '/userrolelist',
            icontype: 'person_add',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },
          {
            routesID: 87,
            title: 'Manage Campaign',
            path: '/campaignlist',
            icontype: 'campaign',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 8,
          },


          {
            routesID: 88,
            title: 'Leads List',
            path: '/leadslist',
            icontype: 'people',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 9,
          },
          {
            routesID: 89,
            title: 'Leads Validation',
            path: '/leadsvalidation',
            icontype: 'how_to_reg',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 9,
            showBadge: true,
            badgeValue: this.leadService._leadValidationList && this.leadService._leadValidationList.length ? this.leadService._leadValidationList.length : 0
          },
          {
            routesID: 90,
            title: 'Admin Leads List',
            path: '/adminleadlist',
            icontype: 'admin_panel_settings',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 9,
          },
          {
            routesID: 90,
            title: 'Discard Leads',
            path: '/discardleads',
            icontype: 'person_remove',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 9,
          },
          {
            routesID: 91,
            title: 'Leads Report',
            path: '/leadsreport',
            icontype: 'content_paste',
            type: 'F',
            collapse: null,
            isExternalLink: false,
            pid: 9,
          },
        ];

      }
      // console.log(' getting routes');
      // this._http.get(myGlobals._apipath + 'Routes',
      //   { headers: this._Global.httpOptions }
      // )
      //   .subscribe(data => {
      //     this._Global.IsReady = true;
      //     this._routes = <Routes[]>data;
      //   },
      //     error => {
      //       this._Global.handleError(error);
      //     });
    } catch (ex) {
      // alert(' getting routes err=' + ex);
      this._Global.IsReady = true;

    }
  }
  GetAll(): any {
    try {
      if (this._Global.loginrecruiterid == undefined || this._Global.loginrecruiterid.toString() == "-1") {
        throw new Error("Please contact your system administrator for access.");
      }

      if (this._routes !== undefined && this._routes !== null && this._routes.length > 0) {
        return this._routes;
      } else {
        //this.LoadStaticRoutes();
        this.LoadRoutes();
      }
    } catch (x) {
      throw x;
    }
  }
  
  LoadRoutes() {
    this.userObject = JSON.parse(localStorage.getItem("data"))
    let userEmail = this.userObject && this.userObject.user.email
    this._http.get(myGlobals._apiPath + 'routes', { headers: this._Global.httpOptions }).subscribe(data => {
      // this._routes = this.routes
      this._routes = <Routes[]>data;
      localStorage.setItem('_routes', JSON.stringify(this._routes));
      this.routeSubject.next(true);
    },
      error => {

        this._Global.handleError(error);
        this.spinner.hide();
      });

  }

}
