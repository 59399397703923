import { Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DeclinependingreviewComponent } from '../declinependingreview/declinependingreview.component';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CreatememoComponent } from '../creatememo/creatememo.component';
import { MatStepper } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';

@Component({
  selector: 'app-createautomatednotification',
  templateUrl: './createautomatednotification.component.html',
  styleUrls: ['./createautomatednotification.component.scss']
})
export class CreateAutomatedNotificationComponent implements OnInit {

  subs = new Subscription();
  submitDraft = false;
  editData = null;

  // step 1 
  notificationForm: FormGroup;
  tableColumnListData:any = [];
  tableList:any = [];
  actionList:any = [
    {title: 'New Row', name: 'new_row'},
    {title: 'Update Row', name: 'update_row'},
    {title: 'Delete Row', name: 'delete_row'},
    {title: 'Date Match', name: 'date_match'}
  ];
  columnObserveList:any = [];
  selectedColumnsItems: any[] = [];
  userTypeList:any = [];
  columnList:any[] = [
    {title: 'Recruiter ID Column', name: 'recruiter_id'},
    {title: 'Recruiter Connected to Patient', name: 'recruiter_connected_to_patient'}
  ];
  columnIDList:any[] = [];
  submittedStep1 = false;
  
  // step 2 

  notificationContentForm: FormGroup;
  editorContent = null;
  variableSearch = new FormControl();
  filteredItems: any[] = [];
  cursorPosition = 0;


  @ViewChild('multiselectelement') multiselectelement: MultiSelectComponent;
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('textarea') textarea: ElementRef;

  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000,
    positionClass: 'toast-top-right'
  };

  constructor(
    public dialogRef: MatDialogRef<CreatememoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Global: GlobalfunctionService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastService: ToastrService,
    private cd: ChangeDetectorRef,
    private notitificationsService: NotitificationsService,
  ) {
    if (this.data) {
      this.editData = this.data.rowData;
      this.createForm(this.data.rowData)
    } else {
      this.createForm();
    }
  }

  createForm(obj?: any): void {

    this.notificationForm = this.fb.group({
      table: [!!obj && obj.table_name || '', Validators.required],
      action: [!!obj && obj.table_action || '', Validators.required],
      columnObserve: [''],
      userType: [!!obj && obj.user_type || '', Validators.required],
      column: [!!obj && obj.recruiter_type || ''],
      columnID: [!!obj && obj.recruiter_column || '']
    });

    this.notificationContentForm = this.fb.group({
      title: [!!obj && obj.title || '', Validators.required],
      text: [!!obj && obj.content || '', Validators.required]
    });
  }

  ngOnInit() {

    this.getTableData();
    this.getUserData();

    this.notificationForm.get('table').valueChanges.subscribe(v => {
      if (!!v) {
        this.notificationForm.get('columnObserve').reset();
        this.selectedColumnsItems = [];
        this.columnObserveList = this.tableColumnListData[v];
        this.filteredItems = this.columnObserveList;
        if (this.data.rowData && !!this.data.rowData.columns_observe) {
          this.notificationForm.get('columnObserve').setValue(this.data.rowData.columns_observe);
        }
      }
    });

    this.notificationForm.get('column').valueChanges.subscribe(v => {
      if (!!v) {
        let value = v === 'recruiter_id' ? 'RecruiterId' : 'PatientId';
        this.notificationForm.get('columnID').setValue(value);
        this.notificationForm.get('columnID').disable();
      }
    });

    this.notificationForm.get('userType').valueChanges.subscribe(v => {
      if (!!v) {
        if (v === 'Recruiters') {
          this.notificationForm.get('column').clearValidators();
          this.notificationForm.get('column').setValidators([Validators.required]);
          this.notificationForm.updateValueAndValidity();
        } else {
          this.notificationForm.get('column').clearValidators();
          this.notificationForm.updateValueAndValidity();
        }
      }
    });

    this.notificationForm.get('action').valueChanges.subscribe(v => {
      if (!!v) {
        if (v === 'update_row' || v === 'date_match') {
          this.notificationForm.get('columnObserve').clearValidators();
          this.notificationForm.get('columnObserve').setValidators([Validators.required]);
          this.notificationForm.get('columnObserve').updateValueAndValidity();
        } else {
          this.notificationForm.get('columnObserve').clearValidators();
          this.notificationForm.get('columnObserve').updateValueAndValidity();
        }
      }
    });

    this.variableSearch.valueChanges.subscribe(v => {
      if (!!v.trim()) {
        this.filteredItems = this.columnObserveList.filter((item) =>
            item.toLowerCase().includes(v.toLowerCase())
        );
      } else {
        this.filteredItems = this.columnObserveList;
      }
    });
  }

  getTableData(): void {

    this.spinner.show();
    this._Global.IsReady = false;
    const getData = this.notitificationsService.getTableColumnList().subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.tableColumnListData = res.data;
          this.tableList = Object.keys(res.data);
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.toastService.error('', err.message, this.toastOptions);
      }
    });
    this.subs.add(getData);
  }

  getUserData(): void {

    this.spinner.show();
    this._Global.IsReady = false;
    const getData = this.notitificationsService.getUserRoleList().subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.userTypeList = res.data;
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.toastService.error('', err.message, this.toastOptions);
      }
    });
    this.subs.add(getData);
  }

  getControlValue(controlName: string, getValue: boolean): any {
    getValue ? this.notificationForm.get(controlName).value : this.notificationForm.get(controlName);
  }

  updateCursorPosition(event: any): void {
    this.cursorPosition = event.target.selectionStart;
  }

  selectSlug(slug: any): void {

    let textToAppend = '{{Current.' + slug + '}}'
    const currentPosition = this.textarea.nativeElement.selectionStart;
    const currentMessage = this.notificationContentForm.get('text').value;

    const updatedMessage =
      currentMessage.substring(0, currentPosition) +
      textToAppend +
      currentMessage.substring(currentPosition);

    this.notificationContentForm.get('text').patchValue(updatedMessage);

    // Optionally, you can reset the cursor position after updating the textarea
    this.textarea.nativeElement.selectionStart = currentPosition + textToAppend.length;
    this.textarea.nativeElement.selectionEnd = currentPosition + textToAppend.length;
    this.textarea.nativeElement.focus();
  }

  onItemSelect(event): void {
    this.selectedColumnsItems.push(event.itemData);
    this.selectedColumnsItems = [...new Set(this.selectedColumnsItems)];
  }
  onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs, list: any) => {
    let query = new Query();
    let filteredData: string[] = list.filter(item => item.toLowerCase().includes(e.text.toLowerCase()));
    e.updateData(filteredData, query);
};


  onItemRemove(event): void {
    this.selectedColumnsItems = this.selectedColumnsItems.filter(data => data !== event.itemData);
  }

  submitstep1Form(): void {

    if (this.notificationForm.valid) {
      if (!!this.editData && this.editData.table_name !== this.notificationForm.get('table').value) {
        this.notificationContentForm.get('text').reset();
      }
      this.stepper.next();
    } else {
      this.submittedStep1 = true;
      this.notificationForm.markAsTouched();
      this.cd.detectChanges();
    }
  }

  exitWithoutSaving(flag: boolean): void {

    const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
      data: {
        title: 'Alert!',
        message: "Are you sure you want to exit without saving?",
        AcceptBtnText: "Exit",
        CancelBtnText: "Cancel"
      },
    });
    const dialogRefSub = dialogRef.afterClosed().subscribe(res => {
      if (res === 'yes') {
        this.dialogRef.close(flag);
      }
    });
    this.subs.add(dialogRefSub);
  }

  goBack(): void {
    this.stepper.previous();
  }

  saveFormData(): void {

    if (this.notificationForm.valid && this.notificationContentForm.valid) {
      const confirmDialogRef = this.dialog.open(DeclinependingreviewComponent, {
        panelClass: ['small-dailog-width'],
        data: {
          title: 'Alert!',
          message: "Are you sure you want to save?",
          AcceptBtnText: "Save",
          CancelBtnText: " Cancel"
        },
      });
      confirmDialogRef.afterClosed().subscribe(res => {
        if (res === 'yes') {
            this.spinner.show();
            this._Global.IsReady = false;
  
            let formData = this.notificationForm.getRawValue();
            let contentForm = this.notificationContentForm.getRawValue();
            
            let payload = {
              title: contentForm.title,
              content: contentForm.text,
              table_name: formData.table,
              table_action: formData.action,
              columns_observe: !!formData.columnObserve ? formData.columnObserve : null,
              user_type: formData.userType,
              recruiter_type: !!formData.column ? formData.column : null,
              recruiter_column: !!formData.columnID ? formData.columnID : null,
              is_active:true
            }
            let params = new HttpParams();
            if (!!this.editData) {
              params = params.set('id', this.editData._id);
            }
            const getData = this.notitificationsService.createAutomatedNotification(payload, params).subscribe({
              next: (res) => {
                if (res.status) {
                  this.spinner.hide();
                  this._Global.IsReady = true;
                  this.toastService.success('', res.message, this.toastOptions);
                  this.dialogRef.close(true);
                }
              },
              error: (err) => {
                this.spinner.hide();
                this._Global.IsReady = true;
                this.toastService.error('', err.message, this.toastOptions);
              }
            });
            this.subs.add(getData);
        } else {
          this.dialogRef.close(false);
        }
      });
    }
  }
}
