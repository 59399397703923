
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientDetailDialogService {
  private patientDetailDialogClosedSource = new Subject<void>();

  patientDetailDialogClosed$ = this.patientDetailDialogClosedSource.asObservable();

  closePatientDetailDialog() {
    this.patientDetailDialogClosedSource.next();
  }
}