import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterService, RowSelectEventArgs, VirtualScrollService } from '@syncfusion/ej2-angular-grids';
import { fadeInOut, bottomtotop } from '@app/animation';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSidenav } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { CallListType } from '@sharedmodels/DMCREnums/DMCREnums';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { RecruitercalllistMarkComponent } from '@app/pages/recruitercalllist-mark/recruitercalllist-mark.component';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@sharedservices/helper.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { CreatepatientComponent } from '@app/pages/createpatient/createpatient.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { RecruitercalllistNotesComponent } from '@app/pages/recruitercalllist-notes/recruitercalllist-notes.component';
import { PatientDetailDialogService } from '@app/calllists/patientdetail/patientdetaildialogservice';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-patientdetail',
  templateUrl: './patientdetail.component.html',
  styleUrls: ['../../style.component.scss',
    '../../../assets/comtrak-ui-system-v-0.1/sass/screens/_rightSideBar.scss',],
  animations: [
    fadeInOut, bottomtotop
  ],
  providers: [FilterService, VirtualScrollService]
})
export class PatientDetailComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  private patienDetail: any;
  subscriptions = new Subscription();
  dialogOpenFlag = false;
  isMoreData:boolean = true;
  constructor(
    private modal: NgbModal, 
    public dialogRef: MatDialogRef<PatientDetailComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: RowSelectEventArgs,
    public _PatientInteractionService: PatientInteractionService,
    public dialog: MatDialog,
    public _Global: GlobalfunctionService,
    public spinner: NgxSpinnerService,
    public helperService:HelperService,
    public _PatientService: PatientService,
    private _PatientDetailDialogService: PatientDetailDialogService,
    private scriptService: ScriptService
    ) {
  }

  ngOnInit() {
    const openScriptDialogSub = this.scriptService.openScriptDialog.subscribe(res => {
      this.dialogOpenFlag = res.openFlag;
      res.openFlag ? this.openModal() : this.closeModal();
    });
    this.subscriptions.add(openScriptDialogSub);

    if(this.data){
      this.patienDetail = this.data;
      // debugger
      this.patienDetail.numberOfDays = this.helperService.getDaysBetweenTwoDates(this.patienDetail.AssignmentDate,new Date().toString());
    }
    this._PatientDetailDialogService.patientDetailDialogClosed$.subscribe(() => {
      this.dialogRef.close();
    });
  }

  openModal(): void {
    // Open the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.add('show');
      modal.setAttribute('aria-modal', 'true');
      modal.style.display = 'block';
    }
  }

  closeModal(): void {
    // Close the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      modal.style.display = 'none';
    }
  }

  onNoClick(){
    this.dialogRef.close();
  }
  OpenMarkDialog() {
    this.spinner.show();
    this._PatientInteractionService.GetInteractions(CallListType.Recruiter, this.patienDetail.Id, this.patienDetail.StatusId, this.patienDetail.LastInteractionId).subscribe(res => {
      const dialogRef = this.dialog.open(RecruitercalllistMarkComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <PatientInteraction>res,
          screenType: this.patienDetail.screenType,
          IsDNQ: false,
          isEdit: false,
          lastInterationStatus : this.patienDetail.Status ? this.patienDetail.Status : "",
          lastInterationStatusId:  this.patienDetail.StatusId ? this.patienDetail.StatusId : "",
          rowData: this.patienDetail,    
        }
      });
      this.spinner.hide();
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }
  OpenNewDialog() {
    this.spinner.show()
    this._PatientService.GetPatientDetailById(this.patienDetail.Id).subscribe(res => {
      const dialogRef = this.dialog.open(CreatepatientComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <Patient>res,
          screenType: CallListType.Recruiter
        }
      })
      this.spinner.hide()
    }, error => {
      this._Global.handleError(error);
      this.spinner.hide()
    })
  }
  OpenNotesDialog(pageNo = 1) {
    let params = new HttpParams()
    .append('PageNo', String(pageNo))

    this.spinner.show()
    forkJoin([this._PatientInteractionService.GetPatientInteractions(this.patienDetail.Id),
    this._PatientInteractionService.GetInteractions(CallListType.PatientNotes, this.patienDetail.Id, this.patienDetail.StatusId, this.patienDetail.LastInteractionId),
    this._PatientService.GeCommunicationHistoryById(this.patienDetail.Id, params)]).subscribe(res => {
      let array = [
        { PatientInteraction: <PatientInteraction>res[0], patientInfo: <Patient>res[1], patientList: res[2] }
      ]
      this.isMoreData =  res[2].pagination.isMore;
      const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        // panelClass: ['dailogwidth', 'animate__animated', 'animate__slideInDown'],

        disableClose: true,
        data: {
          PatientInteraction: array,
          hideAddNotes: true,
          rowData: this.patienDetail,
          isMoreData:this.isMoreData,
        }
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }

  ngOnDestroy(): void {
    this.subscriptions ? this.subscriptions.unsubscribe() : null;
  }

}
