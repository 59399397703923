import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';

import { StudyVisit } from '@sharedmodels/BackModels/ComTrak/Setups/StudyVisit';

@Injectable({
    providedIn: 'root'
})
export class StudyQuestionService {
    public _studyQuestion: any[];
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService) { }
    LoadData(studyId: any, patientInteractionId: any, studyVisitId: any, isVisit: boolean, isScreening: boolean): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'StudyQuestion/LoadData/' + studyId + "/" + patientInteractionId + "/" + studyVisitId + "/" + isVisit + "/" + isScreening, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
        }
    }
}
