import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { CallList } from '@sharedmodels/BackModels/ComTrak/CallLists/CallList';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrescreenerService {

  public _callList: CallList[];
  public arraylength: number;
  public _callListArray: CallList[];
  public isCallApi = new Subject<boolean>();
  constructor(public _http: HttpClient, private _Global: GlobalfunctionService,
    private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

  LoadPreScreenerCallList(studyid: string, siteid: string, statusid: string, startDate: string, endDate: string, pageNo: number,
    pageSize: number, searchText: string, responseId: string): any {
    try {
      this.spinner.show();
      studyid = studyid == '' ? studyid = '-1' : studyid;
      siteid = siteid == '' ? siteid = '-1' : siteid;
      statusid = statusid == '' ? statusid = '-1' : statusid;
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PreSceenersCallList?studyId=' + studyid + '&siteId=' + siteid
        + '&statusid=' + statusid + '&startDate=' + startDate + '&endDate=' + endDate
        + '&pageno=' + pageNo + '&pagesize=' + pageSize + '&searchString=' + searchText + '&responseId=' + responseId
        , { headers: this._Global.httpOptions })
        ;
    } catch (x) {

      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }
  GetPreScreenerCallList(studyid: string, siteid: string, statusid: string, startDate: string, endDate: string, pageNo: number,
    pageSize: number, searchText: string, responseId: string) {
    try {
      this.LoadPreScreenerCallList(studyid, siteid, statusid, startDate, endDate, pageNo, pageSize, searchText,responseId).subscribe(data => {
        this._callList = <CallList[]>data;
        this._callListArray = <CallList[]>data;
        this.arraylength = this._callList.length
        this._Global.IsReady = true;
        this.spinner.hide();
        this.isCallApi.next(true)
        this._Global.IsReady = true;
        this.spinner.hide();
      },
        error => {
          this.isCallApi.next(false)
          this._Global.handleError(error);
          this.spinner.hide();
        });

    } catch (x) {

    }
  }
}
