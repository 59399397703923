import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common'; 
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-publisheddetailsPrescreen',
  templateUrl: './publisheddetailsPrescreen.component.html',
  styleUrls: ['./publisheddetailsPrescreen.component.scss']
})
export class PublisheddetailsPrescreenComponent implements OnInit {
  public myData: any = [];
  htmlContent: any;
  fromAutomatedNotification = false;
  noData = '---';
  actionList = {
    new_row: 'New Row',
    update_row: 'Update Row',
    delete_row: 'Delete Row',
    date_match: 'Date Match'
  };
  columnList = {
    recruiter_id: 'Recruiter ID Column',
    recruiter_connected_to_patient: 'Recruiter Connected to Patient'
  };

  constructor(
    public dialogRef: MatDialogRef<PublisheddetailsPrescreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe ,
    private sanitizer: DomSanitizer
  ) {

    this.myData = this.data.rowData;
    this.fromAutomatedNotification = data.fromAutomated ? true : false;
  }

  ngOnInit() {
    this.updateType();
  }

  columnObsever(data: string[]): string {
    return data.join(', ');
  }

  updateType() {
    if (this.myData.type === 'phone') {
      this.myData.type = 'Phone Script';
    } else if (this.myData.type === 'text') {
      this.myData.type = 'Text Script';
    } else if (this.myData.type === 'email') {
      this.myData.type = 'Email Script';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  formatDate(dateTime: string): string {
    try {
      const localDateTime = new Date(dateTime);
      if (isNaN(localDateTime.getTime())) {
        throw new Error('Invalid date');
      }
      const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy | h:mm a');
      return formattedDate ? formattedDate : '';
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }
  formatDate1(dateTime: string): string {
    if (!dateTime) {
      return null;
    }
    const localDateTime = new Date(dateTime);
    const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy');
    return formattedDate;
  }

  getPastTense(action: string): string {
    const actionMapping: { [key: string]: string } = {
      create: 'Created',
      discard: 'Discarded',
      decline:'Declined',
      update:'Updated',
      pin:'Pined',
      unpin:'Unpined',
      active: 'Activated',
      inactive: 'deactivated',
      restore:'Restored'
    };
    return actionMapping[action.toLowerCase()] || action;
  }
}
