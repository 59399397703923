import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListFilter, ListMaster, ReAssignableRecruiterList } from '@sharedmodels/BackModels/ComTrak/DBLM/DBLM';
import { Observable, Subject } from 'rxjs';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { SheetModel } from '@app/DBLM/importdblm/importdblm.component';

@Injectable({
    providedIn: 'root'
})
export class DBLMService {
    public searchString: string = ''
    public Id: number = 0
    public InclusionValue: any
    public ExclusionValue: any
    public listMasterId: any
    public IsSaveListMaster = new Subject<boolean>();
    public IsPatientCount = new Subject<boolean>();
    public IsSaveList = new Subject<boolean>();
    public IsSaveAssign = new Subject<boolean>();
    public IsCallEdit = new Subject<boolean>();
    public IsSaveBulkInsert = new Subject<boolean>();
    public isCompleted: boolean = false
    public _dblmList: ListMaster[] = []
    public _therapeuticAreaList: any[] = [];
    public _dblmListMaster: ListMaster[] = [];
    public _listFiltersByListMaster: ListFilter[] = [];
    // public IsRemainingPatient = new Subject<boolean>();
    _dblmCount: number = 0;
    _patientCount: any = 0;
    _reAssignableCount: any = 0;
    _reAssignableRecruiterList: ReAssignableRecruiterList[] = [];
    _isEdit: any[];
    disablebtn: boolean = true;
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private spinner: NgxSpinnerService) { }

    GetAllDBLMList(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetAllDBLMList&searchString=' + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._dblmList = <ListMaster[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllDBLMListByDBLMMasterId(Id: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetAllDBLMListByMasterId&listMasterId=' + Id, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._dblmList = <ListMaster[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetDBLMAssignablePatientCount(listMasterId: any, storProcName: string) {
        try {
            return this._http.get(myGlobals._apiPath + 'DBLM?getType=GetDBLMAssignablePatientCount&listMasterId=' + listMasterId + '&storProcName=' + storProcName, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetDBLMReassignableList() {
        try {
            // this.spinner.show()
            // this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetDBLMReassignableList&listMasterId=' + this.listMasterId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._reAssignableRecruiterList = <ReAssignableRecruiterList[]>data;
                    // this.spinner.hide()
                    // this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        // this.spinner.hide()
                        // this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            // this._Global.IsReady = true;
            // this.spinner.hide()
        }
    }

    GetReAssignablePatientCount() {
        try {
            // this.spinner.show()
            // this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetReAssignablePatientCount&listMasterId=' + this.listMasterId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._reAssignableCount = <any[]>data;
                    // this.spinner.hide()
                    // this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        // this.spinner.hide()
                        // this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            // this._Global.IsReady = true;
            // this.spinner.hide()
        }
    }

    IsEdit(): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'DBLM?getType=IsEdit&listMasterId=' + this.listMasterId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetlistPatientsCount(listmasterId: number): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'DBLM?getType=GetlistPatientsCount&listMasterId=' + listmasterId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetRecruiterPatientsCount(recruiterId: number): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'DBLM?getType=GetRecruiterPatientsCount&listMasterId=' + this.listMasterId + '&recruiterId=' + recruiterId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetRecruiterPatientsCountFromOtherList(recruiterId: number): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'DBLM?getType=GetRecruiterPatientsCountFromOtherList&listMasterId=' + this.listMasterId + '&recruiterId=' + recruiterId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetDBLMCount(visCount: number, inClusionFormValue: any, exClusionFormValue: any, isMSG: boolean) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            var _stateIdsInclude = inClusionFormValue && inClusionFormValue.stateIds ? inClusionFormValue.stateIds.map(o => o.StateID).join(",") : null;
            var _statusIdsInclude = inClusionFormValue && inClusionFormValue.statusIds ? inClusionFormValue.statusIds.map(o => o.Id).join(",") : null;
            var _campaignIdsInclude = inClusionFormValue && inClusionFormValue.campaignIds ? inClusionFormValue.campaignIds.map(o => o.Id).join(",") : null;
            var _campaignRefIdsInclude = inClusionFormValue && inClusionFormValue.campaignRefIds ? inClusionFormValue.campaignRefIds.map(o => o.Id).join(",") : null;
            var _siteIdsInclude = inClusionFormValue && inClusionFormValue.siteIds ? inClusionFormValue.siteIds.map(o => o.Id).join(",") : null;
            var _studyIdsInclude = inClusionFormValue && inClusionFormValue.studyIds ? inClusionFormValue.studyIds.map(o => o.Id).join(",") : null;

            var _ThrepauticAreaInclude = inClusionFormValue && inClusionFormValue.therapeutiocAreaIds ? inClusionFormValue.therapeutiocAreaIds.map(o => o.Id).join(",") : null;
            var _IndicationInclude = inClusionFormValue && inClusionFormValue.indicationIds ? inClusionFormValue.indicationIds.map(o => o.Id).join(",") : null;

            var _ThrepauticAreaExclude = exClusionFormValue && exClusionFormValue.therapeutiocAreaIds ? exClusionFormValue.therapeutiocAreaIds.map(o => o.Id).join(",") : null;
            var _IndicationExclude = exClusionFormValue && exClusionFormValue.indicationIds ? exClusionFormValue.indicationIds.map(o => o.Id).join(",") : null;

            var _stateIdsExclude = exClusionFormValue && exClusionFormValue.stateIds ? exClusionFormValue.stateIds.map(o => o.StateID).join(",") : null;
            var _statusIdsExclude = exClusionFormValue && exClusionFormValue.statusIds ? exClusionFormValue.statusIds.map(o => o.Id).join(",") : null;
            var _campaignIdsExclude = exClusionFormValue && exClusionFormValue.campaignIds ? exClusionFormValue.campaignIds.map(o => o.Id).join(",") : null;
            var _siteIdsExclude = exClusionFormValue && exClusionFormValue.siteIds ? exClusionFormValue.siteIds.map(o => o.Id).join(",") : null;
            var _campaignRefIdsExclude = exClusionFormValue && exClusionFormValue.campaignRefIds ? exClusionFormValue.campaignRefIds.map(o => o.Id).join(",") : null;
            var _studyIdsExclude = exClusionFormValue && exClusionFormValue.studyIds ? exClusionFormValue.studyIds.map(o => o.Id).join(",") : null;
            var fromAgeExclude = exClusionFormValue && exClusionFormValue.fromAge != null && exClusionFormValue.fromAge != '' ? exClusionFormValue.fromAge : 0
            var toAgeExclude = exClusionFormValue && exClusionFormValue.toAge != null && exClusionFormValue.toAge != '' ? exClusionFormValue.toAge : 0
            var genderExclude = exClusionFormValue ? exClusionFormValue.gender : null
            var languageExclude = exClusionFormValue ? exClusionFormValue.language : null
            var fromBMIExclude = exClusionFormValue && exClusionFormValue.fromBMI != null && exClusionFormValue.fromBMI != '' ? exClusionFormValue.fromBMI : 0
            var toBMIExclude = exClusionFormValue && exClusionFormValue.toBMI != null && exClusionFormValue.toBMI != '' ? exClusionFormValue.toBMI : 0
            var fromDateExclude = exClusionFormValue ? exClusionFormValue.fromInteractionDate : null
            var toDateExclude = exClusionFormValue ? exClusionFormValue.toInteractionDate : null

            let includeFromage = inClusionFormValue && inClusionFormValue.fromAge != '' && inClusionFormValue.fromAge != null ? inClusionFormValue.fromAge : 0
            let includeToage = inClusionFormValue && inClusionFormValue.toAge != '' && inClusionFormValue.toAge != null ? inClusionFormValue.toAge : 0
            let includeFromBMI = inClusionFormValue && inClusionFormValue.fromBMI != '' && inClusionFormValue.fromBMI != null ? inClusionFormValue.fromBMI : 0
            let includeToBMI = inClusionFormValue && inClusionFormValue.toBMI != '' && inClusionFormValue.toBMI != null ? inClusionFormValue.toBMI : 0
            let inClusionGender = inClusionFormValue && inClusionFormValue.gender ? inClusionFormValue.gender : null
            let inClusionLangugage = inClusionFormValue && inClusionFormValue.language ? inClusionFormValue.language : null
            let inClusionfromInteractionDate = inClusionFormValue && inClusionFormValue.fromInteractionDate ? inClusionFormValue.fromInteractionDate : null
            let inClusiontoInteractionDate = inClusionFormValue && inClusionFormValue.toInteractionDate ? inClusionFormValue.toInteractionDate : null
            let inClusiontoCreationDate = inClusionFormValue && inClusionFormValue.toCreationDate ? inClusionFormValue.toCreationDate : null
            let inClusionfromCreationDate = inClusionFormValue && inClusionFormValue.fromCreationDate ? inClusionFormValue.fromCreationDate : null

            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetDBLMCount&visCount=' + visCount + '&listMasterId=' + this.listMasterId
                + '&fromAgeInclude=' + includeFromage + '&fromAgrExclude=' + fromAgeExclude + '&toAgeInclude=' + includeToage + '&toAgeExclude='
                + toAgeExclude + '&genderInclude=' + inClusionGender + '&genderExclude=' + genderExclude + '&languageIdsInclude=' + inClusionLangugage
                + '&languageIdsExclude=' + languageExclude + '&fomBMIInclude=' + includeFromBMI + '&fomBMIExclude=' + fromBMIExclude + '&toBMIInclude='
                + includeToBMI + '&toBMIExclude=' + toBMIExclude + '&stateIdsInclude=' + _stateIdsInclude + '&stateIdsExclude=' + _stateIdsExclude
                + '&statusIdsInclude=' + _statusIdsInclude + '&statusIdsExclude=' + _statusIdsExclude + '&campaignIdsInclude=' + _campaignIdsInclude + '&campaignIdsExclude='
                + _campaignIdsExclude + '&campaignRefIdsInclude=' + _campaignRefIdsInclude + '&campaignRefIdsExclude=' + _campaignRefIdsExclude + '&campaignRefAdIdsInclude=' + null
                + '&campaignRefAdIdsExclude=' + null + '&siteIdsInclude=' + _siteIdsInclude + '&siteIdsExclude=' + _siteIdsExclude + '&fromInteractionDateInclude='
                + inClusionfromInteractionDate + '&fromInteractionDateExclude=' + fromDateExclude + '&toInteractionDateInclude=' + inClusiontoInteractionDate
                + '&toInteractionDateExclude=' + toDateExclude
                + '&studyIdsInclude=' + _studyIdsInclude + '&studyIdsExclude=' + _studyIdsExclude + '&fromCreationDate=' + inClusionfromCreationDate
                + '&toCreationDate=' + inClusiontoCreationDate
                + '&ThrepauticAreaInclude=' + _ThrepauticAreaInclude + '&ThrepauticAreaExclude=' + _ThrepauticAreaExclude
                + '&IndicationInclude=' + _IndicationInclude + '&IndicationExclude=' + _IndicationExclude, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this._dblmCount = data && data.length > 0 ? data[0].PatientCount : 0;
                    console.log("this._dblmCount", this._dblmCount);
                    if (isMSG) {
                        // this.GetAllDBLMList(this.searchString)
                        this.IsSaveList.next(true)
                        var btn: any = document.getElementById("onNoClick")
                        btn.click()
                        // this._Global.showSuccessMsg("Saved Successfully.")
                    }
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetlistFiltersByListMasterId(listMasterId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetlistFiltersByListMasterId&listMasterId=' + listMasterId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._listFiltersByListMaster = <ListFilter[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllTherapeuticArea() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetAllTherapeuticArea', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._therapeuticAreaList = <any[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllDBLMMasterList() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'DBLM?getType=GetAllDBLMMasterList', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._dblmListMaster = <ListMaster[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }


    SaveAndEditListMaster(isEdit: boolean, body: ListMaster) {
        if (!isEdit) {
            this.SaveListMaster(body)
        }
        else {
            this.UpdateListMaster(body)
        }
    }

    SaveListMaster(body: ListMaster) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'DBLM/CreateListMaster', body, { headers: this._Global.httpOptions })
                .subscribe((data: ListMaster) => {
                    this.disablebtn = false
                    this.listMasterId = data.Id
                    this.GetAllDBLMList(this.searchString)
                    this._Global.showSuccessMsg("Saved Successfully")
                    this.goNext()
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.IsSaveListMaster.next(true)
                },
                    error => {
                        this.isCompleted = false
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveListMaster.next(false)
                    });
        } catch (x) {
            this.isCompleted = false
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    SaveBulkInsert(body: SheetModel[], fileName: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'DBLM/BulkInsert?fileName=' + fileName, body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.IsSaveBulkInsert.next(true)
                    if (data && data.Result && data.Result.IsResponse == 0) {
                        this._Global.showErrorMsg(data && data.Result ? data.Result.Message : "List Upload Unsuccessfully.");
                    }
                    else {
                        this._Global.showSuccessMsg(data && data.Result ? data.Result.Message : "List Upload Successfully.");
                    }
                    this.GetAllDBLMList(this.searchString)
                    // this._Global.showSuccessMsg(data ? data.Result : "List Upload Successfully.")
                    // this.spinner.hide()
                    // this._Global.IsReady = true;
                },
                    error => {
                        this.IsSaveBulkInsert.next(false)
                        this._Global.showErrorMsg(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    goNext() {
        var check = document.getElementById('stepperButtonDBLM')
        check.click();
        this.isCompleted = true
    }

    SaveListFilters(body: ListFilter[]) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'DBLM/CraeteListFilter', body, { headers: this._Global.httpOptions })
                .subscribe((data: ListFilter) => {
                    this._Global.showSuccessMsg("Saved Successfully")
                    this.goNext()
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    setTimeout(() => {
                        this.GetAllDBLMList(this.searchString)
                    }, 600);
         
                    this.IsSaveList.next(true)
                },
                    error => {
                        this.isCompleted = false
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveList.next(false)
                    });
        } catch (x) {
            this.isCompleted = false
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    EditListFilters(body: ListFilter[], isInclusion: boolean) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'DBLM/EditListFilters?isInclusion=' + isInclusion, body, { headers: this._Global.httpOptions })
                .subscribe((data: ListFilter) => {
                    this._Global.showSuccessMsg("Update Successfully")
                    this.goNext()
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    setTimeout(() => {
                        this.GetAllDBLMList(this.searchString)
                    }, 600);
                    this.IsSaveList.next(true)
                },
                    error => {
                        this.isCompleted = false
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveList.next(false)
                    });
        } catch (x) {
            this.isCompleted = false
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    UpdateListMaster(body: ListMaster) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'DBLM/UpdateListMaster', body, { headers: this._Global.httpOptions })
                .subscribe((data: ListMaster) => {
                    this.listMasterId = data.Id
                    this._Global.showSuccessMsg("Update Successfully")
                    this.GetAllDBLMList(this.searchString)
                    this.goNext()
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.IsSaveListMaster.next(true)
                },
                    error => {
                        this.isCompleted = false
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveListMaster.next(false)
                    });
        } catch (x) {
            this.isCompleted = false
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    AddDBLMAssignPatientToRecruiter(body: any[], storeprocName: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'DBLM/AddDBLMAssignPatientToRecruiter?storeprocName=' + storeprocName, body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.GetAllDBLMList(this.searchString)
                    this._Global.showSuccessMsg("Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.IsSaveAssign.next(true)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
}