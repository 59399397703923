import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import * as myGlobals from "@shared/global_var";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Pool } from "@sharedmodels/BackModels/ComTrak/Pools/pool";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PoolBuilderService {
  constructor(
    public _http: HttpClient,
    private _Global: GlobalfunctionService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) { }

  getPools(
    pageNumber: number,
    pageSize?: number,
    searchTerm: string = "",
    status: number = 4,
    orderBy: string = "",
    title: string = "",
    createdBy: string = "",
    poolId: string = ""
  ): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "PatientPool?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&searchTerm=" +
      encodeURIComponent(searchTerm) +
      "&StatusId=" +
      status +
      "&OrderBy=" +
      orderBy +
      "&Title=" +
      title +
      "&CreatedBy=" +
      createdBy +
      "&PoolId=" +
      poolId,
      { headers: this._Global.httpOptions }
    );
  }

  getCriteriaFields(): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath + "Criteria/criteria-fields",
      { headers: this._Global.httpOptions }
    );
  }

  getCriteriaByField(name: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "Criteria/get-criteria-by-field?criteriaName=" +
      name,
      { headers: this._Global.httpOptions }
    );
  }

  getStatuses(): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath + "Criteria/statuses-with-sub_statuses",
      { headers: this._Global.httpOptions }
    );
  }

  getMetroAreas(): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "Criteria/metropolitan-area-with-states",
      { headers: this._Global.httpOptions }
    );
  }

  createPool(data: any, isPublish: string = "false"): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath + "PatientPool?isPublish=" + isPublish,
      data,
      { headers: this._Global.httpOptions }
    );
  }

  getPoolById(id: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath + "PatientPool/" + id,
      { headers: this._Global.httpOptions }
    );
  }

  getOverlappedPatients(id: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "PatientPool/" +
      id +
      "/originalPatientsWithOverlaps",
      { headers: this._Global.httpOptions }
    );
  }

  getOverlappedPools(data: any): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath + "PatientPool/overlappingPools",
      data,
      { headers: this._Global.httpOptions }
    );
  }

  duplicatePool(poolId: number, createdBy: string): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath +
      "PatientPool/" +
      poolId +
      "/copy?createdBy=" +
      createdBy,
      {},
      { headers: this._Global.httpOptions }
    );
  }

  deletePool(poolId: number): Observable<any> {
    return this._http.delete(
      myGlobals._apiPatientMgmtApiPath + "PatientPool/" + poolId,
      { headers: this._Global.httpOptions }
    );
  }

  activatePool(poolId: number): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath + "PatientPool/activate/" + poolId,
      {},
      { headers: this._Global.httpOptions }
    );
  }

  deactivatePool(poolId: number): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath + "PatientPool/deactivate/" + poolId,
      {},
      { headers: this._Global.httpOptions }
    );
  }

  checkDuplicate(name: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "PatientPool/checkDuplicatePoolName?poolName=" +
      name,
      { headers: this._Global.httpOptions }
    );
  }

  refreshPool(data: any): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath + "PatientPool/refreshPool",
      data,
      { headers: this._Global.httpOptions }
    );
  }

  getVaccineNames(keyword: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "CrioCriteria/load-vaccination-history?keyword=" +
      keyword,
      { headers: this._Global.httpOptions }
    );
  }

  getMedicalHistory(keyword: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "CrioCriteria/load-medical-history?keyword=" +
      keyword,
      { headers: this._Global.httpOptions }
    );
  }

  getConcomitantMedications(keyword: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "CrioCriteria/load-concomitant-medications?keyword=" +
      keyword,
      { headers: this._Global.httpOptions }
    );
  }

  getRefreshedPoolInfo(id: string): Observable<any> {
    return this._http.get(
      myGlobals._apiPatientMgmtApiPath +
      "PatientPool/refresh-published-pool-info?poolId=" +
      id,
      { headers: this._Global.httpOptions }
    );
  }

  refreshPublishedPool(id: string, data: any): Observable<any> {
    return this._http.post(
      myGlobals._apiPatientMgmtApiPath +
      "PatientPool/refresh-published-pool?poolId=" +
      id,
      data,
      { headers: this._Global.httpOptions }
    );
  }
}