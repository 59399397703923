import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { EmployeeType } from '@sharedmodels/BackModels/ComTrak/Setups/EmployeeType';

@Injectable({
    providedIn: 'root'
})
export class EmployeeTypeService {
    public isCallApi = new Subject<boolean>();
    public _employeeType: EmployeeType[];

    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

    LoadEmployeeType() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            // this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'EmployeeType', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._employeeType = <EmployeeType[]>data;
                    // this.isCallApi.next(true)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        // this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            // this.isCallApi.next(false)
        }
    }

    GetEmployeeType(): EmployeeType[] {
        if (this._employeeType !== undefined && this._employeeType.length !== null) {
            return this._employeeType;
        } else {
            this.LoadEmployeeType();
        }
    }

    SaveEmployeeType(body: EmployeeType) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'EmployeeType/Save', body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    // this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Employee Type Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadEmployeeType()
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        // this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            // this.isCallApi.next(false)
        }
    }

    EditEmployeeType(body: EmployeeType) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'EmployeeType/Edit', body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    // this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Employee Type Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadEmployeeType()
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        // this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            // this.isCallApi.next(false)
        }
    }

    SaveAndEditEmployeeType(body: EmployeeType, isEdit: boolean) {
        if (isEdit) {
            this.EditEmployeeType(body)
        }
        else {
            this.SaveEmployeeType(body)
        }
    }

}
