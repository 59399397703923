export const environment = {
  production: true,
  staging: true,
  APIEndpoint : 'https://stage.be.comtrak.dmclinical.com/api/v1/' ,
  APIEndpointv2 : 'https://stage.be.comtrak.dmclinical.com/api/v2.0/' ,
  APIPatientMgmtEndpoint : 'https://appservice-patientmodule-api-staging.azurewebsites.net/api/' ,
  APIURLBase : 'https://stage.be.comtrak.dmclinical.com/' ,
  ASSET_URL: 'https://pegasus-staging-cdn.s3.us-east-2.amazonaws.com/', 
  nodeSocketUrl: 'https://commodule.stg.dmclinical.com',
  TWILIO_FUNCTION_EP: "https://burgundy-cockroach-5100.twil.io/",
  COMMODEndPointv1 : 'https://commodule.stg.dmclinical.com/',
  PatientPaymentURL: 'https://stage-dots-patient-payment.dmclinical.com/',
  sentry_URL:'https://a885e2ed216a4825a7884dbd1e7b799a@o562586.ingest.sentry.io/5708707',
  tracking_code:'G-2SEKHL5K6V',
  hotjarSiteId:2920367,
  appInsightsInstrumentationKey:"52c5ade3-c46d-48c5-bfee-8df158692c08",
  comtrakUrl: 'https://comtrak.stage.dmclinical.com', // Only define if you want to redirect users from Beta to Prod
  env: 'staging',
  appVersion: 'V 1.0.11 (090320240700CST)'
 
};
