import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-pool-statuses-info',
  templateUrl: './show-pool-statuses-info.component.html',
  styleUrls: ['./show-pool-statuses-info.component.css']
})
export class ShowPoolStatusesInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
