import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { SiteRooms } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-createsite-pi',
  templateUrl: './createsite-pi.component.html',
  styleUrls: ['./createsite-pi.component.scss']
})
export class CreatesitePIComponent implements OnInit {
  @ViewChild('checkBox') checkBox: TemplateRef<any>;
  @ViewChild('edit') edit: TemplateRef<any>;
  sitePIForm: FormGroup
  submitted: boolean = false
  columnDefs: ColDef[]
  public gridApi: any;
  public gridColumnApi: any;
  public defaultColDef;
  isEdit: boolean = false;
  constructor(public fb: FormBuilder, public siteService: SiteService, public dialogRef: MatDialogRef<CreatesitePIComponent>,
    public _Global: GlobalfunctionService) {
  }

  ngOnInit() {
    this.siteService.isSave = false
    this.gridConfig()
    this.initForm()
  }

  onNoClick(): void {
    if (this.siteService.isSave) {
      this.siteService.LoadSites(this.siteService.searchString)
    }
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  initForm() {
    this.sitePIForm = this.fb.group({
      Id: [0],
      SiteId: [this.siteService.siteId],
      IsActive: [false],
      PrincipalInvestigatorId: ['', Validators.required],
      Phone: ['', Validators.required],
      Email: ['', Validators.required],
      Specialization: ['', Validators.required],
    })
  }

  get f() {
    return this.sitePIForm.controls;
  }

  ChangePI() {
    if (this.sitePIForm.controls.PrincipalInvestigatorId.value == '') {
      this.sitePIForm.controls.Phone.setValue('')
      this.sitePIForm.controls.Email.setValue('')
      this.sitePIForm.controls.Specialization.setValue('')
    }
    else {
      let PIObject = this.siteService._activePrincipleInvestigators.find(o => o.Id == this.sitePIForm.controls.PrincipalInvestigatorId.value)
      if (PIObject) {
        this.sitePIForm.controls.Phone.setValue(PIObject.Phone)
        this.sitePIForm.controls.Email.setValue(PIObject.Email)
        this.sitePIForm.controls.Specialization.setValue(PIObject.Specialization)
      }
    }
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef
    this.columnDefs = [
      { headerName: 'Principal Investigator', field: 'PrincipalInvestigatorName', sortable: true, filter: true },
      { headerName: 'Phone', field: 'Phone', sortable: true, filter: true },
      { headerName: 'Email', field: 'Email', sortable: true, filter: true },
      { headerName: 'Specialization', field: 'Specialization', sortable: true, filter: true },
      {
        headerName: 'Active', field: 'IsActive', sortable: true, filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.checkBox
        }
      },
      {
        headerName: 'Edit',
        colId: 'edit',
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.edit
        }
      },
    ];
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.siteService._principalInvestigatorbySite);
  }

  onSubmit() {
    this.submitted = true;
    if (this.sitePIForm.invalid) {
      return;
    }
    else {
      this.siteService.SaveAndEditPrincipalInvestigator(this.sitePIForm.value, this.isEdit)
      this.siteService.isSaveSitePIs.subscribe(res => {
        if (res) {
          this.isEdit = false
          this.submitted = false
          this.initForm()
        }
      })
    }
  }

  setEditData(row: SiteRooms) {
    this.isEdit = true
    this.sitePIForm.patchValue(row)
  }

  uncheckCheckbox(elementId: number, booleanValue: boolean) {
    const activecheckbox = <HTMLInputElement>document.getElementById('checkBox' + `${elementId}`)
    activecheckbox.checked = booleanValue
  }

  ClickIsActive(row: any) {
    this.isEdit = true
    let datObject = {
      Id: row.Id,
      SiteId: row.SiteId,
      IsActive: row.IsActive,
      PrincipalInvestigatorId: row.PrincipalInvestigatorId,
      Phone: row.Phone,
      Email: row.Email,
      Specialization: row.Specialization,
    }
    if (row.IsActive == false) {
      if (confirm("Are you sure You want to InActive this Site PI?") == true) {
        // this.sitePIForm.patchValue(row)
        datObject.IsActive = row.IsActive
        this.siteService.SaveAndEditPrincipalInvestigator(datObject, this.isEdit)
      }
      else {
        this.uncheckCheckbox(row.Id, true)
        row.IsActive = true
      }
    }
    else {
      if (confirm("Are you sure You want to Active this Site PI?") == true) {
        // this.sitePIForm.patchValue(row)
        datObject.IsActive = row.IsActive
        this.siteService.SaveAndEditPrincipalInvestigator(datObject, this.isEdit)
      }
      else {
        this.uncheckCheckbox(row.Id, false)
        row.IsActive = false
      }
    }
  }
}
