import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { Leads } from '@sharedmodels/BackModels/ComTrak/Leads/lead';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { CallListType } from '@sharedmodels/DMCREnums/DMCREnums';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { RecruitercalllistNotesComponent } from '../recruitercalllist-notes/recruitercalllist-notes.component';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { PatientInteractionDTO } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';

@Component({
  selector: 'app-mergelead',
  templateUrl: './mergelead.component.html',
  styleUrls: ['./mergelead.component.scss']
})
export class MergeleadComponent implements OnInit {
  leadData: Leads;
  existingPatientsData: any;
  defaultSelected: number
  selection: number
  ExistingpatientData: Patient;
  disabledCampaign: boolean = false;
  disabledCampaignref: boolean = false; currentyStatus: number;
  ;
  disabledCampaignrefAd: boolean = false;
  disabledState: boolean = false;

  constructor(public dialogRef: MatDialogRef<MergeleadComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public leadService: LeadService,
    public campaignService: CampaignService, public campaignRefService: CampaignReferralSourceService, public dialog: MatDialog, public _patientService: PatientService,
    public patientIntService: PatientInteractionService, public _Global: GlobalfunctionService, public spinner: NgxSpinnerService,public _locationService: LocationService) { }

  ngOnInit() {
    if (this.data != undefined && this.data != null){
    this.leadData = this.data.leadData
    this.existingPatientsData = this.data.existingData
  }

  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  getCampaignName(campaignId: number) {
    if (campaignId && campaignId > 0) {
      let Obj = this.campaignService._AllCampaign.filter(o => o.Id == campaignId)[0]
      return Obj ? Obj.Name : ""
    } else {
      return ""
    }
  }

  getCampaignRefName(campaignrefId: number) {
    if (campaignrefId && campaignrefId > 0) {
      let Obj = this.campaignRefService._CampaignReferralSourceArray.filter(o => o.Id == campaignrefId)[0]
      return Obj ? Obj.Name : ""
    }
    else {
      return ""
    }
  }

  getCampaignRefAdName(campaignrefAdId: number) {
    if (campaignrefAdId && campaignrefAdId > 0) {
      let Obj = this.campaignRefService._CampaignReferralSourceAdDetails.filter(o => o.Id == campaignrefAdId)[0]
      return Obj ? Obj.Name : ""
    }
    else {
      return ""
    }
  }

  getExistingpatientData(item: Patient) {
    this.ExistingpatientData = item
    console.log("this.ExistingpatientData", this.ExistingpatientData)
  }

  MergeLead() {
    if (confirm("Are you sure you want to merge this lead?") == true) {
      if (this.ExistingpatientData) {
        if (this.ExistingpatientData.Email == null || this.ExistingpatientData.Email == "") {
          this.ExistingpatientData.Email = this.leadData.Email && this.leadData.Email != null && this.leadData.Email != "" ? this.leadData.Email.trim() : ""
        }
        if (this.ExistingpatientData.Phone == null || this.ExistingpatientData.Phone == "") {
          this.ExistingpatientData.Phone = this.leadData.Phone && this.leadData.Phone != null && this.leadData.Phone != "" ? this.leadData.Phone.trim() : ""
        }
        if (this.ExistingpatientData.Phone2 == null || this.ExistingpatientData.Phone2 == "") {
          this.ExistingpatientData.Phone2 = this.leadData.Phone && this.leadData.Phone != null && this.leadData.Phone != "" ? this.leadData.Phone.trim() : ""
        }
        if (this.ExistingpatientData.DateOfBirth == null || this.ExistingpatientData.DateOfBirth == "") {
          this.ExistingpatientData.DateOfBirth = this.leadData.DOB && this.leadData.DOB != null && this.leadData.DOB != "" ? this.leadData.DOB.trim() : ""
        }
        if (this.ExistingpatientData.Notes == null || this.ExistingpatientData.Notes == "") {
          this.ExistingpatientData.Notes = this.leadData.Notes && this.leadData.Notes != null && this.leadData.Notes != "" ? this.leadData.Notes.trim() : ""
        }
        if (this.ExistingpatientData.ZipCode == null || this.ExistingpatientData.ZipCode == "") {
          this.ExistingpatientData.ZipCode = this.leadData.ZipCode && this.leadData.ZipCode != null && this.leadData.ZipCode != "" ? this.leadData.ZipCode.trim() : ""
        }
        if (this.leadData.CampaignId !== null && this.leadData.CampaignId > 0) {
          this.ExistingpatientData.CampaignId = this.leadData.CampaignId
        }
        if (this.leadData.CampaignRefSourceId !== null && this.leadData.CampaignRefSourceId > 0) {
          this.ExistingpatientData.CampaignReferralSourceId = this.leadData.CampaignRefSourceId
        }
        if (this.leadData.CampaignRefSourceAdDetailId !== null && this.leadData.CampaignRefSourceAdDetailId > 0) {
          this.ExistingpatientData.CampaignReferralSourcesAdDetailId = this.leadData.CampaignRefSourceAdDetailId
        }
        this.leadService.MergeLead(this.ExistingpatientData, this.leadData.LeadId)
        this.leadService.isCallApi.subscribe(res => {
          if (res) {
            this.onNoClick()
          }
          else{
            this._Global.IsReady = true;
            this.spinner.hide();
            this.onNoClick();
          }
        });
      }
      else {
        this._Global.showErrorMsg("please select atleast one patient to merge")
      }
    }
  }

  checkDateOfBirth(DateOfBirth) {
    if (moment(DateOfBirth).format('MM/DD/yyyy') == moment(this.leadData.DOB).format('MM/DD/yyyy')) {
      return true
    }
    else {
      return false
    }
  }

  setDateIfBirthFormat() {
    return moment(this.leadData.DOB).format('MM/DD/yyyy')
  }

  CreateAnyway(){
    //debugger
    let currentLeadObj: any = this.leadData;
    this.CheckIncorrectLeads(currentLeadObj)

    let leadObj: Leads = {
        FirstName: currentLeadObj.FirstName,
        LastName: currentLeadObj.LastName,
        Phone: currentLeadObj.Phone,
        Email: currentLeadObj.Email,
        DOB: currentLeadObj.DOB,
        Gender: currentLeadObj.Gender,
        CampaignId: currentLeadObj.CampaignId,
        CampaignRefSourceId: currentLeadObj.CampaignRefSourceId,
        CampaignRefSourceAdDetailId: currentLeadObj.CampaignRefSourceAdDetailId,
        LeadId: currentLeadObj.LeadId,
        CurrentStatus: this.currentyStatus,
        StateID: currentLeadObj.StateID,
    }
    if (this.currentyStatus == LeadsStatusEnumns.IncorrectLead) {
        this._Global.showErrorMsg("This lead is incorrect please edit this lead")
        return
    }
    else {
        this.leadService.AssignedLead(leadObj)
        this.leadService.isCallApi.subscribe(res => {
          if (res) {
            this.onNoClick();
          }
        })
    }

  }
  CheckIncorrectLeads(currentObject: Leads) {
    if (this.campaignService._AllCampaign.filter(o => o.Id == currentObject.CampaignId)[0]) {
        this.disabledCampaign = false
    }
    else {
        this.disabledCampaign = true
    }

    if (this.campaignRefService._AllCampaignReferralSource.filter(o => o.Id == currentObject.CampaignRefSourceId)[0]) {
        this.disabledCampaignref = false
    }
    else {
        this.disabledCampaignref = true
    }

    if (this.campaignRefService._CampaignReferralSourceAdDetails.filter(o => o.Id == currentObject.CampaignRefSourceAdDetailId)[0]) {
        this.disabledCampaignrefAd = false
    }
    else {
        this.disabledCampaignrefAd = true
    }

    if (this._locationService._StateArray.filter(o => o.StateID == currentObject.StateID)[0]) {
        this.disabledState = false
    }
    else {
        this.disabledState = true
    }

    if (this.disabledCampaign == true || this.disabledCampaignref == true || this.disabledCampaignrefAd == true || this.disabledState == true) {
        this.currentyStatus = LeadsStatusEnumns.IncorrectLead;
    }
    else {
        this.currentyStatus = LeadsStatusEnumns.AssignableLead;
    }
}

  GetPatientNotes(item) {
    this.spinner.show()
    this._Global.IsReady = false
    this.patientIntService.GetLastPatientInteraction(item.Id).subscribe(res => {
      if (res) {
        forkJoin([this.patientIntService.GetPatientInteractions(item.Id),
        this.patientIntService.GetInteractions(CallListType.PatientNotes, res.PatientId, res.StatusId, res.Id)]).subscribe(res => {
          let array = [
            { PatientInteraction: <PatientInteractionDTO>res[0], patientInfo: <Patient>res[1] }
          ]
          const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
            panelClass: 'dailogwidth',
            disableClose: true,
            data: {
              PatientInteraction: array,
              hideAddNotes: false,
              rowData: item
            }
          });
          this.spinner.hide()
          this._Global.IsReady = true
        }, err => {
          this._Global.showErrorMsg(err)
          this.spinner.hide()
          this._Global.IsReady = true
        })
      }
      else {
        this.spinner.hide()
        this._Global.IsReady = true
        this._patientService.GetPatientDetailById(item.Id).subscribe(patientData => {
          this.spinner.hide()
          this._Global.IsReady = true
          let array = [
            { PatientInteraction: null, patientInfo: patientData }
          ]
          const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
            panelClass: 'dailogwidth',
            disableClose: true,
            data: {
              PatientInteraction: array,
              hideAddNotes: false,
              rowData: item
            }
          });
        }, error => {
          this._Global.showErrorMsg(error)
          this.spinner.hide()
          this._Global.IsReady = true
        })
      }
    }, error => {
      this._Global.showErrorMsg(error)
      this.spinner.hide()
      this._Global.IsReady = true
    })
  }
}
