import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignReferralSource, CampaignReferralSourceAdvertisement } from './../../../../Models/BackModels/ComTrak/Setups/CampaignReferralSource';
import { Observable, Observer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CampaignReferralSourceService {
    public isCallApi = new Subject<boolean>();
    public isCallApiById = new Subject<boolean>();
    public isCallApiAd = new Subject<boolean>();
    public isCallRefSource = new Subject<boolean>();
    public _CampaignReferralSource: CampaignReferralSource[] = []
    public _AllCampaignReferralSource: CampaignReferralSource[] = []
    public _CampaignReferralSourceArray: CampaignReferralSource[] = []
    public arraylength: number;
    public CampaignReferralSourceByCampaignId: CampaignReferralSource[] = [];
    public _CampaignReferralSourceAdDetails: CampaignReferralSourceAdvertisement[] = [];
    public _CampaignReferralSourceAdDetailsDateWise: CampaignReferralSourceAdvertisement[] = [];
    CampaignReferralSourceAdDetails: CampaignReferralSourceAdvertisement[] = [];
    ActiveCampaignReferralSourceByCampaignId: CampaignReferralSource[] = [];
    _leadsRefSourcesByCampaign: CampaignReferralSource[] = [];
    _patientsRefSourcesByCampaign: CampaignReferralSource[] = [];
    _leadsAdDetailsByRefSource: CampaignReferralSourceAdvertisement[] = [];
    _patientsAdDetailsByRefSource: CampaignReferralSourceAdvertisement[] = [];
    _alertConfigCampignRef: any[] = [];
    public MetroAreas: any[] = [];
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService,
        public spinner: NgxSpinnerService) { }

    LoadCampaignReferralSource() {

        if (this._CampaignReferralSource !== undefined && this._CampaignReferralSource.length > 0) {
            return this._CampaignReferralSource;
        }
        else {
            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetActiveCampaignReferralSource', { headers: this._Global.httpOptions })
                    .subscribe(data => {
                        this._CampaignReferralSource = <CampaignReferralSource[]>data;
                        this._Global.IsReady = true;
                        this.spinner.hide()
                    }, error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
                this.isCallApi.next(false)
            }
        }
    }
    GetCampaignReferralSources() {
        if (this._CampaignReferralSource !== undefined && this._CampaignReferralSource.length !== null) {
            return this._CampaignReferralSource;
        } else {
            this.LoadCampaignReferralSource();
        }
    }

    LoadAllCampaignReferralSource() {

        if (this._AllCampaignReferralSource !== undefined && this._AllCampaignReferralSource.length > 0) {
            return this._AllCampaignReferralSource;
        }
        else {

            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this.isCallApi.next(false)
                this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetAll', { headers: this._Global.httpOptions })
                    .subscribe(data => {
                        this._AllCampaignReferralSource = <CampaignReferralSource[]>data;
                        this._CampaignReferralSourceArray = <CampaignReferralSource[]>data;
                        this.arraylength = this._AllCampaignReferralSource.length
                        this.isCallApi.next(true)
                        this._Global.IsReady = true;
                        this.spinner.hide()
                    }, error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
                this.isCallApi.next(false)
            }
        }
    }

    GetAllCampaignReferralSources() {
        if (this._AllCampaignReferralSource !== undefined && this._AllCampaignReferralSource.length >0 ) {
            return this._AllCampaignReferralSource;
        } else {
            this.LoadAllCampaignReferralSource();
        }
    }

    GetAllCampaignReferralSourcesAdDetailsPreLoaded() {
        if (this._CampaignReferralSourceAdDetails !== undefined && this._CampaignReferralSourceAdDetails.length>0) {
            return this._CampaignReferralSourceAdDetails;
        } else {
            this.GetAllCampaignReferralSourceAdDetails();
        }
    }

    SaveCampaignsRefSource(body: CampaignReferralSource) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'CampaignReferralSource', body, { headers: this._Global.httpOptions })
                .subscribe((data: CampaignReferralSource) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Campaign Referral Source Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    // this.LoadAllCampaignReferralSource()
                    this.LoadCampaignReferralSourceByCampaignId(data.CampaignId)
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    EditCampaignsRefSource(body: CampaignReferralSource,existingStudiesArray:any[]=[]) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            body.CampaignReferralSourcesStudies= body.CampaignReferralSourcesStudies.map(x=>{
                let existingIdx=existingStudiesArray.findIndex(y=>y.studyId==x.StudyId)
                x.Id=existingIdx!=-1?existingStudiesArray[existingIdx].Id:0
                x.CampaignReferralSourcesId=body.Id;
                x.Study=null;
                x.CampaignReferralSources=null;
                return x;
            })


            this._http.put(myGlobals._apiPath + 'CampaignReferralSource?Type=Edit', body, { headers: this._Global.httpOptions })
                .subscribe((data: CampaignReferralSource) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Campaign Referral Source Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    // this.LoadAllCampaignReferralSource()
                    this.LoadCampaignReferralSourceByCampaignId(data.CampaignId)
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    SaveAndEditCampaignRefSource(body: CampaignReferralSource, isEdit: boolean,existingStudiesArray:any[]=[]) {
        if (isEdit) {
            this.EditCampaignsRefSource(body,existingStudiesArray)
        }
        else {
            this.SaveCampaignsRefSource(body)
        }
    }

    LoadCampaignReferralSourceByCampaignId(campaignId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApiById.next(false)
            this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetById&CampaignId=' + campaignId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.CampaignReferralSourceByCampaignId = <CampaignReferralSource[]>data;
                    this.ActiveCampaignReferralSourceByCampaignId = this.CampaignReferralSourceByCampaignId.filter(o => o.IsActive == true)
                    this.ActiveCampaignReferralSourceByCampaignId.forEach(element => {
                        this._alertConfigCampignRef.push({ Id: element.Id, Name: element.Name, CampaignId: campaignId })
                    })
                    this.isCallApiById.next(true)
                    this._Global.IsReady = true;
                    this.spinner.hide()
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApiById.next(false)
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApiById.next(false)
        }
    }

    GetCampaignReferralSourceAdDetailsById(CampaignReferralSourcesId: number): Observable<any> {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            return this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetCampaignRefSourceAdDetailsByCampaignRefId' + "&CampaignReferralSourcesId=" + CampaignReferralSourcesId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    LoadCampaignReferralSourceAdDetailsById(CampaignReferralSourcesId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetCampaignRefSourceAdDetailsByCampaignRefId' + "&CampaignReferralSourcesId=" + CampaignReferralSourcesId, { headers: this._Global.httpOptions }).subscribe(res => {
                this.CampaignReferralSourceAdDetails = <CampaignReferralSourceAdvertisement[]>res
                this._Global.IsReady = true;
                this.spinner.hide()
            }, error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllCampaignReferralSourceAdDetails() {

        if (this._CampaignReferralSourceAdDetails !== undefined && this._CampaignReferralSourceAdDetails.length > 0) {
            return this._CampaignReferralSourceAdDetails;
        }
        else {
            try {
                this.spinner.show()
                this._Global.IsReady = false;
                this.isCallApiAd.next(false)
                this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetAllCampaignRefSourceAdDetails', { headers: this._Global.httpOptions })
                    .subscribe(data => {
                        this._CampaignReferralSourceAdDetails = <CampaignReferralSourceAdvertisement[]>data
                        this._Global.IsReady = true;
                        this.spinner.hide()
                        this.isCallApiAd.next(true)
                    }, error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApiAd.next(false)
                    });
            } catch (x) {
                this._Global.showErrorMsg(x);
                this._Global.IsReady = true;
                this.spinner.hide()
            }
        }
    }

    GetLeadsRefSourcesByCampaign() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetLeadsRefSourcesByCampaign', { headers: this._Global.httpOptions }).subscribe(res => {
                this._leadsRefSourcesByCampaign = <CampaignReferralSource[]>res
                this._Global.IsReady = true;
                this.spinner.hide()
            }, error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetPatientsRefSourcesByCampaign() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetPatientsRefSourcesByCampaign', { headers: this._Global.httpOptions }).subscribe(res => {
                this._patientsRefSourcesByCampaign = <CampaignReferralSource[]>res
                this._Global.IsReady = true;
                this.spinner.hide()
            }, error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetLeadsAdDetailsByRefSource() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetLeadsAdDetailsByRefSource', { headers: this._Global.httpOptions }).subscribe(res => {
                this._leadsAdDetailsByRefSource = <CampaignReferralSourceAdvertisement[]>res
                this._Global.IsReady = true;
                this.spinner.hide()
            }, error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetPatientsAdDetailsByRefSource() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'CampaignReferralSource?type=GetPatientsAdDetailsByRefSource', { headers: this._Global.httpOptions }).subscribe(res => {
                this._patientsAdDetailsByRefSource = <CampaignReferralSourceAdvertisement[]>res
                this._Global.IsReady = true;
                this.spinner.hide()
            }, error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetMetraAreas() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'Location?getType=getAllMetro', { headers: this._Global.httpOptions }).subscribe((res:any) => {
                this.MetroAreas=res;
                this._Global.IsReady = true;
                this.spinner.hide()
            }, error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
}
