import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, throwError } from 'rxjs';
import { EmailConfiguration, EmailConfigurationReminders, EmailTemplate, InComingMessagesList } from '@sharedmodels/BackModels/ComTrak/Communication/EmailTemplate';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import swal from 'sweetalert2';
import { catchError, tap } from 'rxjs/operators';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    public editorText: string = ""
    public isCompleted: boolean = false
    public showNotificationDate: boolean = true
    public showCampaignFields: boolean = true
    public templateId: number
    public TypeId: number
    public templateObject: EmailTemplate
    public _templateList: EmailTemplate[] = []
    public _VariablesList: any[]=[]
    public _ScriptCategoryList: any[] = []
    public _ScriptList:any[]=[]
    public _ScriptDetails:any[]=[]
    public _configReminderList: EmailConfigurationReminders[] = [];
    public _emailCondfigByTemplateList: EmailConfiguration[];
    public IsSaveEmailTemplate = new Subject<boolean>();
    public IsSaveEmailConfig = new Subject<boolean>();
    public IsGetEmailConfig = new Subject<boolean>();
    public IsEditEmailConfig = new Subject<boolean>();
    public _emailConfigList: EmailConfiguration[];
    public _incomingMessagesList: InComingMessagesList[];
    public disabledtemplateSaveButton: boolean = false;
    _outgoingMessagesList: InComingMessagesList[];
    public lovType: Lov[] = []
    toastOptions: Partial<IndividualConfig> = {
        timeOut: 3000, // Duration in milliseconds
        positionClass: 'toast-top-right'
      };
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService,    private toastService: ToastrService, private spinner: NgxSpinnerService) { }

    GetEmailTemplate(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Templates?getType=GetEmailTemplate&searchString=' + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._templateList = <EmailTemplate[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetScriptCategoryList() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get<any>(myGlobals._apiPathv3 + 'script-repos/category' , { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._ScriptCategoryList = <any>data.data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide();
                        this._Global.IsReady = true;
        }
    );
        } catch (x) {
            this.toastService.error('',x, this.toastOptions);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }


    GetHashtagList(): Observable<any[]> {
        return this._http.get<any>(myGlobals._apiPathv3 + 'script-repos/hashtags', { headers: this._Global.httpOptions }) as Observable<any[]>;
    }

      CreateScript(body: any): Observable<any> {
        try {
          this.spinner.show();
          this._Global.IsReady = false;
          return this._http.post(myGlobals._apiPathv3 + 'script-repos/Scripts', body, { headers: this._Global.httpOptions })
            .pipe(
              tap((data: any) => {
                if(!data.status){
                    this.spinner.hide();
                    this._Global.IsReady = true;
                    this.toastService.error('',data.message, this.toastOptions);
                    throw new Error(data.message);
                } else {
                    this.spinner.hide();
                    this._Global.IsReady = true;
                    this.toastService.success('',data.message, this.toastOptions);
                }
              }),
              catchError((error: any) => {
                this.isCompleted = false;
                this._Global.handleError(error);
                this.spinner.hide();
                this._Global.IsReady = true;
                this.IsSaveEmailTemplate.next(false);
                return throwError(error); // Rethrow the error to be caught by the caller
              })
            );
        } catch (x) {
          this.isCompleted = false;
          this.toastService.error('',x, this.toastOptions);
          this._Global.IsReady = true;
          this.spinner.hide();
          this.IsSaveEmailTemplate.next(false);
          return throwError(x); // Rethrow the error to be caught by the caller
        }
      }


    ChnageScriptStatus(body: any): Observable<any> {
  this.spinner.show();
  return this._http.post<any>(myGlobals._apiPathv3 + 'script-repos/Scripts/change-status', body, { headers: this._Global.httpOptions })
    .pipe(
      tap((data: any) => {
        if(!data.status){
            this.spinner.hide();
            this._Global.IsReady = true;
            this.toastService.error('',data.message, this.toastOptions);
            throw new Error(data.message);
        } else {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.toastService.success('',data.message, this.toastOptions);
        }
      }),
      catchError((error: any) => {
        this._Global.handleError(error);
        this.spinner.hide();
        this._Global.IsReady = true;
        this.IsSaveEmailTemplate.next(false);
        return throwError(error); // Rethrow the error to be caught by the caller
      })
    );
}


    GetScriptList(searchString: string, pageNo: number, ScriptStatus: string): Observable<any[]> {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            return this._http.get<any[]>(myGlobals._apiPathv3 + 'script-repos/Scripts?PageNo=' + pageNo + '&ScriptStatus=' + ScriptStatus + '&SearchString=' + searchString, { headers: this._Global.httpOptions })
                .pipe(
                    tap(data => {
                        this._ScriptList = <any[]>data;
                        this._Global.IsReady = true;
                        this.spinner.hide();
                    }),
                    catchError(error => {
                        this._Global.handleError(error);
                        this.spinner.hide();
                        this._Global.IsReady = true;
                        return throwError(error); // Return the error as an observable
                    })
                );
        } catch (x) {
            this.toastService.error('',x, this.toastOptions);
            this._Global.IsReady = true;
            this.spinner.hide();
            return throwError(x); // Return the error as an observable
        }
    }

    GetScriptDetails(Id: any): Observable<any[]> {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            return this._http.get<any[]>(myGlobals._apiPathv3 + 'script-repos/Scripts/'+ Id , { headers: this._Global.httpOptions })
                .pipe(
                    tap(data => {
                        this._ScriptDetails = <any[]>data;
                        this._Global.IsReady = true;
                        this.spinner.hide();
                    }),
                    catchError(error => {
                        this._Global.handleError(error);
                        this.spinner.hide();
                        this._Global.IsReady = true;
                        return throwError(error); // Return the error as an observable
                    })
                );
        } catch (x) {
            this.toastService.error('',x, this.toastOptions);
            this._Global.IsReady = true;
            this.spinner.hide();
            return throwError(x); // Return the error as an observable
        }
    }

    ScriptAction(id: string, action: string): Observable<any[]> {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            return this._http.post<any[]>(myGlobals._apiPathv3 + 'script-repos/Scripts/' + action + '/' + id, false, { headers: this._Global.httpOptions })
                .pipe(
                    tap((data: any) => {
                        if(!data.status){
                            this.spinner.hide();
                            this._Global.IsReady = true;
                            this.toastService.error('',data.message, this.toastOptions);
                            throw new Error(data.message);
                        } else {
                            this._Global.IsReady = true;
                            this.spinner.hide();
                            this.toastService.success('',data.message, this.toastOptions);
                        }
                    }),
                    catchError(error => {
                        this._Global.handleError(error);
                        this.spinner.hide();
                        this._Global.IsReady = true;
                        return throwError(error); // Return the error as an observable
                    })
                );
        } catch (x) {
            this.toastService.error('',x, this.toastOptions);
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide();
            return throwError(x); // Return the error as an observable
        }
    }

    // GetVariablesList(): Observable<any[]> {
    //     return new Observable<any[]>((observer) => {
    //         try {
    //             this.spinner.show();
    //             this._Global.IsReady = false;
    //             this._http.get<any[]>(myGlobals._apiPathv3 + 'defined-items', { headers: this._Global.httpOptions })
    //                 .subscribe(data => {
    //                     this._VariablesList = data;
    //                     this.spinner.hide();
    //                     this._Global.IsReady = true;
    //                     observer.next(data); // Emit the data
    //                     observer.complete(); // Complete the observable
    //                 },
    //                 error => {
    //                     this._Global.handleError(error);
    //                     this.spinner.hide();
    //                     this._Global.IsReady = true;
    //                     observer.error(error); // Emit error
    //                 });
    //         } catch (x) {
    //             this._Global.showErrorMsg(x);
    //             this._Global.IsReady = true;
    //             this.spinner.hide();
    //             observer.error(x); // Emit error
    //         }
    //     });
    // }


    // GetVariablesList(): Observable<any[]> {
    //     return this._http.get<any[]>(myGlobals._apiPathv3 + 'defined-items', { headers: this._Global.httpOptions });
    // }

    GetInComingMessagesList(searchString: string, _emailTemplateId: number, siteId: number, studyId: number, startDate: string, endDate: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Templates?getType=GetIncomingMessagesList&searchString=' + searchString + '&_emailTemplateId=' + _emailTemplateId
                + '&siteId=' + siteId + '&studyId=' + studyId + '&startDate=' + startDate + '&endDate=' + endDate,
                { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._incomingMessagesList = <InComingMessagesList[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetOutgoingMessagesList(_emailTemplateId: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Templates?getType=GetOutgoingMessagesList&_emailTemplateId=' + _emailTemplateId,
                { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._outgoingMessagesList = <InComingMessagesList[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetAllEmailConfiguration() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Templates?getType=GetAllEmailConfiguration', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._emailConfigList = <EmailConfiguration[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetEmailConfigurationRemindersByConfigId(_emailConfigurationId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Templates?getType=GetEmailConfigurationRemindersByConfigId&_emailConfigurationId=' + _emailConfigurationId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._configReminderList = <EmailConfigurationReminders[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetEmailConfigurationListByTemplateId(_emailTemplateId: number): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'Templates?getType=GetEmailConfigurationsByTemplateId&_emailTemplateId=' + _emailTemplateId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetEmailConfigurationsByTemplateId(_emailTemplateId: number, openDialog: boolean) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Templates?getType=GetEmailConfigurationsByTemplateId&_emailTemplateId=' + _emailTemplateId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._emailCondfigByTemplateList = <EmailConfiguration[]>data;
                    this.IsGetEmailConfig.next(openDialog)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.IsGetEmailConfig.next(false)
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this.IsGetEmailConfig.next(false)
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    SaveEmailTemplate(body: EmailTemplate) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Templates/AddEmailTemplate', body, { headers: this._Global.httpOptions })
                .subscribe((data: EmailTemplate) => {
                    this._Global.showSuccessMsg("Template Saved Successfully")
                    this.isCompleted = true
                    this.templateId = data.Id
                    let lovObject = this.lovType.filter(o => o.Id == data.TypeId)[0]
                    // if (data.TypeId == 39) {
                    //     this.showNotificationDate = false
                    //     this.showCampaignFields = true
                    // }
                    // else {
                    //     this.showNotificationDate = true
                    //     this.showCampaignFields = false
                    // }
                    if (lovObject.Name == "Marketing") {
                        this.showNotificationDate = true
                        this.showCampaignFields = true
                    }
                    else {
                        this.showNotificationDate = false
                        this.showCampaignFields = false
                    }
                    this.templateObject = data
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.GetEmailTemplate('')
                    this.IsSaveEmailTemplate.next(true)
                },
                    error => {
                        this.isCompleted = false
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveEmailTemplate.next(false)
                    });
        } catch (x) {
            this.isCompleted = false
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.IsSaveEmailTemplate.next(false)
        }
    }

    SaveEmailConfiguration(body: EmailConfiguration, isForce: boolean) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Templates/AddEmailConfiguration?isForce=' + isForce, body, { headers: this._Global.httpOptions })
                .subscribe((data: EmailConfiguration) => {
                    this._Global.showSuccessMsg("Configuration Saved Successfully")
                    this.GetEmailConfigurationsByTemplateId(data.EmailTemplateId, false)
                    this.GetEmailTemplate('')
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.IsSaveEmailConfig.next(true)
                }, error => {
                    //debugger
                    if (error && error.error.Value && error.error.Value.status && error.error.Value.status == 'Status, Site And Study Already Exists') {
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.ConfirmDialog(body, false)
                    }
                    else {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveEmailConfig.next(false)
                    }
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.IsSaveEmailConfig.next(false)
        }
    }

    EditEmailTemplate(body: EmailTemplate) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'Templates/EditEmailTemplate', body, { headers: this._Global.httpOptions })
                .subscribe((data: EmailTemplate) => {
                    this._Global.showSuccessMsg("Template Update Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.IsEditEmailConfig.next(true)
                    this.GetEmailTemplate('')
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsEditEmailConfig.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    EditEmailConfiguration(body: EmailConfiguration, isForce: boolean) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'Templates/EditEmailConfiguration?isForce=' + isForce, body, { headers: this._Global.httpOptions })
                .subscribe((data: EmailConfiguration) => {
                    this._Global.showSuccessMsg("Configuration Update Successfully")
                    this.GetEmailConfigurationsByTemplateId(data.EmailTemplateId, false)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.IsSaveEmailConfig.next(true)
                }, error => {
                    //debugger
                    if (error && error.error.Value && error.error.Value.status && error.error.Value.status == 'Status, Site And Study Already Exists') {
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.ConfirmDialog(body, true)
                    }
                    else {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.IsSaveEmailConfig.next(false)
                    }
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.IsSaveEmailConfig.next(false)
        }
    }

    ConfirmDialog(body: any, isEdit: boolean) {
        swal({
            title: "Status, Site And Study Already Exists",
            text: "Are you sure. You want to save?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            confirmButtonText: 'Save',
            buttonsStyling: false
        }).then((result) => {
            if (result.value) {
                if (!isEdit) {
                    this.SaveEmailConfiguration(body, true)
                }
                else {
                    this.EditEmailConfiguration(body, true)
                }
                console.log("Yes")
            }
            else {
                console.log("No")
            }
        })
    }
}