import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-addciostudy',
  templateUrl: './addciostudy.component.html',
  styleUrls: ['./addciostudy.component.scss']
})
export class AddciostudyComponent implements OnInit {
  CRIOStudyForm: FormGroup;
  submitted: boolean = false;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };
  constructor(public dialogRef: MatDialogRef<AddciostudyComponent>, public fb: FormBuilder, public _studyService: StudyService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.CRIOStudyForm = this.fb.group({
      CRIOStudyId: [[], Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', {
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.CRIOStudyForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.CRIOStudyForm.invalid) {
      return;
    }
    else {
      let CRIOStudyId = this.CRIOStudyForm.controls.CRIOStudyId.value
      let value = CRIOStudyId[0].Id
      this._studyService.CreateNewCRIOStudy(value)
      this._studyService.isAddCrioStudy.subscribe(res => {
        if (res) {
          this.initForm()
        }
      })
    }
  }
}
