import { ExcelService } from './../../../Shared/Services/FrontServices/Export/excel.service';
import { ActionType, CallListType } from './../../../Shared/Models/DMCREnums/DMCREnums';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { PatientInteractionDTO } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrescreenerService } from '@sharedservices/BackServices/ComTrak/Prescreeners/prescreener.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { StudyvisitService } from '@sharedservices/BackServices/ComTrak/Setups/studyvisit.service';
import { MatDialog, MatDialogRef, MatPaginator } from '@angular/material';
import { CallList } from '@sharedmodels/BackModels/ComTrak/CallLists/CallList';
import { RecruitercalllistMarkComponent } from '@app/pages/recruitercalllist-mark/recruitercalllist-mark.component';
import { RecruitercalllistNotesComponent } from '@app/pages/recruitercalllist-notes/recruitercalllist-notes.component';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { CommandClickEventArgs, CommandModel, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { ColDef } from 'ag-grid-community';
import { CreatepatientComponent } from '@app/pages/createpatient/createpatient.component';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { trigger, state, style, transition, animate, AUTO_STYLE } from '@angular/animations';
import { CreatevaccineComponent } from '@app/pages/createvaccine/createvaccine.component';
import { bottomtotop, fadeInOut } from '@app/animation';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { PatientDetailComponent } from '../patientdetail/patientdetail.component';
import { DatePipe } from '@angular/common';
import { DaterangepickerComponent } from '../../components/daterangepicker/daterangepicker.component';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-prescreenercalllist',
  templateUrl: './prescreenercalllist.component.html',
  styleUrls: ['./prescreenercalllist.component.scss',
    '../../../assets/comtrak-ui-system-v-0.1/sass/screens/_recruitersList.scss'],
  animations: [
    fadeInOut, bottomtotop
  ],
})
export class PrescreenercalllistComponent implements OnInit {
  @ViewChild(DaterangepickerComponent) dateRangPicker: DaterangepickerComponent;
  format: string = 'M/d/yyyy';
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('StartDate') StartDate: any;
  @ViewChild('EndDate') EndDate: any;
  @ViewChild('markInteraction') markInteraction: TemplateRef<any>;
  @ViewChild('editPatient') editPatient: TemplateRef<any>;
  @ViewChild('note') note: TemplateRef<any>;
  search: string
  pageSize: number = 20;
  pagenumber: number;
  statusId: any = '-1'
  startDate: any = null;
  isMoreData:boolean = true;
  selectedDateRange: { startDate: Date, endDate: Date };
  endDate: any = null;
  page: number = 1;
  paginationArray = [];
  collectionSize: number;
  columnDefs: ColDef[]
  public GridApi: any;
  public gridColumnApi: any;
  public defaultColDef;
  columnDefinition: CustomGridColumns[];
  DefaultView: CustomGridColumns[];
  showRecord: boolean = false;
  public commands: any[];
  showPatientDetailDialog = false;
  patientDetail: RowSelectEventArgs;
  dialogRefs: MatDialogRef<any>[] = [];

  studyList: Study[];
  subscriptions = new Subscription();
  dialogOpenFlag = false;

  constructor(public _PrescreenerService: PrescreenerService, public _studyService: StudyService, private scriptService: ScriptService, public _RecruiterService: RecruiterService,
    public _StatusService: StatusService, public _SiteService: SiteService, public _PatientInteractionService: PatientInteractionService,
    public _StudyVisitService: StudyvisitService, public dialog: MatDialog, public spinner: NgxSpinnerService, private excelService: ExcelService,
    public PrescreenList: PrescreenService, private socketService: SocketHandlerService,
    private fb: FormBuilder, public _Global: GlobalfunctionService, public _PatientService: PatientService, public _lovService: LovService,
    public locationService: LocationService, public campaignRefSourceService: CampaignReferralSourceService, private datePipe: DatePipe) {
    this.commands = [
      { title: 'sub-menu', type: 'None', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-contact-center' } },
      { title: 'Add Mark Interaction', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-plus' } },
      { title: 'Edit Mark Interaction.', type: 'Delete', buttonOption: { content: '', cssClass: "trash", iconCss: "e-icons e-hyperlink-edit" } },
      { title: 'Add Notes', type: 'Delete', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-file' } },
      { title: 'Add Vaccine Detail', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-calculated-member' } },
      { title: 'Internal Chat', type: 'Chat', buttonOption: { content: '', cssClass: 'custom-bold', iconCss: 'e-icons e-message' } },
    ];


    const openScriptDialogSub = this.scriptService.openScriptDialog.subscribe(res => {
      this.dialogOpenFlag = res.openFlag;
      res.openFlag ? this.openModal() : this.closeModal();
    });
    this.subscriptions.add(openScriptDialogSub);
  }
  type: FormGroup = this.fb.group({
    study: ['-1', null],
    site: ['-1', null],
    status: ['-1', null],
    studyvisit: ['-1', null],
    startDate: ['', null],
    endDate: ['', null],
    statusId: ['-1', null],
    search: ['', null],
    get title() {
      return this._title;
    },
    set title(value) {
      this._title = value;
    },
  });
  async ngOnInit() {


    const openScriptDialogSub = this.scriptService.openScriptDialog.subscribe(res => {
      this.dialogOpenFlag = res.openFlag;
      res.openFlag ? this.openModal() : this.closeModal();
      setTimeout(() => {
        this._PrescreenerService._callListArray = [...this._PrescreenerService._callListArray];
      }, 0);
    });
    this.subscriptions.add(openScriptDialogSub);


    try {
      console.log('2nd 2nd');
      console.log(this._PrescreenerService._callListArray);
      // this._studyService.LoadStudies('');
      this.gridConfig()
      this.late();
      this._PrescreenerService.arraylength = 0
      this.collectionSize = 0
      this._PrescreenerService._callListArray = undefined
      this._PrescreenerService._callList = undefined
      this.initForm();
      this._lovService.GetSMSMaster()
      await this.getActiveStudies()
      this._StatusService.GetStatusByType(CallListType.PreScreener);

      //handling studylist, to be replaced with proper obserables
      this.subscriptions.add(
        this._SiteService.sitesStudy.subscribe(
          (studies: Study[]) => this.studyList = studies
        )
      );

      this._SiteService.GetActiveSites();
      this._SiteService._sitesStudy = this._studyService._activeStudies;

      this.locationService.getAllState()
      this._StatusService.GetStatus()
      this.locationService.getAllCountry();
      this.campaignRefSourceService.GetAllCampaignReferralSourceAdDetails()
      this.campaignRefSourceService.LoadAllCampaignReferralSource();
      this.campaignRefSourceService.LoadCampaignReferralSource();
      // this._StudyVisitService.GetStudyVisits();
      // this._PrescreenerService.GetPreScreenerCallList();
      // this._PatientInteractionService.isCallApiPreScreen.subscribe(res => {
      //   if (res) {
      //     this.Search(0, 0)
      //   }
      // })
      // this._PrescreenerService.isCallApi.subscribe(res => {
      //   if (res) {
      //     this.collectionSize = this._PrescreenerService.arraylength
      //     this.refreshData(this.page)
      //   }
      // })


    } catch (x) {
      console.log('error on prescreener ngonit=' + x);
    }
  }

  openModal(): void {
    // Open the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.add('show');
      modal.setAttribute('aria-modal', 'true');
      modal.style.display = 'block';
    }
  }

  closeModal(): void {
    // Close the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      modal.style.display = 'none';
    }
  }

  gridConfig() {
    this.columnDefinition = [
      {
        header: 'Cmtrk Id',
        key: "Id",

        visible: true,
        type: "string",
      },
      {
        header: 'Crio ID',
        key: "CrioId",

        visible: true,
        type: "string",
      },
      {
        header: 'Patient Name',
        key: "FullName",

        visible: true,
        type: "string",
      },
      {
        header: 'Age',
        key: "Age",

        visible: true,
        type: "string",
      },
      {
        header: 'Phone',
        key: "Phone",

        visible: true,
        type: "string",
      },
      {
        header: 'State',
        key: "State",

        visible: true,
        type: "string",
      },
      {
        header: 'Race',
        key: "Race",

        visible: true,
        type: "string",
      },
      {
        header: 'Ethinicity',
        key: "Ethinicity",

        visible: true,
        type: "string",
      },
      {
        header: 'Site',
        key: "SiteName",

        visible: true,
        type: "string",
      },
      {
        header: 'Study',
        key: "StudyName",

        visible: true,
        type: "string",
      },
      {
        header: 'Status',
        key: "Status",

        visible: true,
        type: "string",
      },
      {
        header: 'SubStatus',
        key: "SubStatus",

        visible: true,
        type: "string",
      },
      {
        header: 'Confirmation Status',
        key: "ConfirmationStatus",

        visible: true,
        type: "string",
      },
      {
        header: 'Next Visit Date',
        key: "DateofPreScreenScheduled",

        visible: true,
        type: "string",
      },
      {
        header: 'Next Visit Time',
        key: "TimeofPreScreenScheduled",

        visible: true,
        type: "string",
      },
      {
        header: 'CrioLink',
        key: "CrioLink",

        visible: false,
        type: "string",
      },
    ]
    this.DefaultView = this.columnDefinition;
    // this.defaultColDef = this._Global.defaultColDef;
    // this.columnDefs = [
    //   {
    //     headerName: 'Mark Int.',
    //     colId: 'markint',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.markInteraction
    //     }
    //   },
    //   {
    //     headerName: 'Notes',
    //     colId: 'notes',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.note
    //     }
    //   },
    //   {
    //     headerName: 'Edit',
    //     colId: 'edit',
    //     width: 80,
    //     minWidth: 80,
    //     maxWidth: 80,
    //     cellRendererFramework: TemplaterenderComponent,
    //     cellRendererParams: {
    //       ngTemplate: this.editPatient
    //     }
    //   },
    //   { headerName: 'Cmtrk Id', field: 'Id', sortable: true, filter: true },
    //   {
    //     headerName: 'Crio ID', field: 'CrioId',
    //     sortable: true,
    //     filter: true,
    //     cellRenderer: function (params) {
    //       let CrioLink = params.data.CrioLink + params.value
    //       return `<a href="${CrioLink}"  target="_blank" rel="noopener" >${params.value}</a>`
    //       // return `<a href="https://app.clinicalresearch.io/dm-clinical-research/tcdd/patient/${params.value}"  target="_blank" rel="noopener" >${params.value}</a>`
    //     },
    //   },
    //   {
    //     headerName: 'Pat name', field: 'FullName', sortable: true, filter: true,
    //     minWidth: 280,
    //     maxWidth: 900,
    //   },
    //   { headerName: 'Age', field: 'Age', sortable: true, filter: true },
    //   { headerName: 'Phone', field: 'Phone', sortable: true, filter: true },
    //   { headerName: 'State', field: 'State', sortable: true, filter: true },
    //   { headerName: 'Race', field: 'Race', sortable: true, filter: true },
    //   { headerName: 'Ethinicity', field: 'Ethnicity', sortable: true, filter: true },
    //   { headerName: 'Site', field: 'SiteName', sortable: true, filter: true },
    //   { headerName: 'Study', field: 'StudyName', sortable: true, filter: true },
    //   { headerName: 'Status', field: 'Status', sortable: true, filter: true },
    //   { headerName: 'Next Visit Date', field: 'DateofPreScreenScheduled', sortable: true, filter: true },
    //   { headerName: 'Next Visit Time', field: 'TimeofPreScreenScheduled', sortable: true, filter: true },

    // ];
  }

  initForm() {
    this.type = this.fb.group({
      study: ['-1', null],
      site: ['-1', null],
      status: ['-1', null],
      studyvisit: ['-1', null],
      startDate: ['', null],
      endDate: ['', null],
      statusId: ['-1', null],
      search: ['', null],
      responseId: ['-1', null],

    })
  }

  OnReset() {
    this.initForm();
    this._SiteService.sitesStudy.next([]);
    //this.StartDate.dateString = ""
    //this.EndDate.dateString = ""
    this.dateRangPicker.clearDateRange();
    this.dateRangPicker.dateRangeEnabled = true;
  }

  clickSearch() {
    try {
      this.paginator.firstPage();
    } catch (X) { }

    if (this.type.controls.site.value == '-1') {
      this._Global.showErrorMsg("Please Select Site")
    }
    else if (this.dateRangPicker.dateRangeEnabled && !this.dateRangPicker.formObject.validate()) {
      return;
    }
    else {
      this.Search(0, 0);
    }
  }
  getPage(page) {
    this.pageSize = page.pageSize
    this.pagenumber = page.pageIndex + 1
    this.Search(page.pageSize, page.pageIndex + 1);
  }
  ChangeSite() {
    this.type.controls.study.setValue(-1);
    if (this.type.controls.site.value != '-1') {
      this._SiteService.GetStudiesBySite(this.type.controls.site.value, CallListType.PreScreener)
    } else {
      this._SiteService._sitesStudy = this._studyService._activeStudies;
      this._SiteService.sitesStudy.next([]);
    }
  }
  getMatch(a: Study[], b: Study[]): Study[] {
    try {
      let matches: Study[] = [];
      a.forEach(elem => {
        if (b.findIndex(x => x.Id === elem.Id) >= 0) {
          matches.push(elem);
        }
      });

      return matches;
    } catch (e) {

    }
  }

  filterStudies(): Study[] {
    try {
      if (this._SiteService._sitesStudy.length > 0) {
        const studiesonsite = this.getMatch(this._studyService._activeStudies, this._SiteService._sitesStudy);
        return studiesonsite;
      }
    } catch (Err) {
      return this._studyService._activeStudies;
    }

  }
  ChangeStudy() {
    // if (this.type.controls.study.value != '-1') {
    //   this._SiteService.GetSitesByStudy(this.type.controls.study.value)
    // }
    // debugger;
  }

  GetPreScreenerCallList() {
    this._PrescreenerService.GetPreScreenerCallList(this._PatientService.studyid, this._PatientService.siteid,
      this._PatientService.statusid, this._PatientService.startDate, this._PatientService.endDate, 0, 0,
      this._PatientService.searchText, this._PatientService.responseId);
  }

  Search(pageSize: number, pageNo: number) {
    // debugger
    // let studyid = this.type.controls.study.value;
    // let siteid = this.type.controls.site.value;
    // let statusid = this.type.controls.status.value;
    // let startDate = this.type.get('startDate').value; this.startDate
    // let studyvisit = this.type.controls.studyvisit.value;
    // let endDate = this.type.get('endDate').value; this.startDate
    // let searchText = this.type.controls.search.value;

    this._PatientService.studyid = this.type.controls.study.value || '-1';
    this._PatientService.siteid = this.type.controls.site.value;
    this._PatientService.statusid = this.type.controls.status.value;
    if (!this.dateRangPicker.dateRangeEnabled) {
      this._PatientService.startDate = "";
    }
    else {
      this._PatientService.startDate = this.startDate == undefined ? "" : this.startDate;
    }
    this._PatientService.studyvisit = this.type.controls.studyvisit.value;

    if (!this.dateRangPicker.dateRangeEnabled) {
      this._PatientService.endDate = "";
    }
    else {
      this._PatientService.endDate = this.endDate == undefined ? "" : this.endDate;
    }
    this._PatientService.searchText = this.type.controls.search.value;
    this._PatientService.responseId = this.type.controls.responseId.value;

    this._PrescreenerService.GetPreScreenerCallList(this._PatientService.studyid, this._PatientService.siteid,
      this._PatientService.statusid, this._PatientService.startDate, this._PatientService.endDate, pageNo, pageSize,
      this._PatientService.searchText, this._PatientService.responseId);
  }

  ChangeStartDate($event) {
    this.startDate = $event
    if (this.startDate > this.endDate) {
      this._Global.showErrorMsg("Start Date cannot be greater then End Date ")
      this.type.controls.startDate.setValue(new Date())
    }
    else {
      return false;
    }
  }

  ChangeEndDate($event) {
    this.endDate = $event
    if (this.endDate < this.startDate) {
      this._Global.showErrorMsg("End Date cannot be less then Start Date ")
      this.type.controls.endDate.setValue(new Date())
    }
    else {
      return false;
    }
  }

  OpenNewDialog(row: any) {
    this.spinner.show()
    this._PatientService.GetPatientDetailById(row.Id).subscribe(res => {
      const dialogRef = this.dialog.open(CreatepatientComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        // panelClass: ['dailogwidth', 'animate__animated', 'animate__slideInDown'],

        disableClose: true,
        data: {
          callList: <Patient>res,
          screenType: CallListType.PreScreener
        }
      })
      // .afterClosed().subscribe(resData => {
      //   this._PatientService.isCallApiPreScreen.subscribe(res => {
      //     if (res) {
      //       this.Search(0, 0)
      //     }
      //   })
      // });
      this.spinner.hide()
    }, error => {
      this._Global.handleError(error);
      this.spinner.hide()
    })
  }

  OpenMarkDialog(row: Patient, isEdit: boolean) {
    this.spinner.show()
    this._PatientInteractionService.GetInteractions(CallListType.PreScreener, row.Id, row.StatusId, row.LastInteractionId).subscribe(res => {
      const dialogRef = this.dialog.open(RecruitercalllistMarkComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <PatientInteraction>res,
          screenType: CallListType.PreScreener,
          IsDNQ: false,
          isEdit: isEdit,
          formPreview : true,
          lastInterationStatus: row.Status ? row.Status : "",
          lastInterationStatusId: row.StatusId ? row.StatusId : "",
          rowData: row,
        }
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }
  OpenNotesDialog(row: any, pageNo = 1) {
    let params = new HttpParams()
    .append('PageNo', String(pageNo))
    this.spinner.show()
    forkJoin([this._PatientInteractionService.GetPatientInteractions(row.Id),
    this._PatientInteractionService.GetInteractions(CallListType.PatientNotes, row.Id, row.StatusId, row.LastInteractionId),
    this._PatientService.GeCommunicationHistoryById(row.Id, params)]).subscribe(res => {
      let array = [
        { PatientInteraction: <PatientInteractionDTO>res[0], patientInfo: <Patient>res[1], patientList: res[2] }
      ]
      this.isMoreData =  res[2].pagination.isMore;
      const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        // panelClass: ['dailogwidth', 'animate__animated', 'animate__slideInDown'],

        disableClose: true,
        data: {
          PatientInteraction: array,
          hideAddNotes: true,
          rowData: row,
          isMoreData:this.isMoreData,
        }
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }

  ChangeStatus(row) {
    if (this.statusId != '-1') {
      if (confirm("Are you sure ? You want to change call status.") == true) {
        this._Global.IsReady = false
        this.spinner.show()
        this._PatientInteractionService.AddPatientIntercationChangeStatus(row.Id, row.StatusId, this.statusId).subscribe(res => {
          // this.Search(this.pagenumber ? this.pagenumber : 1, this.pageSize ? this.pageSize : 20)
          this.Search(0, 0)
          this._Global.IsReady = true
          this.spinner.hide()
          this.statusId = '-1'
          this._Global.showSuccessMsg("Interaction Add Successfully")
        }, error => {
          this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
          this._Global.IsReady = true
          this.spinner.hide()
          this.statusId = '-1'
        })
      }
      else {
        this.statusId = '-1'
        this._Global.IsReady = true;
        this.spinner.hide()
      }
    }
  }

  PrintElem(elem) {
    this.excelService.PrintElem('Pre-screening Call List', elem)
    // Popup('Pre-screening Call List', $(elem).html());
  }

  ExportToExcel() {
    // try {

    //   let studyid = this.type.controls.study.value;
    //   let siteid = this.type.controls.site.value;
    //   let statusid = this.type.controls.status.value;
    //   let startDate = this.startDate
    //   let endDate = this.endDate
    //   let searchText = this.type.controls.search.value;
    //   this._PrescreenerService.LoadPreScreenerCallList(studyid, siteid, statusid, startDate, endDate, 0, 0, searchText).subscribe(data => {
    //     //    this._callList = <CallList[]>data;
    //     this.excelService.exportAsExcelFile(<CallList[]>data, 'Pre-screening Call List')
    //     this._Global.IsReady = true;
    //     this.spinner.hide();
    //   },
    //     error => {

    //       console.log(JSON.stringify(error));
    //       this._Global.handleError(error);
    //       this.spinner.hide();
    //     });

    //   //  this.excelService.exportAsExcelFile(this._PrescreenerService._callList, 'Pre-screening Call List')
    // } catch (x) {
    //   // alert('error=' + x);
    // }
    this.excelService.exportAsExcelFile(this._PrescreenerService._callListArray, 'Pre-screening Call List')
  }

  // refreshData($event) {
  //   let currentPage = $event == undefined ? this.page : $event
  //   let pageSize = Number(this.pageSize)
  //   this._PrescreenerService._callList = this._PrescreenerService._callListArray.map((data, i) => ({
  //     id: i + 1, ...data
  //   })).slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
  // }

  // DownloadAsPDF() {
  //   this.excelService.DownloadAsPDF('Pre-screening Call List', this.pdfTable)
  // }

  onGridReady(params: any) {
    this.GridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.GridApi.setDatasource(this._PrescreenerService._callListArray);
  }

  onPageSizeChanged(event: any) {
    this.GridApi.paginationSetPageSize(Number(event.target.value));
  }

  onBtExport() {
    this.GridApi.exportDataAsCsv();
  }

  destroyFilter() {
    this.columnDefs.forEach(element => {
      this.GridApi.destroyFilter(element.field);
    })
  }

  actionClick(data: any): void {

    if (data.type === 'call' || data.type === 'sms' || data.type === 'email') {
      this._RecruiterService.contactCenterFlag.next(true);
      this._RecruiterService.contactCenterType.next(data.type);
      this._RecruiterService.contactCenterRowData.next(data.rowData);
      this._PatientService.SetPatientId(data.rowData.Id);
      this._PatientService.SetRowData(data.rowData);
    } else if(data.command) {
      this.commandClick({commandColumn: {...data.command,  name: "commandClick"}, rowData: data.rowData, cancel: false,})
    }
  }

  commandClick(args: any): void {
    this.showPatientDetailDialog = false;
    if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.MarkInteraction) {
      this.OpenMarkDialog(args.rowData, false)
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Notes) {
      this.OpenNotesDialog(args.rowData)
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Edit) {
      this.OpenNewDialog(args.rowData)
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.EditMarkInt) {
      var rowData: any = args.rowData
      var statusObj = this._StatusService._status.find(o => o.Id == rowData.StatusId)
      if (statusObj && statusObj.Roles && statusObj.Roles.filter(x => x.StatusId === rowData.StatusId && ((x.SubStatusId === (rowData.SubStatusId ? rowData.SubStatusId : null)) || x.SubStatusId === null))[0].IsEdit) {
        this.OpenMarkDialog(args.rowData, true)
      }
      else {
        this._Global.showErrorMsg("This interaction is not editable")
      }
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.CreateVaccine) {
      this.OpenVaccineDialog(args.rowData, 'EditSave')
    } else if (args.commandColumn && args.commandColumn.buttonOption && args.commandColumn.buttonOption.iconCss === ActionType.Chat) {
      this.socketService.emitChatJoinRoomEvent('prescreen_list', `prescreen_list_${args.rowData.Id}`);
      localStorage.setItem('entity_name', 'prescreen_list');
      localStorage.setItem('entity_title', args.rowData.FullName);
      localStorage.setItem('entity_id', `prescreen_list_${args.rowData.Id}`);
      this.PrescreenList.openChatBox.next(true);
    }
  }
  late() {
    setTimeout(() => {
      this.showRecord = true
    }, 10000);
  }
  CreateVaccineDialog(row, actionType) {
    //debugger;
    const dialogRef = this.dialog.open(CreatevaccineComponent, {
      panelClass: ['Createvaccinedailogwidth', 'comtrak-modal'],
      disableClose: true,
      data: {
        callList: row,
        actionType: actionType
      }
    })
  }
  OpenVaccineDialog(row, actionType) {
    //debugger;
    if (row == undefined) {
      this.CreateVaccineDialog(undefined, actionType)
    }
    else {
      this.CreateVaccineDialog(row, actionType)
    }
  }
  onShowPatientDatailSideDialog(showDialog: boolean) {
    if (showDialog) {
      this.showPatientDetailDialog = true;
    }
  }
  onEventCatch(args: RowSelectEventArgs) {
    if (args.target.className.includes('e-rowcell')) {
      const dialogRef = this.dialog.open(PatientDetailComponent, {
        panelClass: ['rightside-modal', 'comtrak-modal'],
        disableClose: false,
        data: { ...args.data, screenType: CallListType.PreScreener },
      })
      this.dialogRefs.push(dialogRef);
      dialogRef.backdropClick().subscribe(() => {
        this.closeAllDialogs();
      });
    }
  }
  closeAllDialogs() {
    for (const dialogRef of this.dialogRefs) {
      dialogRef.close();
    }
    this.dialogRefs = [];
  }


  onDateRangeChange(dateRange: { startDate: Date, endDate: Date }): void {
    // debugger
    this.startDate = this.datePipe.transform(dateRange.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(dateRange.endDate, "yyyy-MM-dd");
  }
  onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    let query = new Query();
    let stitedata: any[] = this._SiteService._sitesStudy;
    query = (e.text != "") ? query.where("Name", "Contains", e.text, true) : query;
    e.updateData(stitedata, query);
  };

  async getActiveStudies() {
    await this._studyService.GetActiveStudies();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

  onSearchChange(event: any) {
    if (event.target.value.trim() === '') {
      this.dateRangPicker.dateRangeEnabled = true;
    } else {
      this.dateRangPicker.dateRangeEnabled = false;
    }
  }
}

