import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { ListMaster } from '@sharedmodels/BackModels/ComTrak/DBLM/DBLM';
import { DBLMService } from '@sharedservices/BackServices/ComTrak/DBLM/dblm.service';
import { CreateinclusionComponent } from '../createinclusion/createinclusion.component';

@Component({
  selector: 'app-dblmstepper',
  templateUrl: './dblmstepper.component.html',
  styleUrls: ['./dblmstepper.component.scss', '../../style.component.scss'],
  providers: [CreateinclusionComponent]
})
export class DblmstepperComponent implements OnInit {
  @ViewChild('stepper') public myStepper: MatStepper;
  listMasterObject: ListMaster;
  IsInclusion: boolean
  isDisabledAllFields: boolean = false;
  constructor(public dialogRef: MatDialogRef<DblmstepperComponent>, public dblmService: DBLMService, @Inject(MAT_DIALOG_DATA) public data: any,
    public _CreateinclusionComponent: CreateinclusionComponent) { }

  ngOnInit() {
    this.dblmService._dblmCount = 0
    if (this.data && this.data.dataObject) {
      this.isDisabledAllFields = this.data.isDisabledAllFields
      this.listMasterObject = this.data.dataObject
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  goForward() {
    this.myStepper.next();
  }

  onStepChange() {
    if (this._CreateinclusionComponent.InclusionForm && this._CreateinclusionComponent.InclusionForm.value) {
      this.dblmService.InclusionValue = this._CreateinclusionComponent.InclusionForm.value
    }
  }

}
