import { Component, OnDestroy, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { RoutesService } from '@sharedservices/BackServices/ComTrak/Security/routes.service';
import { Routes } from '@sharedmodels/BackModels/ComTrak/Security/Routes';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';
import { CommonService } from '@sharedservices/FrontServices/Common/common.service';
import { Subscription } from 'rxjs';
import { HelperService } from '@sharedservices/helper.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommunicationService } from '@sharedservices/BackServices/ComTrak/Communication/communication.service';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { TwilioService } from '@sharedservices/FrontServices/twilio.service';
import { environment } from 'environments/environment';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] =
    [
        // {
        //     path: '/dashboard',
        //     title: 'Dashboard',
        //     type: 'link',
        //     icontype: 'dashboard'
        // },
        //
        //
        //  {
        //     path: '/flavours',
        //     title: 'Flavours',
        //     type: 'link',
        //     icontype: 'local_pizza'

        // },
        //  {
        //     path: '/products',
        //     title: 'Products',
        //     type: 'link',
        //     icontype: 'room_service'

        // },
        // {
        //     path: '/toppings',
        //     title: 'Toppings',
        //     type: 'link',
        //     icontype: 'category'

        // },
        //  {
        //     path: '/toppingproduct',
        //     title: 'Toppings By Product',
        //     type: 'link',
        //     icontype: 'layers'
        // },

        // {
        //     path: '/deals',
        //     title: 'Deals',
        //     type: 'link',
        //     icontype: 'grid_on'

        // },
        // {
        //         path: '/discounts',
        //         title: 'Discounts',
        //         type: 'link',
        //         icontype: 'widgets'

        //     },

        // {
        //     path: '/till',
        //     title: 'Till',
        //     type: 'link',
        //     icontype: 'attach_money'
        // },
        // {
        //     path: '/changepassword',
        //     title: 'Change Password',
        //     type: 'link',
        //     icontype: 'widgets'

        // }
        // }, {
        //     path: '/size',
        //     title: 'Size',
        //     type: 'link',
        //     icontype: 'layers'

        // }, {
        //     path: '/type',
        //     title: 'Type',
        //     type: 'link',
        //     icontype: 'layers'

        // }
        // , {
        //     path: '/components',
        //     title: 'Components',
        //     type: 'sub',
        //     icontype: 'apps',
        //     collapse: 'components',
        //     children: [
        //         { path: 'buttons', title: 'Buttons', ab: 'B' },
        //         { path: 'grid', title: 'Grid System', ab: 'GS' },
        //         { path: 'panels', title: 'Panels', ab: 'P' },
        //         { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
        //         { path: 'notifications', title: 'Notifications', ab: 'N' },
        //         { path: 'icons', title: 'Icons', ab: 'I' },
        //         { path: 'typography', title: 'Typography', ab: 'T' }
        //     ]
        // } //, // {
        //     path: '/forms',
        //     title: 'Forms',
        //     type: 'sub',
        //     icontype: 'content_paste',
        //     collapse: 'forms',
        //     children: [
        //         { path: 'regular', title: 'Regular Forms', ab: 'RF' },
        //         { path: 'extended', title: 'Extended Forms', ab: 'EF' },
        //         { path: 'validation', title: 'Validation Forms', ab: 'VF' },
        //         { path: 'wizard', title: 'Wizard', ab: 'W' }
        //     ]
        // }, {
        //     path: '/tables',
        //     title: 'Tables',
        //     type: 'sub',
        //     icontype: 'grid_on',
        //     collapse: 'tables',
        //     children: [
        //         { path: 'regular', title: 'Regular Tables', ab: 'RT' },
        //         { path: 'extended', title: 'Extended Tables', ab: 'ET' },
        //         { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' }
        //     ]
        // }, {
        //     path: '/maps',
        //     title: 'Maps',
        //     type: 'sub',
        //     icontype: 'place',
        //     collapse: 'maps',
        //     children: [
        //         { path: 'google', title: 'Google Maps', ab: 'GM' },
        //         { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
        //         { path: 'vector', title: 'Vector Map', ab: 'VM' }
        //     ]
        // }, {
        //     path: '/widgets',
        //     title: 'Widgets',
        //     type: 'link',
        //     icontype: 'widgets'

        // }
        // , {
        //     path: '/charts',
        //     title: 'Charts',
        //     type: 'link',
        //     icontype: 'timeline'

        // }, {
        //     path: '/calendar',
        //     title: 'Calendar',
        //     type: 'link',
        //     icontype: 'date_range'
        // }
        // , {
        //     path: '/pages',
        //     title: 'Pages',
        //     type: 'sub',
        //     icontype: 'image',
        //     collapse: 'pages',
        //     children: [
        //         { path: 'pricing', title: 'Pricing', ab: 'P' },
        //         { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
        //         { path: 'login', title: 'Login Page', ab: 'LP' },
        //         { path: 'register', title: 'Register Page', ab: 'RP' },
        //         { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' }
        //     ]
        // }
    ];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./../style.component.scss', '../../assets/comtrak-ui-system-v-0.1/sass/partials/_sidebar.scss' , './sidebar.component.css'],

})

export class SidebarComponent implements OnInit, OnDestroy {
    // title1 = this._globalService.Company.name ;
    // title2 = this._globalService.loginuser.name;
    TotalCountScriptPendingingReview:any;
    TotalCountCommunicationPendingingReview:any;
    TotalCountPrescreenPendingingReview:any;
    TotalCountNotificationChat:any
    TotalCountNotificationPhone:any
    TotalCountNotificationEmail:any
    TotalCountNotificationText:any
    page = {
        size: 1,
        totalElements: 1,
        pageCount: 3,
      };
    title1: string
    title2: string
    grandChildParentID: number = -1;
    sidebarOpen = true; 
    activeLinkIndex: number = 200;
    // public menuItems: Routes[];
    public _routes: Routes[];
    mobile: boolean;

    currentUrl: string;
    subscription: Subscription = new Subscription();
    buildVersion: string = environment.appVersion || '';
    constructor(private _Router: Router,
        private _globalService: GlobalfunctionService, private router: Router,
        public leadService: LeadService,  private spinner: NgxSpinnerService,
        private route: ActivatedRoute, private _commonService: CommonService,
        private communicationService: CommunicationService,
        public PrescreenList: PrescreenService,
        public ReviewList:TemplateService,
        private NotificationsService : NotitificationsService,
        public _RoutesService: RoutesService,
        public twilioService: TwilioService,
        public http: HttpClient) {
            
        this.leadService.GetLeadsValidationList("")
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    ngOnInit() {
        const TotalCountCommunicationSub = this.communicationService.TotalCountCommunicationPendingingReview.subscribe(res => this.TotalCountCommunicationPendingingReview = res)
        this.subscription.add(TotalCountCommunicationSub);
        const TotalCountScriptSub = this.communicationService.TotalCountScriptPendingingReview.subscribe((res)=> this.TotalCountScriptPendingingReview = res)
        this.subscription.add(TotalCountScriptSub);
        const TotalCountPrescreenSub = this.PrescreenList.TotalCountPrescreenPendingingReview.subscribe((res)=> this.TotalCountPrescreenPendingingReview = res)
        this.subscription.add(TotalCountPrescreenSub);
        const TotalCountNotificationChatSub = this.NotificationsService.TotalCountNotificationChat.subscribe((res)=> this.TotalCountNotificationChat = res)
        this.subscription.add(TotalCountNotificationChatSub);
        const TotalCountNotificationEmailSub = this.NotificationsService.TotalCountNotificationEmail.subscribe((res)=> this.TotalCountNotificationEmail = res)
        this.subscription.add(TotalCountNotificationEmailSub);
        const TotalCountNotificationPhoneSub = this.NotificationsService.TotalCountNotificationPhone.subscribe((res)=> this.TotalCountNotificationPhone = res)
        this.subscription.add(TotalCountNotificationPhoneSub);
        const TotalCountNotificationTextSub = this.NotificationsService.TotalCountNotificationText.subscribe((res)=> this.TotalCountNotificationText = res)
        this.subscription.add(TotalCountNotificationTextSub);
        const hideSideBarSub = this.PrescreenList.hideSideBar.subscribe((res) => {
            if (res === 'reset') {
                this.sidebarOpen = true;
            } else {
                if ((res === 'maximize') && this.sidebarOpen) {
                    this.minimizeSidebar();
                } else if (res === 'minimize' && !this.sidebarOpen) {
                    this.minimizeSidebar();
                }
            }
        });

        this.subscription.add(hideSideBarSub);
        this.getPendingListData(1);
        this.fetchPendinglist('',1,'pending_review');
        this.fetchPrescreenPendinglist(1);
        try {
            // Subscribe to routeSubject to expand menu for loaded link
            this.subscription.add(this._RoutesService.routeSubject.subscribe(
                x => this.expandMenuForLoadedLink()
            ));
    
            // Fetch all routes
            this._RoutesService.GetAll();
    
            // Check if the window width is greater than 420 for responsiveness
            if (window.screen.width > 420) {
                this.mobile = true;
            }
    
            // Set currentUrl initially
            this.currentUrl = this.router.url;
    
            // Subscribe to router events to track navigation changes
            this.subscription.add(this._Router.events.subscribe((event) => {
                // Check if the event is NavigationEnd
                if (event instanceof NavigationEnd) {
                    // Update currentUrl with the current URL
                    this.currentUrl = this.router.url;
    
                    // Check if query parameters are present in the URL
                    if (this.currentUrl.includes('?')) {
                        // Remove query parameters
                        this.currentUrl = this.currentUrl.split('?')[0];
                    }
                }
            }));
        } catch (x) {
            // Handle any errors
            this._RoutesService._routes = [];
            this._globalService.showErrorMsg(x);
        }

        /* Let's Connect Twilio For Calling */
        this.twilioService.initialize();
    }

     // function to short number
        shortenNumber(num: number): string {
        if (num >= 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
        const thousands = Math.floor(num / 1000);
        if (thousands >= 100) {
            return thousands + 'k+';
        } else {
            return thousands + 'k';
        }
        } else {
        return num.toString();
        }
       }
    
      
       logChildItem(childitem: any): void {
        console.log(childitem);
    }
    
    getPendingListData(pageNO: number): void {
        this.spinner.show();
        this._globalService.IsReady = false;
    
        let params = new HttpParams()
        .set('PageNo', String(pageNO))
        .set('Status', 'pending_review');
    
      if (!!('').trim()) {
        params = params.append('SearchString', '');
      }
        const getData = this.communicationService.getCommunicationList(params).subscribe({
          next: (res) => {
            if (res.status) {
            this.TotalCountCommunicationPendingingReview = res.pagination.totalCount
            }
            this.spinner.hide();
            this._globalService.IsReady = true;
          },
          error: (err) => {
            console.log('err======', err);
            this.spinner.hide();
            this._globalService.IsReady = true;
          }
        });
        this.subscription.add(getData);
      }

      fetchPendinglist(search:string, pageNo:number, ScriptStatus:string ) {
        this.ReviewList.GetScriptList(search, pageNo, ScriptStatus ) .subscribe(
          (response: any) => {
            this.TotalCountScriptPendingingReview = response.pagination.totalCount
          },
          (error: any) => {
            console.error('Error fetching study details:', error);
            // Handle error if needed
          }
        );
      }
    fetchPrescreenPendinglist(pageNo: number) {
        let params = new HttpParams()
        .append('PageNo', String(pageNo))
        .append('Status', 'pending_review');
        this.PrescreenList.getPrescreenList(params).subscribe(
        (response: any) => {
            this.TotalCountPrescreenPendingingReview = response.pagination.totalCount
        },
        (error: any) => {
            console.error('Error fetching study details:', error);
        }
        );
    }


    expandMenuForLoadedLink(): void {
        if(this._RoutesService._routes){
          var route =  this._RoutesService._routes.find(route => HelperService.replaceAll(route.path, ' ', '%20').toUpperCase() === this.currentUrl.toUpperCase());
            if (route) {
                document.getElementById(route.collapse) ? (document.getElementById(route.collapse).className = 'collapse') : null;
            }
        }
    }

    onNavigate(obj: any, Externalpath: string, childitem: any) {
        try {
            if (childitem.type !== 'grandchild') {
                this._commonService._externalURL = Externalpath || childitem.path;
                window.open(this._commonService._externalURL, '_blank');
            }
        } catch (x) { }
    }
    filterItems(routesID: number): Routes[] {
        return this.filterUniqueRoutes().filter(x => x.pid === routesID);
    }
    filterUniqueRoutes(): Routes[] {
        // TODO: Remove in Prod
        return this._RoutesService._routes.filter((value, index, self) => (self.map(x => x.routesID).indexOf(value.routesID) == index) && (value.routesID != 127 && value.routesID != 128 && value.routesID != 129 && value.routesID != 130))
    }
    updatePS(routes: Routes): void {
        try {
            this._RoutesService._routes.filter(x => x.type === 'sub').forEach(route => {
                let routename = route.collapse.toString().trim();
                if (routename != routes.collapse.trim())
                    document.getElementById(routename).className = 'collapse';
            });

            // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {

            //     // const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            // }

        } catch (x) {

        }
    }

    setActiveLinkIndex(i) {
        try {
            this.activeLinkIndex = i;
        } catch (x) { }
    }

    setGrandChildParentID(i) {
        try {
            if (this.grandChildParentID == i) {
                this.grandChildParentID = -1;
            }
            else {
                this.grandChildParentID = i;
            }
        } catch (x) { }
    }


    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    RevokeToken() {
        this.router.navigate(['./']);
    }
    Register() {
        this._Router.navigate(['homepage']);
    }
    hide() {

    }
    /** SideBar Toggle Menus */
    minimizeSidebar() {
        this.sidebarOpen = !this.sidebarOpen;
        const body = document.getElementsByTagName('body')[0];
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }
        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    } 

    onMenuItemClick(url: string, event: Event): void {
        const normalizedUrl = url.split(' ').join('%20').toUpperCase();
        if (normalizedUrl === this.currentUrl.toUpperCase()) {
            event.preventDefault();
            return;
        }
        if (this.isExternalUrl(url)) {
            this.openExternalUrl(url);
        } else {
            this.router.navigateByUrl(url);
        }
    }

    isExternalUrl(url: string): boolean {
        return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('ftp://');
    }

    openExternalUrl(url:string){
        window.open(url);
    }

    ngOnDestroy(): void {
        console.log(`SidebarComponent ~ ngOnDestroy ~ ngOnDestroy:`);
        if(this.subscription)  {
            this.subscription.unsubscribe();   
        }
        this.twilioService.ngOnDestroy();
    }
}
