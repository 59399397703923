import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as myGlobals from "@shared/global_var";
import { SocialUser } from "@sharedmodels/BackModels/ComTrak/Security/SocialUser";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";

@Injectable({
  providedIn: "root",
})
export class ExternalAuthService {
  constructor(
    public _http: HttpClient,
    public spinner: NgxSpinnerService,
    public _Global: GlobalfunctionService
  ) {}

  ExternalLogin(_user: SocialUser): any {
    try {
      this.spinner.show();
      return this._http.post(myGlobals._apiPath + "auth/ExternalLogin", _user);
    } catch (x) {
      this.spinner.hide();
    }
  }

  CheckBetaAccess(recruiterId: Number): any {
    try {
      this.spinner.show();
      return this._http.get(myGlobals._apiPathv3 + "patient-manage/get-redirect-url/" + recruiterId);
    } catch (x) {
      this.spinner.hide();
    }
  }

  // RefreshToken() {
  //     try {
  //         console.log('token refresh');
  //         this.spinner.show();
  //         let socialUser = this._Global.socialUser
  //         let postBody = {
  //             "client_id": "687199414693-c4ocf6bslimh2mfipbir5o6fiiofgrku.apps.googleusercontent.com",
  //             "client_secret": "bn4SI0Zm6Twh759uOMdLiNl2",
  //             "grant_type": "refresh_token",
  //             "refresh_token": socialUser.accessToken
  //         }
  //         console.log('token refreshed!');
  //         return this._http.post('https://oauth2.googleapis.com/token', postBody);

  //     } catch (x) {
  //         console.log('err at token refresh=' +x);
  //         this.spinner.hide()
  //     }
  // }
}
