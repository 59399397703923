import { RegisterationService } from './../../../Shared/Services/BackServices/ComTrak/Setups/Registeration.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ExternalAuthService } from '@sharedservices/BackServices/ComTrak/Security/externalauth.service';
import { GoogleLoginProvider, SocialService } from 'ngx-social-button';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { RegisterUser, SocialUser } from '@sharedmodels/BackModels/ComTrak/Security/SocialUser';
import { Router } from '@angular/router';

@Component({
    selector: 'registeration',
    templateUrl: 'registeration.component.html',
    styleUrls: ['registeration.component.scss']
})
export class RegisterationComponent implements OnInit {
    RegisterForm: FormGroup
    submitted: boolean = false;
    constructor(private fb: FormBuilder, public registerationService: RegisterationService) {

    }
    ngOnInit() {
        // this.registerationService.OnGet()
        this.initForm()
    }

    initForm() {
        this.RegisterForm = this.fb.group({
            Email: ['', [Validators.email, Validators.required]],
            Password: ['', Validators.required],
            ConfirmPassword: ['', Validators.required],
        }, { validator: this.passwordConfirming })
    }

    get f() {
        return this.RegisterForm.controls;
    }

    ExternalLogin() {
        this.registerationService.ExternalLogin(this.RegisterForm.value)
    }

    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get('Password').value !== c.get('ConfirmPassword').value) {
            return { invalid: true };
        }
    }

    RegisterUser() {
        this.submitted = true;
        if (this.RegisterForm.invalid) {
            return;
        }
        else {
            this.registerationService.RegisterUser(this.RegisterForm.value)
            this.registerationService.isCallApi.subscribe(res => {
                if (res) {
                    this.initForm()
                    this.submitted = false
                }
            })
        }
    }

}
