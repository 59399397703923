import { OperationReports } from './../../../../Models/BackModels/ComTrak/Reports/OperationReports';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '@shared/global_var';

@Injectable({
    providedIn: 'root'
})
export class OperationReportService {
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService) { }

    LoadOperationReport(reportType: string, siteId: any, studyId: any, statusId: any, startDate: any, endDate: any, pageSize?: number, pageNo?: number, count?: number): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'OperationReport?GetType=' + reportType + '&siteId=' + siteId + "&studyId=" + studyId + "&statusId=" + statusId
                + "&startDate=" + startDate + "&endDate=" + endDate + "&pagesize=" + pageSize + "&pageno=" + pageNo + "&count=" + count,
                { headers: this._Global.httpOptions });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }
}

