import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserRoleService } from "@sharedservices/BackServices/ComTrak/role/userrole.service";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TherapeuticArea } from "../../../Shared/Models/BackModels/ComTrak/Setups/TherapeuticArea";
import { TherapeuticAreaService } from "./../../../Shared/Services/BackServices/ComTrak/Therapeutic/therapeutic-area.service";

@Component({
  selector: "app-create-therapeutic-area",
  templateUrl: "./create-therapeutic-area.component.html",
  styleUrls: ["./create-therapeutic-area.component.scss", '../../style.component.scss'],
})
export class CreateTherapeuticAreaComponent implements OnInit {
  TherapeuticAreaForm: FormGroup;
  submitted: boolean = false;
  TherapeuticAreaObject: TherapeuticArea;
  public _data: any;
  isedit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateTherapeuticAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public userRoleService: UserRoleService,
    public spinner: NgxSpinnerService,
    public _Global: GlobalfunctionService,
    public _TherapeuticAreaService: TherapeuticAreaService
  ) { }

  ngOnInit() {
    this.initForm();
    console.log(this.data);
    if (this.data.therapeuticAreaData != undefined) {
      this.TherapeuticAreaForm.controls.Name.setValue(
        this.data.therapeuticAreaData.Name
      );
      this.TherapeuticAreaForm.controls.IsActive.setValue(
        this.data.therapeuticAreaData.IsActive
      );
      this.isedit = true;
    } else {
      // console.log("Is Edit");
      this.isedit = false;
    }
  }

  initForm() {
    this.TherapeuticAreaForm = this.fb.group({
      Name: ["", Validators.required],
      IsActive: [false, Validators.required],
      Id: [""],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga("send", "event", {
    //   eventCategory: "click",
    //   eventLabel: "buttonClick",
    //   eventAction: "onNoClick",
    // });
  }

  get f() {
    return this.TherapeuticAreaForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // console.log(
    //   "Create Therapeutic Area Form Name : ",
    //   this.TherapeuticAreaForm.controls.Name.value
    // );
    // console.log(
    //   "Create Therapeutic Area Form IsActive : ",
    //   this.TherapeuticAreaForm.controls.IsActive.value
    // );

    if (this.TherapeuticAreaForm.invalid) {
      return;
    } else {
      this.TherapeuticAreaObject = {
        Name: this.TherapeuticAreaForm.controls.Name.value,
        IsActive: this.TherapeuticAreaForm.controls.IsActive.value,
      };

      if (this.isedit == true) {
        this.TherapeuticAreaObject.Id = this.data.therapeuticAreaData.Id;
      }

      // console.log("Therapeutic object : ", this.TherapeuticAreaObject);

      this._TherapeuticAreaService.checkIfEditorAddTherapeuticArea(
        this.TherapeuticAreaObject,
        this.isedit
      );
      this._TherapeuticAreaService.isCallApi.subscribe((res) => {
        if (res) {
          this.onNoClick();
          this.initForm();
          this._TherapeuticAreaService.isCallApi.next(false);
        }
      });
    }
  }
}
