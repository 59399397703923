import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { VisitorFlowColumns, VisitorFlowSiteColumns } from '@sharedmodels/BackModels/ComTrak/VisitorFlow/VisitorFlowColumns';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { VisitorFlowSiteColumnService } from '@sharedservices/BackServices/ComTrak/VisitorFlow/visitorflowsitecolumn.service';
import { VisitorFlowColumnService } from '@sharedservices/BackServices/ComTrak/VisitorFlow/visitorflowcolumn.service';

@Component({
  selector: 'app-visitorflowsitecolumn',
  templateUrl: './visitorflowsitecolumn.component.html',
  styleUrls: ['./visitorflowsitecolumn.component.scss']
})
export class VisitorflowsitecolumnComponent implements OnInit {
  visitorFlowSiteColumnForm: FormGroup
  submitted: boolean;
  visitorFlowColumns = null
  editRecord: boolean;
  showSelectQuery: boolean;
  showStatusDropDown: boolean;
  siteId: number;
  screenTitle: string
  VFTypeId: number;
  constructor(public dialogRef: MatDialogRef<VisitorflowsitecolumnComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, public visitorFlowSiteColumnService: VisitorFlowSiteColumnService, private spinner: NgxSpinnerService,
    public visitorFlowColumnService: VisitorFlowColumnService, public _Global: GlobalfunctionService, public statusService: StatusService) { }

  ngOnInit() {
  
    this.siteId = this.data.siteId
    this.screenTitle = this.data.screenTitle
    this.VFTypeId = this.data.VFTypeId
    console.log("this.data.siteId = ", this.data.siteId)
    this.visitorFlowColumnService.LoadVisitorFlowColumns()
    this.visitorFlowSiteColumnService.LoadVisitorFlowSiteColumns(this.siteId, this.VFTypeId);
    this.LoadFilterData();
    this.initForm()
  }

  LoadFilterData() {
    this.visitorFlowSiteColumnService.CheckIfExistOrNot(this.siteId).subscribe(res => {
      this.visitorFlowSiteColumnService.FilteredVisitorFlowColumns = undefined
      if (res == true) {
        this.visitorFlowSiteColumnService.LoadFilteredVisitorFlowColumns(this.siteId, this.VFTypeId)
      }
      else {
        this.visitorFlowSiteColumnService.GetVisitorFlowColumns()
      }
    })
  }

  initForm() {
    this.visitorFlowSiteColumnForm = this.fb.group({
      SiteID: [this.siteId, Validators.required],
      VisitorFlowColumnsID: ['', Validators.required],
      Alias: [''],
      HeaderColor: ['#FFFFFF'],
      // PostDoseTime: ['', Validators.required],
      Site: [this.data.siteName],
      SelectQuery: [''],
      PrescreenerStatusToUpdate: [null],
      ScreenerStatusToUpdate: [null],
      VisitorFlowSiteColumnID: [0],
      VFTypeId: [this.VFTypeId]
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.visitorFlowSiteColumnForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.visitorFlowSiteColumnForm.invalid) {
      return;
    }
    else {
      this.visitorFlowSiteColumnService.SaveAndEdit(this.visitorFlowSiteColumnForm.value, this.editRecord)
      this.visitorFlowSiteColumnService.isCallApi.subscribe(res => {
        if (res) {
          this.onNoClick()
          this.initForm();
          // this.visitorFlowSiteColumnService.LoadFilteredVisitorFlowColumns(this.data.siteId)
          // this.LoadFilterData()
          this.editRecord = false
          this.showSelectQuery = false
          this.showStatusDropDown = false
        }
      })
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (confirm("Are you sure You want to change the index of this Column?") == true) {
      var PreviousVisitId = this.visitorFlowSiteColumnService._VisitorFlowSiteColumns[event.currentIndex - 1].VisitorFlowSiteColumnID
      var updatedVisitId = this.visitorFlowSiteColumnService._VisitorFlowSiteColumns[event.previousIndex].VisitorFlowSiteColumnID
      var NextVisitId = this.visitorFlowSiteColumnService._VisitorFlowSiteColumns[event.currentIndex].VisitorFlowSiteColumnID
      this.visitorFlowSiteColumnService.EditStudyVisitorFlowSequence(updatedVisitId, PreviousVisitId, NextVisitId, this.visitorFlowSiteColumnService._VisitorFlowSiteColumns[event.previousIndex]);
    }
  }

  setEditData(row: VisitorFlowSiteColumns) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this.visitorFlowSiteColumnService.FilteredVisitorFlowColumns = []
      this.visitorFlowSiteColumnService.LoadFilteredVisitorFlowColumn(this.siteId, this.VFTypeId).subscribe(res => {
        this.visitorFlowSiteColumnService.FilteredVisitorFlowColumns = <VisitorFlowColumns[]>res;
        this._Global.IsReady = true;
        this.spinner.hide()
        this.editRecord = true
        let object = this.visitorFlowColumnService._VisitorFlowColumns.filter(o => o.VisitorFlowColumnsID == row.VisitorFlowColumnsID)[0]
        if (object) {
          this.visitorFlowSiteColumnService.FilteredVisitorFlowColumns.push({ Title: object.Title, VisitorFlowColumnsID: object.VisitorFlowColumnsID, ColumnType: object.ColumnType })
        }
        this.visitorFlowSiteColumnForm.patchValue(row)
        this.ChangeDropDown(row.VisitorFlowColumnsID)
        this._Global.IsReady = true;
        this.spinner.hide()
      }, error => {
        this._Global.showErrorMsg(error);
        this.spinner.hide()
        this._Global.IsReady = true;
      });
    }
    catch (ex) {
      this._Global.showErrorMsg(ex);
      this.spinner.hide()
      this._Global.IsReady = true;
    }
  }

  ChangeDropDown(VisitorFlowColumnsID: number) {
    let Id = VisitorFlowColumnsID == undefined ? this.visitorFlowSiteColumnForm.controls.VisitorFlowColumnsID.value : VisitorFlowColumnsID
    let Obj = this.visitorFlowSiteColumnService.FilteredVisitorFlowColumns.
      filter(o => o.VisitorFlowColumnsID == Id)[0]
    // this.visitorFlowSiteColumnForm.controls.PostDoseTime.setValue(Obj.DefaultValue)
    if (Obj && Obj.ColumnType == "D" || Obj.ColumnType == "Drop Down") {
      // this.visitorFlowSiteColumnForm.get('SelectQuery').setValidators([Validators.required]);
      this.showSelectQuery = true
      this.showStatusDropDown = false
    }
    else if (Obj && Obj.ColumnType == "C" || Obj.ColumnType == "CheckBox") {
      this.statusService.GetStatusByScreener();
      this.statusService.GetStatusByPrescreener();
      this.showSelectQuery = false
      this.showStatusDropDown = false
      // this.visitorFlowSiteColumnForm.get('SelectQuery').clearValidators();
      this.visitorFlowSiteColumnForm.controls.SelectQuery.setValue('');
    }
    else {
      this.showSelectQuery = false
      this.showStatusDropDown = false
    }
  }

}
