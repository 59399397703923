import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Router } from '@angular/router';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';

@Component({
  selector: 'app-incomingcall',
  templateUrl: './incomingcall.component.html',
  styleUrls: ['./incomingcall.component.scss'],
  encapsulation: ViewEncapsulation.None,
})  
export class IncomingCallComponent implements OnInit {
  public myData: any = [];
  htmlContent: any;
  patientFc = new FormControl(''); 
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public dialogRef: MatDialogRef<IncomingCallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _global: GlobalfunctionService,
    private router: Router,
    private PatientService: PatientService

  ) {}  

  ngOnInit() {
    this.myData = this.data;
    console.log('Incoming Call Dialog Received Data:', this.data);
  }
  
  onSubmit(accepted): void {
    this.dialogRef.close(accepted); 

    if(accepted) {

      if (!this.data.patientData.FullName) {
      this.PatientService.acceptCallFlag.next(this.data.callParams.From.replace("+1", ""));
      this.router.navigate(['/patientslist']);
    } 
  }
  }
}
