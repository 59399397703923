import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common'; 
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  public myData: string = '';
  htmlContent: SafeHtml;
  toggleheader=false
  constructor(
    public dialogRef: MatDialogRef<PreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe ,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.myData = this.data.rowData;
    this.toggleheader = this.data.toggleheader
    // if ((this.data.type && this.data.type.toLowerCase() === "sms") ||
    //   (this.data.type && this.data.type.toLowerCase() === "text script") ||
    //   (this.data.type && this.data.type.toLowerCase() === "text")||
    //   ( this.data.type.toLowerCase() === "" && !this.data.editorPreview)
    //   ) {
    //   // this.myData = this.myData.replace(/\n/g, '<br>');
    // }
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.myData) as string;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
