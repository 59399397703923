import { SiteService } from './site.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VaccineService {
  public isCallApi = new Subject<boolean>();
  public _vaccines: any[];
  public _vaccineTypes: any[];
  public arraylength: number;
  public _Manufacturers: any[];
  public _Indications: any[];

  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService,
    public siteService: SiteService) { }

    LoadVaccines(searchString: string) {
      try {
        
        this.isCallApi.next(false)
        this._http.get(myGlobals._apiPath + 'Vaccine/GetAllVaccines?searchString='+ searchString, { headers: this._Global.httpOptions })
          .subscribe(data => { 
            this._vaccines = <any[]>data;
            this.arraylength = this._vaccines.length
            this.isCallApi.next(true)
          },
            error => {
              this._Global.handleError(error);
              this.isCallApi.next(false)
            });
      } catch (x) {
        this._Global.showErrorMsg(x);
        this.isCallApi.next(false)
      }
    }
    LoadAllVaccines(searchString: string, patientId: number) {
      try {
        
       // this.isCallApi.next(false)
        return this._http.get(myGlobals._apiPath + 'Vaccine/GetAllVaccines?searchString='+ searchString + '&patientId=' + patientId, { headers: this._Global.httpOptions })
      } catch (x) {
      }  //.subscribe(data => { 
      //       this._vaccines = <any[]>data;
      //       this.arraylength = this._vaccines.length
      //       this.isCallApi.next(true)
      //     },
      //       error => {
      //         this._Global.handleError(error);
      //         this.isCallApi.next(false)
      //       });
      // } catch (x) {
      //   this._Global.showErrorMsg(x);
      //   this.isCallApi.next(false)
      // }
    }
    // LoadVaccinesTypes(): Observable<any> {
    //   try {
    //     return this._http.get(myGlobals._apiPath + 'Vaccine/GetVaccineTypes', { headers: this._Global.httpOptions })
    //   } catch (x) {
    //   }
    // }
    LoadManufacturers(){
      try {
         this._http.get(myGlobals._apiPath + 'Manufacturer/GetAllManufacturers', { headers: this._Global.httpOptions })
         .subscribe(res => {
          this._Manufacturers = <any[]>res;
          
        }, error => {
          this._Global.handleError(error);
          
        });
      } catch (x) {
      }
    }
    LoadIndications() {
      try {
        this._http.get(myGlobals._apiPath + 'Indication/GetAllIndications', { headers: this._Global.httpOptions })
        .subscribe(res => {
        this._Indications = <any[]>res;
          
      }, error => {
        this._Global.handleError(error);
        
      });
      } catch (x) {
      }
    }
    

  GetVaccineByID(vaccineId: number): Observable<any> {
    try {
      return this._http.get(myGlobals._apiPath + 'Vaccine/GetVaccine?id=' + vaccineId, { headers: this._Global.httpOptions })
    } catch (x) {
    }
  }
  SaveAndEditVaccine(body: any, isEdit: boolean) {
    if (isEdit) {
      this.UpdateVaccine(body)
    }
    else {
      this.SaveVaccine(body)
    }
  }
  SaveVaccine(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Vaccine/AddVaccine', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Vaccine Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadVaccines('')
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  UpdateVaccine(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Vaccine/UpdateVaccine', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Vaccine Updated Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadVaccines('')
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  SaveManufacturer(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Manufacturer/AddManufacturer', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Manufacturer Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadVaccines('')
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }
  SaveIndication(body: any) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'Indication/AddIndication', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Indication Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadVaccines('')
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }
}
