import { Staff } from './../../../../Models/BackModels/ComTrak/Setups/Staff';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  public isCallApi = new Subject<boolean>();
  public _staffs: Staff[];
  public _staffsArray: Staff[];
  //   public _studyBYID: Staff[];
  public arraylength: number;
  staffTypeLead: Staff[];
  staffTypeRecruiters: Staff[];
  public _stateWiseStaffs: Staff[];
  DBLMRecruiters: Staff[];

  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

  LoadStaff(searchString: string, onlineUsers = null) {
    try {
      if (searchString === undefined || searchString === null) {
        searchString = '';
      }
      onlineUsers ? null : this.spinner.show()
      onlineUsers ? null : this._Global.IsReady = false;

      this.isCallApi.next(false)
      this._http.get(myGlobals._apiPath + 'Staffs?searchString=' + searchString, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._staffs = <Staff[]>data;
          this._staffsArray = <Staff[]>data;
          
          if(onlineUsers && onlineUsers.length) {
            this._staffsArray.forEach(data => {
              data['onlineStatus'] = onlineUsers.includes(data.Email) ? 'online' : 'offline'; 
            })
          }
          this.arraylength = this._staffs.length
          onlineUsers ? null : this.spinner.hide()
          onlineUsers ? null : this._Global.IsReady = true;
          this.isCallApi.next(true)
        },
          error => {
            this._Global.handleError(error);
            onlineUsers ? null : this.spinner.hide()
            onlineUsers ? null : this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      onlineUsers ? null : this._Global.IsReady = true;
      onlineUsers ? null : this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  GetAllActiveStaff() {
    try {
      // this.spinner.show()
      // this._Global.IsReady = false;
      this._http.get(myGlobals._apiPath + 'Staffs?type=GetAllActiveStaff', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._staffs = <Staff[]>data;
          this.staffTypeLead = this._staffs.filter(o => o.StaffType == 1)
          this.DBLMRecruiters= this._staffs.filter(o => o.StaffType == 1)
          this.staffTypeRecruiters= this._staffs.filter(o => o.StaffType == 1)
          // this.spinner.hide()
          // this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            // this.spinner.hide()
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      // this._Global.IsReady = true;
      // this.spinner.hide()
    }
  }

  GetStateWiseStaff() {
    try {
      // this.spinner.show()
      // this._Global.IsReady = false;
      this._http.get(myGlobals._apiPath + 'Staffs?type=GetStateWiseStaff', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._stateWiseStaffs = <Staff[]>data;
          // this.spinner.hide()
          // this._Global.IsReady = true;
        },
          error => {
            this._Global.showErrorMsg(error);
            // this.spinner.hide()
            // this._Global.IsReady = true;
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      // this.spinner.hide()
      // this._Global.IsReady = true;
    }
  }

  GetAllStaff(): Staff[] {
    if (this._staffs !== undefined && this._staffs.length !== null) {
      return this._staffs;
    } else {
      this.LoadStaff('');
    }
  }

  GetSaffByID(recruiterId: number): Observable<Staff> {
    try {
      return this._http.get(myGlobals._apiPath + 'Staffs?recruiterId=' + recruiterId, { headers: this._Global.httpOptions })
    } catch (x) {
    }
  }

  GetAssignPatientCount(recruiterId: number): Observable<Staff> {
    try {
      return this._http.get(myGlobals._apiPath + 'Staffs?recruiterId=' + recruiterId + "&type=GetAssignPatientCount", { headers: this._Global.httpOptions })
    } catch (x) {
    }
  }


  SaveStaff(body: Staff) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._Global.clean(body);
      this._http.post(myGlobals._apiPath + 'Staffs/Save', body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Staff Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadStaff('')
        },
          error => {
            this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  EditStaff(siteID: number, body: Staff) {
    try {
      this.spinner.show()
      this._Global.clean(body);
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'Staffs/Edit/' + siteID, body, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.isCallApi.next(true)
          this._Global.showSuccessMsg("Staff Updated Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadStaff('')
        },
          error => {
            this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  SaveAndEditStaff(siteId: number, body: Staff, isEdit: boolean) {
    if (isEdit) {
      this.EditStaff(siteId, body)
    }
    else {
      this.SaveStaff(body)
    }
  }

}
