import { Component, OnInit, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-templaterender',
  templateUrl: './templaterender.component.html',
  styleUrls: ['./templaterender.component.css']
})
export class TemplaterenderComponent implements ICellRendererAngularComp {
  template: TemplateRef<any>;
  templateContext: { $implicit: any, params: any };

refresh(params: any): boolean {
  this.templateContext = {
    $implicit: params.data,
    params: params
  };
  return true;
}

  agInit(params: ICellRendererParams): void {
    this.template = params['ngTemplate'];
    this.refresh(params);
  }
}
