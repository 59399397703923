import { Subject, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { VisitorFlowColumns, VisitorFlowSiteColumns } from '@sharedmodels/BackModels/ComTrak/VisitorFlow/VisitorFlowColumns';

@Injectable({
    providedIn: 'root'
})
export class VisitorFlowSiteColumnService {
    public isCallApi = new Subject<boolean>();
    public _VisitorFlowSiteColumns: VisitorFlowSiteColumns[];
    public _activeVisitorFlowSiteColumns: VisitorFlowSiteColumns[];
    public FilteredVisitorFlowColumns: VisitorFlowColumns[];
    public CheckIfExist: VisitorFlowSiteColumns[];
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

    LoadVisitorFlowSiteColumns(siteId: number, VFTypeId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'VisitorFlowSiteColumn?type=GetAllVisitorFlowSiteColumns&siteid=' + siteId + '&VFTypeId=' + VFTypeId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._VisitorFlowSiteColumns = <VisitorFlowSiteColumns[]>data;
                    this._VisitorFlowSiteColumns = this._VisitorFlowSiteColumns.sort((a: any, b: any) => a.SequenceNo - b.SequenceNo)
                    this.isCallApi.next(true)
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    LoadFilteredVisitorFlowColumn(siteId: number, VFTypeId: number): Observable<any> {
        try {
            return this._http.get(myGlobals._apiPath + 'VisitorFlowSiteColumn?type=GetFilteredData&siteid=' + siteId + '&VFTypeId=' + VFTypeId, { headers: this._Global.httpOptions })
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    LoadFilteredVisitorFlowColumns(siteId: number, VFTypeId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'VisitorFlowSiteColumn?type=GetFilteredData&siteid=' + siteId + '&VFTypeId=' + VFTypeId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.FilteredVisitorFlowColumns = <VisitorFlowColumns[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetVisitorFlowColumns() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'VisitorFlowSiteColumn?type=GetVisitorFlowColumns', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.FilteredVisitorFlowColumns = <VisitorFlowColumns[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }



    // GetVisitorFlowSiteColumns(): VisitorFlowSiteColumns[] {
    //     if (this._VisitorFlowSiteColumns !== undefined && this._VisitorFlowSiteColumns.length !== null) {
    //         return this._VisitorFlowSiteColumns;
    //     } else {
    //         this.LoadVisitorFlowSiteColumns();
    //     }
    // }

    LoadActiveVisitorFlowSiteColumns() {
        try {
            this._Global.IsReady = false;
            this.spinner.show()
            this._http.get(myGlobals._apiPath + 'VisitorFlowSiteColumn?type=GetAllActiveVisitorFlowSiteColumns', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._activeVisitorFlowSiteColumns = <VisitorFlowSiteColumns[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    CheckIfExistOrNot(siteId: number): Observable<any> {
        return this._http.get(myGlobals._apiPath + 'VisitorFlowSiteColumn?type=CheckIfExistOrNot&siteid=' + siteId, { headers: this._Global.httpOptions })
    }


    GetActiveVisitorFlowSiteColumns(): VisitorFlowSiteColumns[] {
        if (this._activeVisitorFlowSiteColumns !== undefined && this._activeVisitorFlowSiteColumns.length !== null) {
            return this._activeVisitorFlowSiteColumns;
        } else {
            this.LoadActiveVisitorFlowSiteColumns();
        }
    }

    SaveVisitorFlowSiteColumns(body: VisitorFlowSiteColumns) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'VisitorFlowSiteColumn/SaveVisitorFlowSiteColumns', body, { headers: this._Global.httpOptions })
                .subscribe((data: VisitorFlowSiteColumns) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Visitor Flow Site Columns Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadVisitorFlowSiteColumns(data.SiteID, data.VFTypeId)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    SaveAndEdit(body: VisitorFlowColumns, isEdit: boolean) {
        if (isEdit) {
            this.Edit(body)
        }
        else {
            this.SaveVisitorFlowSiteColumns(body)
        }
    }

    Edit(body: VisitorFlowSiteColumns) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'VisitorFlowSiteColumn?apiType=Edit', body, { headers: this._Global.httpOptions })
                .subscribe((data: VisitorFlowSiteColumns) => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Visitor Flow Site Columns Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadVisitorFlowSiteColumns(data.SiteID, data.VFTypeId)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    EditStudyVisitorFlowSequence(visitorFlowID: number, previousvisitorflowId: number, nextvisitorflowId: number, body: VisitorFlowSiteColumns) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'VisitorFlowSiteColumn?apiType=EditStudyVisitorFlowSequence&updatedvisistId=' + visitorFlowID +
                '&previousvisitorflowId=' + previousvisitorflowId + '&nextvisitorflowId=' + nextvisitorflowId, body, { headers: this._Global.httpOptions })
                .subscribe((data: VisitorFlowSiteColumns) => {
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadVisitorFlowSiteColumns(data.SiteID, data.VFTypeId)
                    this._Global.showSuccessMsg("Sequence Updated Successfully")
                    this.isCallApi.next(true)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
}
