import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReAssignableRecruiterList } from '@sharedmodels/BackModels/ComTrak/DBLM/DBLM';
import { Staff } from '@sharedmodels/BackModels/ComTrak/Setups/Staff';
import { DBLMService } from '@sharedservices/BackServices/ComTrak/DBLM/dblm.service';
import { StaffService } from '@sharedservices/BackServices/ComTrak/Staff/staff.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reassignlist',
  templateUrl: './reassignlist.component.html',
  styleUrls: ['./reassignlist.component.scss', '../../style.component.scss']
})
export class ReassignlistComponent implements OnInit {
  displayRemoveIcon: boolean = false;
  errorMessage: string;
  count: number = 0;
  ReAssigncount: any;
  recruitersArray: any[] = []
  reAssignList: any[] = []
  ReAssigncount2: number;
  constructor(public dialogRef: MatDialogRef<ReassignlistComponent>, public dblmService: DBLMService, @Inject(MAT_DIALOG_DATA) public data: any,
    public staffService: StaffService, public _Global: GlobalfunctionService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // this.dblmService.IsPatientCount.subscribe(res => {
    //   if (res) {
    this.pushArray()
    this.count = this.dblmService._patientCount ? this.dblmService._patientCount.PatientCount : 0
    this.ReAssigncount = this.count
    this.ReAssigncount2 = this.count
    this.staffService.staffTypeLead = this.staffService.staffTypeRecruiters
    this.dblmService._reAssignableRecruiterList.forEach(element => {
      let index = this.staffService.DBLMRecruiters.findIndex(o => o.Id == element.RecruiterId)
      let recruiterObject = this.staffService.DBLMRecruiters.find(o => o.Id == element.RecruiterId)
      this.recruitersArray.push(recruiterObject)
      if (index >= 0) {
        this.staffService.staffTypeLead.splice(index, 1)
      }
    })
    //   }
    // })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkTableValidation() {
    this.reAssignList = this.reAssignList
    let lastObject = this.reAssignList[this.reAssignList.length - 1]
    var checkValidate = lastObject.SelectedRecruiterId == undefined || lastObject.RecruiterId == undefined || lastObject.NoOfAssignPatient == null || lastObject.NoOfAssignPatient == '' || lastObject.NoOfAssignPatient == 0
    if (checkValidate) {
      let errorMessage = `All details are required`
      this._Global.showErrorMsg(errorMessage)
      return true
    }
    else {
      return false
    }
  }

  showRemoveIcon() {
    if (this.reAssignList.length === 1) {
      this.displayRemoveIcon = false;
    } else {
      this.displayRemoveIcon = true;
    }
  }

  ChangeRecruiter(indexNumber: number, recruiterId: number) {
    if (this.reAssignList.length > 1) {
      let Object = this.reAssignList.filter((value, index) => value.SelectedRecruiterId == recruiterId && indexNumber !== index)[0]
      let RecruiterObject = this.staffService.DBLMRecruiters.filter(o => o.Id == recruiterId)[0];
      if (Object) {
        this.errorMessage = `Recruiter ${RecruiterObject.FullName} is already selected at line : ${indexNumber}`
        this._Global.showErrorMsg(this.errorMessage)
        this.reAssignList[indexNumber].SelectedRecruiterId = undefined
      }
      else {
        this.errorMessage = ""
      }
    }
  }

  ChangeAssignCount(assignCount: any, index: number) {
    if (assignCount > this.reAssignList[index].Count) {
      this._Global.showErrorMsg("Assign count can't be greater then Re-Assignable patient")
      this.reAssignList[index].NoOfAssignPatient = ''
    }
    else if (assignCount > this.ReAssigncount) {
      this._Global.showErrorMsg("Assign count can't be greater then remaining patient")
      this.reAssignList[index].NoOfAssignPatient = ''
    }
    else {
      if (this.reAssignList.length == 1 && assignCount == null) {
        this.count = this.ReAssigncount2
        this.ReAssigncount = this.ReAssigncount2
      }
      else {
        this.count = this.ReAssigncount - assignCount
      }
    }
  }

  SpliceArray(index: number, NoOfAssignPatient: number) {
    if (this.reAssignList.length == 1) {
      return
    }
    else {
      this.count += NoOfAssignPatient;
      this.ReAssigncount = this.count
      this.reAssignList.splice(index, 1)
    }
  }

  pushArray() {
    // this.dblmService._reAssignableRecruiterList.push({
    //   Count: '',
    //   RecruiterId: undefined,
    //   TotalPatientsCount: '',
    //   ListMasterId: this.dblmService.listMasterId,
    //   NoOfAssignPatient: '',
    //   SelectedRecruiterId: undefined
    // })
    this.reAssignList.push({
      Count: '',
      RecruiterId: undefined,
      TotalPatientsCount: '',
      ListMasterId: this.dblmService.listMasterId,
      NoOfAssignPatient: '',
      SelectedRecruiterId: undefined
    })
  }

  AddArray(index: number) {
    // this.count = 0
    // let assignedCount = 0
    // this.reAssignList.forEach(element => {
    // this.count += element.Count
    // assignedCount += element.NoOfAssignPatient
    // })
    // this.count = this.dblmService._patientCount ? this.dblmService._patientCount.PatientCount : 0
    // if (assignedCount != this.count) {
    if (this.count > 0) {
      if (index != null) {
        let checkValidate = this.checkTableValidation()
        if (checkValidate == true) {
          return
        }
        else {
          this.pushArray()
        }
      }
      else {
        this.pushArray()
      }
      this.ReAssigncount = this.count
    }
    else {
      this._Global.showErrorMsg("cant add more details Re-Assignable Patient Count is zero")
    }
    // }
    // else {
    //   this._Global.showErrorMsg("cant add more details Re-Assignable patient count is full")
    // }
  }

  onSubmit() {
    let checkValidate = this.checkTableValidation()
    if (checkValidate == true) {
      return
    }
    else {
      if (this.reAssignList.filter(o => o.SelectedRecruiterId == undefined || (o.NoOfAssignPatient == 0 || o.NoOfAssignPatient == ""))[0]) {
        this._Global.showErrorMsg("Please Fill All Details")
      }
      else {
        let dataArray: any[] = []
        this.reAssignList.forEach(element => {
          dataArray.push({
            RecruiterId: +element.RecruiterId,
            SelectedRecruiterId: +element.SelectedRecruiterId,
            NoOfAssignPatient: +element.NoOfAssignPatient,
            ListMasterId: +element.ListMasterId
          })
        })
        this.dblmService.AddDBLMAssignPatientToRecruiter(dataArray, 'sp_DBLMReassignPatient')
        this.dblmService.IsSaveAssign.subscribe(res => {
          if (res) {
            this.onNoClick()
          }
        })
      }
    }
  }

  ChangeCurrentRecruiter(recruiterId: number, index: number, TotalPatientsCount: number) {
    let data = this.reAssignList.filter((o, ind) => o.RecruiterId == recruiterId)
    let prevObject: any = data[data.length - 2]
    if (prevObject) {
      if (prevObject.Count != 0) {
        this.reAssignList[index].TotalPatientsCount = prevObject.TotalPatientsCount
        this.reAssignList[index].Count = prevObject.Count - prevObject.NoOfAssignPatient
      }
      else {
        this.reAssignList[index].Count = ''
        this.reAssignList[index].TotalPatientsCount = data[0].TotalPatientsCount
      }
    }
    else {
      let dataObj = this.dblmService._reAssignableRecruiterList.filter((o, ind) => o.RecruiterId == recruiterId)[0]
      if (dataObj) {
        this.reAssignList[index].Count = dataObj.Count
        this.reAssignList[index].TotalPatientsCount = dataObj.TotalPatientsCount
      }
      else {
        this.reAssignList[index].Count = ''
        this.reAssignList[index].TotalPatientsCount = ''
      }

    }
  }

  OnlynumberAssignList(event): boolean {
    if (event.target.value == 0 && event.keyCode == 48) {
      return false;
    }
  }
}
