import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import io, { Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketHandlerService {

  /** url set */
  private URL = environment.nodeSocketUrl;
  public socket: any;
  private isConnected: boolean = false;
  public onlineUsers: any = {
    users: [],
    distros: []
  };
  visibilityState = 'visible';
  userObject = null;

  constructor(private _RecruiterService: RecruiterService, private NotificationsService: NotitificationsService, private router: Router) {
    document.addEventListener('visibilitychange', () => {
      this.visibilityState = document.visibilityState;
    });
    this.userObject = JSON.parse(localStorage.getItem('data'));
  }

  initialization(email: string, userObject:any): void {
    console.log(`SocketHandlerService ~ initialization ~ called email:`, email);
    this.socket = io(this.URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionDelay: 1000, // tries to reconnect every second
      reconnectionAttempts: 60, // maximum number of reconnection attempts
      pingInterval: 10000,
      pingTimeout: 120000,
    });
    this.socket.on('connect', () => {
      this.isConnected = true;
      console.log("socket connected");
      this.socket.emit('joinRoom', email, true, userObject,
        err => {
          if (err) {
            alert('Error while sending message');
            return;
          }
      });
        
      this.receivedMemoCreateEvent().subscribe(res => {
        console.log('==============> memo notification ', res);
      });
      
      this.receivedChatMensionEvent().subscribe(res =>{
        console.log('===========> chat notification ', res);
      });

      this.receivedIncomingInboxesEvent().subscribe(res =>{
        console.log('===========> inboxes notification ', res);
      });

      this.receivedBadgeCountEvent().subscribe(res =>{
        console.log('===========> badge count ', res);
      });

      this.getConnectedEmailEvent().subscribe(res =>{
        console.log('===========> check account connected ', res);
      });

      this.receivedChatCreateEvent().subscribe(res =>{
        console.log('===========> chat create ', res);
      });

      // this.receivedUserStatusEvent().subscribe(res =>{
      //   console.log('===========> users status ', res);
      // });

      this.receivedOnlineUserEvent().subscribe(onlineUsers =>{
        console.log('===========> online user event received => ', onlineUsers);
      });

      this.receivedOnlineDistroUserEvent().subscribe(onlineDistros =>{
        console.log('===========> online distro user ', onlineDistros);
        this.onlineUsers.distros = onlineDistros;
      });

      this.receivedAutomatedNotificationEvent().subscribe(res =>{
        console.log('===========> Automated notification', res);
      });

      this.receivedUnreadCountEvent().subscribe(res =>{
        console.log('===========> unread count', res);
      });
      
      this.updatePatientProfile().subscribe(res =>{
        console.log('===========> update patient count', res);
      });
    })

    // Socket on disconnect let's unsubscribe all the events and functions
    this.socket.on('disconnect', () => {
      console.log('SocketHandlerService ~ initialization ~ socket disconnected');
      this.isConnected = false;
      this.socket.off('memo-create');
      this.socket.off('chat-mension');
      this.socket.off('incoming-inboxes');
      this.socket.off('badge-count');
      this.socket.off('check-account-receiver');
      this.socket.off('chat-create');
      this.socket.off('online-users');
      this.socket.off('online-distro-users');
      this.socket.off('automated-notification');
      this.socket.off('unread-count');
      this.socket.off('patient-profile-update');
    })
  }

  notifyMe(data, type: string) {
    console.log("🚀 ~ SocketHandlerService ~ notifyMe ~ notifyMe:", this.visibilityState, data, type);
    if (this.visibilityState === 'hidden') {
      const options = {
        body: 'Click here to view',
        icon: '../../../assets/comtrak-ui-system-v-0.1/images/circle.svg',
      };
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        const title = (type === 'memo') ? (data ? data.user_name + ' send you memo.\n' : '') : type === 'chat' ? (data ? data.sender_name + ' mentioned you.\n' : '') : (data ? data.title : '');
        const content = (type === 'memo') ? (data ? data.title : '') : (data ? data.content : '');
        const notification = new Notification(title + (['memo', 'chat', 'automated-notification'].includes(type) ? content : ''), options);
        notification.onclick = (event) => {
          const parsedUrl = new URL(window.location.href);
          event.preventDefault();
          window.focus();
          const redirectURL = type === 'memo' ? `${parsedUrl.origin}/all-memos-screen` : null;
          if (redirectURL && redirectURL !== '') {
            this.router.navigate([redirectURL]);
          }
        };
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const title = (type === 'memo') ? (data ? data.user_name + ' send you memo.\n' : '') : type === 'chat' ? (data ? data.sender_name + ' mentioned you.\n' : '') : (data ? data.title : '');
            const content = (type === 'memo') ? (data ? data.title : '') : (data ? data.content : '');
            const notification = new Notification(title + (['memo', 'chat', 'automated-notification'].includes(type) ? content : ''), options);
            notification.onclick = (event) => {
              const parsedUrl = new URL(window.location.href);
              event.preventDefault();
              window.focus();
              const redirectURL = type === 'memo' ? `${parsedUrl.origin}/all-memos-screen` : null;
              if (redirectURL && redirectURL !== '') {
                this.router.navigate([redirectURL]);
              }
            };
          } else {
            alert("Please manually allow Notification permissions to review the Background notifications.");
          }
        });
      }
    } else {
      if (type === 'call') return;
      this._RecruiterService.notificationToasterFlag.next(true);
      this._RecruiterService.notificationRowData.next(data);
      this._RecruiterService.notificationType.next(type);
      this.NotificationsService.memoIdData.next('');
    }
  }

  public receivedMemoCreateEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('memo-create', (data) => {
        const parsedUrl = new URL(window.location.href);
        this.NotificationsService.refreshMemoGrid.next(parsedUrl.pathname === '/all-memos-screen' ? true : false);
        this.notifyMe(data, 'memo');
        observer.next(data);
      })
    });
  }

  public receivedChatMensionEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('chat-mension', (data) => {
        const parsedUrl = new URL(window.location.href);
        this.NotificationsService.refreshEmailGrid.next(parsedUrl.pathname === '/emailscreen' || parsedUrl.pathname === '/communication-incoming-message-responses' ? true : false);
        this.NotificationsService.refreshTextGrid.next(parsedUrl.pathname === '/textscreen' ? true : false);
        this.NotificationsService.refreshChatGrid.next(parsedUrl.pathname === '/chatscreen' ? true : false);
        this.notifyMe(data, 'chat');
        observer.next(data);
      })
    });
  }

  public receivedIncomingInboxesEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('incoming-inboxes', (data) => {
        const parsedUrl = new URL(window.location.href);
        this.NotificationsService.refreshEmailGrid.next(parsedUrl.pathname === '/emailscreen' ? true : false);
        this.NotificationsService.refreshTextGrid.next(parsedUrl.pathname === '/textscreen' ? true : false);
        this.NotificationsService.refreshChatGrid.next(parsedUrl.pathname === '/chatscreen' ? true : false);

        /* Lets refresh Phone screen list if type of notification is phone and on phone screen */
        if (data.type === 'phone') {
          if (parsedUrl.pathname === '/phonescreen') {
            this.NotificationsService.refreshPhoneGrid.next(true);
          }
        }

        this.notifyMe(data, 'inboxes');
        observer.next(data);
      })
    });
  }

  public receivedBadgeCountEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('badge-count', (data) => {
        observer.next(data);
      })
    });
  }

  public receivedUnreadCountEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('unread-count', (data) => {
        observer.next(data);
      })
    });
  }

  public emitChatJoinRoomEvent(entity_name: string, entity_id: string): void {
    console.log(`${new Date().toISOString()} emitChatJoinRoomEvent ~ entity_name:`, entity_name, entity_id);
    if (this.isConnected) {
      console.log("emitChatJoinRoomEvent Socket is connected. Joining Room: ", entity_name + '#' + entity_id);

      if (entity_id != '') {
        entity_id = '#' + entity_id;
      }

      this.socket.emit('joinRoom',  entity_name + entity_id, 
      err => {
          if (err){
              alert('Error while sending message');
              return;
          }
      });
    } else {
      console.log("emitChatJoinRoomEvent Socket is not connected. Retrying to join room...");
      setTimeout(() => this.emitChatJoinRoomEvent(entity_name, entity_id), 1000); // Retry after 1 second
    }
  }

  public leaveRoom(roomName: string): void {
    console.log(`${new Date().toISOString()} leaveRoom ~ entity_name:`, roomName);
    if (this.isConnected) {
      console.log("leaveRoom Socket is connected. Leavning Room: ", roomName);
      this.socket.emit('leaveRoom',  roomName, 
      err => {
          if (err){
              alert('Error while sending message');
              return;
          }
      });
    } else {
      console.log("leaveRoom Socket is not connected. Retrying to leave room...");
      setTimeout(() => this.leaveRoom(roomName), 1000); // Retry after 1 second
    }
  }

  public receivedChatCreateEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('chat-create', (data) => {
        observer.next(data);
      })
    });
  }

  public emitUserStatusEvent(status: string, userObj: object): void {
    this.socket.emit('user-status-update', userObj, status,
      err => {
        if (err) {
          alert('Error while sending message');
          return;
        }
      });
  }

  public chatOpenRead(data: object): void {
    this.socket.emit('open-chat-read', data,
      err => {
        if (err) {
          alert('Error while sending user data');
          return;
        }
      });
  }

  public receivedUserStatusEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('user-status', (data) => {
        observer.next(data);
      })
    });
  }

  public receivedOnlineUserEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('online-users', (data) => {
      console.log(`SocketHandlerService ~ this.socket.on ~ data:`, data);

        // Backward compatibility
        if (!data.users && !data.user) {
          observer.next(data);
          return;
        }

        if (data && data.users) {
          this.onlineUsers.users = data.users;
        }

        if (data.event && data.user) {
          if (data.event === 'online') {
            const userExists = this.onlineUsers.users.find((user) => user == data.user);
            if (!userExists) {
              this.onlineUsers.users.push(data.user);
            }
          }
          if (data.event === 'offline') {
            this.onlineUsers.users = this.onlineUsers.users.filter((user) => user !== data.user);
          }
        }

        console.log('receivedOnlineUserEvent ==> ', this.onlineUsers.users);

        observer.next(this.onlineUsers.users);
      })
    });
  }

  public getConnectedEmailEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('check-account-receiver', (data) => {
        observer.next(data);
      })
    });
  }

  public receivedOnlineDistroUserEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('online-distro-users', (data) => {
        observer.next(data);
      })
    });
  }

  public receivedAutomatedNotificationEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('automated-notification', (data) => {
        this.notifyMe(data, 'automated-notification');
        observer.next(data);
      })
    });
  }

  public receiveIncomingCall = (): Observable<any> => {
    console.log(`${new Date().toISOString()} ~ file: socket-handler.service.ts:294 ~ SocketHandlerService ~ receiveIncomingCall: Event Setup done`);
    return Observable.create((observer) => {
      this.socket.on('incoming-call', (data) => {
        console.log('Socket => Incoming call', data);
        observer.next(data.data);
      })
    });
  }


  public receiveCallObjEvent = (): Observable<any> => {
    return Observable.create((observer) => {
      this.socket.on('call-update', (data) => {
        console.log('Socket => Call update', data);
        observer.next(data);
      })
    });
  }

  public receiveCommunicationUpdate = (): Observable<any> => {
    return Observable.create((observer) => {
      this.socket.on('communication-update', (data) => {
        console.log('Socket => Communication update', data);
        observer.next(data);
      })
    });
  }
  
  public updatePatientProfile = (): Observable<any> => {
    return Observable.create((observer) => {
      this.socket.on('patient-profile-update', (data) => {
        console.log('Socket => patient profile update', data);
        observer.next(data);
      })
    });
  }

  public disconnectSocket(type = null): void {
    console.log(`SocketHandlerService ~ disconnectSocket ~ this.socket:`, this.socket);

    if (this.socket) {
      try {
  
        if (type === 'logout') {
          const email = this.userObject && this.userObject.user && this.userObject.user.email ? this.userObject.user.email : null;
          this.socket.emit('logout', email);
        }
  
        this.socket.disconnect();
        this.isConnected = false;
        this.socket.off('memo-create');
        this.socket.off('chat-mension');
        this.socket.off('incoming-inboxes');
        this.socket.off('badge-count');
        this.socket.off('check-account-receiver');
        this.socket.off('chat-create');
        this.socket.off('online-users');
        this.socket.off('online-distro-users');
        this.socket.off('automated-notification');
        this.socket.off('unread-count');
      } catch (err) {
        console.log(`SocketHandlerService ~ disconnectSocket ~ err:`, err);
      }
    }
  }
}
