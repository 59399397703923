import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbPopover, NgbPopoverConfig, NgbDateStruct, NgbDatepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'jquery';
import { DateModel } from './datepicker.model';

@Component({
    selector: 'app-datepicker',
    templateUrl: 'datepicker.component.html',
    styleUrls: ['datepicker.component.scss'],
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input()
    dateString: string;
    @Input()
    inputDatetimeFormat = "M/d/yyyy";
    @Input()
    hourStep = 1;
    @Input()
    minuteStep = 15;
    @Input()
    secondStep = 30;
    @Input()
    seconds = true;
    @Input()
    maxDate = {
        year: 2030, month: 12, day: 31
    }
    @Input()
    minDate = {
        year: 1900, month: 1, day: 31
    }
    @Output() public onChangeDate = new EventEmitter<any>();
    @Output() public onBlurDate = new EventEmitter<any>();
    @Input()
    disabled = false;
    private showTimePickerToggle = false;
    private datetime: DateModel = new DateModel();
    @ViewChild(NgbPopover)
    private popover: NgbPopover;
    private onTouched: () => void = noop;
    private onChange: (_: any) => void = noop;

    constructor(public config: NgbPopoverConfig, private inj: Injector) {
        config.autoClose = "outside";
        config.placement = "bottom";

    }

    ngOnInit(): void {
        // this.ngControl = this.inj.get(NgControl);
    }

    ngAfterViewInit(): void {
        this.popover.hidden.subscribe($event => {
            this.showTimePickerToggle = false;
        });
    }

    writeValue(newModel: string) {
        if (newModel) {
            this.datetime = Object.assign(
                this.datetime,
                DateModel.fromLocalString(newModel)
            );
            this.dateString = newModel;
            this.setDateStringModel();
        } else {
            this.datetime = new DateModel();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    toggleDateTimeState($event) {
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onInputChange($event: any) {
        this.onChangeDate.emit($event.target.value)
        const value = $event.target.value;
        const dt = DateModel.fromLocalString(value);
        if (dt) {
            this.datetime = dt;
            this.setDateStringModel();
        } else if (value.trim() === "") {
            this.datetime = new DateModel();
            this.dateString = "";
            this.onChange(this.dateString);

        } else {
            this.onChange(value);
        }
    }

    onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker) {

        const date = new DateModel($event);
        if (!date) {
            this.dateString = this.dateString;
            return;
        }
        if (!this.datetime) {
            this.datetime = date;
        }
        this.datetime.year = date.year;
        this.datetime.month = date.month;
        this.datetime.day = date.day;

        const adjustedDate = new Date(this.datetime.toString());
        if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
            this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
        }
        this.setDateStringModel();
        this.onChangeDate.emit(this.dateString)
    }

    setDateStringModel() {
        this.dateString = this.datetime.toString();
        this.onChange(this.dateString);
    }

    inputBlur($event) {
        this.onTouched();
        this.onBlurDate.emit(true);
    }
    meridian = true;

    toggleMeridian() {
        this.meridian = !this.meridian;
    }


    Onlynumber(event): boolean {
        var Regexpattern = /^[0-9/]+$/;
        var result = Regexpattern.test(event.key);
        return result;
    }

    preventDefault(event) {
        event.preventDefault();
    }

    clearDate() {
        this.dateString = '';
        this.datetime = new DateModel();
        this.onChange(this.dateString);
        this.onChangeDate.emit(null);
    }
}
