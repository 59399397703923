import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { EmailConfiguration, EmailTemplate } from '@sharedmodels/BackModels/ComTrak/Communication/EmailTemplate';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';

@Component({
  selector: 'app-templatestepper',
  templateUrl: './templatestepper.component.html',
  styleUrls: ['./templatestepper.component.scss', '../../style.component.scss']
})
export class TemplatestepperComponent implements OnInit {
  @ViewChild('stepper') public myStepper: MatStepper;
  isCompleted: boolean = true
  emailTemplateDataObject: EmailTemplate
  emailConfigDataObject: EmailConfiguration
  constructor(public dialogRef: MatDialogRef<TemplatestepperComponent>, public templateService: TemplateService, @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    if (this.data && this.data.dataObject) {
      this.emailTemplateDataObject = this.data.dataObject.emailTemplateData
      this.emailConfigDataObject = this.data.dataObject.emailConfigData
    }
    // this.templateService.IsSaveEmailConfig.subscribe(res=>{
    //   if(res){
    //     this.onNoClick()
    //   }
    // })
  }

  onNoClick(): void {
    this.dialogRef.close();

    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });

    this.templateService._emailCondfigByTemplateList = []
    this.templateService.isCompleted = false
  }

  goForward(stepper: MatStepper) {
    this.templateService.isCompleted = true;
    this.myStepper.next();
  }
}
