import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-draftdetailsprescreen',
  templateUrl: './draftdetailsPrescreen.component.html',
  styleUrls: ['./draftdetailsPrescreen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})  
export class DraftdetailsPrescreenComponent implements OnInit {
  public myData: any = [];
  htmlContent: any;

  constructor(
    public dialogRef: MatDialogRef<DraftdetailsPrescreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {}  

  ngOnInit() {
    this.myData = this.data.rowData;
    this.updateType();
  }

  updateType() {
    if (this.myData.type === 'phone') {
      this.myData.type = 'Phone Script';
    } else if (this.myData.type === 'text') {
      this.myData.type = 'Text Script';
    } else if (this.myData.type === 'email') {
      this.myData.type = 'Email Script';
    }
  }
  
  formatDate1(dateTime: string): string {
    if(dateTime != null){
      const localDateTime = new Date(dateTime);
      const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy | h:mm a');
      return formattedDate;
    }else{
      return null
    }
  }

  formatDate(dateTime: string): string {
    if (!dateTime) {
      return null;
    }
    const localDateTime = new Date(dateTime);
    const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy');
    return formattedDate;
  }
  
  onNoClick(): void {
    this.dialogRef.close(); 
  } 
}
