import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserRoleService } from "@sharedservices/BackServices/ComTrak/role/userrole.service";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ColDef } from "ag-grid-community";
import { TemplaterenderComponent } from "@app/aggrid/templaterender/templaterender.component";
import {
  TherapeuticArea,
  TherapeuticAreaIndication,
} from "../../../Shared/Models/BackModels/ComTrak/Setups/TherapeuticArea";
import { TherapeuticAreaService } from "./../../../Shared/Services/BackServices/ComTrak/Therapeutic/therapeutic-area.service";

@Component({
  selector: "app-createtherapeuticindication",
  templateUrl: "./createtherapeuticindication.component.html",
  styleUrls: ["./createtherapeuticindication.component.scss", '../../style.component.scss'],
})
export class CreatetherapeuticindicationComponent implements OnInit {
  @ViewChild("checkBox") checkBox: TemplateRef<any>;
  @ViewChild("edit") edit: TemplateRef<any>;

  TherapeuticAreaIndicationForm: FormGroup;
  submitted: boolean = false;
  TherapeuticIndicationObject: TherapeuticAreaIndication;
  activeEditID: any = -1;
  isedit: boolean = false;
  columnDefs: ColDef[];
  _TherapeuticName: string = ""
  public _data: any;
  public gridApi: any;
  public gridColumnApi: any;
  public defaultColDef;

  constructor(
    public dialogRef: MatDialogRef<CreatetherapeuticindicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public userRoleService: UserRoleService,
    public spinner: NgxSpinnerService,
    public _Global: GlobalfunctionService,
    public _TherapeuticAreaService: TherapeuticAreaService
  ) { }

  ngOnInit() {
    this.initForm();
    this._Global.gotoTop();
    this.gridConfig();
    this._TherapeuticName = this.data && this.data.therapeuticIndicationData ? this.data.therapeuticIndicationData.Name : ""
    console.log(this.data);
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef;
    this.columnDefs = [
      { headerName: "Name", field: "Name", sortable: true, filter: true },
      {
        headerName: "Is Active",
        field: "IsActive",
        sortable: true,
        filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.checkBox,
        },
      },
      {
        headerName: "Edit",
        colId: "edit",
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.edit,
        },
      },
    ];
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(
      this.data.therapeuticIndicationData.TherapeuticAreaIndications
    );
  }

  initForm() {
    this.TherapeuticAreaIndicationForm = this.fb.group({
      Name: ["", Validators.required],
      TherapeuticAreaId: [""],
      IsActive: [false],
      Id: [""],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga("send", "event", {
    //   eventCategory: "click",
    //   eventLabel: "buttonClick",
    //   eventAction: "onNoClick",
    // });
  }

  get f() {
    return this.TherapeuticAreaIndicationForm.controls;
  }

  onClickEdit(rowData: any) {
    // console.log("Ediited Row : ", rowData);
    this.isedit = true;
    this.TherapeuticAreaIndicationForm.controls.Name.setValue(rowData.Name);
    this.TherapeuticAreaIndicationForm.controls.IsActive.setValue(
      rowData.IsActive
    );
    this.activeEditID = rowData.Id;
  }

  reset() {
    this.isedit = false;
    this.activeEditID = -1;
    this.TherapeuticAreaIndicationForm.controls.Name.setValue("");
    this.TherapeuticAreaIndicationForm.controls.IsActive.setValue(false);
  }

  onSubmit() {
    this.submitted = true;
    // console.log(
    //   "Create Therapeutic Indication Name : ",
    //   this.TherapeuticAreaIndicationForm.controls.Name.value
    // );
    // console.log(
    //   "Create Therapeutic Indication IsActive : ",
    //   this.TherapeuticAreaIndicationForm.controls.IsActive.value
    // );

    if (this.TherapeuticAreaIndicationForm.invalid) {
      return;
    } else {
      this.TherapeuticIndicationObject = {
        Name: this.TherapeuticAreaIndicationForm.controls.Name.value,
        IsActive: this.TherapeuticAreaIndicationForm.controls.IsActive.value,
        TherapeuticAreaId: this.data.therapeuticIndicationData.Id,
      };

      // console.log(this.activeEditID);
      // console.log(this.isedit);
      // console.log("Edit Status : ", this.isedit);

      if (this.isedit == true) {
        this.TherapeuticIndicationObject.Id = this.activeEditID;
      }

      // console.log("Therapeutic object : ", this.TherapeuticIndicationObject);

      this._TherapeuticAreaService.checkIfEditOrAddTherapeuticIndication(
        this.TherapeuticIndicationObject,
        this.isedit
      );

      this._TherapeuticAreaService.isCallApi.subscribe((res) => {
        if (res) {
          this.onNoClick();
          this.isedit = false;
          this.initForm();
          this.reset();
          this.submitted = false;
          this.activeEditID = -1;
          this._TherapeuticAreaService.isCallApi.next(false)
        } else {
        }
      });
    }
  }
}
