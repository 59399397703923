// Angular Imports
import { NgModule } from '@angular/core';
// import { CountUpModule } from 'ngx-countup';

// This Module's Components
import { TotalrecordshowComponent } from './totalrecordshow.component';

@NgModule({
    imports: [
        // CountUpModule,
    ],
    declarations: [
        // TotalrecordshowComponent,
    ],
    exports: [
        // TotalrecordshowComponent,
    ]
})
export class TotalrecordshowModule {

}
