import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { SiteRooms } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { CommandModel } from '@syncfusion/ej2-angular-grids';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-createsiteroom',
  templateUrl: './createsiteroom.component.html',
  styleUrls: ['./createsiteroom.component.scss']
})
export class CreatesiteroomComponent implements OnInit {
  @ViewChild('checkBox') checkBox: TemplateRef<any>;
  @ViewChild('edit') edit: TemplateRef<any>;
  siteRoomForm: FormGroup
  submitted: boolean = false
  columnDefs: ColDef[]
  public gridApi: any;
  public gridColumnApi: any;
  public defaultColDef;
  isEdit: boolean = false;
  constructor(public fb: FormBuilder, public siteService: SiteService, public dialogRef: MatDialogRef<CreatesiteroomComponent>,
    public _Global: GlobalfunctionService) {
  }

  ngOnInit() {
    this.gridConfig()
    this.initForm()
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  initForm() {
    this.siteRoomForm = this.fb.group({
      Id: [0],
      RoomNo: ['', Validators.required],
      IsActive: [false],
      SiteId: [this.siteService.siteId],
    })
  }

  get f() {
    return this.siteRoomForm.controls;
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef
    this.columnDefs = [
      { headerName: 'Room No', field: 'RoomNo', sortable: true, filter: true },
      {
        headerName: 'Active', field: 'IsActive', sortable: true, filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.checkBox
        }
      },
      {
        headerName: 'Edit',
        colId: 'edit',
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.edit
        }
      },
    ];
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.siteService._roomsbySite);
  }

  onSubmit() {
    this.submitted = true;
    if (this.siteRoomForm.invalid) {
      return;
    }
    else {
      this.siteService.SaveAndEditSiteRooms(this.siteRoomForm.value, this.isEdit)
      this.siteService.isSaveSiteRooms.subscribe(res => {
        if (res) {
          this.isEdit=false
          this.submitted = false
          this.initForm()
        }
      })
    }
  }

  setEditData(row: SiteRooms) {
    this.isEdit = true
    this.siteRoomForm.patchValue(row)
  }
}
