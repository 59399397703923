import { Staff } from './../../../../Models/BackModels/ComTrak/Setups/Staff';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { Subject, Observable } from 'rxjs';
import { CRIOStudyVisits } from '@shared/Models/BackModels/ComTrak/Setups/CrioStudyVisit';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class CRIOService {
    CRIOPatientsList: Staff[];
    public IsSaveBulkInsert = new Subject<boolean>();
    public IsSaveBulkInsertStudies = new Subject<boolean>();
    _CRIOStudyVisits: any[] = [];
    searchString: string = ''
    _StudyWiseCRIOStudyVisits: any[];
    _CRIOStudies: any[];
    _CRIOStudiesByComtrekSite: any[];
    searchStringStudies: string = '';
    _CRIOSites: any[];
    _CRIODumpFiles: any[];
    _IncompleteCRIOInteractions: any[];
    crioSearchString: string = ''
    crioSiteId: number = null
    criostudyId: number = null
    
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private spinner: NgxSpinnerService) { }

    GetAllCRIOPatients(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'CRIO?searchString=' + searchString + "&apiType=GetAllCRIOPatients", { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.CRIOPatientsList = <any[]>data;
                    this.GetCRIOStudiesByComtrekSite('');
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    BulkInsertStudyVisits(body: any[], fileName: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'CRIO/BulkInsertStudyVisits?fileName=' + fileName, body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.GetCRIOStudyVisits(this.searchString)
                    this._Global.showSuccessMsg("List Upload Unsuccessfully.");
                    this.IsSaveBulkInsert.next(true)
                }, error => {
                    this.IsSaveBulkInsert.next(false)
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    BulkInsertStudies(body: any[], fileName: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'CRIO/BulkInsertStudies?fileName=' + fileName, body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.GetCRIOStudies(this.searchStringStudies)
                    this._Global.showSuccessMsg("List Upload Unsuccessfully.");
                    this.IsSaveBulkInsertStudies.next(true)
                }, error => {
                    this.IsSaveBulkInsertStudies.next(false)
                    this._Global.showErrorMsg(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetCRIOStudyVisits(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + "CRIO?apiType=GetCRIOStudyVisits&searchString=" + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._CRIOStudyVisits = <any[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetCRIOStudies(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + "CRIO?apiType=GetCRIOStudies&searchString=" + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._CRIOStudies = <any[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetCRIOStudiesByComtrekSite(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
             this._http.get(myGlobals._apiPath + "CRIO?apiType=getGetCRIOStudiesByComtrekSite&searchString=" + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._CRIOStudiesByComtrekSite = <any[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
             this.spinner.hide()
             
        }
    }
    GetCRIOSites() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + "CRIO?apiType=GetCRIOSites", { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._CRIOSites = <any[]>data;
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getIncompletePatientInteractions(searchString: string, siteId: number, studyId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'CRIO?searchString=' + searchString + "&apiType=getIncompletePatientInteractions&siteId=" + siteId + "&studyId=" + studyId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._IncompleteCRIOInteractions = <any[]>data;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    AddBulkCRIOPatientInteractions(body: any[]) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'CRIO/AddBulkCRIOPatientInteractions', body, { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this.getIncompletePatientInteractions(this.crioSearchString, this.crioSiteId, this.criostudyId)
                    this._Global.showSuccessMsg("Save Unsuccessfully.");
                }, error => {
                    this.getIncompletePatientInteractions(this.crioSearchString, this.crioSiteId, this.criostudyId)
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }
    UploadStudyVisit(CRIOStudyVisits: CRIOStudyVisits[], file: string): Observable<any> {
        try{
            return this._http.post(myGlobals._apiPath + "CRIO/AddCRIOStudyVisit?fileName=" + file, CRIOStudyVisits, { headers: this._Global.httpOptions })
        }
        catch (x){
        }
    }

    GetCRIODumpFiles(searchString?: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + "CRIO?apiType=CRIODumpFileNames", { headers: this._Global.httpOptions })
                .subscribe((data:any) => {
                    if(searchString){
                        this._CRIODumpFiles = data.filter(file => {
                            return file.FileName && file.FileName.toLowerCase().includes(searchString.toLowerCase())
                        });
                    } 
                    else
                    this._CRIODumpFiles = data;
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    searchCrioDumpFiles(searchString){
        this._CRIODumpFiles = this._CRIODumpFiles.filter(file =>
            file.FileName.toLowerCase().includes(searchString.toLowerCase())
          );
    }

    uploadVisitsImortFile(attachment: File, fileName: string): Observable<any> {
        const formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('attachment', attachment);
        const header = this._Global.httpOptions;
        header.append("Content-Type", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        return this._http.post(myGlobals._apiPath + 'CRIO/SaveCrioVisitImportFile', formData, {headers:header});
      }

      downloadFile(fileName: string, fileType: string): Observable<any> {
        let fileExtension = fileType;
        return this._http
          .post(
            myGlobals._apiPath + "CRIO/DownloadVisitImportFile?fileName=" + fileName,
            "",
            { responseType: "blob" }
          )
          .pipe(
            map((res) => {
              var blob = new Blob([res], { type: fileExtension });
              return blob;
            })
          );
      }
   
}
