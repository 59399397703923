import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as myGlobals from "@shared/global_var";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { NgxSpinnerService } from "ngx-spinner";

import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class RecruiterAssignmentService {
  recruiters = new Subject<any>();
  recruiterList = this.recruiters.asObservable();

  poolInfo = new Subject<any>();
  poolStats = this.poolInfo.asObservable();

  recruiterStats = new Subject<any>();
  recruiterStatsData = this.recruiterStats.asObservable();

  saveRecruiterAssignment = new Subject<any>();
  saveRecruiterAssignmentResponce = this.saveRecruiterAssignment.asObservable();

  ReleaseAll = new Subject<any>();
  ReleaseAllResponce = this.ReleaseAll.asObservable();

  constructor(
    public _http: HttpClient,
    private _Global: GlobalfunctionService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) {}

  getRecruiterList(poolId:string): any {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .get(myGlobals._apiPatientMgmtApiPath + "Recruiter?poolId="+poolId, {
          headers: this._Global.httpOptions,
        })
        .subscribe(
          (res: any) => {
            this.recruiters.next(res);
            //this.spinner.hide();
            //this._Global.IsReady = true;
          },
          (error) => {
            this._Global.handleError(error);
            //this.spinner.hide();
            //this._Global.IsReady = true;
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetPoolStats(poolId: string): any {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .get(
          myGlobals._apiPatientMgmtApiPath +
            "Recruiter/patient-assignments-details?poolId=" +
            poolId,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (res: any) => {
            this.poolInfo.next(res);
            this.spinner.hide();
            this._Global.IsReady = true;
          },
          (error) => {
            this.poolInfo.next({success:false});
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
          }
        );
    } catch (x) {
      this.poolInfo.next({success:false});
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetRecruiterStats(poolId: string, recruiterId: string, index: number): any {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .get(
          myGlobals._apiPatientMgmtApiPath +
            "Recruiter/assigned-patients-count?poolId=" +
            poolId +
            "&recruiterId=" +
            recruiterId,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.recruiterStats.next({ data: res.data, index: index });
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  SaveRecruiterAssignment(poolId: string, data: any): any {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .post(
          myGlobals._apiPatientMgmtApiPath +
            "Recruiter/assign-patients-recruiters?poolId=" +
            poolId,
          data,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.saveRecruiterAssignment.next(res);
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  releaseAllPatients(poolId: string, recruiterId: string, noOfPatients ): any {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http
        .delete(
          myGlobals._apiPatientMgmtApiPath +
            "Recruiter/release-all-patients-recruiter?poolId="+poolId+"&recruiterId="+recruiterId,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.ReleaseAll.next({...res,recruiterId, poolId, noOfPatients});
          },
          (error) => {
            this._Global.handleError(error);
            this.spinner.hide();
            this._Global.IsReady = true;
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }
}
