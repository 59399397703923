import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import { Leads } from '@sharedmodels/BackModels/ComTrak/Leads/lead';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { Router } from '@angular/router';
@Injectable({ 
    providedIn: 'root'
})   
export class ScriptService {
    public isCallApi = new Subject<boolean>();
    public isDiscardLeadApi = new Subject<boolean>();
    public isCallApiREvert = new Subject<boolean>();
    public _leadList: Leads[];
    public _leadValidationList: Leads[];
    public _leadsMachedPatients: Patient[];
    public _adminleadList: Leads[];
    public _allLeadList: Leads[];
    public isCallApiAssign = new Subject<boolean>();
    public leadsReport: any;
    public leadsReportArray: any;
    public _discardleadList: Leads[];
    public leadsValidationCount: number = 0;
    public assingableLeadsCount: number = 0;
    public UnassingableLeadsCount: number = 0;
    public incorrectLeadsCount: number = 0;
    public mergedLeadsCount: number = 0;
    public discardedLeadsCount: number = 0;
    public totalLeadsCount: number = 0;
    public leadsCount: number;
    public mergedProfileLeadList: any;
    public AssignCount: any;
    public UnAssignCount: any;

    public _scriptDraftList:any;
    public _scriptPendingReviewList:any;
    public _scriptPublishData:any;
    public _scriptDiscardedData:any;
    openScriptDialog = new BehaviorSubject<{openFlag: boolean, type: string}>({openFlag: false, type: ''});
    openGlobalDialog = new BehaviorSubject<{openFlag: boolean, type: string}>({openFlag: false, type: ''});
    insertScriptData = new BehaviorSubject<{content: string, script_type: string, data?: any }>({content: '', script_type: '',data: null})
    exitFromInteraction = new BehaviorSubject<boolean>(false);

    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private spinner: NgxSpinnerService, public router: Router) { }

    GetLeadsList(searcString: string, campaignId?: number, campaignRefId?: number): any {
        try {
            this.spinner.show();
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Lead?searchString=' + searcString + '&currentStatusId=' + LeadsStatusEnumns.AssignableLead
                + '&callApiType=GetAll' + '&campaignId=' + campaignId + '&campaignRefId=' + campaignRefId, { headers: this._Global.httpOptions }).subscribe((res: Leads[]) => {
                    this._leadList = <Leads[]>res
                    // this.leadsCount = <Leads[]>res
                    // this.assingableLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.AssignableLead).length
                    // this.UnassingableLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.UnAssignableLead).length
                    // this.incorrectLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.IncorrectLead).length;
                    // this.mergedLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.MergedLead).length;
                    // this.discardedLeadsCount = this.leadsCount.filter(o => o.CurrentStatusId == LeadsStatusEnumns.DiscardLead).length;
                    // this.totalLeadsCount = this.leadsCount.length;
                    this.spinner.hide();
                    this._Global.IsReady = true;
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                })
                ;
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    getDataProduct(){
        this.spinner.show();
        try {
            this._http.get("https://fakestoreapi.com/products", { headers: this._Global.httpOptions }).subscribe((res: any) => {
                this._scriptDraftList =<any>res
                this.spinner.hide();
                this._Global.IsReady = true
                console.log(res);
            },error => {
                this._Global.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            }
        );
        } catch (error) {
            console.error(error);
        }
    }

    // getScriptedDraftData(){
    //     this.spinner.show();
    //     try{
    //     this._http.get(myGlobals._apiPathv3 + 'script/list?status=draft',{headers:this._Global.httpOptions}).subscribe((res:any)=>{
    //         console.log(res)
    //         this._scriptDraftList =<any>res;
    //         this.spinner.hide();
    //         this._Global.IsReady = true
    //     },error => {
    //         this._Global.handleError(error);
    //         this.spinner.hide();
    //         this._Global.IsReady = true;
    //     });
    //     }catch(error){
    //         this._Global.showErrorMsg(error);
    //         this._Global.IsReady = true;
    //         this.spinner.hide()
    //     }
    // }

    // getScriptPendingReviewData(){
    //     this.spinner.show();
    //     try{
    //     this._http.get(myGlobals._apiPathv3 + 'script/list?status=pending_review',{headers:this._Global.httpOptions}).subscribe((res:any)=>{
    //         console.log(res)
    //         this._scriptPendingReviewList=<any>res;
    //         this.spinner.hide();
    //         this._Global.IsReady = true
    //     },error =>{
    //      this._Global.handleError(error);
    //      this.spinner.hide();
    //      this._Global.IsReady = true
    //     }
    //     );
    //     }
    //     catch(error){
    //         this._Global.showErrorMsg(error);
    //         this._Global.IsReady = true;
    //         this.spinner.hide()
    //     }
    // }

    // getScriptPublishData(){
    //     this.spinner.show();
    //     try{
    //         this._http.get(myGlobals._apiPathv3 + 'script/list?status=published',{headers:this._Global.httpOptions}).subscribe((res:any)=>{
    //             console.log(res)
    //            this._scriptPublishData = <any>res;
    //            this.spinner.hide();
    //            this._Global.IsReady = true
    //         },error => {
    //             this._Global.handleError(error);
    //             this.spinner.hide();
    //             this._Global.IsReady = true
    //         }
    //         );
    //     }
    //     catch(error){
    //     this._Global.showErrorMsg(error);
    //     this._Global.IsReady = true;
    //     this.spinner.hide();
    //     this._Global.IsReady = true
    //     }
    // }
 
//     getScriptDicardedData(){
//         try{
//    this._http.get(myGlobals._apiPathv3 + 'script/list?status=discarded',{headers:this._Global.httpOptions}).subscribe((res:any)=>{
//     console.log(res)
//     this._scriptDiscardedData =<any>res;
//     this.spinner.hide();
//     this._Global.IsReady = true;
//    },error =>{
//     this._Global.handleError(error);
//     this.spinner.hide();
//     this._Global.IsReady = true;
//    }) 
//         }
//         catch(error){
//        this._Global.showErrorMsg(error);
//        this._Global.IsReady =true;
//        this.spinner.hide();
//        this._Global.IsReady =true
//         }
//     }

}
