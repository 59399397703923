import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailConfiguration, EmailConfigurationDetail, EmailConfigurationReminders } from '@sharedmodels/BackModels/ComTrak/Communication/EmailTemplate';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { StudyVisit } from '@sharedmodels/BackModels/ComTrak/Setups/StudyVisit';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { StudyvisitService } from '@sharedservices/BackServices/ComTrak/Setups/studyvisit.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { CommandClickEventArgs, CommandModel } from '@syncfusion/ej2-angular-grids';
import { ColDef } from 'ag-grid-community';
import { th } from 'date-fns/locale';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';

@Component({
  selector: 'app-createalertconfiguration',
  templateUrl: './createalertconfiguration.component.html',
  styleUrls: ['./createalertconfiguration.component.scss', '../../style.component.scss']
})
export class CreatealertconfigurationComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    // selectAllText: 'Select All',
    // unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5000,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  @ViewChild('IsActive') IsActive: TemplateRef<any>;
  format: string = 'M/d/yyyy';
  @ViewChild('edit') edit: TemplateRef<any>;
  ConfigurationForm: FormGroup
  lovType: Lov[] = []
  ReminderArray: EmailConfigurationReminders[] = []
  displayRemoveIcon: boolean = false;
  submitted: boolean;
  columnDefinition: CustomGridColumns[]
  public commands: CommandModel[];
  @Input() emailConfigDataObject: EmailConfiguration[] = []
  @Input() templateId: number
  public defaultColDef;
  columnDefs: ColDef[]
  public gridApi: any;
  public gridColumnApi: any;
  editData: boolean = false;
  lovData: Lov[] = []
  errorMessage: string = "";
  hideNotificationDate: boolean = false;
  hideCampaignFields: boolean = false;
  visitsofStudy: StudyVisit[] = [];
  studyVisitsByStudy: StudyVisit[] = [];
  showReminder: boolean;
  toPhoneNumber: string = ""
  btntext: string = "Test SMS";
  submittedTest: boolean = false
  _EmailConfigurationDetail: EmailConfigurationDetail[] = []
  reminderIntervalText: string;
  EmailConfigurationDetailsArray: EmailConfigurationDetail[] = [];
  showBtnTest: boolean = false;
  studiesBySites: any[];
  siteIds: any[] = [];
  submittedEmail: boolean = false;
  toEmail: string = "";
  StudyWiseStatuses: any[] = [];
  StudyWiseStatusesArray: any[] = [];
  constructor(public fb: FormBuilder, public statusService: StatusService, public siteService: SiteService,
    public studyService: StudyService, public studyVisitService: StudyvisitService, public templateService: TemplateService,
    public campaignService: CampaignService, public lovService: LovService, public campaignrefService: CampaignReferralSourceService,
    public _Global: GlobalfunctionService, public patientInteraction: PatientInteractionService, private modalService: NgbModal, private spinner: NgxSpinnerService) {
    this.commands = [
      { title: 'Edit Reminder', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-edit e-icons' } },
    ];
  }

  ngOnInit() {
    this.statusService._Configstatuses = []
    this.getSendTo()
    this.gridConfig()
    this.initForm()
    this.getSMSIntervalHrsLOV()
    if (this.emailConfigDataObject) {
      this.templateService._emailCondfigByTemplateList = this.emailConfigDataObject
    }
    if (this.templateService.templateObject) {
      this.ConfigurationForm.controls.EmailTemplateId.setValue(this.templateService.templateObject.Id)
      if (this.templateService.templateObject.TypeName == "Marketing" || this.templateService.templateObject.TypeId == 39 || this.templateService.TypeId == 39) {
        this.templateService.showNotificationDate = true
        this.templateService.showCampaignFields = true
      }
      else {
        this.templateService.showNotificationDate = false
        this.templateService.showCampaignFields = false
      }
    }
    else {
      this.ConfigurationForm.controls.EmailTemplateId.setValue(this.templateService.templateId)
      if (this.templateService.TypeId == 39) {
        this.templateService.showNotificationDate = true
        this.templateService.showCampaignFields = true
      }
      else {
        this.templateService.showNotificationDate = false
        this.templateService.showCampaignFields = false
      }
    }
    this.templateService.IsSaveEmailTemplate.subscribe(res => {
      if (res) {
        this.ConfigurationForm.controls.EmailTemplateId.setValue(this.templateService.templateObject.Id)
      }
    })
    this.ChangeConfigType()
    // this.ChangeConfigFrequency()
  }



  initForm() {
    this.ConfigurationForm = this.fb.group({
      Id: [0],
      Name: ['', Validators.required],
      EmailTemplateId: ['', Validators.required],
      SendTo: [0, Validators.required],
      SendAsEmail: [false],
      SendAsText: [true],
      SiteId: [''],
      StatusId: [''],
      SubStatusId: [''],
      StudyId: [''],
      StudyVisitId: [''],
      IsActive: [false],
      SiteIdArray: [],
      StatusIdArray: ['', Validators.required],
      StudyVisitStatusIdArray: [],
      StudyIdArray: [],
      StudyVisitIdArray: [],
      CampaignId: [''],
      CampaignReferralSourcesId: [''],
      NotificationDate: [''],
      SendReminder: [false],
      SendInstant: [false],
      SendSchedule: [false],
      EmailConfigurationReminders: [],
      StatusIds: [],
      SiteIds: [],
      StudyIds: [],
      StudyStatusIdArray: [],
      ConfigType: [1, Validators.required],
      ConfigFrequency: ['', Validators.required],
      EmailConfigurationDetails: []
    })
  }

  ChangeCampaign() {
    this.campaignrefService.LoadCampaignReferralSourceByCampaignId(this.ConfigurationForm.controls.CampaignId.value)
  }

  getSendTo() {
    this.lovType = [
      {
        Id: 0,
        Name: "Patients"
      },
      // {
      //   Id: 1,
      //   Name: "Staffs"
      // },
    ]
  }

  getSMSIntervalHrsLOV() {
    this.lovService.getLovDataById(LovStatusMasterID.SMSIntervalHrs).subscribe(res => {
      this.lovData = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  ChangeReminder() {
    this.ReminderArray = []
    if (this.ConfigurationForm.controls.SendReminder.value == true) {
      this.ReminderArray.push({
        EmailConfigurationId: 0,
        Interval: "",
        ReminderText: "",
        ReminderType: 1,
        IsActive: true
      })
    }
    else {
      this.ReminderArray = []
    }
  }

  checkTableValidation() {
    this.ReminderArray = this.ReminderArray
    let lastObject = this.ReminderArray[this.ReminderArray.length - 1]
    var checkValidate = lastObject.Interval == "" ? true : false;
    if (checkValidate) {
      let index = this.ReminderArray.findIndex(o => o.Interval == lastObject.Interval)
      let errorMessage = ` ${this.reminderIntervalText} ${index + 1} Is Required`
      this._Global.showErrorMsg(errorMessage)
      return true
    }
    else {
      return false
    }
  }

  AddReminderArray(index: number) {
    if (index != null) {
      let checkValidate = this.checkTableValidation()
      if (checkValidate == true) {
        return
      }
      else {
        this.ReminderArray.push({
          EmailConfigurationId: 0,
          Interval: "",
          ReminderText: "",
          ReminderType: 1,
          IsActive: true
        })
      }
    }
    else {
      this.ReminderArray.push({
        EmailConfigurationId: 0,
        Interval: "",
        ReminderText: "",
        ReminderType: 1,
        IsActive: true
      })
    }
  }

  showRemoveIcon() {
    if (this.ReminderArray.length === 1) {
      this.displayRemoveIcon = false;
    } else {
      this.displayRemoveIcon = true;
    }
  }

  SpliceReminderArray(index: number) {
    if (this.ReminderArray.length == 1) {
      return
    }
    else {
      this.ReminderArray.splice(index, 1)
    }
  }

  get f() {
    return this.ConfigurationForm.controls;
  }

  setNullValues() {
    // this.ConfigurationForm.reset()
    this.ConfigurationForm.controls.StatusIdArray.setValue([])
    this.ConfigurationForm.controls.SiteIdArray.setValue([])
    this.ConfigurationForm.controls.StudyVisitStatusIdArray.setValue([])
    this.ConfigurationForm.controls.StudyIdArray.setValue([])
    this.ConfigurationForm.controls.StudyVisitIdArray.setValue([])
  }

  onSubmit() {
    this.submitted = true;
    if (this.ConfigurationForm.invalid) {
      return;
    }
    else {
      if (this.ConfigurationForm.controls.ConfigFrequency.value != 0) {
        let checkValidate = this.checkTableValidation()
        if (checkValidate == true) {
          return
        }
      }
      if (this.errorMessage != "") {
        this._Global.showErrorMsg(this.errorMessage)
      }
      else {
        this.SetEmailConfigurationDetailData()
        if (this.ConfigurationForm.controls.SendReminder.value == true || this.ConfigurationForm.controls.SendSchedule.value == true) {
          this.ConfigurationForm.controls.EmailConfigurationReminders.setValue(this.ReminderArray)
        }
        else {
          this.ConfigurationForm.controls.EmailConfigurationReminders.setValue([])
        }
        if (this.editData) {
          this.templateService.EditEmailConfiguration(this.ConfigurationForm.value, false)
          this.gridConfig()
        }
        else {
          this.templateService.SaveEmailConfiguration(this.ConfigurationForm.value, false)
          this.gridConfig()
        }
        // let count = 0
        this.templateService.IsSaveEmailConfig.subscribe(res => {
          if (res) {
            this.setNullValues()
            this.initForm()
            this.ConfigurationForm.controls.SendTo.setValue(0)
            // this.ConfigurationForm.controls.ConfigType.setValue(1)
            this.ConfigurationForm.controls.EmailTemplateId.setValue(this.templateService.templateId)
            this.ReminderArray = []
            // }
          }
        })
        this.submitted = false;
      }
    }
  }

  setEditData(row: EmailConfiguration) {
    this.StudyWiseStatuses = []
    this.StudyWiseStatusesArray = []
    this.EmailConfigurationDetailsArray = row.EmailConfigurationDetails
    if (row.StudyIdArray && row.StudyIdArray.length && row.StudyIdArray.length > 0) {
      this.statusService.GetComplainceStatus()
      row.StudyIdArray.forEach((element) => {
        this.onSelectStudy(element)
      });
      this.onDropDownClose()
    }
    if (row.SiteIdArray && row.SiteIdArray.length && row.SiteIdArray.length > 0) {
      this.statusService.GetComplainceStatus()
      row.SiteIdArray.forEach((element: any) => {
        this.siteIds.push({ Id: element })
        this.onSelectSite(element)
      });
      this.onDropDownCloseSite()
    }
    if (row.StudyStatusIdArray && row.StudyStatusIdArray.length && row.StudyStatusIdArray.length > 0) {
      row.StudyStatusIdArray.forEach((element: any) => {
        this.onSelectStudy(element)
      });
      this.onDropDownClose()
    }
    this.editData = true
    this.getSMSIntervalHrsLOV()
    this.ConfigurationForm.patchValue(row)
    this.ConfigurationForm.controls.SiteIdArray.setValue(row.sitesArray)
    this.ConfigurationForm.controls.StatusIdArray.setValue(row.statusesArray)
    this.ConfigurationForm.controls.StudyVisitStatusIdArray.setValue(row.studyVisitStatusArray)
    this.ConfigurationForm.controls.StudyIdArray.setValue(row.studysArray)
    this.ConfigurationForm.controls.StudyVisitIdArray.setValue(row.studyVisitsArray)
    this.ConfigurationForm.controls.StudyStatusIdArray.setValue(row.studyStatusesArray)
    this.ReminderArray = []
    if (row.SendInstant == true) {
      this.ConfigurationForm.controls.ConfigFrequency.setValue(0)
    }

    else if (row.SendReminder == true) {
      this.ConfigurationForm.controls.ConfigFrequency.setValue(1)
    }

    else if (row.SendSchedule == true) {
      this.ConfigurationForm.controls.ConfigFrequency.setValue(2)
    }

    if (row.ConfigType == "Send As Email") {
      this.ConfigurationForm.controls.ConfigType.setValue(0)
    }
    else {
      this.ConfigurationForm.controls.ConfigType.setValue(1)
    }
    if (row.SendReminder == true || row.SendSchedule == true) {
      this.ReminderArray = row.EmailConfigurationReminders
      console.log("this.ReminderArray", this.ReminderArray)
      row.EmailConfigurationReminders.forEach((elemet: EmailConfigurationReminders) => {
        this.showRemoveIcon()
      })

    }
    else {
      this.ReminderArray = []
    }
    this.ChangeConfigType()
    if (this.ConfigurationForm.controls.ConfigFrequency.value == 0) {
      // this.ReminderArray = []
      // this.AddReminderArray(null)
      this.ConfigurationForm.controls.SendInstant.setValue(true)
      this.ConfigurationForm.controls.SendReminder.setValue(false)
      this.ConfigurationForm.controls.SendSchedule.setValue(false)
      this.statusService._Configstatuses = this.statusService._status.filter(o => o.Id != 2 && o.Id != 3 && o.Id != 15)
    }
    else if (this.ConfigurationForm.controls.ConfigFrequency.value == 2) {
      // this.ReminderArray = []
      // this.AddReminderArray(null)
      this.ConfigurationForm.controls.SendSchedule.setValue(true)
      this.ConfigurationForm.controls.SendReminder.setValue(false)
      this.ConfigurationForm.controls.SendInstant.setValue(false)
      this.reminderIntervalText = "Interval (Hrs After Marking Interaction)"
      this.statusService._Configstatuses = this.statusService._status.filter(o => o.Id != 2 && o.Id != 3 && o.Id != 15)
    } else if (this.ConfigurationForm.controls.ConfigFrequency.value == 1) {
      // this.ReminderArray = []
      // this.AddReminderArray(null)
      this.reminderIntervalText = "Interval (Hrs Before Scheduled Date)"
      this.ConfigurationForm.controls.SendSchedule.setValue(false)
      this.ConfigurationForm.controls.SendReminder.setValue(true)
      this.ConfigurationForm.controls.SendInstant.setValue(false)
      this.statusService._Configstatuses = this.statusService._alertConfigstatus
    }
    else {
      this.statusService._Configstatuses = []
    }
    // this.ChangeConfigFrequency()
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.templateService._emailCondfigByTemplateList);
  }

  onItemDeSelectStudy(event: Study) {
    this.visitsofStudy = this.studyVisitsByStudy.filter(o => o.StudyId != event.Id)
    this.StudyWiseStatuses = this.StudyWiseStatuses.filter(o => o.StudyId != event.Id)
    this.StudyWiseStatusesArray = Array.from(this.StudyWiseStatuses);
    if (this.EmailConfigurationDetailsArray && this.EmailConfigurationDetailsArray.length && this.EmailConfigurationDetailsArray.length > 0) {
      this.EmailConfigurationDetailsArray = this.EmailConfigurationDetailsArray.filter(o => +o.FilterTypeValue != event.Id)
    }
    this.ConfigurationForm.controls.StudyVisitIdArray.setValue([])
  }

  onDropDownClose() {
    let array = this.visitsofStudy;
    if (array && array.length > 0) {
      this.studyVisitsByStudy = Array.from(array).sort((a: any, b: any) => (a.Indexing > b.Indexing ? 1 : -1))
    }
    this.StudyWiseStatusesArray = Array.from(this.StudyWiseStatuses);
  }

  onSelectStudy(event: any) {
    this.statusService.GetComplainceStatus()
    let studyId = event && event.Id ? event.Id : event
    this.studyVisitService._studyvisits.filter(x => x.StudyId == studyId).forEach(element => {
      this.visitsofStudy.push(element)
    })
    let studyStatusesArray: any[] = this.studyService._StudyStatuses.filter(o => o.StudyId == studyId)
    if (studyStatusesArray && studyStatusesArray.length > 0) {
      studyStatusesArray.forEach(element => {
        this.StudyWiseStatuses.push({
          Id: element.StudyStatusId,
          Name: element.StudyStatusName,
          StudyId: element.StudyId
        })
      })
    }
  }

  removeDeuplicateValues(dataArray: any[]) {
    var uniqueDataArray = dataArray.reduce((item, o) => {
      if (!item.some(obj => obj.Id === o.Id && obj.Name === o.Name)) {
        item.push(o);
      } return item
    }, []);
    return uniqueDataArray
  }

  onSelectSite(event: any) {
    this.ConfigurationForm.controls.StudyIdArray.setValue([])
    this.ConfigurationForm.controls.StudyVisitStatusIdArray.setValue([])
    this.ConfigurationForm.controls.StudyVisitIdArray.setValue([])
    let siteId = event && event.Id ? event.Id : event
    this.siteIds.push({ Id: siteId })
    // this.siteService.GetStudiesBySite(siteId)
    this.studyService._activeStudies.filter(o => o.siteId == siteId).forEach(element => {
      this.siteService._alertConfigSiteStudies.push({ Id: element.Id, Name: element.Name, SiteId: siteId })
    })
  }

  onItemDeSelectSite(event: any) {
    this.siteIds = this.siteIds.filter(o => o.Id != event.Id)
    this.siteService._alertConfigSiteStudies = this.siteService._alertConfigSiteStudies.filter(o => o.SiteId != event.Id)
    this.ConfigurationForm.controls.StudyIdArray.setValue([])
  }

  onDropDownCloseSite() {
    //debugger
    if (this.siteIds && this.siteIds.length && this.siteIds.length > 0) {
      if (this.siteService._alertConfigSiteStudies && this.siteService._alertConfigSiteStudies.length > 0) {
        this.studyService._activeStudiesArray = this.removeDeuplicateValues(this.siteService._alertConfigSiteStudies)
      }
    }
    else {
      this.studyService._activeStudiesArray = this.removeDeuplicateValues(this.studyService._activeStudies)
    }
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef
    this.columnDefs = [
      {
        headerName: 'Edit',
        colId: 'edit',
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.edit
        }
      },
      { headerName: 'Configuration', field: 'Name', sortable: true, filter: true },
      { headerName: 'Configuration Type', field: 'ConfigType', sortable: true, filter: true },
      { headerName: 'Configuration Frequency', field: 'ConfigFrequency', sortable: true, filter: true },
      { headerName: 'Template', field: 'templateName', sortable: true, filter: true },
      { headerName: 'Site', field: 'siteName', sortable: true, filter: true },
      { headerName: 'Study', field: 'studyName', sortable: true, filter: true },
      { headerName: 'Status', field: 'statusName', sortable: true, filter: true },
      { headerName: 'Study Visit', field: 'studyVisitName', sortable: true, filter: true },
      { headerName: 'Study Visit Status', field: 'studyVisitStatusName', sortable: true, filter: true },
      { headerName: 'Send To', field: 'sendToName', sortable: true, filter: true },
      { headerName: 'Notification Date', field: 'NotificationDate', sortable: true, filter: true },
      { headerName: 'Campaign', field: 'campaignName', sortable: true, filter: true },
      { headerName: 'Referral Source', field: 'campaignRefSourceName', sortable: true, filter: true },
      {
        headerName: 'Active', field: 'IsActive', sortable: true, filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.IsActive
        }
      },
    ];
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[(0-9)]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  ChangeConfigType() {
    if (this.ConfigurationForm.controls.ConfigType.value == "Select Send Configuration Type") {
      this.showBtnTest = false
      this.btntext = ""
    }
    else {
      if (this.ConfigurationForm.controls.ConfigType.value == 0) {
        this.btntext = "Test Email"
        this.ConfigurationForm.controls.SendAsEmail.setValue(true)
        this.ConfigurationForm.controls.SendAsText.setValue(false)
        this.showBtnTest = true
      }
      else {
        this.btntext = "Test SMS"
        this.ConfigurationForm.controls.SendAsEmail.setValue(false)
        this.ConfigurationForm.controls.SendAsText.setValue(true)
        this.showBtnTest = true
      }
    }
  }

  ChangeConfigFrequency() {
    if (this.ConfigurationForm.controls.ConfigFrequency.value == 0) {
      this.ReminderArray = []
      this.ConfigurationForm.controls.SendInstant.setValue(true)
      this.ConfigurationForm.controls.SendReminder.setValue(false)
      this.ConfigurationForm.controls.SendSchedule.setValue(false)

      this.statusService._Configstatuses = this.statusService._ConfigVisitStatuses.filter(o => o.Id != 2 && o.Id != 3 && o.Id != 15)
    }
    else if (this.ConfigurationForm.controls.ConfigFrequency.value == 2) {
      this.ConfigurationForm.controls.SendSchedule.setValue(true)
      this.ConfigurationForm.controls.SendReminder.setValue(false)
      this.ConfigurationForm.controls.SendInstant.setValue(false)
      this.ReminderArray = []
      this.AddReminderArray(null)
      this.reminderIntervalText = "Interval (Hrs After Marking Interaction)"
      this.statusService._Configstatuses = this.statusService._ConfigVisitStatuses.filter(o => o.Id != 2 && o.Id != 3 && o.Id != 15)
    } else if (this.ConfigurationForm.controls.ConfigFrequency.value == 1) {
      this.reminderIntervalText = "Interval (Hrs Before Scheduled Date)"
      this.ConfigurationForm.controls.SendSchedule.setValue(false)
      this.ConfigurationForm.controls.SendReminder.setValue(true)
      this.ConfigurationForm.controls.SendInstant.setValue(false)
      this.ReminderArray = []
      this.AddReminderArray(null)
      this.statusService._Configstatuses = this.statusService._alertConfigstatus
    }
    else {
      this.statusService._Configstatuses = []
    }
  }

  SendSMS() {
    this.submittedTest = true
    if (this.toPhoneNumber == "") {
      return
    }
    else {
      let saveBody: any = {
        toNumber: this.toPhoneNumber,
        body: this.templateService.templateObject.Content
      }
      this.patientInteraction.SendSMS(saveBody)
      this.patientInteraction.isSendSMS.subscribe(res => {
        if (res) {
          this.toPhoneNumber = "";
          this.submittedTest = false
          this.modalService.dismissAll()
        }
      })
    }
  }

  SendEmail() {
    this.submittedEmail = true
    if (this.toEmail == "") {
      return
    }
    else {
      let saveBody: any = {
        toEmail: this.toEmail,
        body: this.templateService.templateObject.Content,
      }
      this.patientInteraction.SendEmail(saveBody)
      this.patientInteraction.isSendSMS.subscribe(res => {
        if (res) {
          this.toEmail = "";
          this.submittedEmail = false
          this.modalService.dismissAll()
        }
      })
    }
  }

  openTestModal(content) {
    this.modalService.open(content)
  }


  EmailConfigurationDetailsId(filterId: any, refId: any) {
    var Id = this.EmailConfigurationDetailsArray.filter(o => o.FilterTypeId == refId && +o.FilterTypeValue == filterId)[0]
    if (Id && Id.Id) {
      return Id.Id
    }
    else {
      return 0
    }
  }

  onSelectAllSite(event: any[]) {
    console.log(event)
  }

  onDeSelectAllSite(event: any[]) {
    console.log(event)
  }

  SetEmailConfigurationDetailData() {
    this._EmailConfigurationDetail = []
    if (this.ConfigurationForm.controls.SiteIdArray.value && this.ConfigurationForm.controls.SiteIdArray.value.length > 0) {
      this.ConfigurationForm.controls.SiteIds.setValue(this.ConfigurationForm.controls.SiteIdArray.value.map(o => o.Id).join(","))
      this.ConfigurationForm.controls.SiteIdArray.value.forEach(element => {
        var EmailConfigurationDetailsIdId = this.EmailConfigurationDetailsId(element.Id, 1)
        this._EmailConfigurationDetail.push({ Id: EmailConfigurationDetailsIdId, FilterTypeId: 1, FilterTypeValue: element.Id, isActive: true })
      });
    }
    if (this.ConfigurationForm.controls.StudyIdArray.value && this.ConfigurationForm.controls.StudyIdArray.value.length > 0) {
      this.ConfigurationForm.controls.StudyIds.setValue(this.ConfigurationForm.controls.StudyIdArray.value.map(o => o.Id).join(","))
      this.ConfigurationForm.controls.StudyIdArray.value.forEach(element => {
        var EmailConfigurationDetailsIdId = this.EmailConfigurationDetailsId(element.Id, 2)
        this._EmailConfigurationDetail.push({ Id: EmailConfigurationDetailsIdId, FilterTypeId: 2, FilterTypeValue: element.Id, isActive: true })
      });
    }
    if (this.ConfigurationForm.controls.StatusIdArray.value && this.ConfigurationForm.controls.StatusIdArray.value.length > 0) {
      this.ConfigurationForm.controls.StatusIds.setValue(this.ConfigurationForm.controls.StatusIdArray.value.map(o => o.Id).join(","))
      this.ConfigurationForm.controls.StatusIdArray.value.forEach(element => {
        var EmailConfigurationDetailsIdId = this.EmailConfigurationDetailsId(element.Id, 3)
        this._EmailConfigurationDetail.push({ Id: EmailConfigurationDetailsIdId, FilterTypeId: 3, FilterTypeValue: element.Id, isActive: true })
      });
    }
    if (this.ConfigurationForm.controls.StudyVisitIdArray.value && this.ConfigurationForm.controls.StudyVisitIdArray.value.length > 0) {
      this.ConfigurationForm.controls.StudyVisitIdArray.value.forEach(element => {
        var EmailConfigurationDetailsIdId = this.EmailConfigurationDetailsId(element.Id, 4)
        this._EmailConfigurationDetail.push({ Id: EmailConfigurationDetailsIdId, FilterTypeId: 4, FilterTypeValue: element.Id, isActive: true })
      });
    }
    if (this.ConfigurationForm.controls.StudyVisitStatusIdArray.value && this.ConfigurationForm.controls.StudyVisitStatusIdArray.value.length > 0) {
      this.ConfigurationForm.controls.StudyVisitStatusIdArray.value.forEach(element => {
        var EmailConfigurationDetailsIdId = this.EmailConfigurationDetailsId(element.Id, 5)
        this._EmailConfigurationDetail.push({ Id: EmailConfigurationDetailsIdId, FilterTypeId: 5, FilterTypeValue: element.Id, isActive: true })
      });
    }
    if (this.ConfigurationForm.controls.StudyStatusIdArray.value && this.ConfigurationForm.controls.StudyStatusIdArray.value.length > 0) {
      this.ConfigurationForm.controls.StudyStatusIdArray.value.forEach(element => {
        var EmailConfigurationDetailsIdId = this.EmailConfigurationDetailsId(element.Id, 21)
        this._EmailConfigurationDetail.push({ Id: EmailConfigurationDetailsIdId, FilterTypeId: 21, FilterTypeValue: element.Id, isActive: true })
      });
    }
    this.ConfigurationForm.controls.EmailConfigurationDetails.setValue(this._EmailConfigurationDetail)
  }
}
