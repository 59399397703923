import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-field-error-display-cmp',
  templateUrl: './field-error-display-cmp.component.html',
 // styleUrls: ['./field-error-display-cmp.component.css']
 styleUrls: []
})
// tslint:disable-next-line:component-class-suffix
export class FieldErrorDisplayComponentCmp {

  @Input() errorMsg: string;
  @Input() displayError: boolean;

}

