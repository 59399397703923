import { AssignPatinets } from "./../../../../Models/BackModels/ComTrak/CallLists/AssignPatients";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { Recruiter } from "./../../../../Models/BackModels/ComTrak/Setups/Recruiter";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as myGlobals from "@shared/global_var";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { GoogleAnalyticsService } from "@sharedservices/FrontServices/google-analytics.service";
import { CallList } from "@sharedmodels/BackModels/ComTrak/CallLists/CallList";
import { Race } from "@sharedmodels/BackModels/ComTrak/Setups/race";
import { Call } from "@twilio/voice-sdk";
import { callDataObject } from "@sharedservices/FrontServices/twilio.service";

@Injectable({
  providedIn: "root",
})
export class RecruiterService {
  public _callList: CallList[];
  public _Recruiter: Recruiter[];
  public _showCampaign: any[];
  public _patientCount: any[];
  public _AssignPatients: AssignPatinets[];
  public isCallApi = new Subject<boolean>();
  public getPatientCount = new Subject<boolean>();
  public getPatientUnAssignCount = new Subject<boolean>();
  public _RecruiterReport: Recruiter[];
  public arraylength: number;
  public _callListArray: CallList[];
  public Race: Race[];
  public assignPatientCount: number;
  UnassignPatientCount: any;
  contactCenterFlag = new BehaviorSubject<boolean>(false);
  contactCenterRowData = new BehaviorSubject<object>(null);
  contactCenterType = new BehaviorSubject<string>('');
  notificationToasterFlag = new BehaviorSubject<boolean>(false);
  notificationRowData = new BehaviorSubject<string>('');
  notificationType = new BehaviorSubject<string>('');
  sidenavHeight = new BehaviorSubject<number>(0);
  currentCall = new BehaviorSubject<Call>(null);
  currentCallData = new BehaviorSubject<callDataObject>(null);
  myStatus = new BehaviorSubject<string>('');

  constructor(
    public _http: HttpClient,
    private _Global: GlobalfunctionService,
    private _GA: GoogleAnalyticsService,
    private spinner: NgxSpinnerService
  ) {}

  LoadRecruiterCallList(
    pageSize: number,
    pageNo: number,
    siteID: number,
    studyID: number,
    statusID: number,
    startDate: any,
    endDate: any,
    searchString: string
  ) {
    try {
      const pagesize = pageSize;
      const pageno = pageNo;
      return this._http.get(
        myGlobals._apiPath +
          "RecruiterCallList?getType=GetAll&startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&siteID=" +
          siteID +
          "&studyID=" +
          studyID +
          "&statusID=" +
          statusID +
          "&pageno=" +
          pageno +
          "&pagesize=" +
          pagesize +
          "&searchString=" +
          searchString,
        { headers: this._Global.httpOptions }
      );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetRecruiterCallList(
    pageSize: number,
    pageNo: number,
    siteID: number,
    studyID: number,
    statusID: number,
    startDate: any,
    endDate: any,
    searchString: string
  ) {
    // this._Global.IsReady = false;
    // this.spinner.show();
    this.isCallApi.next(false);
    this.LoadRecruiterCallList(
      pageSize,
      pageNo,
      siteID,
      studyID,
      statusID,
      startDate,
      endDate,
      searchString
    ).subscribe(
      (data) => {

        this._Global.IsReady = true;
        this.spinner.hide();

        var finalResponse = (<CallList[]>data).map((item) => {
          // Check if the item has the CreatedOn property
          if (item["fCreatedOn"]) {
            // Update the CreatedOn property to local time
            item["fCreatedOn"] = new Date(new Date(item["fCreatedOn"]).getTime() - new Date(item["fCreatedOn"]).getTimezoneOffset() * 60000).toLocaleString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
          }
          return item;
        });

        this._callList =finalResponse;
        this._callListArray = finalResponse;

        console.log(
          "Lead Merged Date : ",
          this._callListArray.filter(
            (x: any) => x.LeadMergedDate != "" && x.LeadMergedDate != null
          )
        );
        this.arraylength = this._callList.length;
        this.isCallApi.next(true);
      },
      (error) => {
        this._Global.IsReady = true;
        this.spinner.hide();
        this._Global.handleError(error);
        this.isCallApi.next(false);
      }
    );
  }

  LoadAllRecuiter() {
    try {
      this._Global.IsReady = false;
      this.spinner.show();
      this._http
        .get(myGlobals._apiPath + "RecruiterCallList?getType=GetAllRecruiter", {
          headers: this._Global.httpOptions,
        })
        .subscribe(
          (data) => {
            this._Global.IsReady = true;
            this.spinner.hide();
            this._Recruiter = <Recruiter[]>data;
          },
          (error) => {
            this._Global.IsReady = true;
            this.spinner.hide();
            this._Global.handleError(error);
          }
        );
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetAllRecuiters() {
    if (this._Recruiter !== undefined && this._Recruiter.length !== null) {
      return this._Recruiter;
    } else {
      this.LoadAllRecuiter();
    }
  }

  GetAdminCalllistReport(
    recruiterId: number,
    pagesize: number,
    pageno: number,
    startDate: any,
    endDate: any,
    siteID: number,
    studyID: number,
    statusID: number,
    searchString: string
  ): Observable<any> {
    return this._http.get(
      myGlobals._apiPath +
        "RecruiterCallList?getType=GetAdminCallList&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&siteID=" +
        siteID +
        "&studyID=" +
        studyID +
        "&statusID=" +
        statusID +
        "&recruiterId=" +
        recruiterId +
        "&pageno=" +
        pageno +
        "&pagesize=" +
        pagesize +
        "&searchString=" +
        searchString,
      { headers: this._Global.httpOptions }
    );
  }

  ShowCampaign(recruiterId: number) {
    this._Global.IsReady = false;
    this.spinner.show();
    this._http
      .get(
        myGlobals._apiPath +
          "RecruiterCallList?getType=ShowCampaigns&recruiterId=" +
          recruiterId,
        { headers: this._Global.httpOptions }
      )
      .subscribe(
        (res) => {
          this._Global.IsReady = true;
          this.spinner.hide();
          this._showCampaign = res as [];
        },
        (error) => {
          this._Global.IsReady = true;
          this.spinner.hide();
          this._Global.handleError(error);
        }
      );
  }

  GetPatientCount(
    recruiterId: number,
    campaignId: any[],
    campaignRefId: any[],
    stateID: any[],
    siteID: any[],
    studyID: any[],
    statusID: any[],
    gender: string,
    fromage: number,
    toage: number,
    IsLeadInclusive: boolean,
    storeProcedureName: string
  ) {
    // this._Global.IsReady = false;
    // this.spinner.show()
    this.getPatientCount.next(false);
    var cmapiagnIds =
      campaignId && campaignId.length > 0
        ? campaignId.map((o) => o.Id).join(",")
        : "";
    var campaignRefIds =
      campaignRefId && campaignRefId.length > 0
        ? campaignRefId.map((o) => o.Id).join(",")
        : "";
    var siteIDs =
      siteID && siteID.length > 0 ? siteID.map((o) => o.Id).join(",") : "";
    var studyIDs =
      studyID && studyID.length > 0 ? studyID.map((o) => o.Id).join(",") : "";
    var statusIDs =
      statusID && statusID.length > 0
        ? statusID.map((o) => o.Id).join(",")
        : "";
    var stateIDs =
      stateID && stateID.length > 0 ? stateID.map((o) => o.Id).join(",") : "";
    this._http
      .get(
        myGlobals._apiPath +
          "RecruiterCallList?getType=GetPatientCount&campaignIds=" +
          cmapiagnIds +
          "&campaignRefIds=" +
          campaignRefIds +
          "&fromage=" +
          fromage +
          "&toage=" +
          toage +
          "&gender=" +
          gender +
          "&recruiterId=" +
          recruiterId +
          "&storeProcedureName=" +
          storeProcedureName +
          "&siteIDs=" +
          siteIDs +
          "&studyIDs=" +
          studyIDs +
          "&statusIDs=" +
          statusIDs +
          "&stateIDs=" +
          stateIDs +
          "&IsLeadInclusive=" +
          IsLeadInclusive,
          { headers: this._Global.httpOptions }
      )
      .subscribe(
        (res: any) => {
          // this._Global.IsReady = true;
          // this.spinner.hide()
          this.Race =
            res && res.Race && res.Race.length && res.Race.length > 0
              ? res.Race
              : undefined;
          this.assignPatientCount =
            res && res.PatientCount ? res.PatientCount : 0;
          //this.UnassignPatientCount = res && res.PatientCount ? res.PatientCount : 0
          this.getPatientCount.next(true);
        },
        (error) => {
          this._Global.IsReady = true;
          this.spinner.hide();
          this._Global.showErrorMsg(error);
        }
      );
  }

  GetPatientsToReleaseCount(
    campaignId: any[],
    campaignRefId: any[],
    fromage: number,
    toage: number,
    gender: string,
    recruiterId: number,
    storeProcedureName: string,
    siteID: any[],
    studyID: any[],
    statusID: any[],
    stateID: any[],
    IsLeadInclusive: boolean
  ) {
    // this._Global.IsReady = false;
    // this.spinner.show()
    this.getPatientUnAssignCount.next(false);
    var cmapiagnIds =
      campaignId && campaignId.length > 0
        ? campaignId.map((o) => o.Id).join(",")
        : "";
    var campaignRefIds =
      campaignRefId && campaignRefId.length > 0
        ? campaignRefId.map((o) => o.Id).join(",")
        : "";
    var siteIDs =
      siteID && siteID.length > 0 ? siteID.map((o) => o.Id).join(",") : "";
    var studyIDs =
      studyID && studyID.length > 0 ? studyID.map((o) => o.Id).join(",") : "";
    var statusIDs =
      statusID && statusID.length > 0
        ? statusID.map((o) => o.Id).join(",")
        : "";
    var stateIDs =
      stateID && stateID.length > 0 ? stateID.map((o) => o.Id).join(",") : "";
    this._http
      .get(
        myGlobals._apiPath +
          "RecruiterCallList?getType=GetPatientCount&campaignIds=" +
          cmapiagnIds +
          "&campaignRefIds=" +
          campaignRefIds +
          "&fromage=" +
          fromage +
          "&toage=" +
          toage +
          "&gender=" +
          gender +
          "&recruiterId=" +
          recruiterId +
          "&storeProcedureName=" +
          storeProcedureName +
          "&siteIDs=" +
          siteIDs +
          "&studyIDs=" +
          studyIDs +
          "&statusIDs=" +
          statusIDs +
          "&stateIDs=" +
          stateIDs +
          "&IsLeadInclusive=" +
          IsLeadInclusive,
        { headers: this._Global.httpOptions }
      )
      .subscribe(
        (res: any) => {
          // this._Global.IsReady = true;
          // this.spinner.hide()
          this.UnassignPatientCount =
            res && res.PatientCount ? res.PatientCount : 0;
          this.getPatientUnAssignCount.next(true);
        },
        (error) => {
          this._Global.IsReady = true;
          this.spinner.hide();
          this._Global.showErrorMsg(error);
        }
      );
  }

  GetAssignPatient(
    count: number,
    pagenumber: number,
    pagesize: number
  ): Observable<any> {
    return this._http.get(
      myGlobals._apiPath +
        "RecruiterCallList?getType=GetAssignPatient&count=" +
        count +
        "&pageno=" +
        pagenumber +
        "&pagesize=" +
        pagesize,
      { headers: this._Global.httpOptions }
    );
  }

  ReleasePatient() {
    if (
      this._AssignPatients == undefined ||
      (this._AssignPatients && this._AssignPatients.length == 0)
    ) {
      this._Global.showErrorMsg("Patients list cannot be empty");
      return;
    } else {
      if (
        confirm(
          "Are you sure? All patients will be released back to the available patient pool."
        ) == true
      ) {
        //this._Global.IsReady = false;
        //this.spinner.show();
        this._http
          .post(
            myGlobals._apiPath + "RecruiterCallList/PostReleasePatients",
            null,
            { headers: this._Global.httpOptions }
          )
          .subscribe(
            (res) => {
              this._Global.IsReady = true;
              this.spinner.hide();
              this._Global.showSuccessMsg(
                "Patients succssfully released back to pool!"
              );
              this.isCallApi.next(true);
            },
            (error) => {
              this._Global.IsReady = true;
              this.spinner.hide();
              this._Global.handleError(error);
              this.isCallApi.next(false);
            }
          );
      } else {
        return false;
      }
    }
  }

  AssignOrReAssignPatients(
    numberOfPatients: number,
    recruiterId: number,
    campaignId: any[],
    campaignRefId: any[],
    fromage: number,
    toage: number,
    gender: string,
    storeProcName: string,
    fromrecruiterId: number,
    siteID: any[],
    studyID: any[],
    statusID: any[],
    stateId: any[],
    IsLeadInclusive: boolean
  ): Observable<any> {
    var cmapiagnIds =
      campaignId && campaignId.length > 0
        ? campaignId.map((o) => o.Id).join(",")
        : "";
    var campaignRefIds =
      campaignRefId && campaignRefId.length > 0
        ? campaignRefId.map((o) => o.Id).join(",")
        : "";
    var siteIDs =
      siteID && siteID.length > 0 ? siteID.map((o) => o.Id).join(",") : "";
    var studyIDs =
      studyID && studyID.length > 0 ? studyID.map((o) => o.Id).join(",") : "";
    var statusIDs =
      statusID && statusID.length > 0
        ? statusID.map((o) => o.Id).join(",")
        : "";
    var stateIDs =
      stateId && stateId.length > 0 ? stateId.map((o) => o.Id).join(",") : "";

    return this._http.put(
      myGlobals._apiPath +
        "RecruiterCallList/Put?getType=AssignOrReAssignPatients&numberOfPatients=" +
        numberOfPatients +
        "&recruiterId=" +
        recruiterId +
        "&campaignIds=" +
        cmapiagnIds +
        "&campaignRefIds=" +
        campaignRefIds +
        "&fromage=" +
        fromage +
        "&toage=" +
        toage +
        "&gender=" +
        gender +
        "&storeProcName=" +
        storeProcName +
        "&fromrecruiterId=" +
        fromrecruiterId +
        "&siteIds=" +
        siteIDs +
        "&studyIds=" +
        studyIDs +
        "&statusIds=" +
        statusIDs +
        "&stateIds=" +
        stateIDs +
        "&IsLeadInclusive=" +
        IsLeadInclusive,
      null,
      { headers: this._Global.httpOptions }
    );
  }

  // ReleaseRecruiterPatients(
  //   numberOfPatients: number,
  //   recruiterId: number,
  //   campaignId: any[],
  //   campaignRefId: any[],
  //   campaignRefAdDetailId: any[],
  //   fromage: number,
  //   toage: number,
  //   gender: string,
  //   race: string,
  //   ethnicity: string,
  //   siteID: any[],
  //   studyID: any[],
  //   statusID: any[],
  //   stateID: any[]
  // ) {
  //   if (
  //     confirm(
  //       "Are you sure? Patients will be released back to the available patient pool."
  //     ) == true
  //   ) {
  //     // this._Global.IsReady = false;
  //     // this.spinner.show();
  //     var cmapiagnIds =
  //       campaignId && campaignId.length > 0
  //         ? campaignId.map((o) => o.Id).join(",")
  //         : "";
  //     var campaignRefIds =
  //       campaignRefId && campaignRefId.length > 0
  //         ? campaignRefId.map((o) => o.Id).join(",")
  //         : "";
  //     var campaignRefAdDetailIds =
  //       campaignRefAdDetailId && campaignRefAdDetailId.length > 0
  //         ? campaignRefAdDetailId.map((o) => o.Id).join(",")
  //         : "";
  //     var siteIDs =
  //       siteID && siteID.length > 0 ? siteID.map((o) => o.Id).join(",") : "";
  //     var studyIDs =
  //       studyID && studyID.length > 0 ? studyID.map((o) => o.Id).join(",") : "";
  //     var statusIDs =
  //       statusID && statusID.length > 0
  //         ? statusID.map((o) => o.Id).join(",")
  //         : "";
  //     var stateIDs =
  //       stateID && stateID.length > 0 ? stateID.map((o) => o.Id).join(",") : "";

  //     this._http
  //       .put(
  //         myGlobals._apiPath +
  //           "RecruiterCallList/Put?getType=ReleaseRecruiterPatients&numberOfPatients=" +
  //           numberOfPatients +
  //           "&recruiterId=" +
  //           recruiterId +
  //           "&campaignIds=" +
  //           cmapiagnIds +
  //           "&campaignRefIds=" +
  //           campaignRefIds +
  //           "&campaignRefAdDetailIds=" +
  //           campaignRefAdDetailIds +
  //           "&fromage=" +
  //           fromage +
  //           "&toage=" +
  //           toage +
  //           "&gender=" +
  //           gender +
  //           "&race=" +
  //           race +
  //           "&ethnicity=" +
  //           ethnicity +
  //           "&siteIds=" +
  //           siteIDs +
  //           "&studyIds=" +
  //           studyIDs +
  //           "&statusIds=" +
  //           statusIDs +
  //           "&stateIds=" +
  //           stateIDs,
  //         null,
  //         { headers: this._Global.httpOptions }
  //       )
  //       .subscribe(
  //         (res) => {
  //           this._Global.IsReady = true;
  //           this.spinner.hide();
  //           this._Global.showSuccessMsg(
  //             "Patients succssfully released back to pool!"
  //           );
  //           this.isCallApi.next(true);
  //         },
  //         (error) => {
  //           this._Global.IsReady = true;
  //           this.spinner.hide();
  //           this._Global.handleError(error);
  //           this.isCallApi.next(false);
  //         }
  //       );
  //   } else {
  //     return false;
  //   }
  // }

  ReleaseAllPatient(recruiterId: number) {
    if (
      confirm(
        "Are you sure you want to release all patients of this recruiter?"
      ) == true
    ) {
      // this._Global.IsReady = false;
      // this.spinner.show();
      this._http
        .put(
          myGlobals._apiPath +
            "RecruiterCallList/Put?getType=ReleaseRecruiterPatientsAll&recruiterId=" +
            recruiterId,
          null,
          { headers: this._Global.httpOptions }
        )
        .subscribe(
          (res: any) => {
            this._Global.IsReady = true;
            this.spinner.hide();
            this._Global.showSuccessMsg(
              res.Result ? res.Result : "All Patients released"
            );
            this.isCallApi.next(true);
          },
          (error) => {
            this._Global.IsReady = true;
            this.spinner.hide();
            this._Global.handleError(error);
            this.isCallApi.next(false);
          }
        );
    } else {
      return false;
    }
  }
}
