import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DBLMService } from '@sharedservices/BackServices/ComTrak/DBLM/dblm.service';
import { StaffService } from '@sharedservices/BackServices/ComTrak/Staff/staff.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assignlist',
  templateUrl: './assignlist.component.html',
  styleUrls: ['./assignlist.component.scss', '../../style.component.scss']
})
export class AssignlistComponent implements OnInit {
  assignList: any[] = []
  displayRemoveIcon: boolean = false;
  errorMessage: string;
  count: number = 0;
  Assigncount: any;
  Assigncount2: any;
  constructor(public dialogRef: MatDialogRef<AssignlistComponent>, public dblmService: DBLMService, @Inject(MAT_DIALOG_DATA) public data: any,
    public staffService: StaffService, public _Global: GlobalfunctionService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // this.dblmService.IsRemainingPatient.subscribe(res => {
    //   if (res) {
    this.count = this.dblmService._patientCount ? this.dblmService._patientCount.PatientCount : 0
    this.Assigncount = this.count
    this.Assigncount2 = this.Assigncount
    //   }
    // })
    //this.count = this.dblmService._patientCount && this.dblmService._patientCount.PatientCount != 0 ? this.dblmService._patientCount.PatientCount : this.data.listCount
    this.assignList = []
    this.pushArray()
  }

  pushArray() {
    this.assignList.push({
      SelectedRecruiterId: undefined,
      alreadyAssign: '',
      alreadyAssignFromOtherList: '',
      NoOfAssignPatient: '',
      ListMasterId: this.dblmService.listMasterId
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkTableValidation() {
    this.assignList = this.assignList
    let lastObject = this.assignList[this.assignList.length - 1]
    var checkValidate = lastObject.SelectedRecruiterId == undefined ? true : lastObject.NoOfAssignPatient == '' ? true : lastObject.NoOfAssignPatient == null ? true : false;
    if (checkValidate) {
      let index = this.assignList.findIndex(o => o.SelectedRecruiterId == lastObject.SelectedRecruiterId)
      let errorMessage = ` Line ${index + 1} Is Required `
      this._Global.showErrorMsg(errorMessage)
      return true
    }
    else {
      return false
    }
  }

  AddArray(index: number) {
    if (this.count > 0) {
      if (index != null) {
        let checkValidate = this.checkTableValidation()
        if (checkValidate == true) {
          return
        }
        else {
          this.pushArray()
        }
      }
      else {
        this.pushArray()
      }
      this.Assigncount = this.count
    }
    else {
      this._Global.showErrorMsg("cant add more details available patient count is zero")
    }
  }

  showRemoveIcon() {
    if (this.assignList.length === 1) {
      this.displayRemoveIcon = false;
    } else {
      this.displayRemoveIcon = true;
    }
  }

  SpliceArray(index: number, NoOfAssignPatient: number) {
    if (this.assignList.length == 1) {
      return
    }
    else {
      this.count += NoOfAssignPatient;
      this.Assigncount = this.count
      this.assignList.splice(index, 1)
    }
  }

  ChangeRecruiter(indexNumber: number, recruiterId: number) {
    this.spinner.show()
    this._Global.IsReady = false;
    this.dblmService.GetRecruiterPatientsCount(recruiterId).subscribe(res => {
      this.assignList[indexNumber].alreadyAssign = res
      this.spinner.hide()
      this._Global.IsReady = true;
    }, error => {
      this._Global.handleError(error);
      this.spinner.hide()
      this._Global.IsReady = true;
    })

    this.dblmService.GetRecruiterPatientsCountFromOtherList(recruiterId).subscribe(res => {
      this.assignList[indexNumber].alreadyAssignFromOtherList = res
      this.spinner.hide()
      this._Global.IsReady = true;
    }, error => {
      this._Global.handleError(error);
      this.spinner.hide()
      this._Global.IsReady = true;
    })

    if (this.assignList.length > 1) {
      let Object = this.assignList.filter((value, index) => value.SelectedRecruiterId == recruiterId && indexNumber !== index)[0]
      let RecruiterObject = this.staffService.staffTypeLead.filter(o => o.Id == recruiterId)[0];
      if (Object) {
        this.errorMessage = `Recruiter ${RecruiterObject.FullName} is already selected at line : ${indexNumber}`
        this._Global.showErrorMsg(this.errorMessage)
        this.assignList[indexNumber].SelectedRecruiterId = undefined
      }
      else {
        this.errorMessage = ""
      }
    }
  }

  ChangeAssignCount(assignCount: any, index: number) {
    if (assignCount > this.Assigncount) {
      this._Global.showErrorMsg("Assign count can't be greater then remaining patient")
      this.assignList[index].NoOfAssignPatient = ''
    }
    else {
      if (this.assignList.length == 1 && assignCount == null) {
        this.count = this.Assigncount2
        this.Assigncount = this.Assigncount2
      }
      else {
        this.count = this.Assigncount - assignCount
      }
    }
  }

  onSubmit() {
    let checkValidate = this.checkTableValidation()
    if (checkValidate == true) {
      return
    }
    else {
      this.dblmService.AddDBLMAssignPatientToRecruiter(this.assignList, 'sp_DBLMAssignPatientToRecruiter')
      this.dblmService.IsSaveAssign.subscribe(res => {
        if (res) {
          this.onNoClick()
        }
      })
    }
  }

  OnlynumberAssignList(event): boolean {
    if (event.target.value == 0 && event.keyCode == 48) {
      return false;
    }
  }
}
