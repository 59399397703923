import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PoolBuilderService } from "@sharedservices/BackServices/ComTrak/PoolBuilder/poolBuilder.service";
import { CustomGridColumns } from "@app/components/datatable/datatable.component";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { PoolStatus } from "@sharedmodels/DMCREnums/DMCREnums";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-refresh-pool-details",
  templateUrl: "./refreshPoolDetails.component.html",
  styleUrls: ["./refreshPoolDetails.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class refreshPoolDetailsComponent implements OnInit {
  myData: any;
  totalPatientsCount: number = 0;
  adjustedPatientsCount: number = 0;
  noOfPatientsToInsertCount: number = 0;
  noOfPatientsToReleaseCount: number = 0;
  overlappedPools: Array<any> = [];
  stealedPatientIds: any[] = [];
  overlappedPoolsColumnDefinition: CustomGridColumns[];

  constructor(
    public dialogRef: MatDialogRef<refreshPoolDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public poolBuilderService: PoolBuilderService,
    public _Global: GlobalfunctionService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.totalPatientsCount = this.data.totalPatients;
    this.overlappedPools = this.data.overlappingPools;
    this.noOfPatientsToInsertCount = this.data.noOfPatientsToInsert
    this.noOfPatientsToReleaseCount = this.data.noOfPatientsToRelease

    // Load columns
    this.gridConfig()
  }

  gridConfig() {
    this.overlappedPoolsColumnDefinition = [
      {
        header: "List Name",
        key: "name",
        visible: true,
        type: "string",
      },
      {
        header: "Overlapping Patients",
        key: "overlappingPatients",
        visible: true,
        type: "string",
      },
      {
        header: "Total Count",
        key: "totalCount",
        visible: true,
        type: "string",
      },
      {
        header: "Actions",
        width: 240,
        key: "actions",
        visible: true,
        type: "string",
      },
    ];
  }

  getStatus(val: number) {
    return PoolStatus[val];
  }

  onReleasePool(pool: any) {
    pool.isReleased = !pool.isReleased;

    if (pool.isReleased) {
      this.adjustedPatientsCount =
        this.adjustedPatientsCount + pool.overlappingCount;
      this.stealedPatientIds.push({
        poolId: pool.poolId,
        overlappinPatientIds: pool.patientIds,
      });
    } else {
      this.adjustedPatientsCount =
        this.adjustedPatientsCount - pool.overlappingCount;
      this.stealedPatientIds = this.stealedPatientIds.filter(
        (x) => x.poolId !== pool.poolId
      );
    }
  }

  onSubmit() {
    this.dialogRef.close(this.stealedPatientIds);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
