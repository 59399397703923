import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subscription } from 'rxjs';

// This is necessary to load the fonts into pdfMake
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-previewpdf',
  templateUrl: './previewpdf.component.html',
  styleUrls: ['./previewpdf.component.scss']
})
export class PreviewPdfComponent implements OnInit, OnDestroy {

  pdfbase64: string;
  subs = new Subscription();
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  payload: string;

  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('pdfFrame') pdfFrame: ElementRef;

  constructor(
    private prescreenService: PrescreenService,
    private toastService: ToastrService,
    public dialogRef: MatDialogRef<PreviewPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public spinner: NgxSpinnerService,
    private _Global: GlobalfunctionService
  ) { }

  ngOnInit() {
    if(this.data.pdfData != false){
      this.generatePdf();
    }else{
      this.pdfFrame.nativeElement.src = this.data.link;
    }
  }

  generatePdf() {
    this.spinner.show();
    this._Global.IsReady = false; 
    pdfMake.createPdf(this.data.pdfData).getBase64((data) => {
      this.pdfbase64 = 'data:application/pdf;base64,' + data;
      this.payload = data;
      this.pdfFrame.nativeElement.src = this.pdfbase64;
      this.spinner.hide();
      this._Global.IsReady = true; 
    });
  }

  generatePDFName(): string {
    return this.data.callListData.patientData['patientLastInteraction.PatientName'] + '-' + this.data.callListData.patientData.Id + '-' + this.data.callListData.study.Name
  }

  onSubmit(value: string): void {
    const data = {
      study: {id: this.data.callListData.study.Id, name: this.data.callListData.study.Name},
      patient : {id: this.data.callListData.patientData.Id, name: this.data.callListData.patientData['patientLastInteraction.PatientName']},
      validity : this.data.callListData.validity,
      status : this.data.status,
      pdf: this.payload,
      pdf_name : this.generatePDFName(),
      prescreen_form_id:this.data.formId
    }
    this.spinner.show();
    this._Global.IsReady = false; 
    this.prescreenService.savePatientPreScreenConfig(data).subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.toastService.success('', res.message, this.toastOptions);
          this.onNoClick(true);
        } else {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.toastService.error('', res.message, this.toastOptions);
        }
      },
      error: (err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.toastService.error('', err.message, this.toastOptions);
      }
    });
    this.onSubmitAction.emit(value);
  }

  onNoClick(flag: boolean): void {
    this.dialogRef.close(flag);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
