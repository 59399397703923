import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PoolBuilderService } from "@sharedservices/BackServices/ComTrak/PoolBuilder/poolBuilder.service";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-draftdetails",
  templateUrl: "./draftdetailsPatientPool.component.html",
  styleUrls: ["./draftdetailsPatientPool.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class draftdetailsPatientPoolComponent implements OnInit, AfterViewInit {
  myData: any;
  noData = "---";
  htmlContent: any;
  @ViewChild("contentDiv") contentDiv: ElementRef;

  criteriaFields: any = [];

  constructor(
    public dialogRef: MatDialogRef<draftdetailsPatientPoolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public poolBuilderService: PoolBuilderService,
    public _Global: GlobalfunctionService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.myData = {
      ...this.data,
      inclusionCriteria: this.transformCriteriaFields(this.data.inclusion),
      exclusionCriteria: this.transformCriteriaFields(this.data.exclusion),
    };

    // Load criteria fields
    this.getCriteriaFields();
  }

  ngAfterViewInit(): void {}

  formatDate(dateTime: string): string {
    if (!dateTime) {
      return null;
    }
    const localDateTime = new Date(dateTime);
    const formattedDate = this.datePipe.transform(localDateTime, "MM/dd/yyyy");
    return formattedDate;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // Todo: This function  is supposed to return correct form object to be used by review pool
  transformCriteriaFields(inclusionArray: any[]) {
    const result: any = {};

    inclusionArray.forEach((item) => {
      if (item.fieldName) {
        const fieldName = this.toCamelCase(item.fieldName);

        switch (fieldName) {
          case "state":
          case "site":
          case "study":
          case "therapeuticArea":
          case "language":
          case "transportation":
          case "wOCBPStatus":
          case "previousInteractedStudy":
          case "adName":
          case "referralSource":
          case "lastInteractionStudy":
          case "campaignName":
          case "campaignType":
          case "confirmationStatus":
          case "pastPatientStudy":
            if (!result[fieldName]) {
              result[fieldName] = [];
            }
            result[fieldName].push({
              id: Number(item.fieldValue),
              name: item.value,
            });
            break;

          case "medicalHistory":
          case "vaccineName":
          case "nameOfConcomitantMedications":
            if (!result[fieldName]) {
              result[fieldName] = [];
            }
            result[fieldName].push({
              id: Number(item.value),
              name: item.fieldValue,
            });
            break;

          case "age":
          case "lVMCounterForStatus":
          case "lVMCounterForPastStatus":
          case "lVMCounterForLastInteractionStatus":
          case "bMI":
          case "numberOfTimesEnrolledInDMStudies":
            if (!result[fieldName]) {
              result[fieldName] = { start: "", end: "" };
            }
            if (!result[fieldName].start || +item.value < +result[fieldName].start) {
              result[fieldName].start = item.value;
            }
            if (!result[fieldName].end || +item.value > +result[fieldName].end) {
              result[fieldName].end = item.value;
            }
            break;

          case "gender":
          case "previouslyEnrolledAtAnyPoint":
            if (!result[fieldName]) {
              result[fieldName] = [];
            }
            result[fieldName].push({
              id: item.fieldValue,
              name: item.fieldValue,
            });
            break;
          case "metropolitanArea":
            if (!result[fieldName]) {
              result[fieldName] = [];
            }
            result[fieldName].push({
              id: item.fieldValue,
              description: item.value,
            });
            break;
          case "status":
          case "lastInteractionStatus":
          case "pastPatientStatus":
            if (!result[fieldName]) {
              result[fieldName] = [];
            }
            result[fieldName].push({
              statusId: Number(item.fieldValue),
              statusName: item.value,
            });
            break;
          case "subStatus":
            if (!result[fieldName]) {
              result[fieldName] = [];
            }
            result[fieldName].push({
              subStatusId: Number(item.fieldValue),
              subStatusName: item.value,
            });
            break;

          default:
            // Handling date values
            result[fieldName] = item.fieldValue ? item.fieldValue : null;
        }
      }
    });

    return result;
  }

  toCamelCase(string: string) {
    let parts = string.trim().split(/[\s-]+/); // Split on spaces or hyphens
    let result = parts
      .map((part: string, index: number) => {
        if (index === 0) {
          return part.charAt(0).toLowerCase() + part.slice(1); // Lowercase the first part entirely
        } else {
          return part.charAt(0).toUpperCase() + part.slice(1); // Capitalize the first letter of subsequent parts
        }
      })
      .join("");
    return result;
  }

  getCriteriaFields() {
    this.poolBuilderService.getCriteriaFields().subscribe(
      (res) => {
        if (res.success) {
          console.log("criteria fields", res);
          this.criteriaFields = res.data;
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      },
      (error) => {
        this._Global.IsReady = true;
        this.spinner.hide();
        this._Global.showErrorMsg(error);
      }
    );
  }
}
