import { ScreenerEdit } from './../../../Shared/Models/BackModels/ComTrak/CallLists/ScreenerEdit';
import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { CallList } from './../../../Shared/Models/BackModels/ComTrak/CallLists/CallList';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { VisitcompletedcalllistComponent } from './../../calllists/visitcompletedcalllist/visitcompletedcalllist.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-screeneredit',
  templateUrl: './screeneredit.component.html',
  styleUrls: ['./screeneredit.component.css']
})
export class ScreenereditComponent implements OnInit {
  ScreenerForm: FormGroup
  constructor(public dialogRef: MatDialogRef<VisitcompletedcalllistComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService, public patientInteractionService: PatientInteractionService) { }
  public _data: ScreenerEdit;
  show: boolean = false;
  submitted: boolean = false;

  ngOnInit() {
    this.initForm()
    let id = 0;
    if (this.data.callList !== null) {
      this._data = this.data.callList;
      this.ScreenerForm.controls.FullName.setValue(this._data && this._data.Patient && this._data.Patient.FullName ? this._data.Patient.FullName : '')
      this.ScreenerForm.controls.Study.setValue(this._data && this._data.Study && this._data.Study.Name ? this._data.Study.Name : '')
      this.ScreenerForm.controls.Recruiter.setValue(this._data && this._data.Recruiter && this._data.Recruiter.FullName ? this._data.Recruiter.FullName : '')
      this.ScreenerForm.controls.CrioId.setValue(this._data && this._data.CrioId ? this._data.CrioId : '')
      this.ScreenerForm.controls.Site.setValue(this._data && this._data.Site && this._data.Site.Name ? this._data.Site.Name : '')
      this.ScreenerForm.controls.Calendar.setValue(this._data && this._data.SiteCalendar && this._data.SiteCalendar.Name ? this._data.SiteCalendar.Name : '')
      this.ScreenerForm.controls.Status.setValue(this._data && this._data.Status && this._data.Status.Name ? this._data.Status.Name : '')
      this.ScreenerForm.controls.NextVisit.setValue(this._data && this._data.StudyVisit && this._data.StudyVisit.Name ? this._data.StudyVisit.Name : '')
      this.ScreenerForm.controls.VisitStatus.setValue(this._data && this._data.StudyVisitStatusName ? this._data.StudyVisitStatusName : '')
      this.ScreenerForm.controls.Id.setValue(this._data && this._data.Id ? this._data.Id : '')
    }
  }

  initForm() {
    this.ScreenerForm = this.fb.group({
      FullName: [''],
      Study: [''],
      Recruiter: [''],
      CrioId: [''],
      Site: [''],
      Calendar: [''],
      Status: [''],
      NextVisit: [''],
      VisitStatus: [''],
      Notes: [''],
      Id: ['']
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  onSubmit() {
    try {
      this.submitted = true;
      this.ScreenerForm.controls.Id.setValue(this._data.Id)
      if (this.ScreenerForm.invalid) {
        return;
      }
      else {
        this.spinner.show()
        this._Global.IsReady = false
        this.patientInteractionService.ScreenerEdit(this.ScreenerForm.value).subscribe(res => {
          this._Global.IsReady = true;
          this._Global.showSuccessMsg('Answers Updated Successfully');
          this.onNoClick()
          this.spinner.hide()
        }, error => {
          this._Global.IsReady = true;
          this._Global.showErrorMsg(error);
          this.spinner.hide()
        })
      }
    }
    catch (error) {
      this._Global.showErrorMsg(error);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }
}
