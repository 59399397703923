import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { bottomtotop, fadeInOut } from '@app/animation';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { CreatepatientComponent } from '@app/pages/createpatient/createpatient.component';
import { RecruitercalllistMarkComponent } from '@app/pages/recruitercalllist-mark/recruitercalllist-mark.component';
import { RecruitercalllistNotesComponent } from '@app/pages/recruitercalllist-notes/recruitercalllist-notes.component';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { CallListType, ActionType } from '@sharedmodels/DMCREnums/DMCREnums';
import { ScreenerService } from '@sharedservices/BackServices/ComTrak/Screeners/screener.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { CommandClickEventArgs, CommandModel } from '@syncfusion/ej2-angular-grids';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { PatientInteractionDTO } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import {DatePipe} from '@angular/common';
import { DaterangepickerComponent } from '../../components/daterangepicker/daterangepicker.component';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query,DataManager } from '@syncfusion/ej2-data';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'dnqlist',
    templateUrl: 'dnqlist.component.html',
    styleUrls: ['dnqlist.component.scss', '../../style.component.scss'],
    animations: [
        fadeInOut, bottomtotop
    ]
})
export class DnqlistComponent implements OnInit, OnDestroy {
    @ViewChild(DaterangepickerComponent) dateRangPicker: DaterangepickerComponent;
    @ViewChild('StartDate') StartDate: any;
    public commands: CommandModel[];
    @ViewChild('EndDate') EndDate: any;
    srearchForm: FormGroup
    submitted: boolean = false
    startDate: string
    endDate: string
    isMoreData:boolean = true;
    selectedDateRange: { startDate: Date, endDate: Date };
    columnDefinition: CustomGridColumns[]

    studyList: Study[];
    subscriptions = new Subscription();
    dialogOpenFlag = false;

    constructor(private fb: FormBuilder, public studyService: StudyService, public statusService: StatusService, public siteService: SiteService, private scriptService: ScriptService,
        public spinner: NgxSpinnerService, public _PatientInteractionService: PatientInteractionService, public dialog: MatDialog, public _PatientService: PatientService,
        public _Global: GlobalfunctionService, public screenerService: ScreenerService, public campaignRefSourceService: CampaignReferralSourceService,private datePipe: DatePipe) {
        this.commands = [
            { title: 'Add Notes', type: 'Delete', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-file' } },
            { title: 'Edit Patient', type: 'None', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-edit e-icons' } }];
    }

    async ngOnInit() {
        const openScriptDialogSub = this.scriptService.openScriptDialog.subscribe(res => {
            this.dialogOpenFlag = res.openFlag;
            res.openFlag ? this.openModal() : this.closeModal();
        });
        this.subscriptions.add(openScriptDialogSub);

        this.screenerService._DNQListArray = []
        // this.screenerService.GetDNQList(null, null, null, null, null, '')
        await this.getActiveStudies()
        this.gridConfig()
        this.initForm();

        //handling studylist, to be replaced with proper obserables
        this.subscriptions.add(
            this.siteService.sitesStudy.subscribe(
            (studies: Study[]) => this.studyList = studies
            )
        );

        this.siteService.GetActiveSites();
        this.siteService._sitesStudy = this.studyService._activeStudies;
        
        this.campaignRefSourceService.LoadCampaignReferralSource();
        this.campaignRefSourceService.GetAllCampaignReferralSourceAdDetails()

        this.screenerService.isCallApiDNQList.subscribe(res => {
            if (res) {
                this.screenerService.GetDNQList(this.srearchForm.controls.studyId.value, this.srearchForm.controls.siteId.value, this.srearchForm.controls.statusId.value,
                    this.startDate, this.endDate, this.srearchForm.controls.searchString.value)
            }
        })
    }

    openModal(): void {
        // Open the Bootstrap modal
        const modal = document.getElementById('quickScriptModal');
        if (modal) {
            modal.classList.add('show');
            modal.setAttribute('aria-modal', 'true');
            modal.style.display = 'block';
        }
    }

    closeModal(): void {
        // Close the Bootstrap modal
        const modal = document.getElementById('quickScriptModal');
        if (modal) {
            modal.classList.remove('show');
            modal.setAttribute('aria-modal', 'false');
            modal.style.display = 'none';
        }
    }
    
    initForm() {
        this.srearchForm = this.fb.group({
            studyId: [null],
            siteId: ['-1', null],
            statusId: [null, Validators.required],
            startDate: [null],
            endDate: [null],
            searchString: [''],
        })
    }

    OnReset() {
        this.submitted = false
        //this.StartDate.dateString = "";
       // this.EndDate.dateString = "";
        this.initForm();
        this.screenerService._DNQListArray = []
        this.siteService.sitesStudy.next([]);
        // this.screenerService.GetDNQList(null, null, null, null, null, '')
        this.dateRangPicker.clearDateRange();
    }

    GetDNQList() {
        this.screenerService.GetDNQList(this.screenerService.StudyId, this.screenerService.SiteId,
            this.screenerService.StatusId, this.startDate, this.endDate,
            this.screenerService.SearchString)
    }

    onSearch() {
        this.submitted = true;

        if (this.srearchForm.invalid || !this.dateRangPicker.formObject.validate()) {
            return;
        } else {
            this.screenerService.StudyId = this.srearchForm.controls.studyId.value || '-1';
            this.screenerService.SiteId = this.srearchForm.controls.siteId.value
            this.screenerService.StatusId = this.srearchForm.controls.statusId.value
            this.screenerService.StartDate = this.startDate==undefined?"":this.startDate;
            this.screenerService.EndDate = this.endDate==undefined?"":this.endDate;
            this.screenerService.SearchString = this.srearchForm.controls.searchString.value

            this.screenerService.GetDNQList(this.srearchForm.controls.studyId.value, this.srearchForm.controls.siteId.value, this.srearchForm.controls.statusId.value,
                this.screenerService.StartDate, this.screenerService.EndDate, this.srearchForm.controls.searchString.value)
        }
    }

    OpenNewDialog(row: any) {
        this.spinner.show()
        this._PatientService.GetPatientDetailById(row.Id).subscribe(res => {
            const dialogRef = this.dialog.open(CreatepatientComponent, {
                panelClass: ['dailogwidth', 'comtrak-modal'],
                disableClose: true,
                data: {
                    callList: <Patient>res,
                    screenType: CallListType.DNQScreener
                }
            })
            this.spinner.hide()
        }, error => {
            this._Global.handleError(error);
            this.spinner.hide()
        })
    }

    ChangeSite() {
        if (this.srearchForm.controls.siteId.value != null) {
            this.siteService.GetStudiesBySite(this.srearchForm.controls.siteId.value)
        } else {
            this.siteService._sitesStudy = this.studyService._activeStudies;
            this.siteService.sitesStudy.next([]);
        }
    }

    get f() {
        return this.srearchForm.controls;
    }

    OpenMarkDialog(row: any) {
        this.spinner.show()
        this._PatientInteractionService.GetInteractions(CallListType.PreScreener, row.Id, row.StatusId, row.LastInteractionId).subscribe(res => {
            this._PatientInteractionService.GetNextVisits("GetNextVisits", row.Id, row.StudyId)
            const dialogRef = this.dialog.open(RecruitercalllistMarkComponent, {
                panelClass: ['dailogwidth', 'comtrak-modal'],
                disableClose: true,
                data: {
                    callList: <PatientInteraction>res,
                    screenType: CallListType.PreScreener,
                    IsDNQ: true,
                    isEdit: false,
                    formPreview : true,
                    lastInterationStatus: row.Status ? row.Status : "",
                    lastInterationStatusId: row.StatusId ? row.StatusId : "",
                    rowData: row,
                }
            });
            this.spinner.hide()
        }, err => {
            this._Global.handleError(err);
            this.spinner.hide()
        })
    }

    OpenNotesDialog(row: any, pageNo = 1) {
        let params = new HttpParams()
        .append('PageNo', String(pageNo))

        this.spinner.show()
        forkJoin([this._PatientInteractionService.GetPatientInteractions(row.Id),
        this._PatientInteractionService.GetInteractions(CallListType.PatientNotes, row.Id, row.StatusId, row.LastInteractionId),
        this._PatientService.GeCommunicationHistoryById(row.Id, params)]).subscribe(res => {
            let array = [
                { PatientInteraction: <PatientInteractionDTO>res[0], patientInfo: <Patient>res[1], patientList: res[2] }
            ]
            this.isMoreData =  res[2].pagination.isMore;
            const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
                panelClass: ['dailogwidth', 'comtrak-modal'],
                disableClose: true,
                data: {
                    PatientInteraction: array,
                    hideAddNotes: true,
                    rowData: row,
                    isMoreData:this.isMoreData,
                }
            });
            this.spinner.hide()
        }, err => {
            this._Global.handleError(err);
            this.spinner.hide()
        })
    }

    commandClick(args: CommandClickEventArgs): void {
        if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.MarkInteraction) {
            this.OpenMarkDialog(args.rowData)
        }
        else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Notes) {
            this.OpenNotesDialog(args.rowData)
        }
        else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Edit) {
            this.OpenNewDialog(args.rowData)
        }
    }

    ChangeStartDate($event) {
        this.startDate = $event
        if (this.endDate != '') {
            if (this.startDate > this.endDate) {
                this._Global.showErrorMsg("Start Date cannot be greater then End Date ")
                this.srearchForm.controls.startDate.setValue(new Date())
            }
            else {
                return false;
            }
        }
    }

    ChangeEndDate($event) {
        this.endDate = $event
        if (this.startDate != '') {
            if (this.endDate < this.startDate) {
                this._Global.showErrorMsg("End Date cannot be less then Start Date ")
                this.srearchForm.controls.endDate.setValue(new Date())
            }
            else {
                return false;
            }
        }
    }

    gridConfig() {
        this.columnDefinition = [
            {
                header: 'Name',
                key: "FullName",
                width: 180,
                visible: true,
                type: "string",
            },
            {
                header: 'Phone',
                key: "Phone",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'Email',
                key: "Email",
                width: 220,
                visible: true,
                type: "string",
            },
            {
                header: 'Crio ID',
                key: "CrioId",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'Comtrak Id',
                key: "Id",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'Scheduled Date & Time',
                key: "ScheduledDateTime",
                width: 210,
                visible: true,
                type: "string",
            },
            {
                header: 'Study',
                key: "StudyName",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'Status',
                key: "Status",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'SubStatus',
                key: "SubStatus",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'Site',
                key: "SiteName",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'State',
                key: "State",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'DNQ / NTC Status',
                key: "DNQStatus",
                width: 150,
                visible: true,
                type: "string",
            },
            {
                header: 'Date & Time Of Marking Interaction',
                key: "DNQCreatedON",
                width: 300,
                visible: true,
                type: "string",
            },
        ]
    }

    onDateRangeChange(dateRange: { startDate: Date, endDate: Date }): void {
        this.startDate=this.datePipe.transform(dateRange.startDate,"yyyy-MM-dd");
        this.endDate=this.datePipe.transform(dateRange.endDate,"yyyy-MM-dd");
      }

    onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        let query = new Query();
        let stitedata : any[]= this.siteService._sitesStudy;
        query = (e.text != "") ? query.where("Name", "Contains", e.text, true) : query;
        e.updateData(stitedata, query);
    };
    async getActiveStudies(){
        await this.studyService.GetActiveStudies();
      }

      ngOnDestroy(): void {
        if(this.subscriptions) this.subscriptions.unsubscribe();
    }
}