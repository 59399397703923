import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as myGlobals from '@shared/global_var';
import { HelperService } from '@sharedservices/helper.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CommunicationService {
    TotalCountScriptPendingingReview:BehaviorSubject<number> = new BehaviorSubject(0);
    TotalCountCommunicationPendingingReview:BehaviorSubject<number> = new BehaviorSubject(0);
    patient_apiUrl = `${myGlobals._apiPathv3}patient-manage`;
    masscom_apiUrl = `${myGlobals._apiPathv3}mass-com`;
    notification_apiUrl = `${myGlobals._apiPathv3}notifications`;

    constructor(
        public http: HttpClient,
        private _Global: GlobalfunctionService,
        private helper: HelperService
    ) { }

    // step 1 get referral list
    getReferralSourceList(): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/referral-list`, {}, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // step 1 get study list
    getStudyList(requestBody): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/study-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // step 1 get department list
    getDepartmentList(): Observable<any> {
        return this.http.get(`${this.masscom_apiUrl}/list-department`, { headers: this._Global.httpOptions }) as Observable<any>;
    }


    // stepper 3 apis

    getStatusList(option): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/status-list`, {params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSubStatusList(requestBody): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/substatus-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getCounterList(requestBody): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/get-status-counter-data`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getCampaignList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/campaigns-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getReferralList(requestBody): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/referral-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getIndicationList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/therapeutic-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getStudyVisitCompletedVisitList(studyId: number): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/study-visit-list?studyId=${studyId}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getStudyVisitStatusList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/study-visit-status-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getStateList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/state-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSiteList(requestBody): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/site-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getLanguageList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/language-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getTransportationList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/transportation-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getVaccineList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/vaccine-indication-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // patient count
    getPatientFilterCount(data: any): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/patients-count`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // Communication Draft
    getConfirmationStatusList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/confirmation-status-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // get insert script data
    getScriptEditorData(option: HttpParams): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}script-repos/Scripts`, { params: option,headers: this._Global.httpOptions }) as Observable<any>;
    }

    // Communication Draft
    getCommunicationList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.masscom_apiUrl}/communication`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    //get communication detail
    getCommunicationDetail(id: string ): Observable<any> {
        return this.http.get(`${this.masscom_apiUrl}/communication/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // communication actions: pin, duplicate, active, 
    communicationAction(id: string, action: string): Observable<any> {
        return this.http.post(`${this.masscom_apiUrl}/communication/${action}/${id}`, {}, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // change status API
    changeCommunicationAction(requestBody: any): Observable<any> {
        return this.http.post(`${this.masscom_apiUrl}/communication/change-status`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    //save step API
    saveCommunicationData(requestBody): Observable<any> {
        return this.http.post(`${this.masscom_apiUrl}/communication`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    //Hashtag Edit API
    HashtagEdit(data): Observable<any> {
        return this.http.post(`${this.masscom_apiUrl}/update-hashtag`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    //get sender email
    getSenderEmail(option: HttpParams): Observable<any> {
        const cacheKey = 'senderEmailList';
        const cachedData = this.helper.getCache(cacheKey);
        if (cachedData) {
            return cachedData;
        } else {
            return this.http.get(`${this.masscom_apiUrl}/get-sender-email`, { params: option, headers: this._Global.httpOptions })
            .pipe(
                tap(data => this.helper.setCache(cacheKey, data))
            );
        }
    }

    getScriptList(requestBody): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/script-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // get Patient list 
    getPatientList(): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/patients-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    //send test call
    postSendTest(data: any): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/send-test`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    //get incomming Response list
    getIncommingResponseList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/incoming-communication-history`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    pinIncommingResponseList(id: string): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/incoming-communication-pin/${id}`, {}, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getPatientStatusList(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/auth`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    verifyPatient(code: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/verify-gmail?code=${code}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getEmailAccountList(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/email-account-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getAnalyticsList(type:string,id:string): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/communication-analytics/${type}/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    sendSMS(smsData: any): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/send-sms`, smsData, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSenderPhoneList(): Observable<any> {
        const cacheKey = 'senderPhoneList';
        const cachedData = this.helper.getCache(cacheKey);
        if (cachedData) {
            return cachedData;
        } else {
            return this.http.get(`${this.masscom_apiUrl}/get-sender-phone`, { headers: this._Global.httpOptions })
            .pipe(
                tap(data => this.helper.setCache(cacheKey, data))
            );
        }
    }
    
    getNotificationSettingList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/setting/list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    setNotificationSettingData(data: any): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/setting/update`, data , { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getAvailableUsers(option): Observable<any> {
        return this.http.post(`${this.masscom_apiUrl}/calling/available-users`, {}, {params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }
    
    generateToken(agentEmail: string): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/calling/generate-token`, { agent_email: agentEmail }, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSyncList(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/setting/sync-ivr-phone`, { headers: this._Global.httpOptions }) as Observable<any>;
    }
      
    getCallMatrics(option: HttpParams): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/communication-report`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }
    getNotifyMe(id:string): Observable<any> {
        return this.http.get(`${this.patient_apiUrl}/get-recruiter-notify-settings/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    
    postNotifyMeStatus(data: any): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/update-recruiter-notify-settings`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

}