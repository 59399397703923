import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.css']
})
export class StatCardComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() color: string;
  @Input() height: string;
  @Input() width: string;
  @Input() varient: string;
 @Input() tooltip: string;
  constructor() { }

  ngOnInit() {
  }

}
