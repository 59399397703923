import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListFilter } from '@sharedmodels/BackModels/ComTrak/DBLM/DBLM';
import { CampaignReferralSource } from '@sharedmodels/BackModels/ComTrak/Setups/CampaignReferralSource';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { DBLMService } from '@sharedservices/BackServices/ComTrak/DBLM/dblm.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StaticDataService } from '@sharedservices/BackServices/ComTrak/Setups/StaticData.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { TherapeuticAreaService } from '@sharedservices/BackServices/ComTrak/Therapeutic/therapeutic-area.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-createinclusion',
  templateUrl: './createinclusion.component.html',
  styleUrls: ['./createinclusion.component.scss']
})
export class CreateinclusionComponent implements OnInit {
  format: string = 'M/d/yyyy';
  InclusionForm: FormGroup
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    // itemsShowLimit: 5000,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  dropdownSettingsState: IDropdownSettings = {
    singleSelection: false,
    idField: 'StateID',
    textField: 'Name',
    // itemsShowLimit: 5000,
    allowSearchFilter: true,
    enableCheckAll: false
  };

  dropdownSetting: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    // itemsShowLimit: 5000,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  public LanguageData: Lov[] = []
  siteIds: any[] = [];
  campaignIds: any[] = [];
  campaignrefData: CampaignReferralSource[] = [];
  listFiltersData: ListFilter[] = [];
  filterTypeId: number;
  formControlType: string;
  @Input() IsInclusion: boolean
  @Input() isSave: boolean
  @Input() isDisabledAllFields: boolean
  isEdit: boolean;
  count: number = 0
  isCallGetApi: boolean = false;
  therapeuticAreaIds: any[] = [];
  _TAIndicationIds: any[] = [];
  campaignrefDataTwo: any[] = [];
  constructor(public _lovService: LovService, public staticDataService: StaticDataService, public _Global: GlobalfunctionService, public locationService: LocationService,
    public campaignService: CampaignService, public campaignrefService: CampaignReferralSourceService, public siteService: SiteService, public studyService: StudyService,
    public statusService: StatusService, public fb: FormBuilder, public dblmService: DBLMService, public spinner: NgxSpinnerService,
    public _TherapeutincAreaService: TherapeuticAreaService) { }

  ngOnInit() {
    this.campaignrefService._alertConfigCampignRef = []
    this.count++;
    if (this.count == 1) {
      this.initForm()
    }
    this.getLanguageData()
    //debugger
    if (this.dblmService.listMasterId) {
      this.dblmService.GetlistPatientsCount(this.dblmService.listMasterId).subscribe(res => {
        //debugger
        if (res > 0) {
          this.isCallGetApi = false
          this.dblmService._dblmCount = res
          this.spinner.hide()
          this._Global.IsReady = true;
        }
        else {
          this.isCallGetApi = true
        }
        if (this.dblmService._listFiltersByListMaster && this.dblmService._listFiltersByListMaster.length && this.dblmService._listFiltersByListMaster.length > 0 && this.isSave == false) {
          this.isEdit = true
          this.setEditData(this.IsInclusion)
        }
        else {
          this.isEdit = false
        }
        var btn: any = document.getElementById("btn")
        btn.click()
      }, error => {
        this._Global.handleError(error);
        this.spinner.hide()
        this._Global.IsReady = true;
      })
    }
    if (this.isDisabledAllFields) {
      Object.keys(this.InclusionForm.controls).forEach(key => {
        this.InclusionForm.controls[key].disable()
      })
    }
    else {
      Object.keys(this.InclusionForm.controls).forEach(key => {
        this.InclusionForm.controls[key].enable()
      })
    }
  }

  setEditData(IsInclusion: boolean) {
    var dataArray: ListFilter[] = this.dblmService._listFiltersByListMaster.filter(o => o.IsInclusion == IsInclusion)
    this.InclusionForm.controls.toInteractionDate.setValue(dataArray.find(o => o.FilterTypeId == 16) ? dataArray.find(o => o.FilterTypeId == 16).FilterTypeValue : "")
    this.InclusionForm.controls.fromInteractionDate.setValue(dataArray.find(o => o.FilterTypeId == 15) ? dataArray.find(o => o.FilterTypeId == 15).FilterTypeValue : "")
    this.InclusionForm.controls.toBMI.setValue(dataArray.find(o => o.FilterTypeId == 11) ? dataArray.find(o => o.FilterTypeId == 11).FilterTypeValue : "")
    this.InclusionForm.controls.fromBMI.setValue(dataArray.find(o => o.FilterTypeId == 10) ? dataArray.find(o => o.FilterTypeId == 10).FilterTypeValue : "")
    this.InclusionForm.controls.gender.setValue(dataArray.find(o => o.FilterTypeId == 8) ? dataArray.find(o => o.FilterTypeId == 8).FilterTypeValue : "")
    this.InclusionForm.controls.toAge.setValue(dataArray.find(o => o.FilterTypeId == 7) ? dataArray.find(o => o.FilterTypeId == 7).FilterTypeValue : "")
    this.InclusionForm.controls.fromAge.setValue(dataArray.find(o => o.FilterTypeId == 6) ? dataArray.find(o => o.FilterTypeId == 6).FilterTypeValue : "")
    this.InclusionForm.controls.language.setValue(dataArray.find(o => o.FilterTypeId == 9) ? +dataArray.find(o => o.FilterTypeId == 9).FilterTypeValue : "")
    this.InclusionForm.controls.fromCreationDate.setValue(dataArray.find(o => o.FilterTypeId == 17) ? +dataArray.find(o => o.FilterTypeId == 17).FilterTypeValue : "")
    this.InclusionForm.controls.toCreationDate.setValue(dataArray.find(o => o.FilterTypeId == 18) ? +dataArray.find(o => o.FilterTypeId == 18).FilterTypeValue : "")
    // ------------------- FOR STATUS --------------------- //
    var statusesArray: any = []
    dataArray.filter(o => o.FilterTypeId == 3).forEach(element => {
      var statusObj = this.statusService._status.find(o => o.Id == element.FilterTypeValue)
      if (statusObj) {
        statusesArray.push({ Id: statusObj.Id, Name: statusObj.Name })
      }
    })
    this.InclusionForm.controls.statusIds.setValue(statusesArray)
    // ------------------- FOR CAMPAIGN --------------------- //
    var campaignArray: any = []
    dataArray.filter(o => o.FilterTypeId == 13).forEach(element => {
      var campaignObj = this.campaignService._Campaign.find(o => o.Id == element.FilterTypeValue)
      if (campaignObj) {
        campaignArray.push({ Id: campaignObj.Id, Name: campaignObj.Name })
      }
    })
    this.InclusionForm.controls.campaignIds.setValue(campaignArray)
    // ------------------- FOR STATE --------------------- //
    var stateArray: any = []
    dataArray.filter(o => o.FilterTypeId == 12).forEach(element => {
      var stateObj = this.locationService._ActiveStateArray.find(o => o.StateID == element.FilterTypeValue)
      if (stateObj) {
        stateArray.push({ StateID: stateObj.StateID, Name: stateObj.Name })
      }
    })
    this.InclusionForm.controls.stateIds.setValue(stateArray)
    // ------------------- FOR SITE --------------------- //
    var siteArray: any = []
    dataArray.filter(o => o.FilterTypeId == 1).forEach(element => {
      var siteObj = this.siteService._activeSites.find(o => o.Id == element.FilterTypeValue)
      if (siteObj) {
        siteArray.push({ Id: siteObj.Id, Name: siteObj.Name })
      }
    })
    this.InclusionForm.controls.siteIds.setValue(siteArray)
    // ------------------- FOR THERAPEUTICAREA --------------------- //
    var _TAArray: any = []
    dataArray.filter(o => o.FilterTypeId == 19).forEach(element => {
      var TAObject = this._TherapeutincAreaService._therapeuticArea.find(o => o.Id == element.FilterTypeValue)
      if (TAObject) {
        _TAArray.push({ Id: TAObject.Id, Name: TAObject.Name })
      }
    })
    this.InclusionForm.controls.therapeutiocAreaIds.setValue(_TAArray)
    // ------------------- FOR THERAPEUTICAREAINDICATIONS --------------------- //
    dataArray.filter(o => o.FilterTypeId == 19).forEach(element => {
      this.onSelectTA(element.FilterTypeValue)
    })
    var InidcationsArray: any = []
    dataArray.filter(o => o.FilterTypeId == 20).forEach(element => {
      var IndicationObject = this._TherapeutincAreaService._therapeuticAreaIndications.find(o => o.Id == element.FilterTypeValue)
      if (IndicationObject) {
        InidcationsArray.push({ Id: IndicationObject.Id, Name: IndicationObject.Name })
      }
    })
    this.InclusionForm.controls.indicationIds.setValue(InidcationsArray)
    // ------------------- FOR STUDY --------------------- //
    dataArray.filter(o => o.FilterTypeId == 1).forEach(element => {
      this.siteIds.push({ Id: element.FilterTypeValue })
      this.onSelectSite(element.FilterTypeValue)
    })
    // this.onDropDownCloseSite()
    var studyArray: any = []
    dataArray.filter(o => o.FilterTypeId == 2).forEach(element => {
      var studyObj = this.studyService._activeStudiesArray.find(o => o.Id == element.FilterTypeValue)
      if (studyObj) {
        studyArray.push({ Id: studyObj.Id, Name: studyObj.Name })
      }
    })
    this.InclusionForm.controls.studyIds.setValue(studyArray)
    // ------------------- FOR CAMPAIGN REFERRAL --------------------- //
    dataArray.filter(o => o.FilterTypeId == 13).forEach(element => {
      this.campaignIds.push({ Id: element.FilterTypeValue })
      this.onSelectCampaign(element.FilterTypeValue)
    })
    this.onDropDownCloseCampaign()
    var campaignRefArray: any = []
    dataArray.filter(o => o.FilterTypeId == 14).forEach(element => {
      var campaignRefObj = this.campaignrefData.find(o => o.Id == element.FilterTypeValue)
      if (campaignRefObj) {
        campaignRefArray.push({ Id: campaignRefObj.Id, Name: campaignRefObj.Name })
      }
    })
    this.InclusionForm.controls.campaignRefIds.setValue(campaignRefArray)
    if (this.isCallGetApi) {
      this.onSearch(1, false)
    }

  }

  initForm() {
    this.InclusionForm = this.fb.group({
      fromAge: [null],
      toAge: [null],
      gender: [null],
      language: [null],
      fromBMI: [null],
      toBMI: [null],
      fromInteractionDate: [null],
      toInteractionDate: [null],
      campaignIds: [null],
      campaignRefIds: [null],
      siteIds: [null],
      studyIds: [null],
      statusIds: [null],
      stateIds: [null],
      fromCreationDate: [null],
      toCreationDate: [null],
      therapeutiocAreaIds: [null],
      indicationIds: [null],
    })
  }

  getLanguageData() {
    this._lovService.getLovDataById(LovStatusMasterID.Language).subscribe(res => {
      this.LanguageData = <Lov[]>res;
    }, error => {
      this._Global.handleError(error)
    })
  }

  Onlynumberbmi(event): boolean {
    const Regexpattern = /[-.(0-9) ]/;
    const result = Regexpattern.test(event.key);
    return result;
  }

  onSelectTA(event: any) {
    let dataArray: Array<any> = [];
    this.InclusionForm.controls.indicationIds.setValue([])
    let therapeuticAreaId = event && event.Id ? event.Id : event
    this.therapeuticAreaIds.push({ Id: therapeuticAreaId })
    this.therapeuticAreaIds.forEach(Id => {
      this._TherapeutincAreaService._therapeuticArea.filter(o => o.Id == Id.Id).forEach(element => {
        element.TherapeuticAreaIndications.forEach(data => {
          dataArray.push({ Id: data.Id, Name: data.Name, TherapeuticAreaId: Id.Id })
        });
        this._TherapeutincAreaService._therapeuticAreaIndications = dataArray
      })
    })
  }

  onItemDeSelectTA(event: any) {
    this.therapeuticAreaIds = this.therapeuticAreaIds.filter(o => o.Id != event.Id)
    this._TherapeutincAreaService._therapeuticAreaIndications = this._TherapeutincAreaService._therapeuticAreaIndications.filter(o => o.TherapeuticAreaId != event.Id)
    this.InclusionForm.controls.indicationIds.setValue([])
  }

  onSelectIndicationId(event: any) {
    this.studyService._activeStudiesArray = []
    this.InclusionForm.controls.studyIds.setValue([])
    let indicationId = event && event.Id ? event.Id : event
    this._TAIndicationIds.push({ Id: indicationId })
    this.studyService._activeStudies.filter(o => o.TherapeuticAreaIndicationId == indicationId).forEach(element => {
      this.siteService._alertConfigSiteStudies.push({ Id: element.Id, Name: element.Name, SiteId: null, TherapeuticAreaIndicationId: indicationId })
    })
    setTimeout(() => {
      this.studyService._activeStudiesArray = this.siteService._alertConfigSiteStudies
    }, 300);
  }

  onItemDeSelectIndicationId(event: any) {
    this._TAIndicationIds = this._TAIndicationIds.filter(o => o.Id != event.Id)
    this.siteService._alertConfigSiteStudies = this.siteService._alertConfigSiteStudies.filter(o => o.TherapeuticAreaIndicationId != event.Id)
    this.studyService._activeStudiesArray = this.studyService._activeStudiesArray.filter(o => o.TherapeuticAreaIndicationId != event.Id)
    this.InclusionForm.controls.studyIds.setValue([])
    if (this.studyService._activeStudiesArray.length == 0) {
      this.studyService._activeStudiesArray = this.studyService._activeStudies
    }
  }

  onSelectSite(event: any) {
    this.InclusionForm.controls.studyIds.setValue([])
    let siteId = event && event.Id ? event.Id : event
    this.siteIds.push({ Id: siteId })
    this.studyService._activeStudies.filter(o => o.siteId == siteId).forEach(element => {
      this.siteService._alertConfigSiteStudies.push({ Id: element.Id, Name: element.Name, SiteId: siteId, TherapeuticAreaIndicationId: null })
    })
    setTimeout(() => {
      this.studyService._activeStudiesArray = this.siteService._alertConfigSiteStudies
    }, 300);
  }

  onItemDeSelectSite(event: any) {
    this.siteIds = this.siteIds.filter(o => o.Id != event.Id)
    this.siteService._alertConfigSiteStudies = this.siteService._alertConfigSiteStudies.filter(o => o.SiteId != event.Id)
    this.studyService._activeStudiesArray = this.studyService._activeStudiesArray.filter(o => o.SiteId != event.Id)
    this.InclusionForm.controls.studyIds.setValue([])
    if (this.studyService._activeStudiesArray.length == 0) {
      this.studyService._activeStudiesArray = this.studyService._activeStudies
    }
  }

  // onDropDownCloseSite() {
  // if (this.siteIds && this.siteIds.length && this.siteIds.length > 0) {
  //   if (this.siteService._alertConfigSiteStudies && this.siteService._alertConfigSiteStudies.length > 0) {
  //     this.studyService._activeStudiesArray = this.siteService._alertConfigSiteStudies
  //   }
  // }
  // }

  onSelectCampaign(event: any) {
    let dataArray: Array<any> = [];
    this.InclusionForm.controls.campaignRefIds.setValue([])
    let campaignId = event && event.Id ? event.Id : event
    this.campaignIds.push({ Id: campaignId })
    this.campaignrefService._CampaignReferralSource.filter(o => o.CampaignId == campaignId).forEach(element => {
      this.campaignrefService._alertConfigCampignRef.push({ Id: element.Id, Name: element.Name, CampaignId: campaignId })
    })
    setTimeout(() => {
      if (this.campaignIds && this.campaignIds.length && this.campaignIds.length > 0) {
        if (this.campaignrefService._alertConfigCampignRef && this.campaignrefService._alertConfigCampignRef.length > 0) {
          this.campaignrefService._alertConfigCampignRef.forEach(element => {
            dataArray.push(element)
          });
          this.campaignrefData = dataArray
          console.log("this.campaignrefData = ", this.campaignrefData)
          console.log("this.campaignrefService._alertConfigCampignRef = ", this.campaignrefService._alertConfigCampignRef)
        }
      }
      else {
        this.campaignrefData = []
      }
    }, 300);
  }

  onItemDeSelectCampaign(event: any) {
    this.campaignIds = this.campaignIds.filter(o => o.Id != event.Id)
    this.campaignrefData = this.campaignrefData.filter(o => o.CampaignId != event.Id)
    this.InclusionForm.controls.campaignRefIds.setValue([])
  }

  onDropDownCloseCampaign() {
    // if (this.campaignIds && this.campaignIds.length && this.campaignIds.length > 0) {
    //   if (this.campaignrefService._alertConfigCampignRef && this.campaignrefService._alertConfigCampignRef.length > 0) {
    //     this.campaignrefData = this.campaignrefService._alertConfigCampignRef
    //   }
    // }
    // else {
    //   this.campaignrefData = []
    // }
  }

  getlistFilterId(filterTypeValue: any, filterTypeId: any) {
    var dataObject = this.dblmService._listFiltersByListMaster.filter(o => o.FilterTypeId == filterTypeId && +o.FilterTypeValue == filterTypeValue)[0]
    if (dataObject && dataObject.Id) {
      return dataObject.Id
    }
    else {
      return 0
    }
  }

  onSearch(vsCount: number, isMSG: boolean) {
    if (this.IsInclusion) {
      this.dblmService.InclusionValue = this.InclusionForm.value
    }
    let isCallApi: boolean = false
    Object.keys(this.InclusionForm.controls).forEach(key => {
      if (this.InclusionForm.controls[key].value != null && this.InclusionForm.controls[key].value != '') {
        isCallApi = true
      }
    })
    if (isCallApi == false) {
      this.dblmService._dblmCount = 0
    }
    if (isCallApi) {
      if (this.IsInclusion) {
        this.dblmService.GetDBLMCount(vsCount, this.dblmService.InclusionValue, undefined, isMSG)
      }
      else {
        this.dblmService.GetDBLMCount(vsCount, this.dblmService.InclusionValue, this.InclusionForm.value, isMSG)
      }
    }
  }

  onCreateList(vsCount: number, isMSG: boolean) {
    if (this.IsInclusion) {
      this.dblmService.InclusionValue = this.InclusionForm.value
    }
    if (this.IsInclusion) {
      this.dblmService.GetDBLMCount(vsCount, this.dblmService.InclusionValue, undefined, isMSG)
    }
    else {
      this.dblmService.GetDBLMCount(vsCount, this.dblmService.InclusionValue, this.InclusionForm.value, isMSG)
    }
    this.onSubmit()
  }

  onSubmit() {
    this.listFiltersData = []
    Object.keys(this.InclusionForm.controls).forEach(key => {
      if (this.InclusionForm.get(key).value && this.InclusionForm.get(key).value != "") {
        this.setFilterTypeId(key)
        if (this.formControlType == "Array") {
          this.InclusionForm.get(key).value.forEach(element => {
            this.listFiltersData.push({
              Id: this.getlistFilterId(element.Id, this.filterTypeId),
              ListMasterId: this.dblmService.listMasterId,
              FilterTypeId: this.filterTypeId,
              FilterTypeValue: element.Id ? element.Id : element.StateID,
              IsInclusion: this.IsInclusion
            })
          });
        }
        else {
          this.listFiltersData.push({
            Id: this.getlistFilterId(this.InclusionForm.get(key).value, this.filterTypeId),
            ListMasterId: this.dblmService.listMasterId,
            FilterTypeId: this.filterTypeId,
            FilterTypeValue: this.InclusionForm.get(key).value,
            IsInclusion: this.IsInclusion
          })
        }
      }
    });
    console.log("this.listFiltersData", this.listFiltersData)
    if (!this.isEdit) {
      this.dblmService.SaveListFilters(this.listFiltersData)
    }
    else {
      this.dblmService.EditListFilters(this.listFiltersData, this.IsInclusion)
    }
    this.dblmService.IsSaveList.subscribe(res => {
      if (res && !this.IsInclusion) {
        // this.dblmService.GetAllDBLMList('')
        var btn: any = document.getElementById("onNoClick")
        btn.click()
      }
    })
  }

  Click() {

  }

  setFilterTypeId(key: string) {
    switch (key) {
      case "fromAge": {
        this.filterTypeId = 6;
        this.formControlType = "nonArray"
        break;
      }
      case "toAge": {
        this.filterTypeId = 7;
        this.formControlType = "nonArray"
        break;
      }
      case "gender": {
        this.filterTypeId = 8;
        this.formControlType = "nonArray"
        break;
      }
      case "language": {
        this.filterTypeId = 9;
        this.formControlType = "nonArray"
        break;
      }
      case "fromBMI": {
        this.filterTypeId = 10;
        this.formControlType = "nonArray"
        break;
      }
      case "toBMI": {
        this.filterTypeId = 11;
        this.formControlType = "nonArray"
        break;
      }
      case "fromInteractionDate": {
        this.filterTypeId = 15;
        this.formControlType = "nonArray"
        break;
      }
      case "toInteractionDate": {
        this.filterTypeId = 16;
        this.formControlType = "nonArray"
        break;
      }
      case "campaignIds": {
        this.filterTypeId = 13;
        this.formControlType = "Array"
        break;
      }
      case "campaignRefIds": {
        this.filterTypeId = 14;
        this.formControlType = "Array"
        break;
      }
      case "siteIds": {
        this.filterTypeId = 1;
        this.formControlType = "Array"
        break;
      }
      case "studyIds": {
        this.filterTypeId = 2;
        this.formControlType = "Array"
        break;
      }
      case "statusIds": {
        this.filterTypeId = 3;
        this.formControlType = "Array"
        break;
      }
      case "stateIds": {
        this.filterTypeId = 12;
        this.formControlType = "Array"
        break;
      }
      case "therapeutiocAreaIds": {
        this.filterTypeId = 19;
        this.formControlType = "Array"
        break;
      }
      case "indicationIds": {
        this.filterTypeId = 20;
        this.formControlType = "Array"
        break;
      }
      case "fromCreationDate": {
        this.filterTypeId = 17;
        this.formControlType = "nonArray"
        break;
      }
      case "toCreationDate": {
        this.filterTypeId = 18;
        this.formControlType = "nonArray"
        break;
      }
      default: {
        this.filterTypeId = 0;
        break;
      }
    }
  }
}
