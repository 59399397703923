import { NgxSpinnerService } from 'ngx-spinner';
import { Campaign } from './../../../../Models/BackModels/ComTrak/Setups/Campaign';
import { Observable, Observer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { CampaignReferralSource, CampaignReferralSourceAdvertisement } from '@sharedmodels/BackModels/ComTrak/Setups/CampaignReferralSource';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {
    public isCallApi = new Subject<boolean>();
    public _Campaign: Campaign[]
    public _AllCampaign: Campaign[]
    public _CampaignArray: Campaign[]
    public arraylength: number;
    _leadsCampaign: Campaign[];
    _patientsCampaign: any[] = [];
    _patientsCampaignAdDetails: any[] = [];
    _patientsCampaignRefSource: any[] = [];
    _patientsCampaignWiseRefSource: any[] = [];
    _patientsCampaignRefAdds: any[] = [];
    _LeadsPatientsCampaigns: any[] = [];
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService) { }

    LoadActiveCampaign() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'Campaign', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    let dataArray: Campaign[] = <Campaign[]>data;
                    this._Campaign = dataArray;//.sort((a, b) => 0 - (a.Name > b.Name ? -1 : 1));
                    this._Global.IsReady = true;
                    //this.spinner.hide()
                    this.isCallApi.next(true);
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.isCallApi.next(false)
            this.spinner.hide()
        }
    }
    GetActiveCampaigns() {
        if (this._Campaign !== undefined && this._Campaign.length !== null) {
            return this._Campaign;
        } else {
            this.LoadActiveCampaign();
        }
    }

    LoadAllCampaign(searchString: string) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'Campaign?type=GetAll&searchString=' + searchString, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._AllCampaign = <Campaign[]>data;
                    this._CampaignArray = <Campaign[]>data;
                    this.arraylength = this._AllCampaign.length
                    this.isCallApi.next(true)
                    this._Global.IsReady = true;
                    this.spinner.hide()
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.isCallApi.next(false)
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.isCallApi.next(false)
            this.spinner.hide()
        }
    }

    GetAllCampaigns(searchString: string) {
        if (this._AllCampaign !== undefined && this._AllCampaign.length !== null) {
            return this._AllCampaign;
        } else {
            this.LoadAllCampaign(searchString);
        }
    }

    SaveCampaigns(body: Campaign) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Campaign', body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Campaign Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadAllCampaign("")
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    EditCampaigns(body: Campaign) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'Campaign?Type=Edit', body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Campaign Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    this.LoadAllCampaign("")
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    SaveAndEditCampaigns(body: Campaign, isEdit: boolean) {
        if (isEdit) {
            this.EditCampaigns(body)
        }
        else {
            this.SaveCampaigns(body)
        }
    }

    CampaignActiveInActive(body: Campaign): Observable<any> {
        return this._http.put(myGlobals._apiPath + 'Campaign?Type=CampaignActiveInActive', body, { headers: this._Global.httpOptions })
    }

    GetLeadsCampaigns() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Campaign?type=GetLeadsCampaigns', { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._leadsCampaign = <Campaign[]>data;
                    this._Global.IsReady = true;
                    this.spinner.hide()
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    removeDeuplicateValues(dataArray: any[]) {
        var uniqueDataArray = dataArray.reduce((item, o) => {
            if (!item.some(obj => obj.Id === o.Id && obj.Name === o.Name)) {
                item.push(o);
            } return item
        }, []);
        return uniqueDataArray
    }

    GetPatientsCampaigns(spName: string, recruiterId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Campaign?type=GetPatientsCampaigns&spName=' + spName + '&recruiterId=' + recruiterId + "&campaignId=" +
                null + "&campaignRefId=" + null, { headers: this._Global.httpOptions })
                .subscribe((data: any[]) => {
                    let patientCampaigns = []
                    let patientCampaignRefs = []
                    let patientCampaignRefAdds = []
                    data.forEach((element: any) => {
                        patientCampaigns.push({
                            Id: element.CampaignId,
                            Name: element.CampaignName,
                        })
                        if (element.CampaignRefID != null) {
                            patientCampaignRefs.push({
                                Id: element.CampaignRefID,
                                Name: element.CampaignRefName,
                                CampaignId: element.CampaignId,
                            })
                        }
                        if (element.CampaignRefAddID != null) {
                            patientCampaignRefAdds.push({
                                Id: element.CampaignRefAddID,
                                Name: element.CampaignRefAddName,
                                CampaignRefID: element.CampaignRefID,
                            })
                        }
                    })
                    if (patientCampaigns.length > 0) {
                        this._patientsCampaign = this.removeDeuplicateValues(patientCampaigns)
                    }
                    if (patientCampaignRefs.length > 0) {
                        this._patientsCampaignRefSource = this.removeDeuplicateValues(patientCampaignRefs)
                    }
                    if (patientCampaignRefAdds.length > 0) {
                        this._patientsCampaignAdDetails = this.removeDeuplicateValues(patientCampaignRefAdds)
                    }
                    this._Global.IsReady = true;
                    this.spinner.hide()
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetLeadsPatientsCampaigns(spName: string, recruiterId: number) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.get(myGlobals._apiPath + 'Campaign?type=GetLeadsPatientsCampaigns&spName=' + spName + '&recruiterId=' + recruiterId + "&campaignId=" +
                null + "&campaignRefId=" + null, { headers: this._Global.httpOptions })
                .subscribe((data: any[]) => {
                    this._LeadsPatientsCampaigns = data
                    this._Global.IsReady = true;
                    this.spinner.hide()
                }, error => {
                    this._Global.handleError(error);
                    this.spinner.hide()
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
        }
    }

    GetPatientsCampaignsRefSource(spName: string, recruiterId: number, campaignId: number) {
        try {
            this._http.get(myGlobals._apiPath + 'Campaign?type=GetLeadsPatientsCampaigns&spName=' + spName + '&recruiterId=' + recruiterId + "&campaignId=" +
                campaignId + "&campaignRefId=" + null, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._patientsCampaignRefSource = <CampaignReferralSource[]>data;
                }, error => {
                    this._Global.handleError(error);
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }

    GetPatientsCampaignsAdDetails(spName: string, recruiterId: number, campaignId: number, campaignRefId: number) {
        try {
            this._http.get(myGlobals._apiPath + 'Campaign?type=GetLeadsPatientsCampaigns&spName=' + spName + '&recruiterId=' + recruiterId + "&campaignId=" +
                campaignId + "&campaignRefId=" + campaignRefId, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._patientsCampaignAdDetails = <CampaignReferralSourceAdvertisement[]>data;
                }, error => {
                    this._Global.handleError(error);
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
        }
    }
}
