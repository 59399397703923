import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-ivr-script',
  templateUrl: './ivr-script.component.html',
  styleUrls: ['./ivr-script.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IvrScriptComponent implements OnInit {
  date = '';
  subs = new Subscription();
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right',
  };
  users: any[] = [];
  ivrMessagesForm: FormGroup;

  ivrMessages: any = [
    {
      key: "welcome_script",
      title: "Welcome Script",
      message: "",
    },
    {
      key: "user_did_not_choose",
      title: "User Did not Choose any IVR option",
      message: "",
    },
    {
      key: "extension_added",
      title: "When user enter the Extension of Agent",
      message: "",
    },
    {
      key: "wrong_ivr",
      title: "When user choose wrong IVR",
      message: "",
    },
    {
      key: "patient_not_responding",
      title: "Outgoing Call, When Patient doesn't pick up the call",
      message: "",
    },
    {
      key: "no_agents_available",
      title: "When No Agents Available to take a call",
      message: "",
    },
    {
      key: "voicemail_record_done",
      title: "After Voicemail is Recorded",
      message: "",
    },
    {
      key: "call_timeout",
      title: "Call Timeout (How long to wait till Agent pickup the call, Add in Seconds)",
      message: "",
    },
    {
      key: "ivr_input_timeout",
      title: "IVR Input Timeout (In Seconds)",
      message: "",
    },
  ];

  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<IvrScriptComponent>,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private _global: GlobalfunctionService,
    private toastService: ToastrService,
    private datePipe: DatePipe,
    private notificationsService: NotitificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.data.ivr_script && this.data.ivr_script.length > 0) {
      this.data.ivr_script.forEach(script => {
        const messageIndex = this.ivrMessages.findIndex(m => m.key === script.key);
        if (messageIndex !== -1) {
          this.ivrMessages[messageIndex] = { ...this.ivrMessages[messageIndex], ...script };
        }
      });
    }

    this.initializeForm();
  }

  initializeForm(): void {
    this.ivrMessagesForm = this.fb.group({
      messages: this.fb.array(this.ivrMessages.map(message => this.createMessageGroup(message)))
    });

    this.ivrMessagesForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.updateMessages();
      });
  }

  createMessageGroup(message: any): FormGroup {
    return this.fb.group({
      key: [message.key],
      title: [message.title],
      message: [message.message, Validators.required]
    });
  }

  updateMessages(): void {
    const messagesArray = this.ivrMessagesForm.get('messages') as FormArray;
    messagesArray.controls.forEach((msgControl: FormGroup) => {
      const formValue = msgControl.value;
      const index = this.ivrMessages.findIndex(m => m.key === formValue.key);
      if (index !== -1 && formValue.message !== this.ivrMessages[index].message) {
        this.ivrMessages[index].message = formValue.message;
      }
    });
  }
  

  onSubmit(): void {
    const new_data = { ...this.data, ivr_script: this.ivrMessages };
    console.log('this.ivrMessages', this.ivrMessages);
    this.onSubmitAction.emit(new_data);
    this.onNoClick();
  }

  onSearch(): void {
    // Implement search functionality if needed
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
