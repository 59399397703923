import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { Leads } from '@sharedmodels/BackModels/ComTrak/Leads/lead';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { CallListType } from '@sharedmodels/DMCREnums/DMCREnums';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { PatientInteractionService } from '@sharedservices/BackServices/ComTrak/Setups/patientInteraction.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { CreatepatientComponent } from '../createpatient/createpatient.component';
import { RecruitercalllistNotesComponent } from '../recruitercalllist-notes/recruitercalllist-notes.component';
import { PatientInteractionDTO } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';

@Component({
  selector: 'app-mergepatient',
  templateUrl: './mergepatient.component.html',
  styleUrls: ['./mergepatient.component.scss']
})
export class MergePatientComponent implements OnInit {
  patientData: any;
  existingPatientsData: any;
  defaultSelected: number
  selection: number
  //PatientData: Patient;
  mappingPatientId: number;

  constructor(public dialogRef: MatDialogRef<MergePatientComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public leadService: LeadService,
    public campaignService: CampaignService, public campaignRefService: CampaignReferralSourceService, public dialog: MatDialog, public _patientService: PatientService,
    public patientIntService: PatientInteractionService, public _Global: GlobalfunctionService, public spinner: NgxSpinnerService, public dialogRefAll: MatDialog,public router: Router) { }

  ngOnInit() {
    this.patientData = this.data.leadData
    this.existingPatientsData = this.data.existingData
    
  }

  onNoClick(): void {
    //debugger;
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });


  }

  getCampaignName(campaignId: number) {
    if (campaignId && campaignId > 0) {
      let Obj = this.campaignService._AllCampaign.filter(o => o.Id == campaignId)[0]
      return Obj ? Obj.Name : ""
    } else {
      return ""
    }
  }

  getCampaignRefName(campaignrefId: number) {
    if (campaignrefId && campaignrefId > 0) {
      let Obj = this.campaignRefService._CampaignReferralSourceArray.filter(o => o.Id == campaignrefId)[0]
      return Obj ? Obj.Name : ""
    }
    else {
      return ""
    }
  }

  getCampaignRefAdName(campaignrefAdId: number) {
    if (campaignrefAdId && campaignrefAdId > 0) {
      let Obj = this.campaignRefService._CampaignReferralSourceAdDetails.filter(o => o.Id == campaignrefAdId)[0]
      return Obj ? Obj.Name : ""
    }
    else {
      return ""
    }
  }

  getExistingpatientData(item: Patient) {
    this.mappingPatientId = item.Id
    console.log("this.mappingPatientId", this.mappingPatientId)
  }

  MergeLead() {
    //debugger
    if (confirm("Are you sure you want to merge this patient?") == true) {
      if (this.mappingPatientId) {
        let patient : Patient
        patient = this.patientData as Patient
        this.leadService.MergePatient(patient, this.mappingPatientId)
        this.leadService.isCallApi.subscribe(res => {
          if (res) {
            //this.onNoClick()
            this.dialogRefAll.closeAll();
          }
        })
      }
      else {
        this._Global.showErrorMsg("please select atleast one patient to merge")
      }
    }
  }

  checkDateOfBirth(DateOfBirth) {
    if (moment(DateOfBirth).format('YYYY-MM-DD') == moment(this.patientData.DateOfBirth).format('YYYY-MM-DD')) {
      return true
    }
    else {
      return false
    }
  }

  setDateIfBirthFormat() {
    return  moment(this.patientData.DateOfBirth).format('YYYY/MM/DD')
  }

  GetPatientNotes(item) {
    this.spinner.show()
    this._Global.IsReady = false
    this.patientIntService.GetLastPatientInteraction(item.Id).subscribe(res => {
      if (res) {
        forkJoin([this.patientIntService.GetPatientInteractions(item.Id),
        this.patientIntService.GetInteractions(CallListType.PatientNotes, res.PatientId, res.StatusId, res.Id)]).subscribe(res => {
          let array = [
            { PatientInteraction: <PatientInteractionDTO>res[0], patientInfo: <Patient>res[1] }
          ]
          const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
            panelClass: 'dailogwidth',
            disableClose: true,
            data: {
              PatientInteraction: array,
              hideAddNotes: false,
              rowData: item
            }
          });
          this.spinner.hide()
          this._Global.IsReady = true
        }, err => {
          this._Global.showErrorMsg(err)
          this.spinner.hide()
          this._Global.IsReady = true
        })
      }
      else {
        this.spinner.hide()
        this._Global.IsReady = true
        this._patientService.GetPatientDetailById(item.Id).subscribe(patientData => {
          this.spinner.hide()
          this._Global.IsReady = true
          let array = [
            { PatientInteraction: null, patientInfo: patientData }
          ]
          const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
            panelClass: 'dailogwidth',
            disableClose: true,
            data: {
              PatientInteraction: array,
              hideAddNotes: false,
              rowData: item
            }
          });
        }, error => {
          this._Global.showErrorMsg(error)
          this.spinner.hide()
          this._Global.IsReady = true
        })
      }
    }, error => {
      this._Global.showErrorMsg(error)
      this.spinner.hide()
      this._Global.IsReady = true
    })
  }

  CreateAnyway() {
    this.spinner.show()
    this._Global.IsReady = false
    let patient : Patient
    patient = this.patientData as Patient
    let Id = 0;
    this._patientService.SaveAndEditPatient(Id, patient, false).subscribe(res => {
      //debugger
      this.dialogRefAll.closeAll();
      this._patientService.isCallApi.next(true)
      this._Global.IsReady = true;
      this.spinner.hide()
      if (res.Message != undefined && res.Message != null && res.Message != '') {
        this._Global.showSuccessMsg(res.Message);
      }
      else {
        this._Global.showSuccessMsg('Saved successfully');
        this.router.navigateByUrl("/recruitercalllist/recruiter")
      }
    }, error => {
      this._Global.IsReady = true;
      this.spinner.hide()
      this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
      this._patientService.isCallApi.next(false)
    })
  }
}
