import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common'; 
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-publisheddetails',
  templateUrl: './publisheddetails.component.html',
  styleUrls: ['./publisheddetails.component.scss']
})
export class PublisheddetailsComponent implements OnInit {
  public myData: any = [];
  htmlContent: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<PublisheddetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe ,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.myData = this.data.rowData;  
    if (this.data.rowData.type === 'text' && this.data.rowData.script_content) {
      this.myData.script_content = this.myData.script_content.replace(/\n/g, '<br>');
    }  
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.myData.script_content) as string;
    this.updateType();
  }
  updateType() {
    if (this.myData.type === 'phone') {
      this.myData.type = 'Phone Script';
    } else if (this.myData.type === 'text') {
      this.myData.type = 'Text Script';
    } else if (this.myData.type === 'email') {
      this.myData.type = 'Email Script';
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  formatDate(dateTime: string): string {
    const localDateTime = new Date(dateTime);
    const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy | h:mm a');
    return formattedDate;
  }
  formatDate1(dateTime: string): string {
    if (!dateTime) {
      return null;
    }
    const localDateTime = new Date(dateTime);
    const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy');
    return formattedDate;
  }

  getPastTense(action: string): string {
    const actionMapping: { [key: string]: string } = {
      create: 'Created',
      discard: 'Discarded',
      decline:'Declined',
      update:'Updated',
      pin:'Pined',
      unpin:'Unpined',
      active: 'Activated',
      inactive: 'Deactivated',
      restore:'Restored'
    };
    return actionMapping[action.toLowerCase()] || action;
  }
  
  
  
}
