import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<
      ConfirmComponent,
      { response?: "leave page" | "stay on page" }
    >
  ) { }

  stay() {
    this.dialogRef.close({ response: "stay on page" });
  }

  leave() {
    this.dialogRef.close({ response: "leave page" });
  }

  ngOnInit() {
  }
}





