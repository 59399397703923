import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Leads } from '@sharedmodels/BackModels/ComTrak/Leads/lead';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';

@Component({
  selector: 'app-discardlead',
  templateUrl: './discardlead.component.html',
  styleUrls: ['./discardlead.component.scss']
})
export class DiscardleadComponent implements OnInit {
  DiscardForm: FormGroup
  submitted: boolean = false;
  leadData: Leads;
  constructor(public dialogRef: MatDialogRef<DiscardleadComponent>, public fb: FormBuilder, public leadService: LeadService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.leadData = this.data.rowData
    console.log(this.leadData)
    this.initForm()
  }

  initForm() {
    this.DiscardForm = this.fb.group({
      Notes: ['', [Validators.required, Validators.pattern(/^.*\S.*$/)]],
      LeadId: [''],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.DiscardForm.controls;
  }

  onSubmit() {
    try {
      this.submitted = true;
      if (this.DiscardForm.invalid) {
        return;
      }
      else {
        this.DiscardForm.controls.LeadId.setValue(this.leadData.LeadId)
        this.leadService.DiscardLead(this.DiscardForm.value)
        this.leadService.isCallApi.subscribe(res => {
          if (res) {
            this.onNoClick()
          }
        })
      }
    }
    catch (ex) {
      console.log("EXCEPTION", ex)
    }

  }

}
