import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';

import { Status } from '@sharedmodels/BackModels/ComTrak/Setups/Status';
import { StatusModel } from '@sharedmodels/BackModels/ComTrak/Setups/StatusModel';
import { debug } from 'console';
import { SubStatuses } from '@sharedmodels/BackModels/ComTrak/Setups/SubStatuses';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  public _status: StatusModel[];
  public _statusBytype: StatusModel[];
  public _statusByScreentypes: StatusModel[];
  public _Compalinestatus: StatusModel[];
  public show: boolean;
  public _statusByScreener: StatusModel[];
  public _statusByPrescreener: StatusModel[];
  public _alertConfigstatus: Status[];
  public _ConfigVisitStatuses: StatusModel[];
  public _configurationStatus: Status[];
  public _Configstatuses: Status[];
  _randomizedStatuses: StatusModel[];
  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService, private spinner: NgxSpinnerService) { }

  LoadStatus() {
    try {
      //debugger
      this._http.get(myGlobals._apiPath + 'Status/AllStatus', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._status = <StatusModel[]>Object.values(data).sort((st1, st2)=> st1.Ordering - st2.Ordering);
          this._ConfigVisitStatuses = this._status.filter(o => o.Id != 48 && o.Id != 51 && o.Id != 54 && o.Id != 55)
          //this._alertConfigstatus = this._status.filter(o => o.ShowCalendar == true && o.Id != 51 && o.Id != 54)          
        },
          error => {
            this._Global.handleError(error);
          })
    } catch (x) {
      this._Global.showErrorMsg(x);
    }
  }

  async LoadComplainceStatus() {
    try {
      const data = await this._http.get<StatusModel[]>(myGlobals._apiPath + 'Status/ComplainceStatus', { headers: this._Global.httpOptions }).toPromise();
      this._Compalinestatus = data;
    } catch (error) {
      this._Global.handleError(error);
    }
  }
  

  GetRandomizedStatuses() {
    try {
      this._http.get(myGlobals._apiPath + 'Status/GetRandomizedStatuses', { headers: this._Global.httpOptions })
        .subscribe(data => {
          // this._statusBytype = <Status[]>data;
          this._randomizedStatuses = <StatusModel[]>data;
        },
          error => {
            this._Global.handleError(error);
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
    }
  }

  GetStatus(): StatusModel[] {
    //debugger
    if (this._status !== undefined && this._status.length !== null) {
      return this._status;
    } else {
      this.LoadStatus();
    }
  }

  async GetComplainceStatus(): Promise<Status[]> {
    if (this._Compalinestatus !== undefined && this._Compalinestatus.length !== null) {
      return this._Compalinestatus;
    } else {
      await this.LoadComplainceStatus();
      return this._Compalinestatus;
    }
  }
  

  GetStatusByType(screenType: string, marKinteractionNextVisit?: boolean) {
    try {
      this._http.get(myGlobals._apiPath + 'Status/' + screenType, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._statusBytype = <StatusModel[]>data;
          this._statusByScreentypes = <StatusModel[]>data;
          ////debugger
          if(marKinteractionNextVisit){
            //this._randomizedStatuses = this._statusBytype.filter(o => o.Id == 44 || o.Id == 56 || o.Id == 45);
            this._randomizedStatuses = this._statusBytype.filter((statusModel) => {
              return statusModel.Roles.some((role) => role.IsScreenCompleted === true);
            });
            
            //this._randomizedStatuses = this._statusBytype.filter(o => o.Roles.filter(x => x.IsScreenCompleted === true)); //IsScreenCompleted
          }
          else{
            this._randomizedStatuses = <StatusModel[]>data;
          }
        },
          error => {
            this._Global.handleError(error);
          });
    } catch (x) {
      this._Global.showErrorMsg(x);
    }
  }

  GetStatusByPrescreener() {
    try {
      this._Global.IsReady = false
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'Status/' + "prescreener", { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._statusByPrescreener = <StatusModel[]>data;
          this._Global.IsReady = true
          this.spinner.hide()
        },
          error => {
            this._Global.IsReady = true
            this.spinner.hide()
            this._Global.handleError(error);
          });
    } catch (x) {
      this._Global.IsReady = true
      this.spinner.hide()
      this._Global.showErrorMsg(x);
    }
  }

  GetStatusByScreener() {
    try {
      this._Global.IsReady = false
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'Status/' + "screener", { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._statusByScreener = <StatusModel[]>data;
          this._Global.IsReady = true
          this.spinner.hide()
        },
          error => {
            this._Global.IsReady = true
            this.spinner.hide()
            this._Global.handleError(error);
          });
    } catch (x) {
      this._Global.IsReady = true
      this.spinner.hide()
      this._Global.showErrorMsg(x);
    }
  }
}
