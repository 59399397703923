import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-draftdetails',
  templateUrl: './draftdetailsCommunication.component.html',
  styleUrls: ['./draftdetailsCommunication.component.scss'],
})  
export class draftdetailsCommunicationComponent implements OnInit, AfterViewInit {
  myData: any;
  noData = '---';
  htmlContent: any;
  @ViewChild('contentDiv') contentDiv: ElementRef;


  constructor(
    public dialogRef: MatDialogRef<draftdetailsCommunicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
  ) {}  

  ngOnInit() {
    this.myData = this.data.rowData;
    if(this.data.rowData.type === "sms" && this.data.rowData.script_content){
      this.myData.script_content = this.myData.script_content.replace(/\n/g, '<br>');
    }
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.myData.script_content) as string;

  }
  
  ngAfterViewInit(): void {
  }  

  replaceCharacterGlobally(text: string, wordToReplace: string, replacementWord: string): string {
    if (typeof text !== 'string') {
      return ''; 
    }
    let regex = new RegExp(wordToReplace, "g");
    let replacedStr = text.replace(regex, replacementWord);
    return replacedStr;
  }

  formatDate(dateTime: string): string {
    if (!dateTime) {
      return null;
    }
    const localDateTime = new Date(dateTime);
    const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy');
    return formattedDate;
  }
  
  onNoClick(): void {
    this.dialogRef.close(); 
  } 
}
