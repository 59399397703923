import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { VaccineService } from '@sharedservices/BackServices/ComTrak/Setups/vaccine.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
selector: 'app-createmanufacturer',
templateUrl: './createmanufacturer.component.html',
styleUrls: ['./createmanufacturer.component.scss', '../../style.component.scss'],

})
export class CreateManufacturerComponent implements OnInit {
ManufacturerForm: FormGroup
submitted: boolean = false;
_Manufacturers: any[]

constructor(public dialogRef: MatDialogRef<CreateManufacturerComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
public fb: FormBuilder, private _Global: GlobalfunctionService, public vaccineService: VaccineService,
private modalService: NgbModal) {
}

ngOnInit() {
    this._Global.gotoTop();
    this.initForm()
}
get f(){
    return this.ManufacturerForm.controls
}

initForm() {
    this.ManufacturerForm = this.fb.group({
    Id: [0],
    ManufacturerName: ['', Validators.required]
    })    
}

onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    // eventCategory: 'click',
    // eventLabel: 'buttonClick',
    // eventAction: 'onNoClick',
    // });
}

onSubmit() {
    //debugger;
    this.submitted = true;
    if (this.ManufacturerForm.invalid) {
    this.checkFormValidation()
    return;
    }
    else {
    this.vaccineService.SaveManufacturer(this.ManufacturerForm.value)
    this.vaccineService.isCallApi.subscribe(res => {
        if (res) {
        this.vaccineService.LoadManufacturers()
        this.ManufacturerForm.reset()
        //this.onNoClick()
        }
    })
    }
}

checkFormValidation() {
    let errorMessage = 'Please insert Manufacturer Name'
    this._Global.showSuccessMsg(errorMessage)
    this._Global.IsReady = true;
}

}

