import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';

import { StudyVisit } from '@sharedmodels/BackModels/ComTrak/Setups/StudyVisit';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyvisitService {
  public isCallApi = new Subject<boolean>();
  public isCallDeleteApi = new Subject<boolean>();
  public isCallApiSave = new Subject<boolean>();
  public _studyvisits: StudyVisit[];
  public _studyvisitsArray: StudyVisit[];
  public arraylength: number = 0
  public isCallRefSource = new Subject<boolean>();
  _studyvisitssequence: StudyVisit[];
  public _studyvisitsAnchor: StudyVisit[];
  public _studyvisitssequenceBYStudyId: StudyVisit[];
  public dataArray: StudyVisit[];
  public studyVisitsParentAnchorByStudy: any;

  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService,
    private _GA: GoogleAnalyticsService) { }

  LoadStudyVisits() {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this.isCallApi.next(false)
      this._http.get(myGlobals._apiPath + 'StudyVisit', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._studyvisits = <StudyVisit[]>data;
          // this._studyvisitsArray = <StudyVisit[]>data;
          // this.arraylength = this._studyvisits.length
          this._Global.IsReady = true;
          this.spinner.hide()
          this.isCallApi.next(true)
        },
          error => {
            this._Global.IsReady = true;
            this.spinner.hide()
            this._Global.handleError(error);
            this.isCallApi.next(false)
          });
    } catch (x) {
      this._Global.IsReady = true;
      this.spinner.hide()
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.isCallApi.next(false)
    }
  }

  async LoadStudyVisitsForSequence() {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this.isCallApi.next(false);
  
      const data = await this._http.get<StudyVisit[]>(myGlobals._apiPath + 'StudyVisit?type=StudyVisitsForSequence', { headers: this._Global.httpOptions }).toPromise();
      
      this._studyvisitssequence = data;
      this._studyvisitsArray = data;
      this.arraylength = this._studyvisitssequence.length;
      this._Global.IsReady = true;
      
      this.spinner.hide();
      this.isCallApi.next(true);
    } catch (error) {
      this._Global.IsReady = true;
      this.spinner.hide();
      this._Global.handleError(error);
      this.isCallApi.next(false);
    }
  }

  GetStudyVisitById(studyVisitId: number, studyVisitSitesDetailId: number): Observable<StudyVisit> {
    return this._http.get(myGlobals._apiPath + 'StudyVisit?type=GetStudyVisitsById' + '&studyVisitId=' + studyVisitId + '&studyVisitSitesDetailId=' + studyVisitSitesDetailId, { headers: this._Global.httpOptions })
  }

  GetStudyVisits(): StudyVisit[] {
    if (this._studyvisits !== undefined && this._studyvisits.length !== null) {
      return this._studyvisits;
    } else {
      this.LoadStudyVisits();
    }
  }

  SaveStudyVisit(body: StudyVisit) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.post(myGlobals._apiPath + 'StudyVisit/Save', body, { headers: this._Global.httpOptions })
        .subscribe((data: StudyVisit) => {
          this.LoadStudyVisitsForSequenceByStudyId(data.StudyId)
          this.GetStudyVisitsParentAnchorByStudyId(data.StudyId)
          this._Global.showSuccessMsg("Study Visit Saved Successfully")
          this.spinner.hide()
          this._Global.IsReady = true;

        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;

          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()

    }
  }

  EditStudyVisit(siteID: number, previousvisitId: number, nextvisitId: number, body: StudyVisit) {
    try {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'StudyVisit/Edit?id=' + siteID + '&previousvisitId=' + previousvisitId + '&nextvisitId=' + nextvisitId, body, { headers: this._Global.httpOptions })
        .subscribe((data: StudyVisit) => {

          this.spinner.hide()
          this._Global.IsReady = true;
          this.LoadStudyVisitsForSequenceByStudyId(data.StudyId)
          this.GetStudyVisitsParentAnchorByStudyId(data.StudyId)
          if (previousvisitId == 0 && nextvisitId == 0) {
            this._Global.showSuccessMsg("Study Visit Updated Successfully")

          }
          else {
            this._Global.showSuccessMsg("Sequence Updated Successfully")
            this.LoadStudyVisitsForSequence()
          }

        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;

          });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()

    }
  }

  SaveAndEditStudyVisit(siteId: number, body: StudyVisit, isEdit: boolean) {
    if (isEdit) {
      this.EditStudyVisit(siteId, 0, 0, body)
    }
    else {
      this.SaveStudyVisit(body)
    }
  }

  EditStudyVisitAnchor(body: StudyVisit[]) {
    try {
      // if (confirm("Are you sure. You want to start anchor against this visit ?") == true) {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'StudyVisit/Edit/Acnhor', body, { headers: this._Global.httpOptions })
        .subscribe((data: StudyVisit) => {
          // this.isCallApi.next(true)
          this.spinner.hide()
          this.LoadStudyVisitsForSequenceByStudyId(data[0].StudyId)
          this.GetStudyVisitsParentAnchorByStudyId(data[0].StudyId)
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            // this.isCallApi.next(false)
          });
      // }
      // else {
      //   return;
      // }

    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  EditSingleAnchor(anchorNumber: number, body: StudyVisit) {
    try {
      // if (confirm("Are you sure. You want to update its anchor against this visit ?") == true) {
      this.spinner.show()
      this._Global.IsReady = false;
      this._http.put(myGlobals._apiPath + 'StudyVisit/Edit?id=' + 0 + '&previousvisitId=' + 0 + '&nextvisitId=' + 0 + '&anchorNumber=' + anchorNumber, body, { headers: this._Global.httpOptions })
        .subscribe((data: StudyVisit) => {
          this.isCallApi.next(true)
          this.spinner.hide()
          this._Global.showSuccessMsg("Updated Successfully")
          this.LoadStudyVisitsForSequenceByStudyId(data.StudyId)
          this._Global.IsReady = true;
        },
          error => {
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
            this.isCallApi.next(false)
          });
      // }
      // else {
      //   return 0;
      // }
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
      this.isCallApi.next(false)
    }
  }

  GetStudyVisitsParentAnchor() {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'StudyVisit?type=GetStudyVisitsParentAnchor', { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._studyvisitsAnchor = <StudyVisit[]>data;
          this._Global.IsReady = true;
          this.spinner.hide()
        },
          error => {
            this._Global.IsReady = true;
            this.spinner.hide()
            this._Global.handleError(error);
          });
    } catch (x) {
      this._Global.IsReady = true;
      this.spinner.hide()
      this._Global.showErrorMsg(x);
    }
  }

  LoadStudyVisitsForSequenceByStudyId(studyId: number) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'StudyVisit?type=GetStudyVisitsForSequenceBYStudyId' + '&studyId=' + studyId, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this._studyvisitssequenceBYStudyId = <StudyVisit[]>data;
          this.isCallApiSave.next(true)
          this._Global.IsReady = true;
          this.spinner.hide()
        },
          error => {
            this._Global.IsReady = true;
            this.spinner.hide()
            this._Global.handleError(error);
            this.isCallApiSave.next(false)
          });
    } catch (x) {
      this._Global.IsReady = true;
      this.spinner.hide()
      this._Global.showErrorMsg(x);
      this.isCallApiSave.next(false)
    }
  }

  GetStudyVisitsParentAnchorByStudyId(studyId: number) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'StudyVisit?type=GetStudyVisitsParentAnchorByStudyId' + '&studyId=' + studyId, { headers: this._Global.httpOptions })
        .subscribe(data => {
          this.dataArray = <StudyVisit[]>data
          this.studyVisitsParentAnchorByStudy = data;
          this._Global.IsReady = true;
          this.spinner.hide()
        },
          error => {
            this._Global.IsReady = true;
            this.spinner.hide()
            this._Global.handleError(error);
          });
    } catch (x) {
      this._Global.IsReady = true;
      this.spinner.hide()
      this._Global.showErrorMsg(x);
    }
  }

  RemoveStudyVisitAnchor(body: StudyVisit[]) {
    // if (confirm("Are you sure. You want to remove anchor against this visit ?") == true) {
    this.spinner.show()
    this._Global.IsReady = false;
    this._http.put(myGlobals._apiPath + 'StudyVisit/Edit/Acnhor', body, { headers: this._Global.httpOptions })
      .subscribe((data: StudyVisit) => {
        this.spinner.hide()
        if (data && data[0] && data[0].StudyId) {
          this.LoadStudyVisitsForSequenceByStudyId(data[0].StudyId)
          this.GetStudyVisitsParentAnchorByStudyId(data[0].StudyId)
          this._Global.showSuccessMsg("Anchor Removed Successfully")
        }
       
        this._Global.IsReady = true;
      },
        error => {
          this._Global.handleError(error);
          this.spinner.hide()
          this._Global.IsReady = true;
        });
    // }
    // else {
    //   return;
    // }
  }

  Delete(studyVisitId: number, studyId: number, siteId: number) {
    if (confirm("Are you sure You want to delete this record?") == true) {
      this.spinner.show()
      this.isCallDeleteApi.next(false)
      this._Global.IsReady = false;
      this._http.delete(myGlobals._apiPath + 'StudyVisit?studyVisitId=' + studyVisitId + '&studyId=' + studyId + '&siteId=' + siteId, { headers: this._Global.httpOptions })
        .subscribe((data: StudyVisit) => {
          if (data && data.StudyId){
          this.LoadStudyVisitsForSequenceByStudyId(data.StudyId)
          this.GetStudyVisitsParentAnchorByStudyId(data.StudyId)
          }
          this._Global.showSuccessMsg("Deleted Successfully")
          this.spinner.hide()
          
          this.isCallDeleteApi.next(true)
          this._Global.IsReady = true;
        },
          error => {
            
            this.isCallDeleteApi.next(false)
            this._Global.handleError(error);
            this.spinner.hide()
            this._Global.IsReady = true;
          });
          //this.isCallDeleteApi.next(true)
    }
    else {
      
      return;
    }
   // this.isCallDeleteApi.next(false)
  }

  GetStudyVisitsForSequenceByStudyId(studyId: number): Observable<any>  {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      return this._http.get(myGlobals._apiPath + 'StudyVisit?type=GetStudyVisitsForSequenceBYStudyId' + '&studyId=' + studyId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

}

