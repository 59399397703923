import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Pool } from "@sharedmodels/BackModels/ComTrak/Pools/pool";
import { LeadService } from "@sharedservices/BackServices/ComTrak/Leads/lead.service";

@Component({
  selector: "app-verifypool",
  templateUrl: "./verifypool.component.html",
  styleUrls: ["./verifypool.component.scss"],
})
export class VerifypoolComponent implements OnInit {
  submitted: boolean = false;
  isActive: Boolean;
  poolData: Pool;

  constructor(
    public dialogRef: MatDialogRef<VerifypoolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.poolData = this.data.rowData;
    this.isActive = this.data.isActive;
  }

  onClickYes() {
    console.log("clicked yes");
    this.dialogRef.close("yes");
  }

  onClickNo() {
    this.dialogRef.close("no");
  }
}
