import { StudyVisit } from './../../../../Models/BackModels/ComTrak/Setups/StudyVisit';
import { CalendarEvent } from './../../../../Models/BackModels/ComTrak/Setups/CalendarEvent';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { Observable, Subject } from 'rxjs';
import { DTOTimeZoneDate, PatientInteraction } from './../../../../Models/BackModels/ComTrak/Interactions/PatientInteraction';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '@shared/global_var';
import * as moment from 'moment';
import { StatusService } from './status.service';
import { Status } from '@sharedmodels/BackModels/ComTrak/Setups/Status';
import { StatusModel } from '@sharedmodels/BackModels/ComTrak/Setups/StatusModel';

@Injectable({
  providedIn: 'root'
})
export class PatientInteractionService {
  public isCallApiScreen = new Subject<boolean>()
  public isCallApiPreScreen = new Subject<boolean>()
  public isCallApiRecruiter = new Subject<boolean>()
  public isSendSMS = new Subject<boolean>()
  public nextVisitsArray: StudyVisit[] = [];
  public _getCurrentorAfterTime: DTOTimeZoneDate;
  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService, public statusService: StatusService) { }

  GetInteractions(type: string, patientId: number, statusId: number, patientInteractionId: number): Observable<Patient | PatientInteraction> {
    try {
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=' + type + "&patientId=" + patientId + "&statusId="
        + statusId + "&patientInteractionId=" + patientInteractionId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetStudyVisitStatuses(patientInteractionId: number, statusId: number, studyVisitId: number) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetStudyVisitStatuses&patientInteractionId=' + patientInteractionId + '&statusId=' + statusId + '&studyVisitId=' + studyVisitId, { headers: this._Global.httpOptions }).subscribe(res => {
        this.statusService._Compalinestatus = <StatusModel[]>res
        this._Global.IsReady = true;
        this.spinner.hide()
      }, error => {
        this._Global.handleError(error);
        this._Global.IsReady = true;
        this.spinner.hide()
      })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetCurrentorAfterTime() {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetCurrentorAfterTime', { headers: this._Global.httpOptions }).subscribe(res => {
        this._getCurrentorAfterTime = <DTOTimeZoneDate>res
        this._Global.IsReady = true;
        this.spinner.hide()
      }, error => {
        this._Global.handleError(error);
        this._Global.IsReady = true;
        this.spinner.hide()
      })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetPatientInteractions(patientId: number): Observable<PatientInteraction> {
    try {
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetPatientInteractions&patientId=' + patientId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  SavePatientNotes(patientId: number, notes: string): Observable<PatientInteraction> {
    try {
      this._Global.IsReady = false;
      var data: any = new FormData();
      data.append("json", notes);
      return this._http.post(myGlobals._apiPath + 'PatientInteraction/AddPatientNotes/' + patientId, data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  AddPatientIntercationChangeStatus(patientId: string, prevStatusId: string, StatusId: string): Observable<PatientInteraction> {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      return this._http.post(myGlobals._apiPath + 'PatientInteraction/AddPatientIntercationChangeStatus/' + patientId + "/" + prevStatusId + "/" + StatusId, null, { headers: this._Global.httpOptions })

    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  SavePatientInteractionWithStatuses(patientInteractionId: number, StatusId: number, body: any): Observable<any> {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      return this._http.post(myGlobals._apiPath + 'PatientInteraction?getType=SaveVisitedCompletedPatientInteraction&patientInteractionId=' + patientInteractionId + '&StatusId=' + StatusId, body, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  GetLastPatientInteraction(patientId: number): Observable<any> {
    try {
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetLastPatientInteraction&patientId=' + patientId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
    }
  }

  LoadAppointmentData(data: any): Observable<any> {
    try {
      this._Global.IsReady = false;
      return this._http.post(myGlobals._apiPath + 'PatientInteraction/LoadAppointmentData', data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  SaveCalendarEvent(data: CalendarEvent, getType: string): Observable<CalendarEvent> {
    try {
      this._Global.IsReady = false;
      return this._http.post(myGlobals._apiPath + 'PatientInteraction?getType=' + getType, data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  SaveMarkInteraction(data: any, screenerType: string, isEdit: boolean): Observable<CalendarEvent> {
    try {
      return this._http.post(myGlobals._apiPath + 'PatientInteraction?getType=AddNewMarkInteraction&screenerType=' + screenerType + '&isEdit=' + isEdit, data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  MarkInteractionScheduleNextvisit(data: CalendarEvent): Observable<CalendarEvent> {
    try {
      this._Global.IsReady = false;
      return this._http.post(myGlobals._apiPath + 'PatientInteraction?GetType=AddPatientIntercationEventScreenerCallList', data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  ScreenerEdit(data: any): Observable<any> {
    try {
      this._Global.IsReady = false;
      return this._http.put(myGlobals._apiPath + 'PatientInteraction/ScreenerEdit', data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  SkipVisit(data: any): Observable<any> {
    try {
      this._Global.IsReady = false;
      return this._http.post(myGlobals._apiPath + 'PatientInteraction?getType=SkipVisit', data, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetUnscheduledVisits(studyId: number): Observable<any> {
    try {
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetUnscheduledVisits&studyId=' + studyId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetScreenerEdit(patientInteractionId: number): Observable<any> {
    try {
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetScreenerEdit&patientInteractionId=' + patientInteractionId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetVisitProjectionByPatientVisit(patientId: number, studyVisitId: number): Observable<any> {
    try {
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetVisitProjectionByPatientVisit&patientId=' + patientId + "&studyVisitId=" + studyVisitId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetNextVisits(type: string, patientId: number, studyId: number) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.get(myGlobals._apiPath + "PatientInteraction?GetType=" + type + "&patientId=" + patientId + "&studyId=" + studyId,
        { headers: this._Global.httpOptions }).subscribe((res: any) => {
          this.nextVisitsArray = <StudyVisit[]>res.Result
          this._Global.IsReady = true;
          this.spinner.hide()
        }, error => {
          this._Global.handleError(error)
          this._Global.IsReady = true;
          this.spinner.hide()
        })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  SendSMS(body: any) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.post(myGlobals._apiPath + 'PatientInteraction/SendSMS?getType=SendSMS', body, { headers: this._Global.httpOptions }).subscribe(res => {
        this._Global.showSuccess("SMS Send Successfully")
        this._Global.IsReady = true;
        this.spinner.hide()
        this.isSendSMS.next(true)
      }, error => {
        this.isSendSMS.next(false)
        this._Global.showErrorMsg(error);
        this._Global.IsReady = true;
        this.spinner.hide()
      })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }

  SendEmail(body: any) {
    try {
      this._Global.IsReady = false;
      this.spinner.show()
      this._http.post(myGlobals._apiPath + 'PatientInteraction/SendSMS?getType=SendEmail', body, { headers: this._Global.httpOptions }).subscribe(res => {
        this._Global.showSuccess("Email Send Successfully")
        this._Global.IsReady = true;
        this.spinner.hide()
        this.isSendSMS.next(true)
      }, error => {
        this.isSendSMS.next(false)
        this._Global.showErrorMsg(error);
        this._Global.IsReady = true;
        this.spinner.hide()
      })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }
  GetPatientInteractionsWithDetails(patientId: number): Observable<PatientInteraction> {
    try {
      this._Global.IsReady = false;
      return this._http.get(myGlobals._apiPath + 'PatientInteraction?GetType=GetPatientInteractionsDetailsByPatientId&patientId=' + patientId, { headers: this._Global.httpOptions })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
    }
  }

  GetInteractionCounter(patientId: number, studyId: number, statusId: number, subStatusId?: number): Promise<any> {
      this._Global.IsReady = false;
      return new Promise((resolve, reject) => {
        this._http.get(myGlobals._apiPath + 'PatientInteraction/GetInteractionsCounter?' + "patientId=" + patientId + "&studyId="
        + studyId + "&statusId=" + statusId+ "&subStatusId=" + subStatusId, { headers: this._Global.httpOptions }).subscribe(
          {
            next:data => {
                 resolve(data);
            },
            error: (e) => {
              this._Global.showErrorMsg(e);
              this._Global.IsReady = true;
              reject(e);
            }
          }
        )
      });
  }

  GetCompleteVisit(patientId: number, statusId: number, subStatusId: number, studyVisitId: number): Promise<any> {
    this._Global.IsReady = false;
    return new Promise((resolve, reject) => {
      this._http.get(myGlobals._apiPath + 'PatientInteraction/GetCompleteVisit?' + "patientId=" + patientId + "&statusId=" + statusId+ "&subStatusId=" + subStatusId + "&studyVisitId=" + studyVisitId, { headers: this._Global.httpOptions }).subscribe(
        {
          next:data => {
               resolve(data);
          },
          error: (e) => {
            this._Global.showErrorMsg(e);
            this._Global.IsReady = true;
            reject(e);
          }
        }
      )
    });
}
}
