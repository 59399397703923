import { Component, OnInit, NgModule, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { GlobalfunctionService } from 'Shared/Services/FrontServices/globalfunction.service';
import { environment } from '../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { SwUpdateServiceService } from '@sharedservices/FrontServices/Integrations/sw-update-service.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Subject } from 'rxjs';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';

// declare ga as a function to set and sent the events
declare let ga: Function;
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
// @NgModule({
//   imports: [

//     BrowserAnimationsModule
//   ],

// })
@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],


})

export class AppComponent implements OnInit, OnDestroy {
  location: Location;
  isBackDrop = false;
  constructor(public router: Router,
    public swUpdateService: SwUpdateServiceService, // public _NotificationService: NotificationService,
    public _globalService: GlobalfunctionService, private spinner: NgxSpinnerService, private scriptService: ScriptService, private cd: ChangeDetectorRef) {
    try {
      this.swUpdateService.checkForUpdates();
      // subscribe to router events and send page views to Google Analytics
      this.router.events.subscribe(event => {

        if (event instanceof NavigationEnd) {
          {
            //ga('set', 'page', event.urlAfterRedirects);
            //ga('send', 'pageview');
          }
        }
      });
    } catch (Error) { }
  }

  ngOnInit() {
    if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission();
    }

    this.scriptService.openScriptDialog.subscribe(res => {
      this.isBackDrop = res.openFlag;
      this.cd.detectChanges();
    });

    try {
      const body = document.getElementsByTagName('body')[0];
      this.router.events.subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            if (event.url == '/visitorflowlist') {
              setTimeout(function () {
                body.classList.add('sidebar-mini');
                misc.sidebar_mini_active = true;
              }, 300);
            }
            else {
              body.classList.remove('sidebar-mini');
              misc.sidebar_mini_active = false;
            }
          }
        });

      // For http to https redirect
      if (environment.production) {
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
      }


      const obj = JSON.stringify(JSON.parse(localStorage.getItem('data')));

      if (localStorage.getItem('data') !== undefined && localStorage.getItem('data') !== null
        && localStorage.getItem('data') !== '') {
        this._globalService.setData(obj);


        if (this._globalService.httpOptions === undefined || this._globalService.httpOptions === null) {
          this.router.navigate(['./login']);
        } else {
          // this._LoadingSetupsServiceService.LoadData_User();
        }
      }


    } catch (error) {
      // alert(error)
    }

    {
      // this.spinner.show();
    }
    window.addEventListener('beforeunload', this.beforeUnloadHandler);

  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }

  beforeUnloadHandler(event: BeforeUnloadEvent) {
    const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
    event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage;             // Gecko, WebKit, Chrome <34
  }
}
