import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpParams, HttpParameterCodec, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class EncodeHttpParamsInterceptor implements HttpInterceptor {

  private sessionExpiredHandled = false;
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };

  constructor(private router: Router, private toastService: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = new HttpParams({encoder: new CustomEncoder(), fromString: req.params.toString()});
    return next.handle(req.clone({params})).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          if (!this.sessionExpiredHandled) {
            this.router.navigate(['/login']);
            this.toastService.error('', 'Your session has expired. Please login again.', this.toastOptions);
            this.sessionExpiredHandled = true;
          }
          return new Observable<HttpEvent<any>>();
        }
        return throwError(error);
      })
    );
  }
}


class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}