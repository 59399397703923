import { Campaign } from './../../../Shared/Models/BackModels/ComTrak/Setups/Campaign';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';

@Component({
  selector: 'app-createcampaign',
  templateUrl: './createcampaign.component.html',
  styleUrls: ['./createcampaign.component.scss', '../../style.component.scss']
})
export class CreatecampaignComponent implements OnInit {
  CamapignForm: FormGroup
  submitted: boolean = false;
  public _data: Campaign;
  isedit: boolean = false;
  actionType: string;
  disbaledFields: boolean;
  campaignType: Lov[];
  constructor(public dialogRef: MatDialogRef<CreatecampaignComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, public campaignService: CampaignService, public lovService: LovService, public _Global: GlobalfunctionService) { }

  ngOnInit() {
    this.getLovData()
    this.lovService.GetLov(LovStatusMasterID.EmployeeCategory)
    this.actionType = this.data.actionType
    this.initForm()
    if (this.data.callList != undefined) {
      this.CamapignForm.patchValue(this.data.callList);
      this.isedit = true
    }
    else {
      this.isedit = false
    }
  }

  initForm() {
    this.CamapignForm = this.fb.group({
      Name: ['', Validators.required],
      CampignTypeId: ['', Validators.required],
      IsImportCampaign: [false],
      ReferralName: [''],
      DocumentId: [null],
      IsScheduledImport: [0],
      LastRowread: [0],
      IsActive: [false],
      LovDetailId: ['', Validators.required],
      Id: [''],
    })
  }

  getLovData() {
    this.lovService.getLovDataById(LovStatusMasterID.CampaignType).subscribe(res => {
      this.campaignType = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.CamapignForm.controls;
  }

  // ClickIsActive() {
  //   if (this.data.callList.IsActive == true) {
  //     this.CamapignForm.controls.IsActive.setValue(false)
  //   }
  //   else {
  //     this.CamapignForm.controls.IsActive.setValue(true)
  //   }
  //   if (this.data && this.data.callList) {
  //     this.spinner.show()
  //     this._Global.IsReady = false
  //     this.campaignService.isCallApi.next(false)
  //     this.campaignService.CampaignActiveInActive(this.CamapignForm.value).subscribe((res: any) => {
  //       this.onNoClick()
  //       this._Global.showSuccessMsg("Campaign & Its Referral Source InActive Successfully.")
  //       this.campaignService.isCallApi.next(true)
  //       this._Global.IsReady = true
  //       this.spinner.hide()
  //     }, (err => {
  //       this._Global.showErrorMsg(err)
  //       this.spinner.hide()
  //       this.campaignService.isCallApi.next(false)
  //       this._Global.IsReady = true
  //     }))
  //   }
  //   else {
  // return false;
  //   }
  // }

  onSubmit() {
    this.submitted = true;
    if (this.CamapignForm.invalid) {
      return;
    }
    else {
      let Id = this.data.callList ? this.data.callList.Id : 0
      this.CamapignForm.controls.Id.setValue(Id)
      this.campaignService.SaveAndEditCampaigns(this.CamapignForm.value, this.isedit)
      this.campaignService.isCallApi.subscribe(res => {
        if (res) {
          this.onNoClick()
          this.campaignService.isCallApi.next(true)
        }
      })
    }
  }
}
