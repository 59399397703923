import { ExcelService } from '@sharedservices/FrontServices/Export/excel.service';
import { PatientInteraction } from './../../../Shared/Models/BackModels/ComTrak/Interactions/PatientInteraction';
import { ActionType, CallListType } from './../../../Shared/Models/DMCREnums/DMCREnums';
import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { ScreenerService } from './../../../Shared/Services/BackServices/ComTrak/Screeners/screener.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { StudyvisitService } from '@sharedservices/BackServices/ComTrak/Setups/studyvisit.service';
import { RecruitercalllistMarkComponent } from '@app/pages/recruitercalllist-mark/recruitercalllist-mark.component';
import { RecruitercalllistNotesComponent } from '@app/pages/recruitercalllist-notes/recruitercalllist-notes.component';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { MatDialog, MatDialogRef, MatPaginator } from '@angular/material';
import { Subscription, forkJoin } from 'rxjs';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { ColDef } from 'ag-grid-community';
import { StudyVisit } from '@sharedmodels/BackModels/ComTrak/Setups/StudyVisit';
import { CreatepatientComponent } from '@app/pages/createpatient/createpatient.component';
import { CommandClickEventArgs, CommandModel, FilterService, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { CustomGridColumns } from '@app/components/datatable/datatable.component';
import { fadeInOut, bottomtotop } from '@app/animation';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { CreatevaccineComponent } from '@app/pages/createvaccine/createvaccine.component';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { PatientInteractionDTO } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { PatientDetailComponent } from '../patientdetail/patientdetail.component';
import { DatePipe } from '@angular/common';
import { DaterangepickerComponent } from '../../components/daterangepicker/daterangepicker.component';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-screenercalllist',
  templateUrl: './screenercalllist.component.html',
  styleUrls: ['./screenercalllist.component.scss', '../../style.component.scss',
    '../../../assets/comtrak-ui-system-v-0.1/sass/screens/_recruitersList.scss'],
  animations: [
    fadeInOut, bottomtotop
  ],
  providers: [FilterService]
})
export class ScreenercalllistComponent implements OnInit, OnDestroy {
  @ViewChild(DaterangepickerComponent) dateRangPicker: DaterangepickerComponent;
  format: string = 'M/d/yyyy';
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('StartDate') StartDate: any;
  @ViewChild('EndDate') EndDate: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('markInteraction') markInteraction: TemplateRef<any>;
  @ViewChild('editPatient') editPatient: TemplateRef<any>;
  @ViewChild('note') note: TemplateRef<any>;
  // @ViewChild('deleteInteraction') deleteInteraction: TemplateRef<any>;
  srearchForm: FormGroup
  page = 1;
  statusId: any = '-1'
  todayDate: Date = new Date()
  pageSize = 20
  collectionSize: number = 0
  filter = new FormControl('');
  startDate: string
  public visitsofStudy: StudyVisit[];
  endDate: string
  pagenumber: any;
  type = this.fb.group({
    statusId: ['-1']
  });
  columnDefs: ColDef[]
  public defaultColDef;
  public gridApi: any;
  public gridColumnApi: any;
  deleteInt: boolean = false
  columnDefinition: CustomGridColumns[];
  DefaultView: CustomGridColumns[];
  showRecord: boolean = false;
  public commands: any[];
  showPatientDetailDialog = false;
  public patientDetail: RowSelectEventArgs;
  dialogRefs: MatDialogRef<any>[] = [];
  isMoreData:boolean = true;
  studyList: Study[];
  subscriptions = new Subscription();
  dialogOpenFlag = false;

  constructor(private fb: FormBuilder, public screenerService: ScreenerService, public studyService: StudyService, private excelService: ExcelService,
    public statusService: StatusService, public siteService: SiteService, public studyvisitService: StudyvisitService, private scriptService: ScriptService,
    public spinner: NgxSpinnerService, public _RecruiterService: RecruiterService, public _PatientInteractionService: PatientInteractionService,
    public dialog: MatDialog, public _PatientService: PatientService, public _Global: GlobalfunctionService, public _lovService: LovService, private socketService: SocketHandlerService,
    public PrescreenList: PrescreenService, public locationService: LocationService, public campaignRefSourceService: CampaignReferralSourceService, private datePipe: DatePipe) {
    this.commands = [
      { title: 'sub-menu', type: 'None', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-contact-center' } },
      { title: 'Add Mark Interaction', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-plus' } },
      { title: 'Edit Mark Interaction.', type: 'Delete', buttonOption: { content: '', cssClass: "trash", iconCss: "e-icons e-hyperlink-edit" } },
      { title: 'Add Notes', type: 'Delete', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-file' } },
      { title: 'Edit Patient', type: 'None', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-edit e-icons' } },
      { title: 'Add Vaccine Detail', type: 'Edit', buttonOption: { content: '', cssClass: 'custom_bold', iconCss: 'e-icons e-calculated-member' } },
      { title: 'Internal Chat', type: 'Chat', buttonOption: { content: '', cssClass: 'custom-bold', iconCss: 'e-icons e-message' } },
    ];
  }

  async ngOnInit() {
    const openScriptDialogSub = this.scriptService.openScriptDialog.subscribe(res => {
      this.dialogOpenFlag = res.openFlag;
      res.openFlag ? this.openModal() : this.closeModal();

      setTimeout(() => {
        this.screenerService._callListArray = [...this.screenerService._callListArray];
      }, 0);
    });
    this.subscriptions.add(openScriptDialogSub);

    this.gridConfig()
    this.late();
    this.screenerService._callList = [];
    this.screenerService.arraylength = 0
    this.screenerService._callListArray = undefined
    this.screenerService._callList = undefined
    this.initForm();
    await this.getActiveStudies()
    // this.studyService.GetActiveStudies();

    //handling studylist, to be replaced with proper obserables
    this.subscriptions.add(
      this.siteService.sitesStudy.subscribe(
        (studies: Study[]) => this.studyList = studies
      )
    );

    this.siteService.GetActiveSites();
    this.siteService._sitesStudy = this.studyService._activeStudies;

    this.statusService.GetStatusByType(CallListType.Screener);
    this.studyvisitService.GetStudyVisits();
    this._lovService.GetSMSMaster()
    this.locationService.getAllState()
    this.statusService.GetStatus()
    this.locationService.getAllCountry();
    this.campaignRefSourceService.GetAllCampaignReferralSourceAdDetails()
    this.campaignRefSourceService.LoadAllCampaignReferralSource();
    this.campaignRefSourceService.LoadCampaignReferralSource();
    // this._PatientInteractionService.isCallApiScreen.subscribe(res => {
    //   if (res) {
    //     this.onSearch(0, 0)
    //   }
    // })
    // this.screenerService.isCallApi.subscribe(res => {
    //   if (res) {
    //     this.collectionSize = this.screenerService.arraylength
    //     this.refreshData(this.page)
    //   }
    // })
  }

  openModal(): void {
    // Open the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.add('show');
      modal.setAttribute('aria-modal', 'true');
      modal.style.display = 'block';
    }
  }

  closeModal(): void {
    // Close the Bootstrap modal
    const modal = document.getElementById('quickScriptModal');
    if (modal) {
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      modal.style.display = 'none';
    }
  }

  gridConfig() {
    this.columnDefinition = [
      {
        header: 'Cmtrk Id',
        key: "Id",
        width: 200,
        visible: true,
        type: "string",
      },
      {
        header: 'Crio ID',
        key: "CrioId",
        width: 200,
        visible: true,
        type: "string",
      },
      {
        header: 'Sub Number',
        key: "SubjectNumber",
        width: 200,
        visible: true,
        type: "string",
      },
      {
        header: 'Patient Name',
        key: "FullName",
        width: 200,
        visible: true,
        type: "string",
      },
      // {
      //   header: 'State',
      //   key: "State",
      //   width: 150,
      //   visible: true,
      //   type: "string",
      // },
      // {
      //   header: 'Site',
      //   key: "Site",
      //   width: 150,
      //   visible: true,
      //   type: "string",
      // },
      {
        header: 'Study',
        key: "Study",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'Status',
        key: "Status",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'SubStatus',
        key: "SubStatus",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'Confirmation Status',
        key: "ConfirmationStatus",

        visible: true,
        type: "string",
      },
      {
        header: 'Next Visit Date',
        key: "fScheduledDateTime",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'Study Visit',
        key: "StudyVisitName",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'Visit Status',
        key: "SubStatus",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'Next Visit Time',
        key: "fScheduledTime",
        width: 250,
        visible: true,
        type: "string",
      },
      {
        header: 'OOW',
        key: "IsOutOfWindow",
        width: 140,
        visible: true,
        type: "string",
      },
      // {
      //   header: 'Win Start date',
      //   key: "fWindowStartDate",
      //   width: 200,
      //   visible: true,
      //   type: "string",
      // },
      // {
      //   header: 'Win End date',
      //   key: "fWindowEndDate",
      //   width: 200,
      //   visible: true,
      //   type: "string",
      // },
    ]
    this.DefaultView = this.columnDefinition;
  }

  initForm() {
    this.srearchForm = this.fb.group({
      study: ['-1', null],
      site: ['-1', null],
      status: ['-1', null],
      studyvisit: ['-1', null],
      startDate: [null, null],
      endDate: [null, null],
      search: ['', null],
      responseId: ['-1', null]
    })
  }
  OnReset() {
    this.initForm();
    this.siteService.sitesStudy.next([]);
    // this.StartDate.dateString = "";
    // this.EndDate.dateString = "";

    this.dateRangPicker.clearDateRange();
    this.dateRangPicker.dateRangeEnabled=true;
  }
  getMatch(a: Study[], b: Study[]): Study[] {
    try {
      let matches: Study[] = [];
      a.forEach(elem => {
        if (b.findIndex(x => x.Id === elem.Id) >= 0) {
          matches.push(elem);
        }
      });

      return matches;
    } catch (e) {

    }
  }
  clickSearch() {
    if (this.srearchForm.controls.site.value == '-1') {
      this._Global.showErrorMsg("Please Select Site")
    }
    else if (this.dateRangPicker.dateRangeEnabled && !this.dateRangPicker.formObject.validate()) {
      return;
    }
    else {
      this.onSearch(0, 0);
    }

  }

  filterStudies(): Study[] {
    try {

      if (this.siteService._sitesStudy.length > 0) {
        const studiesonsite = this.getMatch(this.studyService._activeStudies, this.siteService._sitesStudy);
        return studiesonsite;
      }
    } catch (Err) {

      return this.studyService._activeStudies;
    }

  }

  // DeleteInteraction(row: any) {
  //   if (confirm("Are you sure You want to remove this interaction?") == true) {
  //     this._Global.IsReady = false
  //     this.spinner.show()
  //     this.screenerService.DeleteInteraction(row.PatientInteractionId).subscribe(res => {
  //       this.clickSearch()
  //       this._Global.showSuccess("Interaction removed successfully.")
  //     }, error => {
  //       this._Global.showAuthenticationMsg(error)
  //       this._Global.IsReady = true
  //       this.spinner.hide()
  //     })
  //   }
  //   else {
  //     return
  //   }
  // }

  GetScreenerCallList() {
    this.screenerService.GetScreenerCallList(false, this._PatientService.studyid, this._PatientService.siteid, this._PatientService.statusid,
      this._PatientService.studyvisit, this._PatientService.startDate, this._PatientService.endDate, 0, 0, this._PatientService.searchText, this._PatientService.responseId);
  }

  onSearch(pageSize: number, pageNo: number) {

    // let studyid = this.srearchForm.get('study').value;
    // let siteid = this.srearchForm.get('site').value;
    // let statusid = this.srearchForm.get('status').value;
    // let startDate = this.srearchForm.get('startDate').value == "" ? null : this.srearchForm.get('startDate').value;
    // let studyvisitid = this.srearchForm.get('studyvisit').value;
    // let endDate = this.srearchForm.get('endDate').value == "" ? null : this.srearchForm.get('endDate').value;
    // let searchText = this.srearchForm.get('search').value;

    this._PatientService.studyid = this.srearchForm.get('study').value || '-1';
    this._PatientService.siteid = this.srearchForm.get('site').value;
    this._PatientService.statusid = this.srearchForm.get('status').value;
    if(!this.dateRangPicker.dateRangeEnabled){
      this._PatientService.startDate="";
    }
    else{
      this._PatientService.startDate = this.startDate == undefined ? "" : this.startDate;
    }
    this._PatientService.studyvisit = this.srearchForm.get('studyvisit').value;

    if(!this.dateRangPicker.dateRangeEnabled){
      this._PatientService.endDate="";
    }
    else{
      this._PatientService.endDate = this.endDate == undefined ? "" : this.endDate;
    }
    this._PatientService.searchText = this.srearchForm.get('search').value;
    this._PatientService.responseId = this.srearchForm.get('responseId').value;

    this.screenerService.GetScreenerCallList(false, this._PatientService.studyid, this._PatientService.siteid, this._PatientService.statusid,
      this._PatientService.studyvisit, this._PatientService.startDate, this._PatientService.endDate, 0, 0, this._PatientService.searchText, this._PatientService.responseId);
  }

  OpenNewDialog(row: any) {
    this.spinner.show()
    this._PatientService.GetPatientDetailById(row.Id).subscribe(res => {
      const dialogRef = this.dialog.open(CreatepatientComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <Patient>res,
          screenType: CallListType.Screener
        }
      })
      // .afterClosed().subscribe(resDat
      //   a => {
      //   this._PatientService.isCallApiScreener.subscribe(res => {
      //     if (res) {
      //       this.onSearch(20, 1)
      //     }
      //   })
      // });
      this.spinner.hide()
    }, error => {
      this._Global.handleError(error);
      this.spinner.hide()
    })
    // this.spinner.show()
    // this._PatientService.GetPatientDetailById(row.Id).subscribe(res => {
    //   const dialogRef = this.dialog.open(CreatepatientComponent, {
    //     panelClass: 'dailogwidth',
    //     disableClose: true,
    //     data: {
    //       callList: <Patient>res,
    //       screenType: CallListType.Screener
    //     }
    //   }).afterClosed().subscribe(res => {
    //       this._PatientService.isCallApiScreener.subscribe(res => {
    //         if (res) {
    //           this.onSearch(20, 1)
    //         }
    //       })
    //     });
    //   this.spinner.hide()
    // }, error => {
    //   this._Global.handleError(error);
    //   this.spinner.hide()
    // })

  }

  ChangeStudy() {
    this.visitsofStudy = [];
    this.srearchForm.get('studyvisit').setValue('-1');
    let studyid = this.srearchForm.get('study').value;
    this.visitsofStudy = this.studyvisitService._studyvisits.filter(x => x.StudyId == studyid);
  }

  ChangeSite() {
    if (this.srearchForm.controls.site.value != '-1') {
      this.siteService.GetStudiesBySite(this.srearchForm.controls.site.value, CallListType.Screener)
    } else {
      //this.siteService._sitesStudy = this.studyService._activeStudies;
      this.siteService.sitesStudy.next([]);
    }
  }

  OpenMarkDialog(row: any, isEdit: boolean) {
    this.spinner.show()
    this._PatientInteractionService.GetInteractions(CallListType.Screener, row.Id, row.StatusId, row.PatientInteractionId).subscribe(res => {
      // this._PatientInteractionService.GetNextVisits("GetNextVisits", row.Id, row.StudyId)
      const dialogRef = this.dialog.open(RecruitercalllistMarkComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          callList: <PatientInteraction>res,
          screenType: CallListType.Screener,
          IsDNQ: false,
          isEdit: isEdit,
          formPreview : true,
          lastInterationStatus: row.Status ? row.Status : "",
          lastInterationStatusId: row.StatusId ? row.StatusId : "",
          rowData: row,
        }
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }
  OpenNotesDialog(row: any, pageNo = 1) {
    let params = new HttpParams()
    .append('PageNo', String(pageNo))

    this.spinner.show()
    forkJoin([this._PatientInteractionService.GetPatientInteractions(row.Id),
    this._PatientInteractionService.GetInteractions(CallListType.PatientNotes, row.Id, row.StatusId, row.LastInteractionId),
    this._PatientService.GeCommunicationHistoryById(row.Id, params)]).subscribe(res => {
      let array = [
        { PatientInteraction: <PatientInteractionDTO>res[0], patientInfo: <Patient>res[1], patientList: res[2] }
      ]
      this.isMoreData =  res[2].pagination.isMore;
      const dialogRef = this.dialog.open(RecruitercalllistNotesComponent, {
        panelClass: ['dailogwidth', 'comtrak-modal'],
        disableClose: true,
        data: {
          PatientInteraction: array,
          hideAddNotes: true,
          rowData: row,
          isMoreData:this.isMoreData,
        }
      });
      this.spinner.hide()
    }, err => {
      this._Global.handleError(err);
      this.spinner.hide()
    })
  }

  getPage(page) {
    this.pageSize = page.pageSize
    this.pagenumber = page.pageIndex + 1
    this.onSearch(page.pageSize, page.pageIndex + 1);
  }

  ChangeStatus(row) {
    if (this.statusId != '-1') {
      if (confirm("Are you sure ? You want to change call status.") == true) {
        this._Global.IsReady = false
        this.spinner.show()
        this._PatientInteractionService.AddPatientIntercationChangeStatus(row.Id, row.StatusId, this.statusId).subscribe(res => {
          this.onSearch(this.pageSize, this.pagenumber)
          this._Global.IsReady = true
          this.spinner.hide()
          this.statusId = '-1'
          this._Global.showSuccessMsg("Interaction Add Successfully")
        }, error => {
          this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
          this._Global.IsReady = true
          this.spinner.hide()
          this.statusId = '-1'
        })
      }
      else {
        this._Global.IsReady = true;
        this.spinner.hide()
        this.statusId = '-1'
      }
    }
  }

  ChangeStartDate($event) {
    this.startDate = $event
    if (this.startDate > this.endDate) {
      this._Global.showErrorMsg("Start Date cannot be greater then End Date ")
      this.srearchForm.controls.startDate.setValue(new Date())
    }
    else {
      return false;
    }
  }

  ChangeEndDate($event) {
    this.endDate = $event
    if (this.endDate < this.startDate) {
      this._Global.showErrorMsg("End Date cannot be less then Start Date ")
      this.srearchForm.controls.endDate.setValue(new Date())
    }
    else {
      return false;
    }
  }

  PrintElem(elem) {
    this.excelService.PrintElem("Screening Call List", $(elem).html());
  }

  ExportToExcel() {
    this.excelService.exportAsExcelFile(this.screenerService._callListArray, "Screening Call List")
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.siteService._sitesArray);
  }

  onPageSizeChanged(event: any) {
    this.gridApi.paginationSetPageSize(Number(event.target.value));
  }

  destroyFilter() {
    this.columnDefs.forEach(element => {
      this.gridApi.destroyFilter(element.field);
    })
  }

  actionClick(data: any): void {

    if (data.type === 'call' || data.type === 'sms' || data.type === 'email') {
      this._RecruiterService.contactCenterFlag.next(true);
      this._RecruiterService.contactCenterType.next(data.type);
      this._RecruiterService.contactCenterRowData.next(data.rowData);
      this._PatientService.SetPatientId(data.rowData.Id);
      this._PatientService.SetRowData(data.rowData);
    } else if(data.command) {
      this.commandClick({commandColumn: {...data.command,  name: "commandClick"}, rowData: data.rowData, cancel: false,})
    }
  }

  commandClick(args: any): void {
    this.showPatientDetailDialog = false;
    if (args.commandColumn != undefined) {
    if (args.commandColumn.buttonOption.iconCss == ActionType.MarkInteraction) {
      this.OpenMarkDialog(args.rowData, false)
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Notes) {
      this.OpenNotesDialog(args.rowData)
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Edit) {
      this.OpenNewDialog(args.rowData)
    }
    // else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.Delete) {
    //   this.DeleteInteraction(args.rowData)
    // }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.EditMarkInt) {
      //debugger
      var rowData: any = args.rowData
      var statusObj = this.statusService._status.find(o => o.Id == rowData.StatusId)
      if (statusObj && statusObj.Roles.find(x => x.IsEdit == true)) {
        this.OpenMarkDialog(args.rowData, true)
      }
      else {
        this._Global.showErrorMsg("This interaction is not editable")
      }
    }
    else if (args.commandColumn && args.commandColumn.buttonOption.iconCss == ActionType.CreateVaccine) {
      this.OpenVaccineDialog(args.rowData, 'EditSave')
    } else if (args.commandColumn.buttonOption.iconCss === ActionType.Chat) {
      this.socketService.emitChatJoinRoomEvent('site_visit_list', `site_visit_list_${args.rowData.Id}`);
      localStorage.setItem('entity_name', 'site_visit_list');
      localStorage.setItem('entity_title', args.rowData.FullName);
      localStorage.setItem('entity_id', `site_visit_list_${args.rowData.Id}`);
      this.PrescreenList.openChatBox.next(true);
    }
    }
  }
  late() {
    setTimeout(() => {
      this.showRecord = true
    }, 13000);
  }
  CreateVaccineDialog(row, actionType) {
    //debugger;
    const dialogRef = this.dialog.open(CreatevaccineComponent, {
      panelClass: ['Createvaccinedailogwidth', 'comtrak-modal'],
      //panelClass: 'Createvaccinedailogwidth',
      disableClose: true,
      data: {
        callList: row,
        actionType: actionType
      }
    })
  }
  OpenVaccineDialog(row, actionType) {
    //debugger;
    if (row == undefined) {
      this.CreateVaccineDialog(undefined, actionType)
    }
    else {
      this.CreateVaccineDialog(row, actionType)
    }
  }
  onShowPatientDatailSideDialog(showDialog: boolean) {
    if (showDialog) {
      this.showPatientDetailDialog = true;
    }
  }
  onEventCatch(args: RowSelectEventArgs) {
    if (args.target.className.includes('e-rowcell')) {
      const dialogRef = this.dialog.open(PatientDetailComponent, {
        panelClass: ['rightside-modal', 'comtrak-modal'],
        disableClose: false,
        data: { ...args.data, screenType: CallListType.Screener },
      })
      this.dialogRefs.push(dialogRef);
      dialogRef.backdropClick().subscribe(() => {
        this.closeAllDialogs();
      });
    }
  }
  closeAllDialogs() {
    for (const dialogRef of this.dialogRefs) {
      dialogRef.close();
    }
    this.dialogRefs = [];
  }

  onDateRangeChange(dateRange: { startDate: Date, endDate: Date }): void {
    // debugger
    this.startDate = this.datePipe.transform(dateRange.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(dateRange.endDate, "yyyy-MM-dd");
  }

  onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    let query = new Query();
    let stitedata: any[] = this.siteService._sitesStudy;
    query = (e.text != "") ? query.where("Name", "Contains", e.text, true) : query;
    e.updateData(stitedata, query);
  };

  async getActiveStudies() {
    await this.studyService.GetActiveStudies();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

  onSearchChange(event: any) {
    if (event.target.value.trim() === '') {
      this.dateRangPicker.dateRangeEnabled = true;
    } else {
      this.dateRangPicker.dateRangeEnabled = false;
    }
  }

}