
import { Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {
  public _SponsorData: any[];
  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private spinner: NgxSpinnerService) { }

  LoadSponsors() {
    try {
      return this._http.get(myGlobals._apiPath + 'Sponsor/GetActive',
        { headers: this._Global.httpOptions });
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }
  }