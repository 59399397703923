import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
    providedIn: 'root'
})
export class IconService {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.registerIcons();
    }

    private registerIcons() {
        this.matIconRegistry.addSvgIcon(
            'assign_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/assign.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'duplicate_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/duplicate-icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'filter_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/filter-icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'trash_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/trash.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'info_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/Info.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'delete_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/delete-icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'eye_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/eye-icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'edit_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/edit-icon.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'up-sort-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/descending-up-arrow.svg')
        )
        this.matIconRegistry.addSvgIcon(
            'down-sort-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/ascending-down-arrow.svg')
        )
        this.matIconRegistry.addSvgIcon(
            'refresh_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comtrak-ui-system-v-0.1/images/refresh-icon.svg')
        )

        // Register more icons as needed
    }
}
