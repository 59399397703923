import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ColDef } from 'ag-grid-community';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { VisitorFlowColumns } from '@sharedmodels/BackModels/ComTrak/VisitorFlow/VisitorFlowColumns';

import { ExcelService } from '@sharedservices/FrontServices/Export/excel.service';
import { VisitorFlowColumnService } from '@sharedservices/BackServices/ComTrak/VisitorFlow/visitorflowcolumn.service';

@Component({
  selector: 'app-visitorflowcolumn',
  templateUrl: './visitorflowcolumn.component.html',
  styleUrls: ['./visitorflowcolumn.component.scss', '../../style.component.scss']
})
export class VisitorflowcolumnComponent implements OnInit {
  @ViewChild('checkBox') checkBox: TemplateRef<any>;
  @ViewChild('edit') edit: TemplateRef<any>;
  visitorFlowColumnForm: FormGroup
  submitted: boolean;
  columnDefs: ColDef[]
  public defaultColDef;
  public gridApi: any;
  public gridColumnApi: any;
  pageSize: number = 20;
  columnType: string
  editRecord: boolean = false;
  constructor(public dialogRef: MatDialogRef<VisitorflowcolumnComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, public visitorFlowColumnService: VisitorFlowColumnService, public _Global: GlobalfunctionService,
    public excelService: ExcelService) { }

  ngOnInit() {
    
    this._Global.gotoTop();
    this.gridConfig();
    this.visitorFlowColumnService.LoadVisitorFlowColumns();
    this.initForm()
  }

  initForm() {
    this.visitorFlowColumnForm = this.fb.group({
      ColumnType: [''],
      Title: ['', Validators.required],
      Description: [''],
      Active: [true],
      VisitorFlowColumnsID: [0],
      DefaultValue: ['', Validators.required]
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  onSubmit() {
    this.submitted = true;
    if (this.visitorFlowColumnForm.invalid) {
      return;
    }
    else {
      this.visitorFlowColumnForm.controls['ColumnType'].enable();
      this.visitorFlowColumnService.SaveAndEdit(this.visitorFlowColumnForm.value, this.editRecord)
      this.visitorFlowColumnService.isCallApi.subscribe(res => {
        if (res) {
          this.editRecord = false
          this.initForm();
          this.submitted = false
          // this.visitorFlowColumnService.isCallApi.next(true)
        }
      })
    }
  }

  get f() {
    return this.visitorFlowColumnForm.controls;
  }

  gridConfig() {
    this.defaultColDef = this._Global.defaultColDef
    this.columnDefs = [
      { field: 'Title', sortable: true, filter: true },
      { field: 'ColumnType', sortable: true, filter: true },
      { field: 'Description', sortable: true, filter: true },
      {
        field: 'Active', sortable: true, filter: true,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.checkBox
        }
      },
      {
        field: 'Action', sortable: false, filter: false,
        cellRendererFramework: TemplaterenderComponent,
        cellRendererParams: {
          ngTemplate: this.edit
        }
      },
    ];
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDatasource(this.visitorFlowColumnService._VisitorFlowColumns);
  }

  onPageSizeChanged(event: any) {
    this.gridApi.paginationSetPageSize(Number(event.target.value));
  }

  PrintElem(elem) {
    this.excelService.PrintElem('Sites', elem)
  }

  onBtExport() {
    this.gridApi.exportDataAsCsv();
  }

  destroyFilter() {
    this.columnDefs.forEach(element => {
      this.gridApi.destroyFilter(element.field);
    })
  }

  ClickIsActive(row: VisitorFlowColumns) {
    row.ColumnType = row.ColumnType == "CheckBox" ? "C" : row.ColumnType == "Drop Down" ? "D" :
      row.ColumnType == "TextBox" ? "T" : row.ColumnType == "Yes / No" ? "YN" : row.ColumnType;
    if (row.Active == false) {
      if (confirm("Are you sure You want to InActive this Column?") == true) {
        this.visitorFlowColumnService.InActiveVisitorFlowColumns(row, "Column InActive Successfully.")
      }
      else {
        row.Active = true
      }
    }
    else {
      if (confirm("Are you sure You want to Active this Column?") == true) {
        this.visitorFlowColumnService.InActiveVisitorFlowColumns(row, "Column Active Successfully.")
      }
      else {
        row.Active = false
      }
    }
  }

  setEditData(row: VisitorFlowColumns) {

    this.editRecord = true
    let columnTypeData = row.ColumnType = row.ColumnType == "CheckBox" ? "C" : row.ColumnType == "Drop Down" ? "D" :
      row.ColumnType == "TextBox" ? "T" : row.ColumnType == "Yes / No" ? "YN" : row.ColumnType;
    this.columnType = row.ColumnType
    this.visitorFlowColumnForm.controls.Title.setValue(row.Title);
    this.visitorFlowColumnForm.controls.Description.setValue(row.Description);
    this.visitorFlowColumnForm.controls.ColumnType.setValue(columnTypeData);
    this.visitorFlowColumnForm.controls.VisitorFlowColumnsID.setValue(row.VisitorFlowColumnsID);
    this.visitorFlowColumnForm.controls['ColumnType'].disable();
  }
}
