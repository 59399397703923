import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { CommunicationService } from '@sharedservices/BackServices/ComTrak/Communication/communication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-email-connect',
  templateUrl: './email-connect.component.html',
  styleUrls: ['./email-connect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailConnectDialogComponent implements OnInit {
  public myData: any = [];
  htmlContent: any;
  loginUser:any = {};
  popupWindow: Window | null = null;
  showTable: boolean = false
  memoFc = new FormControl('');
  memoList = []
  date = ''
  subs = new Subscription()
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  users=[] ;
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEmailDialogClose: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public dialogRef: MatDialogRef<EmailConnectDialogComponent>,
    private spinner: NgxSpinnerService,
    private _global: GlobalfunctionService,
    private toastService: ToastrService,
    private communicationService: CommunicationService,
    private datePipe: DatePipe,
    private NotitificationsService: NotitificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) { }

  ngOnInit() {
    this.memoList = this.data
    this.loginUser = this._global.getLoginUserDetails()
  }
  handleViewAll() {
    this.dialogRef.close();
    this.router.navigate(['/all-memos-screen']);
  }

  onSubmit(value: string): void {
    this.onSubmitAction.emit(value);
    this.onNoClick();
  }
 
  handleSearch(event): void {
    this.memoFc.setValue(event.itemData._id)
    if (this.showTable) {
      this.onSearch()
    }
  }

  onSearch(): void {
    if (this.memoFc.value) {
      this.spinner.show();
      this._global.IsReady = false;
      const getData = this.NotitificationsService.getMemoListById(this.memoFc.value).subscribe({
        next: (res) => {
          if (res.status) {
            this.spinner.hide();
            this._global.IsReady = true;
            this.users = res.data.users
            this.date = res.data.created_on
            this.showTable = true
            console.log(" this.users", this.users)
          } else {
            this.toastService.error('', res.message, this.toastOptions);
            this.spinner.hide();
            this._global.IsReady = true;
          }
        },
        error: (err) => {
          this.spinner.hide();
          this._global.IsReady = true;
          this.toastService.error('', err, this.toastOptions);
        }
      });
      this.subs.add(getData);
    } else {
      this.toastService.error('', 'Please Select Memo', this.toastOptions);
    }
  }
  onAdd() {
    this.spinner.show();
    this._global.IsReady = false;
    this.communicationService.getPatientStatusList().subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.hide();
          this._global.IsReady = true;
          // Define the dimensions and settings for the popup window
          const popupWidth = 800;
          const popupHeight = 600;
          const popupOptions = `
            width=${popupWidth},
            height=${popupHeight},
            top=${(window.innerHeight - popupHeight) / 2},
            left=${(window.innerWidth - popupWidth) / 2},
            resizable=yes,
            scrollbars=yes,
            status=no,
            toolbar=no,
            location=no,
            menubar=no`;

          this.popupWindow = window.open(res.data, '_blank', popupOptions);
        }else{
          this.spinner.hide();
          this._global.IsReady = true;
        }
        this.spinner.hide();
        this._global.IsReady = true;
        
      },
      error: (err) => {
        console.log('err======', err);
        this.spinner.hide();
        this._global.IsReady = true;
      }
    });
  }
  formatDate1(dateTime: string): string {
    if (dateTime != null) {
      const localDateTime = new Date(dateTime);
      const formattedDate = this.datePipe.transform(localDateTime, 'MM/dd/yyyy | h:mm a');
      return formattedDate;
    } else {
      return null
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.onEmailDialogClose.emit();
  }
}
