import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { MatMenuTrigger, MatSidenav } from '@angular/material';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Subject, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { RoutesService } from '@sharedservices/BackServices/ComTrak/Security/routes.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatModalComponent implements OnInit, OnDestroy {

  sub: Subscription;
  smsChatMessageList = [];
  isEmojiPickerOpenForSMS = false;
  isEmojiButtonClickForSMS = false;
  smsInputValue: FormControl;
  cursorPosition: number = 0;
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  userList = [];
  selectedUserList = [];
  filterUserList = [];
  replyMessData = null;
  isTag = false;
  loginUserName = null;
  loginUserEmail = null;
  pageNumber = 0;
  routeID: number;
  replyParentId: any;
  searchUser =  new FormControl();
  toastrActive = false;
  onScrollApiCalling = false;
  stopApiCall = false;
  tagIndex = null;
  chatTitle: string;
  entityName: string;
  entityTitleList = {
    patient_profiles: 'Patient Profile',
    prescreen_list: 'Pre-Screener List',
    recruiter_list: 'Recruiter List',
    site_visit_list: 'Site Visit List',
    communication_draft: 'Communication Draft',
    communication_pending: 'Communication Pending Review',
    prescreen_draft: 'Prescreen Forms Draft',
    prescreen_pending: 'Prescreen Forms Pending Review',
    script_draft: 'Script Library Draft',
    script_pending: 'Script Library Pending Review',
    email_individual: 'Email Individual',
    email_distro: 'Email Distro',
    phone_individual: 'Phone Individual',
    phone_distro: 'Phone Distro',
    text_individual: 'Text Individual',
    text_distro: 'Text Distro'
  }
  isUserMenuOpen = true;
  isEnterPressed = false;

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('textInput') textInput!: ElementRef;
  @ViewChild("sidenav", { read: ElementRef }) sidenavcontainer: ElementRef;
  @ViewChild('firstListItem', { read: ElementRef }) firstListItem: ElementRef;
  @ViewChild('searchTagInput', { read: ElementRef }) searchTagInput: ElementRef;
  private searchSubject: Subject<string> = new Subject<string>();
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  constructor(
    public _Global: GlobalfunctionService,
    private prescreenService: PrescreenService,
    public spinner: NgxSpinnerService,
    private router: Router,
    private toastService: ToastrService,
    private notificationService: NotitificationsService,
    public _RoutesService: RoutesService,
    private cd: ChangeDetectorRef,
    private overlayContainer: OverlayContainer,
    private socketService: SocketHandlerService
  ) {
      this.sub = new Subscription();
    }
    
  ngOnInit(): void {
    const openChatSub = this.prescreenService.openChatBox.subscribe(v => {
      if (v) {
        this.isEmojiPickerOpenForSMS = false;
        this.isEmojiButtonClickForSMS = false;
        this.cursorPosition = 0;
        this.selectedUserList = [];
        this.filterUserList = [];
        this.replyMessData = null;
        this.isTag = false;
        this.loginUserName = null;
        this.loginUserEmail = null;
        this.pageNumber = 0;
        this.replyParentId = null;
        this.searchUser = new FormControl();
        this.toastrActive = false;
        this.onScrollApiCalling = false;
        this.stopApiCall = false;
        this.tagIndex = null;
        this.isUserMenuOpen = true;
        this.isEnterPressed = false;
        this.setEntityDetails();
      }
      v ? this.sidenav.open() : this.sidenav.close();
      
      if (v) {
        this.getChatList(this.pageNumber += 1)

        this.socketService.receivedChatCreateEvent().subscribe(data => {
          var isTagUser = data.tags_users.some(user => user.email === this.loginUserEmail)
          if (this.sidenav.opened && isTagUser) {
            var senderData = {
              entity_id: data.entity_id,
              entity_name: data.entity_name,
              user: this.loginUserEmail
            }
          this.socketService.chatOpenRead(senderData);
          }
          if (!!data) {
            data.is_sent = data.sender_id === this.loginUserEmail ? true : false;
            if (!!data.parent_id) {
              let parentIndex = this.smsChatMessageList.findIndex(v => v._id === data.parent_id);
              this.smsChatMessageList[parentIndex]['reply_data'].push(data);
              if (this.replyMessData) {
                this.replyMessData = null;
              }
            } else {
              data['reply_data'] = [];
              this.smsChatMessageList.splice(
                this.smsChatMessageList.length,
                0,
                data,
              );
            }
            this.scrollToBottomForChatList();
          }
        });

      }
    });

    this.searchUser.valueChanges
      .pipe(
        debounceTime(300), // Adjust time in milliseconds (300ms in this case)
        distinctUntilChanged() // Only emit if the value changes
      )
      .subscribe(value => {
        this.tagUserList(value)
      });
      this.searchSubject.pipe(
        debounceTime(300),  // Adjust time in milliseconds (300ms in this case)
        distinctUntilChanged() // Only emit if the value changes
      ).subscribe(input => {
        this.tagUserList(input); 
     });
    this.sub.add(openChatSub);
  }

  setEntityDetails(): void {
    this.smsInputValue = new FormControl();
    this.loginUserName = this._Global.getLoginUserDetails().name;
    this.loginUserEmail = this._Global.getLoginUserDetails().email;
    const routeUrl = this.router.url.includes('/patientprofile') ? '/patientslist' : this.getPathFromUrl(this.router.url);
    let routeData = this._RoutesService._routes.filter(v => v.path === routeUrl);
    if (!!routeData.length) {
      this.routeID = routeData[0].routesID;
    }

    if (this.routeID) {
      setTimeout(() => this.menuTrigger.openMenu(), 200)
    }
    this.chatTitle = localStorage.getItem('entity_title');
    this.entityName = localStorage.getItem('entity_name');
  }

  getPathFromUrl(url: string): string {
    return url.split('?')[0];
  }

  onScroll(event: Event): void {
    if (!this.stopApiCall) {
      const element = event.target as HTMLElement;
      if (element.scrollTop === 0) {
        this.onScrollApiCalling = true;
        this.getChatList(this.pageNumber += 1);
      }
    }
  }

  onMenuOpened(menuClass: string): void {
    this.isUserMenuOpen = true;
    this.overlayContainer.getContainerElement().classList.add(menuClass);
    setTimeout(() => {
      this.searchTagInput.nativeElement.focus();
    }, 800);
  }

  onMenuClosed(menuClass: string): void {
    this.searchUser.reset();
    this.tagIndex = false;
    this.filterUserList = this.userList;
    setTimeout(() => {
      this.overlayContainer.getContainerElement().classList.remove(menuClass);
      this.isUserMenuOpen = false;
    }, 4000);
  }

  trackByFn(index: number, item: any): string {
    return item.name;
  }

  onFilterChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value.toLowerCase();
    // this.filterUserList = this.userList.filter(user =>
    //   user.name.toLowerCase().includes(input)
    // );
    this.searchSubject.next(input);
    setTimeout(() => {
      this.searchTagInput.nativeElement.focus();
    }, 200);
  }

  keyDownEvent(event): void {
    const inputValue = this.smsInputValue.value;
    this.tagIndex = (event.key === '@');

    // Allow typing '@' and backspace
    if (event.key === '@' || event.key === 'Backspace') {
      return;
    }

    // Allow further typing only if input starts with '@' and selectedUserList has data
    if (!!inputValue && (!inputValue.startsWith('@') || this.selectedUserList.length === 0)) {
      event.preventDefault();
    }
  }

  onInputChange(): void {

    const inputValue = this.smsInputValue.value;

    if (this.tagIndex) {
      this.menuTrigger.openMenu();
    } else {
      this.menuTrigger.closeMenu();
      this.searchUser.reset();
      this.filterUserList = this.userList;
    }
    
    if (!inputValue.startsWith('@')) {
    // Clear the input if it doesn't start with '@'
      this.smsInputValue.setValue('');
    }
  }

  tagUserList(search: string): void {

    const getUser = this.notificationService.getTagUserList({'route_id': `${this.routeID}`,search: search}).subscribe({
      next:(res) => {
        if (res.status) {
          this.filterUserList = this.userList = res.data;
          this.cd.detectChanges();
        } else {
          this.showErrorToast('error', res.message);
        }
      },
      error:(err) => {
        this.showErrorToast('error', err.message);
      }
    });
    this.sub.add(getUser);
  }

  getChatList(pageNO: number): void {

    this.spinner.show();
    this._Global.IsReady = false;

    let params = new HttpParams().set('entity_name', localStorage.getItem('entity_name'))
                                 .set('entity_id', localStorage.getItem('entity_id'))
                                 .set('page_no', String(pageNO));
    const getListSub = this.notificationService.getChatList(params).subscribe({
      next:(res) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        let modifyData = [...res.data].reverse();
        if (res.status) {
          if (this.onScrollApiCalling) {
            if (!!this.smsChatMessageList.length) {
              this.smsChatMessageList = [...modifyData, ...this.smsChatMessageList];
            }
          } else {
            this.smsChatMessageList = modifyData
          }
          this.smsChatMessageList.forEach((v:any) => {
            v['is_sent'] = v.sender_id === this.loginUserEmail ? true : false;
          });
          if (this.replyMessData || this.replyParentId) {
            this.scrollToIndex(this.replyParentId);
          } else {
            if (!this.onScrollApiCalling) {
              this.scrollToBottomForChatList();
            }
          }
          if (res.pagination.currentPage === res.pagination.totalPage) {
            this.stopApiCall = true;
          }
          this.onScrollApiCalling = false;
          if (this.isEnterPressed) {
            this.isEnterPressed = false;
          }
        } else {
          this.showErrorToast('error', res.message);
        }
      },
      error:(err) => {
        this.spinner.hide();
        this._Global.IsReady = true;
        this.showErrorToast('error', err.message);
      }
    });
    this.sub.add(getListSub);
  }

  selectUser(user: any): void {

    if (!!user) {
      this.selectedUserList.push(user);
      const value = this.textInput.nativeElement.value;
      const textBeforeCursor = value.slice(0, this.cursorPosition);
      const textAfterCursor = value.slice(this.cursorPosition);
      
      const atIndex = textBeforeCursor.lastIndexOf('@');
      const newValue = textBeforeCursor.slice(0, (atIndex != -1) ? atIndex : textBeforeCursor.length) + ' @' + user.name + ' ' + textAfterCursor;
      
      this.smsInputValue.setValue(newValue.trim());
      this.menuTrigger.closeMenu();
      this.searchUser.reset();
      this.filterUserList = this.userList;
      this.textInput.nativeElement.focus();
    }
  }

  highlightText(content: string): string {
    if (content) {
      const userPattern = this.userList.map(user => user.name.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')).join('|');
      const regex = new RegExp(`(@(${userPattern}))`, 'gi');
      return content.replace(regex, match => `<span class="highlight-user-color">${match}</span>`);
    }
  }

  toggleEmojiPicker(): void {

    this.isEmojiButtonClickForSMS = !this.isEmojiButtonClickForSMS;
    this.isEmojiPickerOpenForSMS =  this.isEmojiButtonClickForSMS;
  }
 
  emojiSelect(e: any): void {

    if (this.smsInputValue.value.startsWith('@') && !!this.selectedUserList.length) {
      const emoji = e.emoji.native;
      const currentValue = !!this.smsInputValue.value ? this.smsInputValue.value : null;
      const newValue = !!currentValue ? currentValue.slice(0, this.cursorPosition) + emoji + currentValue.slice(this.cursorPosition) : emoji;
      this.smsInputValue.setValue(newValue);
      this.cursorPosition += emoji.length;
  
      setTimeout(() => {
        this.textInput.nativeElement.setSelectionRange(this.cursorPosition, this.cursorPosition);
      }, 0);
    }
  }

  updateCursorPosition(event: any): void {
    this.cursorPosition = event.target.selectionStart;
  }

  sendChat(): void {
    if (!this.isUserMenuOpen && !this.isEnterPressed) {
      this.sendMessage();
      this.isEnterPressed = true;
    }
  }

  sendMessage(): void {

    this.isEmojiPickerOpenForSMS = false;
    this.isEmojiButtonClickForSMS = false;
    let messageValue: string = !!this.smsInputValue.value ? this.smsInputValue.value.trim() : null;
    if (!!messageValue) {

      if (!messageValue.startsWith('@')) {
        this.showErrorToast('info', 'Mention someone at first');
      } else {

        let userList = this.selectedUserList.filter(v => this.smsInputValue.value.includes(v.name));
          
        let sendMessage = {
          content: !!this.smsInputValue.value ? this.smsInputValue.value : null,
          entity_name: localStorage.getItem('entity_name'),
          entity_id: localStorage.getItem('entity_id'),
          entity_title: localStorage.getItem('entity_title'),
          tags_users: userList,
        }

        if (this.replyMessData) {

          this.replyParentId = this.replyMessData._id;
          sendMessage['parent_id'] = this.replyMessData._id;

          const messageSub = this.notificationService.relpychat(sendMessage).subscribe({
            next:(res) => {
              if (res.status) {
                this.resetAllValues();
              } else {
                this.showErrorToast('error', res.message);
              }
            },
            error:(err) => {
              this.showErrorToast('error', err.message);
            }
          });
          this.sub.add(messageSub);

        } else {

          const messageSub = this.notificationService.createChat(sendMessage).subscribe({
            next:(res) => {
              if (res.status) {
                this.resetAllValues();
              } else {
                this.showErrorToast('error', res.message);
              }
            },
            error:(err) => {
              this.showErrorToast('error', err.message);
            }
          });
          this.sub.add(messageSub);
        }
      }
    } else {
      this.showErrorToast('info', 'Add Your Message');
    }
  }

  relpyUserTag(content: string, list:any[]): string {

    list.forEach(user => {
      const regex = new RegExp(`@${user.name}`, 'g');
      content = content.replace(regex, `<span class="highlight-user-color">@${user.name}</span>`);
    });
    return content;
  }

  resetAllValues(): void {

    this.smsInputValue.reset();
    this.selectedUserList = [];
    this.getChatList(1);
  }

  replyItem(data: any): void {
    this.replyMessData = data;
    let replyTagUser = this.userList.filter(v => v.email === data.sender_id);
    this.selectedUserList.push(replyTagUser[0]);
    this.smsInputValue.setValue(`@${data.sender_name}`);
  }

  clearReplyData(): void {
    this.replyMessData = null;
  }

  scrollToIndex(index: any): void {
    const element = document.getElementById('message-' + index);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    this.replyMessData = null;
    this.replyParentId = null;
  }

  scrollToBottomForChatList(): void {
    this.cd.detectChanges();
    const smsChatElement = document.querySelector('.sms-chat');
    if (smsChatElement) {
      smsChatElement.scrollTop = smsChatElement.scrollHeight;
    }
  }

  updateDivValue(event: any): void {
    this.smsInputValue.setValue(event.target.innerText);
  }

  displayName(userName: string, type?: string): string {

    let titleList = ['communication_draft','communication_pending','prescreen_draft','prescreen_pending','script_draft','script_pending'];

    let initials = '';
    if(!!type && titleList.includes(this.entityName)) {
      initials = userName.substring(0,2);
    } else {
      const parts = userName && userName.split(' ');

      if (parts.length === 1) {
        initials = parts[0].charAt(0) + parts[0].charAt(1);
      } else {
        for (const part of parts) {
          initials += part.charAt(0);
        }
      }
    }
    return initials.toUpperCase();
  }
  
  showErrorToast(type: string, message: string): void {
    if (!this.toastrActive) {
      this.toastrActive = true;
      const toast = this.toastService[type]('', message, this.toastOptions);
      toast.onHidden.subscribe(() => this.toastrActive = false);
    }
  }

  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.remove('menu-a');
    this.sub.unsubscribe();
  }

  closeSidenav(): void {
    this.stopApiCall = false;
    this.overlayContainer.getContainerElement().classList.remove('menu-a');
    this.prescreenService.openChatBox.next(false);
  }

}
