import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { SiteCalendarService } from './../../../Shared/Services/BackServices/ComTrak/Setups/sitecalendar.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Site } from '@sharedmodels/BackModels/ComTrak/Setups/Site';

@Component({
  selector: 'app-createsitecalendar',
  templateUrl: './createsitecalendar.component.html',
  styleUrls: ['./createsitecalendar.component.scss','../../style.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),

    ]),
  ]
})
export class CreatesitecalendarComponent implements OnInit {
  SiteCalendarForm: FormGroup
  submitted: boolean = false;
  public _data: Site;
  isedit: boolean = false;
  disbaledFields: boolean;
  constructor(public dialogRef: MatDialogRef<CreatesitecalendarComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public siteService: SiteService,
    public fb: FormBuilder, public siteCalendarService: SiteCalendarService) { }

  ngOnInit() {
    this.siteService.LoadActiveSites()
    this.initForm()
    if (this.data.callList != undefined) {
      this.SiteCalendarForm.patchValue(this.data.callList)
      this.isedit = true
    }
    else {
      this.isedit = false
    }
  }

  initForm() {
    this.SiteCalendarForm = this.fb.group({
      Name: ['', Validators.required],
      CalendarId: ['', Validators.required],
      SiteId: ['', Validators.required],
      IsActive: [false],
      Id: [''],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.SiteCalendarForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.SiteCalendarForm.invalid) {
      return;
    }
    else {
      let Id = this.data.callList ? this.data.callList.Id : 0
      this.SiteCalendarForm.controls.Id.setValue(Id)
      this.siteCalendarService.SaveAndEditSiteCalendar(Id, this.SiteCalendarForm.value, this.isedit)
      this.siteCalendarService.isCallApi.subscribe(res => {
        if (res) {
          this.onNoClick()
          this.siteCalendarService.isCallApi.next(true)
        }
      })
    }
  }
}
