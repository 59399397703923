import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patient.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CallList } from '@sharedmodels/BackModels/ComTrak/CallLists/CallList';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { StaticDataService } from '@sharedservices/BackServices/ComTrak/Setups/StaticData.service';
import { RecruitercalllistMarkComponent } from '../recruitercalllist-mark/recruitercalllist-mark.component';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';

@Component({
  selector: 'app-calllistdetails',
  templateUrl: './calllistdetails.component.html',
  styleUrls: ['./calllistdetails.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),

    ]),
  ]
})
export class CalllistdetailsComponent implements OnInit {
  RecruiterForm: FormGroup
  Gender = [
    { name: "Male" },
    { name: "Female" }
  ]
  format: string = 'M/d/yyyy';
  constructor(public dialogRef: MatDialogRef<RecruitercalllistMarkComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public _PatientInteractionService: PatientInteractionService,
    private fb: FormBuilder, public _CampaignService: CampaignService, public _CampaignReferralSourceService: CampaignReferralSourceService,
    public staticDataService: StaticDataService, private _patientService: PatientService, public _Global: GlobalfunctionService, public spinner: NgxSpinnerService) { }
  public _data: CallList;
  show: boolean = false;
  submitted: boolean = false;

  ngOnInit() {
    this.initForm()
    let id = 0;
    if (this.data.callList !== null) {
      this._data = this.data.callList;
      this.RecruiterForm.patchValue(this._data)
      this.RecruiterForm.controls.CampaignId.setValue(this._data.CampaignId == 0 ? '' : this._data.CampaignId)
      this.RecruiterForm.controls.CampaignReferralSourceId.setValue(this._data.CampaignReferralSourceId == 0 ? '' : this._data.CampaignReferralSourceId)
      this.RecruiterForm.controls.Gender.setValue(this._data.Gender == "Select Gender" || this._data.Gender == "" ? "Male" : this._data.Gender)
      this._CampaignService.GetActiveCampaigns()
      this._CampaignReferralSourceService.GetCampaignReferralSources()
    }
  }

  initForm() {
    this.RecruiterForm = this.fb.group({
      FirstName: ['', Validators.required],
      MiddleName: [''],
      LastName: ['', Validators.required],
      CrioId: [''],
      Phone: ['', [Validators.required, Validators.min(90000000), Validators.max(10000000000)]],
      Phone2: [''],
      Email: ['', Validators.email],
      Gender: ['', Validators.required],
      DateOfBirth: ['',],
      Race: [''],
      Ethnicity: [''],
      Country: [''],
      CampaignId: ['', Validators.required],
      CampaignReferralSourceId: ['', Validators.required],
      Bmi: [''],
      Address: [''],
      City: [''],
      State: [''],
      ZipCode: [''],
      Id: ['']
    })
  }

  get f() {
    return this.RecruiterForm.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  onSubmit() {
    try {
      this.submitted = true;
      this.RecruiterForm.controls.Id.setValue(this._data.Id)
      if (this.RecruiterForm.invalid) {
        return;
      }
      else {
        this.spinner.show()
        this._Global.IsReady = false
        this._patientService.EditPatient(this._data.Id, this.RecruiterForm.value).subscribe(res => {

          this._Global.showSuccessMsg('Updated successfully');
          this.onNoClick()
          // this._Global.IsReady = true;
          // this.spinner.hide()
        }, error => {
          this._Global.IsReady = true;
          this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
          this.spinner.hide()
        })
      }
    }
    catch (error) {
      this._Global.showErrorMsg(error);
      this._Global.IsReady = true;
      this.spinner.hide()
    }
  }
  Onlyphonenumber(event): boolean {
    const Regexpattern = /[-.(0-9) ]/;
    const result = Regexpattern.test(event.key);
    return result;
  }
}
