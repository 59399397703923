import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import * as myGlobals from '@shared/global_var';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  
  public _externalURL: string;
  public _outlet: string;
  constructor(public _http: HttpClient, private _Global: GlobalfunctionService) {
    // this.GetAllOrderTypes();
    // this.GetAllUoM();

  }

 
}


