import { TemplaterenderComponent } from './../aggrid/templaterender/templaterender.component';
import { AlphabetOnlyDirective } from '../Decorators/alphabet-only.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableModule } from '@app/components/datatable/datatable.module';
import { TotalrecordshowModule } from '@app/components/totalrecordshow/totalrecordshow.module';
import { TotalrecordshowComponent } from '@app/components/totalrecordshow/totalrecordshow.component';
// import { CountUpModule } from 'ngx-countup'; removing this library for pipeline

@NgModule({
  declarations: [
    AlphabetOnlyDirective,
    TemplaterenderComponent,
    TotalrecordshowComponent
  ],
  imports: [
    CommonModule,
    TotalrecordshowModule,
    // CountUpModule,
  ],
  exports: [
    AlphabetOnlyDirective,
    TotalrecordshowComponent
  ]
})
export class GlobalModule { }
