import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmailTemplate } from '@sharedmodels/BackModels/ComTrak/Communication/EmailTemplate';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { TemplateService } from '@sharedservices/BackServices/ComTrak/Communication/template.service';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import 'quill-mention';
import 'quill-emoji';
import { TemplatestepperComponent } from '../templatestepper/templatestepper.component';


@Component({
  selector: 'app-createalerttemplate',
  templateUrl: './createalerttemplate.component.html',
  styleUrls: ['./createalerttemplate.component.scss', '../../style.component.scss'],
  providers: [TemplatestepperComponent]
})
export class CreatealerttemplateComponent implements OnInit {
  TemplateForm: FormGroup
  submitted: boolean = false
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'ltr' }],                         // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        // [{ 'align': [] }],
        ['clean'],                                         // remove formatting button
        ['image', "emoji"]
        // ['video'], 
      ],
      handlers: { 'emoji': function () { } }
    },
    // autoLink: true,
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        }
      }
    }
  }
  // config: AngularEditorConfig = {
  //   defaultFontSize: '3',
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   showToolbar: true,
  //   defaultFontName: 'Calibri',
  // };
  // lovType: Lov[];
  @Input() emailTemplateDataObject: EmailTemplate
  disableButton: boolean;
  constructor(public fb: FormBuilder, public lovService: LovService, public _Global: GlobalfunctionService,
    public templateService: TemplateService, public _TemplatestepperComponent: TemplatestepperComponent) { }

  ngOnInit() {
    this.getLovData()
    this.initForm()
    if (this.emailTemplateDataObject) {
      this.templateService.isCompleted = true
      this.TemplateForm.patchValue(this.emailTemplateDataObject)
      this.TemplateForm.controls.Content2.setValue(this.emailTemplateDataObject.Content)
      let val = this.removeHTML(this.TemplateForm.controls.Content2.value)
      this.templateService.editorText = val
      this.templateService.templateObject.TypeId = this.TemplateForm.controls.TypeId.value
      this.ChangeType()
    }
  }

  removeHTML(str) {
    var tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  // getInnerHTML(val) {
  //   let content = val.replace(/<[^>]*>/g, '');
  //   content = val.replace('&#160;', ' ');
  //   return content;
  // }

  initForm() {
    this.TemplateForm = this.fb.group({
      Id: [0],
      Name: ['', Validators.required],
      Content: [''],
      Content2: [''],
      Subject: [''],
      TypeId: ['', Validators.required],
      IsActive: [false]
    })
  }

  getLovData() {
    this.lovService.getLovDataById(LovStatusMasterID.TemplateType).subscribe(res => {
      this.templateService.lovType = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  setEditorText(value: string) {
    var text = this.TemplateForm.controls.Content2.value
    text += value;
    this.TemplateForm.controls.Content2.setValue(text);
  }

  get f() {
    return this.TemplateForm.controls;
  }

  onSubmit(nexPage: boolean) {
    this.submitted = true;
    if (this.TemplateForm.invalid) {
      return;
    }
    else {
      this.TemplateForm.controls['Name'].enable();
      this.TemplateForm.controls['Content'].enable();
      this.TemplateForm.controls['Subject'].enable();
      this.TemplateForm.controls['TypeId'].enable();
      if (this.TemplateForm.controls.Content2.value == "") {
        this._Global.showErrorMsg("Content cannot be empty")
        return
      }
      let val = this.removeHTML(this.TemplateForm.controls.Content2.value)
      this.templateService.editorText = val
      this.TemplateForm.controls.Content.setValue(this.templateService.editorText)
      this.TemplateForm.controls.Id.setValue( this.templateService.templateId)
      if (this.emailTemplateDataObject || this.templateService.templateId) {
        this.templateService.EditEmailTemplate(this.TemplateForm.value)
        this.templateService.IsEditEmailConfig.subscribe(res => {
          if (nexPage == true) {
            var check = document.getElementById('stepperButton')
            check.click();
          }
          else {
            this._TemplatestepperComponent.onNoClick()
          }
          if (res) {
            this.disableButton = true
            this.TemplateForm.controls['Name'].disable();
            this.TemplateForm.controls['Content'].disable();
            this.TemplateForm.controls['Subject'].disable();
            this.TemplateForm.controls['TypeId'].disable();
          }
          else {
            this.disableButton = false
            this.TemplateForm.controls['Name'].enable();
            this.TemplateForm.controls['Content'].enable();
            this.TemplateForm.controls['Subject'].enable();
            this.TemplateForm.controls['TypeId'].enable();
          }
          if (nexPage == true) {
            check.click();
          }
        })
      }
      else {
        this.templateService.SaveEmailTemplate(this.TemplateForm.value)
        this.templateService.IsSaveEmailTemplate.subscribe(res => {
          if (nexPage == true) {
            var check = document.getElementById('stepperButton')
            check.click();
          }
          else {
            this._TemplatestepperComponent.onNoClick()
          }
          if (res && nexPage == false) {
            this.disableButton = true
            this.TemplateForm.controls['Name'].disable();
            this.TemplateForm.controls['Content'].disable();
            this.TemplateForm.controls['Subject'].disable();
            this.TemplateForm.controls['TypeId'].disable();
          }
          else {
            this.disableButton = false
            this.TemplateForm.controls['Name'].enable();
            this.TemplateForm.controls['Content'].enable();
            this.TemplateForm.controls['Subject'].enable();
            this.TemplateForm.controls['TypeId'].enable();
          }
          if (nexPage == true) {
            check.click();
          }
        })
      }
      this.templateService.isCompleted = true
      this.submitted = false;
    }
  }

  ChangeType() {
    let lovObject = this.templateService.lovType.filter(o => o.Id == this.TemplateForm.controls.TypeId.value)[0]
    if (lovObject.Name == "Marketing") {
      this.templateService.showNotificationDate = true
      this.templateService.showCampaignFields = true
    }
    else {
      this.templateService.showNotificationDate = false
      this.templateService.showCampaignFields = false
    }
    if (this.templateService.templateObject) {
      this.templateService.templateObject.TypeId = this.TemplateForm.controls.TypeId.value
    }
    else {
      this.templateService.TypeId = this.TemplateForm.controls.TypeId.value
    }
  }
}
