import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Register } from '@sharedmodels/BackModels/ComTrak/Setups/Register';
import { RegisterUser, SocialUser } from '@sharedmodels/BackModels/ComTrak/Security/SocialUser';
import { ExternalAuthService } from '../Security/externalauth.service';

@Injectable({
    providedIn: 'root'
})
export class RegisterationService {
    public isCallApi = new Subject<boolean>();
    public _data: any[] = [];

    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, public spinner: NgxSpinnerService,
        public router: Router, private _globalService: GlobalfunctionService, public authService: ExternalAuthService,) { }

    OnGet() {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            // this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'Register', { headers: this._Global.httpOptions })
                .subscribe((data: any) => {
                    this._data = data.Result ? data.Result as [] : null;
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    // this.isCallApi.next(true)
                },
                    error => {
                        this._Global.handleError(error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        // this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            // this.isCallApi.next(false)
        }
    }

    RegisterUser(body: Register) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Register/Save?getType=Register', body, { headers: this._Global.httpOptions })
                .subscribe((data: RegisterUser) => {
                    this.isCallApi.next(true)
                    this.Login(data)
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }


    Login(data: RegisterUser) {
        let socialUser: SocialUser = {
            accessToken: "",
            email: data.Email,
            id: data.Id,
            idToken: "",
            image: "",
            name: data.UserName,
            provider: "google"
        }
        this._globalService.socialUser = <SocialUser>socialUser;
        this.authService.ExternalLogin(this._globalService.socialUser)
            .subscribe((data: any) => {
                if (data === undefined || data === null) {
                    this._globalService.handleError('error at recieving response');
                    return;
                }
                const d = JSON.stringify(data);
                this._globalService.setData(d);
                this.router.navigate(['homepage']);
                this._globalService.IsReady = true
                this.isCallApi.next(true)
                this._Global.showSuccessMsg("Registered Successfully")
                this.spinner.hide()
                this._Global.IsReady = true;
            }, error => {
                this._globalService.handleError(error);
                this.spinner.hide()
                this._Global.IsReady = true;
            })
    }

    ExternalLogin(body: Register) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'Register/Save?getType=ExternalLogin', body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    // this.isCallApi.next(true)
                    this._Global.showSuccessMsg("Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        // this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            // this.isCallApi.next(false)
        }
    }
}
