import { SponsorService } from './../../../Shared/Services/BackServices/ComTrak/Setups/sponsor.service';
import { SiteService } from './../../../Shared/Services/BackServices/ComTrak/Setups/site.service';
import { StaticDataService } from '@sharedservices/BackServices/ComTrak/Setups/StaticData.service';
import { StudyService } from './../../../Shared/Services/BackServices/ComTrak/Setups/study.service';
import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { StudyDetailEnrollments } from '@sharedmodels/BackModels/ComTrak/Setups/StudyDetail';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { TherapeuticAreaService } from './../../../Shared/Services/BackServices/ComTrak/Therapeutic/therapeutic-area.service';
import { PrincipalInvestigatorService } from '@sharedservices/BackServices/ComTrak/Setups/principal-investigator.service';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { CRIOService } from '@sharedservices/BackServices/ComTrak/CRIO/crio.service';
import { Sponsor } from '@sharedmodels/BackModels/ComTrak/Setups/Sponsor';
import { UpdateArmIdModalComponent } from '../update-armid/update-armid.component';



@Component({
  selector: 'app-createstudy',
  templateUrl: './createstudy.component.html',
  styleUrls: ['./createstudy.component.scss', '../../style.component.scss'],
  
})
export class CreatestudyComponent implements OnInit {
  StudyForm: FormGroup
  submitted: boolean = false;
  public _data: Study;
  nestedDetailTargetError: boolean = false;
  isedit: boolean = false;
  isIndicationEnable: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettingsCRIO: IDropdownSettings = {
    singleSelection: true,
    idField: 'CRIOStudiesId',
    textField: 'Name',
    allowSearchFilter: true,
    enableCheckAll: false,
    
  };
  dropdownSettingsStudy: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    allowSearchFilter: true,
    enableCheckAll: false,
    
  };
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    maxHeight: 197,
    allowSearchFilter: true
  };
  public studySitesDetail: any[] = [];
  studySitesDetailEnrollments: any[] = [];
  DeletedStudyDetailEnrollments: any[] = [];
  displayRemoveIcon: boolean;
  errorMessage: string = "";
  _SponsorName: Lov[];
  _PhaseName: Lov[];
  _PatientPopulationName: Lov[];
  _StudyTypeName: Lov[];
  _IRBName: Lov[];
  _CRIOName: Lov[];
  _TherapeuticIndications: any[] = [];
  _PrincipalInvestigatorBySiteID: any[] = [];
  CRIOSiteStudies: any[];
  ParentStudies: any[];
  SelectedCRIOSiteStudies = [];
  activeStudies: any[]
  public IsStudyArm: boolean;
  constructor(public dialogRef: MatDialogRef<CreatestudyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public siteService: SiteService,
    public fb: FormBuilder, public studyService: StudyService, public staticDataService: StaticDataService, public lovService: LovService,public SponsorService: SponsorService,
    private _Global: GlobalfunctionService, public _TherapeuticAreaService: TherapeuticAreaService, public _PrincipalInvestigatorService: PrincipalInvestigatorService,
    public crioService: CRIOService,private dialog: MatDialog) { }

  ngOnInit() {    
    this._Global.gotoTop();
    this.initForm()
    this.AddstudySitesDetailArray(null);
    this.siteService.LoadActiveSites()
    this.siteService.GetAllPrincipalInvestigatorWithSiteId()
    this.getSponsorName()
    this.getPhaseName()
    this.getPatientPopulationName()
    this.getStudyTypeName()
    this.getIRBName()
    this.getCROName()
    //console.log(this.studySitesDetail);

    if (this.data.callList != undefined) {
     
      this.StudyForm.patchValue(this.data.callList)
      let crioSiteId = this.crioService._CRIOStudies.filter(o => o.CRIOStudiesId == this.data.callList.CRIOStudyId)
      if (crioSiteId && crioSiteId.length > 0) {
        this.StudyForm.controls.CRIOSiteId.setValue(crioSiteId[0].CRIOSiteId)
        this.ChangeCRIOSites()
        this.StudyForm.controls.CRIOStudyId.setValue(crioSiteId)
      }
      // this.StudyForm.controls.studySitesDetail.setValue(this.data.callList.studySites)
      if (this.data.callList.studySitesDetail && this.data.callList.studySitesDetail.length > 0) {
        this.studySitesDetail = []
     
       
        this.studySitesDetail = this.data.callList.studySitesDetail;
        this.studySitesDetail.forEach(x=>{
          let studyStatus = this.staticDataService.StudyStatus.filter(o => o.Id == x.StudyStatusId)[0]
          if (studyStatus != null && studyStatus != undefined){
           x.StudyStatus = studyStatus.Name
          }
         })
        this.showRemoveIcon()
        this.StudyArmChange()
        
        this.activeStudies = this.studyService._studiesActiveArray.filter(o=> o.Id != this.StudyForm.controls.Id.value)
      }
      else {
        this.studySitesDetail = []
        this.AddstudySitesDetailArray(null)
      }
      this.isedit = true
      this.StudyForm.controls.SponsorId.disable();
      this.StudyForm.controls.TherapeuticAreaId.disable();
      this.StudyForm.controls.TherapeuticAreaIndicationId.disable();
    }
    else {
      this.activeStudies = this.studyService._studiesActiveArray
      this.StudyForm.controls.CRIOSiteId.setValue(1139)
      this.ChangeCRIOSites()
      //isDisabled: true;  
      this.isedit = false
    }
  }

  initForm() {
    this.StudyForm = this.fb.group({
      Id: [''],
      Name: ['', Validators.required],
      CrioName: [''],//, Validators.required],
      SponsorId: [null, Validators.required],
      ProtocolNumber: ['', Validators.required],
      PhaseId: [null, Validators.required],
      TherapeuticAreaId: [null, Validators.required],
      TherapeuticAreaIndicationId: [null, Validators.required],
      CROId: [null],
      PatientPopulationId: [null],
      StudyTypeId: [null],
      IRBId: [null],
      IsActive: [false],
      studySitesDetail: [],
      CRIOStudyId:  [],
      CRIOSiteId: [null, Validators.required],
      selectedItems: [],
      IsStudyArm: [false],
      SelectedParentStudy: [],
      ParentStudyId: [0]
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.StudyForm.controls;
  }

  onSubmit() {

    this.checkNestedDataTargetCompleted();
    if (this.studySitesDetail && this.studySitesDetail.length && this.studySitesDetail.length > 0) {
      this.studySitesDetail.map(element => {
       
        let crioStudyId = 0;
        if (element.CRIOStudy != undefined){
          if (element.CRIOStudy.length > 0){
          crioStudyId = element.CRIOStudy[0].CRIOStudiesId
          }
        }
        element.StudyDetailId = element.Id
        element.CRIOStudyId = crioStudyId;//element.CRIOStudy.length > 0 && element.CRIOStudy != null && element.CRIOStudy != undefined ? element.CRIOStudy[0].CRIOStudiesId : 0;
        
        let studyStatus;
        if (element.StudyStatus != undefined && element.StudyStatus != null){
          studyStatus = this.staticDataService.StudyStatus.filter(o => o.Name.toLowerCase() == element.StudyStatus.toLowerCase())[0];
        }
        
        element.StudyStatusId = studyStatus != null && studyStatus != undefined ? studyStatus.Id : null;
      })
    }
    this.StudyForm.controls.studySitesDetail.setValue(this.studySitesDetail)
    this.submitted = true;
    if (this.StudyForm.invalid) {
      this.checkTableValidation()
      return;
    }
    else {
      let checkValidation = this.checkTableValidation()
      if (checkValidation || this.nestedDetailTargetError) {
        return;
      }
      else {
        // this.changeValueToNumber()
        // console.log("Condition # 2")
        // let siteIDarray = []
        // if (this.StudyForm.controls.studySitesDetail.value && this.StudyForm.controls.studySitesDetail.value.length > 0) {
        //   this.StudyForm.controls.studySitesDetail.value.forEach(element => {
        //     siteIDarray.push({ SiteID: element.Id })
        //   });
        //   this.StudyForm.controls.studySitesDetail.setValue(this.studySitesDetail)
        // }
        this.StudyForm.controls.studySitesDetail.setValue(this.studySitesDetail)
        let Id = this.data.callList ? this.data.callList.Id : 0
        this.StudyForm.controls.Id.setValue(Id)
       
         let CRIOStudyId = 0;//this.StudyForm.controls.CRIOStudyId.value
        this.StudyForm.controls.CRIOStudyId.setValue(CRIOStudyId)
        if(this.StudyForm.controls.IsStudyArm.value){
          if(this.StudyForm.controls.SelectedParentStudy.value[0] != undefined){
            this.StudyForm.controls.ParentStudyId.setValue(this.StudyForm.controls.SelectedParentStudy.value[0].Id)
          }
          else{
            this._Global.showErrorMsg('Please select a Parent Study')
            return 
          }
        }
        else{
          this.StudyForm.controls.ParentStudyId.setValue(0)
        }
        
        this.studyService.SaveAndEditStudy(Id, this.StudyForm.value, this.isedit)
       
        this.studyService.isCallApi.subscribe(res => {
          if (res) {
            this.studySitesDetail = []
            this.AddstudySitesDetailArray(null)
            this.crioService.GetCRIOStudiesByComtrekSite('');
            this.onNoClick()
            this.studyService.isCallApi.next(false)
          }
        })
     
        //this.crioService.GetCRIOStudies('');
        //this.crioService.GetCRIOStudiesByComtrekSite('');
      }
    }
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[0-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytext(event): boolean {
    var Regexpattern = /[a-zA-Z0-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytextnumber(event): boolean {
    var Regexpattern = /[a-zA-Z-0-9 ]/;
    var result = Regexpattern.test(event.key);
    return result;
  }
  Onlytextspecail(event): boolean {
    var Regexpattern = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
    var result = Regexpattern.test(event.key);
    return result;
  }

  AddstudySitesDetailArray(index: number) {
   
       // if(CRIOStudy !== null && CRIOStudy !== undefined){
    //   this.SelectedCRIOSiteStudies = [CRIOStudy]
    // }
    if (index != null) {
    
      let checkValidate = this.checkTableValidation()
      if (checkValidate == true) {
        return
      }
      else {
        this.studySitesDetail.push({
          StudyDetailId: 0,
          SiteID: 0,
          PrincipleInvestigator: null,
          TargetEnrollment: null,
          EnrollStartDate: null,
          EnrollEndtDate: null,
          StudyDetailEnrollments: [],
          CRIOStudyId: 0,     
        })
      }
    }
    else {
      if (this.errorMessage == "") {
        this.studySitesDetail.push({
          StudyDetailId: 0,
          SiteID: 0,
          PrincipleInvestigator: null,
          TargetEnrollment: null,
          EnrollStartDate: null,
          EnrollEndtDate: null,
          StudyDetailEnrollments: [],
          CRIOStudyId: 0,         
        })
      }
      else {
        this._Global.showErrorMsg(this.errorMessage)
        return;
      }
    }

  }

  showRemoveIcon() {
    if (this.studySitesDetail.length === 1) {
      this.displayRemoveIcon = false;
    } else {
      this.displayRemoveIcon = true;
    }
  }

  showNestedRemoveIcon(index, nestedIndex) {
    var Data = this.studySitesDetail[index].StudyDetailEnrollments;
    if ((Data.length - 1).toString() == nestedIndex.toString()) {
      return true;
    }
    else {
      return false;
    }
  }

  SplicestudySitesDetailArray(index: number) {
    if (this.studySitesDetail.length == 1) {
      return;
    }
    else {
      this.studySitesDetail.splice(index, 1)
    }
  }

  checkTableValidation() {
    this.studySitesDetail = this.studySitesDetail
    // let lastObject = this.studySitesDetail[this.studySitesDetail.length - 1]
    // ;
    // var checkValidate = lastObject.SiteID == 0 || lastObject.SiteID == undefined || lastObject.PrincipalInvestigatorId == "" || lastObject.PrincipalInvestigatorId == undefined || lastObject.TargetEnrollment == ""
    //   || lastObject.EnrollStartDate == "" || lastObject.EnrollEndtDate == "" || lastObject.CRIOStudyId == "" || lastObject.CRIOStudyId == undefined || lastObject.CRIOStudyId == 0
    // console.log(lastObject.CRIOStudyId);
    //   if (checkValidate) {
    //   let index = this.studySitesDetail.findIndex(o => o.SiteID == lastObject.SiteID)
    //   let errorMessage = `CRIO Study, PI , Enrollment Window Detail ${index + 1} Is Required`
    //   this._Global.showErrorMsg(errorMessage)
    //   return true
    // }
    // else {
    //   return false
    // }
    let CrioStudyIds = [];
    for (let i = 0; i < this.studySitesDetail.length; i++) {
    // this.studySitesDetail.forEach(
    //   (item, index) => {
      if (this.studySitesDetail[i].CRIOStudy != undefined){
      if (this.studySitesDetail[i].CRIOStudy.length > 0){
          CrioStudyIds.push(this.studySitesDetail[i].CRIOStudy[0].CRIOStudiesId); 
      }
    }
       
        if (this.studySitesDetail[i].SiteID == 0 || this.studySitesDetail[i].SiteID == undefined){
        //|| this.studySitesDetail[i].CRIOStudy[0].CRIOStudiesId == "" || this.studySitesDetail[i].CRIOStudy[0].CRIOStudiesId == undefined || this.studySitesDetail[i].CRIOStudy[0].CRIOStudiesId == 0){
          let errorMessage = `PI , Enrollment Window Detail ${i + 1} Is Required`
          this._Global.showErrorMsg(errorMessage)
          
          return true
          
        }
      }
      if (CrioStudyIds.length > 0){
        const doesArrayHaveDuplicates = CrioStudyIds.some(
          (val, i) => CrioStudyIds.indexOf(val) !== i
        ) 
        if (doesArrayHaveDuplicates){
          let errorMessage = `Cannot Insert Duplicate CRIO Study`
            this._Global.showErrorMsg(errorMessage)
          return true;
        }
      }
      return false
  }

  ChangeSite(indexNumber: number, siteId: number) {
    if (this.studySitesDetail.length > 1) {
      let siteObject = this.studySitesDetail.filter((value, index) => value.SiteID == siteId && indexNumber !== index)[0]
      let siteObjectData = this.siteService._activeSites.filter(o => o.Id == siteId)[0];
      if (siteObject) {
        this.errorMessage = `Site ${siteObjectData.Name} is already selected at line : ${indexNumber}`
        this._Global.showErrorMsg(this.errorMessage)
        this.studySitesDetail[indexNumber].SiteID = undefined
      }
      else {
        this.errorMessage = ""
      }
    }
  }

  getPrincipalInvestigatorsBySiteID(siteID?: number) {
    if (siteID != null || siteID != undefined) {
      if (this.siteService._allPrincipalInvestigatorWithSite.length > 0 && this.siteService._allPrincipalInvestigatorWithSite != undefined) {
        return this.siteService._allPrincipalInvestigatorWithSite.filter(
          x => x.SiteId.toString() == siteID.toString()
        )
      }
      else {
        return [];
      }
    }
    else {
      return [];
    }
  }

  checkNestedDataTargetCompleted() {
    this.nestedDetailTargetError = false;
    var errorFullIndex = -1;
    var isError: boolean = false;
    this.studySitesDetail.forEach(
      (item, index) => {
        var parentDataCopy = JSON.parse(JSON.stringify(item));
        var remainingTarget = Number(parentDataCopy.TargetEnrollment) - parentDataCopy.StudyDetailEnrollments.reduce((accumulator, object) => {
          return accumulator + Number(object.TargetEnrollment);
        }, 0);
        if (item.StudyDetailEnrollments.length > 0) {
          if (remainingTarget == 0) {
          }
          else if (remainingTarget > 0) {
            errorFullIndex = index;
            isError = true;
          }
        }
      }
    )
    if (errorFullIndex != -1) {
      this.nestedDetailTargetError = true;
      this._Global.showErrorMsg(`Enrollment Window ${errorFullIndex + 1} Target are Not Fully Distributed Among Nested Detail !`)
    }
  }

  changeNestedTargetEnrollment(event: any, index: number, indexOfNested: any) {
    var parentData = this.studySitesDetail[index];
    var parentDataCopy = JSON.parse(JSON.stringify(this.studySitesDetail[index]))
    var childData = parentData.StudyDetailEnrollments[indexOfNested];
    parentDataCopy.StudyDetailEnrollments.splice(indexOfNested, 1)

    var remainingTarget = Number(parentDataCopy.TargetEnrollment) - parentDataCopy.StudyDetailEnrollments.reduce((accumulator, object) => {
      return accumulator + Number(object.TargetEnrollment);
    }, 0);

    if (childData.TargetEnrollment <= 0) {
      childData.TargetEnrollment = remainingTarget
    }
    if (remainingTarget <= 0) {
      this.studySitesDetail[index].StudyDetailEnrollments.splice(indexOfNested, 1)
    }
    if (childData.TargetEnrollment > remainingTarget) {
      childData.TargetEnrollment = remainingTarget
    }
  }

  ChangeStartDate(index: number, indexOfNested?: any, type?: string) {
    if (this.studySitesDetail[index].EnrollStartDate != "" && this.studySitesDetail[index].EnrollEndtDate != "" && type != 'Nested') {
      if (this.studySitesDetail[index].EnrollStartDate > this.studySitesDetail[index].EnrollEndtDate) {
        this._Global.showErrorMsg("Start Date cannot be greater than End Date ")
        this.studySitesDetail[index].EnrollStartDate = new Date().toString()
        this.studySitesDetail[index].EnrollEndtDate = new Date().toString()
      }
    }

    else if (type == "Nested") {
      var parentData = this.studySitesDetail[index];
      var childAllData = parentData.StudyDetailEnrollments;
      var childData = parentData.StudyDetailEnrollments[indexOfNested];

      if ((childData.StartDate < parentData.EnrollStartDate) || (childData.StartDate >= childData.EndDate)) {
        if (childAllData.length == 1 || indexOfNested == 0) {
          childData.StartDate = new Date(parentData.EnrollStartDate + "T00:00:00");
        }
        else {
          childData.StartDate = new Date(childAllData[indexOfNested - 1].EndDate + "T00:00:00");
          childData.StartDate.setDate(childData.StartDate.getDate() + 1);
        }
        this._Global.showErrorMsg("Sub Enrollment Detail Start Date Can Neither Be Less Then Enrollment Window Start Date Nor ( Greater Than or Equal ) to End Date")
      }
      else if (childAllData.length > 0) {
        if (childData.StartDate <= childAllData[indexOfNested - 1].EndDate) {
          childData.StartDate = new Date(childAllData[indexOfNested - 1].EndDate + "T00:00:00");
          childData.StartDate.setDate(childData.StartDate.getDate() + 1);
          this._Global.showErrorMsg("Start Date Cannot Be Less than or Equal to End Date of Previous Sub Enrollment Detail")
        }
      }
    }
  }

  ChangeEndDate(index: number, indexOfNested?: any, type?: string) {
    if (this.studySitesDetail[index].EnrollStartDate != "" && this.studySitesDetail[index].EnrollEndtDate != "" && type != 'Nested') {
      if (this.studySitesDetail[index].EnrollEndtDate < this.studySitesDetail[index].EnrollStartDate) {
        this._Global.showErrorMsg("End Date cannot be less than Start Date ")
        this.studySitesDetail[index].EnrollStartDate = new Date().toString()
        this.studySitesDetail[index].EnrollEndtDate = new Date().toString()
      }
    }
    else if (type == "Nested") {
      var parentData = this.studySitesDetail[index];
      var childAllData = parentData.StudyDetailEnrollments;
      var childData = parentData.StudyDetailEnrollments[indexOfNested];

      if ((childData.EndDate > parentData.EnrollEndtDate) || (childData.StartDate >= childData.EndDate)) {
        if (childAllData.length == 1 || indexOfNested == (childAllData.length - 1)) {
          childData.EndDate = new Date(parentData.EnrollEndtDate + "T00:00:00");
        }
        else {
          childData.EndDate = new Date(childAllData[indexOfNested + 1].StartDate + "T00:00:00");
          childData.EndDate.setDate(childData.EndDate.getDate() - 1);
        }
        this._Global.showErrorMsg("Sub Enrollment Detail End Date Can Neither Be Greater Then Enrollment Window EndDate Nor (Less than or Equals) to Start Date")
      }
      else if (childAllData.length > 0 && indexOfNested != (childAllData.length - 1)) {
        if (childData.EndDate >= childAllData[indexOfNested + 1].StartDate) {
          childData.EndDate = new Date(childAllData[indexOfNested + 1].StartDate + "T00:00:00");
          childData.EndDate.setDate(childData.EndDate.getDate() - 1);
          this._Global.showErrorMsg("End Date Cannot Be Greater than or Equal to Start Date of Next Sub Enrollment Detail")
        }
      }
    }
  }

  filterTherapeutiArea() {
    var therapeuticAreaData = this._TherapeuticAreaService._therapeuticArea;
    if (therapeuticAreaData != null && therapeuticAreaData != undefined) {
      if (this.isedit == false) {
        return therapeuticAreaData.filter(o => o.IsActive == true)
      }
      else {
        var checkIfAreaIsActive = this._TherapeuticAreaService._therapeuticAreaActive.filter(x => x.Id == this.StudyForm.controls.TherapeuticAreaId.value);
        if (checkIfAreaIsActive.length > 0) {
          return therapeuticAreaData.filter(o => o.IsActive == true)
        }
        else {
          return therapeuticAreaData.filter(o => o.IsActive == true || o.Id == this.StudyForm.controls.TherapeuticAreaId.value)
        }
      }
    }
    else {
      return [];
    }
  }

  filterTherapeuticIndication() {
    if (
      this.StudyForm.controls.TherapeuticAreaId.value != null &&
      this.StudyForm.controls.TherapeuticAreaId.value != undefined &&
      this.StudyForm.controls.TherapeuticAreaId.value != "" &&
      this._TherapeuticAreaService._therapeuticArea != undefined
    ) {
      var indicationsOptionsData = this._TherapeuticAreaService._therapeuticArea.filter(x => x.Id == this.StudyForm.controls.TherapeuticAreaId.value);
      indicationsOptionsData = indicationsOptionsData.length > 0 ? indicationsOptionsData[0].TherapeuticAreaIndications : [];
      if (indicationsOptionsData.length > 0) {
        if (indicationsOptionsData.filter(o => o.IsActive == true).length > 0) {
          if(this.isedit == false) {
            this.StudyForm.controls.TherapeuticAreaIndicationId.enable();
          }
        }
        if (this.isedit == false) {
          return indicationsOptionsData = indicationsOptionsData.filter(o => o.IsActive == true)
        }
        else {
          if (this.StudyForm.controls.TherapeuticAreaIndicationId.value == undefined
            ||
            this.StudyForm.controls.TherapeuticAreaIndicationId.value == null
            ||
            this.StudyForm.controls.TherapeuticAreaIndicationId.value == ""
          ) {
            return indicationsOptionsData = indicationsOptionsData.filter(o => o.IsActive == true)
          }
          else {
            //this.StudyForm.controls.TherapeuticAreaIndicationId.enable();
            return indicationsOptionsData = indicationsOptionsData.filter(o => o.IsActive == true || o.Id == this.StudyForm.controls.TherapeuticAreaIndicationId.value)
          }
        }
      }
      else {
        this.StudyForm.controls.TherapeuticAreaIndicationId.disable();
      }
      return indicationsOptionsData
    }
    else {
      this.StudyForm.controls.TherapeuticAreaIndicationId.disable();
      return null;
    }
  }

  addStudyDetailEnrollment(id: any, index: any) {

    if (this.checkNestedRowsValidation(index) == true) {
      return false;
    }
    var obj: StudyDetailEnrollments = {
      TargetEnrollment: null,
      StartDate: null,
      EndDate: null,
      //CRIOStudyId: null,
    }
    var parentData = this.studySitesDetail[index];

    if (parentData.StudyDetailEnrollments.length == 0) {
      obj.TargetEnrollment = parentData.TargetEnrollment
      obj.StartDate = parentData.EnrollStartDate + "T00:00:00";
      obj.EndDate = parentData.EnrollEndtDate + "T00:00:00";
     // obj.EndDate = parentData.CRIOStudyId;
      parentData.StudyDetailEnrollments.push(obj)
    }
    else {
      var remainingTarget = Number(parentData.TargetEnrollment) - parentData.StudyDetailEnrollments.reduce((accumulator, object) => {
        return accumulator + Number(object.TargetEnrollment);
      }, 0);
      obj.TargetEnrollment = remainingTarget
      obj.StartDate = parentData.StudyDetailEnrollments[parentData.StudyDetailEnrollments.length - 1].EndDate + "T00:00:00";

      var result = new Date(obj.StartDate);
      result.setDate(result.getDate() + 1);

      obj.StartDate = result.toString();
      obj.EndDate = parentData.EnrollEndtDate + "T00:00:00";
      //obj.CRIOStudyId = parentData.CRIOStudyId;
      parentData.StudyDetailEnrollments.push(obj)
    }
  }

  removeNestedEnrollment(indexOfEnrollment: any, indexOfNestedEnrollment: any, nestedDetailRow: any) {
    var selectedStudyDetails = this.studySitesDetail[indexOfEnrollment];
    var selectedEnrollment = selectedStudyDetails.StudyDetailEnrollments[indexOfNestedEnrollment];

    if (nestedDetailRow.Id == undefined || nestedDetailRow.Id == null || nestedDetailRow.Id == "") {
      selectedStudyDetails.StudyDetailEnrollments.splice(indexOfNestedEnrollment, 1)
    }
    else {
      if (selectedStudyDetails.DeletedStudyDetailEnrollments == undefined || selectedStudyDetails.DeletedStudyDetailEnrollments == null) {
        selectedStudyDetails.DeletedStudyDetailEnrollments = [];
        selectedStudyDetails.DeletedStudyDetailEnrollments.push(selectedEnrollment);
        selectedStudyDetails.StudyDetailEnrollments.splice(indexOfNestedEnrollment, 1)
      }
      else {
        selectedStudyDetails.DeletedStudyDetailEnrollments.push(selectedEnrollment);
        selectedStudyDetails.StudyDetailEnrollments.splice(indexOfNestedEnrollment, 1)
      }
    }
  }

  checkNestedRowsValidation(index: any) {
   
    let lastObject = this.studySitesDetail[index]
    var checkValidate = lastObject.SiteID == 0 || lastObject.SiteID == undefined || (lastObject.CRIOStudyId == "" || lastObject.CRIOStudyId == undefined || lastObject.CRIOStudyId == 0)
    if (checkValidate) {
      let errorMessage = `Enrollment Window Detail ${index + 1} Is Required To Add Nested Details`
      this._Global.showErrorMsg(errorMessage)
      return true
    }
    else if (isNaN(lastObject.TargetEnrollment) == true) {
      let errorMessage = `Enrollment Window Detail ${index + 1} Target Enrollment Is Invalid Number`
      this._Global.showErrorMsg(errorMessage)
      return true
    }
    else {
      let DetailObject = this.studySitesDetail[index].StudyDetailEnrollments
      if (DetailObject.length > 0) {
        var lastDetailObject = DetailObject[DetailObject.length - 1];
        var checkValidate2 =
          (lastDetailObject.StartDate == "" || lastDetailObject.StartDate == undefined || lastDetailObject.StartDate == null)
          ||
          (lastDetailObject.EndDate == "" || lastDetailObject.EndDate == undefined || lastDetailObject.EndDate == null)
          ||
          (lastDetailObject.TargetEnrollment == "" || lastDetailObject.TargetEnrollment == undefined || lastDetailObject.TargetEnrollment == null)
          ||
          (lastDetailObject.CRIOStudyId == "" || lastDetailObject.CRIOStudyId == undefined || lastDetailObject.CRIOStudyId == null);;

        if (checkValidate2) {
          let errorMessage = `Enrollment Window Detail ${index + 1} Last Nested Details Information is Required`
          this._Global.showErrorMsg(errorMessage)
          return true
        }
        else {
          var remainingTarget = Number(lastObject.TargetEnrollment) - lastObject.StudyDetailEnrollments.reduce((accumulator, object) => {
            return accumulator + Number(object.TargetEnrollment);
          }, 0);
          if (Number(remainingTarget) == 0 || Number(remainingTarget) < 0) {
            let errorMessage = `Sub Enrollment Target Is Already Equal To Enrollment Target`
            this._Global.showErrorMsg(errorMessage)
            return true
          }
          else if (lastObject.EnrollEndtDate == lastDetailObject.EndDate) {
            let errorMessage = `Sub Enrollment Target End Date is Already Equal Enrollment End Date`
            this._Global.showErrorMsg(errorMessage)
            return true
          }
          else {
            return false
          }
        }
      }
      else {
        return false
      }
    }
  }

  getSponsorName() {
    this.SponsorService.LoadSponsors().subscribe((res: any) => {
      this._SponsorName = <Sponsor[]>res.Data;
  }, error => {
      this._Global.handleError(error);
  });
}

  getPhaseName() {
    this.lovService.getLovDataById(LovStatusMasterID.Phase).subscribe(res => {
      this._PhaseName = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  getPatientPopulationName() {
    this.lovService.getLovDataById(LovStatusMasterID.PatientPopulation).subscribe(res => {
      this._PatientPopulationName = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  getStudyTypeName() {
    this.lovService.getLovDataById(LovStatusMasterID.StudyType).subscribe(res => {
      this._StudyTypeName = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  getIRBName() {
    this.lovService.getLovDataById(LovStatusMasterID.IRB).subscribe(res => {
      this._IRBName = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  getCROName() {
    this.lovService.getLovDataById(LovStatusMasterID.CRO).subscribe(res => {
      this._CRIOName = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  ChangeCRIOSites() {
    this.StudyForm.controls.CRIOStudyId.setValue(null)
    
    let Id = this.data.callList ? this.data.callList.Id : 0
      if (Id != 0){
        this.CRIOSiteStudies = this.crioService._CRIOStudies.filter(o => o.CRIOSiteId == this.StudyForm.controls.CRIOSiteId.value);
      }
      else {
        this.CRIOSiteStudies = this.crioService._CRIOStudies.filter(o => o.CRIOSiteId == this.StudyForm.controls.CRIOSiteId.value && o.AlreadyMapped == false);
      }
  }

   
  ChangeCRIOStudy(indexNumber: number, CRIOStudy: any){
    if (CRIOStudy != null && CRIOStudy != undefined && CRIOStudy.length > 0){
      if (this.studySitesDetail.length > 0) {
        let siteId = this.studySitesDetail[indexNumber].SiteID;
        let crioStudyId=CRIOStudy[0].CRIOStudiesId;
        let studyId= this.studySitesDetail[indexNumber].StudyID;

        if(siteId!=undefined && crioStudyId!=undefined && studyId!=undefined){
          this.studyService.CheckStudyDetailsExistsAgainstCrioStudy(crioStudyId,siteId,studyId).
          subscribe((res) => {
            if (res && res.length > 0) {
              this.studySitesDetail[indexNumber].AllowUpdateArm = res[0].AllowupdateStudyArm;
              this.studySitesDetail[indexNumber].NoOfVisits = res[0].NoOfVisits;
              this.studySitesDetail[indexNumber].CrioStudyArmId = res[0].CrioStudyArmId;
            } else {
              // If no data returned, set the properties to undefined
              this.studySitesDetail[indexNumber].AllowUpdateArm = null;
              this.studySitesDetail[indexNumber].NoOfVisits = null;
              this.studySitesDetail[indexNumber].CrioStudyArmId = null;
          }}, error => {
           
            this.studySitesDetail[indexNumber].AllowUpdateArm = null;
            this.studySitesDetail[indexNumber].NoOfVisits = null;
            this.studySitesDetail[indexNumber].CrioStudyArmId = null;
          });         
        }
        else{
          this.studySitesDetail[indexNumber].AllowUpdateArm=false;
        }

      let CRIOStudies = this.crioService._CRIOStudies.filter(o => o.CRIOStudiesId == CRIOStudy[0].CRIOStudiesId)[0];
      
        if (CRIOStudies != null){
          if (CRIOStudies.StudyStatus == "Enrolling New" || CRIOStudies.StudyStatus == "Enrolling Updated"){
            CRIOStudies.StudyStatus = "Enrolling"
          }
          let studyStatus = this.staticDataService.StudyStatus.filter(o => o.Name.toLowerCase() == CRIOStudies.StudyStatus.toLowerCase())[0];
          if (studyStatus != null && studyStatus != undefined){
           this.studySitesDetail[indexNumber].StudyStatus = studyStatus.Name;
          }
          else{
            this.studySitesDetail[indexNumber].StudyStatus = "";
          }

          this.studySitesDetail[indexNumber].CRIOStudyId=CRIOStudies.CRIOStudiesId;
        }
      }
    }
    else {
      this.studySitesDetail[indexNumber].StudyStatus = "";
      this.studySitesDetail[indexNumber].CRIOStudyId = null;
    }
     
    //  let siteObject = this.studySitesDetail.filter((value, index) => value.SiteID == siteId && indexNumber !== index)[0]
    //   let siteObjectData = this.siteService._activeSites.filter(o => o.Id == siteId)[0];
    //   if (siteObject) {
    //     this.errorMessage = `Site ${siteObjectData.Name} is already selected at line : ${indexNumber}`
    //     this._Global.showErrorMsg(this.errorMessage)
    //     this.studySitesDetail[indexNumber].SiteID = undefined
    //   }
    //   else {
    //     this.errorMessage = ""
    //   }
    
  }
  // changeValueToNumber(){
  //   this.StudyForm.controls.PatientPopulationId.setValue(Number(this.StudyForm.controls.PatientPopulationId.value))
  //   this.StudyForm.controls.StudyTypeId.setValue(Number(this.StudyForm.controls.StudyTypeId.value))
  //   this.StudyForm.controls.IRBId.setValue(Number(this.StudyForm.controls.IRBId.value))
  //   this.StudyForm.controls.TherapeuticAreaId.setValue(Number(this.StudyForm.controls.TherapeuticAreaId.value))
  //   this.StudyForm.controls.TherapeuticAreaIndicationId.setValue(Number(this.StudyForm.controls.TherapeuticAreaIndicationId.value))
  //   this.StudyForm.controls.CROId.setValue(Number(this.StudyForm.controls.CROId.value))
  //   this.StudyForm.controls.CROId.setValue(Number(this.StudyForm.controls.CROId.value))
  // }
  StudyArmChange() {
   
    if (this.StudyForm.controls.IsStudyArm.value != null && this.StudyForm.controls.IsStudyArm.value != undefined) {
      if (this.StudyForm.controls.IsStudyArm.value == true) {
        this.IsStudyArm = true
        this.StudyForm.get('ParentStudyId').setValidators(Validators.required);
      } else {
        this.IsStudyArm = false
        this.StudyForm.get('ParentStudyId').clearValidators();
      }
      this.StudyForm.get('ParentStudyId').updateValueAndValidity();
    }
  }
  openUpdateArmIdModal(item): void {
    var dialogRef =this.dialog.open(UpdateArmIdModalComponent, {
      panelClass: 'Updatearmiddailogwidth',
      data: {
        ...item
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        var index=this.data.callList.studySitesDetail.findIndex(t=>t.Id==result.Id);
        this.data.callList.studySitesDetail[index].CrioStudyArmId=result.CrioStudyArmId;
      }
    });
    
  }
}
