import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { PrincipalInvestigator } from "@sharedmodels/BackModels/ComTrak/Setups/PrincipalInvestigator";
import { PrincipalInvestigatorService } from "@sharedservices/BackServices/ComTrak/Setups/principal-investigator.service";

@Component({
  selector: "app-create-principal-investigator",
  templateUrl: "./create-principal-investigator.component.html",
  styleUrls: ["./create-principal-investigator.component.scss"],
})
export class CreatePrincipalInvestigatorComponent implements OnInit {
  PrincipalInvestigatorForm: FormGroup;
  submitted: boolean = false;
  isedit: boolean = false;
  actionType: string;
  disbaledFields: boolean;
  format: string = "M/d/yyyy";

  constructor(
    public dialogRef: MatDialogRef<CreatePrincipalInvestigatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public _Global: GlobalfunctionService,
    public _PrincipalInvestigatorService: PrincipalInvestigatorService
  ) {}

  ngOnInit() {
    this.actionType = this.data.actionType;
    this.initForm();
    if (this.data.principalInvestigatorData != undefined) {
      this.isedit = true;
      this.setPrincipalInvestigatorData(this.data.principalInvestigatorData);
    } else {
      this.isedit = false;
    }
  }

  initForm() {
    this.PrincipalInvestigatorForm = this.fb.group({
      Id: [0, Validators.required],
      Name: ["", Validators.required],
      Phone: ['', [Validators.required, Validators.pattern(/^(2|3|4|5|6|7|8|9)\d{9}$/)]],
      Specialization: ["", Validators.required],
      NPI: ["", Validators.required],
      ML: ["", Validators.required],
      Email: ["", [Validators.required,Validators.email]],
      ExpiryDate: ["", Validators.required],
      IsActive: [false],
    });
  }

  setPrincipalInvestigatorData(data: PrincipalInvestigator) {
    this.PrincipalInvestigatorForm.controls.Name.setValue(data.Name);
    this.PrincipalInvestigatorForm.controls.Phone.setValue(data.Phone);
    this.PrincipalInvestigatorForm.controls.Specialization.setValue(
      data.Specialization
    );
    this.PrincipalInvestigatorForm.controls.Email.setValue(
      data.Email
    );
    this.PrincipalInvestigatorForm.controls.NPI.setValue(data.NPI);
    this.PrincipalInvestigatorForm.controls.ML.setValue(data.ML);
    this.PrincipalInvestigatorForm.controls.ExpiryDate.setValue(
      data.ExpiryDate
    );
    if (data.IsActive != undefined && data.IsActive != null) {
      this.PrincipalInvestigatorForm.controls.IsActive.setValue(data.IsActive);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga("send", "event", {
    //   eventCategory: "click",
    //   eventLabel: "buttonClick",
    //   eventAction: "onNoClick",
    // });
  }

  Onlynumber(event): boolean {
    var Regexpattern = /[0-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  Onlytext(event): boolean {
    var Regexpattern = /[a-zA-Z0-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  OnlyPhonenumber(event): boolean {
    var Regexpattern = /[2-9]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  get f() {
    return this.PrincipalInvestigatorForm.controls;
  }

  onSubmit() {
    // console.log("From Control Value 1: ", this.PrincipalInvestigatorForm.value);
    this.submitted = true;
    if (this.PrincipalInvestigatorForm.invalid) {
      return;
    } else {
      let Id = this.data.principalInvestigatorData
        ? this.data.principalInvestigatorData.Id
        : 0;
      // if (this.data.principalInvestigatorData?.IsActive == undefined) {
      //   this.data.principalInvestigatorData.IsActive = false;
      // }
      this.PrincipalInvestigatorForm.controls.Id.setValue(Id);

      // console.log(
      //   "From Control Value 2: ",
      //   this.PrincipalInvestigatorForm.value
      // );

      this._PrincipalInvestigatorService.AddOrEditPrincipalInvestigator(
        this.PrincipalInvestigatorForm.value,
        this.isedit
      );
      this._PrincipalInvestigatorService.isCallApi.subscribe((res) => {
        if (res) {
          this.onNoClick();
          this._PrincipalInvestigatorService.isCallApi.next(false);
        }
      });
    }
  }
}
