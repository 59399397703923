import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
    imports: [ 
        RouterModule, 
        CommonModule, 
        MatButtonModule,
        MatMenuModule,
        MatDividerModule, 
        MatIconModule,
        MatProgressSpinnerModule 
    ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ]
})
export class NavbarModule {}
