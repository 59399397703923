import { Component, Inject, Input, OnChanges, OnInit, Optional, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material'; 
import { DeclinependingreviewComponent } from '../declinependingreview/declinependingreview.component';
import { Subscription } from 'rxjs';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { DatePipe } from '@angular/common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PreviewPdfComponent } from '../previewpdf/previewpdf.component';
import { isArray } from 'jquery';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { patientVariables } from '@shared/Constants';
import { HelperService } from '@sharedservices/helper.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-previewprescreenbuilderform',
  templateUrl: './previewprescreenbuilderform.component.html',
  styleUrls: ['./previewprescreenbuilderform.component.scss'],
  providers: [DatePipe]
})
export class PreviewPrescreenBuilderFormComponent implements OnInit, OnChanges {
  previewscreenForm: FormGroup;
  myData:any;
  freeTextBox = ['Free text (Alpha & Numeric)', 'Free text (Numeric only)'];
  @Input() previewData = null;
  @Input() type = null;
  @Input() formDetails = null;
  @Input() formId = null;
  @Input() fromMarkInteraction = false;
  @Input() callListData = null;
  @Output() saveCallSuccess: EventEmitter<{flag: boolean}> = new EventEmitter();
  @Output() screenHeightChange: EventEmitter<boolean> = new EventEmitter();
  isGroupVisible = true;
  sub: Subscription;
  subTypeVariables: any[] = patientVariables;
  patientDetails: any;

  
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private scriptService: ScriptService,
    private datePipe: DatePipe,
    private helperService: HelperService,
    private _globalService: GlobalfunctionService,
    @Optional() public dialogRef: MatDialogRef<PreviewPrescreenBuilderFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.sub = new Subscription();
  }

  ngOnChanges(): void {
    this.fromMarkInteraction ? this.createPreviewForm() : null;
  }
  
  ngOnInit() {
    console.log("callListData =====>>>", this.callListData);
    this.myData= this.data.description
    this.createPreviewForm();
  }

  createPreviewForm() {
    this.isGroupVisible = true;
    if (this.previewData || (this.data && this.data.isDialog)) {
      (this.data && this.data.isDialog) ? (this.previewData = this.data.formArray) : null;
      this.previewscreenForm = this.fb.group({
        questionArray: this.fb.array([])
      });

      if(!!this.previewData.length) {
        this.previewData.forEach((res, i) => this.addNewQuestion(res, i));
      }
    } else {
      this.previewscreenForm = this.fb.group({
        questionArray: this.data.formArray
      });
      this.questionArray.controls.forEach(control => {
        const regex = /{{[^{}]+}}/g;
        regex.test(control.get('questionValue').value) ? control.get('isVariable').setValue(true) : control.get('isVariable').setValue(false);
      })
    }

    this.questionArray.controls.forEach((control) => {
      control.get('questionName').enable();
      control.get('questionType').enable();
      control.get('description').enable();
      this.data && !this.data.formPreview ? control.get('questionValue').disable() : ((control.get('isVariable').value && (control.get('questionValue').value || control.get('questionValue').value == 0)) ? control.get('questionValue').disable() : control.get('questionValue').enable());
      this.data && !this.data.formPreview ? control.get('questionValueArray').disable() : control.get('questionValueArray').markAsUntouched();
      this.data && (this.data.formPreview || this.fromMarkInteraction) ? control.get('questionValue').markAsUntouched() : null;
    })
  }

  addNewQuestion(data, i): void {
    var variableFlag = false;

    const regex = /{{[^{}]+}}/g;
    const answerKeyResult = (this.fromMarkInteraction && regex.test(data.answer_key)) ? 
      this.helperService.replaceVariablesWithPatientData(data.answer_key, this.callListData.patientData) : data.answer_key;
    const newQuestionFormGroup = this.fb.group({
      questionName: [data ? data.question : '', [Validators.required, Validators.pattern('^.*\\S.*$')]],
      questionType: [data ? data.option_type : 'Free text (Alpha & Numeric)', Validators.required],
      questionValue: [answerKeyResult, Validators.pattern('^.*\\S.*$')],
      description: [data ? data.description : '', Validators.pattern('^.*\\S.*$')],
      notes: [''],
      questionValueArray: this.fb.array([]),
      selectCheckBoxLength: [0],
      isRequired: [data ? data.is_required : false],
      isGroupVisible: [this.isGroupVisible],
      isValid: [true],
      saveDNQValidation: [true],
      isVariable: [variableFlag],
      category: [data ? data.categroy_id : '', Validators.required],
      conditionRule: [data ? data.condition.rules : ''],
      conditionValue: [data ? data.condition.value : ''],
      conditionMessage: [data ? data.condition.message : '']
    });
    if(data) {
      data.is_required && data.option_type !== 'Checkbox' ? newQuestionFormGroup.get('questionValue').setValidators([Validators.required, Validators.pattern('^.*\\S.*$')]) : null;
    }

    if(data && data.options.length) {
      data.options.forEach(res => {
        const questionValueControl = newQuestionFormGroup.get('questionValueArray') as FormArray;
        questionValueControl.push(this.addOptions(res));
      })
    }
    this.questionArray.push(newQuestionFormGroup);

    if (variableFlag) {
      const isValid = this.controlValidationCriteria(newQuestionFormGroup, newQuestionFormGroup.value.saveDNQValidation, newQuestionFormGroup.value.conditionRule, newQuestionFormGroup.value.conditionValue, i, newQuestionFormGroup.value.questionType === 'Checkbox', true, false);
      this.isGroupVisible = this.isGroupVisible ? isValid : this.isGroupVisible;
    }
  }

 isIsoDate(value: any): boolean {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return typeof value === 'string' && isoDatePattern.test(value);
  }

  addOptions(value: string = null): FormGroup {
    return this.fb.group({
      option: [value || ''],
      optionValue: [false]
    });
  }

  exitForm(): void {

    const dialogRef = this.dialog.open(DeclinependingreviewComponent, {
      data: {
        title: 'Alert!',
        message: "Are you sure you want to exit without submitting?",
        AcceptBtnText: "Yes",
        CancelBtnText: "NO"
      },
    });
    const dialogRefSub = dialogRef.afterClosed().subscribe(res => {
      if (res === 'yes') {
        this.onClose(false, true);
      } else if (res === 'no') {
        dialogRef.close();
      }
    });
    this.sub.add(dialogRefSub);
  }

  get questionArray(): FormArray {
    return this.previewscreenForm.get('questionArray') as FormArray;
  }

  getControlOnIndex(index: number, controlName: string, isValue: boolean) {
    return this.questionArray.at(index).get(controlName)[isValue ? 'value' : 'invalid'];
  }

  onDateChange(event, index: number): void {
    this.questionArray.at(index).get('questionValue').setValue(event.value);
  }

  onKeyDown(event: KeyboardEvent) {
    var keyCode = event.keyCode || event.which;
    if (
      (keyCode >= 48 && keyCode <= 57) || // Numbers 0-9
      (keyCode >= 96 && keyCode <= 105) || // Numpad numbers 0-9
      (event.key === 'A') ||
      (event.key === 'M') ||
      (event.key === 'P') ||
      (event.key === ':') ||
      event.key === '/' || // / (forward slash)
      keyCode === 8 || // Backspace
      keyCode === 46 || // Delete
      keyCode === 37 || // Left arrow
      keyCode === 39 || // Right arrow
      keyCode === 32 || // Space key
      keyCode === 9 // Tab key
    ) {
      return true;
    }
    // Prevent the default action for other keys
    event.preventDefault();
  }

  setValidationValue(formGroup: FormGroup, isValid: boolean, index: number, setIsGroupVisible: boolean): void {
    var dnqInvalidIndex = null;
    formGroup.get('isValid').setValue(isValid);
    setIsGroupVisible ? this.questionArray.controls.forEach((control, i) => {
      if (i > index) {
        var dnqValid = control.get('isValid').value ? true : false;
        if (dnqInvalidIndex == null) {
          dnqInvalidIndex = dnqValid ? dnqInvalidIndex : i;
        }
        control.get('isGroupVisible').setValue(dnqInvalidIndex != null && i > dnqInvalidIndex ? false : (isValid ? true : false));
      } else {
        control.get('isGroupVisible').setValue(true);
      }
    }) : null;
  }

  controlValidationCriteria(formGroup: FormGroup, isApplyValidation: boolean, validationRule: string, validationValue: any, index: number, isCheckBox = false, isVariable = false, setIsGroupVisible = true): boolean {
    let isValid = true;
    if (!!validationRule && !!validationValue && isApplyValidation) {
      let controlValue = isCheckBox ? formGroup.value.questionValueArray.filter(data => data.optionValue) : isVariable ? formGroup.value.questionValue ? formGroup.value.questionValue.split(' ') : [] : formGroup.value.questionValue;

      let checkboxOptions:any = [];
      if (isCheckBox) {
        checkboxOptions = controlValue.map(data => data.option);
      }

      let isRadioDropdown = false;
      if (['Dropdown','Single Choice'].includes(formGroup.value.questionType)) {
        isRadioDropdown = true;
      }

      switch(validationRule) {

        case 'Text contains': {

          isValid = !!controlValue ? ((isVariable ?
            !controlValue.find(data => data.includes(validationValue)) :
            !!checkboxOptions.length ? checkboxOptions.every(element => this.checkboxConditionCheck(element, validationValue, 'includes')) :
            isRadioDropdown ? !validationValue.find(v => v.includes(controlValue)) :
            !controlValue.includes(validationValue)) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Text does not contains': {
  
          isValid =  !!controlValue ? ((isVariable ?
          controlValue.find(data => data.includes(validationValue)) :
          !!checkboxOptions.length ? checkboxOptions.every(element => this.checkboxConditionCheck(element, validationValue, 'not includes')) :
          isRadioDropdown ? validationValue.find(v => v.includes(controlValue)) :
          controlValue.includes(validationValue)) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Text starts with': {
  
          isValid =  !!controlValue ? ((isVariable ?
            !controlValue.find(data => data.startsWith(validationValue)) :
            !!checkboxOptions.length ? checkboxOptions.every(element => this.checkboxConditionCheck(element, validationValue, 'startsWith')) :
            isRadioDropdown ? !validationValue.find(v => v.startsWith(controlValue)) :
            !controlValue.startsWith(validationValue)) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Text end with': {
  
          isValid =  !!controlValue ? ((isVariable ?
            !controlValue.find(data => data.endsWith(validationValue)) :
            !!checkboxOptions.length ? checkboxOptions.every(element => this.checkboxConditionCheck(element, validationValue, 'endsWith')) :
            isRadioDropdown ? !validationValue.find(v => v.endsWith(controlValue)) :
            !controlValue.endsWith(validationValue)) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Text is exactly': {
  
          isValid =  !!controlValue ? ((isVariable ?
            !controlValue.find(data => data == validationValue) :
            !!checkboxOptions.length ? checkboxOptions.every(element => this.checkboxConditionCheck(element, validationValue, 'equal')) :
            isRadioDropdown ? !validationValue.find(v => v == controlValue) :
            controlValue != validationValue) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Date is': {
          isValid =new Date(controlValue).getTime() != new Date(validationValue).getTime() ? true : false;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Date is before': {
  
          isValid = new Date(controlValue).getTime() >= new Date(validationValue).getTime() ? true : false;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Date is after': {
          
          isValid = new Date(controlValue).getTime() <= new Date(validationValue).getTime() ? true : false;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Greater than': {
  
          isValid = !!controlValue ? ( (isVariable ? !controlValue.find(data => data > Number(validationValue)) : Number(controlValue) <= Number(validationValue)) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Greater than or equal to': {
  
          isValid = !!controlValue ? ( (isVariable ? !controlValue.find(data => data >= Number(validationValue)) : Number(controlValue) < Number(validationValue)) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Less than': {

          isValid = !!controlValue ? (
          isVariable ? !controlValue.find(data => data < Number(validationValue)) : Number(controlValue) >= Number(validationValue)) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Less than or equal to': {
          
          isValid = !!controlValue ? (
          isVariable ? !controlValue.find(data => data <= Number(validationValue)) : Number(controlValue) > Number(validationValue)) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Is equal to': {
  
          isValid = !!controlValue ? ( (isVariable ? !controlValue.find(data => data == validationValue) : controlValue != validationValue) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Is not equal to': {
  
          isValid = !!controlValue ? ( (isVariable ? !controlValue.find(data => data != validationValue) : controlValue == validationValue) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Is between': {
  
          // value like '21-30'
          let betweenValue = validationValue.split('-');
          isValid = !!controlValue ? ( (isVariable ? !controlValue.find(data => data > Number(betweenValue[0]) && data < Number(betweenValue[1])) : Number(controlValue) <= Number(betweenValue[0]) || Number(controlValue) >= Number(betweenValue[1])) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
        case 'Is not between': {
  
          // value like '21-30'
          let betweenValue = validationValue.split('-');
          isValid = !!controlValue ? ( (isVariable ? !controlValue.find(data =>  data <= Number(betweenValue[0]) || data >= Number(betweenValue[1])) : Number(controlValue) > Number(betweenValue[0]) && Number(controlValue) < Number(betweenValue[1])) ? true : false ) : true;
          this.setValidationValue(formGroup, isValid, index, setIsGroupVisible);
          break;
        }
      }
    }
    setTimeout(() => {
      this.screenHeightChange.emit(true);
    }, 200);
    return isValid;
  }

  checkInputValidation(event: any): void {
    if (isNaN(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
  
  checkboxConditionCheck(element: any, validationArray: any, condition: string): boolean {
    
    let ans: boolean;
    switch (condition) {

      case 'includes':
        ans = !validationArray.find(item => element.includes(item));
        break;
      
      case 'not includes':
        ans = validationArray.find(item => element.includes(item));
        break;

      case 'startsWith':
        ans = !validationArray.find(item => element.startsWith(item));
        break;

      case 'endsWith':
        ans = !validationArray.find(item => element.endsWith(item));
        break;

      case 'equal':
        ans = !validationArray.find(item => element === item);
        break;
    }
    return ans;
  }

  checkBoxSelection(question: FormGroup): void {
    const checkedLength = question.get('questionValueArray').value.some(data => data.optionValue) ? 1 : 0;
    question.get('selectCheckBoxLength').setValue(checkedLength); 
  }

  isFormValid(isSaveClick = false): {formValid: boolean, formDNQValidFlag: boolean} {
    var checkBoxValidationFlag = true;
    var formValidFlag = true;
    var formDNQValidFlag = true;

    !isSaveClick || this.questionArray.controls.forEach(control => control.get('questionValue').markAsTouched());
    !isSaveClick || this.questionArray.controls.forEach(control => control.get('questionValueArray').markAsTouched());
    this.questionArray.controls.forEach((question) => {
      if (question.value.isGroupVisible) {
        formValidFlag = formValidFlag && question.valid;
        formDNQValidFlag = formDNQValidFlag && question.value.isValid;
        if (question.value.questionType === 'Checkbox' && question.value.isRequired) {
          checkBoxValidationFlag = checkBoxValidationFlag && (question.value.selectCheckBoxLength ? true : false);
        }
      }
    })

    return { formValid: (formValidFlag && checkBoxValidationFlag && (this.fromMarkInteraction ? true : formDNQValidFlag)), formDNQValidFlag: formDNQValidFlag };
  }

  generatePDFName(): string {
    return this.callListData.patientData['patientLastInteraction.PatientName'] + '-' + this.callListData.patientData.Id + '-' + this.callListData.study.Name;
  }

  showAnsInPdf(ansValue): any {

    if (!!ansValue && ansValue) {
      if (isArray(ansValue)) {
        let ans = ansValue.filter(v => v.optionValue).map(vi => vi.option);
        ans.length > 1 ? ans.join(', ') : ans[0]; 
        return ans;
      } else if (typeof ansValue === 'object') {
        return new DatePipe('en-US').transform(ansValue, 'MM/dd/yyyy | hh:mm a');
      } else {
        return ansValue;
      }
    } else {
      return '---';
    }
  }

  onClose(isSave: boolean, fromExitClick?: boolean): void {
    if (!isSave) {
      this.questionArray.controls.forEach((control, i) => {
        if (!!control.get('questionValue').value && (control.get('questionType').value === 'Date Picker' || !control.get('questionValue').value.includes('{{'))) {
          control.get('questionValue').setValue('');
        }
        control.get('questionValue').markAsUntouched();
        control.get('questionValueArray').markAsUntouched();
        control.get('isValid').setValue(true);
        control.get('isGroupVisible').setValue(true);
        control.get('selectCheckBoxLength').setValue(0);
        control.get('questionValueArray')['controls'].forEach(optionControl => optionControl.get('optionValue').setValue(false));
      })
      if (!fromExitClick) {
        this.dialogRef.close();
      } else { 
        this.scriptService.exitFromInteraction.next(true);
      }
    } else {
      const formValid = this.isFormValid(true);

      if (formValid.formValid) {
        if (this.fromMarkInteraction) {

          const quesListData = this.questionArray.getRawValue();

          let staffName = this._globalService.getLoginUserDetails().name;
          let pdfFormName = this.generatePDFName();
          let pdfPreviewContent: any = {
            pageOrientation: 'portrait',
            background: function () {
              return [
                {
                  canvas: [
                      { type: 'line', x1: 5, y1: 5, x2: 590, y2: 5, lineWidth: 1 }, //Up line
                      { type: 'line', x1: 5, y1: 5, x2: 5, y2: 835, lineWidth: 1 }, //Left line
                      { type: 'line', x1: 5, y1: 835, x2: 590, y2: 835, lineWidth: 1 }, //Bottom line
                      { type: 'line', x1: 590, y1: 5, x2: 590, y2: 835, lineWidth: 1 }, //Rigth line
                  ]
    
                }
              ]
            },
            content: [],
            info: {
              title: this.generatePDFName(),
            },
            styles: {
              pdfTitleHeader: {
                fontSize: 20,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              header: {
                fontSize: 15,
                bold: true,
              },
              title: {
                fontsize: 13,
                bold: true
              },
              tableExample: {
                margin: [0, 15, 0, 15]
              },
              staffNametable:{
                fontSize: 12,
                bold: true,
              },
              questionBox: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 15],
                fillColor: '#2e6da4',
                padding: [10, 5, 10, 5]
              },
              answerBox: {
                fontSize: 13,
                margin: [20, 0, 10, 5],
                color: 'black',
                fillColor: '#f0f0f0',
                padding: [10, 5, 10, 5]
              },
              starLabel: {
                fontSize: 12,
                margin: [20, 20, 0, 0],
                color: 'blue',
              },
              notesLabel: {
                fontSize: 12,
                margin: [20, 20, 0, 0],
                color: 'blue',
                decoration: 'underline',
              },
              notesContent: {
                fontSize: 12,
                margin: [0, 20],
                color: 'blue'
              },
            }
          }

          let pdfContent:any = [];
          pdfContent.push(
            {
              text: `${pdfFormName + '-' + this.formDetails.title}`,
              style: 'pdfTitleHeader',
              alignment: 'center',
              margin: [0, 5, 0, 20]
            },
            {
              text: !!this.formDetails.description ? this.formDetails.description : '',
              alignment: 'justify',
              margin: [0, 5, 0, 20]
            },
            {
              canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
              margin: [0, 10]
            },
          );
          
          quesListData.forEach((ele: any, i: number) => {
            pdfContent.push(
              {
                text: `${i+1}. ${ele.questionName}`,
                style: 'questionBox'
              },
              {
                text: `${this.showAnsInPdf(ele.questionType === 'Checkbox' && !!ele.questionValueArray ? ele.questionValueArray : !!ele.questionValue ? ele.questionValue : ' --- ')} \n\n`,
                style: 'answerBox'
              },
              {
                text: [
                    { text: '*', style: 'starLabel' },
                    { text: 'Notes', style: 'notesLabel' },
                    { text: ' : ', style: 'starLabel' },
                    { text: `${!!ele.notes ? ele.notes : ' --- '}`, style: 'notesContent' },
                ],
              },
            );

            if (!ele.isValid) {
              pdfContent.push(
                {
                  text: ele.conditionMessage,
                  margin: [0,15],
                  width: '*',
                  color: '#F75555',
                },
              )
            }

            if (i !== quesListData.length - 1) {
              pdfContent.push(
                {
                  canvas: [{ type: 'rect', x: 0, y: 0, w: 515, h: 20, lineWidth: 1 }],
                  margin: [0, 10]
                },
              )
            }
          });

          pdfContent.push(
          {
            style: 'staffNametable',
            table: {
                widths: ['*', '*'],
                body: [
                    [
                      { text: `Staff Name :- ${staffName}`, margin: [5, 5], fillColor: '#f3f3f3' },
                      { text: `Completion Date :- ${this.datePipe.transform(new Date(), 'MM/dd/yyyy')}`, margin: [5, 5], fillColor: '#f3f3f3' }
                    ]
                ]
            },
            margin: [0, 30],
          });

          pdfPreviewContent['content'] = pdfContent;

          const dialogRef = this.dialog.open(PreviewPdfComponent, {
            panelClass:['large-dailog-width'],
            data: { pdfData:  pdfPreviewContent, formId: this.formId, callListData: this.callListData, status: formValid.formDNQValidFlag ? 'Qualified' : 'DNQ'},
          });
          dialogRef.afterClosed().subscribe((action: boolean) => this.saveCallSuccess.emit({flag: action}));

        } else {
          var formDNQValidFlag = true;
          this.questionArray.controls.forEach((question: FormGroup, i: number) => {
            const isValid = this.controlValidationCriteria(question, question.value.saveDNQValidation, question.value.conditionRule, question.value.conditionValue, i, question.value.questionType === 'Checkbox');
            formDNQValidFlag = formDNQValidFlag && isValid;
          })
          formDNQValidFlag ? this.dialogRef.close() : null;
        }
      }
    }
  }

}
