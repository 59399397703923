import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { LeadsStatusEnumns } from '@sharedmodels/DMCREnums/DMCREnums';
import { LeadService } from '@sharedservices/BackServices/ComTrak/Leads/lead.service';
import { CampaignService } from '@sharedservices/BackServices/ComTrak/Setups/campaign.service';
import { CampaignReferralSourceService } from '@sharedservices/BackServices/ComTrak/Setups/campaignreferralsource.service';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { StaticDataService } from '@sharedservices/BackServices/ComTrak/Setups/StaticData.service';
import { TreeMapModule } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-editlead',
  templateUrl: './editlead.component.html',
  styleUrls: ['./editlead.component.scss', '../../style.component.scss']
})
export class EditleadComponent implements OnInit {
  LeadForm: FormGroup
  submitted: boolean = false;
  disabledCampaign: boolean = false;
  disabledCampaignref: boolean = false;
  disabledCampaignrefAd: boolean = false;
  disabledState: boolean = false;
  constructor(public dialogRef: MatDialogRef<EditleadComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder,
    public campaignService: CampaignService, public campaignRefSourceService: CampaignReferralSourceService, public staticDataService: StaticDataService,
    public leadService: LeadService, public _locationService: LocationService) { }

  async ngOnInit() {
    debugger
    this.initForm()
    this.LeadForm.patchValue(this.data.leadData)
    if (this.data && this.data.leadData) {
      this.LeadForm.controls['CurrentStatus'].setValue(this.data.leadData.CurrentStatusId);
      this.LeadForm.controls['RecruiterId'].setValue(this.data.leadData.RecruiterId);
      this.LeadForm.controls['PatientId'].setValue(this.data.leadData.PatientId);
    }

    this.campaignService.LoadActiveCampaign()
    this.ChangeCampaign()
    this.ChangeCampaignRefSource()


    // Check if the StateID value exists and set the default selected option
    const stateIDs = this._locationService._ActiveStateArray.map(item => item.StateID);
    const selectedStateID = this.data.leadData.StateID;

    if(this.data.leadData.StateID!=undefined && this.data.leadData.StateID!=null){
      await this._locationService.getMetroAreabyStateAsync(this.data.leadData.StateID);
    }

    if (!stateIDs.includes(selectedStateID)) {
       this.LeadForm.controls['StateID'].setValue(null); // Set the value to null for "Select State"
    }

    this.DisabledFields()
  }

  initForm() {
    this.LeadForm = this.fb.group({
      FirstName: [''],
      LastName: [''],
      Phone: [''],
      Email: [''],
      DOB: [''],
      Gender: [''],
      CampaignId: ['', Validators.required],
      CampaignRefSourceId: ['', Validators.required],
      CampaignRefSourceAdDetailId: ['', Validators.required],
      StateID: ['', Validators.required],
      MetropolitanAreaId: ['', Validators.required],
      LeadId: [''],
      CurrentStatus: [''],
      RecruiterId: [''],
      PatientId: [''],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.LeadForm.controls;
  }

  ChangeCampaign() {
    this.campaignRefSourceService.LoadCampaignReferralSourceByCampaignId(this.LeadForm.controls.CampaignId.value)
    if (this.LeadForm.controls.CampaignId.value != "") {
      this.disabledCampaign = false
    }
    else {
      this.disabledCampaign = true
    }
  }

  async ChangeState(){
    await this._locationService.getMetroAreabyStateAsync(this.LeadForm.controls.StateID.value);
  }


  ChangeCampaignRefSource() {
    this.campaignRefSourceService.LoadCampaignReferralSourceAdDetailsById(this.LeadForm.controls.CampaignRefSourceId.value)
    if (this.LeadForm.controls.CampaignRefSourceId.value != "") {
      this.disabledCampaignref = false
    }
    else {
      this.disabledCampaignref = true
    }
  }

  ChangeCampaignRefSourceAdDetails() {
    if (this.LeadForm.controls.CampaignRefSourceAdDetailId.value != "") {
      this.disabledCampaignrefAd = false
    }
    else {
      this.disabledCampaignrefAd = true
    }
  }

  // ChangeState() {
  //   if (this.LeadForm.controls.StateID.value != "") {
  //     this.disabledState = false
  //   }
  //   else {
  //     this.disabledState = true
  //   }
  // }

  DisabledFields() {
    if (this.campaignService._AllCampaign.filter(o => o.Id == this.LeadForm.controls.CampaignId.value)[0]) {
      this.LeadForm.controls['CampaignId'].disable();
      this.LeadForm.controls['CampaignId'].setValidators(null)
      this.disabledCampaign = false
    }
    else {
      this.LeadForm.controls['CampaignId'].enable();
      this.LeadForm.controls['CampaignId'].setValue('');
      this.LeadForm.controls['CampaignId'].setValidators([Validators.required,])
      this.disabledCampaign = true
    }

    if (this.campaignRefSourceService._AllCampaignReferralSource.filter(o => o.Id == this.LeadForm.controls.CampaignRefSourceId.value)[0]) {
      this.LeadForm.controls['CampaignRefSourceId'].disable();
      this.LeadForm.controls['CampaignRefSourceId'].setValidators(null)
      this.disabledCampaignref = false
    }
    else {
      this.LeadForm.controls['CampaignRefSourceId'].enable();
      this.LeadForm.controls['CampaignRefSourceId'].setValue('');
      this.LeadForm.controls['CampaignRefSourceId'].setValidators([Validators.required,])
      this.disabledCampaignref = true
    }

    if (this.campaignRefSourceService._CampaignReferralSourceAdDetails.filter(o => o.Id == this.LeadForm.controls.CampaignRefSourceAdDetailId.value)[0]) {
      this.LeadForm.controls['CampaignRefSourceAdDetailId'].disable();
      this.LeadForm.controls['CampaignRefSourceAdDetailId'].setValidators(null)
      this.disabledCampaignrefAd = false
    }
    else {
      this.LeadForm.controls['CampaignRefSourceAdDetailId'].enable();
      this.LeadForm.controls['CampaignRefSourceAdDetailId'].setValue('');
      this.LeadForm.controls['CampaignRefSourceAdDetailId'].setValidators([Validators.required,])
      this.disabledCampaignrefAd = true
    }

    if (this._locationService._ActiveStateArray.filter(o => o.StateID == this.LeadForm.controls.StateID.value)[0]) {
      this.LeadForm.controls['StateID'].disable();
      this.LeadForm.controls['StateID'].setValidators(null)
      this.disabledState = false
    }
    else {
      this.LeadForm.controls['StateID'].enable();
      this.LeadForm.controls['StateID'].setValue('');
      this.LeadForm.controls['StateID'].setValidators([Validators.required,])
      this.disabledState = true
    }


    if(this.LeadForm.controls.MetropolitanAreaId.value!=null || this.LeadForm.controls.MetropolitanAreaId.value!=undefined){
       this.LeadForm.controls['MetropolitanAreaId'].disable();
       this.LeadForm.controls['MetropolitanAreaId'].setValidators(null);
       //this.disabledState = false
    }
    else{
       this.LeadForm.controls['MetropolitanAreaId'].enable();
       this.LeadForm.controls['MetropolitanAreaId'].setValue('');
       this.LeadForm.controls['MetropolitanAreaId'].setValidators([Validators.required,]);
    }

  }

  Onlynumber(event): boolean {
    const Regexpattern = /^[0-9]*$/;
    const result = Regexpattern.test(event.key);
    return result;
  }

  onSubmit() {
    this.submitted = true;
    if (this.LeadForm.invalid) {
      return;
    }
    else {
      if (this.disabledCampaign == true || this.disabledCampaignref == true || this.disabledCampaignrefAd == true) {
        this.LeadForm.controls['CurrentStatus'].setValue(LeadsStatusEnumns.IncorrectLead);
      }
      else if (this.LeadForm.controls['CurrentStatus'].value != LeadsStatusEnumns.IncorrectLead) {
        this.LeadForm.controls['CurrentStatus'].setValue(this.LeadForm.controls['CurrentStatus'].value);
      }
      else {
        this.LeadForm.controls['CurrentStatus'].setValue(LeadsStatusEnumns.AssignableLead);
      }
      this.LeadForm.controls['CampaignRefSourceAdDetailId'].enable();
      this.LeadForm.controls['CampaignRefSourceId'].enable();
      this.LeadForm.controls['CampaignId'].enable();
      this.LeadForm.controls['StateID'].enable();
      this.LeadForm.controls['MetropolitanAreaId'].enable();
      this.leadService.EditLead(this.LeadForm.value)
      this.leadService.isCallApi.subscribe(res => {
        if (res) {
          this.onNoClick()
        }
      })
    }
  }
}
