import { LocationService } from './../../../Shared/Services/BackServices/ComTrak/Setups/location.service';
import { ExcelService } from './../../../Shared/Services/FrontServices/Export/excel.service';
import { SiteRooms } from './../../../Shared/Models/BackModels/ComTrak/Setups/Site';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { Site } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import { Component, Inject, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ColDef } from 'ag-grid-community';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { TemplateRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { LovStatusMasterID } from '@sharedmodels/DMCREnums/DMCREnums';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-createsites',
  templateUrl: './createsites.component.html',
  styleUrls: ['./createsites.component.scss', '../../style.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),

    ]),
  ]
})
export class CreatesitesComponent implements OnInit {
  @ViewChild('checkBox') checkBox: TemplateRef<any>;
  @ViewChild('edit') edit: TemplateRef<any>;
  SiteForm: FormGroup
  SiteRoomsForm: FormGroup
  isSiteRoom = false;
  submitted: boolean = false;
  public _data: Site;
  isedit: boolean = false;
  pageSize: number = 20;
  // actionType: string;
  columnDefs: ColDef[]
  public defaultColDef;
  public gridApi: any;
  public gridColumnApi: any;
  disbaledFields: boolean;
  SiteRoomArray: any[] = []
  displayRemoveIcon: boolean;
  dropdownSettingsState: IDropdownSettings = {
    singleSelection: false,
    idField: 'StateId',
    textField: 'Name',
    // itemsShowLimit: 5000,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  disableAdditionalSiteStatesDropdown: boolean = true;


  lovData: Lov[] = [];
  constructor(public dialogRef: MatDialogRef<CreatesitesComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public excelService: ExcelService,
    public fb: FormBuilder, public siteService: SiteService, public _Global: GlobalfunctionService, public locationService: LocationService,
    public scroll: ViewportScroller, public lovService: LovService) { }

  ngOnInit() {
    // this._Global.gotoTop();
    // this.gridConfig()
    this.initForm()
    this.locationService.getAllCountry()
    this.ChangeCountry()
    this.getSiteTimeZones()
    // this.locationService.isCallApi.subscribe(res => {
    //   if (res) {
    //     this.ChangeState()
    //   }
    // })
    if (this.data.callList != undefined) {
      // this.siteService.GetRoomsBySiteId(this.data.callList.Id)
      this.SiteForm.patchValue(this.data.callList)
      //debugger
      this.SiteForm.controls.StateID.setValue(this.data.callList.StateID == 0 ? '' : this.data.callList.StateID)
      this.SiteForm.controls.CityID.setValue(this.data.callList.CityID == 0 ? '' : this.data.callList.CityID)
      this.ChangeCountry()
      this.ChangeState()
      this.setAdditionalStateOnEdit(this.data.callList.AdditionalSiteStates)
      this.isedit = true
    }
    else {
      // this.siteService._roomsbySite = []
      this.isedit = false
    }
  }

  initForm() {
    this.SiteForm = this.fb.group({
      Name: ['', Validators.required],
      NickName: [''],
      Address: ['', Validators.required],
      IsActive: [false],
      IsSiteRoom: [false],
      Id: [''],
      RoomNo: [''],
      CountryID: [1, Validators.required],
      StateID: ['', Validators.required],
      CityID: ['', Validators.required],
      ZipCode: ['', Validators.required],
      TimeZone: ['', Validators.required],
      AdditionalSiteStates: [[]]
    })

    this.SiteRoomsForm = this.fb.group({
      SiteId: [''],
      IsActive: [true],
      Id: [0],
      RoomNo: [''],

    })
  }

  ChangeCountry() {
    this.locationService.getStatebyCountry(this.SiteForm.controls.CountryID.value)
  }

  ChangeState() {
    this.locationService.getCitybyState(this.SiteForm.controls.StateID.value)
    if ((this.SiteForm.controls.StateID.value == null) || (this.SiteForm.controls.StateID.value == undefined)) {
      this.disableAdditionalSiteStatesDropdown = true;
    }
    else {
      this.disableAdditionalSiteStatesDropdown = false;
    }
  }

  getAdditionalStateData() {
    var locationData: any = [];
    if (this.locationService._State != undefined && this.locationService._State != null) {
      locationData = JSON.parse(JSON.stringify(this.locationService._State));
      locationData.forEach(
        (x) => {
          x['StateId'] = x.StateID;
          // delete x['StateID']
        })
      if ((this.SiteForm.controls.StateID.value == null) || (this.SiteForm.controls.StateID.value == undefined)) {
        return locationData
      }
      else {
        return locationData.filter((x: any) => x.StateID != this.SiteForm.controls.StateID.value)
      }
    }
    else {
      return [];
    }
  }

  getSiteTimeZones() {
    this.lovService.getLovDataById(LovStatusMasterID.SiteTimeZones).subscribe(res => {
      this.lovData = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  get f() {
    return this.SiteForm.controls;
  }

  SaveSite() {
    this.SiteRoomsForm.controls.RoomNo.setValue(this.SiteForm.controls.RoomNo.value)
    let Id = this.data.callList ? this.data.callList.Id : 0
    this.SiteForm.controls.Id.setValue(Id)
    this.siteService.SaveAndEditSites(Id, this.SiteForm.value, this.isedit, this.isSiteRoom)
    this.siteService.isCallApi.subscribe(res => {
      if (res) {
        this.onNoClick()
        this.siteService.isCallApi.next(true)
      }
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.SiteForm.invalid) {
      return;
    }
    else {
      console.log("Site Form Value Before: ", this.SiteForm.value)
      this.SiteForm.value.AdditionalSiteStates.forEach(
        (x) => {
          delete x['Name'];
          if (this.isedit == false) {
            x["SiteId"] = 0
          }
          else {
            x["SiteId"] = this.data.callList.Id
          }
        }
      )

      console.log("Site Form Value After: ", this.SiteForm.value)
      // if (this.SiteForm.controls.IsSiteRoom.value == true) {
      //   let checkValidate = this.checkTableValidation()
      //   if (checkValidate == true) {
      //     this._Global.showErrorMsg("Room Is Required")
      //     return
      //   }
      //   else {
      //     this.SaveSite()
      //   }
      // }
      // else {

      this.SaveSite()

      // }
    }
  }

  setAdditionalStateOnEdit(additionalStateArray: any) {
    additionalStateArray.forEach(
      (x) => {
        var obj = this.locationService._State.filter(
          y => y.StateID == x.StateId
        )
        x['Name'] = obj[0].Name
      }
    )
    this.SiteForm.controls.AdditionalSiteStates.setValue(additionalStateArray)
  }

  Onlytext(event): boolean {
    var Regexpattern = /[a-zA-Z0-9 ]/;
    var result = Regexpattern.test(event.key);
    return result;
  }

  // onGridReady(params: any) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  //   this.gridApi.setDatasource(this.siteService._roomsbySite);
  // }

  // onPageSizeChanged(event: any) {
  //   this.gridApi.paginationSetPageSize(Number(event.target.value));
  // }


  // ClickIsActive(row: SiteRooms) {
  //   if (row.IsActive == false) {
  //     if (confirm("Are you sure You want to InActive this Room?") == true) {
  //       this.siteService.InActiveSiteRooms(row, "Room InActive Successfully.")
  //     }
  //     else {
  //       row.IsActive = true
  //     }
  //   }
  //   else {
  //     if (confirm("Are you sure You want to Active this Room?") == true) {
  //       this.siteService.InActiveSiteRooms(row, "Room Active Successfully.")
  //     }
  //     else {
  //       row.IsActive = false
  //     }
  //   }
  // }

  // onBtExport() {
  //   this.gridApi.exportDataAsCsv();
  // }

  // PrintElem(elem) {
  //   this.excelService.PrintElem('Site Rooms', elem)
  // }

  // destroyFilter() {
  //   this.columnDefs.forEach(element => {
  //     this.gridApi.destroyFilter(element.field);
  //   })
  // }

  // checkTableValidation() {
  //   this.SiteRoomArray = this.SiteRoomArray
  //   let lastObject = this.SiteRoomArray[this.SiteRoomArray.length - 1]
  //   var checkValidate = lastObject.RoomNo == "" ? true : false;
  //   if (checkValidate) {
  //     let index = this.SiteRoomArray.findIndex(o => o.RoomNo == lastObject.RoomNo)
  //     let errorMessage = `Room No ${index + 1} Is Required`
  //     this._Global.showErrorMsg(errorMessage)
  //     return true
  //   }
  //   else {
  //     return false
  //   }
  // }

  // AddSiteRoomArray(index: number) {
  //   if (index != null) {
  //     let checkValidate = this.checkTableValidation()
  //     if (checkValidate == true) {
  //       return
  //     }
  //     else {
  //       this.SiteRoomArray.push({
  //         RoomNo: '',
  //         Id: 0,
  //         SiteId: this.data.callList ? this.data.callList.Id : 0,
  //         IsActive: true
  //       })
  //     }
  //   }
  //   else {
  //     this.SiteRoomArray.push({
  //       RoomNo: '',
  //       Id: 0,
  //       SiteId: this.data.callList ? this.data.callList.Id : 0,
  //       IsActive: true
  //     })
  //   }

  // }

  // showRemoveIcon() {
  //   if (this.SiteRoomArray.length === 1) {
  //     this.displayRemoveIcon = false;
  //   } else {
  //     this.displayRemoveIcon = true;
  //   }
  // }

  // SpliceSiteRoomArray(index: number) {
  //   if (this.SiteRoomArray.length == 1) {
  //     return
  //   }
  //   else {
  //     this.SiteRoomArray.splice(index, 1)
  //   }
  // }

  // ChangeSiteRoom() {
  //   if (this.SiteForm.controls.IsSiteRoom.value == true) {
  //     this.isSiteRoom = true
  //     this.AddSiteRoomArray(null)
  //   }
  //   else {
  //     this.isSiteRoom = false
  //     this.SiteRoomArray = []
  //   }
  // }

  // SetEditCampaignRefSourceData(row: SiteRooms) {
  //   this.isedit = true
  //   this.SiteRoomArray = []
  //   this.siteService._roomsbySite.forEach((element: SiteRooms) => {
  //     this.SiteRoomArray.push({
  //       RoomNo: element.RoomNo,
  //       Id: element.Id,
  //       SiteId: element.SiteId,
  //       IsActive: element.IsActive
  //     })
  //     this.showRemoveIcon()
  //   })
  //   this._Global.gotoTop();
  // }


}
