import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommunicationService } from '@sharedservices/BackServices/ComTrak/Communication/communication.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'Markinteraction',
  templateUrl: './markinteraction.component.html',
  styleUrls: ['./markinteraction.component.scss']
})
export class MarkInteractionComponent implements OnInit,OnDestroy {
  htmlContent: SafeHtml;
  selectedPatient: any;
  activePatient:any;
  patientFc = new FormControl(''); 
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  myscript = "";
  userData = { last_name: 'John', lastname: 'Doe', age: 30, phone: 8308267153,campaign:'mumbai' };
  PatientList:any;
  subs = new Subscription()
  @ViewChild('departmentSelect') departmentSelect: any;
  constructor(
    public dialogRef: MatDialogRef<MarkInteractionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Global: GlobalfunctionService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private communicationservice :CommunicationService
  ) {

  }
  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  ngOnInit() {
    this.PatientList= this.data.list
    this.myscript= this.data.content
    const processedHtml = this.myscript.replace(/<p>&nbsp;<\/p>/g, '<br/>');
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(processedHtml);
  }


  processHtmlVariables(htmlContent: string, userData: any): string {
    // Regular expression to match variables inside double curly braces
    const regex = /{{(.*?)}}/g;
    // Replace variables with their corresponding values
    const processedHtml = htmlContent.replace(regex, (match, variable) => {
      const trimmedVariable = variable.trim();
      // Check if the variable exists in userData
      if (userData.hasOwnProperty(trimmedVariable)) {
        // Check if the variable is "Age" and its value is 0
        if (trimmedVariable === 'age' && userData[trimmedVariable] === 0) {
          return ''; // Return an empty string
        }
        if (trimmedVariable === 'study' && userData[trimmedVariable] === 'N/A') {
          return ''; // Return an empty string
        }
        // Return the variable's value
        return ' ' + userData[trimmedVariable] + ' ';
      }
      // If variable not found, return the original match
      return match;
    });
    return processedHtml;
  }

  onSubmit(value: string): void {
    // const htmlContents = Object.values(this.htmlContent)[0]; 
    // const datatype = this.data.type === 'SMS' ? 'sms':'email'
 
    if (this.patientFc && this.patientFc.valid) {  
    //   this.spinner.show();
    //   this._Global.IsReady = false;
    //   const param = {
    //     type: datatype,
    //     content: htmlContents
    //   };
  
    //   this.communicationservice.postSendTest(param).subscribe(
    //     (response: any) => {
    //       this.spinner.hide();
    //       this._Global.IsReady = true;
    //       this._Global.showSuccessMsg(response.message);
    //     },
    //     (error: any) => {
    //       console.error('Error sending test:', error);
    //     }
    //   );
  
    //   this.onSubmitAction.emit(value);
      this.dialogRef.close();
    } else {
      // Handle invalid form
    }
  }
  

  onPatientSelect(): void {
    if ( this.selectedPatient !== '') {
      this.selectedPatient = this.PatientList.find(patient => patient.comtrak_id === parseInt(this.patientFc.value));
      const newHtml = this.processHtmlVariables(this.myscript, this.selectedPatient);
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(newHtml);
    } else {
      this.selectedPatient = null; 
    }
  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }
}
