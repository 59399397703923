import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as myGlobals from '@shared/global_var';

@Injectable({
    providedIn: 'root'
})
export class NotitificationsService {
    notification_apiUrl = `${myGlobals._apiPathv3}notifications`;
    patient_apiUrl = `${myGlobals._apiPathv3}patient-manage`;
    TotalCountNotificationEmail:BehaviorSubject<number> = new BehaviorSubject(0);
    TotalCountNotificationText:BehaviorSubject<number> = new BehaviorSubject(0);
    TotalCountNotificationPhone:BehaviorSubject<number> = new BehaviorSubject(0);
    TotalCountNotificationChat:BehaviorSubject<number> = new BehaviorSubject(0);
    hideSideBar: BehaviorSubject<string> = new BehaviorSubject('');
    isOpenInteraction: BehaviorSubject<boolean> = new BehaviorSubject(true);
    refreshMemoGrid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshEmailGrid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshTextGrid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshChatGrid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshPhoneGrid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    memoIdData: BehaviorSubject<any> = new BehaviorSubject('');
    closeNotificationReply: BehaviorSubject<boolean> = new BehaviorSubject(false);
    sendReplyMessage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    notificationAudioFlag: BehaviorSubject<boolean> = new BehaviorSubject(true);
    tagUserListData: BehaviorSubject<any> = new BehaviorSubject(null);
    replyChatRowData: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        public http: HttpClient,
        private _Global: GlobalfunctionService
    ) {}

    MemoCreate(data: any): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/memo/create`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }
    // MemoCreate(data: any): Observable<any> {
    //     return this.http.post('http://192.168.1.79:3000/notifications/memo/create', data, {headers: this._Global.httpOptions }) as Observable<any>;
    // }
    getMemoList(supervisor?: boolean): Observable<any> {
        let url = `${this.notification_apiUrl}/memo/list?is_supervisor=${supervisor}`;
        return this.http.get(url, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getmemoList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/memo/list?is_supervisor=`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }
    
    getUsersList(type: string): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}patient-manage/get-user?is_distro=true`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getMemoListById(id): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/memo/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    AcknowledgeById(id): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/memo/update/add-acknowledge/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    MemoAction(id): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/memo/update/is-read/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getTypesOfNotificationList(option: HttpParams, type: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/inboxes-notification-list/${type}`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    getChatNotificationList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/chat-notification-list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    NotificationAction(id: string): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/mark-as-read/${id}`, {}, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    getNotificationList(option?: HttpParams): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    markAllAsRead(type: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/mark-as-read-all?type=${type}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    viewMemoById(id: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/memo/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // distro auth api's

    syncDistrolist(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/sync-get-group-data`, { headers: this._Global.httpOptions }) as Observable<any>;
    }    
    getDistrolist(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/get-distro-group-data`, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    connectDistro(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/auth-group`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    verifyGroup(code: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/verify-group?code=${code}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // chat-modal apis

    getChatList(option: HttpParams): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/chat/list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    createChat(data: any): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/chat/create`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    relpychat(data: any): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/chat/reply`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getTagUserList(data: any): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/tag-users-list`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    AssignPatient(data: any): Observable<any> {
        return this.http.post(`${this.patient_apiUrl}/assign-patient-notification`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // automated notification

    getTableColumnList(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/automated-notifications/get-tables-columns`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getUserRoleList(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/automated-notifications/get-roles`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    createAutomatedNotification(data: any, option: HttpParams): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/automated-notifications/create`, data, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    getAutomatedNotification(option: HttpParams): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/automated-notifications/list`, { params: option, headers: this._Global.httpOptions }) as Observable<any>;
    }

    toggleAutomatedNotification(id: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/automated-notifications/active-inactive/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    deleteAutomatedNotification(id: string): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/automated-notifications/delete/${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    // PatientProfile callrecording

    PatientCallRecording(code): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}patient-manage/fetch-patient-call-recording/${code}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    // distrolist
    getDistoList(): Observable<any> {
        return this.http.get(`${this.notification_apiUrl}/distro-list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    TransferDistro(data: any): Observable<any> {
        return this.http.post(`${this.notification_apiUrl}/transfer-distro-notification`, data, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    
    PatientData(phone): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}patient-manage/fetch-patient-by-number/${phone}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }
    
}