import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Patient } from '@sharedmodels/BackModels/ComTrak/Setups/patient';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, state, style, transition, animate, AUTO_STYLE } from '@angular/animations';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { StudyvisitService } from '@sharedservices/BackServices/ComTrak/Setups/studyvisit.service';
import { PatientDetailDialogService } from '@app/calllists/patientdetail/patientdetaildialogservice';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { FormControl } from '@angular/forms';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { SocketHandlerService } from '@sharedservices/FrontServices/socket-handler.service';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { HttpParams } from '@angular/common/http';
const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-recruitercalllist-notes',
  templateUrl: './recruitercalllist-notes.component.html',
  styleUrls: ['./recruitercalllist-notes.component.css', '../../style.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RecruitercalllistNotesComponent implements OnInit {
  ActivePageNo: number = 1;
  isMoreData:boolean = true;
  isMoreADataLoader:boolean = false;
  checkNotes: boolean = true
  checkSMS: boolean = true
  patientNotes: string = ''
  searchValue:string=''
  submitted: boolean;
  hideFields: boolean = true;
  patientList = [];
  originalPatientList: any[] = [...this.patientList];
  patientListMain = [];
  patientStudies = [];
  directionList = ['Inbound', 'Outbound'];
  communicationList = ['Phone', 'SMS', 'Email']

  constructor(public dialogRef: MatDialogRef<RecruitercalllistNotesComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public _patientInteractionService: PatientInteractionService, public _Global: GlobalfunctionService, public spinner: NgxSpinnerService, private socketService: SocketHandlerService, private PrescreenList: PrescreenService,
    public studyService: StudyService, public siteSerivce: SiteService, public statusService: StatusService, public studyVisitService: StudyvisitService,
    private _PatientDetailDialogService: PatientDetailDialogService, private patientService: PatientService, public _RecruiterService: RecruiterService, private _PatientService: PatientService) { }
  show1 = false;
  show = false;
  type = 'Interactions';
  directionFc = new FormControl('');
  communicationTypeFc = new FormControl('');
  public _data: any
  public notesData: any;
  public _patientDetails: Patient
  public _studies: any[];
  public selectedStudy: string;
  public communicationSelectedStudy: string;
  ngOnInit() {
    // this.siteSerivce.GetSites('');
    // this.statusService.GetStatus();
    // this.studyService.GetStudies('');
    // this.studyVisitService.GetStudyVisits()
    this.isMoreData = this.data.isMoreData
    let id = 0;
    if (this.data.PatientInteraction !== null) {
      this.type = (this.data.type || this.type);
      this.hideFields = this.data.hideAddNotes
      this.patientListMain = this.data.PatientInteraction[0].patientList.data.length && this.data.PatientInteraction[0].patientList.data.map(data => {
        data.type = typeof data.type === 'string' ? data.type.toLowerCase() === 'sms' ? 'SMS' : data.type.replace(/^\w/, c => c.toUpperCase()) : data.type;
        return data;
    });;
      const filteredStudyList = this.patientListMain && this.patientListMain
      .filter(data => data.patient_obj && data.patient_obj['patientLastInteraction.Study'] !== null && data.patient_obj['patientLastInteraction.Study'] !== '')
      .map(data => data.patient_obj['patientLastInteraction.Study']);
      this.communicationSelectedStudy = this.patientListMain.length && filteredStudyList.length ? filteredStudyList[0] : 'All';
      this.patientList = this.communicationSelectedStudy !== 'All' ? this.patientListMain.filter(o => o.patient_obj && o.patient_obj['patientLastInteraction.Study'] == this.communicationSelectedStudy) : this.patientListMain;
      this.patientStudies = filteredStudyList.length ? filteredStudyList.filter((obj, index, self) =>
        index === self.findIndex((res) => (
          res === obj
        ))
      ) : [];
      this._patientDetails = this.data.PatientInteraction[0].patientInfo
      this._data = Object.assign({}, this.data.PatientInteraction[0].PatientInteraction);

      if (this._data != undefined && this._data.Notes != undefined && this._data.Notes.length > 0) {
        var _studyId = this.data.PatientInteraction[0].PatientInteraction.Studies.find(x => x.SelectedStudyId).Id;
        this.selectedStudy = _studyId;
        this._data.Notes = this.data.PatientInteraction[0].PatientInteraction.Notes.filter(t => t.StudyId == _studyId);

        this._data.Notes  = (this._data.Notes).map((item) => {
          // Check if the item has the CreatedOn property
          if (item["fCreatedOn"]) {
            // Update the CreatedOn property to local time
            item["fCreatedOn"] = new Date(new Date(item["fCreatedOn"]).getTime() - new Date(item["fCreatedOn"]).getTimezoneOffset() * 60000).toLocaleString()
          }
          return item;
        });

      }

      //this.notesData = this.data.PatientInteraction[0].PatientInteraction;
      // this._patientDetails.CrioId
    }

  this.originalPatientList = this.patientList
  }
  onNoClick(): void {
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }

  onScroll(event: any): void {  // to handle api call , when user scroll upto bottom
    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if(this.data.isMoreData &&  !this.isMoreADataLoader){
        this.LoadCommunicationHistory(this.ActivePageNo + 1)
      }
    }
  }

  LoadCommunicationHistory(pageNO: number){
    if(this.isMoreData){
    this.isMoreADataLoader= true;
    let params = new HttpParams()
    .append('PageNo', String(pageNO))

    this.patientService.GeCommunicationHistoryById(this.data.rowData.Id, params).subscribe(
        (res: any) => {
            if (res.status) {
              this.patientList = [...this.patientList, ...res.data]
                this.isMoreData =  res.pagination.isMore;
                this.isMoreADataLoader= false;
                this.ActivePageNo = Number(res.pagination.currentPage)
            }
        },
        error => {
            this._Global.handleError(error);
            this.isMoreADataLoader= false;
        }
    );
  }
}

  openCommunicationPannel(type: string): void {
    this._RecruiterService.contactCenterFlag.next(true);
    this._RecruiterService.contactCenterType.next(type === 'Phone' ? 'call' : type.toLocaleLowerCase());
    this._RecruiterService.contactCenterRowData.next(this.data.rowData);
    this._PatientService.SetPatientId(this.data.rowData.Id);
    this._PatientService.SetRowData(this.data.rowData);
    this.onNoClick();
  }

  onselect() {
    const selectedDirection = this.directionFc.value;
    const selectedCommunicationType = this.communicationTypeFc.value;
    this.patientList = this.originalPatientList.filter(data => {
      let matchesDirection = true;
      let matchesCommunicationType = true;
  
      if (selectedDirection) {
        matchesDirection = selectedDirection.toLowerCase() === data.direction.toLowerCase();
      }
  
      if (selectedCommunicationType) {
        matchesCommunicationType = selectedCommunicationType.toLowerCase() === data.type.toLowerCase();
      }
  
      return matchesDirection && matchesCommunicationType;
    });
  }
  


  AddPatientNotes() {
    this.submitted = true
    if (this.patientNotes.trim() == '') {
      return;
    }
    else {
      this.spinner.show();
      this._Global.IsReady = false;
      // console.log(' my patient notes=' + JSON.stringify(this.patientNotes));
      this._patientInteractionService.SavePatientNotes(this._patientDetails.Id, this.patientNotes)
        .subscribe(res => {
          this.spinner.hide();
          this._Global.IsReady = true;
          this._Global.showSuccessMsg('Notes Saved successfully');
          this.onNoClick()
          this.submitted = false
          this.patientNotes = ''
          this.spinner.hide()
          this._PatientDetailDialogService.closePatientDetailDialog();
        }, error => {
          this._Global.IsReady = true;
          this._Global.handleError(error);
          this.spinner.hide()
        })
    }
  }
  collapsed = true;

  toggle() {
    this.collapsed = !this.collapsed;
  }

  // ChangeNotes() {
  //   if (this.checkNotes == false) {
  //     this.checkSMS = true
  //     this._data = this.notesData
  //     this._data = this._data.filter(o => o.NotesTypeID == "1")
  //   }
  //   else {
  //     if (this.checkSMS) {
  //       this._data = this.notesData
  //     }
  //     else {
  //       this._data = this._data.filter(o => o.NotesTypeID == "0")
  //     }
  //   }
  // }

  // ChangeSMS() {
  //   if (this.checkSMS == false) {
  //     this.checkNotes = true
  //     this._data = this.notesData
  //     this._data = this._data.filter(o => o.NotesTypeID == "0")
  //   }
  //   else {
  //     if (this.checkNotes) {
  //       this._data = this.notesData
  //     }
  //     else {
  //       this._data = this._data.filter(o => o.NotesTypeID == "1")
  //     }
  //   }
  // }

  filteredData(studyId: number) {
    let Obj = this.studyService._studies.filter(o => o.Id == studyId)[0]
    return Obj.Name
  }
  // filteredData(studyId: number) {
  //   let Obj = this.studyService._studiesArray.filter(o => o.Id == studyId)[0]
  //   return Obj.Name
  // }
  // getwid(num?: number): number {
  //   if (num) {
  //     console.log(num)
  //     return num * 12
  //   }
  //   return 50;
  // }

  toggleType(type: string) {
    this.show = false;
    this.type = type;
    this.searchValue = '';
  }

  onClickStudies(studyId) {
    if (studyId != undefined && studyId != "All") {
      //var notes= Object.assign([], this.data.PatientInteraction[0].PatientInteraction.Notes);
      if (this.type === 'Interactions') {
        this.selectedStudy = studyId;
        this._data.Notes = this.data.PatientInteraction[0].PatientInteraction.Notes.filter(o => o.StudyId == studyId);
      } else if (this.type === 'Communication') {
        this.communicationSelectedStudy = studyId;
        this.patientList = this.patientListMain.filter(o => o.patient_obj && o.patient_obj['patientLastInteraction.Study'] == studyId);
        this.originalPatientList = this.patientList
        this.directionFc.setValue('');
        this.communicationTypeFc.setValue('');
      }
    }
    else {
      if (this.type === 'Interactions') {
        this.selectedStudy = studyId;
        this._data.Notes = this.data.PatientInteraction[0].PatientInteraction.Notes;
      } else if (this.type === 'Communication') {
        this.communicationSelectedStudy = studyId;
        this.patientList = this.patientListMain;
        this.originalPatientList = this.patientList
        this.directionFc.setValue('');
        this.communicationTypeFc.setValue('');
      }
    }
    this.searchValue='';
  }

  onSearch(query) {
    const inputValue = (event.target as HTMLInputElement).value;
    if(this.type === 'Interactions') {

      const results = this.data.PatientInteraction[0].PatientInteraction.Notes.filter((item) => {
        if (item.StudyId === this.selectedStudy || this.selectedStudy == "All") {
          // Specify the properties you want to search in
          const searchableProperties = ["Notes", "fCreatedOn", "NameSMS", "StudyName","ScheduledDateTime","StudyVisitStatusName","StatusName","SiteName","NextDate"];
          
          // Check if the current property is in the list of searchable properties
          for (const key in item) {
            if (searchableProperties.includes(key) && item[key] != null && item[key].toString().toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      
      this._data.Notes = results;
    } else {
      const results = this.patientListMain.filter((item) => {
        if (item.study.id == this.communicationSelectedStudy || this.communicationSelectedStudy == "All") {
          // Specify the properties you want to search in
          const searchableProperties = ["type", "study", "sent_at", "received_at", "direction", "patient_obj", "title"];
          
          // Check if the current property is in the list of searchable properties
          for (const key in item) {
            if (key == 'study') {
              if (searchableProperties.includes(key) && item[key] != null && item[key].name.toString().toLowerCase().includes(inputValue.toLowerCase())) {
                return true;
              }
            } else if (key == 'patient_obj') {
              if (searchableProperties.includes(key) && item[key] != null && (item[key].FirstName.toString().toLowerCase().includes(inputValue.toLowerCase()) || item[key].LastName.toString().toLowerCase().includes(inputValue.toLowerCase())) ) {
                return true;
              }
            } else if (searchableProperties.includes(key) && item[key] != null && item[key].toString().toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      
      this.patientList = results;
    }
  }

  highlightText(content: string, searchText: string, value=true): string {
    if (!searchText || searchText === '' || typeof content !== 'string') {
      return content;
    }
  
    const pattern = new RegExp(searchText, 'gi');
    return content.replace(pattern, match => `<span class="highlight">${match}</span>`).toString();
  }

  getDirectionString(direction: any): string {
    if (typeof direction === 'boolean') {
      return direction ? 'true' : 'false';
    } else if (typeof direction === 'string') {
      return direction;
    } else {
      return '---'; 
    }
  }
  
  openChatBox(rowData: any): void {

    this.socketService.emitChatJoinRoomEvent('notes', rowData._id);
    localStorage.setItem('entity_name', 'notes');
    localStorage.setItem('entity_title', rowData.patient_obj.FullName);
    localStorage.setItem('entity_id', rowData._id);
    this.PrescreenList.openChatBox.next(true);
    this.onNoClick();
  }
}

