import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NotitificationsService } from '@sharedservices/BackServices/ComTrak/Communication/notifications.service';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transferdisto',
  templateUrl: './transferdisto.component.html',
  styleUrls: ['./transferdisto.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransferDistoComponent implements OnInit {
  subs = new Subscription();
  public myData: any = [];
  htmlContent: any;
  SubmitClicked: boolean = false;
  TransferDistroForm: FormGroup;
  notificationId:string;
  DistroList:any
  patientFc = new FormControl('');
  @Output() onSubmitAction: EventEmitter<string> = new EventEmitter<string>();
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000,
    positionClass: 'toast-top-right'
  };
  constructor(
    public dialogRef: MatDialogRef<TransferDistoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _global: GlobalfunctionService,
    private fb: FormBuilder,
    private _Global: GlobalfunctionService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastService: ToastrService,
    private NotitificationsService: NotitificationsService,
  ) {
    this.TransferDistroForm = this.fb.group({
      distro: [[], Validators.required],
      notes: [''],
    });
  }

  ngOnInit() {
    this.DistroList= this.data.list
     this.notificationId=this.data.id
  }

  onSubmit(): void {
    if (this.TransferDistroForm.valid) {
      this.SubmitClicked = false;
      this.onSubmitAction.emit(this.TransferDistroForm.value);
      this.TransferDistro()
    } else {
      this.SubmitClicked = true;
    }
  }

  TransferDistro(): void {
    this.spinner.show();
    this._Global.IsReady = false;
    let formData = this.TransferDistroForm.getRawValue();
    let selectedDistro = this.DistroList.filter(user => formData.distro.includes(user.email));
    console.log('selectedDistro',selectedDistro[0].email)
    const notesValue = this.TransferDistroForm.get('notes').value;
    let passData = {
      notification_id: this.notificationId,
      distro_email: selectedDistro[0].email,
      note: notesValue,
  }
    const getData = this.NotitificationsService.TransferDistro(passData).subscribe({
      next: (res) => {
        if (res.status) {
          this.spinner.hide();
          this._Global.IsReady = true;
         this.toastService.success('', res.message, this.toastOptions);

        const parsedUrl = new URL(window.location.href);
        this.NotitificationsService.refreshEmailGrid.next(parsedUrl.pathname === '/emailscreen' ? true : false);
        this.NotitificationsService.refreshTextGrid.next(parsedUrl.pathname === '/textscreen' ? true : false);
        this.NotitificationsService.refreshChatGrid.next(parsedUrl.pathname === '/chatscreen' ? true : false);
         this.dialogRef.close(); 
         this.onNoClick(true);
        }else{
          this.toastService.success('', res.message, this.toastOptions);
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      },
      error: (err) => {
        this.toastService.error('', err.error.message, this.toastOptions);
        this.spinner.hide();
        this._Global.IsReady = true;
      }
    });
    this.subs.add(getData);
  }

  onNoClick(flag: boolean): void {
    this.dialogRef.close(flag);
  }

  handleSelect(event: any): void {
    // Add your select handler logic here
  }
}
